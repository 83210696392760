import getSearchTextAt from './getSearchTextAt'

export const getSearchText = (
  editorState: any,
  selection: any,
  trigger: any
) => {
  const anchorKey = selection.getAnchorKey()
  const anchorOffset = selection.getAnchorOffset()
  const currentContent = editorState.getCurrentContent()
  const currentBlock = currentContent.getBlockForKey(anchorKey)
  const blockText = currentBlock.getText()
  return getSearchTextAt(blockText, anchorOffset, trigger)
}
