export const DELETE_TASKS_REQUEST = 'DELETE_TASKS_REQUEST'
export const DELETE_TASKS_SUCCESS = 'DELETE_TASKS_SUCCESS'
export const DELETE_TASKS_FAILURE = 'DELETE_TASKS_FAILURE'
export const DELETE_TASKS_CANCEL = 'DELETE_TASKS_CANCEL'

export const DELETE_PLANS_REQUEST = 'DELETE_PLANS_REQUEST'
export const DELETE_PLANS_SUCCESS = 'DELETE_PLANS_SUCCESS'
export const DELETE_PLANS_FAILURE = 'DELETE_PLANS_FAILURE'
export const DELETE_PLANS_CANCEL = 'DELETE_PLANS_CANCEL'

export const DELETE_TEAMS_REQUEST = 'DELETE_TEAMS_REQUEST'
export const DELETE_TEAMS_SUCCESS = 'DELETE_TEAMS_SUCCESS'
export const DELETE_TEAMS_FAILURE = 'DELETE_TEAMS_FAILURE'
export const DELETE_TEAMS_CANCEL = 'DELETE_TEAMS_CANCEL'

export const DELETE_INVITES_REQUEST = 'DELETE_INVITES_REQUEST'
export const DELETE_INVITES_SUCCESS = 'DELETE_INVITES_SUCCESS'
export const DELETE_INVITES_FAILURE = 'DELETE_INVITES_FAILURE'
export const DELETE_INVITES_CANCEL = 'DELETE_INVITES_CANCEL'

export const DELETE_MESSAGES_REQUEST = 'DELETE_MESSAGES_REQUEST'
export const DELETE_MESSAGES_SUCCESS = 'DELETE_MESSAGES_SUCCESS'
export const DELETE_MESSAGES_FAILURE = 'DELETE_MESSAGES_FAILURE'
export const DELETE_MESSAGES_CANCEL = 'DELETE_MESSAGES_CANCEL'

export const DELETE_UPLOADED_FILES_REQUEST = 'DELETE_UPLOADED_FILES_REQUEST'
export const DELETE_UPLOADED_FILES_SUCCESS = 'DELETE_UPLOADED_FILES_SUCCESS'
export const DELETE_UPLOADED_FILES_FAILURE = 'DELETE_UPLOADED_FILES_FAILURE'
export const DELETE_UPLOADED_FILES_CANCEL = 'DELETE_UPLOADED_FILES_CANCEL'

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'
export const DELETE_USERS_CANCEL = 'DELETE_USERS_CANCEL'

export const DELETE_USER_INVITES_REQUEST = 'DELETE_USER_INVITES_REQUEST'
export const DELETE_USER_INVITES_SUCCESS = 'DELETE_USER_INVITES_SUCCESS'
export const DELETE_USER_INVITES_FAILURE = 'DELETE_USER_INVITES_FAILURE'
export const DELETE_USER_INVITES_CANCEL = 'DELETE_USER_INVITES_CANCEL'

export const DELETE_CUSTOM_CLAIM_REQUEST = 'DELETE_CUSTOM_CLAIM_REQUEST'
export const DELETE_CUSTOM_CLAIM_SUCCESS = 'DELETE_CUSTOM_CLAIM_SUCCESS'
export const DELETE_CUSTOM_CLAIM_FAILURE = 'DELETE_CUSTOM_CLAIM_FAILURE'
export const DELETE_CUSTOM_CLAIM_CANCEL = 'DELETE_CUSTOM_CLAIM_CANCEL'
