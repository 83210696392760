import { getCurrentTeam } from './getCurrentTeam'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCurrentTeamTaskSizes = ({ state, teamId }: Props): string[] | undefined => {
  const team = getCurrentTeam({ state, teamId })

  return team?.taskSizes
}
