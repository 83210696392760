import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskPlanDropdownConnectedProps } from './types/TaskPlanDropdownConnectedProps'
import { TaskPlanDropdownMapStateReturnType } from './types/TaskPlanDropdownMapStateReturnType'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskPlanDropdownMapState = (
  state: StateReducers,
  props: TaskPlanDropdownConnectedProps
): TaskPlanDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const canWrite = getCanWrite({ state })

  const showTaskPlanDropdown =
    showDropdown &&
    showDropdown.type === 'taskPlan' &&
    showDropdown.id === props.taskId

  return {
    showTaskPlanDropdown,
    canWrite,
  }
}
