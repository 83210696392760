import { GET_UNSCOPE_USER_FAILURE } from 'actions'

import { GetUnscopeUserFailureAction } from './types/GetUnscopeUserFailureAction'
import { GetUnscopeUserRequestActionValues } from './types/GetUnscopeUserRequestActionValues'


interface Props {
  requestValues: GetUnscopeUserRequestActionValues
}

export const createGetUnscopeUserFailureAction = ({
  requestValues,
}: Props): GetUnscopeUserFailureAction => {
  return {
    type: GET_UNSCOPE_USER_FAILURE,
    requestValues,
  }
}
