import { connect } from 'react-redux'
import { ProCanceledMain } from './ProCanceledMain'
import { ProCanceledMainMapState } from './ProCanceledMainMapState'
import { ProCanceledMainMapDispatch } from './ProCanceledMainMapDispatch'
import { ProCanceledMainMapStateReturnType } from './types/ProCanceledMainMapStateReturnType'
import { ProCanceledMainMapDispatchReturnType } from './types/ProCanceledMainMapDispatchReturnType'
import { ProCanceledMainConnectedProps } from './types/ProCanceledMainConnectedProps'

export const ProCanceledMainConnected = connect<
  ProCanceledMainMapStateReturnType,
  ProCanceledMainMapDispatchReturnType,
  ProCanceledMainConnectedProps>(ProCanceledMainMapState,
    ProCanceledMainMapDispatch)(ProCanceledMain)
