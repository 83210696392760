import { POST_MESSAGES_FAILURE } from 'actions'

import { PostMessagesFailureAction } from './types/PostMessagesFailureAction'
import { PostMessagesRequestActionValues } from './types/PostMessagesRequestActionValues'


interface Props {
  requestValues: PostMessagesRequestActionValues
}

export const createPostMessagesFailureAction = ({
  requestValues,
}: Props): PostMessagesFailureAction => {
  return {
    type: POST_MESSAGES_FAILURE,
    requestValues,
  }
}
