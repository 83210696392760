// import { NewMessageFileFieldStyles } from './NewMessageFileFieldStyles'
import React from 'react'
import { NewMessageFileFieldProps } from './types/NewMessageFileFieldProps'
import { WorkspaceStorageUploadFieldConnected } from 'components/field/WorkspaceStorageUploadField/WorkspaceStorageUploadFieldConnected'

export const NewMessageFileField = ({
  uploadFile,
}: NewMessageFileFieldProps): JSX.Element => {
  return <WorkspaceStorageUploadFieldConnected onValid={uploadFile} />
}
