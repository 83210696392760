import { StateReducers } from '../reducers/types/StateReducers'
import { getFilteredTasks } from './getFilteredTasks'
import { getSortedByOrder } from 'utilities/getSortedByOrder'
import { getAllPlanTaskIdObject } from './getAllPlanTaskIdObject'
import { pickBy } from 'lodash'

interface Props {
  state: StateReducers
  useFilter?: boolean
}

export const getBackLogTaskIds = ({ state, useFilter }: Props): string[] => {
  const plans = state.plans
  const filteredTasks = useFilter
    ? getFilteredTasks({ state })
    : state.tasks || undefined

  const planTaskIds = getAllPlanTaskIdObject({ state })

  const filteredTasksWithoutPlanId = pickBy(
    filteredTasks,
    (taskReducer, taskId) => {
      const planId = taskReducer.data.planId
      const abandoned = plans && planId && plans[planId] && !planTaskIds[taskId]
      const isPlanned = !planId && !planTaskIds[taskId]

      return abandoned || isPlanned
    }
  )

  const ids = getSortedByOrder({
    itemsObject: filteredTasksWithoutPlanId,
    reverse: true,
  })

  return ids
}
