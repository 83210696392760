import { ReportMainStyles } from './ReportMainStyles'
import React from 'react'
import { ReportMainProps } from './types/ReportMainProps'
import { SyncTaskCountButtonConnected } from 'components/button/SyncTaskCountButton/SyncTaskCountButtonConnected'
import { TASKS_NOUN } from 'constants/english'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'

export const ReportMain = ({ taskCount }: ReportMainProps): JSX.Element => {
  return (
    <div className={`ReportMain ${ReportMainStyles}`}>
      <HeaderOneTextConnected>Report</HeaderOneTextConnected>

      <div className={lightBoxStyles}>
        <h6>
          {taskCount} {TASKS_NOUN}
        </h6>

        <p>It may be out of sync, click below to resync.</p>

        <SyncTaskCountButtonConnected className={marginTopMediumStyles} />
      </div>
    </div>
  )
}
