import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_READ_ALL } from 'actions'
import { ReadAllButtonMapDispatchReturnType } from './types/ReadAllButtonMapDispatchReturnType'

export const ReadAllButtonMapDispatch = (
  dispatch: Dispatch<Action>
): ReadAllButtonMapDispatchReturnType => {
  return {
    readAll: (notificationGroup) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_READ_ALL,
          values: {
            notificationGroup,
          },
        })
      )
    },
  }
}
