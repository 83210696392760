// import { UserInvitesMainStyles } from './UserInvitesMainStyles'
import React from 'react'
import { UserInvitesMainProps } from './types/UserInvitesMainProps'
import { UserInvitesWatcherConnected } from 'components/watcher/UserInvitesWatcher'
import { UserInviteListConnected } from 'components/list/UserInviteList'

export const UserInvitesMain = ({
  email,
}: UserInvitesMainProps): JSX.Element => {
  return (
    <>
      {email && <UserInvitesWatcherConnected email={email} />}
      <UserInviteListConnected />
    </>
  )
}
