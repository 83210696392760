// import { Dispatch, Action } from 'redux'

import {
  // DisplayNameSpanConnectedProps,
  DisplayNameSpanMapDispatchReturnType,
} from './types'

export const DisplayNameSpanMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: DisplayNameSpanConnectedProps,
): DisplayNameSpanMapDispatchReturnType => {
  return {
  }
}
