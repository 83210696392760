import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUserRole } from 'state/getters/getUserRole'
import { AvatarConnectedProps } from './types/AvatarConnectedProps'
import { AvatarMapStateReturnType } from './types/AvatarMapStateReturnType'

export const AvatarMapState = (
  state: StateReducers,
  props: AvatarConnectedProps
): AvatarMapStateReturnType => {
  const user = getUserRole({ state, userId: props.userId }) || state.currentUser
  const displayName = user?.displayName
  const photoURL = user?.photoURL

  return {
    displayName,
    photoURL,
  }
}
