// export * from './putActions'
// export * from './postActions'
export * from './getActions'
export * from './patchActions'
export * from './authActions'
export * from './adminActions'
// export * from './bridgeActions'
export * from './setActions'
export * from './submitActions'
export * from './postActions'
export * from './deleteActions'
export * from './uploadActions'

export const API_FLOW_ERROR = 'API_FLOW_ERROR'
export const ERROR = 'ERROR'
export const CALL_HISTORY_METHOD = 'CALL_HISTORY_METHOD'
export const FIRESTORE_FAILURE = 'FIRESTORE_FAILURE'
export const LOCATION_CHANGE = 'LOCATION_CHANGE'
export const HISTORY_ACTION_WITH_CURRENT_QUERY =
  'HISTORY_ACTION_WITH_CURRENT_QUERY'
export const TOGGLE = 'TOGGLE'
export const TOGGLE_SHOW_DASHBOARD_SPINNER = 'TOGGLE_SHOW_DASHBOARD_SPINNER'
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR' // TODO: handle UNEXPECTED_ERROR
export const WINDOW_RESIZE = 'WINDOW_RESIZE'
export const SET_WINDOW_META = 'SET_WINDOW_META'
export const GO_BACK = 'GO_BACK' // Be careful, this constant is also used in the Android app
export const CLOSE_DROPDOWN = 'CLOSE_DROPDOWN'
export const READING_MESSAGES = 'READING_MESSAGES'
export const READING_MESSAGES_DONE = 'READING_MESSAGES_DONE'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const FIX_TASK_ORDERS = 'FIX_TASK_ORDERS'
export const FIX_TASK_ORDERS_DONE = 'FIX_TASK_ORDERS_DONE'
export const INCREMENT_WATCHER = 'INCREMENT_WATCHER'
export const UNAVAILABLE_TASK = 'UNAVAILABLE_TASK'
export const INCREMENT_TASK_WATCHER = 'INCREMENT_TASK_WATCHER'
export const INCREMENT_MESSENGER_WATCHER = 'INCREMENT_MESSENGER_WATCHER'
export const INCREMENT_TASK_PAGE = 'INCREMENT_TASK_PAGE'
export const CLEAR_TEAM_WATCHED_TASKS = 'CLEAR_TEAM_WATCHED_TASKS'
export const CLEAR_TEAM_SCOPE = 'CLEAR_TEAM_SCOPE'
export const NEW_PUSH_NOTIFICATION = 'NEW_PUSH_NOTIFICATION'
