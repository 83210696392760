import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { Plan } from 'firebaseFunctions/types'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { SUBMIT_REOPEN_PLAN, SUBMIT_REOPEN_PLAN_DONE } from 'actions'
import { patchPlansSaga } from 'flows/sagas/patch/patchPlansSaga'
import { createSetSessionStorageAction } from '../../actionCreators/sessionStorage'
import { createSetAlertMessageAction } from '../../actionCreators/set/SetAlertMessage/createSetAlertMessageAction'

export const submitReopenPlanF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_REOPEN_PLAN])
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const currentUserId = state.currentUserId
    const currentuserDisplayName = state.currentUser?.displayName
    const { planId } = action.values

    if (planId && currentUserId && teamId && currentuserDisplayName) {
      const updatedPlanMutable: Plan = {
        status: 'open',
      }

      const items = {
        [planId]: {
          data: updatedPlanMutable,
          teamId,
        },
      }

      yield spawn(patchPlansSaga, {
        items: items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentuserDisplayName,
      })

      yield put(
        createSetSessionStorageAction({
          description: 'submitReopenPlanF',
          sessionStorage: {
            showCurrentPlan: true,
            showNextPlan: true,
          },
        })
      )

      yield put(
        createSetAlertMessageAction({
          description: 'submitClosePlanF',
          values: {
            location: 'toast',
            type: 'success',
            message: 'Reopened',
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitReopenPlanF',
        })
      )
    }

    yield put(
      createToggleAction({
        description: 'submitReopenPlanF',
        toggles: {
          showDropdown: false,
        },
      })
    )

    yield put({
      type: SUBMIT_REOPEN_PLAN_DONE,
    })
  }
}
