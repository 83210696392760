import { connect } from 'react-redux'
import { SendConfirmEmailButton } from './SendConfirmEmailButton'
import { SendConfirmEmailButtonMapState } from './SendConfirmEmailButtonMapState'
import { SendConfirmEmailButtonMapDispatch } from './SendConfirmEmailButtonMapDispatch'
import { SendConfirmEmailButtonMapStateReturnType } from './types/SendConfirmEmailButtonMapStateReturnType'
import { SendConfirmEmailButtonMapDispatchReturnType } from './types/SendConfirmEmailButtonMapDispatchReturnType'
import { SendConfirmEmailButtonConnectedProps } from './types/SendConfirmEmailButtonConnectedProps'

export const SendConfirmEmailButtonConnected = connect<
  SendConfirmEmailButtonMapStateReturnType,
  SendConfirmEmailButtonMapDispatchReturnType,
  SendConfirmEmailButtonConnectedProps>(SendConfirmEmailButtonMapState,
    SendConfirmEmailButtonMapDispatch)(SendConfirmEmailButton)
