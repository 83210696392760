import { UpgradeWorkspaceMainConnectedProps } from './types/UpgradeWorkspaceMainConnectedProps'
import { UpgradeWorkspaceMainMapStateReturnType } from './types/UpgradeWorkspaceMainMapStateReturnType'

import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'

export const UpgradeWorkspaceMainMapState = (
  state: StateReducers,
  _props: UpgradeWorkspaceMainConnectedProps
): UpgradeWorkspaceMainMapStateReturnType => {
  const currentTeamReducer = getCurrentTeamReducer({ state })
  const userCount = currentTeamReducer?.stats?.userCount

  return {
    userCount,
  }
}
