import {
  TEAMS_COLLECTION,
  TASKS_COLLECTION,
  TASK_CHANGES_COLLECTION,
} from '../../../constants'
import { FirestoreClient, TaskChange } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  taskId: string
}

export const getTaskChangesRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  taskId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.CollectionReference<TaskChange>
  : FirebaseFirestore.CollectionReference => {
  return firestore
    .collection(TEAMS_COLLECTION)
    .doc(teamId)
    .collection(TASKS_COLLECTION)
    .doc(taskId)
    .collection(TASK_CHANGES_COLLECTION) as any
}
