// import { Dispatch, Action } from 'redux'

import {
  // WorkingMessageListConnectedProps,
  WorkingMessageListMapDispatchReturnType,
} from './types'

export const WorkingMessageListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: WorkingMessageListConnectedProps,
): WorkingMessageListMapDispatchReturnType => {
  return {
  }
}
