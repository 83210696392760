import { connect } from 'react-redux'
import { WorkspaceSubscriptionMain } from './WorkspaceSubscriptionMain'
import { WorkspaceSubscriptionMainMapState } from './WorkspaceSubscriptionMainMapState'
import { WorkspaceSubscriptionMainMapDispatch } from './WorkspaceSubscriptionMainMapDispatch'

import { WorkspaceSubscriptionMainMapStateReturnType } from './types/WorkspaceSubscriptionMainMapStateReturnType'
import { WorkspaceSubscriptionMainMapDispatchReturnType } from './types/WorkspaceSubscriptionMainMapDispatchReturnType'
import { WorkspaceSubscriptionMainConnectedProps } from './types/WorkspaceSubscriptionMainConnectedProps'


export const WorkspaceSubscriptionMainConnected = connect<
  WorkspaceSubscriptionMainMapStateReturnType,
  WorkspaceSubscriptionMainMapDispatchReturnType,
  WorkspaceSubscriptionMainConnectedProps>(WorkspaceSubscriptionMainMapState,
    WorkspaceSubscriptionMainMapDispatch)(WorkspaceSubscriptionMain)
