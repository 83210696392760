import { PATCH_TASKS_FAILURE } from 'actions'

import { PatchTasksFailureAction } from './types/PatchTasksFailureAction'
import { PatchTasksRequestActionValues } from './types/PatchTasksRequestActionValues'


interface Props {
  requestValues?: PatchTasksRequestActionValues
}

export const createPatchTasksFailureAction = ({
  requestValues,
}: Props): PatchTasksFailureAction => {
  return {
    type: PATCH_TASKS_FAILURE,
    requestValues,
  }
}
