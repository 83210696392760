import { pickBy } from 'lodash'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { MAX_TASK_ID_WATCHERS } from 'constants/environmentConstants'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsRoutesFromState } from 'state/getters/getIsRoutesFromState'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import {
  // TasksWatcherConnectedProps,
  TasksWatcherMapStateReturnType,
} from './types'

export const TasksWatcherMapState = (
  state: StateReducers
  // props: TasksWatcherConnectedProps,
): TasksWatcherMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const isRoutes = getIsRoutesFromState({ state })
  const isPlanRoute = isRoutes && isRoutes.isPlanRoute
  const isCurrentRoute = isRoutes && isRoutes.isCurrentRoute
  const { plans, tasks } = state
  const task = taskId && getCurrentTask({ state, taskId })
  const maxTaskIdWatchers = MAX_TASK_ID_WATCHERS

  let taskIds: string[] = []
  let planIds: string[] = []

  if (taskId && !task && tasks) {
    // will cost an extra read and watcher since the taskId can be any where
    taskIds.push(taskId)
  }

  // TODO: separate and write unit tests
  if (plans && tasks && (isPlanRoute || isCurrentRoute)) {
    const watchPlans = pickBy(plans, (planReducer) => {
      const plan = planReducer.data
      const planTaskIds = plan.taskIds || []

      const missingTaskIds = Array.isArray(planTaskIds)
        ? planTaskIds.filter((taskId) => {
            return !tasks[taskId]
          })
        : []

      if (missingTaskIds.length) {
        const totalMissingLength = taskIds.length + missingTaskIds.length

        if (totalMissingLength < maxTaskIdWatchers) {
          taskIds = taskIds.concat(missingTaskIds) // TODO: use [...taskIds, ...missingTaskIds] and replace concat everywhere

          return false
        } else {
          return true
        }
      }

      return false
    })

    planIds = Object.keys(watchPlans)
  }

  return {
    taskIds: memoizeFlatArray(taskIds, 'TasksWatcherMapState-taskIds'),
    planIds: memoizeFlatArray(planIds, 'TasksWatcherMapState-planIds'),
  }
}
