import { FIREBASE_FUNCTIONS_BASE_URL } from './sdkConstants'

export const USER_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/user`
export const TEAMS_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/teams`
export const CUSTOM_CLAIM_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/customClaim`
export const TEAM_STAT_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/teamStat`
export const DASHBOARD_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/dashboard`
export const CONFIRM_EMAIL_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/confirmEmail`
export const USER_INVITES_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/userInvites`
export const STRIPE_SUBSCRIPTION_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/stripeSubscription`
export const STRIPE_INVOICES_URL = `${FIREBASE_FUNCTIONS_BASE_URL}/stripeInvoices`
