import { LoginFormStyles } from './LoginFormStyles'
import React, { useState, useCallback } from 'react'
import { LoginFormProps } from './types/LoginFormProps'
import { GoogleSignInButtonConnected } from 'components/button/GoogleSignInButton'
import { Link } from 'components/link/Link'
import { CREATE_ACCOUNT_ROUTE, RESET_PASSWORD_ROUTE } from 'routes'
import { RecaptchaConnected } from 'components/widget/Recaptcha'
import { FormFooter } from 'components/footer/FormFooter'
import { isInvalidEmail } from 'firebaseFunctions/utilities/is/isInvalidEmail'
import { isInvalidPassword } from 'firebaseFunctions/utilities/is/isInvalidPassword'
import { TextInputField } from 'components/field/TextInputField/TextInputField'

export const LoginForm = ({
  showRecaptcha,
  submit,
}: LoginFormProps): JSX.Element => {
  const [emailState, setEmailState] = useState('')
  const [emailErrorState, setEmailErrorState] = useState<string | null>(null)
  const [passwordState, setPasswordState] = useState('')
  const [passwordErrorState, setPasswordErrorState] = useState<string | null>(
    null
  )

  const validateEmail = useCallback(() => {
    const error = isInvalidEmail(emailState)

    setEmailErrorState(error || null)

    return error
  }, [emailState, setEmailErrorState])

  const validatePassword = useCallback(() => {
    const error = isInvalidPassword(passwordState)

    setPasswordErrorState(error || null)

    return error
  }, [passwordState])

  return (
    <form
      className={`LoginForm ${LoginFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const passwordError = validatePassword()
        const emailError = validateEmail()

        if (!emailError && !passwordError) {
          submit({
            email: emailState,
            password: passwordState,
          })
        }
      }}
    >
      <GoogleSignInButtonConnected />

      <TextInputField
        label="Email Address"
        name="email"
        type="email"
        error={emailErrorState}
        onChange={(e) => {
          setEmailErrorState(null)
          setEmailState(e.target.value)
        }}
      />

      <TextInputField
        type="password"
        name="password"
        label="Password"
        error={passwordErrorState}
        onChange={(e) => {
          setPasswordErrorState(null)
          setPasswordState(e.target.value)
        }}
      />

      <p className="links">
        <Link
          to={CREATE_ACCOUNT_ROUTE}
          title="create an account"
          className="createUser"
        >
          create an account
        </Link>
        or
        <Link to={RESET_PASSWORD_ROUTE} title="reset password">
          reset password
        </Link>
      </p>

      {showRecaptcha && <RecaptchaConnected />}

      <FormFooter />
    </form>
  )
}
