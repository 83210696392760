import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { UserRoles } from 'firebaseFunctions/types'
import { MEMBERS_ROUTE } from 'routes'

import { createHistoryAction } from 'actionCreators/createHistoryAction'

import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'

import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import { SUBMIT_DELETE_USER_ROLE, SUBMIT_DELETE_USER_ROLE_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitDeleteUserRoleF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_USER_ROLE])
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state }) || {}
    const teamId = query.teamId
    const userId = action.values.userId || query.userId
    const team = getCurrentTeam({ state })
    const userRoles = team && team.userRoles

    if (userId && teamId && userRoles) {
      const newUserRolesMutable: UserRoles = {
        ...userRoles,
      }

      delete newUserRolesMutable[userId]

      const requestAction = createPatchTeamsRequestAction({
        requestValues: {
          items: {
            [teamId]: {
              data: {
                userRoles: newUserRolesMutable,
              },
            },
          },
        },
      })

      yield put(requestAction)

      yield put(
        createHistoryAction({
          description: 'submitDeleteUserRoleF',
          pathname: MEMBERS_ROUTE,
          query: {
            teamId,
          },
          method: 'replace',
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteUserRoleF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_USER_ROLE_DONE,
    })
  }
}
