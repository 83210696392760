import { Dispatch, Action } from 'redux'
// import { StripeSubscriptionFieldConnectedProps } from './types/StripeSubscriptionFieldConnectedProps'
import { StripeSubscriptionFieldMapDispatchReturnType } from './types/StripeSubscriptionFieldMapDispatchReturnType'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { WELCOME_TO_PRO_ROUTE } from 'routes'

export const StripeSubscriptionFieldMapDispatch = (
  dispatch: Dispatch<Action>
): StripeSubscriptionFieldMapDispatchReturnType => {
  return {
    handleApiError: (error, description: string) => {
      dispatch(createErrorAction({ error, description }))
    },
    redirectNext: (teamId: string) => {
      dispatch(
        createHistoryAction({
          pathname: WELCOME_TO_PRO_ROUTE,
          query: { teamId },
          description: 'StripeSubscriptionFieldMapDispatch',
        })
      )
    },
  }
}
