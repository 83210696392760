import { UNEXPECTED_ERROR } from 'actions'
import { UnexpectedAction } from './types/UnexpectedAction'

interface Props {
  description: string
  silent?: boolean // TODO: make sure this is absolutely needed instead of bad coding
}

export const createUnexpectedErrorAction = ({
  description,
  silent,
}: Props): UnexpectedAction => {
  return {
    type: UNEXPECTED_ERROR,
    description,
    silent,
  }
}
