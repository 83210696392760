import { createSetAlertMessageAction } from './../../../actionCreators/set/SetAlertMessage/createSetAlertMessageAction'
import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { UserRoles } from 'firebaseFunctions/types'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_UPDATE_ROLE, SUBMIT_UPDATE_ROLE_DONE } from 'actions'

export const submitUpdateRoleF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_ROLE])
    const { role } = action.values
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state }) || {}
    const teamId = query.teamId
    const userId = action.values.userId || query.userId
    const team = getCurrentTeam({ state })
    const userRoles = team && team.userRoles

    if (userId && role && teamId && userRoles) {
      const newUserRoles: UserRoles = {
        ...userRoles,
        [userId]: {
          ...userRoles[userId],
          role,
        },
      }

      yield put(
        createPatchTeamsRequestAction({
          requestValues: {
            items: {
              [teamId]: {
                data: {
                  userRoles: newUserRoles,
                },
              },
            },
          },
        })
      )

      yield put(
        createSetAlertMessageAction({
          description: 'submitClosePlanF',
          values: {
            location: 'toast',
            type: 'success',
            message: 'Updated',
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateRoleF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_ROLE_DONE,
    })
  }
}
