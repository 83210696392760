import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { TaskActionListMapDispatchReturnType } from './types/TaskActionListMapDispatchReturnType'

export const TaskActionListMapDispatch = (
  dispatch: Dispatch<Action>
): TaskActionListMapDispatchReturnType => {
  return {
    onUploadStart: () => {
      dispatch(
        createToggleAction({
          description: 'TaskActionListMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },
  }
}
