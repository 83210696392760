import { DashboardNavStyles } from './DashboardNavStyles'
import React from 'react'
import { DashboardNavProps } from './types/DashboardNavProps'
import { ToDoLinkConnected } from 'components/link/ToDoLink'
import { CurrentLinkConnected } from 'components/link/CurrentLink'
import { PlanLinkConnected } from 'components/link/PlanLink'

export const DashboardNav = ({
  dashboardView,
  isMediumScreen,
}: DashboardNavProps): JSX.Element => {
  return (
    <nav className={'DashboardNav ' + DashboardNavStyles}>
      <ul>
        <li className={dashboardView === 'todo' ? 'active' : ''}>
          <ToDoLinkConnected title="to do">
            {isMediumScreen && <span className="count">1.</span>} To Do
          </ToDoLinkConnected>
        </li>

        <li className={dashboardView === 'plan' ? 'active' : ''}>
          <PlanLinkConnected title="plan">
            {isMediumScreen && <span className="count">2.</span>} Plan
          </PlanLinkConnected>
        </li>

        <li className={dashboardView === 'current' ? 'active' : ''}>
          <CurrentLinkConnected title="execute">
            {isMediumScreen && <span className="count">3.</span>} Current
          </CurrentLinkConnected>
        </li>
      </ul>
    </nav>
  )
}
