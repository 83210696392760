import { storageSdk } from 'sdks'
import { getMessageStoragePath } from 'firebaseFunctions/utilities/get/getMessageStoragePath'

interface Props {
  teamId: string
  taskId: string
  messageId: string
}

export const getMessageFiles = async ({
  teamId,
  taskId,
  messageId,
}: Props): Promise<string[]> => {
  const files: string[] = []
  const storagePath = getMessageStoragePath({ teamId, taskId, messageId })

  const ref = await storageSdk()
    .ref()
    .child(storagePath)
    .listAll()

  ref.items.forEach((itemRef) => {
    files.push(itemRef.name)
  })

  return files
}
