import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

import {
  // TaskSizeListConnectedProps,
  TaskSizeListMapStateReturnType,
} from './types'

export const TaskSizeListMapState = (
  state: StateReducers,
  // props: TaskSizeListConnectedProps,
): TaskSizeListMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const taskSizes = team && team.taskSizes

  return {
    taskSizes,
  }
}
