import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentParams } from 'state/getters/getCurrentParams'

import { MessageFileCardConnectedProps } from './types/MessageFileCardConnectedProps'
import { MessageFileCardMapStateReturnType } from './types/MessageFileCardMapStateReturnType'


import { getMessageFileOriginalPath } from 'firebaseFunctions/utilities/get/getMessageFileOriginalPath'
import { getMessageThumbPath } from 'firebaseFunctions/utilities/get/getMessageThumbPath'

export const MessageFileCardMapState = (
  state: StateReducers,
  props: MessageFileCardConnectedProps
): MessageFileCardMapStateReturnType => {
  const params = getCurrentParams({ state })
  const teamId = params?.teamId
  const taskId = params?.taskId
  const { fileName, messageId } = props

  const originalPath =
    fileName && teamId && taskId && messageId
      ? getMessageFileOriginalPath({
          teamId,
          taskId,
          messageId,
          fileName,
        })
      : undefined

  const thumbPath =
    fileName && teamId && taskId && messageId
      ? getMessageThumbPath({
          teamId,
          taskId,
          messageId,
          fileName,
        })
      : undefined

  return {
    thumbPath,
    originalPath,
  }
}
