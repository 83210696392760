import { Plan } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
  planId: string
}

export const getCurrentPlan = ({ state, planId }: Props): Plan | undefined => {
  return state.plans?.[planId]?.data
}
