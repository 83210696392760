// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // CollapsibleDivConnectedProps,
  CollapsibleDivMapStateReturnType,
} from './types'

export const CollapsibleDivMapState = (): // state: StateReducers,
// props: CollapsibleDivConnectedProps,
CollapsibleDivMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {
    random: Math.random(), // always recalculate height when state changes
  }
}
