// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // UploadedFileWatcherConnectedProps,
  UploadedFileWatcherMapStateReturnType,
} from './types'

export const UploadedFileWatcherMapState = (): // state: StateReducers,
// props: UploadedFileWatcherConnectedProps,
UploadedFileWatcherMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
