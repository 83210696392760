import { Dispatch, Action } from 'redux'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import { CurrentLinkMapDispatchReturnType } from './types/CurrentLinkMapDispatchReturnType'
import { createSetScrollPositionAction } from 'actionCreators/set/SetScrollPosition/createSetScrollPositionAction'

export const CurrentLinkMapDispatch = (
  dispatch: Dispatch<Action>
): CurrentLinkMapDispatchReturnType => {
  return {
    clearCurrentScrollPosition: () => {
      dispatch(
        createSetScrollPositionAction({
          description: 'CurrentLinkMapDispatch',
          scrollPosition: {
            currentPosition: 0,
          },
        })
      )
    },
  }
}
