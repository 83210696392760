import React, { useCallback, useEffect } from 'react'
import { NewMessageEditorProps } from './types/NewMessageEditorProps'
import { DraftEditorConnected } from 'components/editor/DraftEditor'
import { NewMessageEditorStyles } from './NewMessageEditorStyles'
import { UploadFilesListConnected } from 'components/list/UploadFilesList'
import { MessagingToolBar } from 'components/bar/MessagingToolBar'
import { debounce } from 'lodash'
import { ReplyMessageCardConnected } from 'components/card/ReplyMessageCard'

export const NewMessageEditor = ({
  save,
  isTouch,
  autoFocus,
  setWorking,
  replyToMessageId,
  setReplyToMessageId,
}: NewMessageEditorProps): JSX.Element => {
  const debouncedSetWorking = useCallback(
    debounce(setWorking, 10000, { leading: true, maxWait: 50 }),
    [setWorking]
  )

  useEffect(() => {
    return () => {
      debouncedSetWorking.cancel()
    }
  }, [debouncedSetWorking])

  return (
    <div className={'NewMessageEditor ' + NewMessageEditorStyles}>
      <UploadFilesListConnected />

      {replyToMessageId && (
        <ReplyMessageCardConnected
          messageId={replyToMessageId}
          onClose={() => {
            setReplyToMessageId?.(null)
          }}
        />
      )}

      <DraftEditorConnected
        save={(markdown) => {
          save(markdown)
          setReplyToMessageId?.(null)
          debouncedSetWorking.cancel()
          setWorking(false)
        }}
        saveOnEnter={!isTouch}
        clearOnSave={true}
        autoFocus={autoFocus || !!replyToMessageId}
        onChange={() => {
          debouncedSetWorking()
        }}
        placeholder="Write a new message"
        footerToolBar={(props) => {
          return (
            <MessagingToolBar
              {...props}
              dropdownId="NewMessageEditor"
              showFileUpload={true}
            />
          )
        }}
      />
    </div>
  )
}
