import { css } from 'emotion'

export const QuestionIconStyles = css`
  font-weight: 800;
  font-size: 1.2em;
  border: 2px solid;
  border-radius: 50%;
  display: inline-block;
  padding: 2px 7px;
`
