// import { PlusSolidIconStyles } from './PlusSolidIconStyles'
import React from 'react'
import { PlusSolidIconProps } from './types/PlusSolidIconProps'
import { iconStyles } from 'styles/iconStyles'

export const PlusSolidIcon = ({
  className = '',
}: PlusSolidIconProps): JSX.Element => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={`PlusSolidIcon ${className} ${iconStyles}`}
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
      ></path>
    </svg>
  )
}
