import { CONFIRM_EMAIL_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PostUserSuccessPayload } from 'firebaseFunctions/user/types/PostUserSuccessPayload'
import { PostConfirmEmailApiProps } from './types/PostConfirmEmailApiProps'

export const postConfirmEmailApi = ({
  values,
  apiHeaders,
}: PostConfirmEmailApiProps): AxiosPromise<PostUserSuccessPayload> => {
  return Axios.post(CONFIRM_EMAIL_URL, values, {
    headers: apiHeaders,
  })
}
