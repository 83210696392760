// import { Dispatch, Action } from 'redux'

import {
  // TaskStatusListConnectedProps,
  TaskStatusListMapDispatchReturnType,
} from './types'

export const TaskStatusListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskStatusListConnectedProps,
): TaskStatusListMapDispatchReturnType => {
  return {
  }
}
