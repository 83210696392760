import { take, put, select, spawn } from 'redux-saga/effects'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_UPDATE_MESSAGE } from 'actions'
import { patchMessagesSaga } from 'flows/sagas/patch/patchMessagesSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { SubmitEditorAction } from 'actionCreators/submit/SubmitEditor'

export const submitUpdateMessageF = function*() {
  while (1) {
    const action: SubmitEditorAction = yield take([SUBMIT_UPDATE_MESSAGE])
    const { markdown, id } = action.values
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state })
    const taskId = query?.taskId
    const teamId = query?.teamId

    if (id && taskId && markdown && teamId) {
      const items: MessagesReducer = {
        [id]: {
          data: {
            description: markdown,
          },
          taskId,
          teamId,
        },
      }

      yield spawn(patchMessagesSaga, {
        items,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateMessageF',
        })
      )
    }
  }
}
