import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TaskFilters } from 'firebaseFunctions/types'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_SET_DEFAULT_FILTER, SUBMIT_TASK_FILTER_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createPatchUserExtensionRequestDelayAction } from 'actionCreators/patch/PatchUserExtension/createPatchUserExtensionRequestDelayAction'

export const submitSetDefaultFilterF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_SET_DEFAULT_FILTER])
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const userId = state.currentUserId
    const { summary, tagIds, taskStatuses } = action.values
    const isEmpty = !summary && !tagIds && !taskStatuses

    const defaultTaskFilters: TaskFilters | null = isEmpty
      ? null
      : {
          summary: summary || null,
          tagIds: tagIds || null,
          taskStatuses: taskStatuses || null,
        }

    if (teamId && userId) {
      yield put(
        createPatchUserExtensionRequestDelayAction({
          requestValues: {
            params: {
              userId,
              teamId,
            },
            item: {
              defaultTaskFilters,
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskFilterF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_FILTER_DONE,
    })
  }
}
