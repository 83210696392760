import { css } from 'emotion'
import { TEXT_COLOR_LIGHT, FONT_WEIGHT_BOLDER } from 'constants/styleConstants'

export const UploadedFilesMainStyles = css`
  .usage {
    label {
      font-size: 0.8em;
      color: ${TEXT_COLOR_LIGHT};
      font-weight: ${FONT_WEIGHT_BOLDER};
    }
  }

  .UploadedFileList,
  .UploadedFileWatcher {
    margin-top: 32px;
  }
`
