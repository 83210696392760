import { css } from 'emotion'
import { FONT_WEIGHT_BOLD } from 'constants/styleConstants'

export const TaskSummaryMainStyles = css`
  position: relative;
  .UserWorkingWidget {
    display: flex;
    position: absolute;
    padding: 16px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }

  .TaskSummaryField .TextAreaInput {
    font-weight: ${FONT_WEIGHT_BOLD};
  }
`
