import { take, put } from 'redux-saga/effects'
import { firebaseSdk, UserCredential } from 'sdks'
import { POST_USER_REQUEST } from 'actions'
import { PUBLIC_URL } from 'constants/environmentConstants'
import { SELECT_TEAM_ROUTE } from 'routes'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { PostUserRequestAction } from 'actionCreators/post/PostUser/types/PostUserRequestAction'
import { createPostUserSuccessAction } from 'actionCreators/post/PostUser/createPostUserSuccessAction'
import { createPostUserFailureAction } from 'actionCreators/post/PostUser/createPostUserFailureAction'
import { createActionCodeSettings } from '../../firebaseFunctions/utilities/createActionCodeSettings'

export const postUserRequestF = function*() {
  while (1) {
    const postUserRequestAction: PostUserRequestAction = yield take([
      POST_USER_REQUEST,
    ])

    const { requestValues } = postUserRequestAction
    const { email, password, displayName } = requestValues

    if (email && password && displayName) {
      try {
        const userCredential: UserCredential = yield firebaseSdk
          .auth()
          .createUserWithEmailAndPassword(email, password)

        if (userCredential.user) {
          const actionCodeSettings = createActionCodeSettings({
            publicUrl: PUBLIC_URL,
            route: SELECT_TEAM_ROUTE,
          })

          const { uid } = userCredential.user

          yield userCredential.user.updateProfile({
            displayName,
          })

          yield userCredential.user.sendEmailVerification(actionCodeSettings)

          const users = {
            [uid]: {
              email,
              displayName,
            },
          }

          yield put(
            createPostUserSuccessAction({
              requestValues,
              successValues: {
                items: users,
              },
            })
          )
        }
      } catch (error) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'postUserRequestF',
          })
        )

        yield put(
          createPostUserFailureAction({
            requestValues,
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'postUserRequestF',
        })
      )
    }
  }
}
