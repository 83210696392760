import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsEndToEndTesting } from 'state/getters/getIsEndToEndTesting'

import {
  // ServerErrorConnectedProps,
  ServerErrorMapStateReturnType,
} from './types'

export const ServerErrorMapState = (
  state: StateReducers
  // props: ServerErrorConnectedProps,
): ServerErrorMapStateReturnType => {
  const alertMessage = state.alertMessage || undefined
  // TODO: currently an error happens but user is redirected and the error is destroyed before the user can even see it, possibility do not reset toggles on location change?
  const isEndToEndTesting = getIsEndToEndTesting({ state })
  const message = alertMessage?.message
  const type = alertMessage?.type
  const status = alertMessage?.status

  return {
    showDetails: isEndToEndTesting,
    message,
    type,
    status,
  }
}
