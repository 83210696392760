import { createLogger } from 'redux-logger'

import { INCREMENT_WATCHER } from 'actions'

export const reduxLogger = createLogger({
  predicate: (_getState, action) => {
    const { type } = action
    // return true
    return type !== INCREMENT_WATCHER
  },
})
