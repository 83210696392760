import React, { useCallback } from 'react'
import { ToNextCycleButtonProps } from './types/ToNextCycleButtonProps'
import { ActionButton } from '../ActionButton'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { LevelUpAltIcon } from '../../icon/LevelUpAltIcon/LevelUpAltIcon'
import { PLAN_NOUN } from '../../../constants/english'

export const ToNextCycleButton = ({
  moveToNextCycle,
  taskId,
  className = '',
}: ToNextCycleButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    moveToNextCycle(taskId)
  }, [taskId, moveToNextCycle])

  return (
    <ActionButton
      className={`ToNextCycleButton ${className}`}
      onClick={onClickCallback}
      title={`move to Next ${PLAN_NOUN}`}
    >
      <IconAndDiv icon={<LevelUpAltIcon />}>Next {PLAN_NOUN}</IconAndDiv>
    </ActionButton>
  )
}
