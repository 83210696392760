import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // UserAccountHeaderConnectedProps,
  UserAccountHeaderMapStateReturnType,
} from './types'

export const UserAccountHeaderMapState = (
  state: StateReducers
  // props: UserAccountHeaderConnectedProps,
): UserAccountHeaderMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const showAccountDropdown = showDropdown && showDropdown.type === 'account'
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    showAccountDropdown,
    isMediumScreen,
  }
}
