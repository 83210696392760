import { connect } from 'react-redux'
import { TeamActionList } from './TeamActionList'
import { TeamActionListMapState } from './TeamActionListMapState'
import { TeamActionListMapDispatch } from './TeamActionListMapDispatch'

import { TeamActionListMapStateReturnType } from './types/TeamActionListMapStateReturnType'
import { TeamActionListMapDispatchReturnType } from './types/TeamActionListMapDispatchReturnType'
import { TeamActionListConnectedProps } from './types/TeamActionListConnectedProps'


export const TeamActionListConnected = connect<
  TeamActionListMapStateReturnType,
  TeamActionListMapDispatchReturnType,
  TeamActionListConnectedProps>(TeamActionListMapState,
    TeamActionListMapDispatch)(TeamActionList)
