import { connect } from 'react-redux'
import { MessageNotificationDropdown } from './MessageNotificationDropdown'
import { MessageNotificationDropdownMapState } from './MessageNotificationDropdownMapState'
import { MessageNotificationDropdownMapDispatch } from './MessageNotificationDropdownMapDispatch'
import { MessageNotificationDropdownMapStateReturnType } from './types/MessageNotificationDropdownMapStateReturnType'
import { MessageNotificationDropdownMapDispatchReturnType } from './types/MessageNotificationDropdownMapDispatchReturnType'
import { MessageNotificationDropdownConnectedProps } from './types/MessageNotificationDropdownConnectedProps'

export const MessageNotificationDropdownConnected = connect<
  MessageNotificationDropdownMapStateReturnType,
  MessageNotificationDropdownMapDispatchReturnType,
  MessageNotificationDropdownConnectedProps
>(
  MessageNotificationDropdownMapState,
  MessageNotificationDropdownMapDispatch
)(MessageNotificationDropdown)
