// import { Dispatch, Action } from 'redux'

import {
  // TaskMainConnectedProps,
  TaskMainMapDispatchReturnType,
} from './types'

export const TaskMainMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskMainConnectedProps,
): TaskMainMapDispatchReturnType => {
  return {
  }
}
