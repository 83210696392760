import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // NewMessageEditorConnectedProps,
  NewMessageEditorMapStateReturnType,
} from './types'

export const NewMessageEditorMapState = (
  state: StateReducers
  // props: NewMessageEditorConnectedProps,
): NewMessageEditorMapStateReturnType => {
  const isTouch = state.windowMeta?.isTouch

  return {
    isTouch,
  }
}
