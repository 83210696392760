// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // ModalButtonConnectedProps,
  ModalButtonMapStateReturnType,
} from './types'

export const ModalButtonMapState = (): // state: StateReducers,
// props: ModalButtonConnectedProps,
ModalButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
