import { StripeSubscriptionDropdownStyles } from './StripeSubscriptionDropdownStyles'
import React from 'react'
import { StripeSubscriptionDropdownProps } from './types/StripeSubscriptionDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { EllipsisHorizontalIcon } from 'components/icon/EllipsisHorizontalIcon/EllipsisHorizontalIcon'
import { StripeSubscriptionActionList } from 'components/list/StripeSubscriptionActionList/StripeSubscriptionActionList'

export const StripeSubscriptionDropdown = ({
  showStripeSubscriptionDropdown,
  stripeSubscriptionId,
  handleDeletedStripeSubscription,
}: StripeSubscriptionDropdownProps): JSX.Element => {
  return (
    <div
      className={
        `StripeSubscriptionDropdown _${stripeSubscriptionId} ` +
        StripeSubscriptionDropdownStyles
      }
    >
      <DropdownButtonConnected
        dropdownType="stripeSubscription"
        watchSelector={`.StripeSubscriptionDropdown._${stripeSubscriptionId}`}
        id={stripeSubscriptionId}
        title="More options"
      >
        <EllipsisHorizontalIcon />
      </DropdownButtonConnected>

      {showStripeSubscriptionDropdown && (
        <DropdownConnected>
          {stripeSubscriptionId && (
            <StripeSubscriptionActionList
              stripeSubscriptionId={stripeSubscriptionId}
              handleDeletedStripeSubscription={handleDeletedStripeSubscription}
            />
          )}
        </DropdownConnected>
      )}
    </div>
  )
}
