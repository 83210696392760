import { StateReducers } from 'state/reducers/types/StateReducers'
import { CurrentUserReducer } from 'state/reducers/types/CurrentUserReducer'

interface Props {
  state: StateReducers
}

export const getCurrentUser = ({
  state,
}: Props): CurrentUserReducer | undefined => {
  return state.currentUser || undefined
}
