import { take, put } from 'redux-saga/effects'
import { firebaseSdk } from 'sdks'
import { CustomClaim } from 'firebaseFunctions/types/CustomClaim'
import { REFRESH_TOKEN } from 'actions'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createGetCustomClaimSuccessAction } from 'actionCreators/get/GetCustomClaim/createGetCustomClaimSuccessAction'

export const refreshTokenF = function*() {
  while (1) {
    yield take([REFRESH_TOKEN])
    const currentUser = firebaseSdk.auth().currentUser

    if (currentUser) {
      const refreshTokenResult = yield currentUser.getIdTokenResult(true)

      yield put(
        createGetCustomClaimSuccessAction({
          requestValues: {},
          successValues: {
            item: refreshTokenResult.claims as CustomClaim,
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'refreshTokenF',
        })
      )
    }
  }
}
