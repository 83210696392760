import { SET_WINDOW_META } from 'actions'
import { SetWindowMetaAction } from './types/SetWindowMetaAction'
import { WindowMetaR } from '../state/reducers/types/WindowMetaR'

interface Props {
  values: WindowMetaR
  permissionState?: PermissionState
  description: string
}

export const createSetWindowMetaAction = ({
  values,
  description,
}: Props): SetWindowMetaAction => {
  return {
    type: SET_WINDOW_META,
    values,
    description,
  }
}
