import { css } from 'emotion'
// import { PRIMARY_BACKGROUND_COLOR } from 'constants/styleConstants'

export const ActivityNotificationDropdownStyles = css`
  position: relative;
  height: 25px;

  .DropdownButton,
  .BellSolidIcon {
    height: 25px;
  }

  > button,
  > .CustomLink {
    position: relative;
    padding: 0 4px;

    .CountBubbleWidget {
      position: absolute;
      top: -8px;
      right: -4px;
    }
  }

  .NotificationList {
    .title {
      font-size: 0.75em;
    }
  }

  .Dropdown {
    .chevronContainer {
      right: 3px;
    }

    .container {
      right: -97px;
    }
  }

  &.isMediumScreen {
    .Dropdown {
      .container {
        right: -20px;
        width: 380px;
      }
    }
  }
`
