import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // UploadedFileListConnectedProps,
  UploadedFileListMapStateReturnType,
} from './types'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { getSortedByBytes } from 'utilities/getSortedByBytes'

export const UploadedFileListMapState = (
  state: StateReducers
  // props: UploadedFileListConnectedProps,
): UploadedFileListMapStateReturnType => {
  const uploadedFiles = state.uploadedFiles || undefined

  const ids = getSortedByBytes({
    itemsObject: uploadedFiles,
    reverse: true,
  })

  return {
    uploadedFileIds: memoizeFlatArray(
      ids,
      'UploadedFileListMapState-uploadedFileIds'
    ),
  }
}
