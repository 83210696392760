// import { Dispatch, Action } from 'redux'

import {
  // PageSpinnerWidgetConnectedProps,
  PageSpinnerWidgetMapDispatchReturnType,
} from './types'

export const PageSpinnerWidgetMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: PageSpinnerWidgetConnectedProps,
): PageSpinnerWidgetMapDispatchReturnType => {
  return {
  }
}
