import { StateReducers } from 'state/reducers/types/StateReducers'
import { QuickSearchFieldConnectedProps } from './types/QuickSearchFieldConnectedProps'
import { QuickSearchFieldMapStateReturnType } from './types/QuickSearchFieldMapStateReturnType'
import { getCurrentUserExtension } from 'state/getters/getCurrentUserExtension'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { forEach } from 'lodash'
import { getCurrentTeamId } from '../../../state/getters/getCurrentTeamId'
import { FirebaseTimestamp } from '../../../sdks'
import { getCurrentTeamReducer } from '../../../state/getters/getCurrentTeamReducer'
import { getPageSizeFromState } from '../../../state/getters/getPageSizeFromState'

export const QuickSearchFieldMapState = (
  state: StateReducers,
  _props: QuickSearchFieldConnectedProps
): QuickSearchFieldMapStateReturnType => {
  let historyTaskIdsMutable: string[] = []
  let summaryItemsMutable: string[] = []
  const usedHistoryTaskIds = {}
  const teamReducer = getCurrentTeamReducer({ state })
  const taskPage = teamReducer?.taskPage
  let lowestWatchedTaskCreatedSeconds: number | undefined = undefined
  let lowestWatchedTaskCreatedNanoSeconds: number | undefined = undefined
  const pageSize = getPageSizeFromState({ state })
  const userExtension = getCurrentUserExtension({ state })
  const teamId = getCurrentTeamId({ state })
  const taskHistoryItems = userExtension?.taskHistoryItems || []

  taskHistoryItems.forEach(({ taskId, taskSummary }) => {
    if (taskId && taskSummary) {
      historyTaskIdsMutable.push(taskId)
      summaryItemsMutable.push(taskSummary)

      usedHistoryTaskIds[taskId] = true
    }
  })

  const taskReducers = state.tasks

  forEach(taskReducers, (taskReducer, taskId) => {
    const { summary, created } = taskReducer.data

    if (taskReducer.teamWatched) {
      const createdSeconds =
        created instanceof FirebaseTimestamp ? created.seconds : undefined
      const createdNanoSeconds =
        created instanceof FirebaseTimestamp ? created.nanoseconds : undefined

      if (createdSeconds) {
        const isLowest =
          !lowestWatchedTaskCreatedSeconds ||
          createdSeconds < lowestWatchedTaskCreatedSeconds

        if (isLowest) {
          lowestWatchedTaskCreatedSeconds = createdSeconds
          lowestWatchedTaskCreatedNanoSeconds = createdNanoSeconds
        }
      }
    }

    if (summary && !usedHistoryTaskIds[taskId]) {
      historyTaskIdsMutable.push(taskId)
      summaryItemsMutable.push(summary)
    }
  })

  return {
    teamId,
    taskPage,
    pageSize,
    lowestWatchedTaskCreatedSeconds,
    lowestWatchedTaskCreatedNanoSeconds,
    taskIds: memoizeFlatArray(
      historyTaskIdsMutable,
      'HistoryListMapState-taskIds'
    ),
    summaryItems: memoizeFlatArray(
      summaryItemsMutable,
      'HistoryListMapState-summaryItems'
    ),
  }
}
