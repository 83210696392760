import { connect } from 'react-redux'
import { NewMessageFileField } from './NewMessageFileField'
import { NewMessageFileFieldMapState } from './NewMessageFileFieldMapState'
import { NewMessageFileFieldMapDispatch } from './NewMessageFileFieldMapDispatch'

import { NewMessageFileFieldMapStateReturnType } from './types/NewMessageFileFieldMapStateReturnType'
import { NewMessageFileFieldMapDispatchReturnType } from './types/NewMessageFileFieldMapDispatchReturnType'
import { NewMessageFileFieldConnectedProps } from './types/NewMessageFileFieldConnectedProps'


export const NewMessageFileFieldConnected = connect<
  NewMessageFileFieldMapStateReturnType,
  NewMessageFileFieldMapDispatchReturnType,
  NewMessageFileFieldConnectedProps>(NewMessageFileFieldMapState,
    NewMessageFileFieldMapDispatch)(NewMessageFileField)
