import { POST_TASKS_SUCCESS } from 'actions'

import { PostTasksSuccessAction } from './types/PostTasksSuccessAction'
import { PostTasksSuccessActionValues } from './types/PostTasksSuccessActionValues'
import { PostTasksRequestActionValues } from './types/PostTasksRequestActionValues'


interface Props {
  successValues: PostTasksSuccessActionValues
  requestValues?: PostTasksRequestActionValues
}

export const createPostTasksSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostTasksSuccessAction => {
  return {
    type: POST_TASKS_SUCCESS,
    requestValues,
    successValues,
  }
}
