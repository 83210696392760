import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { TASK_SIZES_ROUTE } from 'routes'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import {
  SUBMIT_UPDATE_TASK_SIZES,
  SUBMIT_UPDATE_TASK_SIZES_DONE,
} from 'actions'
import { parseCsvString } from 'utilities/parseCsvString'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitUpdateTaskSizesF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_TASK_SIZES])
    const { taskSizesCsv } = action.values
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state })
    const teamId = query && query.teamId

    if (teamId && taskSizesCsv) {
      const taskSizes = parseCsvString(taskSizesCsv)

      yield put(
        createPatchTeamsRequestAction({
          requestValues: {
            items: {
              [teamId]: {
                data: {
                  taskSizes, // TODO: create a method to make this more robust
                },
              },
            },
          },
        })
      )

      yield put(
        createHistoryAction({
          description: 'submitUpdateTaskSizesF',
          pathname: TASK_SIZES_ROUTE,
          query,
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateTaskSizesF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_TASK_SIZES_DONE,
    })
  }
}
