import { css } from 'emotion'

export const TestingMainStyles = css`
  display: none;
  position: fixed;
  bottom: 0;
  padding: 16px;
  background-color: #f3f3c7;

  > button {
    margin: 4px;
  }
`
