import { connect } from 'react-redux'
import { UserInvitesMain } from './UserInvitesMain'
import { UserInvitesMainMapState } from './UserInvitesMainMapState'
import { UserInvitesMainMapDispatch } from './UserInvitesMainMapDispatch'

import { UserInvitesMainMapStateReturnType } from './types/UserInvitesMainMapStateReturnType'
import { UserInvitesMainMapDispatchReturnType } from './types/UserInvitesMainMapDispatchReturnType'
import { UserInvitesMainConnectedProps } from './types/UserInvitesMainConnectedProps'


export const UserInvitesMainConnected = connect<
  UserInvitesMainMapStateReturnType,
  UserInvitesMainMapDispatchReturnType,
  UserInvitesMainConnectedProps>(UserInvitesMainMapState,
    UserInvitesMainMapDispatch)(UserInvitesMain)
