import { put } from 'redux-saga/effects'
import { patchUserInvitesApi } from 'apis/patch/patchUserInvitesApi'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { PatchUserInvitesSuccessPayload } from 'firebaseFunctions/userInvites/types/PatchUserInvitesSuccessPayload'
import { AxiosResponse } from 'axios'
import { createPatchUserInvitesRequestAction } from 'actionCreators/patch/PatchUserInvites/createPatchUserInvitesRequestAction'
import { createPatchUserInvitesSuccessAction } from 'actionCreators/patch/PatchUserInvites/createPatchUserInvitesSuccessAction'
import { createPatchUserInvitesFailureAction } from 'actionCreators/patch/PatchUserInvites/createPatchUserInvitesFailureAction'
import { PatchUserInvitesApiProps } from 'apis/patch/types/PatchUserInvitesApiProps'
import { createPatchCustomClaimSuccessAction } from 'actionCreators/patch/PatchCustomClaim/createPatchCustomClaimSuccessAction'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'

interface Props extends PatchUserInvitesApiProps {}

export const patchUserInvitesSaga = function*({ values, apiHeaders }: Props) {
  const { item } = getObjectFirstItem(values.items)
  const teamId = item?.data.teamId

  if (!teamId) {
    yield put(
      createErrorAction({
        description: 'postTeamsSaga !teamId',
      })
    )

    return
  }

  yield put(
    createPatchUserInvitesRequestAction({
      requestValues: values,
    })
  )

  try {
    const results: AxiosResponse<PatchUserInvitesSuccessPayload> = yield patchUserInvitesApi(
      {
        apiHeaders,
        values: values,
      }
    )

    yield put(
      createPatchUserInvitesSuccessAction({
        requestValues: values,
        successValues: results.data,
      })
    )

    yield put(
      createPatchCustomClaimSuccessAction({
        requestValues: { teamId },
        successValues: {
          item: results.data.customClaim,
        },
        description: 'patchUserInvitesSaga',
      })
    )
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'patchUserInvitesSaga',
      })
    )

    yield put(
      createPatchUserInvitesFailureAction({
        requestValues: values,
      })
    )
  }
}
