import * as React from 'react'
import { Spinner } from './Spinner'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const SpinnerE = () => {
  return (
    <div className="SpinnerE exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <Spinner />
      </Provider>

      <h1 className="exampleHeader">size="large"</h1>

      <Provider store={createMockStore(mockState)}>
        <Spinner size="large" />
      </Provider>
    </div>
  )
}
