import { FirestoreClient, Message } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'
import { getMessagesRef } from './getMessagesRef'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  taskId: string
  messageId: string
}

export const getMessageRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  taskId,
  messageId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<Message>
  : FirebaseFirestore.DocumentReference => {
  return getMessagesRef({ firestore, teamId, taskId }).doc(messageId) as any
}
