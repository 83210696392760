import { submitAcceptInviteF } from './submitAcceptInviteF'
import { submitAvatarUploadF } from './submitAvatarUploadF'
import { submitDeleteFlows } from './delete/submitDeleteFlows'
import { submitLoginF } from './submitLoginF'
import { submitNewFlows } from './new/submitNewFlows'
import { submitResetPasswordF } from './submitResetPasswordF'
import { submitTaskDescriptionF } from './submitTaskDescriptionF'
import { submitCloneTaskF } from './submitCloneTaskF'
import { submitTaskFilesF } from './submitTaskFilesF'
import { submitSetDefaultFilterF } from './submitSetDefaultFilterF'
import { submitTaskStatusF } from './submitTaskStatusF'
import { submitTaskSummaryF } from './submitTaskSummaryF'
import { submitTaskTagF } from './submitTaskTagF'
import { submitTeamNameF } from './submitTeamNameF'
import { submitUpdateFlows } from './update/submitUpdateFlows'
import { submitTaskPlanDropF } from './submitTaskPlanDropF'
import { submitTaskReorderDropF } from './submitTaskReorderDropF'
import { submitReopenPlanF } from './submitReopenPlanF'
import { submitClosePlanF } from './submitClosePlanF'
import { submitTaskStatusDropF } from './submitTaskStatusDropF'
import { submitReadAllF } from './submitReadAllF'
import { submitReadF } from './submitReadF'
import { submitTaskDueDateF } from './submitTaskDueDateF'
import { submitTaskSizeF } from './submitTaskSizeF'
import { submitStatusColorF } from './submitStatusColorF'
import { submitMoveTaskToCycleF } from './submitMoveTaskToCycleF'
import { submitWorkingOnTaskSummaryF } from './submitWorkingOnTaskSummaryF'
import { submitWorkingOnTaskDescriptionF } from './submitWorkingOnTaskDescriptionF'
import { submitWorkingOnTaskMessageF } from './submitWorkingOnTaskMessageF'
import { submitLeaveTeamF } from './submitLeaveTeamF'

export const submitFlows = [
  ...submitDeleteFlows,
  ...submitUpdateFlows,
  ...submitNewFlows,
  submitLoginF,
  submitTaskDescriptionF,
  submitTaskStatusF,
  submitTaskSummaryF,
  submitAvatarUploadF,
  submitTaskTagF,
  submitAcceptInviteF,
  submitResetPasswordF,
  submitTeamNameF,
  submitTaskFilesF,
  submitSetDefaultFilterF,
  submitTaskPlanDropF,
  submitTaskReorderDropF,
  submitReopenPlanF,
  submitClosePlanF,
  submitTaskStatusDropF,
  submitReadAllF,
  submitTaskDueDateF,
  submitReadF,
  submitTaskSizeF,
  submitStatusColorF,
  submitMoveTaskToCycleF,
  submitCloneTaskF,
  submitWorkingOnTaskSummaryF,
  submitWorkingOnTaskDescriptionF,
  submitWorkingOnTaskMessageF,
  submitLeaveTeamF,
]
