import { StateReducers } from 'state/reducers/types/StateReducers'
import { TeamCardConnectedProps } from './types/TeamCardConnectedProps'
import { TeamCardMapStateReturnType } from './types/TeamCardMapStateReturnType'

export const TeamCardMapState = (
  state: StateReducers,
  props: TeamCardConnectedProps
): TeamCardMapStateReturnType => {
  const unreadCount =
    (props.teamId &&
      state.currentUnscopeUser?.teamUnreadCounts?.[props.teamId]) ||
    0

  return {
    unreadCount,
  }
}
