import { StateReducers } from 'state/reducers/types/StateReducers'
import { TeamTasksSingleWatcherConnectedProps } from './types/TeamTasksSingleWatcherConnectedProps'
import { TeamTasksSingleWatcherMapStateReturnType } from './types/TeamTasksSingleWatcherMapStateReturnType'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'
import { getPageSizeFromState } from '../../../state/getters/getPageSizeFromState'

export const TeamTasksSingleWatcherMapState = (
  state: StateReducers,
  _props: TeamTasksSingleWatcherConnectedProps
): TeamTasksSingleWatcherMapStateReturnType => {
  const teamReducer = getCurrentTeamReducer({ state })
  const taskPage = teamReducer?.taskPage
  const pageSize = getPageSizeFromState({ state })

  return {
    taskPage,
    pageSize,
  }
}
