import React from 'react'
import { ToDoLinkProps } from './types/ToDoLinkProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { DASHBOARD_HOME_ROUTE } from 'routes'

export const ToDoLink = ({
  clearTodoScrollPosition,
  children,
}: ToDoLinkProps): JSX.Element => {
  return (
    <CustomLinkConnected withCurrentQuery={true} route={DASHBOARD_HOME_ROUTE} title="to do list" onClick={clearTodoScrollPosition}>{children}</CustomLinkConnected>
  )
}
