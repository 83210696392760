import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskTagCardConnectedProps } from './types/TaskTagCardConnectedProps'
import { TaskTagCardMapStateReturnType } from './types/TaskTagCardMapStateReturnType'

import { getTaskTag } from 'state/getters/getTaskTag'

export const TaskTagCardMapState = (
  state: StateReducers,
  props: TaskTagCardConnectedProps
): TaskTagCardMapStateReturnType => {
  const { taskId, tagId } = props
  const tag = getTaskTag({ state, taskId, tagId })
  const tagType = tag?.type
  const displayName = tag?.displayName

  return {
    tagType,
    displayName,
  }
}
