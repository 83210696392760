import { getIsBigScreen } from 'state/getters/getIsBigScreen'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { HeaderOneTextConnectedProps } from './types/HeaderOneTextConnectedProps'
import { HeaderOneTextMapStateReturnType } from './types/HeaderOneTextMapStateReturnType'
import { getIsMediumScreen } from '../../../state/getters/getIsMediumScreen'

export const HeaderOneTextMapState = (
  state: StateReducers,
  _props: HeaderOneTextConnectedProps
): HeaderOneTextMapStateReturnType => {
  const isMediumScreen = getIsMediumScreen({ state })
  const isBigScreen = getIsBigScreen({ state })

  return {
    isMediumScreen,
    isBigScreen,
  }
}
