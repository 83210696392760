import { PATCH_USER_EXTENSION_SUCCESS } from 'actions'

import { PatchUserExtensionSuccessAction } from './types/PatchUserExtensionSuccessAction'
import { PatchUserExtensionSuccessActionValues } from './types/PatchUserExtensionSuccessActionValues'
import { PatchUserExtensionRequestActionValues } from './types/PatchUserExtensionRequestActionValues'


interface Props {
  successValues: PatchUserExtensionSuccessActionValues
  requestValues: PatchUserExtensionRequestActionValues
}

export const createPatchUserExtensionSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchUserExtensionSuccessAction => {
  return {
    type: PATCH_USER_EXTENSION_SUCCESS,
    requestValues,
    successValues,
  }
}
