import * as React from 'react'
import { TeamStatWatcherProps } from './types/TeamStatWatcherProps'
import { firestore } from 'sdks'
import { getTeamStatRef } from '../../../firebaseFunctions/utilities/ref/get/getTeamStatRef'

export class TeamStatWatcher extends React.Component {
  props: TeamStatWatcherProps
  unsubscribe?: () => void

  componentWillUnmount() {
    this.unsubscribeAll()
  }

  unsubscribeAll() {
    if (this.unsubscribe) {
      const { incrementWatcher } = this.props

      incrementWatcher(-1)

      this.unsubscribe()
      this.unsubscribe = undefined
    }
  }

  public render(): null {
    this.unsubscribeAll()

    const {
      teamId,
      onNext,
      onFirebaseError,
      incrementWatcher,
      canRead,
    } = this.props

    if (canRead && !this.unsubscribe) {
      this.unsubscribe = getTeamStatRef({ firestore, teamId }).onSnapshot(
        onNext,
        onFirebaseError
      )

      incrementWatcher(1)
    }

    return null
  }
}
