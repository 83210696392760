import { getTaskStoragePath } from './getTaskStoragePath'

interface Props {
  teamId: string
  taskId: string
  fileName: string
}

export const getTaskOriginalPath = ({
  teamId,
  taskId,
  fileName,
}: Props): string => {
  const path = getTaskStoragePath({ teamId, taskId })

  return `${path}${fileName}`
}
