// import { DeleteTaskButtonStyles } from './DeleteTaskButtonStyles'
import React, { useCallback } from 'react'
import { DeleteTaskButtonProps } from './types/DeleteTaskButtonProps'
import { DeleteAndConfirmWidget } from '../../widget/DeleteAndConfirmWidget'

export const DeleteTaskButton = ({
  deleteTask,
  taskId,
  ...props
}: DeleteTaskButtonProps): JSX.Element => {
  const onDeleteClick = useCallback(() => {
    if (taskId) {
      deleteTask(taskId)
    }
  }, [taskId, deleteTask])

  return <DeleteAndConfirmWidget onDeleteClick={onDeleteClick} {...props} />
}
