import { connect } from 'react-redux'
import { TaskFileList } from './TaskFileList'
import { TaskFileListMapState } from './TaskFileListMapState'
import { TaskFileListMapDispatch } from './TaskFileListMapDispatch'

import { TaskFileListMapStateReturnType } from './types/TaskFileListMapStateReturnType'
import { TaskFileListMapDispatchReturnType } from './types/TaskFileListMapDispatchReturnType'
import { TaskFileListConnectedProps } from './types/TaskFileListConnectedProps'


export const TaskFileListConnected = connect<
  TaskFileListMapStateReturnType,
  TaskFileListMapDispatchReturnType,
  TaskFileListConnectedProps
>(
  TaskFileListMapState,
  TaskFileListMapDispatch
)(TaskFileList)
