import { StateReducers } from 'state/reducers/types/StateReducers'
import { getNotifications } from 'state/getters/getNotifications'
// import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import { NotificationListConnectedProps } from './types/NotificationListConnectedProps'
import { NotificationListMapStateReturnType } from './types/NotificationListMapStateReturnType'


export const NotificationListMapState = (
  state: StateReducers,
  props: NotificationListConnectedProps,
): NotificationListMapStateReturnType => {
  const { notificationGroup } = props
  const notifications = getNotifications({ state, notificationGroup })

  return {
    length: notifications.length,
  }
}
