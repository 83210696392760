import { PATCH_MESSAGES_REQUEST } from 'actions'
import { PatchMessagesRequestAction } from './types/PatchMessagesRequestAction'
import { PatchMessagesRequestActionValues } from './types/PatchMessagesRequestActionValues'

interface Props {
  requestValues: PatchMessagesRequestActionValues,
}

export const createPatchMessagesRequestAction = ({ requestValues }: Props): PatchMessagesRequestAction => {
  return {
    type: PATCH_MESSAGES_REQUEST,
    requestValues,
  }
}
