import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR, BORDER_RADIUS } from 'constants/styleConstants'

export const TaskChangesMainStyles = css`
  .showChangeHistory {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
  }

  .nextPrevNav {
    margin: 16px;

    button {
      border: 1px solid ${NEUTRAL_BORDER_COLOR};
      border-radius: ${BORDER_RADIUS};
      padding: 8px 12px;
    }
  }

  &.isBigScreen {
    .showChangeHistory {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
