// import { Dispatch, Action } from 'redux'
// import { createSetScrollPositionAction } from 'actionCreators/set/SetScrollPosition/createSetScrollPositionAction'

// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // TaskPlanSpanConnectedProps,
  TaskPlanSpanMapDispatchReturnType,
} from './types'

export const TaskPlanSpanMapDispatch = (): // dispatch: Dispatch<Action>,
// props: TaskPlanSpanConnectedProps,
TaskPlanSpanMapDispatchReturnType => {
  return {}
}
