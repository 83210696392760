// import { Dispatch, Action } from 'redux'

import {
  // TagDisplayNameSpanConnectedProps,
  TagDisplayNameSpanMapDispatchReturnType,
} from './types'

export const TagDisplayNameSpanMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TagDisplayNameSpanConnectedProps,
): TagDisplayNameSpanMapDispatchReturnType => {
  return {
  }
}
