import { connect } from 'react-redux'
import { TagDropdownMain } from './TagDropdownMain'
import { TagDropdownMainMapState } from './TagDropdownMainMapState'
import { TagDropdownMainMapDispatch } from './TagDropdownMainMapDispatch'
import { TagDropdownMainMapStateReturnType } from './types/TagDropdownMainMapStateReturnType'
import { TagDropdownMainMapDispatchReturnType } from './types/TagDropdownMainMapDispatchReturnType'
import { TagDropdownMainConnectedProps } from './types/TagDropdownMainConnectedProps'

export const TagDropdownMainConnected = connect<
  TagDropdownMainMapStateReturnType,
  TagDropdownMainMapDispatchReturnType,
  TagDropdownMainConnectedProps>(TagDropdownMainMapState,
    TagDropdownMainMapDispatch)(TagDropdownMain)
