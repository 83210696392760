 import { StateReducers } from 'state/reducers/types/StateReducers'
import { FeedbackFormConnectedProps } from './types/FeedbackFormConnectedProps'
import { FeedbackFormMapStateReturnType } from './types/FeedbackFormMapStateReturnType'

export const FeedbackFormMapState = (
  _state: StateReducers,
  _props: FeedbackFormConnectedProps,
): FeedbackFormMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
