import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'

import {
  GET_INVITES_SUCCESS,
  POST_INVITES_REQUEST,
  CLEAR_TEAM_SCOPE,
  DELETE_INVITES_REQUEST,
  PATCH_INVITES_REQUEST,
  DELETE_INVITES_SUCCESS,
} from 'actions'

import { InvitesReducer } from './types/InvitesReducer'
import { InviteReducer } from './types/InviteReducer'

export const invitesGenericReducer = createGenericReducer<
  InvitesReducer,
  InviteReducer
>({
  getSuccessAction: GET_INVITES_SUCCESS,
  postRequestAction: POST_INVITES_REQUEST,
  clearActions: [CLEAR_TEAM_SCOPE],
  deleteRequestAction: DELETE_INVITES_REQUEST,
  deleteSuccessAction: DELETE_INVITES_SUCCESS,
  patchRequestAction: PATCH_INVITES_REQUEST,
  // debug: true,
})
