// import { Dispatch, Action } from 'redux'

import {
  // ModalConnectProps,
  ModalMapDispatchReturnType,
} from './types'

export const ModalMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: ModalConnectProps,
): ModalMapDispatchReturnType => {
  return {
  }
}
