import { css } from 'emotion'
import { TEXT_COLOR_LIGHT } from 'constants/styleConstants'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const TaskChangeCardStyles = css`
  > main {
    margin-top: 16px;
  }

  > header {
    padding: 0 16px;
  }

  .displayNameAndTime {
    color: ${TEXT_COLOR_LIGHT};
  }

  .DisplayNameSpan {
    font-weight: bold;
    margin-left: 8px;
  }

  .created {
    margin-left: 8px;
    font-size: 0.9em;
    color: ${TEXT_COLOR_LIGHT};
  }

  .afterHeader,
  .beforeHeader {
    font-weight: bold;
    margin: 16px 0;
    text-align: center;
    font-size: 0.8em;
  }

  .afterContainer,
  .beforeContainer {
    padding: 16px;
    background-color: #fff;
  }

  &.isBigScreen {
    > header {
      padding: 0;
    }
  }
`
