import { connect } from 'react-redux'
import { ToBacklogButton } from './ToBacklogButton'
import { ToBacklogButtonMapState } from './ToBacklogButtonMapState'
import { ToBacklogButtonMapDispatch } from './ToBacklogButtonMapDispatch'
import { ToBacklogButtonMapStateReturnType } from './types/ToBacklogButtonMapStateReturnType'
import { ToBacklogButtonMapDispatchReturnType } from './types/ToBacklogButtonMapDispatchReturnType'
import { ToBacklogButtonConnectedProps } from './types/ToBacklogButtonConnectedProps'

export const ToBacklogButtonConnected = connect<
  ToBacklogButtonMapStateReturnType,
  ToBacklogButtonMapDispatchReturnType,
  ToBacklogButtonConnectedProps>(ToBacklogButtonMapState,
    ToBacklogButtonMapDispatch)(ToBacklogButton)
