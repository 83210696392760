import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCanRead } from 'state/getters/getCanRead'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'
import { getIsBigScreen } from 'state/getters/getIsBigScreen'

import {
  // MessageLayoutConnectedProps,
  MessageLayoutMapStateReturnType,
} from './types'

export const MessageLayoutMapState = (
  state: StateReducers
  // props: MessageLayoutConnectedProps,
): MessageLayoutMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const canRead = getCanRead({ state })
  const isMediumScreen = getIsMediumScreen({ state })
  const isBigScreen = getIsBigScreen({ state })
  const height = state.windowMeta?.height

  return {
    taskId,
    canRead,
    isMediumScreen,
    isBigScreen,
    windowHeight: height,
  }
}
