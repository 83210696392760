import { StateReducers } from 'state/reducers/types/StateReducers'
import { CancelSubscriptionButtonConnectedProps } from './types/CancelSubscriptionButtonConnectedProps'
import { CancelSubscriptionButtonMapStateReturnType } from './types/CancelSubscriptionButtonMapStateReturnType'

export const CancelSubscriptionButtonMapState = (
  _state: StateReducers,
  _props: CancelSubscriptionButtonConnectedProps
): CancelSubscriptionButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
