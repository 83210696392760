import React, { useCallback } from 'react'
import { MessageActionListProps } from './types/MessageActionListProps'
import { ActionButton } from 'components/button/ActionButton'
import { EditIcon } from 'components/icon/EditIcon/EditIcon'
import { ReplyIcon } from 'components/icon/ReplyIcon/ReplyIcon'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'
import { DeleteAndConfirmWidget } from '../../widget/DeleteAndConfirmWidget'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'

export const MessageActionList = ({
  editMessage,
  deleteMessage,
  reply,
  replyClick,
  messageId,
  canDelete,
  canEdit,
}: MessageActionListProps): JSX.Element => {
  const editMessageCallback = useCallback(() => {
    editMessage(messageId)
  }, [messageId, editMessage])

  const deleteMessageCallback = useCallback(() => {
    deleteMessage(messageId)
  }, [messageId, deleteMessage])

  const replyMessageCallback = useCallback(() => {
    replyClick?.()
    reply()
  }, [replyClick, reply])

  return (
    <ul className={`MessageActionList ${listActionButtonsStyles}`}>
      {canEdit && (
        <li>
          <ActionButton title="Edit" onClick={editMessageCallback}>
            <IconAndDiv icon={<EditIcon />}>Edit</IconAndDiv>
          </ActionButton>
        </li>
      )}

      <li>
        <ActionButton title="Reply" onClick={replyMessageCallback}>
          <IconAndDiv icon={<ReplyIcon />}>Reply</IconAndDiv>
        </ActionButton>
      </li>

      {canDelete && (
        <li>
          <DeleteAndConfirmWidget
            onDeleteClick={deleteMessageCallback}
            confirmButtonClassName={marginTopSmallStyles}
          />
        </li>
      )}
    </ul>
  )
}
