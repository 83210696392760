import React, { useCallback } from 'react'
import { ToCurrentCycleButtonProps } from './types/ToCurrentCycleButtonProps'
import { ActionButton } from '../ActionButton'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { ArrowRightIcon } from '../../icon/ArrowRightIcon/ArrowRightIcon'
import { PLAN_NOUN } from '../../../constants/english'

export const ToCurrentCycleButton = ({
  moveToCurrentCycle,
  taskId,
  className = '',
}: ToCurrentCycleButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    moveToCurrentCycle(taskId)
  }, [taskId, moveToCurrentCycle])

  return (
    <ActionButton
      className={`ToCurrentCycleButton ${className}`}
      onClick={onClickCallback}
      title={`move to Current ${PLAN_NOUN}`}
    >
      <IconAndDiv icon={<ArrowRightIcon />}>Current {PLAN_NOUN}</IconAndDiv>
    </ActionButton>
  )
}
