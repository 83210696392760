import { css } from 'emotion'
import { LINK_COLOR } from 'constants/styleConstants'

export const DashboardNavStyles = css`
  > ul {
    display: flex;
    justify-content: center;

    > li {
      margin: 0 16px;
      font-size: 1.1em;

      a {
        font-weight: bold;
        color: ${LINK_COLOR};
        opacity: 0.4;

        &:hover,
        &:active {
          opacity: 1;
        }
      }

      &.active {
        a {
          opacity: 1;
        }
      }
    }
  }
`
