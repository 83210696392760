import { UPLOAD_SUCCESS } from 'actions'
import { take, spawn } from 'redux-saga/effects'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { patchTasksSaga } from './patch/patchTasksSaga'
import { clientServerTimestamp } from 'sdks'
import { UploadSuccessAction } from 'actionCreators/Upload/types/UploadSuccessAction'

interface Props {
  uploadId: string
  taskId: string
  teamId: string
  userId: string
  userDisplayName: string
}

export const uploadSuccessTaskFilesSaga = function*({
  uploadId,
  taskId,
  teamId,
  userId,
  userDisplayName,
}: Props) {
  while (1) {
    const uploadSuccessAction: UploadSuccessAction = yield take([
      UPLOAD_SUCCESS,
    ])
    const { key } = getObjectFirstItem(uploadSuccessAction.successValues)

    if (key === uploadId) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            updated: clientServerTimestamp(),
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items: items,
        lastModifiedUserId: userId,
        lastModifiedDisplayName: userDisplayName,
      })

      return
    }
  }
}
