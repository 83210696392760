import { ResetPasswordFormStyles } from './ResetPasswordFormStyles'
import React, { useState, useCallback } from 'react'
import { ResetPasswordFormProps } from './types/ResetPasswordFormProps'
import { FormFooter } from 'components/footer/FormFooter'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { isInvalidEmail } from 'firebaseFunctions/utilities/is/isInvalidEmail'

export const ResetPasswordForm = ({
  submit,
}: ResetPasswordFormProps): JSX.Element => {
  const [emailState, setEmailState] = useState('')
  const [emailErrorState, setEmailErrorState] = useState<string | null>(null)

  const validateEmail = useCallback(() => {
    const error = isInvalidEmail(emailState)

    setEmailErrorState(error || null)

    return error
  }, [emailState, setEmailErrorState])

  return (
    <form
      className={`ResetPasswordForm ${ResetPasswordFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const emailError = validateEmail()

        if (!emailError) {
          submit({
            email: emailState,
          })
        }
      }}
    >
      <h2>Reset password</h2>

      <TextInputField
        label="Email Address"
        name="email"
        type="email"
        error={emailErrorState}
        onChange={(e) => {
          setEmailErrorState(null)
          setEmailState(e.target.value)
        }}
      />

      <FormFooter />
    </form>
  )
}
