import { Dispatch, Action } from 'redux'
import { UpdateTeamNameFormMapDispatchReturnType } from './types/UpdateTeamNameFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_TEAM_NAME } from 'actions'

export const UpdateTeamNameFormMapDispatch = (
  dispatch: Dispatch<Action>
): UpdateTeamNameFormMapDispatchReturnType => {
  return {
    submit: ({ displayName }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_TEAM_NAME,
          values: { displayName },
          description: 'UpdateTeamNameFormMapDispatch',
        })
      )
    },
  }
}
