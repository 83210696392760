import { css } from 'emotion'
import { TEXT_COLOR, TEXT_COLOR_LIGHT } from 'constants/styleConstants'

export const TaskStatusButtonsInputFieldStyles = css`
  > li {
    margin: 4px;
    display: inline-box;

    &:first-child {
      margin-left: 0px;
    }

    &.isActive {
      button {
        background-color: #ccc;
        color: ${TEXT_COLOR};
        border: 1px solid ${TEXT_COLOR};
      }
    }
  }

  button {
    padding: 4px 8px;
    background-color: #ddd;
    border-radius: 8px;
    color: #aaa;
    font-size: 0.75em;
    letter-spacing: 0.5px;
    border: 1px solid rgba(0, 0, 0, 0);

    &:hover {
      color: ${TEXT_COLOR_LIGHT};
    }
  }
`
