import { PATCH_TASKS_SUCCESS } from 'actions'

import { PatchTasksSuccessAction } from './types/PatchTasksSuccessAction'
import { PatchTasksSuccessActionValues } from './types/PatchTasksSuccessActionValues'
import { PatchTasksRequestActionValues } from './types/PatchTasksRequestActionValues'


interface Props {
  successValues: PatchTasksSuccessActionValues
  requestValues: PatchTasksRequestActionValues
}

export const createPatchTasksSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchTasksSuccessAction => {
  return {
    type: PATCH_TASKS_SUCCESS,
    requestValues,
    successValues,
  }
}
