import { css } from 'emotion'
import {
  NEUTRAL_BORDER_COLOR,
  BORDER_RADIUS,
  NEUTRAL_HIGH_LIGHT_COLOR,
  PRIMARY_COLOR,
  TEXT_COLOR_LIGHT,
  NEUTRAL_BORDER_COLOR_LIGHT,
} from 'constants/styleConstants'

export const FileDropInputStyles = css`
  border: 2px solid rgba(0, 0, 0, 0);

  &.isDragActive {
    border-color: ${PRIMARY_COLOR};
  }

  > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border: 2px dashed ${NEUTRAL_BORDER_COLOR_LIGHT};

    .FileUploadIcon {
      width: 60px;
      margin-bottom: 16px;
      color: ${TEXT_COLOR_LIGHT};
    }

    .or {
      margin-top: 16px;
    }

    button {
      margin-top: 16px;
      border: 1px solid ${NEUTRAL_BORDER_COLOR};
      padding: 8px 16px;
      border-radius: ${BORDER_RADIUS};
      background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
    }
  }
`
