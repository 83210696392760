 import { StateReducers } from 'state/reducers/types/StateReducers'
import { SendConfirmEmailButtonConnectedProps } from './types/SendConfirmEmailButtonConnectedProps'
import { SendConfirmEmailButtonMapStateReturnType } from './types/SendConfirmEmailButtonMapStateReturnType'

export const SendConfirmEmailButtonMapState = (
  _state: StateReducers,
  _props: SendConfirmEmailButtonConnectedProps,
): SendConfirmEmailButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
