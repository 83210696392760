import { TaskSizeSelectFieldStyles } from './TaskSizeSelectFieldStyles'
import React, { useCallback } from 'react'
import { TaskSizeSelectFieldProps } from './types/TaskSizeSelectFieldProps'
import { SelectInput } from 'components/input/SelectInput'
import { SelectOption } from '../../../types/SelectOption'

export const TaskSizeSelectField = ({
  taskSizes = [],
  onSizeClick,
  size,
}: TaskSizeSelectFieldProps): JSX.Element => {
  const options = taskSizes.map((size) => {
    return {
      value: size,
    }
  })

  const value = size ? { value: size } : undefined

  const handleChange = useCallback(
    (selectedOptions: SelectOption) => {
      onSizeClick?.(selectedOptions.value)
    },
    [onSizeClick]
  )

  return (
    <SelectInput
      className={'TaskSizeSelectField ' + TaskSizeSelectFieldStyles}
      options={options}
      placeholder="size"
      onChange={handleChange}
      value={value}
    />
  )
}
