import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const BigHeaderStyles = css`
  .addTask {
    text-decoration: none;
    padding: 4px 8px;
    margin-left: 16px;
  }

  .TeamNameLink {
    margin-left: 8px;
  }

  .taskDetailLink {
    font-size: 0.8em;
    margin-left: 8px;
    line-height: 1.3em;
    display: inline-block;
    max-height: 34px;
    overflow: hidden;
  }

  .icons {
    > li {
      margin-left: 8px;
    }
  }

  &.isBigScreen {
    .taskDetailLink {
      max-width: 800px;
      font-size: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
