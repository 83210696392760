import { connect } from 'react-redux'
import { UpdateTaskSizesForm } from './UpdateTaskSizesForm'
import { UpdateTaskSizesFormMapState } from './UpdateTaskSizesFormMapState'
import { UpdateTaskSizesFormMapDispatch } from './UpdateTaskSizesFormMapDispatch'
import { UpdateTaskSizesFormMapStateReturnType } from './types/UpdateTaskSizesFormMapStateReturnType'
import { UpdateTaskSizesFormMapDispatchReturnType } from './types/UpdateTaskSizesFormMapDispatchReturnType'
import { UpdateTaskSizesFormConnectedProps } from './types/UpdateTaskSizesFormConnectedProps'

export const UpdateTaskSizesFormConnected = connect<
  UpdateTaskSizesFormMapStateReturnType,
  UpdateTaskSizesFormMapDispatchReturnType,
  UpdateTaskSizesFormConnectedProps
>(
  UpdateTaskSizesFormMapState,
  UpdateTaskSizesFormMapDispatch
)(UpdateTaskSizesForm)
