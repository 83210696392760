import { StateReducers }  from 'state/reducers/types/StateReducers'
import { MessagesReducer }  from 'state/reducers/types/MessagesReducer'
import { pickBy } from 'lodash'

interface Props {
  state: StateReducers
  taskId: string
}

export const getTaskMessages = ({ state, taskId }: Props): MessagesReducer | undefined => {
  const messages = state.messages || {}

  return pickBy(messages, (messageReducer) => {
    return messageReducer.taskId === taskId
  })
}
