import { connect } from 'react-redux'
import { BigHeader } from './BigHeader'
import { BigHeaderMapState } from './BigHeaderMapState'
import { BigHeaderMapDispatch } from './BigHeaderMapDispatch'

import { BigHeaderMapStateReturnType } from './types/BigHeaderMapStateReturnType'
import { BigHeaderMapDispatchReturnType } from './types/BigHeaderMapDispatchReturnType'
import { BigHeaderConnectedProps } from './types/BigHeaderConnectedProps'


export const BigHeaderConnected = connect<
  BigHeaderMapStateReturnType,
  BigHeaderMapDispatchReturnType,
  BigHeaderConnectedProps
>(
  BigHeaderMapState,
  BigHeaderMapDispatch
)(BigHeader)
