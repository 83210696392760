import { connect } from 'react-redux'
import { SyncTaskCountButton } from './SyncTaskCountButton'
import { SyncTaskCountButtonMapState } from './SyncTaskCountButtonMapState'
import { SyncTaskCountButtonMapDispatch } from './SyncTaskCountButtonMapDispatch'
import { SyncTaskCountButtonMapStateReturnType } from './types/SyncTaskCountButtonMapStateReturnType'
import { SyncTaskCountButtonMapDispatchReturnType } from './types/SyncTaskCountButtonMapDispatchReturnType'
import { SyncTaskCountButtonConnectedProps } from './types/SyncTaskCountButtonConnectedProps'

export const SyncTaskCountButtonConnected = connect<
  SyncTaskCountButtonMapStateReturnType,
  SyncTaskCountButtonMapDispatchReturnType,
  SyncTaskCountButtonConnectedProps>(SyncTaskCountButtonMapState,
    SyncTaskCountButtonMapDispatch)(SyncTaskCountButton)
