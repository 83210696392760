import { USER_INVITES_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PatchUserInvitesSuccessPayload } from 'firebaseFunctions/userInvites/types/PatchUserInvitesSuccessPayload'
import { PatchUserInvitesApiProps } from './types/PatchUserInvitesApiProps'

export const patchUserInvitesApi = ({
  values,
  apiHeaders,
}: PatchUserInvitesApiProps): AxiosPromise<PatchUserInvitesSuccessPayload> => {
  return Axios.patch(USER_INVITES_URL, values, {
    headers: apiHeaders,
  })
}
