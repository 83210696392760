// import { Dispatch, Action } from 'redux'

import {
  // LabelIconSpanConnectedProps,
  LabelIconSpanMapDispatchReturnType,
} from './types'

export const LabelIconSpanMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: LabelIconSpanConnectedProps,
): LabelIconSpanMapDispatchReturnType => {
  return {
  }
}
