import * as React from 'react'
import { StripeSubscriptionCard } from './StripeSubscriptionCard'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'
import { StripeSubscription } from '../../../firebaseFunctions/types/StripeSubscription'

const stripeSubscription: StripeSubscription = {}

export const StripeSubscriptionCardExamples = () => {
  return (
    <div className="StripeSubscriptionCardExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <StripeSubscriptionCard
          stripeSubscriptionId="foo"
          stripeSubscription={stripeSubscription}
        />
      </Provider>
    </div>
  )
}
