import { Dispatch, Action } from 'redux'
import { NotificationPermissionWatcherMapDispatchReturnType } from './types/NotificationPermissionWatcherMapDispatchReturnType'
import { createSetWindowMetaAction } from 'actionCreators/createSetWindowMetaAction'
import { createSetCookieStorageAction } from '../../../actionCreators/cookieStorage'

export const NotificationPermissionWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): NotificationPermissionWatcherMapDispatchReturnType => {
  return {
    setWindowMeta: (values) => {
      dispatch(
        createSetWindowMetaAction({
          values,
          description: 'NotificationPermissionWatcherMapDispatch',
        })
      )
    },

    unsetDontAskAgain: () => {
      dispatch(
        createSetCookieStorageAction({
          description: 'NotificationPermissionWatcherMapDispatch',
          values: {
            dontAskToEnableNotifications: false,
          },
        })
      )
    },
  }
}
