import { connect } from 'react-redux'
import { UpgradeWorkspaceMain } from './UpgradeWorkspaceMain'
import { UpgradeWorkspaceMainMapState } from './UpgradeWorkspaceMainMapState'
import { UpgradeWorkspaceMainMapDispatch } from './UpgradeWorkspaceMainMapDispatch'

import { UpgradeWorkspaceMainMapStateReturnType } from './types/UpgradeWorkspaceMainMapStateReturnType'
import { UpgradeWorkspaceMainMapDispatchReturnType } from './types/UpgradeWorkspaceMainMapDispatchReturnType'
import { UpgradeWorkspaceMainConnectedProps } from './types/UpgradeWorkspaceMainConnectedProps'


export const UpgradeWorkspaceMainConnected = connect<
  UpgradeWorkspaceMainMapStateReturnType,
  UpgradeWorkspaceMainMapDispatchReturnType,
  UpgradeWorkspaceMainConnectedProps
>(
  UpgradeWorkspaceMainMapState,
  UpgradeWorkspaceMainMapDispatch
)(UpgradeWorkspaceMain)
