import { getMaxUserCount } from './../state/getters/getMaxUserCount'
import { take, put, select } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'actions'
import { selectState } from 'state/reducers/selectState'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsProFromState } from 'state/getters/getIsProFromState'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { PRO_CANCELED_ROUTE, PAYMENT_FAILED_ROUTE } from 'routes'

export const proCanceledF = function*() {
  while (1) {
    const action: LocationChangeAction = yield take(LOCATION_CHANGE)

    const isDashboardLayoutRoute =
      action.values.isRoutes?.isDashboardLayoutRoute
    const isTaskMainRoute = action.values.isRoutes?.isTaskMainRoute

    if (isDashboardLayoutRoute || isTaskMainRoute) {
      const state: StateReducers = yield select(selectState)
      const isPro = getIsProFromState({ state })
      const teamReducer = getCurrentTeamReducer({ state })
      const userCount = teamReducer?.stats?.userCount || 0
      const { maxUserCountFree } = getMaxUserCount({ state })
      const tooManyUsers = userCount > maxUserCountFree
      const paymentFailed = teamReducer?.stats?.subscription?.paymentFailed
      const query = getCurrentQuery({ state })

      if ((!isPro && tooManyUsers) || paymentFailed) {
        yield put(
          createHistoryAction({
            pathname: paymentFailed ? PAYMENT_FAILED_ROUTE : PRO_CANCELED_ROUTE,
            query,
            method: 'replace',
            description: 'proCanceledF',
          })
        )
      }
    }
  }
}
