import { RefObject } from 'react'
import { PluginEditor } from './pluginEditor/PluginEditor'

interface EditorSubmitForAndroidProps {
  saveOnBlur?: boolean
  editorRef: RefObject<PluginEditor>
  submit: () => void
}

export const editorSubmitForAndroid = ({
  submit,
  editorRef,
  saveOnBlur,
}: EditorSubmitForAndroidProps) => {
  setTimeout(() => {
    const editor = editorRef.current

    if (editor) {
      editor.blur() // will cause draft js to recognize change

      if (!saveOnBlur) {
        submit()
      }
    }
  }, 100) // 100 is a guestimate of how long it will take for draft js to see change
}
