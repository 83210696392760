import { LoadStripeInvoicesButtonStyles } from './LoadStripeInvoicesButtonStyles'
import React, { useState, useCallback } from 'react'
import { LoadStripeInvoicesButtonProps } from './types/LoadStripeInvoicesButtonProps'
import { ActionButton } from '../ActionButton'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { ReceiptIcon } from '../../icon/ReceiptIcon/ReceiptIcon'
import { getIdToken } from '../../../sdks/firebase/getIdToken'
import { getStripeInvoicesApi } from '../../../apis/get/getStripeInvoicesApi'
import { Spinner } from '../../widget/Spinner'

export const LoadStripeInvoicesButton = ({
  setStripeInvoices,
  canGetToken,
  handleError,
  stripeSubscriptionId,
  className = '',
}: LoadStripeInvoicesButtonProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(false)

  const getInvoicesCallback = useCallback(async () => {
    try {
      setShowSpinner(true)
      const token = await getIdToken()

      const response = await getStripeInvoicesApi({
        values: {
          stripeSubscriptionId,
        },
        apiHeaders: { token },
      })

      setShowSpinner(false)
      setStripeInvoices?.(response.data.items)
    } catch (error) {
      setShowSpinner(false)
      handleError?.(error)
    }
  }, [setShowSpinner, handleError, setStripeInvoices, stripeSubscriptionId])

  return (
    <ActionButton
      className={`LoadStripeInvoicesButton ${LoadStripeInvoicesButtonStyles} ${className}`}
      title="Click to show invoices"
      disabled={showSpinner || !canGetToken}
      onClick={getInvoicesCallback}
    >
      {showSpinner && <Spinner />}
      {!showSpinner && <IconAndDiv icon={<ReceiptIcon />}>Invoices</IconAndDiv>}
    </ActionButton>
  )
}
