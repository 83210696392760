import { getCurrentTeamId } from './getCurrentTeamId'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { Team } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCurrentTeam = ({ state, teamId }: Props): Team | undefined => {
  const useTeamId = teamId || getCurrentTeamId({ state })

  const teams = state.teams || undefined
  const data = useTeamId ? teams?.[useTeamId]?.data : undefined

  return data
}
