import { connect } from 'react-redux'
import { TaskSizeDropdown } from './TaskSizeDropdown'
import { TaskSizeDropdownMapState } from './TaskSizeDropdownMapState'
import { TaskSizeDropdownMapDispatch } from './TaskSizeDropdownMapDispatch'

import { TaskSizeDropdownMapStateReturnType } from './types/TaskSizeDropdownMapStateReturnType'
import { TaskSizeDropdownMapDispatchReturnType } from './types/TaskSizeDropdownMapDispatchReturnType'
import { TaskSizeDropdownConnectedProps } from './types/TaskSizeDropdownConnectedProps'


export const TaskSizeDropdownConnected = connect<
  TaskSizeDropdownMapStateReturnType,
  TaskSizeDropdownMapDispatchReturnType,
  TaskSizeDropdownConnectedProps>(TaskSizeDropdownMapState,
    TaskSizeDropdownMapDispatch)(TaskSizeDropdown)
