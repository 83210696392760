import { getTasksRef } from './getTasksRef'
import { FirestoreClient, Task } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  taskId: string
}

export const getTaskRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  taskId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<Task>
  : FirebaseFirestore.DocumentReference => {
  return getTasksRef({ firestore, teamId }).doc(taskId) as any
}
