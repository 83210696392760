import React from 'react'
import { LabelDivProps } from './types/LabelDivProps'
import { labelStyles } from 'styles/labelStyles'

// TODO: deprecated in favor of LabelStyles

export const LabelDiv = ({
  className = '',
  children,
  onClick,
}: LabelDivProps): JSX.Element => {
  const extraClassName = className ? ` ${className}` : ''

  return (
    <div
      className={'LabelDiv ' + labelStyles + extraClassName}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
