import { TaskStatusSelectFieldStyles } from './TaskStatusSelectFieldStyles'
import React from 'react'
import { TaskStatusSelectFieldProps } from './types/TaskStatusSelectFieldProps'
import { SelectInput } from 'components/input/SelectInput'

export const TaskStatusSelectField = ({
  taskStatuses = [],
  status,
  onChange,
  className = '',
}: TaskStatusSelectFieldProps): JSX.Element => {
  const options = taskStatuses.map((status) => {
    return {
      value: status,
    }
  })

  const value = status ? { value: status } : undefined

  return (
    <SelectInput
      className={`TaskStatusSelectField ${TaskStatusSelectFieldStyles} ${className}`}
      options={options}
      placeholder="status"
      onChange={onChange}
      value={value}
    />
  )
}
