import { MembersMainStyles } from './MembersMainStyles'
import React from 'react'
// import { MembersMainProps } from './types/MembersMainProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { MemberListConnected } from 'components/list/MemberList'
import { ADD_NEW_MEMBER_ROUTE } from 'routes'
import { PlusSolidIcon } from 'components/icon/PlusSolidIcon/PlusSolidIcon'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { USERS_NOUN } from '../../../constants/english'
import { UserReportMainConnected } from '../UserReportMain/UserReportMainConnected'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { HeaderOneText } from '../../text/HeaderOneText/HeaderOneText'

export const MembersMain = (): JSX.Element => {
  return (
    <div className={'MembersMain ' + MembersMainStyles}>
      <HeaderOneText>{USERS_NOUN}</HeaderOneText>

      <UserReportMainConnected />

      <header className={marginTopMediumStyles}>
        <CustomLinkConnected
          route={ADD_NEW_MEMBER_ROUTE}
          withCurrentQuery={true}
          className={actionButtonStyles}
          title="add member"
        >
          <PlusSolidIcon /> Invite
        </CustomLinkConnected>
      </header>

      <MemberListConnected />
    </div>
  )
}
