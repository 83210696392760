import { css } from 'emotion'
import {
  LINK_COLOR,
  LINK_COLOR_DARK,
  TEXT_COLOR_LIGHT,
} from 'constants/styleConstants'

export const textLinkStyles = css`
  color: ${LINK_COLOR};

  &:hover,
  &:active {
    color: ${LINK_COLOR_DARK};
    text-decoration: underline;
  }

  &:disabled {
    color: ${TEXT_COLOR_LIGHT};
    text-decoration: none;
    cursor: initial;
  }
`
