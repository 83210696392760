import { StatusDropdownStyles } from './StatusDropdownStyles'
import React, { useCallback } from 'react'
import { StatusDropdownProps } from './types/StatusDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { TaskStatusSelectFieldConnected } from 'components/field/TaskStatusSelectField'
import { TaskStatusButtonListConnected } from 'components/list/TaskStatusButtonList'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { CustomLinkConnected } from '../../link/CustomLink'
import { TASK_STATUSES_ROUTE } from '../../../routes'
import { SelectOption } from '../../../types/SelectOption'
import { displayBlockStyles } from '../../../styles/display/displayBlockStyles'
import { FilterByButtonConnected } from '../../button/FilterByButton/FilterByButtonConnected'
import { footerDarkStyles } from '../../../styles/footerDarkStyles'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'
import { flexSpaceBetweenStyles } from '../../../styles/flex/flexSpaceBetweenStyles'
import { marginSmallStyles } from '../../../styles/margin/marginSmallStyles'

// TODO: make a StatusDropdownMain
export const StatusDropdown = ({
  showStatusDropdown,
  taskId,
  status,
  showSelectField,
  submitStatus,
  closeDropdown,
  canOwn,
  canWrite,
  isDashboardLayoutRoute,
}: StatusDropdownProps): JSX.Element => {
  const selectOnChange = useCallback(
    (option: SelectOption) => {
      if (typeof option.value === 'string') {
        submitStatus({ status: option.value, taskId })
      }
      closeDropdown()
    },
    [closeDropdown, submitStatus, taskId]
  )

  const buttonOnClick = useCallback(
    (status: string) => {
      submitStatus({ status, taskId })
      closeDropdown()
    },
    [submitStatus, closeDropdown, taskId]
  )

  return (
    <div className={`StatusDropdown _${taskId} ` + StatusDropdownStyles}>
      <DropdownButtonConnected
        dropdownType="status"
        title="Set status"
        disabled={!canWrite}
        className={`${textLinkStyles} ${textSmallestStyles}`}
        watchSelector={`.StatusDropdown._${taskId}`}
        id={taskId}
      >
        {status || <span className="placeholder">status</span>}
      </DropdownButtonConnected>

      {showStatusDropdown && (
        <DropdownConnected>
          {showSelectField && (
            <TaskStatusSelectFieldConnected
              onChange={selectOnChange}
              className={marginSmallStyles}
            />
          )}

          <TaskStatusButtonListConnected
            className={`${showSelectField ? marginTopMediumStyles : ''}`}
            onStatusClick={buttonOnClick}
          />

          <div
            className={`${footerDarkStyles} ${paddingSmallStyles} ${flexSpaceBetweenStyles}`}
          >
            {isDashboardLayoutRoute && (
              <FilterByButtonConnected status={status} />
            )}
            {!isDashboardLayoutRoute && <div />}
            {canOwn && (
              <CustomLinkConnected
                withCurrentQuery={true}
                className={`${textSmallestStyles} ${textLinkStyles} ${displayBlockStyles}`}
                title="Configure statuses"
                route={TASK_STATUSES_ROUTE}
              >
                Configure
              </CustomLinkConnected>
            )}
          </div>
        </DropdownConnected>
      )}
    </div>
  )
}
