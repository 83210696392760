import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import {
  // TaskDescriptionMainConnectedProps,
  TaskDescriptionMainMapStateReturnType,
} from './types'
import { FirebaseTimestamp } from 'sdks'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskDescriptionMainMapState = (
  state: StateReducers
  // props: TaskDescriptionMainConnectedProps,
): TaskDescriptionMainMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const task = taskId ? getCurrentTask({ state, taskId }) : undefined
  const currentUserId = state.currentUserId || undefined
  const workingOnDescription = task?.workingOnDescription
  const workingOnDescriptionUserId = workingOnDescription?.userId
  const workingOnDescriptionUpdated = workingOnDescription?.updated
  const description = task?.description
  const canWrite = getCanWrite({ state })
  const workingOnDescriptionUpdatedSeconds =
    workingOnDescriptionUpdated instanceof FirebaseTimestamp
      ? workingOnDescriptionUpdated.seconds
      : undefined

  return {
    currentUserId,
    description,
    workingOnDescriptionUserId,
    workingOnDescriptionUpdatedSeconds,
    taskId,
    canWrite,
  }
}
