import { connect } from 'react-redux'
import { UpdateUserDisplayNameForm } from './UpdateUserDisplayNameForm'
import { UpdateUserDisplayNameFormMapState } from './UpdateUserDisplayNameFormMapState'
import { UpdateUserDisplayNameFormMapDispatch } from './UpdateUserDisplayNameFormMapDispatch'
import { UpdateUserDisplayNameFormMapStateReturnType } from './types/UpdateUserDisplayNameFormMapStateReturnType'
import { UpdateUserDisplayNameFormMapDispatchReturnType } from './types/UpdateUserDisplayNameFormMapDispatchReturnType'
import { UpdateUserDisplayNameFormConnectedProps } from './types/UpdateUserDisplayNameFormConnectedProps'

export const UpdateUserDisplayNameFormConnected = connect<
  UpdateUserDisplayNameFormMapStateReturnType,
  UpdateUserDisplayNameFormMapDispatchReturnType,
  UpdateUserDisplayNameFormConnectedProps>(UpdateUserDisplayNameFormMapState,
    UpdateUserDisplayNameFormMapDispatch)(UpdateUserDisplayNameForm)
