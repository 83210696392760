import { PATCH_UNSCOPE_USER_FAILURE } from 'actions'

import { PatchUnscopeUserFailureAction } from './types/PatchUnscopeUserFailureAction'
import { PatchUnscopeUserRequestActionValues } from './types/PatchUnscopeUserRequestActionValues'


interface Props {
  requestValues: PatchUnscopeUserRequestActionValues
}

export const createPatchUnscopeUserFailureAction = ({
  requestValues,
}: Props): PatchUnscopeUserFailureAction => {
  return {
    type: PATCH_UNSCOPE_USER_FAILURE,
    requestValues,
  }
}
