import * as React from 'react'
import { TextAreaInput } from './TextAreaInput'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const TextAreaInputExamples = () => {
  return (
    <div className="TextAreaInputExamples exampleContainer">
      <h1 className="exampleHeader">text input with error and placeholder</h1>

      <Provider store={createMockStore(mockState)}>
        <TextAreaInput className="hasError" placeholder="hello world" />
      </Provider>

      <h1 className="exampleHeader">clean</h1>

      <Provider store={createMockStore(mockState)}>
        <TextAreaInput className="clean" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
      </Provider>

      <h1 className="exampleHeader">text input with value</h1>

      <Provider store={createMockStore(mockState)}>
        <TextAreaInput value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
      </Provider>

      <h1 className="exampleHeader">text input disabled</h1>

      <Provider store={createMockStore(mockState)}>
        <TextAreaInput value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." disabled={true} />
      </Provider>
    </div>
  )
}
