import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUserRole } from 'state/getters/getUserRole'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'

import { MemberMainConnectedProps } from './types/MemberMainConnectedProps'
import { MemberMainMapStateReturnType } from './types/MemberMainMapStateReturnType'


export const MemberMainMapState = (
  state: StateReducers,
  props: MemberMainConnectedProps,
): MemberMainMapStateReturnType => {
  const userRole = getUserRole({ state, userId: props.userId })
  const currentQuery = getCurrentQuery({ state }) || {}
  const role = userRole && userRole.role
  const currentUserId = state.currentUserId || undefined
  const showCantUpdateRole = currentUserId === (currentQuery.userId || props.userId)

  return {
    role,
    showCantUpdateRole,
  }
}
