// import { Dispatch, Action } from 'redux'

import {
  // GoBackLinkConnectedProps,
  GoBackLinkMapDispatchReturnType,
} from './types'

export const GoBackLinkMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: GoBackLinkConnectedProps,
): GoBackLinkMapDispatchReturnType => {
  return {
  }
}
