import { take, put } from 'redux-saga/effects'
import { auth } from 'sdks'
import { PUBLIC_URL } from 'constants/environmentConstants'
import { LOGIN_ROUTE } from 'routes'
import { FirebaseError } from 'firebase'
import {
  POST_RESET_PASSWORD_REQUEST,
  POST_RESET_PASSWORD_FAILURE,
} from 'actions'
import { USER_NOT_FOUND } from 'firebaseFunctions/constants/failureCodesConstants'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createSetAlertMessageAction } from 'actionCreators/set/SetAlertMessage/createSetAlertMessageAction'
import { PostResetPasswordRequestAction } from 'actionCreators/post/PostResetPassword/types/PostResetPasswordRequestAction'
import { createPostResetPasswordSuccessAction } from 'actionCreators/post/PostResetPassword/createPostResetPasswordSuccessAction'
import { createPostResetPasswordFailureAction } from 'actionCreators/post/PostResetPassword/createPostResetPasswordFailureAction'

export const postResetPasswordRequestF = function*() {
  while (1) {
    const postResetPasswordRequestAction: PostResetPasswordRequestAction = yield take(
      [POST_RESET_PASSWORD_REQUEST]
    )

    const { requestValues } = postResetPasswordRequestAction
    const { email } = requestValues

    try {
      const actionCodeSettings = {
        url: `${PUBLIC_URL}${LOGIN_ROUTE}`,
      }

      yield auth.sendPasswordResetEmail(email, actionCodeSettings)

      yield put(
        createPostResetPasswordSuccessAction({
          requestValues,
          successValues: requestValues,
        })
      )
    } catch (error) {
      if (error.code === USER_NOT_FOUND) {
        yield put(
          createSetAlertMessageAction({
            description: 'postResetPasswordRequestF',
            values: {
              location: 'form',
              message: 'The email is incorrect.',
              actionType: POST_RESET_PASSWORD_FAILURE,
              type: 'error',
            },
          })
        )
      } else {
        yield put(
          createFirebaseErrorAction({
            error: error as FirebaseError,
            description: 'postResetPasswordRequestF',
          })
        )
      }

      yield put(
        createPostResetPasswordFailureAction({
          requestValues,
        })
      )
    }
  }
}
