import { UpgradeWorkspaceMainStyles } from './UpgradeWorkspaceMainStyles'
import React from 'react'
import { UpgradeWorkspaceMainProps } from './types/UpgradeWorkspaceMainProps'
import {
  PROFESSIONAL_FLAT_PRICE,
  PROFESSIONAL_PRICE_PER_USER,
  PROFESSIONAL_FLAT_PRICING_END_USER_COUNT,
  WORKSPACE_MAX_USER_COUNT_PRO,
  UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO,
} from 'constants/environmentConstants'
import { Elements } from '@stripe/react-stripe-js'
import { stripeSdk } from 'sdks/stripe/stripeSdk'
import { StripeSubscriptionFieldConnected } from 'components/field/StripeSubscriptionField'
import { USERS_NOUN, USER_NOUN, TEAMS_NOUN } from 'constants/english'
import { flexSpaceBetweenStyles } from '../../../styles/flex/flexSpaceBetweenStyles'

export const UpgradeWorkspaceMain = ({
  userCount = 0,
}: UpgradeWorkspaceMainProps): JSX.Element => {
  const useFlatPrice = userCount <= PROFESSIONAL_FLAT_PRICING_END_USER_COUNT
  const newMonthlyTotal = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'USD',
  }).format(
    useFlatPrice
      ? PROFESSIONAL_FLAT_PRICE
      : userCount * PROFESSIONAL_PRICE_PER_USER
  )

  return (
    <div className={`UpgradeWorkspaceMain ${UpgradeWorkspaceMainStyles}`}>
      <h1>Upgrade from Free to Pro</h1>

      <div className="details">
        <div>
          <p className="flatRate">
            <span className="pricePerMonth">
              ${PROFESSIONAL_FLAT_PRICE} / mo.{' '}
            </span>
            flat rate for 1 - {PROFESSIONAL_FLAT_PRICING_END_USER_COUNT}{' '}
            {USERS_NOUN}
          </p>
          <p className="tierPrice">
            <span style={{ textTransform: 'capitalize' }}>{TEAMS_NOUN}</span> of{' '}
            {PROFESSIONAL_FLAT_PRICING_END_USER_COUNT + 1}+ are $
            {PROFESSIONAL_PRICE_PER_USER} per {USER_NOUN}
          </p>
        </div>

        <ul className="upgradePoints">
          <li>
            Up to {WORKSPACE_MAX_USER_COUNT_PRO} {USERS_NOUN}
          </li>
          <li>
            {UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO} GB file storage per{' '}
            {USER_NOUN}
          </li>
          <li>Larger page sizes</li>
        </ul>
      </div>

      <h2>Summary</h2>

      <ul className="summary">
        <li className={flexSpaceBetweenStyles}>
          <div>
            New monthly total{' '}
            {!useFlatPrice && (
              <span>
                ({userCount} {USERS_NOUN})
              </span>
            )}
          </div>
          <div>{newMonthlyTotal}/ month</div>
        </li>
        <li className={flexSpaceBetweenStyles}>
          <div>Due Today</div>
          <div>{newMonthlyTotal}</div>
        </li>
      </ul>

      <h2>Payment</h2>

      <div className="payment">
        <Elements stripe={stripeSdk}>
          <StripeSubscriptionFieldConnected />
        </Elements>
      </div>
    </div>
  )
}
