 import { StateReducers } from 'state/reducers/types/StateReducers'
import { ToPreviousCycleButtonConnectedProps } from './types/ToPreviousCycleButtonConnectedProps'
import { ToPreviousCycleButtonMapStateReturnType } from './types/ToPreviousCycleButtonMapStateReturnType'

export const ToPreviousCycleButtonMapState = (
  _state: StateReducers,
  _props: ToPreviousCycleButtonConnectedProps,
): ToPreviousCycleButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
