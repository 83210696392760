import { connect } from 'react-redux'
import { HistoryCard } from './HistoryCard'
import { HistoryCardMapState } from './HistoryCardMapState'
import { HistoryCardMapDispatch } from './HistoryCardMapDispatch'

import { HistoryCardMapStateReturnType } from './types/HistoryCardMapStateReturnType'
import { HistoryCardMapDispatchReturnType } from './types/HistoryCardMapDispatchReturnType'
import { HistoryCardConnectedProps } from './types/HistoryCardConnectedProps'


export const HistoryCardConnected = connect<
  HistoryCardMapStateReturnType,
  HistoryCardMapDispatchReturnType,
  HistoryCardConnectedProps>(HistoryCardMapState,
    HistoryCardMapDispatch)(HistoryCard)
