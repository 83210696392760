import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'
import {
  // FullImageWidgetConnectedProps,
  FullImageWidgetMapStateReturnType,
} from './types'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const FullImageWidgetMapState = (
  state: StateReducers
  // props: FullImageWidgetConnectedProps,
): FullImageWidgetMapStateReturnType => {
  const isMediumScreen = getIsMediumScreen({ state })
  const canWrite = getCanWrite({ state })

  return {
    isMediumScreen,
    canWrite,
  }
}
