import { DraftEditorStyles } from './DraftEditorStyles'

import React, { useState, useEffect, useCallback, useRef } from 'react'
import { DraftEditorProps } from './types/DraftEditorProps'
import { PluginEditor } from './pluginEditor/PluginEditor'
import {
  createMentionPlugin,
  MentionPlugin,
} from './mention/createMentionPlugin'
import { createLinkifyPlugin } from './createLinkifyPlugin'
import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'
import 'draft-js/dist/Draft.css'
import { debounce } from 'lodash'
import { InlineEditorToolBarConnected } from 'components/bar/InlineEditorToolBar'
import { getSuggestionsCenterPosition } from './getSuggestionsCenterPosition'
import { EditorState } from 'draft-js'
import { editorSubmit } from './editorSubmit'
import { keyBinding } from './keyBinding'
import { handleKeyCommand } from './handleKeyCommand'
import { getContentFromMarkdown } from './getContentFromMarkdown'
import { editorSubmitForAndroid } from './editorSubmitForAndroid'
import { MentionSuggestionListConnected } from 'components/list/MentionSuggestionList'

export const DraftEditorHooked = ({
  placeholder,
  className = '',
  headerToolBar,
  footerToolBar,
  isAndroid,
  onFocus,
  onBlur,
  onChange,
  autoDebounceSave,
  save,
  clearOnSave,
  saveOnEnter,
  value,
  saveOnBlur,
  alwaysShowInlineToolbar,
  autoFocus,
}: DraftEditorProps): JSX.Element => {
  const editorRef = useRef<PluginEditor>(null)
  const [key, setKey] = useState(Math.random())
  const [mentionPlugin, setMentionPlugin] = useState<MentionPlugin | null>(null)
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  )
  const [contentChangedSinceSaved, setContentChangedSinceSaved] = useState(
    false
  )
  const [isFocused, setIsFocused] = useState(false)
  const [plugins, setPlugins] = useState<null | any[]>(null)

  const additionalClassname = className ? ` ${className}` : ''
  const focusClassname = isFocused ? ' isFocused' : ''

  useEffect(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      positionSuggestions: getSuggestionsCenterPosition,
    })

    const linkifyPlugin = createLinkifyPlugin()

    setPlugins([mentionPlugin, linkifyPlugin])
    setMentionPlugin(mentionPlugin)
  }, [setPlugins])

  useEffect(() => {
    const contentValue = value && getContentFromMarkdown({ markdown: value })
    const stateValue =
      contentValue && EditorState.createWithContent(contentValue)
    const useEditorState = stateValue || EditorState.createEmpty()
    setKey(Math.random())
    setEditorState(useEditorState)
  }, [value])

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        // setTimeout hack to fix https://github.com/draft-js-plugins/draft-js-plugins/issues/800
        editorRef.current?.focus()
      })
    }
  }, [autoFocus])

  const onFocusCallback = useCallback(() => {
    setIsFocused(true)

    onFocus?.()
  }, [setIsFocused, onFocus])

  const submitDebounce = useCallback(debounce(editorSubmit, 30000), [
    editorSubmit,
  ])

  const submit = useCallback(() => {
    submitDebounce.cancel()

    editorSubmit({
      editorState,
      save,
      setContentChangedSinceSaved,
      clearOnSave,
      setEditorState,
      editorRef,
    })
  }, [clearOnSave, editorState, editorRef, save, submitDebounce])

  const androidSubmit = useCallback(() => {
    editorSubmitForAndroid({
      submit,
      editorRef,
      saveOnBlur,
    })
  }, [saveOnBlur, submit])

  const MentionSuggestions = mentionPlugin?.MentionSuggestions

  const onBlurCallback = useCallback(() => {
    setIsFocused(false)

    onBlur?.()

    if (saveOnBlur) {
      submit()
    }
  }, [setIsFocused, onBlur, submit, saveOnBlur])

  // onChangeCallback gets call every time editorState changes
  const onChangeCallback = useCallback(
    (newEditorState: EditorState) => {
      const currentContentState = editorState.getCurrentContent()
      const newContentState = newEditorState.getCurrentContent()
      const contentChanged = currentContentState !== newContentState

      if (contentChanged) {
        // Have to put this in here because onChange is triggered when component mounts, not sure why
        onChange?.()

        if (!contentChangedSinceSaved) {
          setContentChangedSinceSaved(true)
        }

        if (autoDebounceSave) {
          submitDebounce({
            editorState: newEditorState,
            save,
            setContentChangedSinceSaved,
            clearOnSave,
            setEditorState,
            editorRef,
          })
        }

        if (!isFocused) {
          setIsFocused(true)

          setTimeout(() => {
            // settime out, hack because focus will call onChange again before state changes
            editorRef.current?.focus()
          })
        }
      } else {
        // The change was triggered by a change in focus/selection
      }

      setEditorState(newEditorState)
    },
    [
      setIsFocused,
      autoDebounceSave,
      contentChangedSinceSaved,
      isFocused,
      onChange,
      submitDebounce,
      clearOnSave,
      editorState,
      save,
      editorRef,
    ]
  )

  return (
    <div
      className={`DraftEditor ${DraftEditorStyles} ${additionalClassname} ${focusClassname}`}
    >
      {headerToolBar &&
        headerToolBar({
          editorState,
          setEditorState: onChangeCallback,
          contentChangedSinceSaved,
          submit: isAndroid ? androidSubmit : submit,
        })}

      {plugins && (
        <PluginEditor
          key={key}
          customStyleMap={{
            CODE: {
              backgroundColor: 'rgba(29, 28, 29, 0.04)',
              color: '#e01e5a',
              borderRadius: '3px',
              padding: '2px 4px',
              border: `1px solid ${NEUTRAL_BORDER_COLOR}`,
            },
          }}
          ref={editorRef}
          editorState={editorState}
          onChange={onChangeCallback}
          plugins={plugins}
          onBlur={onBlurCallback}
          onFocus={onFocusCallback}
          placeholder={placeholder}
          autoCapitalize={isAndroid ? 'off' : 'on'}
          autoComplete={isAndroid ? 'off' : 'on'}
          // autoCorrect="off" will cause a bug in android when user backspace
          // all content and start typing again, the keyboard would go away
          autoCorrect="off"
          spellCheck={isAndroid ? false : true}
          stripPastedStyles={isAndroid ? true : false}
          keyBindingFn={(e: any) => {
            return keyBinding({
              e,
              saveOnEnter,
            })
          }}
          handleKeyCommand={(command: string) => {
            return handleKeyCommand({
              command,
              submit,
            })
          }}
        />
      )}

      {footerToolBar &&
        footerToolBar({
          editorState: editorState,
          setEditorState: onChangeCallback,
          contentChangedSinceSaved,
          submit: isAndroid ? androidSubmit : submit,
        })}

      <InlineEditorToolBarConnected
        editorState={editorState}
        setEditorState={onChangeCallback}
        alwaysShowInlineToolbar={alwaysShowInlineToolbar}
      />

      {MentionSuggestions && (
        <MentionSuggestionListConnected
          MentionSuggestionComponent={MentionSuggestions}
        />
      )}
    </div>
  )
}
