import { connect } from 'react-redux'
import { TeamSettingsMain } from './TeamSettingsMain'
import { TeamSettingsMainMapState } from './TeamSettingsMainMapState'
import { TeamSettingsMainMapDispatch } from './TeamSettingsMainMapDispatch'

import { TeamSettingsMainMapStateReturnType } from './types/TeamSettingsMainMapStateReturnType'
import { TeamSettingsMainMapDispatchReturnType } from './types/TeamSettingsMainMapDispatchReturnType'
import { TeamSettingsMainConnectedProps } from './types/TeamSettingsMainConnectedProps'


export const TeamSettingsMainConnected = connect<
  TeamSettingsMainMapStateReturnType,
  TeamSettingsMainMapDispatchReturnType,
  TeamSettingsMainConnectedProps>(TeamSettingsMainMapState,
    TeamSettingsMainMapDispatch)(TeamSettingsMain)
