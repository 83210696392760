import React from 'react'
import { EditorEmojiButtonProps } from './types/EditorEmojiButtonProps'
import { EmojiDropdownConnected } from 'components/dropdown/EmojiDropdown'

export const EditorEmojiButton = ({
  onEmojiSelect,
  dropdownId,
  onMouseDown,
}: EditorEmojiButtonProps): JSX.Element => {
  return (
    <EmojiDropdownConnected dropdownId={dropdownId} closeOnSelect={true} onEmojiSelect={onEmojiSelect} onMouseDown={onMouseDown} />
  )
}
