import { take, put, select, spawn } from 'redux-saga/effects'
import { firebaseSdk } from 'sdks'
import { createId } from 'firebaseFunctions/utilities/createId'

import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import { SUBMIT_NEW_PLAN, SUBMIT_NEW_PLAN_DONE } from 'actions'
import { postPlansSaga } from 'flows/sagas/post/postPlansSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { PlansReducer } from 'state/reducers/types/PlansReducer'

export const submitNewPlanF = function*() {
  while (1) {
    yield take([SUBMIT_NEW_PLAN])

    const state: StateReducers = yield select(selectState)
    const currentUserId = state && state.currentUserId
    const teamId = getCurrentTeamId({ state })
    const planId = createId()

    if (currentUserId && teamId) {
      const created = firebaseSdk.firestore.Timestamp.fromDate(new Date())

      const items: PlansReducer = {
        [planId]: {
          data: {
            lastModifiedUserId: currentUserId,
            status: 'open',
            created,
          },
          teamId,
        },
      }

      yield spawn(postPlansSaga, { items })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewPlanF',
        })
      )
    }

    yield put({
      type: SUBMIT_NEW_PLAN_DONE,
    })
  }
}
