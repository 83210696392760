import { SpinnerStyles } from './SpinnerStyles'
import * as React from 'react'
import { SpinnerProps } from './types/SpinnerProps'

export const Spinner = ({
  className = '',
  size,
  style,
}: SpinnerProps): JSX.Element => {
  const extraClassName = className ? ' ' + className : ''
  const sizeClassName = size ? ' ' + size : ''

  return (
    <div
      className={'Spinner ' + SpinnerStyles + extraClassName + sizeClassName}
      style={style}
    >
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}
