import { connect } from 'react-redux'
import { DropdownButton } from './DropdownButton'
import { DropdownButtonMapState } from './DropdownButtonMapState'
import { DropdownButtonMapDispatch } from './DropdownButtonMapDispatch'

import { DropdownButtonMapStateReturnType } from './types/DropdownButtonMapStateReturnType'
import { DropdownButtonMapDispatchReturnType } from './types/DropdownButtonMapDispatchReturnType'
import { DropdownButtonConnectedProps } from './types/DropdownButtonConnectedProps'


export const DropdownButtonConnected = connect<
  DropdownButtonMapStateReturnType,
  DropdownButtonMapDispatchReturnType,
  DropdownButtonConnectedProps>(DropdownButtonMapState,
    DropdownButtonMapDispatch)(DropdownButton)
