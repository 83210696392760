import { UNEXPECTED_ERROR } from 'actions'
import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { UnexpectedAction } from 'actionCreators/types/UnexpectedAction'
import { sentrySdk } from '../sdks/sentry/sentrySdk'
import { SentrySeverity } from '../sdks/sentry/SentrySeverity'

export const unexpectedErrorF = function*() {
  while (1) {
    const action: UnexpectedAction = yield take([UNEXPECTED_ERROR])

    sentrySdk.captureMessage(
      `UNEXPECTED_ERROR: ${action.description}`,
      SentrySeverity.Critical
    )

    if (!action.silent) {
      yield put(
        createToggleAction({
          description: 'unexpectedErrorF',
          toggles: {
            showModal: {
              type: 'Error',
            },
          },
        })
      )
    }
  }
}
