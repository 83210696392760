import { css } from 'emotion'
import { WARNING_HIGHLIGHT_COLOR } from 'constants/styleConstants'

export const TaskTagListStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  > li {
    text-align: left;
  }

  &.isDetail {
    display: block;
    text-align: left;

    > li {
      margin: 0px;
      padding: 8px;
      display: block;

      &:hover {
        background-color: ${WARNING_HIGHLIGHT_COLOR};
      }
    }

    .Dropdown {
      .chevron {
        left: 10px;
      }

      .container {
        left: 0px;
      }
    }
  }

  > li {
    margin: 4px;
  }
`
