import { PATCH_INVITES_FAILURE } from 'actions'

import { PatchInvitesFailureAction } from './types/PatchInvitesFailureAction'
import { PatchInvitesRequestActionValues } from './types/PatchInvitesRequestActionValues'


interface Props {
  requestValues: PatchInvitesRequestActionValues
}

export const createPatchInvitesFailureAction = ({
  requestValues,
}: Props): PatchInvitesFailureAction => {
  return {
    type: PATCH_INVITES_FAILURE,
    requestValues,
  }
}
