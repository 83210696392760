import { connect } from 'react-redux'
import { FilterByButton } from './FilterByButton'
import { FilterByButtonMapState } from './FilterByButtonMapState'
import { FilterByButtonMapDispatch } from './FilterByButtonMapDispatch'
import { FilterByButtonMapStateReturnType } from './types/FilterByButtonMapStateReturnType'
import { FilterByButtonMapDispatchReturnType } from './types/FilterByButtonMapDispatchReturnType'
import { FilterByButtonConnectedProps } from './types/FilterByButtonConnectedProps'

export const FilterByButtonConnected = connect<
  FilterByButtonMapStateReturnType,
  FilterByButtonMapDispatchReturnType,
  FilterByButtonConnectedProps
>(
  FilterByButtonMapState,
  FilterByButtonMapDispatch
)(FilterByButton)
