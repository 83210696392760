import { connect } from 'react-redux'
import { TeamDropdown } from './TeamDropdown'
import { TeamDropdownMapState } from './TeamDropdownMapState'
import { TeamDropdownMapDispatch } from './TeamDropdownMapDispatch'

import { TeamDropdownMapStateReturnType } from './types/TeamDropdownMapStateReturnType'
import { TeamDropdownMapDispatchReturnType } from './types/TeamDropdownMapDispatchReturnType'
import { TeamDropdownConnectedProps } from './types/TeamDropdownConnectedProps'


export const TeamDropdownConnected = connect<
  TeamDropdownMapStateReturnType,
  TeamDropdownMapDispatchReturnType,
  TeamDropdownConnectedProps>(TeamDropdownMapState,
    TeamDropdownMapDispatch)(TeamDropdown)
