import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from './getCurrentTeamId'
import { getDashboardRoute } from './getDashboardRoute'

import {
  LOGIN_ROUTE,
  TEAM_SETTINGS_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  TASK_STATUSES_ROUTE,
  MEMBERS_ROUTE,
  TASK_SIZES_ROUTE,
  SELECT_TEAM_ROUTE,
  TASK_DETAIL_ROUTE,
  LABELS_ROUTE,
  DASHBOARD_HOME_ROUTE,
} from 'routes'
import { getCurrentTaskId } from './getCurrentTaskId'

interface Props {
  state: StateReducers
}

interface Return {
  pathname?: string
  teamId?: string
  taskId?: string
}

export const getBackLocation = ({ state }: Props): Return => {
  const currentLocation = state.currentLocation || undefined
  const isRoutes = currentLocation && currentLocation.isRoutes
  const teamId = getCurrentTeamId({ state })
  const taskId = getCurrentTaskId({ state })
  const isTaskMainRoute = isRoutes?.isTaskMainRoute
  const isTeamSettingsRoute = isRoutes?.isTeamSettingsRoute
  const isResetPasswordSentRoute = isRoutes?.isResetPasswordSentRoute
  const isCreateUserRoute = isRoutes?.isCreateUserRoute
  const isTaskMessagesRoute = isRoutes?.isTaskMessagesRoute
  const isAccountSettingsRoute = isRoutes?.isAccountSettingsRoute
  const isAddNewMemberRoute = isRoutes?.isAddNewMemberRoute
  const isMembersRoute = isRoutes?.isMembersRoute
  const isMemberDetailRoute = isRoutes?.isMemberDetailRoute
  const isUpdateTaskStatusesRoute = isRoutes?.isUpdateTaskStatusesRoute
  const isUpdateTaskSizesRoute = isRoutes?.isUpdateTaskSizesRoute
  const isCreateTaskRoute = isRoutes?.isCreateTaskRoute
  const isResetPasswordRoute = isRoutes?.isResetPasswordRoute
  const isUpdateDisplayNameRoute = isRoutes?.isUpdateDisplayNameRoute
  const isTaskTagsRoute = isRoutes?.isTaskTagsRoute
  const isUpdatePasswordRoute = isRoutes?.isUpdatePasswordRoute
  const isAddNewLabelRoute = isRoutes?.isAddNewLabelRoute
  const isTaskStatusesRoute = isRoutes?.isTaskStatusesRoute
  const isTaskSizesRoute = isRoutes?.isTaskSizesRoute
  const isInviteDetailRoute = isRoutes?.isInviteDetailRoute
  const isLabelsRoute = isRoutes?.isLabelsRoute
  const isActivityNotificationRoute = isRoutes?.isActivityNotificationRoute
  const isDashboardHomeRoute = isRoutes?.isDashboardHomeRoute
  const isCurrentRoute = isRoutes?.isCurrentRoute
  const isPlanRoute = isRoutes && isRoutes.isPlanRoute
  const isTeamFilesRoute = isRoutes?.isTeamFilesRoute
  const isNotificationSettingsRoute = isRoutes?.isNotificationSettingsRoute
  const isUpgradeWorkspaceRoute = isRoutes?.isUpgradeWorkspaceRoute
  const isWelcomeToProRoute = isRoutes?.isWelcomeToProRoute
  const isWorkspaceSubscriptionRoute = isRoutes?.isWorkspaceSubscriptionRoute
  const isBillingRoute = isRoutes?.isBillingRoute
  const isProCanceledRoute = isRoutes?.isProCanceledRoute
  const isUpdateAvatarRoute = isRoutes?.isUpdateAvatarRoute
  const isDeleteWorkspaceRoute = isRoutes?.isDeleteWorkspaceRoute
  const isReportRoute = isRoutes?.isReportRoute
  const isUpdateTeamNameRoute = isRoutes?.isUpdateTeamNameRoute
  const isUpdateWorkspaceLogoRoute = isRoutes?.isUpdateWorkspaceLogoRoute
  const isPaymentFailedRoute = isRoutes?.isPaymentFailedRoute

  if (
    isDashboardHomeRoute ||
    isProCanceledRoute ||
    isPaymentFailedRoute ||
    (isAccountSettingsRoute && !teamId)
  ) {
    return {
      pathname: SELECT_TEAM_ROUTE,
      teamId,
    }
  }

  if (isCurrentRoute || isPlanRoute) {
    return {
      pathname: DASHBOARD_HOME_ROUTE,
      teamId,
    }
  }

  if (isAddNewLabelRoute) {
    return {
      pathname: LABELS_ROUTE,
      teamId,
    }
  }

  if (
    isUpdateDisplayNameRoute ||
    isUpdatePasswordRoute ||
    isNotificationSettingsRoute ||
    isBillingRoute ||
    isUpdateAvatarRoute
  ) {
    return {
      pathname: ACCOUNT_SETTINGS_ROUTE,
      teamId,
    }
  }

  if (isCreateUserRoute || isResetPasswordRoute || isResetPasswordSentRoute) {
    return {
      pathname: LOGIN_ROUTE,
    }
  }

  if (isUpdateTaskStatusesRoute) {
    return {
      pathname: TASK_STATUSES_ROUTE,
      teamId,
    }
  }

  if (isUpdateTaskSizesRoute) {
    return {
      pathname: TASK_SIZES_ROUTE,
      teamId,
    }
  }

  if (isInviteDetailRoute || isAddNewMemberRoute || isMemberDetailRoute) {
    return {
      pathname: MEMBERS_ROUTE,
      teamId,
    }
  }

  if (
    isTaskStatusesRoute ||
    isMembersRoute ||
    isLabelsRoute ||
    isTaskSizesRoute ||
    isTeamFilesRoute ||
    isUpgradeWorkspaceRoute ||
    isWelcomeToProRoute ||
    isWorkspaceSubscriptionRoute ||
    isDeleteWorkspaceRoute ||
    isReportRoute ||
    isUpdateTeamNameRoute ||
    isUpdateWorkspaceLogoRoute
  ) {
    return {
      pathname: TEAM_SETTINGS_ROUTE,
      teamId,
    }
  }

  if (isTaskMessagesRoute) {
    return {
      pathname: TASK_DETAIL_ROUTE,
      teamId,
      taskId,
    }
  }

  if (
    isTaskMainRoute ||
    isTeamSettingsRoute ||
    (isAccountSettingsRoute && teamId) ||
    isCreateTaskRoute ||
    isActivityNotificationRoute ||
    isTaskTagsRoute
  ) {
    return {
      pathname: getDashboardRoute({ state }),
      teamId,
    }
  }

  return {}
}
