import React from 'react'
import { EllipsisHorizontalIconProps } from './types/EllipsisHorizontalIconProps'
import { iconStyles } from 'styles/iconStyles'

export const EllipsisHorizontalIcon = ({
  className = '',
  style,
}: EllipsisHorizontalIconProps): JSX.Element => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={`EllipsisHorizontalIcon ${className} ${iconStyles}`}
      role="img"
      viewBox="0 0 512 512"
      style={style}
    >
      <path
        fill="currentColor"
        d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
      ></path>
    </svg>
  )
}
