import { FileDropInputStyles } from './FileDropInputStyles'
import React from 'react'
import { FileDropInputProps } from './types/FileDropInputProps'
import { useDropzone } from 'react-dropzone'
import { Button } from 'components/button/Button'
import { FileUploadIcon } from 'components/icon/FileUploadIcon/FileUploadIcon'
import { lightBoxStyles } from 'styles/lightBoxStyles'

export const FileDropInput = ({
  className = '',
  accept,
  onChange,
  ...props
}: FileDropInputProps): JSX.Element => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop: onChange,
  })
  const isDragActiveClassName = isDragActive ? ' isDragActive' : ''

  return (
    <div
      className={`FileDropInput ${FileDropInputStyles} ${className} ${isDragActiveClassName}`}
      {...getRootProps()}
    >
      <label className={`dropContainer ${lightBoxStyles}`}>
        <FileUploadIcon />

        <div>Drag a file here</div>

        <div className="or">- or -</div>

        <Button type="button">Select a file from your computer</Button>
      </label>

      <input {...props} {...getInputProps()} />
    </div>
  )
}
