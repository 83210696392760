import { connect } from 'react-redux'
import { MainWatcher } from './MainWatcher'
import { MainWatcherMapState } from './MainWatcherMapState'
import { MainWatcherMapDispatch } from './MainWatcherMapDispatch'

import { MainWatcherMapStateReturnType } from './types/MainWatcherMapStateReturnType'
import { MainWatcherMapDispatchReturnType } from './types/MainWatcherMapDispatchReturnType'
import { MainWatcherConnectedProps } from './types/MainWatcherConnectedProps'


export const MainWatcherConnected = connect<
  MainWatcherMapStateReturnType,
  MainWatcherMapDispatchReturnType,
  MainWatcherConnectedProps>(MainWatcherMapState,
    MainWatcherMapDispatch)(MainWatcher)
