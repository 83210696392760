import { Dispatch, Action } from 'redux'
import { LoadStripeInvoicesButtonMapDispatchReturnType } from './types/LoadStripeInvoicesButtonMapDispatchReturnType'
import { createErrorAction } from '../../../actionCreators/createErrorAction'

export const LoadStripeInvoicesButtonMapDispatch = (
  dispatch: Dispatch<Action>
): LoadStripeInvoicesButtonMapDispatchReturnType => {
  return {
    handleError: (error) => {
      dispatch(
        createErrorAction({
          error,
          description: 'LoadStripeInvoicesButtonMapDispatch',
        })
      )
    },
  }
}
