import { GET_INVITES_FAILURE } from 'actions'

import { GetInvitesFailureAction } from './types/GetInvitesFailureAction'
import { GetInvitesRequestActionValues } from './types/GetInvitesRequestActionValues'


interface Props {
  requestValues: GetInvitesRequestActionValues
}

export const createGetInvitesFailureAction = ({ requestValues }: Props): GetInvitesFailureAction => {
  return {
    type: GET_INVITES_FAILURE,
    requestValues,
  }
}
