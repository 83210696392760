import { css } from 'emotion'
import {
  LINK_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_LIGHT,
} from 'constants/styleConstants'

export const iconButtonStyles = css`
  color: ${TEXT_COLOR_LIGHT};

  &:hover,
  &:active {
    color: ${TEXT_COLOR};
  }

  &.active {
    color: ${LINK_COLOR};

    &:hover,
    &:active {
      color: ${LINK_COLOR};
    }
  }
`
