import { StripeSubscriptionFieldStyles } from './StripeSubscriptionFieldStyles'
import React, { useState } from 'react'
import { StripeSubscriptionFieldProps } from './types/StripeSubscriptionFieldProps'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { DANGER_COLOR } from 'constants/styleConstants'
import { postStripeSubscriptionsApi } from 'apis/post/postStripeSubscriptionsApi'
import { getIdToken } from 'sdks/firebase/getIdToken'
import { StripeSubscriptions } from 'firebaseFunctions/types/StripeSubscriptions'
import { createId } from 'firebaseFunctions/utilities/createId'
import { Spinner } from 'components/widget/Spinner'
import { ActionButton } from 'components/button/ActionButton'
import { ErrorMessage } from '../../message/ErrorMessage'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { statusCodes } from '../../../firebaseFunctions/statusCodes'

export const StripeSubscriptionField = ({
  handleApiError,
  canGetToken,
  teamId,
  addSubscriptions,
  redirectNext,
}: StripeSubscriptionFieldProps): JSX.Element | null => {
  const stripe = useStripe()
  const elements = useElements()
  const [showSpinner, setShowSpinner] = useState(false)
  const [errorState, setErrorState] = useState('')

  if (!stripe || !elements || !canGetToken || !teamId) {
    // dependencies not yet loaded.
    return null
  }

  const handleSubmit = async () => {
    const card = elements.getElement(CardElement)
    const token = await getIdToken()

    if (!card || !token) {
      return
    }

    setShowSpinner(true)
    setErrorState('')

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card,
    })
    const stripePaymentId = result?.paymentMethod?.id

    if (!stripePaymentId) {
      setShowSpinner(false)
      setErrorState('Your card is invalid.')
      return
    }

    try {
      const tempId = createId()
      const subscriptions: StripeSubscriptions = {
        [tempId]: {
          teamId,
          stripePaymentId,
        },
      }

      const postResponse = await postStripeSubscriptionsApi({
        values: { items: subscriptions },
        apiHeaders: {
          token,
        },
      })

      addSubscriptions?.(postResponse.data.items)
      setShowSpinner(false)
      redirectNext(teamId) // will cause unmount
    } catch (error) {
      if (error?.response?.status === statusCodes.unprocessable) {
        setErrorState(error?.response?.data?.message)
      } else {
        handleApiError(error, 'StripeSubscriptionField')
      }
      setShowSpinner(false)
    }
  }

  return (
    <div className={`StripeSubscriptionField ${StripeSubscriptionFieldStyles}`}>
      <div className="cardElementContainer">
        <CardElement
          options={{
            style: {
              base: {
                padding: 16,
                fontSize: '16px',
              },
              invalid: {
                color: DANGER_COLOR,
                iconColor: DANGER_COLOR,
              },
            },
          }}
        />
      </div>

      {errorState && (
        <ErrorMessage className={marginTopMediumStyles}>
          {errorState}
        </ErrorMessage>
      )}

      <ActionButton
        title="submit"
        className="big"
        onClick={handleSubmit}
        disabled={showSpinner}
      >
        {showSpinner && <Spinner />}
        {!showSpinner && 'Submit'}
      </ActionButton>
    </div>
  )
}
