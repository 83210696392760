import { TeamCardStyles } from './TeamCardStyles'
import React from 'react'
import { TeamCardProps } from './types/TeamCardProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { DASHBOARD_HOME_ROUTE } from 'routes'
import { TEAM_NOUN } from 'constants/english'
import { CountBubbleWidget } from 'components/widget/CountBubbleWidget'
import { TeamDropdownConnected } from 'components/dropdown/TeamDropdown'
import { lightBoxCardStyles } from 'styles/lightBoxCardStyles'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { TeamNameWidgetConnected } from '../../widget/TeamNameWidget'

export const TeamCard = ({
  teamId,
  unreadCount,
}: TeamCardProps): JSX.Element => {
  return (
    <div className={`TeamCard ${TeamCardStyles} ${lightBoxCardStyles}`}>
      <CustomLinkConnected
        title={`link to ${TEAM_NOUN}`}
        className={`spaceBetween teamLink ${textLinkStyles}`}
        route={DASHBOARD_HOME_ROUTE}
        teamId={teamId}
      >
        <TeamNameWidgetConnected teamId={teamId} />
      </CustomLinkConnected>

      <div className="settingsContainer centerFlex">
        {unreadCount > 0 && (
          <CountBubbleWidget>{unreadCount}</CountBubbleWidget>
        )}

        <TeamDropdownConnected teamId={teamId} />
      </div>
    </div>
  )
}
