import { StateReducers } from 'state/reducers/types/StateReducers'

import { UploadWatcherConnectedProps } from './types/UploadWatcherConnectedProps'
import { UploadWatcherMapStateReturnType } from './types/UploadWatcherMapStateReturnType'


export const UploadWatcherMapState = (
  state: StateReducers,
  props: UploadWatcherConnectedProps
): UploadWatcherMapStateReturnType => {
  const uploads = state.uploads || undefined
  const upload = uploads && uploads[props.uploadId]

  return {
    upload,
  }
}
