import { getInvitesRef } from './getInvitesRef'
import { FirestoreClient, Invite } from '../../../types'
import firebase from 'firebase'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  inviteId: string
}

export const getInviteRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  inviteId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<Invite>
  : FirebaseFirestore.DocumentReference => {
  return getInvitesRef({
    firestore,
    teamId,
  }).doc(inviteId) as any
}
