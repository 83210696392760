import { NewInviteFormStyles } from './NewInviteFormStyles'
import React, { useState, useCallback } from 'react'
import { NewInviteFormProps } from './types/NewInviteFormProps'
import { NoteMessage } from 'components/message/NoteMessage'
import { USERS_NOUN } from 'constants/english'
import { UPGRADE_WORKSPACE_ROUTE } from 'routes'
import { PRO_PRODUCT_NOUN, FREE_PRODUCT_NOUN } from 'constants/english'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { FormFooter } from 'components/footer/FormFooter'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { isInvalidEmails } from 'utilities/is/isInvalidEmails'
import { SelectInputField } from 'components/field/SelectInputField/SelectInputField'
import { roleOptions } from 'constants/roleOptions'
import { RoleTypes } from 'firebaseFunctions/types'
import { SelectOption } from 'types/SelectOption'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { marginBottomLargeStyles } from '../../../styles/margin/marginBottomLargeStyles'

export const NewInviteForm = ({
  maxUserCountReachedFree,
  maxUserCountReachedPro,
  isPro,
  maxEmailCount,
  blacklist,
  submit,
}: NewInviteFormProps): JSX.Element => {
  const [emailState, setEmailState] = useState('')
  const [emailErrorState, setEmailErrorState] = useState<string | null>(null)
  const [roleState, setRoleState] = useState<RoleTypes>()
  const [roleErrorState, setRoleErrorState] = useState<string | null>(null)

  const validateEmails = useCallback(() => {
    const error = isInvalidEmails({
      value: emailState,
      maxEmailCount,
      blacklist,
    })

    setEmailErrorState(error || null)

    return error
  }, [emailState, setEmailErrorState, blacklist, maxEmailCount])

  const validateRole = useCallback(() => {
    const error = roleState ? null : 'Please select a role'

    setRoleErrorState(error)

    return error
  }, [roleState, setRoleErrorState])

  return (
    <form
      className={`NewInviteForm ${NewInviteFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const emailsError = validateEmails()
        const roleError = validateRole()

        if (!roleError && !emailsError && roleState) {
          submit({
            emails: emailState,
            role: roleState,
          })
        }
      }}
    >
      {!isPro && (
        <NoteMessage className={`${marginBottomLargeStyles}`}>
          Invite more {USERS_NOUN} by upgrading to{' '}
          <CustomLinkConnected
            route={UPGRADE_WORKSPACE_ROUTE}
            title="upgrade"
            className={`${textLinkStyles}`}
            withCurrentQuery={true}
          >
            {PRO_PRODUCT_NOUN}
          </CustomLinkConnected>
        </NoteMessage>
      )}
      <HeaderOneTextConnected>Invite {USERS_NOUN}</HeaderOneTextConnected>

      <TextInputField
        label="Email Address"
        name="email"
        type="textarea"
        placeholder="example1@example.com, example2@example.com"
        error={emailErrorState}
        onChange={(e) => {
          setEmailErrorState(null)
          setEmailState(e.target.value)
        }}
        onBlur={validateEmails}
      />

      <SelectInputField
        label="Role"
        error={roleErrorState}
        options={roleOptions}
        className={`${marginTopLargeStyles}`}
        onChange={(option: SelectOption) => {
          setRoleErrorState(null)
          // TODO: get rid of as
          const role = option.value as RoleTypes
          setRoleState(role)
        }}
        onBlur={validateRole}
      />

      {maxUserCountReachedFree && !isPro && (
        <ErrorMessage className={`${marginTopLargeStyles}`}>
          You have reached the limit for {FREE_PRODUCT_NOUN}.
        </ErrorMessage>
      )}

      {maxUserCountReachedPro && isPro && (
        <ErrorMessage className={`${marginTopLargeStyles}`}>
          You have reach the limit for {PRO_PRODUCT_NOUN}.
        </ErrorMessage>
      )}

      <FormFooter
        label="Send Invitation"
        disabled={maxUserCountReachedPro || maxUserCountReachedFree}
      />
    </form>
  )
}
