import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

import { LabelCardConnectedProps } from './types/LabelCardConnectedProps'
import { LabelCardMapStateReturnType } from './types/LabelCardMapStateReturnType'


export const LabelCardMapState = (
  state: StateReducers,
  props: LabelCardConnectedProps,
): LabelCardMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const labels = team?.labels
  const label = labels?.[props.labelId]
  const icon = label?.icon
  const archived = label?.archived
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {
    icon,
    archived,
  }
}
