import { connect } from 'react-redux'
import { UserInviteList } from './UserInviteList'
import { UserInviteListMapState } from './UserInviteListMapState'
import { UserInviteListMapDispatch } from './UserInviteListMapDispatch'

import { UserInviteListMapStateReturnType } from './types/UserInviteListMapStateReturnType'
import { UserInviteListMapDispatchReturnType } from './types/UserInviteListMapDispatchReturnType'
import { UserInviteListConnectedProps } from './types/UserInviteListConnectedProps'


export const UserInviteListConnected = connect<
  UserInviteListMapStateReturnType,
  UserInviteListMapDispatchReturnType,
  UserInviteListConnectedProps>(UserInviteListMapState,
    UserInviteListMapDispatch)(UserInviteList)
