import { take, put, spawn } from 'redux-saga/effects'
import { firebaseSdk } from 'sdks'
import {
  SUBMIT_DELETE_CUSTOM_CLAIM,
  SUBMIT_DELETE_CUSTOM_CLAIM_DONE,
} from 'actions'
import { deleteCustomClaimSaga } from 'flows/sagas/delete/deleteCustomClaimSaga'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

export const submitDeleteCustomClaimF = function*() {
  while (1) {
    yield take([SUBMIT_DELETE_CUSTOM_CLAIM])

    const currentUser = firebaseSdk.auth().currentUser

    if (currentUser) {
      const tokenResult: firebase.auth.IdTokenResult = yield currentUser.getIdTokenResult()

      yield spawn(deleteCustomClaimSaga, {
        apiHeaders: { token: tokenResult.token },
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteCustomClaimF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_CUSTOM_CLAIM_DONE,
    })
  }
}
