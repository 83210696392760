import { UserInvitesMainConnectedProps } from './types/UserInvitesMainConnectedProps'
import { UserInvitesMainMapStateReturnType } from './types/UserInvitesMainMapStateReturnType'

import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentUser } from 'state/getters/getCurrentUser'

export const UserInvitesMainMapState = (
  state: StateReducers,
  _props: UserInvitesMainConnectedProps
): UserInvitesMainMapStateReturnType => {
  const currentUser = getCurrentUser({ state })
  const email = currentUser?.email

  return {
    email,
  }
}
