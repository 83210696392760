import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  // ModalLayoutConnectedProps,
  ModalLayoutMapDispatchReturnType,
} from './types'

export const ModalLayoutMapDispatch = (
  dispatch: Dispatch<Action>
  // props: ModalLayoutConnectedProps,
): ModalLayoutMapDispatchReturnType => {
  return {
    onCancel: () => {
      const toggleAction = createToggleAction({
        description: 'ModalLayoutMapDispatch',
        toggles: {
          showModal: false,
        },
      })

      dispatch(toggleAction)
    },
  }
}
