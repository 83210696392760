import { css } from 'emotion'

export const ContentLoaderWidgetStyles = css`
  div {
    margin-top: 16px;
    @keyframes bgcolor {
      0% {
        background-color: #fff;
      }
      50% {
        background-color: #eee;
      }
      100% {
        background-color: #fff;
      }
    }

    animation: bgcolor 2s infinite linear alternate;

    &:first-child {
      width: 100%;
      height: 50px;
      margin-top: 0;
    }

    &:nth-child(2) {
      width: 100%;
      height: 50px;
    }

    &:nth-child(3) {
      width: 100%;
      height: 50px;
    }
  }
`
