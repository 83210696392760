import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUserRole } from 'state/getters/getUserRole'

import { MemberCardConnectedProps } from './types/MemberCardConnectedProps'
import { MemberCardMapStateReturnType } from './types/MemberCardMapStateReturnType'


export const MemberCardMapState = (
  state: StateReducers,
  props: MemberCardConnectedProps,
): MemberCardMapStateReturnType => {
  const userRole = getUserRole({ state, userId: props.userId }) 
  const email = userRole && userRole.email
  const role = userRole && userRole.role
  const displayName = userRole && userRole.displayName
  const photoURL = userRole && userRole.photoURL

  return {
    email,
    roleType: role,
    displayName,
    photoURL,
  }
}
