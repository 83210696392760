import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUserRoles } from 'state/getters/getUserRoles'

import {
  // MemberListConnectedProps,
  MemberListMapStateReturnType,
} from './types'
import { getSortedByCreated } from 'utilities/getSortedByCreated'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

export const MemberListMapState = (
  state: StateReducers
  // props: MemberListConnectedProps,
): MemberListMapStateReturnType => {
  const userRoles = getUserRoles({ state })
  const userIds = userRoles ? Object.keys(userRoles) : []
  const invites = state.invites || undefined

  const { ids } = getSortedByCreated({
    itemsObject: invites,
    reverse: true,
  })

  return {
    userIds,
    orderedInviteIds: memoizeFlatArray(
      ids,
      'MemberListMapState-orderedInviteIds'
    ),
  }
}
