export const CLIENT_CREATE_ACCOUNT_ROUTE = '/create-account'
export const CLIENT_TASK_MESSAGES_ROUTE = '/task-messages'
export const CLIENT_TASK_DETAIL_ROUTE = '/task-detail'
export const CLIENT_SELECT_TEAM_ROUTE = '/select-workspace'
export const CLIENT_DASHBOARD_HOME_ROUTE = '/todo'
export const CLIENT_LOGIN_ROUTE = '/login'
export const CLIENT_QUERY_NAME_INVITE_ID = 'invite_id'
export const CLIENT_QUERY_NAME_TEAM_ID = 'team_id' // will have to be changed in many places since they were hard coded
export const CLIENT_QUERY_NAME_TASK_ID = 'task_id'
export const CLIENT_QUERY_NAME_TEAM_NAME = 'team_name'
export const CLIENT_QUERY_NAME_SECRET = 'secret'
export const CLIENT_QUERY_NAME_EMAIL = 'email'
export const CLIENT_QUERY_NAME_REFRESH_ID_TOKEN = 'refresh_id_token'
export const LOGO_FILE_NAME_PREFIX = 'logo'
export const THUMB_FILE_NAME_PREFIX = 'cc_thumb_'
export const CLIENT_WORKSPACE_MAX_USER_COUNT_FREE = 10
export const CLIENT_WORKSPACE_MAX_USER_COUNT_PRO = 20
export const CLIENT_UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO = 0.2
export const CLIENT_UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE = 0.1
export const CLIENT_SYNC_TASK_COOLDOWN = 18000000
