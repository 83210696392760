import * as React from 'react'
import { UploadFilesListConnected } from './UploadFilesListConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const UploadFilesListExamples = () => {
  return (
    <div className="UploadFilesListExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <UploadFilesListConnected />
      </Provider>
    </div>
  )
}
