import { connect } from 'react-redux'
import { TestingMain } from './TestingMain'
import { TestingMainMapState } from './TestingMainMapState'
import { TestingMainMapDispatch } from './TestingMainMapDispatch'

import { TestingMainMapStateReturnType } from './types/TestingMainMapStateReturnType'
import { TestingMainMapDispatchReturnType } from './types/TestingMainMapDispatchReturnType'
import { TestingMainConnectedProps } from './types/TestingMainConnectedProps'


export const TestingMainConnected = connect<
  TestingMainMapStateReturnType,
  TestingMainMapDispatchReturnType,
  TestingMainConnectedProps>(TestingMainMapState,
    TestingMainMapDispatch)(TestingMain)
