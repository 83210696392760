import { connect } from 'react-redux'
import { UserInvitesWatcher } from './UserInvitesWatcher'
import { UserInvitesWatcherMapState } from './UserInvitesWatcherMapState'
import { UserInvitesWatcherMapDispatch } from './UserInvitesWatcherMapDispatch'

import { UserInvitesWatcherMapStateReturnType } from './types/UserInvitesWatcherMapStateReturnType'
import { UserInvitesWatcherMapDispatchReturnType } from './types/UserInvitesWatcherMapDispatchReturnType'
import { UserInvitesWatcherConnectedProps } from './types/UserInvitesWatcherConnectedProps'


export const UserInvitesWatcherConnected = connect<
  UserInvitesWatcherMapStateReturnType,
  UserInvitesWatcherMapDispatchReturnType,
  UserInvitesWatcherConnectedProps>(UserInvitesWatcherMapState,
    UserInvitesWatcherMapDispatch)(UserInvitesWatcher)
