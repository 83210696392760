import { LabelsMainStyles } from './LabelsMainStyles'
import React from 'react'
// import { LabelsMainProps } from './types/LabelsMainProps'
import { LabelListConnected } from 'components/list/LabelList'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { LABELS_NOUN } from 'constants/english'
import { NoteMessage } from 'components/message/NoteMessage'
import { ADD_NEW_LABEL_ROUTE } from 'routes'
import { PlusSolidIcon } from 'components/icon/PlusSolidIcon/PlusSolidIcon'
import { ArchiveSolidIcon } from 'components/icon/ArchiveSolidIcon/ArchiveSolidIcon'
import { flexRightStyles } from 'styles/flex/flexRightStyles'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { HeaderTwoTextConnected } from '../../text/HeaderTwoText/HeaderTwoTextConnected'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const LabelsMain = (): JSX.Element => {
  return (
    <main className={'LabelsMain ' + LabelsMainStyles}>
      <HeaderOneTextConnected>{LABELS_NOUN}</HeaderOneTextConnected>

      <header className={`labelHeader ${flexRightStyles}`}>
        <CustomLinkConnected
          route={ADD_NEW_LABEL_ROUTE}
          withCurrentQuery={true}
          title={`Add`}
          className={actionButtonStyles}
        >
          <IconAndDiv icon={<PlusSolidIcon />}>New</IconAndDiv>
        </CustomLinkConnected>
      </header>

      <LabelListConnected
        className={marginTopMediumStyles}
        showOnlyActive={true}
      />

      <HeaderTwoTextConnected className={marginTopLargeStyles}>
        <IconAndDiv icon={<ArchiveSolidIcon />}>Archived</IconAndDiv>
      </HeaderTwoTextConnected>

      <NoteMessage>
        Archived tags will no longer show up in the menu.
      </NoteMessage>

      <LabelListConnected showOnlyArchive={true} />
    </main>
  )
}
