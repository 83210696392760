import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdateTaskStatusesFormConnectedProps } from './types/UpdateTaskStatusesFormConnectedProps'
import { UpdateTaskStatusesFormMapStateReturnType } from './types/UpdateTaskStatusesFormMapStateReturnType'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

export const UpdateTaskStatusesFormMapState = (
  state: StateReducers,
  _props: UpdateTaskStatusesFormConnectedProps
): UpdateTaskStatusesFormMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const taskStatuses = team && team.taskStatuses

  return {
    taskStatusesCsv: taskStatuses?.join(', '),
  }
}
