import { PATCH_MESSAGES_FAILURE } from 'actions'

import { PatchMessagesFailureAction } from './types/PatchMessagesFailureAction'
import { PatchMessagesRequestActionValues } from './types/PatchMessagesRequestActionValues'


interface Props {
  requestValues: PatchMessagesRequestActionValues
}

export const createPatchMessagesFailureAction = ({
  requestValues,
}: Props): PatchMessagesFailureAction => {
  return {
    type: PATCH_MESSAGES_FAILURE,
    requestValues,
  }
}
