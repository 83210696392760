import { connect } from 'react-redux'
import { TeamCard } from './TeamCard'
import { TeamCardMapState } from './TeamCardMapState'
import { TeamCardMapDispatch } from './TeamCardMapDispatch'

import { TeamCardMapStateReturnType } from './types/TeamCardMapStateReturnType'
import { TeamCardMapDispatchReturnType } from './types/TeamCardMapDispatchReturnType'
import { TeamCardConnectedProps } from './types/TeamCardConnectedProps'


export const TeamCardConnected = connect<
  TeamCardMapStateReturnType,
  TeamCardMapDispatchReturnType,
  TeamCardConnectedProps>(TeamCardMapState,
    TeamCardMapDispatch)(TeamCard)
