// import { Dispatch, Action } from 'redux'

import {
  // UserInviteListConnectedProps,
  UserInviteListMapDispatchReturnType,
} from './types'

export const UserInviteListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: UserInviteListConnectedProps,
): UserInviteListMapDispatchReturnType => {
  return {
  }
}
