import { map } from 'lodash'
import { TasksReducer }  from 'state/reducers/types/TasksReducer'

interface Props {
  itemsObject?: TasksReducer
  reverse?: boolean
}

interface IdAndOrder {
  itemId: string
  order: number
}

export const getSortedByOrder = ({ itemsObject, reverse, }: Props): string[] => {
  if (!itemsObject) {
    return []
  }

  const idAndOrderItems: IdAndOrder[] = map(itemsObject, (item, itemId) => {
    const order = item.data?.order || -999999999

    return {
      itemId,
      order: order,
    }
  })

  const sortedIdAndOrderItems = idAndOrderItems.sort((itemA, itemB) => {
    const { order: itemAOrder } = itemA
    const { order: itemBOrder } = itemB

    if (itemAOrder < itemBOrder) {
      return reverse ? 1 : -1
    }

    if (itemAOrder > itemBOrder) {
      return reverse ?  -1 : 1
    }

    return 0
  })

  const ids = sortedIdAndOrderItems.map(({ itemId }) => {
    return itemId
  })

  return ids
}
