import { css } from 'emotion'
import { WARNING_HIGHLIGHT_COLOR } from '../../../constants/styleConstants'

export const EnableNotificationMessageStyles = css`
  position: fixed;
  top: 0;
  background-color: ${WARNING_HIGHLIGHT_COLOR};
  width: 100%;
  text-align: center;
  padding: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10000;
`
