import { WelcomeToProMainStyles } from './WelcomeToProMainStyles'
import React from 'react'
import { WelcomeToProMainProps } from './types/WelcomeToProMainProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { ADD_NEW_MEMBER_ROUTE } from 'routes'
import { USERS_NOUN, USER_NOUN } from 'constants/english'
import { UsersIcon } from 'components/icon/UsersIcon/UsersIcon'
import { FileUploadIcon } from 'components/icon/FileUploadIcon/FileUploadIcon'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { ListSolidIcon } from '../../icon/ListSolidIcon/ListSolidIcon'

export const WelcomeToProMain = ({
  gigabytesPerUserPro,
}: WelcomeToProMainProps): JSX.Element => {
  return (
    <div className={`WelcomeToProMain ${WelcomeToProMainStyles}`}>
      <h1>Welcome to EggKat Pro!</h1>

      <div className={lightBoxStyles}>
        <IconAndDiv icon={<UsersIcon />}>
          {' '}
          More {USERS_NOUN},{' '}
          <CustomLinkConnected
            route={ADD_NEW_MEMBER_ROUTE}
            withCurrentQuery={true}
            title="Invite"
            className={`${textLinkStyles}`}
          >
            invite now!
          </CustomLinkConnected>
        </IconAndDiv>
      </div>

      <div className={lightBoxStyles}>
        <IconAndDiv icon={<FileUploadIcon />}>
          {gigabytesPerUserPro} GB storage per {USER_NOUN}
        </IconAndDiv>
      </div>

      <div className={lightBoxStyles}>
        <IconAndDiv icon={<ListSolidIcon />}>Larger page sizes</IconAndDiv>
      </div>
    </div>
  )
}
