import { connect } from 'react-redux'
import { DueDateDropdown } from './DueDateDropdown'
import { DueDateDropdownMapState } from './DueDateDropdownMapState'
import { DueDateDropdownMapDispatch } from './DueDateDropdownMapDispatch'

import { DueDateDropdownMapStateReturnType } from './types/DueDateDropdownMapStateReturnType'
import { DueDateDropdownMapDispatchReturnType } from './types/DueDateDropdownMapDispatchReturnType'
import { DueDateDropdownConnectedProps } from './types/DueDateDropdownConnectedProps'


export const DueDateDropdownConnected = connect<
  DueDateDropdownMapStateReturnType,
  DueDateDropdownMapDispatchReturnType,
  DueDateDropdownConnectedProps>(DueDateDropdownMapState,
    DueDateDropdownMapDispatch)(DueDateDropdown)
