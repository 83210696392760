import { Action } from 'redux'
import {
  SIGN_OUT_SUCCESS,
  GET_UNSCOPE_USER_SUCCESS,
  PATCH_UNSCOPE_USER_REQUEST,
  POST_UNSCOPE_USER_REQUEST,
} from 'actions'
import { CurrentUnscopeUserReducer } from './types/CurrentUnscopeUserReducer'
import { GetUnscopeUserSuccessAction } from 'actionCreators/get/GetUnscopeUser/types/GetUnscopeUserSuccessAction'
import { PostUnscopeUserRequestAction } from 'actionCreators/post/PostUnscopeUser/types/PostUnscopeUserRequestAction'
import { PatchUnscopeUserRequestAction } from 'actionCreators/patch/PatchUnscopeUser/types/PatchUnscopeUserRequestAction'

export const currentUnscopeUserReducer = (
  state?: CurrentUnscopeUserReducer,
  action?: Action
): CurrentUnscopeUserReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case GET_UNSCOPE_USER_SUCCESS: {
      const getUnscopeUserSuccessAction = action as GetUnscopeUserSuccessAction
      const getUnscopeUserSuccessValues =
        getUnscopeUserSuccessAction.successValues

      return {
        ...getUnscopeUserSuccessValues.item,
      }
    }

    case PATCH_UNSCOPE_USER_REQUEST: {
      const patchUnscopeUserSuccessAction = action as PatchUnscopeUserRequestAction
      const patchUnscopeUserSuccessValues =
        patchUnscopeUserSuccessAction.requestValues

      return {
        ...state,
        ...patchUnscopeUserSuccessValues.item,
      }
    }

    case POST_UNSCOPE_USER_REQUEST: {
      const postUnscopeUserSuccessAction = action as PostUnscopeUserRequestAction
      const postUnscopeUserSuccessValues =
        postUnscopeUserSuccessAction.requestValues

      return {
        ...postUnscopeUserSuccessValues.item,
      }
    }

    case SIGN_OUT_SUCCESS: {
      return null
    }
  }

  return state || null
}
