import { StateReducers } from 'state/reducers/types/StateReducers'

import { ServiceWorkerMessageWatcherConnectedProps } from './types/ServiceWorkerMessageWatcherConnectedProps'
import { ServiceWorkerMessageWatcherMapStateReturnType } from './types/ServiceWorkerMessageWatcherMapStateReturnType'


export const ServiceWorkerMessageWatcherMapState = (
  _state: StateReducers,
  _props: ServiceWorkerMessageWatcherConnectedProps
): ServiceWorkerMessageWatcherMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
