import { Subscription } from '../../types/Subscription'

interface Props {
  subscription?: Subscription | null
}

export const getIsProFromSubscription = ({ subscription }: Props) => {
  if (!subscription) {
    return false
  }

  const currentPeriodEndSeconds = subscription?.currentPeriodEndSeconds
  const nowSeconds = new Date().getTime() / 1000

  if (currentPeriodEndSeconds && currentPeriodEndSeconds >= nowSeconds) {
    return true
  }

  return false
}
