import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SelectOption } from 'types/SelectOption'
import { RoleTypes } from 'firebaseFunctions/types'
import { SUBMIT_UPDATE_ROLE, SUBMIT_DELETE_USER_ROLE } from 'actions'

import { MemberMainConnectedProps } from './types/MemberMainConnectedProps'
import { MemberMainMapDispatchReturnType } from './types/MemberMainMapDispatchReturnType'


export const MemberMainMapDispatch = (
  dispatch: Dispatch<Action>,
  props: MemberMainConnectedProps,
): MemberMainMapDispatchReturnType => {
  return {
    updateRole: (selectOption: SelectOption) => {
      const action = createSubmitAction({
        values: {
          role: selectOption.value as RoleTypes,
          userId: props.userId,
        },
        type: SUBMIT_UPDATE_ROLE,
      })

      dispatch(action)
    },

    deleteUserRole: () => {
      const action = createSubmitAction({
        values: {
          userId: props.userId,
        },
        type: SUBMIT_DELETE_USER_ROLE,
      })

      dispatch(action)
    },
  }
}
