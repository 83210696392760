import { DELETE_TASKS_REQUEST } from 'actions'
import { DeleteTasksRequestActionValues } from './types/DeleteTasksRequestActionValues'
import { DeleteTasksRequestAction } from './types/DeleteTasksRequestAction'

interface Props {
  requestValues: DeleteTasksRequestActionValues
}

export const createDeleteTasksRequestAction = ({
  requestValues,
}: Props): DeleteTasksRequestAction => {
  return {
    type: DELETE_TASKS_REQUEST,
    requestValues,
  }
}
