// import { Dispatch, Action } from 'redux'

import {
  // CollapsibleDivConnectedProps,
  CollapsibleDivMapDispatchReturnType,
} from './types'

export const CollapsibleDivMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: CollapsibleDivConnectedProps,
): CollapsibleDivMapDispatchReturnType => {
  return {
  }
}
