import { SelectInputFieldStyles } from './SelectInputFieldStyles'
import React from 'react'
import { SelectInputFieldProps } from './types/SelectInputFieldProps'
import { labelStyles } from 'styles/labelStyles'
import { SelectInput } from 'components/input/SelectInput'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { marginLeftSmallestStyles } from '../../../styles/margin/marginLeftSmallestStyles'

export const SelectInputField = ({
  label,
  error,
  className = '',
  ...props
}: SelectInputFieldProps): JSX.Element => {
  return (
    <div className={`SelectInputField ${SelectInputFieldStyles} ${className}`}>
      <label>
        {label && (
          <div className={`${labelStyles} ${marginLeftSmallestStyles}`}>
            {label}
          </div>
        )}

        <SelectInput {...props} />
      </label>

      <ErrorMessage style={{ height: !error ? '0px' : '20px' }}>
        {error}
      </ErrorMessage>
    </div>
  )
}
