import { StateReducers } from 'state/reducers/types/StateReducers'
import { NewLabelFormConnectedProps } from './types/NewLabelFormConnectedProps'
import { NewLabelFormMapStateReturnType } from './types/NewLabelFormMapStateReturnType'
import { getIsTouchScreen } from 'state/getters/getIsTouchScreen'

export const NewLabelFormMapState = (
  state: StateReducers,
  _props: NewLabelFormConnectedProps
): NewLabelFormMapStateReturnType => {
  const isTouch = getIsTouchScreen({ state })

  return {
    isTouch,
  }
}
