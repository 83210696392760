import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { InvitesReducer } from 'state/reducers/types/InvitesReducer'
import { createId } from 'firebaseFunctions/utilities/createId'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { SUBMIT_NEW_INVITE, SUBMIT_NEW_INVITE_DONE } from 'actions'

import { MEMBERS_ROUTE } from 'routes'
import { postInviteSaga } from 'flows/sagas/post/postInviteSaga'
import { parseEmailsString } from 'utilities/parseEmailsString'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitNewInviteF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_NEW_INVITE])
    const { emails, role } = action.values
    const emailParts = parseEmailsString(emails)
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const team = getCurrentTeam({ state })
    const displayName = team && team.displayName

    if (role && teamId && displayName && emailParts.length > 0) {
      const itemsMutable: InvitesReducer = {}

      emailParts.forEach((email) => {
        const cleanedEmail = email.trim().toLowerCase()

        itemsMutable[createId()] = {
          data: {
            email: cleanedEmail,
            role,
            teamId,
            teamDisplayName: displayName,
          },
          teamId,
        }
      })

      yield spawn(postInviteSaga, { items: itemsMutable })

      yield put(
        createHistoryAction({
          description: 'submitNewInviteF',
          pathname: MEMBERS_ROUTE,
          query: {
            teamId,
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewInviteF',
        })
      )
    }

    yield put({
      type: SUBMIT_NEW_INVITE_DONE,
    })
  }
}
