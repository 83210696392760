import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_CLONE_TASK } from 'actions'
import { newTaskSaga } from 'flows/sagas/newTaskSaga'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitCloneTaskF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_CLONE_TASK])
    const { taskId } = action.values
    const state: StateReducers = yield select(selectState)
    const taskReducer = taskId ? state.tasks?.[taskId] : undefined

    if (taskReducer) {
      const summary = taskReducer.data.summary

      yield newTaskSaga({
        task: {
          ...taskReducer.data,
          summary: summary + ' (clone)',
        },
        teamId: taskReducer.teamId,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitCloneTaskF.ts',
        })
      )
    }
  }
}
