import { Dispatch, Action } from 'redux'
// import { createToggleAction } from 'actionCreators/createToggleAction'

// import { SUBMIT_TASK_SIZE } from 'actions'

import { TaskSizeFieldMapDispatchReturnType } from './types/TaskSizeFieldMapDispatchReturnType'

export const TaskSizeFieldMapDispatch = (
  _dispatch: Dispatch<Action>
): TaskSizeFieldMapDispatchReturnType => {
  return {}
}
