import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { BaseEmoji } from 'emoji-mart'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import { EmojiDropdownConnectedProps } from './types/EmojiDropdownConnectedProps'
import { EmojiDropdownMapDispatchReturnType } from './types/EmojiDropdownMapDispatchReturnType'

export const EmojiDropdownMapDispatch = (
  dispatch: Dispatch<Action>,
  props: EmojiDropdownConnectedProps
): EmojiDropdownMapDispatchReturnType => {
  return {
    onSelectDispatch: (emoji: BaseEmoji) => {
      props.onEmojiSelect?.(emoji)

      if (props.closeOnSelect) {
        dispatch(
          createToggleAction({
            description: 'EmojiDropdownMapDispatch',
            toggles: {
              showDropdown: false,
            },
          })
        )
      }
    },
  }
}
