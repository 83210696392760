import { RemoveTagButtonStyles } from './RemoveTagButtonStyles'
import React, { useCallback } from 'react'
import { RemoveTagButtonProps } from './types/RemoveTagButtonProps'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { TimesSolidIcon } from '../../icon/TimesSolidIcon/TimesSolidIcon'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'

export const RemoveTagButton = ({
  removeTag,
  className = '',
  tagId,
  taskId,
}: RemoveTagButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    removeTag({ taskId, tagId })
  }, [removeTag, taskId, tagId])

  return (
    <button
      type="button"
      className={`RemoveTagButton ${RemoveTagButtonStyles} ${className} ${actionButtonStyles}`}
      onClick={onClickCallback}
    >
      <IconAndDiv icon={<TimesSolidIcon />}>Remove</IconAndDiv>
    </button>
  )
}
