import { Dispatch, Action } from 'redux'

import {
  // AvatarConnectedProps,
  AvatarMapDispatchReturnType,
} from './types'

export const AvatarMapDispatch = (
  _dispatch: Dispatch<Action>
  // props: AvatarConnectedProps,
): AvatarMapDispatchReturnType => {
  return {}
}
