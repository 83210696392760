import { TestingMainStyles } from './TestingMainStyles'
import React, { useState } from 'react'
import { TestingMainProps } from './types/TestingMainProps'
import { FileUploadField } from '../../field/FileUploadField'
import { TextInputField } from '../../field/TextInputField/TextInputField'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'
import { storageSdk } from '../../../sdks'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'

export const TestingMain = ({
  deleteCustomClaim,
  refreshToken,
  setSession,
  testing,
}: TestingMainProps): JSX.Element => {
  const [inputTextState, setInputTextState] = useState(testing)
  const [output, setOutput] = useState('')

  return (
    <div
      onDragEnter={() => {
        // TODO
      }}
      className={'TestingMain ' + TestingMainStyles}
    >
      <TextInputField
        type="textarea"
        value={inputTextState}
        onChange={(e) => {
          setInputTextState(e.target.value)
          setSession(e.target.value)
        }}
      />

      <h6 className={marginTopMediumStyles}>Output</h6>
      <p>{output}</p>

      <h6 className={marginTopMediumStyles}>Permission</h6>

      <button
        type="button"
        className={actionButtonStyles}
        onClick={deleteCustomClaim}
      >
        delete custom claim
      </button>

      <button
        type="button"
        className={actionButtonStyles}
        onClick={refreshToken}
      >
        refresh token
      </button>

      <h6 className={marginTopMediumStyles}>Storage</h6>

      <button
        type="button"
        className={actionButtonStyles}
        onClick={async () => {
          setOutput('')
          if (inputTextState) {
            const ref = storageSdk().ref()
            const uploadRef = ref.child(inputTextState)

            try {
              const downloadUrl = await uploadRef.getDownloadURL()
              setOutput(downloadUrl)
            } catch (error) {
              setOutput(JSON.stringify(error))
            }
          } else {
            setOutput('Please specify path.')
          }
        }}
      >
        get download url
      </button>

      <button
        type="button"
        className={actionButtonStyles}
        onClick={async () => {
          setOutput('')
          if (inputTextState) {
            const ref = storageSdk().ref()
            const uploadRef = ref.child(inputTextState)

            try {
              await uploadRef.delete()
              setOutput('Delete successful')
            } catch (error) {
              setOutput(JSON.stringify(error))
            }
          } else {
            setOutput('Please specify path.')
          }
        }}
      >
        delete file
      </button>

      <FileUploadField
        onValid={async (files) => {
          setOutput('')
          if (inputTextState) {
            const ref = storageSdk().ref()
            try {
              await ref.child(inputTextState).put(files[0])
              const downloadUrl = await ref
                .child(inputTextState)
                .getDownloadURL()
              setOutput(downloadUrl)
            } catch (error) {
              setOutput(JSON.stringify(error))
            }
          } else {
            setOutput('Please specify path.')
          }
        }}
      >
        <button type="button" className={actionButtonStyles}>
          Upload file
        </button>
      </FileUploadField>
    </div>
  )
}
