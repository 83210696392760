import { getCurrentTeam } from './getCurrentTeam'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
  status: string
  teamId?: string
}

export const getStatusColor = ({ state, teamId, status }: Props): string => {
  const team = getCurrentTeam({ state, teamId })

  const taskStatusColors = team && team.taskStatusColors

  return taskStatusColors ? taskStatusColors[status] : '#000'
}
