import { PATCH_TEAMS_FF_SUCCESS } from 'actions'

import { PatchTeamsFfSuccessAction } from './types/PatchTeamsFfSuccessAction'
import { PatchTeamsFfSuccessActionValues } from './types/PatchTeamsFfSuccessActionValues'
import { PatchTeamsFfRequestActionValues } from './types/PatchTeamsFfRequestActionValues'


interface Props {
  requestValues: PatchTeamsFfRequestActionValues
  successValues: PatchTeamsFfSuccessActionValues
}

export const createPatchTeamsFfSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchTeamsFfSuccessAction => {
  return {
    type: PATCH_TEAMS_FF_SUCCESS,
    requestValues,
    successValues,
  }
}
