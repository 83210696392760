import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsBigScreen } from 'state/getters/getIsBigScreen'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // BigHeaderConnectedProps,
  BigHeaderMapStateReturnType,
} from './types'

export const BigHeaderMapState = (
  state: StateReducers
  // props: BigHeaderConnectedProps
): BigHeaderMapStateReturnType => {
  const showTaskSummary = state.currentLocation?.isRoutes?.isTaskMessagesRoute
  const isBigScreen = getIsBigScreen({ state })
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    isBigScreen,
    isMediumScreen,
    showTaskSummary,
  }
}
