import { googleSignInF } from './googleSignInF'
import { firebaseAuthStateChangedF } from './firebaseAuthStateChangedF'
import { signOutSuccessF } from './signOutSuccessF'
import { googleSignInSuccessF } from './googleSignInSuccessF'
import { googleSignInProxySuccessF } from './googleSignInProxySuccessF'

export const authFlows = [
  googleSignInF,
  firebaseAuthStateChangedF,
  signOutSuccessF,
  googleSignInSuccessF,
  googleSignInProxySuccessF,
]
