import { Dispatch, Action } from 'redux'
import { SUBMIT_ACCEPT_INVITE, SUBMIT_DELETE_USER_INVITE } from 'actions'

import { UserInviteCardConnectedProps } from './types/UserInviteCardConnectedProps'
import { UserInviteCardMapDispatchReturnType } from './types/UserInviteCardMapDispatchReturnType'

import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const UserInviteCardMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UserInviteCardConnectedProps
): UserInviteCardMapDispatchReturnType => {
  return {
    deleteInvite: () => {
      dispatch(
        createSubmitAction({
          values: {
            inviteId: props.inviteId,
          },
          type: SUBMIT_DELETE_USER_INVITE,
        })
      )
    },

    acceptInvite: () => {
      dispatch(
        createSubmitAction({
          values: {
            inviteId: props.inviteId,
          },
          type: SUBMIT_ACCEPT_INVITE,
        })
      )
    },
  }
}
