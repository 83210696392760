import { Action } from 'redux'
import {
  SIGN_OUT_SUCCESS,
  GET_CUSTOM_CLAIM_SUCCESS,
  PATCH_CUSTOM_CLAIM_SUCCESS,
} from 'actions'
import { CurrentCustomClaimReducer } from './types/CurrentCustomClaimReducer'
import { GetCustomClaimSuccessAction } from 'actionCreators/get/GetCustomClaim/types/GetCustomClaimSuccessAction'
import { PatchCustomClaimSuccessAction } from 'actionCreators/patch/PatchCustomClaim/types/PatchCustomClaimSuccessAction'

export const currentCustomClaimReducer = (
  state?: CurrentCustomClaimReducer,
  action?: Action
): CurrentCustomClaimReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case GET_CUSTOM_CLAIM_SUCCESS: {
      const getCustomClaimSuccessAction = action as GetCustomClaimSuccessAction
      const { item } = getCustomClaimSuccessAction.successValues

      return {
        ...state,
        ...item,
      }
    }

    case PATCH_CUSTOM_CLAIM_SUCCESS: {
      const patchCustomClaimSuccessAction = action as PatchCustomClaimSuccessAction
      const { item } = patchCustomClaimSuccessAction.successValues

      return {
        ...state,
        ...item,
      }
    }

    case SIGN_OUT_SUCCESS: {
      return null
    }
  }

  return state || null
}
