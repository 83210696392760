import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUnreadCount } from 'state/getters/getUnreadCount'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // MessageNotificationDropdownConnectedProps,
  MessageNotificationDropdownMapStateReturnType,
} from './types'

export const MessageNotificationDropdownMapState = (
  state: StateReducers,
  // props: MessageNotificationDropdownConnectedProps,
): MessageNotificationDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const showMessageNotificationsDropdown = showDropdown && showDropdown.type === 'messageNotifications'
  const unreadCount = getUnreadCount({ state, notificationGroup: 'messages' })
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    unreadCount,
    showMessageNotificationsDropdown,
    isMediumScreen,
  }
}
