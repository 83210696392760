import { Dispatch, Action } from 'redux'
import { UpdateAvatarFormMapDispatchReturnType } from './types/UpdateAvatarFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_AVATAR_UPLOAD } from 'actions'

export const UpdateAvatarFormMapDispatch = (
  dispatch: Dispatch<Action>
): UpdateAvatarFormMapDispatchReturnType => {
  return {
    submit: ({ fileBlob }) => {
      dispatch(
        createSubmitAction({
          values: { fileBlob },
          description: 'UpdateAvatarFormMapDispatch',
          type: SUBMIT_AVATAR_UPLOAD,
        })
      )
    },
  }
}
