import { take, put, select } from 'redux-saga/effects'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { firebaseMessaging } from 'sdks'
import { LOGIN_ROUTE } from 'routes'
import { SIGN_OUT_SUCCESS } from 'actions'
import { createSetCookieStorageAction } from 'actionCreators/cookieStorage'
import { createSetSessionStorageAction } from 'actionCreators/sessionStorage'

export const signOutSuccessF = function*() {
  while (1) {
    yield take([SIGN_OUT_SUCCESS])

    yield put(
      createSetCookieStorageAction({
        description: 'signOutSuccessF',
        values: {
          signedIn: false,
          dontAskToEnableNotifications: false,
        },
      })
    )

    const state: StateReducers = yield select(selectState)
    const { sessionStorage, cookieStorage } = state
    const isEndToEndTesting = !!sessionStorage?.isEndToEndTesting
    const platform = cookieStorage?.platform
    const notificationPermission = state.windowMeta?.notificationPermission

    if (
      firebaseMessaging &&
      platform !== 'MacOs' &&
      platform !== 'Window' &&
      notificationPermission === 'granted'
    ) {
      try {
        const token = yield firebaseMessaging.getToken()

        if (token) {
          firebaseMessaging.deleteToken(token)
        }
      } catch (error) {
        // silence error
      }
    }

    const setSessionStorageAction = createSetSessionStorageAction({
      description: 'signOutSuccessF',
      sessionStorage: {
        isEndToEndTesting,
      },
      replace: true,
    })

    yield put(setSessionStorageAction)

    const historyAction = createHistoryAction({
      description: 'signOutSuccessF',
      pathname: LOGIN_ROUTE,
      method: 'push',
    })

    yield put(historyAction)
  }
}
