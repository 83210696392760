import { markdownToDraft } from './markdownToDraft'
import { convertFromRaw, ContentState } from 'draft-js'
import { MENTION_REGEX, MENTION_MARKER } from 'constants/regexConstants'

const MENTION_OPEN = 'mention_open'

export interface GetContentFromMarkdownProps {
  markdown: string
}

const remarkableMentionPlugin = (md: any, _options: any) => {
  md.inline.ruler.push('mention', (state: any, _silent: any) => {
    const matches = state.src.match(MENTION_REGEX)

    if (matches && matches.length === 3) {
      const isMarked = matches[2]?.indexOf(MENTION_MARKER) > -1

      state.push({
        type: MENTION_OPEN,
        id: matches[2] + (isMarked ? '' : MENTION_MARKER),
        name: matches[1], // TODO: use current name if possible
      })
    }
  })
}

export const getContentFromMarkdown = ({
  markdown,
}: GetContentFromMarkdownProps): ContentState => {
  const draftValue = markdownToDraft(markdown, {
    preserveNewlines: true,
    remarkablePlugins: [remarkableMentionPlugin],
    blockEntities: {
      [MENTION_OPEN]: function(item: any) {
        return {
          type: 'mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              id: item.id,
              name: item.name,
            },
          },
        }
      },
    },
  })

  return convertFromRaw(draftValue)
}
