import { Dispatch, Action } from 'redux'
import {
  UserInviteData,
  UserInvites,
  UserInvite,
} from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { UserInvitesWatcherConnectedProps } from './types/UserInvitesWatcherConnectedProps'
import { UserInvitesWatcherMapDispatchReturnType } from './types/UserInvitesWatcherMapDispatchReturnType'
import { createDeleteUserInvitesSuccessAction } from 'actionCreators/delete/DeleteUserInvites/createDeleteUserInvitesSuccessAction'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetUserInvitesSuccessAction } from 'actionCreators/get/GetUserInvites/createGetUserInvitesSuccessAction'
import { createGetUserInvitesFailureAction } from 'actionCreators/get/GetUserInvites/createGetUserInvitesFailureAction'

export const UserInvitesWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UserInvitesWatcherConnectedProps
): UserInvitesWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'UserInvitesWatcherMapDispatch',
          increment,
          watcherType: 'userInvites',
        })
      )
    },

    onNext: (querySnapshot: firebase.firestore.QuerySnapshot) => {
      const newUserInviteUsersMutable: UserInvites = {}
      const deletedUserInviteUsersMutable: UserInvites = {}

      querySnapshot.docChanges().forEach((change) => {
        const userInvite = change.doc.data() as UserInviteData | undefined
        const userInviteId = change.doc.id

        if (!userInvite) {
          return
        }

        const userInviteReducer: UserInvite = {
          data: userInvite,
        }

        if (change.type === 'added' || change.type === 'modified') {
          newUserInviteUsersMutable[userInviteId] = userInviteReducer
        }

        if (change.type === 'removed') {
          deletedUserInviteUsersMutable[userInviteId] = userInviteReducer
        }
      })

      if (Object.keys(newUserInviteUsersMutable).length > 0) {
        dispatch(
          createGetUserInvitesSuccessAction({
            requestValues: {
              email: props.email,
            },
            successValues: {
              items: newUserInviteUsersMutable,
            },
            description: 'UserInvitesWatcherMapDispatch',
          })
        )
      }

      if (Object.keys(deletedUserInviteUsersMutable).length > 0) {
        dispatch(
          createDeleteUserInvitesSuccessAction({
            requestValues: {
              items: deletedUserInviteUsersMutable,
            },
            successValues: {
              items: deletedUserInviteUsersMutable,
            },
            description: 'UserInvitesWatcherMapDispatch',
          })
        )
      }
    },

    onFirebaseError: (error: Error) => {
      dispatch(
        createFirebaseErrorAction({
          error: error as FirebaseError,
          description: 'UserInvitesWatcherMapDispatch',
        })
      )

      dispatch(
        createGetUserInvitesFailureAction({
          requestValues: {
            email: props.email,
          },
        })
      )
    },
  }
}
