import { css } from 'emotion'

export const HeaderTwoTextStyles = css`
  font-size: 1em;
  padding-bottom: 16px;
  font-weight: bold;
  text-transform: capitalize;

  &.isMediumScreen {
    font-size: 1.25em;
    padding-bottom: 16px;
  }
`
