import { TaskStatusButtonListStyles } from './TaskStatusButtonListStyles'
import React, { useCallback } from 'react'
import { TaskStatusButtonListProps } from './types/TaskStatusButtonListProps'
import { ActionButton } from 'components/button/ActionButton'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'
import { listHorizontalScrollStyles } from '../../../styles/list/listHorizontalScrollStyles'
import { marginSmallStyles } from '../../../styles/margin/marginSmallStyles'

export const TaskStatusButtonList = ({
  activeStatuses = [],
  taskStatuses,
  onStatusClick,
  onClick,
  className = '',
  counts,
  showCounts = false,
  horizontalScroll,
}: TaskStatusButtonListProps): JSX.Element => {
  const listStyles =
    taskStatuses.length > 10 || horizontalScroll
      ? `${listHorizontalScrollStyles} ${marginSmallStyles}`
      : listActionButtonsStyles

  const onClickCallback = useCallback(
    (e: React.MouseEvent<HTMLUListElement>) => {
      const target = e.target as HTMLButtonElement

      if (onStatusClick && target.value) {
        onStatusClick(target.value, taskStatuses)
      }

      if (onClick) {
        onClick(e)
      }
    },
    [onClick, onStatusClick, taskStatuses]
  )

  return (
    <ul
      className={`TaskStatusButtonList ${TaskStatusButtonListStyles} ${listStyles} ${className}`}
      onClick={onClickCallback}
    >
      {taskStatuses.map((taskStatus, i) => {
        const isActive = activeStatuses.indexOf(taskStatus) > -1

        return (
          <li key={i} className={isActive ? 'isActive' : ''}>
            <ActionButton title={taskStatus} value={taskStatus}>
              {taskStatus}
              {showCounts ? ` (${counts[i]})` : ''}
            </ActionButton>
          </li>
        )
      })}
    </ul>
  )
}
