import { connect } from 'react-redux'
import { App } from './App'
import { AppMapState } from './AppMapState'
import { AppMapDispatch } from './AppMapDispatch'

import { AppMapStateReturnType } from './types/AppMapStateReturnType'
import { AppMapDispatchReturnType } from './types/AppMapDispatchReturnType'
import { AppConnectedProps } from './types/AppConnectedProps'


export const AppConnected = connect<
  AppMapStateReturnType,
  AppMapDispatchReturnType,
  AppConnectedProps>(AppMapState,
    AppMapDispatch)(App)
