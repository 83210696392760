import { connect } from 'react-redux'
import { UserExtensionWatcher } from './UserExtensionWatcher'
import { UserExtensionWatcherMapState } from './UserExtensionWatcherMapState'
import { UserExtensionWatcherMapDispatch } from './UserExtensionWatcherMapDispatch'

import { UserExtensionWatcherMapStateReturnType } from './types/UserExtensionWatcherMapStateReturnType'
import { UserExtensionWatcherMapDispatchReturnType } from './types/UserExtensionWatcherMapDispatchReturnType'
import { UserExtensionWatcherConnectedProps } from './types/UserExtensionWatcherConnectedProps'


export const UserExtensionWatcherConnected = connect<
  UserExtensionWatcherMapStateReturnType,
  UserExtensionWatcherMapDispatchReturnType,
  UserExtensionWatcherConnectedProps>(UserExtensionWatcherMapState,
    UserExtensionWatcherMapDispatch)(UserExtensionWatcher)
