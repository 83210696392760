import * as React from 'react'
import { Link } from 'components/link/Link'
import { CustomLinkProps } from './types/CustomLinkProps'

export const CustomLink = ({
  to,
  className = '',
  title,
  children,
  onClick,
}: CustomLinkProps) => {
  return (
    <Link
      title={title}
      className={`CustomLink ${className}`}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
