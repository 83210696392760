import { DateSeparatorSpanStyles } from './DateSeparatorSpanStyles'
import React from 'react'
import { DateSeparatorSpanProps } from './types/DateSeparatorSpanProps'
import moment from 'moment'
import { getIsToday } from 'utilities/get/getIsToday'
import { getIsYesterday } from 'utilities/get/getIsYesterday'

export const DateSeparatorSpan = ({
  createdSeconds,
}: DateSeparatorSpanProps): JSX.Element | null => {

  let dayString

  if (createdSeconds) {
    if (getIsToday(createdSeconds)) {
      dayString = 'Today'
    } else if (getIsYesterday(createdSeconds)) {
      dayString = 'Yesterday'
    } else {
      dayString = moment.unix(createdSeconds).format('dddd, MMMM Do')
    }
  } else {
    dayString = 'No date available'
  }


  return (
    <span className={'DateSeparatorSpan ' + DateSeparatorSpanStyles}>
      {dayString}
    </span>
  )
}
