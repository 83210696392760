// import { Dispatch, Action } from 'redux'

import {
  // LabelListConnectedProps,
  LabelListMapDispatchReturnType,
} from './types'

export const LabelListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: LabelListConnectedProps,
): LabelListMapDispatchReturnType => {
  return {
  }
}
