import { SignOutButtonStyles } from './SignOutButtonStyles'
import * as React from 'react'
import { SignOutButtonProps } from './types/SignOutButtonProps'

export const SignOutButton = ({
  signOut,
}: SignOutButtonProps) => {
  return (
    <button className={'SignOutButton ' + SignOutButtonStyles} onClick={signOut}>Sign out</button>
  )
}
