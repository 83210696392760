import { InviteMainStyles } from './InviteMainStyles'
import React from 'react'
import { InviteMainProps } from './types/InviteMainProps'
import { InviteCardConnected } from 'components/card/InviteCard'
import Select from 'react-select'
import { roleOptions } from 'constants/roleOptions'
import {
  PRIMARY_COLOR,
  WARNING_HIGHLIGHT_COLOR,
} from 'constants/styleConstants'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { HeaderTwoTextConnected } from '../../text/HeaderTwoText/HeaderTwoTextConnected'
import { DeleteAndConfirmWidget } from '../../widget/DeleteAndConfirmWidget'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const InviteMain = ({
  inviteId,
  updateRole,
  role,
  deleteInvite,
}: InviteMainProps): JSX.Element => {
  const value = roleOptions.find((roleOption) => {
    return roleOption.value === role
  })

  return (
    <main className={'InviteMain ' + InviteMainStyles}>
      <HeaderOneTextConnected>Invitation</HeaderOneTextConnected>

      <InviteCardConnected inviteId={inviteId} className="noBorder" />

      <HeaderTwoTextConnected className={marginTopLargeStyles}>
        Update permission
      </HeaderTwoTextConnected>

      <Select
        className="Select"
        options={roleOptions}
        value={value}
        onChange={updateRole}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: WARNING_HIGHLIGHT_COLOR,
            primary: PRIMARY_COLOR,
          },
        })}
      />

      <HeaderTwoTextConnected className={marginTopLargeStyles}>
        Cancel invitation
      </HeaderTwoTextConnected>

      <div>
        <DeleteAndConfirmWidget onDeleteClick={deleteInvite} />
      </div>
    </main>
  )
}
