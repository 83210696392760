import { StateReducers } from 'state/reducers/types/StateReducers'
import { TagDropdownMainConnectedProps } from './types/TagDropdownMainConnectedProps'
import { TagDropdownMainMapStateReturnType } from './types/TagDropdownMainMapStateReturnType'
import { getCanOwn } from '../../../state/getters/getCanOwn'

export const TagDropdownMainMapState = (
  state: StateReducers,
  _props: TagDropdownMainConnectedProps
): TagDropdownMainMapStateReturnType => {
  const canOwn = getCanOwn({ state })

  return {
    canOwn,
  }
}
