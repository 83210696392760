import { IsPreAndPostLoginRoutes } from './types/IsPreAndPostLoginRoutes'

import {
  LOGIN_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  preAndPostLoginRoutes,
  CONFIRM_EMAIL_ROUTE,
  ROOT_ROUTE,
  SERVER_ERROR_TEST_ROUTE,
  JS_ERROR_TEST_ROUTE,
  NETWORK_ERROR_TEST_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_PASSWORD_SENT_ROUTE,
} from './preAndPostLoginRoutes'

interface Props {
  pathname: string
}

export const getIsPreAndPostLoginRoutes = ({
  pathname = '',
}: Props): IsPreAndPostLoginRoutes => {
  return {
    isRootRoute: pathname === ROOT_ROUTE,
    isLoginRoute: pathname === LOGIN_ROUTE,
    isCreateUserRoute: pathname === CREATE_ACCOUNT_ROUTE,
    isConfirmEmailRoute: pathname === CONFIRM_EMAIL_ROUTE,
    isServerErrorTestRoute: pathname === SERVER_ERROR_TEST_ROUTE,
    isJsErrorTestRoute: pathname === JS_ERROR_TEST_ROUTE,
    isNetworkErrorTestRoute: pathname === NETWORK_ERROR_TEST_ROUTE,
    isResetPasswordRoute: pathname === RESET_PASSWORD_ROUTE,
    isResetPasswordSentRoute: pathname === RESET_PASSWORD_SENT_ROUTE,
    isPreAndPostLoginRoute: preAndPostLoginRoutes.indexOf(pathname) > -1,
  }
}
