import { StateReducers } from 'state/reducers/types/StateReducers'

import { ReplyMessageCardConnectedProps } from './types/ReplyMessageCardConnectedProps'
import { ReplyMessageCardMapStateReturnType } from './types/ReplyMessageCardMapStateReturnType'


export const ReplyMessageCardMapState = (
  state: StateReducers,
  props: ReplyMessageCardConnectedProps
): ReplyMessageCardMapStateReturnType => {
  const message = state.messages?.[props.messageId].data
  const userDisplayName = message?.userDisplayName
  const description = message?.description

  return {
    userDisplayName,
    description,
  }
}
