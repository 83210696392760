import { css } from 'emotion'

export const SelectTagFieldStyles = css`
  .option {
    display: flex;
    align-items: center;

    .DisplayNameSpan {
      margin-left: 8px;
    }
  }
`
