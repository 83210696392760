import { Action } from 'redux'
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS, PATCH_USERS_SUCCESS } from 'actions'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { CurrentUserReducer } from './types/CurrentUserReducer'
import { SignInSuccessAction } from 'actionCreators/auth'
import { PatchUsersSuccessAction } from 'actionCreators/patch/PatchUsers/types/PatchUsersSuccessAction'

export const currentUserReducer = (
  state?: CurrentUserReducer,
  action?: Action
): CurrentUserReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SIGN_IN_SUCCESS: {
      const signInSuccessAction = action as SignInSuccessAction
      const { user } = signInSuccessAction.values
      const { email } = user
      const cleanedEmail = email?.trim().toLowerCase()

      return {
        ...user,
        email: cleanedEmail,
      }
    }

    case PATCH_USERS_SUCCESS: {
      const patchUsersSucessAction = action as PatchUsersSuccessAction
      const { item } = getObjectFirstItem(
        patchUsersSucessAction.successValues.items
      )

      return {
        ...state,
        ...item,
      }
    }

    case SIGN_OUT_SUCCESS: {
      return null
    }
  }

  return state || null
}
