import { GET_USER_INVITES_FAILURE } from 'actions'

import { GetUserInvitesFailureAction } from './types/GetUserInvitesFailureAction'
import { GetUserInvitesRequestActionValues } from './types/GetUserInvitesRequestActionValues'


interface Props {
  requestValues: GetUserInvitesRequestActionValues
}

export const createGetUserInvitesFailureAction = ({
  requestValues,
}: Props): GetUserInvitesFailureAction => {
  return {
    type: GET_USER_INVITES_FAILURE,
    requestValues,
  }
}
