import { DELETE_TEAMS_SUCCESS } from 'actions'
import { DeleteTeamsRequestActionValues } from './types/DeleteTeamsRequestActionValues'
import { DeleteTeamsSuccessActionValues } from './types/DeleteTeamsSuccessActionValues'
import { DeleteTeamsSuccessAction } from './types/DeleteTeamsSuccessAction'

interface Props {
  requestValues: DeleteTeamsRequestActionValues
  successValues: DeleteTeamsSuccessActionValues
}

export const createDeleteTeamsSuccessAction = ({
  successValues,
  requestValues,
}: Props): DeleteTeamsSuccessAction => {
  return {
    type: DELETE_TEAMS_SUCCESS,
    requestValues,
    successValues,
  }
}
