import { css } from 'emotion'

const width = 25

export const ColorPickerWidgetStyles = css`
  display: flex;
  flex-wrap: wrap;
  width: ${width * 8}px;

  li {
    width: ${width}px;
    height: ${width}px;
    cursor: pointer;
  }
`
