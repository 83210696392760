import { connect } from 'react-redux'
import { NotificationCard } from './NotificationCard'
import { NotificationCardMapState } from './NotificationCardMapState'
import { NotificationCardMapDispatch } from './NotificationCardMapDispatch'

import { NotificationCardMapStateReturnType } from './types/NotificationCardMapStateReturnType'
import { NotificationCardMapDispatchReturnType } from './types/NotificationCardMapDispatchReturnType'
import { NotificationCardConnectedProps } from './types/NotificationCardConnectedProps'


export const NotificationCardConnected = connect<
  NotificationCardMapStateReturnType,
  NotificationCardMapDispatchReturnType,
  NotificationCardConnectedProps>(NotificationCardMapState,
    NotificationCardMapDispatch)(NotificationCard)
