import { connect } from 'react-redux'
import { InviteCard } from './InviteCard'
import { InviteCardMapState } from './InviteCardMapState'
import { InviteCardMapDispatch } from './InviteCardMapDispatch'

import { InviteCardMapStateReturnType } from './types/InviteCardMapStateReturnType'
import { InviteCardMapDispatchReturnType } from './types/InviteCardMapDispatchReturnType'
import { InviteCardConnectedProps } from './types/InviteCardConnectedProps'


export const InviteCardConnected = connect<
  InviteCardMapStateReturnType,
  InviteCardMapDispatchReturnType,
  InviteCardConnectedProps>(InviteCardMapState,
    InviteCardMapDispatch)(InviteCard)
