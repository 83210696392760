import { uniq } from 'lodash'
const STUFF_TO_REMOVE = /\r?\n|\r|,|;/g

export const parseCsvString = (emails?: string): string[] => {
  if (!emails) {
    return []
  }

  const cleanCsv: string[] = []
  const lines = emails.split(/\r?\n/)

  lines.forEach((line) => {
    line.split(',').forEach((email) => {
      const cleaned = email.trim().replace(STUFF_TO_REMOVE, '')

      if (cleaned) {
        cleanCsv.push(cleaned)
      }
    })
  })

  return uniq(cleanCsv)
}
