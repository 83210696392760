import { connect } from 'react-redux'
import { UploadWatcher } from './UploadWatcher'
import { UploadWatcherMapState } from './UploadWatcherMapState'
import { UploadWatcherMapDispatch } from './UploadWatcherMapDispatch'

import { UploadWatcherMapStateReturnType } from './types/UploadWatcherMapStateReturnType'
import { UploadWatcherMapDispatchReturnType } from './types/UploadWatcherMapDispatchReturnType'
import { UploadWatcherConnectedProps } from './types/UploadWatcherConnectedProps'


export const UploadWatcherConnected = connect<
  UploadWatcherMapStateReturnType,
  UploadWatcherMapDispatchReturnType,
  UploadWatcherConnectedProps>(UploadWatcherMapState,
    UploadWatcherMapDispatch)(UploadWatcher)
