// import { Dispatch, Action } from 'redux'

import {
  // TaskTagListConnectedProps,
  TaskTagListMapDispatchReturnType,
} from './types'

export const TaskTagListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskTagListConnectedProps,
): TaskTagListMapDispatchReturnType => {
  return {
  }
}
