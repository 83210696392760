import { NotFoundMainStyles } from './NotFoundMainStyles'
import React from 'react'
// import { NotFoundMainProps } from './types/NotFoundMainProps'
import { Link } from 'components/link/Link'
import { LOGIN_ROUTE } from 'routes'
import { textLinkStyles } from 'styles/text/textLinkStyles'

export const NotFoundMain = (): JSX.Element => {
  return (
    <div className={'NotFoundMain ' + NotFoundMainStyles}>
      Woops, page not found.{' '}
      <Link
        to={LOGIN_ROUTE}
        title="click to login"
        className={`${textLinkStyles}`}
      >
        Click here
      </Link>{' '}
      to login
    </div>
  )
}
