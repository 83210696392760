import { take, put } from 'redux-saga/effects'
import { statusCodes } from 'firebaseFunctions/statusCodes'

import { LOCATION_CHANGE } from 'actions'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'
import { createApiFlowErrorAction } from 'actionCreators/createApiFlowErrorAction'

interface StatusMessage {
  message: string
  status: number
}

const getStatusMessage = (
  locationChangeAction: LocationChangeAction
): StatusMessage | undefined => {
  const isRoutes = locationChangeAction.values.isRoutes
  const isServerErrorTestRoute = isRoutes && isRoutes.isServerErrorTestRoute
  const isJsErrorTestRoute = isRoutes && isRoutes.isJsErrorTestRoute
  const isNetworkErrorTestRoute = isRoutes && isRoutes.isNetworkErrorTestRoute

  if (isServerErrorTestRoute) {
    return {
      message: 'Some server error',
      status: statusCodes.serverError,
    }
  }

  if (isJsErrorTestRoute) {
    return {
      message: 'Some js error',
      status: statusCodes.javascriptError,
    }
  }

  if (isNetworkErrorTestRoute) {
    return {
      message: 'Network Error',
      status: statusCodes.networkError,
    }
  }

  return undefined
}

export const testErrorsF = function*() {
  while (1) {
    const locationChangeAction: LocationChangeAction = yield take([
      LOCATION_CHANGE,
    ])

    const statusMessage = getStatusMessage(locationChangeAction)

    if (statusMessage) {
      const apiFlowErrorAction = createApiFlowErrorAction({
        type: 'testServerError',
        ...statusMessage,
      })

      yield put(apiFlowErrorAction)
    }
  }
}
