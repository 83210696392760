import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const MessageFileListStyles = css`
  > li {
    margin-top: 8px;
  }

  margin-bottom: 8px;
`
