import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentParams } from 'state/getters/getCurrentParams'
import { storageSdk } from 'sdks'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import {
  SUBMIT_DELETE_UPLOADED_FILE,
  SUBMIT_DELETE_UPLOADED_FILE_DONE,
} from 'actions'
import { deleteUploadedFilesSaga } from 'flows/sagas/delete/deleteUploadedFilesSaga'
import { THUMB_FILE_NAME_PREFIX } from 'firebaseFunctions/constants'
import { UploadedFilesReducer } from 'state/reducers/types/UploadedFilesReducer'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteUploadedFileF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_UPLOADED_FILE])
    const { uploadedFileId } = action.values
    const state: StateReducers = yield select(selectState)
    const params = getCurrentParams({ state })
    const { teamId } = params
    const uploadedFile = uploadedFileId
      ? state?.uploadedFiles?.[uploadedFileId].data
      : null

    const path = uploadedFile?.path

    if (uploadedFile && uploadedFileId && teamId) {
      const items: UploadedFilesReducer = {
        [uploadedFileId]: {
          data: uploadedFile,
          teamId,
        },
      }

      yield spawn(deleteUploadedFilesSaga, { items })

      if (path) {
        storageSdk()
          .ref()
          .child(path)
          .delete()

        const pathPartsMutable = path.split('/')
        const fileName = pathPartsMutable[pathPartsMutable.length - 1]
        pathPartsMutable[pathPartsMutable.length - 1] =
          THUMB_FILE_NAME_PREFIX + fileName
        const thumbPath = pathPartsMutable.join('/')

        storageSdk()
          .ref()
          .child(thumbPath)
          .delete()
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteUploadedFileF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_UPLOADED_FILE_DONE,
    })
  }
}
