import { StatusColorWidgetStyles } from './StatusColorWidgetStyles'
import React from 'react'
import { StatusColorWidgetProps } from './types/StatusColorWidgetProps'

export const StatusColorWidget = ({
  statusColor,
}: StatusColorWidgetProps): JSX.Element => {
  return (
    <div className={'StatusColorWidget ' + StatusColorWidgetStyles} style={{
      backgroundColor: statusColor,
    }} />
  )
}
