import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { SUBMIT_DELETE_TASK, SUBMIT_DELETE_TASK_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createDeleteTasksRequestAction } from 'actionCreators/delete/DeleteTasks/createDeleteTasksRequestAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteTaskF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_TASK])
    const state: StateReducers = yield select(selectState)
    const { taskId } = action.values
    const teamId = getCurrentTeamId({ state })

    if (taskId && teamId) {
      yield put(
        createDeleteTasksRequestAction({
          requestValues: {
            items: {
              [taskId]: {
                data: {},
                teamId,
              },
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteTaskF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_TASK_DONE,
    })
  }
}
