import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTeamTaskStatuses } from 'state/getters/getCurrentTeamTaskStatuses'
import { StatusDropdownConnectedProps } from './types/StatusDropdownConnectedProps'
import { StatusDropdownMapStateReturnType } from './types/StatusDropdownMapStateReturnType'
import { getCanOwn } from '../../../state/getters/getCanOwn'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const StatusDropdownMapState = (
  state: StateReducers,
  props: StatusDropdownConnectedProps
): StatusDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const task = getCurrentTask({ state, taskId: props.taskId })
  const status = task && task.status
  const showDropdown = toggles && toggles.showDropdown
  const statuses = getCurrentTeamTaskStatuses({ state })
  const statusesLength = statuses?.length || 0
  const showSelectField = statusesLength > 10
  const canOwn = getCanOwn({ state })
  const canWrite = getCanWrite({ state })
  const isDashboardLayoutRoute =
    state.currentLocation?.isRoutes?.isDashboardLayoutRoute

  const showStatusDropdown =
    showDropdown &&
    showDropdown.type === 'status' &&
    showDropdown.id === props.taskId

  return {
    showStatusDropdown,
    status,
    showSelectField,
    canOwn,
    canWrite,
    isDashboardLayoutRoute,
  }
}
