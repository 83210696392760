import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdateWorkspaceLogoMainConnectedProps } from './types/UpdateWorkspaceLogoMainConnectedProps'
import { UpdateWorkspaceLogoMainMapStateReturnType } from './types/UpdateWorkspaceLogoMainMapStateReturnType'
import { getCurrentTeamReducer } from '../../../state/getters/getCurrentTeamReducer'

export const UpdateWorkspaceLogoMainMapState = (
  state: StateReducers,
  _props: UpdateWorkspaceLogoMainConnectedProps
): UpdateWorkspaceLogoMainMapStateReturnType => {
  const team = getCurrentTeamReducer({ state })
  const showExistingLogo = !!team?.logoUrl

  return {
    showExistingLogo,
  }
}
