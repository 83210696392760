import { Dispatch, Action } from 'redux'
import { ServiceWorkerMessageWatcherMapDispatchReturnType } from './types/ServiceWorkerMessageWatcherMapDispatchReturnType'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createLocationChangeAction } from 'actionCreators/createLocationChangeAction'

export const ServiceWorkerMessageWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): ServiceWorkerMessageWatcherMapDispatchReturnType => {
  return {
    redirect: (url: string) => {
      const { pathname, search, hash } = new URL(url)

      const locationChangeAction = createLocationChangeAction({
        pathname,
        search,
        hash,
      })

      dispatch(
        createHistoryAction({
          pathname,
          query: locationChangeAction.values.query,
          description: 'ServiceWorkerMessageWatcherMapDispatch',
        })
      )
    },
  }
}
