import { MemberCardConnected } from 'components/card/MemberCard'
import { SelectInput } from 'components/input/SelectInput'
import { roleOptions } from 'constants/roleOptions'
import React from 'react'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { HeaderTwoTextConnected } from '../../text/HeaderTwoText/HeaderTwoTextConnected'
import { DeleteAndConfirmWidget } from '../../widget/DeleteAndConfirmWidget'
import { MemberMainStyles } from './MemberMainStyles'
import { MemberMainProps } from './types/MemberMainProps'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'

export const MemberMain = ({
  userId,
  updateRole,
  showCantUpdateRole,
  role,
  deleteUserRole,
}: MemberMainProps): JSX.Element => {
  const value = roleOptions.find((roleOption) => {
    return roleOption.value === role
  })

  return (
    <main className={'MemberMain ' + MemberMainStyles}>
      <HeaderOneTextConnected>Member</HeaderOneTextConnected>

      <MemberCardConnected userId={userId} />

      <HeaderTwoTextConnected className={marginTopLargeStyles}>
        Update permission
      </HeaderTwoTextConnected>

      {showCantUpdateRole && <p>You can't update your own role.</p>}

      {!showCantUpdateRole && (
        <div>
          <SelectInput
            className="Select"
            options={roleOptions}
            value={value}
            onChange={updateRole}
          />

          <HeaderTwoTextConnected className={marginTopLargeStyles}>
            Danger zone
          </HeaderTwoTextConnected>

          <p>Permantently remove this member</p>

          <div className={marginTopMediumStyles}>
            <DeleteAndConfirmWidget
              label="Remove"
              onDeleteClick={deleteUserRole}
            />
          </div>
        </div>
      )}
    </main>
  )
}
