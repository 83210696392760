import { connect } from 'react-redux'
import { PlanLink } from './PlanLink'
import { PlanLinkMapState } from './PlanLinkMapState'
import { PlanLinkMapDispatch } from './PlanLinkMapDispatch'

import { PlanLinkMapStateReturnType } from './types/PlanLinkMapStateReturnType'
import { PlanLinkMapDispatchReturnType } from './types/PlanLinkMapDispatchReturnType'
import { PlanLinkConnectedProps } from './types/PlanLinkConnectedProps'


export const PlanLinkConnected = connect<
  PlanLinkMapStateReturnType,
  PlanLinkMapDispatchReturnType,
  PlanLinkConnectedProps>(PlanLinkMapState,
    PlanLinkMapDispatch)(PlanLink)
