import { FileDropInputFieldStyles } from './FileDropInputFieldStyles'
import React from 'react'
import { FileDropInputFieldProps } from './types/FileDropInputFieldProps'
import { FileDropInput } from 'components/input/FileDropInput/FileDropInput'
import { ErrorMessage } from 'components/message/ErrorMessage'

export const FileDropInputField = ({
  error,
  ...props
}: FileDropInputFieldProps): JSX.Element => {
  return (
    <div className={`FileDropInputField ${FileDropInputFieldStyles}`}>
      <FileDropInput {...props} />

      <ErrorMessage style={{ height: !error ? '0px' : '20px' }}>
        {error}
      </ErrorMessage>
    </div>
  )
}
