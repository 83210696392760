import {
  CLIENT_UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE,
  CLIENT_UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO,
  CLIENT_WORKSPACE_MAX_USER_COUNT_FREE,
  CLIENT_WORKSPACE_MAX_USER_COUNT_PRO,
  CLIENT_SYNC_TASK_COOLDOWN,
} from 'firebaseFunctions/constants'

const env = process && process.env // Can't be const { env } = process, dont know why

export const LOCAL = 'local'
export const LOCAL_HOST = 'localhost'
export const DEVELOPMENT = 'development'
export const BETA = 'beta'
export const DEV_HOST = 'scrumie-dev.firebaseapp.com'
export const BETA_HOST = 'eggkat-beta.firebaseapp.com'
export const PRODUCTION = 'production'
export const ENVIRONMENT_NAME = env.REACT_APP_ENV || LOCAL
export const IS_DEVELOPMENT = ENVIRONMENT_NAME === DEVELOPMENT
export const IS_LOCAL_HOST = ENVIRONMENT_NAME === LOCAL
export const IS_BETA = ENVIRONMENT_NAME === BETA
export const IS_PRODUCTION = ENVIRONMENT_NAME === PRODUCTION
export const APP_VERSION = env.REACT_APP_VERSION || ''
export const BUILD_TIME = env.REACT_APP_BUILD_TIME || ''
export const SESSION_STORAGE_KEY = 'eggkat'
export const PUBLIC_URL =
  env.REACT_APP_PUBLIC_URL || `http://${LOCAL_HOST}:3003`

export const PROFESSIONAL_PRICE_PER_USER = Number(
  env.REACT_APP_PROFESSIONAL_PRICE || 4
)
export const PROFESSIONAL_FLAT_PRICE = Number(
  env.REACT_APP_PROFESSIONAL_PRICE || 5
)
export const PROFESSIONAL_FLAT_PRICING_END_USER_COUNT = Number(
  env.REACT_APP_PROFESSIONAL_FLAT_PRICING_END_USER_COUNT || 15
)

export const TASK_COUNT_SYNC_COOLDOWN_MS = Number(
  env.REACT_APP_TASK_COUNT_SYNC_COOLDOWN_MS || CLIENT_SYNC_TASK_COOLDOWN
)

export const WORKSPACE_MAX_USER_COUNT_FREE = Number(
  env.REACT_APP_WORKSPACE_MAX_USER_COUNT_FREE ||
    CLIENT_WORKSPACE_MAX_USER_COUNT_FREE
)

export const WORKSPACE_MAX_USER_COUNT_PRO = Number(
  env.REACT_APP_WORKSPACE_MAX_USER_COUNT_PRO ||
    CLIENT_WORKSPACE_MAX_USER_COUNT_PRO
)

export const UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO = Number(
  env.REACT_APP_UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO ||
    CLIENT_UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO
)

export const UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE = Number(
  env.REACT_APP_UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE ||
    CLIENT_UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE
)

export const PAGE_SIZE_LIMIT_FREE = Number(
  env.REACT_APP_PAGE_SIZE_LIMIT_FREE || 20
)

export const PAGE_SIZE_LIMIT_PRO = Number(
  env.REACT_APP_PAGE_SIZE_LIMIT_PRO || 40
)

export const MAX_VIEW_HISTORY_COUNT = Number(
  env.REACT_APP_MAX_VIEW_HISTORY_COUNT || 10
)

export const MAX_TASK_ID_WATCHERS = Number(
  env.REACT_APP_MAX_TASK_ID_WATCHERS || 5
)

export const MAX_PLAN_TASK_COUNT = Number(
  env.REACT_APP_MAX_PLAN_TASK_COUNT || 10
)

export const MAX_OPEN_PLAN_COUNT = Number(
  env.REACT_APP_MAX_OPEN_PLAN_COUNT || 2
)

export const MAX_MESSAGING_TOKENS = Number(
  env.REACT_APP_MAX_MESSAGING_TOKENS || 10
)

export const GET_MESSAGES_LIMIT = Number(env.REACT_APP_GET_MESSAGES_LIMIT || 10)

export const DESCRIPTION_MAX_LENGTH = Number(
  env.REACT_APP_DESCRIPTION_MAX_LENGTH || 200
)

export const WORKING_STALE_SECONDS = 20
export const FEEDBACK_TEAM_ID =
  env.REACT_APP_FEEDBACK_TEAM_ID || 'EzYnVWpYsQtrvfmVfmVw'
