import {
  // InlineEditorToolBarConnectedProps,
  InlineEditorToolBarMapStateReturnType,
} from './types'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'
import { StateReducers } from 'state/reducers/types/StateReducers'

export const InlineEditorToolBarMapState = (
  state: StateReducers
  // props: InlineEditorToolBarConnectedProps,
): InlineEditorToolBarMapStateReturnType => {
  const width = state.windowMeta?.width
  const height = state.windowMeta?.height
  const isTouch = state.windowMeta?.isTouch
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    width,
    height,
    isTouch,
    isMediumScreen,
  }
}
