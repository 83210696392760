// import { Dispatch, Action } from 'redux'

import {
  // DraftEditorConnectedProps,
  DraftEditorMapDispatchReturnType,
} from './types'

export const DraftEditorMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: DraftEditorConnectedProps,
): DraftEditorMapDispatchReturnType => {
  return {
  }
}
