import { Action } from 'redux'
import { Uploads } from 'types/Uploads'
import { forEach } from 'lodash'

import { UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE } from 'actions'
import { UploadRequestAction } from 'actionCreators/Upload/types/UploadRequestAction'
import { UploadSuccessAction } from 'actionCreators/Upload/types/UploadSuccessAction'

/*
The way upload works is a bit unorthodox.  The problem is that we need to watch when the upload finishes.
The reducer is used to queue the upload jobs and the UploadWatcher component is used to dispatch when the job is done.
*/

export const uploadsReducer = (
  state?: Uploads,
  action?: Action
): Uploads | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case UPLOAD_REQUEST: {
      const uploadsAction = action as UploadRequestAction
      const { requestValues } = uploadsAction

      return {
        ...state,
        ...requestValues,
      }
    }

    case UPLOAD_SUCCESS:
      const successAction = action as UploadSuccessAction
      const successValues = successAction.successValues

      return {
        ...state,
        ...successValues,
      }

    case UPLOAD_FAILURE: // TODO: need to handle with an error message
      const failureAction = action as UploadSuccessAction
      const failureRequestValues = failureAction.requestValues

      if (state && failureRequestValues) {
        const newState = { ...state }

        forEach(failureRequestValues, (_upload, uploadId) => {
          delete newState[uploadId]
        })

        return newState
      } else {
        return null
      }

    default:
      return state || null
  }
}
