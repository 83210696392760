import React, { useEffect, useState } from 'react'
import { TEAM_NOUN } from '../../../constants/english'
import { TeamLogoWidgetStyles } from './TeamLogoWidgetStyles'
import { TeamLogoWidgetProps } from './types/TeamLogoWidgetProps'
import { ImageIcon } from '../../icon/ImageIcon/ImageIcon'
import { storageSdk } from '../../../sdks'
import { getTeamLogoStoragePath } from '../../../firebaseFunctions/utilities/getTeamLogoStoragePath'

export const TeamLogoWidget = ({
  logoUrl,
  showPlaceHolder,
  teamId,
}: TeamLogoWidgetProps): JSX.Element => {
  const [urlState, setUrlState] = useState()

  useEffect(() => {
    if (teamId) {
      const getUrl = async () => {
        const ref = storageSdk().ref()
        const { thumbPath } = getTeamLogoStoragePath({ teamId })
        const uploadRef = ref.child(thumbPath)
        const downloadUrl = await uploadRef.getDownloadURL()
        setUrlState(downloadUrl)
      }

      getUrl()
    }
  }, [teamId])

  const useUrl = logoUrl || urlState

  return (
    <>
      {useUrl && (
        <img
          className={`TeamLogoWidget ${TeamLogoWidgetStyles}`}
          src={useUrl}
          alt={`${TEAM_NOUN} logo`}
        />
      )}

      {!useUrl && showPlaceHolder && <ImageIcon />}
    </>
  )
}
