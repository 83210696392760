import { getTaskStoragePath } from './getTaskStoragePath'
import { getCanBeThumb } from './getCanBeThumb'
import { THUMB_FILE_NAME_PREFIX } from '../../constants'

interface Props {
  teamId: string
  taskId: string
  fileName: string
}

export const getTaskThumbPath = ({
  teamId,
  taskId,
  fileName,
}: Props): string | undefined => {
  const path = getTaskStoragePath({ teamId, taskId })
  const canBeThumb = getCanBeThumb(fileName)

  return canBeThumb ? `${path}${THUMB_FILE_NAME_PREFIX}${fileName}` : undefined
}
