import { Action } from 'redux'
import { ScrollPosition } from 'types/ScrollPosition'

import { SET_SCROLL_POSITION, CLEAR_TEAM_SCOPE } from 'actions'
import { SetScrollPositionAction } from 'actionCreators/set/SetScrollPosition/types/SetScrollPositionAction'

export const scrollPositionReducer = (
  state?: ScrollPosition,
  action?: Action
): ScrollPosition | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SET_SCROLL_POSITION: {
      const setScrollPositionAction = action as SetScrollPositionAction

      return {
        ...state,
        ...setScrollPositionAction.scrollPosition,
      }
    }

    case CLEAR_TEAM_SCOPE: {
      return null
    }

    default:
      return state || null
  }
}
