import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsProFromState } from './getIsProFromState'
import {
  PAGE_SIZE_LIMIT_PRO,
  PAGE_SIZE_LIMIT_FREE,
} from '../../constants/environmentConstants'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getPageSizeFromState = ({ state, teamId }: Props): number => {
  const isPro = getIsProFromState({ state, teamId })

  return isPro ? PAGE_SIZE_LIMIT_PRO : PAGE_SIZE_LIMIT_FREE
}
