import { Dispatch, Action } from 'redux'
import { TaskSummaryFieldMapDispatchReturnType } from './types/TaskSummaryFieldMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_TASK_SUMMARY, SUBMIT_WORKING_ON_TASK_SUMMARY } from 'actions'

export const TaskSummaryFieldMapDispatch = (
  dispatch: Dispatch<Action>
): TaskSummaryFieldMapDispatchReturnType => {
  return {
    submit: (summary: string) => {
      dispatch(
        createSubmitAction({
          values: {
            summary,
          },
          type: SUBMIT_TASK_SUMMARY,
        })
      )
    },

    setWorking: (working: boolean = true) => {
      dispatch(
        createSubmitAction({
          values: {
            working,
          },
          type: SUBMIT_WORKING_ON_TASK_SUMMARY,
        })
      )
    },
  }
}
