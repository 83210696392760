import { UpdateAvatarFormStyles } from './UpdateAvatarFormStyles'
import React, { useState } from 'react'
import { UpdateAvatarFormProps } from './types/UpdateAvatarFormProps'
import { FormFooter } from 'components/footer/FormFooter'
import { CropImageField } from 'components/field/CropImageField/CropImageField'
import { Crop } from 'react-image-crop'
import { FileDropInputField } from 'components/field/FileDropInputField/FileDropInputField'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { NoteMessage } from '../../message/NoteMessage'

const MAX_FILE_SIZE_BYTES = 10000000

const crop: Crop = {
  aspect: 1 / 1,
  height: 192,
  width: 192,
}

export const UpdateAvatarForm = ({
  submit,
}: UpdateAvatarFormProps): JSX.Element => {
  const [fileSourceState, setFileSourceState] = useState<string | null>(null)
  const [errorState, setErrorState] = useState<string | null>(null)
  const [croppedFileState, setCroppedFileState] = useState<Blob | null>(null)

  return (
    <form
      className={`UpdateAvatarForm ${UpdateAvatarFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()

        if (!croppedFileState) {
          // Using setErrorState because there shouldnt be any errors while cropping
          setErrorState('Please select a file.')
        } else {
          submit({ fileBlob: croppedFileState })
        }
      }}
    >
      <HeaderOneTextConnected>Update Avatar</HeaderOneTextConnected>
      {fileSourceState && (
        <CropImageField
          src={fileSourceState}
          crop={crop}
          minWidth={192}
          minHeight={192}
          keepSelection={true}
          resizeWidth={192}
          resizeHeight={192}
          onChange={(croppedFile) => {
            setCroppedFileState(croppedFile)
          }}
        />
      )}
      {!fileSourceState && (
        <FileDropInputField
          accept="image/*"
          error={errorState}
          onChange={(acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length) {
              setErrorState('Please select an image file.')
            }

            if (acceptedFiles?.[0]) {
              if (acceptedFiles[0].size > MAX_FILE_SIZE_BYTES) {
                setErrorState(
                  `Max file size is ${MAX_FILE_SIZE_BYTES / 1000000}MB.`
                )
              } else {
                const reader = new FileReader()

                reader.addEventListener('load', () => {
                  setFileSourceState(reader.result as string)
                })

                reader.readAsDataURL(acceptedFiles[0])
              }
            }
          }}
        />
      )}

      <NoteMessage className={marginTopMediumStyles}>
        The uploaded image is public.
      </NoteMessage>

      <FormFooter label="Set as profile photo" />
    </form>
  )
}
