import { UserExtension } from '../../../types'
import { getUserExtensionRef } from '../get/getUserExtensionRef'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  userId: string
  userExtension: UserExtension
}

export const setUserExtension = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  userId,
  userExtension,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getUserExtensionRef({
    firestore,
    teamId,
    userId,
  }).set(userExtension, { merge: true }) as any // use set + merge because the doc may not exist
}
