import { POST_MESSAGES_REQUEST } from 'actions'
import { PostMessagesRequestAction } from './types/PostMessagesRequestAction'
import { PostMessagesRequestActionValues } from './types/PostMessagesRequestActionValues'

interface Props {
  requestValues: PostMessagesRequestActionValues,
}

export const createPostMessagesRequestAction = ({ requestValues }: Props): PostMessagesRequestAction => {
  return {
    type: POST_MESSAGES_REQUEST,
    requestValues,
  }
}
