import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR, LINK_COLOR } from 'constants/styleConstants'

export const AccountSettingsMainStyles = css`
  > ul {
    > li {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      .CustomLink, .ModalButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #fff;
        color: ${LINK_COLOR};
        border: 1px solid rgba(0, 0, 0, 0);
        padding: 10px;

        &:hover {
          border-color: ${NEUTRAL_BORDER_COLOR};
        }
      }
    }
  }
`
