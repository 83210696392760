import { DELETE_INVITES_FAILURE } from 'actions'
import { DeleteInvitesFailureAction } from './types/DeleteInvitesFailureAction'
import { DeleteInvitesRequestActionValues } from './types/DeleteInvitesRequestActionValues'

interface Props {
  requestValues?: DeleteInvitesRequestActionValues
}

export const createDeleteInvitesFailureAction = ({
  requestValues,
}: Props = {}): DeleteInvitesFailureAction => {
  return {
    type: DELETE_INVITES_FAILURE,
    requestValues,
  }
}
