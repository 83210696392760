import { ReadAllButtonStyles } from './ReadAllButtonStyles'
import React, { useCallback } from 'react'
import { ReadAllButtonProps } from './types/ReadAllButtonProps'

export const ReadAllButton = ({
  readAll,
  notificationGroup,
}: ReadAllButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    readAll(notificationGroup)
  }, [notificationGroup, readAll])

  return (
    <button
      className={'ReadAllButton ' + ReadAllButtonStyles}
      onClick={onClickCallback}
    >
      Mark All as Read
    </button>
  )
}
