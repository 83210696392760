import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'
import {
  GET_PLANS_SUCCESS,
  POST_PLANS_REQUEST,
  DELETE_PLANS_REQUEST,
  PATCH_PLANS_REQUEST,
  CLEAR_TEAM_SCOPE,
} from 'actions'
import { PlansReducer } from './types/PlansReducer'
import { PlanReducer } from './types/PlanReducer'

export const plansReducer = createGenericReducer<PlansReducer, PlanReducer>({
  getSuccessAction: GET_PLANS_SUCCESS,
  postRequestAction: POST_PLANS_REQUEST,
  deleteRequestAction: DELETE_PLANS_REQUEST,
  patchRequestAction: PATCH_PLANS_REQUEST,
  clearActions: [CLEAR_TEAM_SCOPE],
  // debug: true,
})
