import { POST_USER_INVITED_REQUEST } from 'actions'
import { PostUserInvitedRequestAction } from './types/PostUserInvitedRequestAction'
import { PostUserInvitedRequestActionValues } from './types/PostUserInvitedRequestActionValues'

interface Props {
  requestValues: PostUserInvitedRequestActionValues,
}

export const createPostUserInvitedRequestAction = ({ requestValues }: Props): PostUserInvitedRequestAction => {
  return {
    type: POST_USER_INVITED_REQUEST,
    requestValues,
  }
}
