import './AlertMessageStyles.scss'
import * as React from 'react'
import { AlertMessageProps } from './types/AlertMessageProps'

export const AlertMessage = ({
  showMessage,
  message,
  children,
  type,
}: AlertMessageProps): JSX.Element => {
  const visible = !!showMessage
  const visibleClassName = visible ? ' visible' : ''
  const use = message || children

  return (
    <p className={`AlertMessage ${type}` + visibleClassName}>
      <em>
        {showMessage && use && (
          <span className="backendErrorMessage">{use}</span>
        )}
      </em>
    </p>
  )
}
