import { POST_INVITES_FAILURE } from 'actions'

import { PostInvitesFailureAction } from './types/PostInvitesFailureAction'
import { PostInvitesRequestActionValues } from './types/PostInvitesRequestActionValues'


interface Props {
  requestValues?: PostInvitesRequestActionValues
}

export const createPostInvitesFailureAction = ({
  requestValues,
}: Props): PostInvitesFailureAction => {
  return {
    type: POST_INVITES_FAILURE,
    requestValues,
  }
}
