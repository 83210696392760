import { HistoryDropdownStyles } from './HistoryDropdownStyles'
import React from 'react'
import { HistoryDropdownProps } from './types/HistoryDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { HistoryListConnected } from 'components/list/HistoryList'
import { HistorySolidIcon } from 'components/icon/HistorySolidIcon/HistorySolidIcon'

export const HistoryDropdown = ({
  showHistorysDropdown,
  isMediumScreen,
  className = '',
}: HistoryDropdownProps): JSX.Element => {
  const activeClassName = showHistorysDropdown ? ' active' : ''
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

  return (
    <div
      className={`HistoryDropdown ${className} ${HistoryDropdownStyles} ${isMediumScreenClassName}`}
    >
      <DropdownButtonConnected
        watchSelector=".HistoryDropdown"
        title="View task history"
        className={activeClassName}
        dropdownType="historyDropdown"
      >
        <HistorySolidIcon />
      </DropdownButtonConnected>

      {showHistorysDropdown && (
        <DropdownConnected autoAdjust={true}>
          <HistoryListConnected />
        </DropdownConnected>
      )}
    </div>
  )
}
