import { connect } from 'react-redux'
import { TeamNameLink } from './TeamNameLink'
import { TeamNameLinkMapState } from './TeamNameLinkMapState'
import { TeamNameLinkMapDispatch } from './TeamNameLinkMapDispatch'

import { TeamNameLinkMapStateReturnType } from './types/TeamNameLinkMapStateReturnType'
import { TeamNameLinkMapDispatchReturnType } from './types/TeamNameLinkMapDispatchReturnType'
import { TeamNameLinkConnectedProps } from './types/TeamNameLinkConnectedProps'


export const TeamNameLinkConnected = connect<
  TeamNameLinkMapStateReturnType,
  TeamNameLinkMapDispatchReturnType,
  TeamNameLinkConnectedProps>(TeamNameLinkMapState,
    TeamNameLinkMapDispatch)(TeamNameLink)
