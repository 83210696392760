import { StateReducers } from 'state/reducers/types/StateReducers'
import { LoadStripeInvoicesButtonConnectedProps } from './types/LoadStripeInvoicesButtonConnectedProps'
import { LoadStripeInvoicesButtonMapStateReturnType } from './types/LoadStripeInvoicesButtonMapStateReturnType'

export const LoadStripeInvoicesButtonMapState = (
  state: StateReducers,
  _props: LoadStripeInvoicesButtonConnectedProps
): LoadStripeInvoicesButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {
    canGetToken: !!state.currentUserId,
  }
}
