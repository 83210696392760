import { submitDeleteInviteF } from './submitDeleteInviteF'
import { submitDeleteTaskF } from './submitDeleteTaskF'
import { submitDeleteTaskTagF } from './submitDeleteTaskTagF'
import { submitDeleteMessageF } from './submitDeleteMessageF'
import { submitDeleteUserRoleF } from './submitDeleteUserRoleF'
import { submitDeleteUserInviteF } from './submitDeleteUserInviteF'
import { submitDeleteCustomClaimF } from './submitDeleteCustomClaimF'
import { submitDeleteLabelF } from './submitDeleteLabelF'
import { submitDeleteTaskFileNameF } from './submitDeleteTaskFileNameF'
import { submitDeleteTeamLogoF } from './submitDeleteTeamLogoF'
import { submitDeleteUploadedFileF } from './submitDeleteUploadedFileF'
import { submitDeleteWorkspaceF } from './submitDeleteWorkspaceF'

export const submitDeleteFlows = [
  submitDeleteInviteF,
  submitDeleteTaskF,
  submitDeleteMessageF,
  submitDeleteTaskTagF,
  submitDeleteUserRoleF,
  submitDeleteUserInviteF,
  submitDeleteCustomClaimF,
  submitDeleteLabelF,
  submitDeleteTaskFileNameF,
  submitDeleteTeamLogoF,
  submitDeleteUploadedFileF,
  submitDeleteWorkspaceF,
]
