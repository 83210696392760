import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { FirebaseError } from 'firebase'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { createDeleteMessagesSuccessAction } from 'actionCreators/delete/DeleteMessages/createDeleteMessagesSuccessAction'
import { createDeleteMessagesFailureAction } from 'actionCreators/delete/DeleteMessages/createDeleteMessagesFailureAction'
import { createDeleteMessagesRequestAction } from 'actionCreators/delete/DeleteMessages/createDeleteMessagesRequestAction'
import { deleteMessage } from '../../../firebaseFunctions/utilities/ref/delete/deleteMessage'

interface Props {
  items: MessagesReducer
}

// TODO: need to test
export const deleteMessagesSaga = function*({ items }: Props) {
  yield put(
    createDeleteMessagesRequestAction({
      requestValues: {
        items,
      },
    })
  )

  const { key: messageId, item: firstMessage } = getObjectFirstItem(items)

  if (messageId && firstMessage) {
    const { teamId, taskId } = firstMessage
    try {
      yield deleteMessage({
        firestore,
        teamId,
        taskId,
        messageId,
      })

      yield put(
        createDeleteMessagesSuccessAction({
          requestValues: { items },
          successValues: { items },
          description: 'deleteMessagesSaga',
        })
      )
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as FirebaseError,
          description: 'deleteMessagesSaga',
        })
      )

      yield put(
        createDeleteMessagesFailureAction({
          requestValues: { items },
        })
      )
    }
  } else {
    yield put(
      createUnexpectedErrorAction({ description: 'deleteMessagesSaga' })
    )
  }
}
