// import { TaskFileCardStyles } from './TaskFileCardStyles'
import React from 'react'
import { TaskFileCardProps } from './types/TaskFileCardProps'
import { UploadedImageWidget } from 'components/widget/UploadedImageWidget'

export const TaskFileCard = ({
  url,
  deleteFileName,
  thumbPath,
  originalPath,
  fileName,
}: TaskFileCardProps): JSX.Element => {
  return (
    <UploadedImageWidget
      url={url}
      fileName={fileName}
      thumbPath={thumbPath}
      originalPath={originalPath}
      onDeleteClick={deleteFileName}
    />
  )
}
