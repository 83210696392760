// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // ToDoLinkConnectedProps,
  ToDoLinkMapStateReturnType,
} from './types'

export const ToDoLinkMapState = (): // state: StateReducers,
// props: ToDoLinkConnectedProps,
ToDoLinkMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
