import { Action } from 'redux'
import { forEach } from 'lodash'
import { GenericReducer } from '../types/GenericReducer'
import { ApiFlowRequestAction } from 'actionCreators/types/ApiFlowRequestAction'
import { ApiFlowSuccessAction } from 'actionCreators/types/ApiFlowSuccessAction'

type Extend = (state?: any, action?: Action) => any | null

interface Props {
  getSuccessAction?: string
  postRequestAction?: string
  postFailureAction?: string
  patchRequestAction?: string
  deleteRequestAction?: string
  deleteSuccessAction?: string
  clearActions?: string[]
  debug?: boolean
  extend?: Extend
}

export const createGenericReducer = <T, M extends GenericReducer<any>>({
  getSuccessAction,
  patchRequestAction,
  deleteRequestAction,
  deleteSuccessAction,
  postRequestAction,
  postFailureAction,
  clearActions = [],
  debug,
  extend,
}: Props) => {
  return (state?: T, action?: Action): T | null => {
    if (!action) {
      return state || null
    }

    if (clearActions.indexOf(action.type) > -1) {
      return null
    }

    switch (action.type) {
      case getSuccessAction: {
        if (debug) {
          debugger
        }

        const successAction = action as ApiFlowSuccessAction<M>

        const newStateMutable = Object.assign({}, state)

        forEach(successAction.successValues.items, (value, id) => {
          newStateMutable[id] = {
            ...newStateMutable[id], // keep existing reducer props
            ...value, // merge new reducer props
            data: value.data, // overwrite data
          }
        })

        return newStateMutable
      }

      case postRequestAction: {
        if (debug) {
          debugger
        }

        const postApiRequestAction = action as ApiFlowRequestAction<M>

        const newStateMutable = Object.assign({}, state)

        forEach(postApiRequestAction.requestValues.items, (value, id) => {
          newStateMutable[id] = {
            ...newStateMutable[id], // keep existing reducer props
            ...value, // merge new reducer props
            data: value.data, // overwrite data
          }
        })

        return newStateMutable
      }

      case patchRequestAction: {
        if (debug) {
          debugger
        }

        const patchApiRequestAction = action as ApiFlowRequestAction<M>
        const newStateMutable = Object.assign({}, state)

        forEach(patchApiRequestAction.requestValues.items, (value, id) => {
          newStateMutable[id] = {
            ...newStateMutable[id], // keep existing reducer props
            ...value, // merge new reducer props
            data: {
              // merge data
              ...newStateMutable[id]?.data,
              ...value.data,
            },
          }
        })

        return newStateMutable
      }

      case deleteSuccessAction:
      case postFailureAction:
      case deleteRequestAction: {
        if (debug) {
          debugger
        }

        const deleteApiRequestAction = action as ApiFlowRequestAction<M>
        const deleteNewStateMutable = Object.assign({}, state)

        forEach(deleteApiRequestAction.requestValues.items, (_value, id) => {
          delete deleteNewStateMutable[id]
        })

        return deleteNewStateMutable
      }
    }

    return extend ? extend(state, action) : state || null
  }
}
