import { Action } from 'redux'
import { TOUCH_REGEX, ANDROID_REGEX } from 'constants/regexConstants'
import { WINDOW_RESIZE, LOCATION_CHANGE, SET_WINDOW_META } from 'actions'
import { SetWindowMetaAction } from 'actionCreators/types/SetWindowMetaAction'
import { WindowMetaR } from './types/WindowMetaR'
import { WindowResizeAction } from 'actionCreators/types/WindowResizeAction'

export const windowMetaReducer = (
  state?: WindowMetaR,
  action?: Action
): WindowMetaR | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SET_WINDOW_META: {
      const setFirebaseMessagingTokenAction = action as SetWindowMetaAction
      const { values } = setFirebaseMessagingTokenAction

      return {
        ...state,
        ...values,
      }
    }

    case WINDOW_RESIZE: {
      const getWindowMetaAction = action as WindowResizeAction
      const userAgent = navigator.userAgent.toLowerCase()
      const isTouch = TOUCH_REGEX.test(userAgent)
      const isAndroid = ANDROID_REGEX.test(userAgent)

      return {
        ...state,
        ...getWindowMetaAction.values,
        isTouch,
        isAndroid,
      }
    }

    case LOCATION_CHANGE: {
      return {
        ...state,
        referrer: window.document.referrer,
      }
    }

    default:
      return state || null
  }
}
