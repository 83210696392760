import { List } from 'immutable'
import { createCompositeDecorator } from './createCompositeDecorator'
import { MultiDecorator } from './MultiDecorator'

// Return true if decorator implements the DraftDecoratorType interface
// @see https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecoratorType.js
const decoratorIsCustom = (decorator: any) =>
  typeof decorator.getDecorations === 'function' &&
  typeof decorator.getComponentForKey === 'function' &&
  typeof decorator.getPropsForKey === 'function'

const getDecoratorsFromProps = ({ decorators, plugins }: any) =>
  List([{ decorators }, ...plugins])
    .filter((plugin) => plugin.decorators !== undefined)
    .flatMap((plugin) => plugin.decorators)

export const resolveDecorators = (
  props: any,
  getEditorState: any,
  onChange: any
) => {
  const decorators = getDecoratorsFromProps(props)
  const compositeDecorator = createCompositeDecorator(
    decorators.filter((decorator) => !decoratorIsCustom(decorator)),
    getEditorState,
    onChange
  )

  const customDecorators = decorators.filter((decorator) =>
    decoratorIsCustom(decorator)
  ) as any

  return new MultiDecorator(customDecorators.push(compositeDecorator))
}
