// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // TaskPlanDropdownConnectedProps,
  TaskPlanDropdownMapDispatchReturnType,
} from './types'

export const TaskPlanDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskPlanDropdownConnectedProps,
): TaskPlanDropdownMapDispatchReturnType => {
  return {
  }
}
