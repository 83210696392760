import { take, put, select } from 'redux-saga/effects'
import { Label, Labels } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'

import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import { SUBMIT_UPDATE_LABEL, SUBMIT_UPDATE_LABEL_DONE } from 'actions'

export const submitUpdateLabelF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_LABEL])
    const { labelId } = action.values
    const state: StateReducers = yield select(selectState)
    const team = getCurrentTeam({ state })
    const teamId = getCurrentTeamId({ state })
    const labels = team && team.labels

    if (teamId && labelId && labels) {
      const newLabel: Label = {
        ...labels[labelId],
        archived: !labels[labelId].archived,
      }

      const newLabels: Labels = {
        ...labels,
        [labelId]: newLabel,
      }

      yield put(
        createPatchTeamsRequestAction({
          requestValues: {
            items: {
              [teamId]: {
                data: {
                  labels: newLabels,
                },
              },
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateLabelF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_LABEL_DONE,
    })
  }
}
