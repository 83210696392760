import * as React from 'react'
import { TextInput } from './TextInput'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const TextInputExamples = () => {
  return (
    <div className="TextInputExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <TextInput value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
      </Provider>

      <h1 className="exampleHeader">has error</h1>

      <Provider store={createMockStore(mockState)}>
        <TextInput className="hasError" />
      </Provider>

      <h1 className="exampleHeader">clean</h1>

      <Provider store={createMockStore(mockState)}>
        <TextInput className="clean" />
      </Provider>
    </div>
  )
}
