import { FirebaseErrorAction } from './types/FirebaseErrorAction'
import { FIRESTORE_FAILURE } from 'actions'
import { FirebaseError } from 'firebase'

interface Props {
  error: FirebaseError
  description?: string
}

export const createFirebaseErrorAction = ({
  error,
  description,
}: Props): FirebaseErrorAction => {
  return {
    type: FIRESTORE_FAILURE,
    error,
    description,
  }
}
