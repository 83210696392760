import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR } from '../../../constants/styleConstants'

export const TaskTagButtonFieldStyles = css`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding-bottom: 16px;

  > li {
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding-bottom: 4px;

    &.checked {
      border-color: ${NEUTRAL_BORDER_COLOR};
    }
  }
`
