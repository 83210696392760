import * as React from 'react'
import { NotFoundMain } from './NotFoundMain'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const NotFoundMainExamples = () => {
  return (
    <div className="NotFoundMainExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <NotFoundMain />
      </Provider>
    </div>
  )
}
