import React from 'react'
import { TEAM_NOUN } from '../../../constants/english'
import { lightBoxStyles } from '../../../styles/lightBoxStyles'
import { DeleteTeamLogoButtonConnected } from '../../button/DeleteTeamLogoButton/DeleteTeamLogoButtonConnected'
import { UploadTeamLogoButtonConnected } from '../../button/UploadTeamLogoButton'
import { TeamLogoWidgetConnected } from '../../widget/TeamLogoWidget/TeamLogoWidgetConnected'
// import { UpdateWorkspaceLogoMainProps } from './types/UpdateWorkspaceLogoMainProps'
import { UpdateWorkspaceLogoMainStyles } from './UpdateWorkspaceLogoMainStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { NoteMessage } from '../../message/NoteMessage'

export const UpdateWorkspaceLogoMain = (): JSX.Element => {
  return (
    <div className={`UpdateWorkspaceLogoMain ${UpdateWorkspaceLogoMainStyles}`}>
      <HeaderOneTextConnected>
        Customize {TEAM_NOUN} logo
      </HeaderOneTextConnected>

      <div className={`existingLogo ${lightBoxStyles}`}>
        <TeamLogoWidgetConnected showPlaceHolder={true} />

        <NoteMessage className={marginTopMediumStyles}>
          The uploaded image is public.
        </NoteMessage>

        <div className={marginTopMediumStyles}>
          <DeleteTeamLogoButtonConnected />
        </div>
      </div>

      <UploadTeamLogoButtonConnected className={marginTopLargeStyles} />
    </div>
  )
}
