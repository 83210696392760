import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { DueDateDropdownConnectedProps } from './types/DueDateDropdownConnectedProps'
import { DueDateDropdownMapStateReturnType } from './types/DueDateDropdownMapStateReturnType'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const DueDateDropdownMapState = (
  state: StateReducers,
  props: DueDateDropdownConnectedProps
): DueDateDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const task = getCurrentTask({ state, taskId: props.taskId })
  const seconds = task?.dueDate?.seconds
  const canWrite = getCanWrite({ state })

  const showDueDateDropdown =
    showDropdown &&
    showDropdown.type === 'dueDate' &&
    showDropdown.id === props.taskId

  return {
    showDueDateDropdown,
    seconds,
    canWrite,
  }
}
