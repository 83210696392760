import * as React from 'react'
import { NotificationPermissionWatcherConnected } from './NotificationPermissionWatcherConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const NotificationPermissionWatcherExamples = () => {
  return (
    <div className="NotificationPermissionWatcherExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <NotificationPermissionWatcherConnected />
      </Provider>
    </div>
  )
}
