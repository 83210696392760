import { connect } from 'react-redux'
import { GoBackLink } from './GoBackLink'
import { GoBackLinkMapState } from './GoBackLinkMapState'
import { GoBackLinkMapDispatch } from './GoBackLinkMapDispatch'

import { GoBackLinkMapStateReturnType } from './types/GoBackLinkMapStateReturnType'
import { GoBackLinkMapDispatchReturnType } from './types/GoBackLinkMapDispatchReturnType'
import { GoBackLinkConnectedProps } from './types/GoBackLinkConnectedProps'


export const GoBackLinkConnected = connect<
  GoBackLinkMapStateReturnType,
  GoBackLinkMapDispatchReturnType,
  GoBackLinkConnectedProps>(GoBackLinkMapState,
    GoBackLinkMapDispatch)(GoBackLink)
