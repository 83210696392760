import { FirestoreClient } from '../../../types'
import { getFeedbackRef } from '../get/getFeedbackRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import { Feedback } from '../../../types/Feedback'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  feedbackId: string
  feedback: Feedback
}

export const setFeedback = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  feedbackId,
  feedback,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getFeedbackRef({
    firestore,
    teamId,
    feedbackId,
  }).set(feedback) as any
}
