import { LabelListStyles } from './LabelListStyles'
import React from 'react'
import { LabelListProps } from './types/LabelListProps'
import { LabelCardConnected } from 'components/card/LabelCard'

export const LabelList = ({
  labelIds = [],
  className = '',
}: LabelListProps): JSX.Element => {
  return (
    <ul className={`LabelList ${LabelListStyles} ${className}`}>
      {labelIds.map((labelId) => {
        return (
          <li key={labelId}>
            <LabelCardConnected labelId={labelId} />
          </li>
        )
      })}
    </ul>
  )
}
