import { connect } from 'react-redux'
import { BillingMain } from './BillingMain'
import { BillingMainMapState } from './BillingMainMapState'
import { BillingMainMapDispatch } from './BillingMainMapDispatch'

import { BillingMainMapStateReturnType } from './types/BillingMainMapStateReturnType'
import { BillingMainMapDispatchReturnType } from './types/BillingMainMapDispatchReturnType'
import { BillingMainConnectedProps } from './types/BillingMainConnectedProps'


export const BillingMainConnected = connect<
  BillingMainMapStateReturnType,
  BillingMainMapDispatchReturnType,
  BillingMainConnectedProps>(BillingMainMapState,
    BillingMainMapDispatch)(BillingMain)
