import { SimpleArrays } from 'types/SimpleArrays'

export const isSameFlatArray = (itemsA: SimpleArrays, itemsB: SimpleArrays) => {
  const isSameLength = itemsA.length === itemsB.length

  const isSame =
    isSameLength &&
    itemsA.every((str: string | number, index: number) => {
      return str === itemsB[index]
    })

  return isSame
}
