import { Dispatch, Action } from 'redux'
import { Invite } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { InvitesWatcherConnectedProps } from './types/InvitesWatcherConnectedProps'
import { InvitesWatcherMapDispatchReturnType } from './types/InvitesWatcherMapDispatchReturnType'
import { InvitesReducer } from 'state/reducers/types/InvitesReducer'
import { InviteReducer } from 'state/reducers/types/InviteReducer'
import { createDeleteInvitesSuccessAction } from 'actionCreators/delete/DeleteInvites/createDeleteInvitesSuccessAction'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetInvitesSuccessAction } from 'actionCreators/get/GetInvites/createGetInvitesSuccessAction'
import { createGetInvitesFailureAction } from 'actionCreators/get/GetInvites/createGetInvitesFailureAction'

export const InvitesWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: InvitesWatcherConnectedProps
): InvitesWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'InvitesWatcherMapDispatch',
          increment,
          watcherType: 'invites',
        })
      )
    },

    onNext: (querySnapshot: firebase.firestore.QuerySnapshot) => {
      const newInvitesMutable: InvitesReducer = {}
      const deletedInvitesMutable: InvitesReducer = {}

      querySnapshot.docChanges().forEach((change) => {
        const { doc } = change
        const invite = doc.data() as Invite | undefined
        const inviteId = doc.id

        if (!invite || !invite.created) {
          // skip to prevent it overwriting the temp created time
          return
        }

        const inviteReducer: InviteReducer = {
          data: invite,
          teamId: props.teamId,
        }

        if (change.type === 'added' || change.type === 'modified') {
          newInvitesMutable[inviteId] = inviteReducer
        }

        if (change.type === 'removed') {
          deletedInvitesMutable[inviteId] = inviteReducer
        }
      })

      if (Object.keys(newInvitesMutable).length > 0) {
        dispatch(
          createGetInvitesSuccessAction({
            successValues: {
              items: newInvitesMutable,
            },
            description: 'InvitesWatcherMapDispatch',
          })
        )
      }

      if (Object.keys(deletedInvitesMutable).length > 0) {
        dispatch(
          createDeleteInvitesSuccessAction({
            requestValues: {
              items: deletedInvitesMutable,
            },
            successValues: {
              items: deletedInvitesMutable,
            },
            description: 'InvitesWatcherMapDispatch',
          })
        )
      }
    },

    onFirebaseError: (error: Error) => {
      const firestoreErrorAction = createFirebaseErrorAction({
        error: error as FirebaseError,
        description: 'InvitesWatcherMapDispatch',
      })

      dispatch(firestoreErrorAction)

      if (props.teamId) {
        const getInviteFailureAction = createGetInvitesFailureAction({
          requestValues: {
            teamId: props.teamId,
          },
        })

        dispatch(getInviteFailureAction)
      }
    },
  }
}
