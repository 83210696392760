import React from 'react'
import { ButtonProps } from './types/ButtonProps'
import { buttonStyles } from '../../../styles/button/buttonStyles'

export const Button = ({
  className = '',
  type,
  ...props
}: ButtonProps): JSX.Element => {
  const additionalClassName = className ? ` ${className}` : ''

  return (
    <button
      {...props}
      type={type || 'button'}
      className={'Button ' + buttonStyles + additionalClassName}
    />
  )
}
