import { Action } from 'redux'
import { SIGN_IN_SUCCESS, SIGN_OUT_SUCCESS } from 'actions'
import { SignInSuccessAction } from 'actionCreators/auth'

export const currentUserIdReducer = (
  state?: string,
  action?: Action
): string | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SIGN_IN_SUCCESS: {
      const signInSuccessAction = action as SignInSuccessAction

      return signInSuccessAction.values.userId
    }

    case SIGN_OUT_SUCCESS: {
      return null
    }
  }

  return state || null
}
