// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // UserExtensionWatcherConnectedProps,
  UserExtensionWatcherMapStateReturnType,
} from './types'

export const UserExtensionWatcherMapState = (): // state: StateReducers,
// props: UserExtensionWatcherConnectedProps,
UserExtensionWatcherMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
