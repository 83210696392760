import { RoleTypes } from 'firebaseFunctions/types'
import { MOCK_TEAM_A_ID } from '../mockTeams'
import { InviteReducer } from 'state/reducers/types/InviteReducer'

export const mockInviteA: InviteReducer = {
  data: {
    teamId: MOCK_TEAM_A_ID,
    email: 'foo@example.com',
    role: RoleTypes.Owner,
    teamDisplayName: 'foo',
  },
  teamId: MOCK_TEAM_A_ID,
}
