import { NEUTRAL_HIGH_LIGHT_COLOR } from 'constants/styleConstants'
import { css } from 'emotion'

export const TaskStatusListStyles = css`
  > li {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid ${NEUTRAL_HIGH_LIGHT_COLOR};

    &:first-child {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }
`
