import { DELETE_UPLOADED_FILES_FAILURE } from 'actions'
import { DeleteUploadedFilesRequestActionValues } from './types/DeleteUploadedFilesRequestActionValues'
import { DeleteUploadedFilesFailureAction } from './types/DeleteUploadedFilesFailureAction'

interface Props {
  requestValues: DeleteUploadedFilesRequestActionValues
}

export const createDeleteUploadedFilesFailureAction = ({
  requestValues,
}: Props): DeleteUploadedFilesFailureAction => {
  return {
    type: DELETE_UPLOADED_FILES_FAILURE,
    requestValues,
  }
}
