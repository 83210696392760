import React from 'react'
import { ProCanceledMainProps } from './types/ProCanceledMainProps'
import { PRO_PRODUCT_NOUN, FREE_PRODUCT_NOUN } from 'constants/english'
import { USERS_NOUN, TEAM_NOUN } from 'constants/english'
import { UPGRADE_WORKSPACE_ROUTE, MEMBERS_ROUTE } from 'routes'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { lightBoxStyles } from '../../../styles/lightBoxStyles'
import { HeaderOneTextStyles } from '../../text/HeaderOneText/HeaderOneTextStyles'

export const ProCanceledMain = ({
  className = '',
  canOwn,
}: ProCanceledMainProps): JSX.Element => {
  return (
    <div className={`ProCanceledMain ${className}`}>
      <h1 className={HeaderOneTextStyles}>{PRO_PRODUCT_NOUN} Canceled</h1>
      <p className={lightBoxStyles}>
        There are too many {USERS_NOUN} for {FREE_PRODUCT_NOUN}.{' '}
        {canOwn && (
          <span>
            You can{' '}
            <CustomLinkConnected
              title="Upgrade"
              className={`${textLinkStyles}`}
              withCurrentQuery={true}
              route={UPGRADE_WORKSPACE_ROUTE}
            >
              upgrade
            </CustomLinkConnected>{' '}
            or{' '}
            <CustomLinkConnected
              className={`${textLinkStyles}`}
              title={`Manage ${USERS_NOUN}`}
              withCurrentQuery={true}
              route={MEMBERS_ROUTE}
            >
              remove
            </CustomLinkConnected>{' '}
            {USERS_NOUN}
          </span>
        )}
        {!canOwn && <span>Please contact the {TEAM_NOUN} admin.</span>}
      </p>
    </div>
  )
}
