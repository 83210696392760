import {
  TEAMS_COLLECTION,
  TASKS_COLLECTION,
  MESSAGES_COLLECTION,
} from '../../../constants'
import { FirestoreClient, Message } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  taskId: string
}

export const getMessagesRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  taskId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.CollectionReference<Message>
  : FirebaseFirestore.CollectionReference => {
  return firestore
    .collection(TEAMS_COLLECTION)
    .doc(teamId)
    .collection(TASKS_COLLECTION)
    .doc(taskId)
    .collection(MESSAGES_COLLECTION) as any
}
