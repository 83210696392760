import { getCurrentTeamId } from './getCurrentTeamId'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { UserExtension } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCurrentUserExtension = ({
  state,
  teamId,
}: Props): UserExtension | undefined => {
  const useTeamId = teamId || getCurrentTeamId({ state })
  const teams = state.teams || undefined
  const userExtension = useTeamId
    ? teams?.[useTeamId]?.userExtension
    : undefined

  return userExtension
}
