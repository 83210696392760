import { StateReducers }  from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from './getCurrentTeamId'
import { getCurrentTaskId } from './getCurrentTaskId'
import { getCurrentPlanId } from './getCurrentPlanId'
import { Params } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
}

export const getCurrentParams = ({ state }: Props): Params => {
  const currentUserId = state.currentUserId || undefined

  const teamId = getCurrentTeamId({ state })
  const taskId = getCurrentTaskId({ state })
  const planId = getCurrentPlanId({ state })

  return {
    teamId,
    taskId,
    planId,
    userId: currentUserId,
  }
}
