export const PERMISSION_DENIED = 'permission-denied'
export const TOO_MANY_REQUESTS = 'auth/too-many-requests'
export const WRONG_PASSWORD = 'auth/wrong-password'
export const USER_NOT_FOUND = 'auth/user-not-found'
export const AUTH_INTERNAL_ERROR = 'auth/internal-error'
export const POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user'
export const EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use'
export const NOTIFICATIONS_BLOCKED = 'messaging/notifications-blocked'
export const STORAGE_CANCELLED = 'storage/canceled'
export const RESOURCE_MISSING = 'resource_missing'
export const INTERNAL_ERROR = 'internal_error'
