import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdateTaskSizesFormConnectedProps } from './types/UpdateTaskSizesFormConnectedProps'
import { UpdateTaskSizesFormMapStateReturnType } from './types/UpdateTaskSizesFormMapStateReturnType'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

export const UpdateTaskSizesFormMapState = (
  state: StateReducers,
  _props: UpdateTaskSizesFormConnectedProps
): UpdateTaskSizesFormMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const taskSizes = team && team.taskSizes

  return {
    taskSizesCsv: taskSizes?.join(', '),
  }
}
