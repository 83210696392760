import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const StripeSubscriptionDropdownStyles = css`
  .EllipsisHorizontalIcon {
    width: 16px;
  }

  .Dropdown {
    .chevronContainer {
      left: -1px;
      top: 18px;
    }

    .container {
      width: 160px;
      right: -8px;
      top: 24px;
      padding: 8px;
      text-align: center;
    }
  }

  .CancelSubscriptionButton {
    .ActionButton.danger {
      margin-top: 8px;
    }
  }
`
