import { SUBMIT_DELETE_FILE } from 'actions'
import { Action, Dispatch } from 'redux'
import { createSubmitAction } from '../../../actionCreators/submit/Submit/createSubmitAction'
import { DeleteTeamLogoButtonMapDispatchReturnType } from './types/DeleteTeamLogoButtonMapDispatchReturnType'

export const DeleteTeamLogoButtonMapDispatch = (
  dispatch: Dispatch<Action>
): DeleteTeamLogoButtonMapDispatchReturnType => {
  return {
    deleteFile: (teamId: string) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_FILE,
          values: { teamId },
        })
      )
    },
  }
}
