import { DELETE_INVITES_REQUEST } from 'actions'
import { DeleteInvitesRequestAction } from './types/DeleteInvitesRequestAction'
import { DeleteInvitesRequestActionValues } from './types/DeleteInvitesRequestActionValues'

interface Props {
  requestValues: DeleteInvitesRequestActionValues
}

export const createDeleteInvitesRequestAction = ({
  requestValues,
}: Props): DeleteInvitesRequestAction => {
  return {
    type: DELETE_INVITES_REQUEST,
    requestValues,
  }
}
