import * as React from 'react'
import { HistoryListConnected } from './HistoryListConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const HistoryListExamples = () => {
  return (
    <div className="HistoryListExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <HistoryListConnected />
      </Provider>
    </div>
  )
}
