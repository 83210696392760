import { PATCH_TEAMS_FF_REQUEST } from 'actions'
import { PatchTeamsFfRequestAction } from './types/PatchTeamsFfRequestAction'
import { PatchTeamsFfRequestActionValues } from './types/PatchTeamsFfRequestActionValues'

interface Props {
  requestValues: PatchTeamsFfRequestActionValues,
}

export const createPatchTeamsFfRequestAction = ({ requestValues }: Props): PatchTeamsFfRequestAction => {
  return {
    type: PATCH_TEAMS_FF_REQUEST,
    requestValues,
  }
}
