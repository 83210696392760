import { PATCH_TEAMS_SUCCESS } from 'actions'

import { PatchTeamsSuccessAction } from './types/PatchTeamsSuccessAction'
import { PatchTeamsSuccessActionValues } from './types/PatchTeamsSuccessActionValues'
import { PatchTeamsRequestActionValues } from './types/PatchTeamsRequestActionValues'


interface Props {
  successValues: PatchTeamsSuccessActionValues
  requestValues?: PatchTeamsRequestActionValues
}

export const createPatchTeamsSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchTeamsSuccessAction => {
  return {
    type: PATCH_TEAMS_SUCCESS,
    requestValues,
    successValues,
  }
}
