import { UserWorkingWidgetStyles } from './UserWorkingWidgetStyles'
import React from 'react'
import { UserWorkingWidgetProps } from './types/UserWorkingWidgetProps'
import { AvatarConnected } from '../Avatar'
import { DotDotDotWidget } from '../DotDotDotWidget'

export const UserWorkingWidget = ({
  userId,
}: UserWorkingWidgetProps): JSX.Element => {
  return (
    <div className={'UserWorkingWidget ' + UserWorkingWidgetStyles}>
      <AvatarConnected userId={userId} />

      <DotDotDotWidget />
    </div>
  )
}
