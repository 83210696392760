import { StateReducers } from 'state/reducers/types/StateReducers'
import { NewTeamFormConnectedProps } from './types/NewTeamFormConnectedProps'
import { NewTeamFormMapStateReturnType } from './types/NewTeamFormMapStateReturnType'

export const NewTeamFormMapState = (
  _state: StateReducers,
  _props: NewTeamFormConnectedProps
): NewTeamFormMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
