import { Task } from 'firebaseFunctions/types'
import { MOCK_USER_B_ID } from '../mockUsers'
import { MOCK_TEAM_A_ID } from '../mockTeams/mockTeamsConstants'
import { TaskReducer } from 'state/reducers/types/TaskReducer'

const data: Task = {
  summary: 'mockTaskC summary',
  status: 'In Progress',
  tags: {
    [MOCK_USER_B_ID]: {
      type: 'user',
    },
  },
}

export const mockTaskC: TaskReducer = {
  data,
  teamId: MOCK_TEAM_A_ID,
}
