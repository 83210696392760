import { GET_UNSCOPE_USER_SUCCESS } from 'actions'

import { GetUnscopeUserSuccessAction } from './types/GetUnscopeUserSuccessAction'
import { GetUnscopeUserSuccessActionValues } from './types/GetUnscopeUserSuccessActionValues'
import { GetUnscopeUserRequestActionValues } from './types/GetUnscopeUserRequestActionValues'


interface Props {
  requestValues: GetUnscopeUserRequestActionValues
  successValues: GetUnscopeUserSuccessActionValues
}

export const createGetUnscopeUserSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetUnscopeUserSuccessAction => {
  return {
    type: GET_UNSCOPE_USER_SUCCESS,
    requestValues,
    successValues,
  }
}
