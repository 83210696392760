import { Dispatch, Action } from 'redux'

import {
  CREATE_USER_EXTENSION,
} from 'actions'

import {
  // AdminMainConnectedProps,
  AdminMainMapDispatchReturnType,
} from './types'

export const AdminMainMapDispatch = (
  dispatch: Dispatch<Action>,
  // props: AdminMainConnectedProps,
): AdminMainMapDispatchReturnType => {
  return {
    createUserExtension: () => {
      dispatch({
        type: CREATE_USER_EXTENSION,
      })
    },
  }
}
