import { connect } from 'react-redux'
import { EnableNotificationMessage } from './EnableNotificationMessage'
import { EnableNotificationMessageMapState } from './EnableNotificationMessageMapState'
import { EnableNotificationMessageMapDispatch } from './EnableNotificationMessageMapDispatch'
import { EnableNotificationMessageMapStateReturnType } from './types/EnableNotificationMessageMapStateReturnType'
import { EnableNotificationMessageMapDispatchReturnType } from './types/EnableNotificationMessageMapDispatchReturnType'
import { EnableNotificationMessageConnectedProps } from './types/EnableNotificationMessageConnectedProps'

export const EnableNotificationMessageConnected = connect<
  EnableNotificationMessageMapStateReturnType,
  EnableNotificationMessageMapDispatchReturnType,
  EnableNotificationMessageConnectedProps>(EnableNotificationMessageMapState,
    EnableNotificationMessageMapDispatch)(EnableNotificationMessage)
