import { RecaptchaProps } from './types/RecaptchaProps'
import { firebaseSdk } from 'sdks'
import React, { ReactNode } from 'react'

export class Recaptcha extends React.Component {
  props: RecaptchaProps
  transitionEndBinded: any

  // constructor (props: RecaptchaProps) {
  //   super(props)
  // }

  componentDidMount() {
    new firebaseSdk.auth.RecaptchaVerifier('Recaptcha', {
      callback: () => {
        this.props.onRecaptcha()
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    }).render()
  }

  render(): ReactNode {
    return <div id="Recaptcha" className="Recaptcha" />
  }
}
