// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // InvitesWatcherConnectedProps,
  InvitesWatcherMapStateReturnType,
} from './types'

export const InvitesWatcherMapState = (): // state: StateReducers,
// props: InvitesWatcherConnectedProps,
InvitesWatcherMapStateReturnType => {
  // TODO: will get an error with no custom claim
  return {}
}
