import { NewTeamFormStyles } from './NewTeamFormStyles'
import React, { useState, useCallback } from 'react'
import { NewTeamFormProps } from './types/NewTeamFormProps'
import { isInvalidTeamName } from 'firebaseFunctions/utilities/is/isInvalidTeamName'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { FormFooter } from 'components/footer/FormFooter'
import { TEAM_NOUN } from 'constants/english'

export const NewTeamForm = ({ submit }: NewTeamFormProps): JSX.Element => {
  const [displayNameState, setDisplayNameState] = useState('')
  const [errorState, setErrorState] = useState<string | null>(null)

  const validateDisplayName = useCallback(() => {
    const error = isInvalidTeamName(displayNameState)

    setErrorState(error || null)

    return error
  }, [displayNameState])

  return (
    <form
      className={`NewTeamForm ${NewTeamFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const error = validateDisplayName()

        if (!error) {
          submit({ displayName: displayNameState })
        }
      }}
    >
      <TextInputField
        label="Name"
        type="text"
        placeholder="Team Awesome"
        value={displayNameState}
        error={errorState}
        onChange={(e) => {
          setDisplayNameState(e.target.value)
        }}
        className={errorState ? 'hasError' : ''}
        onFocus={() => {
          setErrorState(null)
        }}
        onBlur={validateDisplayName}
      />

      <FormFooter label={`Create ${TEAM_NOUN}`} />
    </form>
  )
}
