import { StateReducers } from 'state/reducers/types/StateReducers'
import {
  // TeamStatWatcherConnectedProps,
  TeamStatWatcherMapStateReturnType,
} from './types'
import { getCanRead } from 'state/getters/getCanRead'

export const TeamStatWatcherMapState = (
  state: StateReducers
  // props: TeamStatWatcherConnectedProps,
): TeamStatWatcherMapStateReturnType => {
  const canRead = getCanRead({ state })

  return {
    canRead,
  }
}
