import { submitNewTaskF } from './submitNewTaskF'
import { submitNewTeamF } from './submitNewTeamF'
import { submitNewUserF } from './submitNewUserF'
import { submitNewUserInvitedF } from './submitNewUserInvitedF'
import { submitNewInviteF } from './submitNewInviteF'
import { submitNewMessageF } from './submitNewMessageF'
import { submitNewPlanF } from './submitNewPlanF'
import { submitNewLabelF } from './submitNewLabelF'
import { submitNewMessageFilesF } from './submitNewMessageFilesF'
import { submitNewTeamLogoF } from './submitNewTeamLogoF'

export const submitNewFlows = [
  submitNewTaskF,
  submitNewTeamF,
  submitNewUserF,
  submitNewMessageF,
  submitNewInviteF,
  submitNewPlanF,
  submitNewLabelF,
  submitNewMessageFilesF,
  submitNewUserInvitedF,
  submitNewTeamLogoF,
]
