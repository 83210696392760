import { Dispatch, Action } from 'redux'
import { ResetPasswordFormMapDispatchReturnType } from './types/ResetPasswordFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_RESET_PASSWORD } from 'actions'

export const ResetPasswordFormMapDispatch = (
  dispatch: Dispatch<Action>
): ResetPasswordFormMapDispatchReturnType => {
  return {
    submit: ({ email }) => {
      dispatch(
        createSubmitAction({
          values: { email },
          type: SUBMIT_RESET_PASSWORD,
          description: 'ResetPasswordFormMapDispatch',
        })
      )
    },
  }
}
