import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const TaskDescriptionMainStyles = css`
  position: relative;

  .UserWorkingWidget {
    display: flex;
    position: absolute;
    padding: 16px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 100;
  }
`
