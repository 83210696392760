import { PATCH_USER_INVITES_FAILURE } from 'actions'

import { PatchUserInvitesFailureAction } from './types/PatchUserInvitesFailureAction'
import { PatchUserInvitesRequestActionValues } from './types/PatchUserInvitesRequestActionValues'


interface Props {
  requestValues: PatchUserInvitesRequestActionValues
}

export const createPatchUserInvitesFailureAction = ({
  requestValues,
}: Props): PatchUserInvitesFailureAction => {
  return {
    type: PATCH_USER_INVITES_FAILURE,
    requestValues,
  }
}
