import { take, put, select } from 'redux-saga/effects'
import { SUBMIT_READ_ALL, SUBMIT_READ_ALL_DONE } from 'actions'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getNotifications } from 'state/getters/getNotifications'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createPatchUserExtensionRequestDelayAction } from 'actionCreators/patch/PatchUserExtension/createPatchUserExtensionRequestDelayAction'

export const submitReadAllF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_READ_ALL])
    const { notificationGroup } = action.values
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const userId = state.currentUserId

    if (teamId && userId && notificationGroup) {
      const notifications = getNotifications({ state, notificationGroup })

      const newNotifications = notifications.map((notification) => {
        return {
          ...notification,
          unreadCount: 0,
        }
      })

      const newUserExtension =
        notificationGroup === 'messages'
          ? { messageNotifications: newNotifications }
          : { activityNotifications: newNotifications }

      yield put(
        createPatchUserExtensionRequestDelayAction({
          requestValues: {
            params: {
              teamId,
              userId,
            },
            item: {
              ...newUserExtension,
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitReadAllF',
        })
      )
    }

    yield put({
      type: SUBMIT_READ_ALL_DONE,
    })
  }
}
