import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import { getPlansRef } from './getPlansRef'
import { Plan } from '../../../types/Plan'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  planId: string
}

export const getPlanRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  planId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<Plan>
  : FirebaseFirestore.DocumentReference => {
  return getPlansRef({ firestore, teamId }).doc(planId) as any
}
