import { css } from 'emotion'
import { WARNING_HIGHLIGHT_COLOR } from 'constants/styleConstants'

export const UserInviteListStyles = css`
  > li {
    margin-top: 8px;
    background-color: ${WARNING_HIGHLIGHT_COLOR};

    &:first-child {
      margin-top: 0;
    }
  }
`
