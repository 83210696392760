import React, { useCallback } from 'react'
import { TaskTagButtonFieldProps } from './types/TaskTagButtonFieldProps'
import { SelectTagOption } from '../../option/SelectTagOption'
import { TaskTagButtonFieldStyles } from './TaskTagButtonFieldStyles'
import { marginRightSmallestStyles } from '../../../styles/margin/marginRightSmallestStyles'

export const TaskTagButtonField = ({
  className = '',
  values,
  labels,
  types,
  submit,
  removeTag,
  tagIds,
  taskId,
}: TaskTagButtonFieldProps): JSX.Element => {
  const onClickCallback = useCallback(
    (e: React.MouseEvent<HTMLUListElement>) => {
      const targetAny = e.target as any
      const tagId =
        targetAny.parentElement.id || targetAny.parentElement.parentElement.id

      if (!tagId) {
        return
      }

      const checked = tagIds.indexOf(tagId) > -1

      if (checked) {
        removeTag({ taskId, tagId })
      } else {
        const index = values.findIndex((value) => {
          return value === tagId
        })

        submit({
          id: tagId,
          displayName: labels[index] || '(no name)',
          type: types[index],
          taskId,
        })
      }
    },
    [labels, types, taskId, submit, values, tagIds, removeTag]
  )
  return (
    <ul
      className={`TaskTagButtonField ${className} ${TaskTagButtonFieldStyles}`}
      onClick={onClickCallback}
    >
      {values.map((value, i) => {
        const checked = tagIds.indexOf(value) > -1
        return (
          <li
            key={value}
            className={`${
              checked ? 'checked' : ''
            } ${marginRightSmallestStyles}`}
          >
            <SelectTagOption
              id={value}
              type={types[i]}
              showDisplayName={false}
            />
          </li>
        )
      })}
    </ul>
  )
}
