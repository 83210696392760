import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getIsBigScreen } from 'state/getters/getIsBigScreen'

import {
  // TaskMainConnectedProps,
  TaskMainMapStateReturnType,
} from './types'

export const TaskMainMapState = (
  state: StateReducers
  // props: TaskMainConnectedProps,
): TaskMainMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const isBigScreen = getIsBigScreen({ state })
  const unavailableTaskIndex = taskId && state.unavailableTasks?.indexOf(taskId)
  const isNotAvailable =
    unavailableTaskIndex !== undefined && unavailableTaskIndex > -1

  return {
    isNotAvailable,
    taskId,
    isBigScreen,
  }
}
