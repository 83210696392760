import { EditorState, convertToRaw } from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js'
import { ContentState } from 'draft-js'
import { RefObject } from 'react'
import { PluginEditor } from './pluginEditor/PluginEditor'

interface EditorSubmitProps {
  editorState: EditorState
  editorRef: RefObject<PluginEditor>
  save?: (markdown: string) => void
  setContentChangedSinceSaved?: (value: boolean) => void
  clearOnSave?: boolean
  setEditorState: (editorState: EditorState) => void
}

export const editorSubmit = ({
  editorState,
  save,
  setContentChangedSinceSaved,
  clearOnSave,
  setEditorState,
  editorRef,
}: EditorSubmitProps) => {
  if (save && editorState) {
    setContentChangedSinceSaved?.(false)

    const content = editorState.getCurrentContent()
    const raw = convertToRaw(content)

    const markdown = draftToMarkdown(raw, {
      preserveNewlines: true,
      entityItems: {
        mention: {
          open: function(_entity: any) {
            return '['
          },

          close: function(entity: any) {
            return '](@' + entity.data.mention.id + ')'
          },
        },
      },
    })

    save(markdown)

    editorRef.current?.blur()

    if (clearOnSave) {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(''),
          'remove-range'
        )
      )

      setEditorState(newEditorState)
    }
  }
}
