import { connect } from 'react-redux'
import { TaskTagsMain } from './TaskTagsMain'
import { TaskTagsMainMapState } from './TaskTagsMainMapState'
import { TaskTagsMainMapDispatch } from './TaskTagsMainMapDispatch'

import { TaskTagsMainMapStateReturnType } from './types/TaskTagsMainMapStateReturnType'
import { TaskTagsMainMapDispatchReturnType } from './types/TaskTagsMainMapDispatchReturnType'
import { TaskTagsMainConnectedProps } from './types/TaskTagsMainConnectedProps'


export const TaskTagsMainConnected = connect<
  TaskTagsMainMapStateReturnType,
  TaskTagsMainMapDispatchReturnType,
  TaskTagsMainConnectedProps>(TaskTagsMainMapState,
    TaskTagsMainMapDispatch)(TaskTagsMain)
