import { connect } from 'react-redux'
import { TaskPlanSpan } from './TaskPlanSpan'
import { TaskPlanSpanMapState } from './TaskPlanSpanMapState'
import { TaskPlanSpanMapDispatch } from './TaskPlanSpanMapDispatch'

import { TaskPlanSpanMapStateReturnType } from './types/TaskPlanSpanMapStateReturnType'
import { TaskPlanSpanMapDispatchReturnType } from './types/TaskPlanSpanMapDispatchReturnType'
import { TaskPlanSpanConnectedProps } from './types/TaskPlanSpanConnectedProps'


export const TaskPlanSpanConnected = connect<
  TaskPlanSpanMapStateReturnType,
  TaskPlanSpanMapDispatchReturnType,
  TaskPlanSpanConnectedProps>(TaskPlanSpanMapState,
    TaskPlanSpanMapDispatch)(TaskPlanSpan)
