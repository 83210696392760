import { Modifier, EditorState } from 'draft-js'
import { getSearchText } from './getSearchText'
import { getTypeByTrigger } from './getTypeByTrigger'

export const addMention = (
  editorState: any,
  mention: any,
  mentionPrefix: any,
  mentionTrigger: any,
  entityMutability: any
) => {
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity(getTypeByTrigger(mentionTrigger), entityMutability, {
      mention,
    })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

  const currentSelectionState = editorState.getSelection()
  const { begin, end } = getSearchText(
    editorState,
    currentSelectionState,
    mentionTrigger
  )

  // get selection of the @mention search text
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: end,
  })

  let mentionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    mentionTextSelection,
    `${mentionPrefix}${mention.name}`,
    undefined, // no inline style needed
    entityKey
  )

  // If the mention is inserted at the end, a space is appended right after for
  // a smooth writing experience.
  const blockKey = mentionTextSelection.getAnchorKey()
  const blockSize = editorState
    .getCurrentContent()
    .getBlockForKey(blockKey)
    .getLength()
  if (blockSize === end) {
    mentionReplacedContent = Modifier.insertText(
      mentionReplacedContent,
      mentionReplacedContent.getSelectionAfter(),
      ' '
    )
  }

  const newEditorState = EditorState.push(
    editorState,
    mentionReplacedContent,
    'insert-mention' as any
  )
  return EditorState.forceSelection(
    newEditorState,
    mentionReplacedContent.getSelectionAfter()
  )
}
