import { StateReducers } from 'state/reducers/types/StateReducers'
import { RoleTypes } from 'firebaseFunctions/types'
import { getTeamRole } from './getTeamRole'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCanPossiblyOwn = ({ state, teamId }: Props): boolean => {
  const role = getTeamRole({ state, teamId })

  return role === RoleTypes.Owner
}
