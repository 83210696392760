import { FirebaseAuthStateChangedAction } from './types/FirebaseAuthStateChangedAction'
import { FIREBASE_AUTH_STATE_CHANGED } from 'actions'
import { User } from 'firebase'

interface Props {
  user?: User
}

export const createFirebaseAuthStateChangedAction = ({
  user,
}: Props): FirebaseAuthStateChangedAction => {
  return {
    type: FIREBASE_AUTH_STATE_CHANGED,
    values: user,
  }
}
