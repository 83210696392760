import { css } from 'emotion'
// import { TEXT_COLOR } from 'constants/styleConstants'

export const MessageActionDropdownStyles = css`
  position: relative;

  > button {
    padding: 4px 8px;
    font-size: 0.75em;
  }

  .Dropdown {
    .chevronContainer {
      right: 4px;
      top: 25px;
    }

    .container {
      width: 280px;
      top: 32px;
    }
  }
`
