import { connect } from 'react-redux'
import { TaskTagList } from './TaskTagList'
import { TaskTagListMapState } from './TaskTagListMapState'
import { TaskTagListMapDispatch } from './TaskTagListMapDispatch'

import { TaskTagListMapStateReturnType } from './types/TaskTagListMapStateReturnType'
import { TaskTagListMapDispatchReturnType } from './types/TaskTagListMapDispatchReturnType'
import { TaskTagListConnectedProps } from './types/TaskTagListConnectedProps'


export const TaskTagListConnected = connect<
  TaskTagListMapStateReturnType,
  TaskTagListMapDispatchReturnType,
  TaskTagListConnectedProps>(TaskTagListMapState,
    TaskTagListMapDispatch)(TaskTagList)
