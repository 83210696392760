import { connect } from 'react-redux'
import { NewTaskForm } from './NewTaskForm'
import { NewTaskFormMapState } from './NewTaskFormMapState'
import { NewTaskFormMapDispatch } from './NewTaskFormMapDispatch'
import { NewTaskFormMapStateReturnType } from './types/NewTaskFormMapStateReturnType'
import { NewTaskFormMapDispatchReturnType } from './types/NewTaskFormMapDispatchReturnType'
import { NewTaskFormConnectedProps } from './types/NewTaskFormConnectedProps'

export const NewTaskFormConnected = connect<
  NewTaskFormMapStateReturnType,
  NewTaskFormMapDispatchReturnType,
  NewTaskFormConnectedProps
>(
  NewTaskFormMapState,
  NewTaskFormMapDispatch
)(NewTaskForm)
