import every from 'lodash/every'
import { IsRoutes } from './types/IsRoutes'

import {
  DEPRECATED_ROUTES,
} from './deprecatedRoutes'

import { getIsPreAndPostLoginRoutes } from './getIsPreAndPostLoginRoutes'
import { getIsPostLoginRoutes } from './getIsPostLoginRoutes'

import {
  oneLayoutRoutes,
} from './oneLayoutRoutes'

import {
  dashboardLayoutRoutes,
} from './dashboardLayoutRoutes'

interface Props {
  pathname: string
}

export const getIsRoutes = ({ pathname = '' }: Props): IsRoutes => {
  const isDeprecatedRoute = DEPRECATED_ROUTES.indexOf(pathname) > -1
  const isOneLayoutRoute = oneLayoutRoutes.indexOf(pathname) > -1
  const isDashboardLayoutRoute = dashboardLayoutRoutes.indexOf(pathname) > -1
  const isPreAndPostLoginRoutes = getIsPreAndPostLoginRoutes({ pathname })
  const isPostLoginRoutes = getIsPostLoginRoutes({ pathname })

  const isRoutes = {
    ...isPreAndPostLoginRoutes,
    ...isPostLoginRoutes,
  }

  const isNotFoundRoute: boolean = isDeprecatedRoute || every(isRoutes, (isPath: boolean) => {
    return !isPath
  })

  return {
    ...isRoutes,
    isNotFoundRoute,
    isDeprecatedRoute,
    isOneLayoutRoute,
    isDashboardLayoutRoute,
  }
}
