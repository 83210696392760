import * as React from 'react'
import { InvitesWatcherProps } from './types/InvitesWatcherProps'
import { firestore } from 'sdks'
import { getInvitesRef } from 'firebaseFunctions/utilities/ref/get/getInvitesRef'

export class InvitesWatcher extends React.Component<InvitesWatcherProps> {
  unsubscribe?: () => void

  unsubscribeAll() {
    if (this.unsubscribe) {
      this.unsubscribe()
      this.unsubscribe = undefined
      this.props.incrementWatcher(-1)
    }
  }

  componentWillUnmount() {
    this.unsubscribeAll()
  }

  public render(): null {
    const { teamId, onNext, onFirebaseError, incrementWatcher } = this.props

    if (!this.unsubscribe) {
      this.unsubscribe = getInvitesRef({
        firestore,
        teamId,
      }).onSnapshot(onNext, onFirebaseError)

      incrementWatcher(1)
    }

    return null
  }
}
