// import { Dispatch, Action } from 'redux'

import {
  // TaskChangeCardConnectedProps,
  TaskChangeCardMapDispatchReturnType,
} from './types'

export const TaskChangeCardMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskChangeCardConnectedProps,
): TaskChangeCardMapDispatchReturnType => {
  return {
  }
}
