import { connect } from 'react-redux'
import { ToNextCycleButton } from './ToNextCycleButton'
import { ToNextCycleButtonMapState } from './ToNextCycleButtonMapState'
import { ToNextCycleButtonMapDispatch } from './ToNextCycleButtonMapDispatch'
import { ToNextCycleButtonMapStateReturnType } from './types/ToNextCycleButtonMapStateReturnType'
import { ToNextCycleButtonMapDispatchReturnType } from './types/ToNextCycleButtonMapDispatchReturnType'
import { ToNextCycleButtonConnectedProps } from './types/ToNextCycleButtonConnectedProps'

export const ToNextCycleButtonConnected = connect<
  ToNextCycleButtonMapStateReturnType,
  ToNextCycleButtonMapDispatchReturnType,
  ToNextCycleButtonConnectedProps>(ToNextCycleButtonMapState,
    ToNextCycleButtonMapDispatch)(ToNextCycleButton)
