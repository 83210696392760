import { MemberListStyles } from './MemberListStyles'
import React from 'react'
import { MemberListProps } from './types/MemberListProps'
import { MemberCardConnected } from 'components/card/MemberCard'
import { InviteCardConnected } from 'components/card/InviteCard'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { INVITE_DETAIL_ROUTE, MEMBER_DETAIL_ROUTE } from 'routes'

export const MemberList = ({
  userIds = [],
  orderedInviteIds = [],
}: MemberListProps): JSX.Element => {
  return (
    <ul className={'MemberList ' + MemberListStyles}>
      {orderedInviteIds.map((inviteId) => {
        return (
          <li key={inviteId}>
            <CustomLinkConnected
              title="invite"
              route={INVITE_DETAIL_ROUTE}
              withCurrentQuery={true}
              inviteId={inviteId}
            >
              <InviteCardConnected inviteId={inviteId} />
            </CustomLinkConnected>
          </li>
        )
      })}

      {userIds.map((userId) => {
        return (
          <li key={userId}>
            <CustomLinkConnected
              title="member"
              route={MEMBER_DETAIL_ROUTE}
              withCurrentQuery={true}
              userId={userId}
            >
              <MemberCardConnected userId={userId} />
            </CustomLinkConnected>
          </li>
        )
      })}
    </ul>
  )
}
