import { TaskTagListStyles } from './TaskTagListStyles'
import React from 'react'
import { TaskTagListProps } from './types/TaskTagListProps'
import { TagDropdownConnected } from 'components/dropdown/TagDropdown'
import { TaskTagDropdownConnected } from 'components/dropdown/TaskTagDropdown'
import { TaskTagCardConnected } from 'components/card/TaskTagCard'

export const TaskTagList = ({
  tagIds = [],
  isDetail,
  showTagDropdownFirst,
  showTagDropdownLast,
  taskId,
  canWrite,
}: TaskTagListProps): JSX.Element => {
  const detailClassName = isDetail ? ' isDetail' : ''

  return (
    <ul className={'TaskTagList ' + TaskTagListStyles + detailClassName}>
      {showTagDropdownFirst && taskId && canWrite && (
        <li>
          <TagDropdownConnected taskId={taskId} />
        </li>
      )}

      {taskId &&
        tagIds.map((tagId) => {
          return (
            <li key={tagId}>
              {!isDetail && (
                <TaskTagDropdownConnected tagId={tagId} taskId={taskId} />
              )}

              {isDetail && (
                <TaskTagCardConnected
                  showDisplayName={true}
                  showRemove={true}
                  taskId={taskId}
                  tagId={tagId}
                />
              )}
            </li>
          )
        })}

      {showTagDropdownLast && taskId && canWrite && (
        <li>
          <TagDropdownConnected taskId={taskId} />
        </li>
      )}
    </ul>
  )
}
