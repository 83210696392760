import { take, put } from 'redux-saga/effects'
import { storageSdk } from 'sdks'
import { getTeamLogoStoragePath } from 'firebaseFunctions/utilities/getTeamLogoStoragePath'
import { GET_TEAM_LOGO_URL_REQUEST } from 'actions'
import { GetTeamLogoUrlRequestAction } from 'actionCreators/get/GetTeamLogoUrl/types/GetTeamLogoUrlRequestAction'
import { createGetTeamLogoUrlSuccessAction } from 'actionCreators/get/GetTeamLogoUrl/createGetTeamLogoUrlSuccessAction'

export const getTeamLogoUrlRequestF = function*() {
  while (1) {
    const action: GetTeamLogoUrlRequestAction = yield take([
      GET_TEAM_LOGO_URL_REQUEST,
    ])
    const { requestValues } = action
    const teamId = requestValues.teamId
    const ref = storageSdk().ref()
    const { thumbPath } = getTeamLogoStoragePath({ teamId })
    const uploadRef = ref.child(thumbPath)
    const downloadUrl = yield uploadRef.getDownloadURL()

    yield put(
      createGetTeamLogoUrlSuccessAction({
        requestValues,
        successValues: {
          logoUrl: downloadUrl,
        },
      })
    )
  }
}
