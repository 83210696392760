import { GET_TEAMS_FAILURE } from 'actions'
import { GetTeamsFailureAction } from './types/GetTeamsFailureAction'
import { GetTeamsRequestActionValues } from './types/GetTeamsRequestActionValues'

interface Props {
  requestValues?: GetTeamsRequestActionValues
  description: string
}

export const createGetTeamsFailureAction = ({
  requestValues,
  description,
}: Props): GetTeamsFailureAction => {
  return {
    type: GET_TEAMS_FAILURE,
    requestValues,
    description,
  }
}
