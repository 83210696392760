import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  TestingMainConnectedProps,
  TestingMainMapStateReturnType,
} from './types'

export const TestingMainMapState = (
  state: StateReducers,
  _props: TestingMainConnectedProps
): TestingMainMapStateReturnType => {
  const testing = state.sessionStorage?.testing

  return {
    testing,
  }
}
