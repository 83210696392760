import { StateReducers } from '../reducers/types/StateReducers'
import { getActivePlanId } from './getActivePlanId'
import { Plan } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
}

export const getActivePlan = ({ state }: Props): Plan | undefined => {
  const plans = state.plans || undefined
  const planId = getActivePlanId({ state })
  const plan = planId ? plans?.[planId]?.data : undefined

  return plan
}
