import * as React from 'react'
import { Spinner } from 'components/widget/Spinner'
import { FormSpinnerProps } from './types/FormSpinnerProps'

// TODO: move to spiner
export const FormSpinner = ({
  showFormSpinner,
}: FormSpinnerProps) => {
  const visibility = showFormSpinner ? 'visible' : 'hidden'

  return (
    <Spinner className={'FormSpinner'} style={{ visibility }} />
  )
}
