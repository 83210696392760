import * as React from 'react'
import { TaskTagCardConnected } from './TaskTagCardConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_USER_A_ID, MOCK_TASK_A_ID } from 'mockState'

export const TaskTagCardExamples = () => {
  return (
    <div className="TaskTagCardExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <TaskTagCardConnected tagId={MOCK_USER_A_ID} taskId={MOCK_TASK_A_ID} />
      </Provider>

      <h1 className="exampleHeader">show delete and display name</h1>

      <Provider store={createMockStore(mockState)}>
        <TaskTagCardConnected tagId={MOCK_USER_A_ID} taskId={MOCK_TASK_A_ID} showRemove={true} showDisplayName={true} />
      </Provider>
    </div>
  )
}
