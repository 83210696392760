import { LabelCardStyles } from './LabelCardStyles'
import React from 'react'
import { LabelCardProps } from './types/LabelCardProps'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { LabelDropdownConnected } from 'components/dropdown/LabelDropdown/LabelDropdownConnected'

export const LabelCard = ({ labelId, icon }: LabelCardProps): JSX.Element => {
  return (
    <div className={`LabelCard ${LabelCardStyles} ${lightBoxStyles}`}>
      <LabelDropdownConnected labelId={labelId} />

      <div className="iconAndDisplayName">
        <span className="icon">{icon}</span>

        <span className="displayName">{labelId}</span>
      </div>
    </div>
  )
}
