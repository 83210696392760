import { css } from 'emotion'
// import { PRIMARY_COLOR, BORDER_RADIUS } from 'constants/styleConstants'

export const PrimaryDropdownStyles = css`
  position: relative;
  height: 25px;

  .DropdownButton,
  .EllipsisVertialIcon {
    height: 25px;
  }

  > button {
    font-size: 1.6em;
  }

  .Dropdown {
    .chevronContainer {
      right: 3px;
    }

    .container {
      right: 0px;
      width: 352px;
    }
  }
`
