import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskMainDropdownConnectedProps } from './types/TaskMainDropdownConnectedProps'
import { TaskMainDropdownMapStateReturnType } from './types/TaskMainDropdownMapStateReturnType'

export const TaskMainDropdownMapState = (
  state: StateReducers,
  props: TaskMainDropdownConnectedProps
): TaskMainDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown

  const showTaskMainDropdown =
    showDropdown &&
    showDropdown.type === 'taskDetailDropdown' &&
    showDropdown.id === props.taskId

  return {
    showTaskMainDropdown,
  }
}
