import { GET_MESSAGES_FAILURE } from 'actions'

import { GetMessagesFailureAction } from './types/GetMessagesFailureAction'
import { GetMessagesRequestActionValues } from './types/GetMessagesRequestActionValues'


interface Props {
  requestValues: GetMessagesRequestActionValues
}

export const createGetMessagesFailureAction = ({
  requestValues,
}: Props): GetMessagesFailureAction => {
  return {
    type: GET_MESSAGES_FAILURE,
    requestValues,
  }
}
