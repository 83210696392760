import { getCurrentTask } from './../../../state/getters/getCurrentTask'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskPlanActionListConnectedProps } from './types/TaskPlanActionListConnectedProps'
import { TaskPlanActionListMapStateReturnType } from './types/TaskPlanActionListMapStateReturnType'
import { getCyclePlanIds } from '../../../state/getters/getCyclePlanIds'
import { CycleTypes } from '../../../types/CycleTypes'

export const TaskPlanActionListMapState = (
  state: StateReducers,
  props: TaskPlanActionListConnectedProps
): TaskPlanActionListMapStateReturnType => {
  const task = getCurrentTask({ state, taskId: props.taskId })
  const { previousPlanId, currentPlanId, nextPlanId } = getCyclePlanIds({
    state,
  })

  let taskCycle: CycleTypes | undefined = undefined

  if (task?.planId === previousPlanId) {
    taskCycle = 'previous'
  }

  if (task?.planId === nextPlanId) {
    taskCycle = 'next'
  }

  if (task?.planId === currentPlanId) {
    taskCycle = 'current'
  }

  return {
    taskCycle,
    previousPlanId,
  }
}
