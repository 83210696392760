import { connect } from 'react-redux'
import { TaskStatusButtonList } from './TaskStatusButtonList'
import { TaskStatusButtonListMapState } from './TaskStatusButtonListMapState'
import { TaskStatusButtonListMapDispatch } from './TaskStatusButtonListMapDispatch'

import { TaskStatusButtonListMapStateReturnType } from './types/TaskStatusButtonListMapStateReturnType'
import { TaskStatusButtonListMapDispatchReturnType } from './types/TaskStatusButtonListMapDispatchReturnType'
import { TaskStatusButtonListConnectedProps } from './types/TaskStatusButtonListConnectedProps'


export const TaskStatusButtonListConnected = connect<
  TaskStatusButtonListMapStateReturnType,
  TaskStatusButtonListMapDispatchReturnType,
  TaskStatusButtonListConnectedProps>(TaskStatusButtonListMapState,
    TaskStatusButtonListMapDispatch)(TaskStatusButtonList)
