import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'
import {
  UploadedFilesMainConnectedProps,
  UploadedFilesMainMapStateReturnType,
} from './types'
import { firestore } from 'firebase'
import { getIsProFromState } from 'state/getters/getIsProFromState'
import { getMaxUploadSize } from '../../../state/getters/getMaxUploadSize'

export const UploadedFilesMainMapState = (
  state: StateReducers,
  _props: UploadedFilesMainConnectedProps
): UploadedFilesMainMapStateReturnType => {
  const teamReducer = getCurrentTeamReducer({ state })
  const teamStat = teamReducer?.stats
  const fileUploadedBytes =
    teamStat?.fileUploadedBytes instanceof firestore.FieldValue
      ? undefined
      : teamStat?.fileUploadedBytes
  const teamId = getCurrentTeamId({ state })
  const isPro = getIsProFromState({ state })
  const { gigabytesPerUserFree, gigabytesPerUserPro } = getMaxUploadSize({
    state,
    teamId,
  })

  const userCount = teamStat?.userCount || 1
  const uploadCapacityGigabytesPerUser = isPro
    ? gigabytesPerUserPro
    : gigabytesPerUserFree

  return {
    teamId,
    uploadCapacityGigabytesPerUser,
    fileUploadedBytes,
    userCount,
  }
}
