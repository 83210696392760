import { StateReducers } from 'state/reducers/types/StateReducers'
import { pickBy } from 'lodash'

import {
  // UserInviteListConnectedProps,
  UserInviteListMapStateReturnType,
} from './types'

export const UserInviteListMapState = (
  state: StateReducers
  // props: UserInviteListConnectedProps,
): UserInviteListMapStateReturnType => {
  const userInvites = state.userInvites || {}

  const unacceptedInvites = pickBy(userInvites, (userInvite) => {
    return !userInvite.data.accepted
  })

  const inviteIds = Object.keys(unacceptedInvites)

  return {
    inviteIds,
  }
}
