// import { Dispatch, Action } from 'redux'

import {
  // MessageFileListConnectedProps,
  MessageFileListMapDispatchReturnType,
} from './types'

export const MessageFileListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessageFileListConnectedProps,
): MessageFileListMapDispatchReturnType => {
  return {
  }
}
