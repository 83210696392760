import { forEach } from 'lodash'
import { getUserRoles } from './getUserRoles'
import { getLabels } from './getLabels'
import { TagType } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

interface Return {
  values: string[]
  types: TagType[]
  labels: string[]
}

export const getTagSelectOptions = ({ state }: Props): Return => {
  const userRoles = getUserRoles({ state })
  const teamLabels = getLabels({ state })
  const types: TagType[] = []
  const values: string[] = []
  const labels: string[] = []

  if (teamLabels) {
    forEach(teamLabels, (label, labelId) => {
      if (!label.archived) {
        types.push('label')
        values.push(labelId)
        labels.push(labelId)
      }
    })
  }

  if (userRoles) {
    forEach(userRoles, ({ displayName }, userId) => {
      types.push('user')
      values.push(userId)
      labels.push(displayName || '(no name)')
    })
  }

  return {
    types,
    values,
    labels,
  }
}
