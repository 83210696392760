import { StateReducers } from 'state/reducers/types/StateReducers'

import { UpdateMessageEditorConnectedProps } from './types/UpdateMessageEditorConnectedProps'
import { UpdateMessageEditorMapStateReturnType } from './types/UpdateMessageEditorMapStateReturnType'


export const UpdateMessageEditorMapState = (
  state: StateReducers,
  props: UpdateMessageEditorConnectedProps
): UpdateMessageEditorMapStateReturnType => {
  const message = state.messages?.[props.messageId]?.data
  const description = message && message.description

  return {
    description,
  }
}
