import { TaskSummaryFieldStyles } from './TaskSummaryFieldStyles'
import React, { useState, useCallback, useEffect } from 'react'
import { TaskSummaryFieldProps } from './types/TaskSummaryFieldProps'
import { TextAreaInput } from 'components/input/TextAreaInput'
import { isInvalidTaskSummary } from 'utilities/is/isInvalidTaskSummary'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { debounce } from 'lodash'

export const TaskSummaryField = ({
  submit,
  initialSummary,
  setWorking,
  canWrite,
}: TaskSummaryFieldProps): JSX.Element => {
  const [initalSummaryState, setIntialSummaryState] = useState(initialSummary)
  const [summaryState, setSummaryState] = useState(initialSummary)
  const [errorState, setErrorState] = useState<string | null>(null)

  const debouncedSetWorking = useCallback(
    debounce(setWorking, 10000, { leading: true, maxWait: 50 }),
    [setWorking]
  )

  if (initalSummaryState !== initialSummary) {
    setIntialSummaryState(initialSummary)
    setSummaryState(initialSummary)
  }

  useEffect(() => {
    return () => {
      debouncedSetWorking.cancel()
    }
  }, [debouncedSetWorking])

  return (
    <div className={'TaskSummaryField ' + TaskSummaryFieldStyles}>
      <TextAreaInput
        value={summaryState}
        disabled={!canWrite}
        onChange={(e) => {
          setSummaryState(e.target.value)
          debouncedSetWorking()
        }}
        className={errorState ? 'hasError' : ''}
        onFocus={() => {
          setErrorState(null)
          setWorking()
        }}
        onBlur={() => {
          const error = isInvalidTaskSummary(summaryState)

          if (error) {
            setErrorState(error)
          } else {
            if (summaryState && summaryState !== initialSummary) {
              submit(summaryState)
            } else {
              setWorking(false)
            }
          }
        }}
      />

      <ErrorMessage style={{ height: !errorState ? '0px' : '20px' }}>
        {errorState}
      </ErrorMessage>
    </div>
  )
}
