// import { Dispatch, Action } from 'redux'

import {
  // TeamSettingsMainConnectedProps,
  TeamSettingsMainMapDispatchReturnType,
} from './types'

export const TeamSettingsMainMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TeamSettingsMainConnectedProps,
): TeamSettingsMainMapDispatchReturnType => {
  return {}
}
