import { StateReducers } from 'state/reducers/types/StateReducers'
import { getLabel } from 'state/getters/getLabel'
import { LabelIconSpanConnectedProps } from './types/LabelIconSpanConnectedProps'
import { LabelIconSpanMapStateReturnType } from './types/LabelIconSpanMapStateReturnType'

export const LabelIconSpanMapState = (
  state: StateReducers,
  props: LabelIconSpanConnectedProps
): LabelIconSpanMapStateReturnType => {
  const label = getLabel({ state, labelId: props.labelId })
  const icon = label?.icon

  return {
    icon,
  }
}
