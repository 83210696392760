import { take, put, select } from 'redux-saga/effects'
import { TaskHistoryItem } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentUserExtension } from 'state/getters/getCurrentUserExtension'
import { LOCATION_CHANGE } from 'actions'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'
import { createPatchUserExtensionRequestDelayAction } from 'actionCreators/patch/PatchUserExtension/createPatchUserExtensionRequestDelayAction'
import { MAX_VIEW_HISTORY_COUNT } from '../../constants/environmentConstants'

export const setTaskHistoryF = function*() {
  while (1) {
    const locationChangeAction: LocationChangeAction = yield take([
      LOCATION_CHANGE,
    ])
    const state: StateReducers = yield select(selectState)
    const isRoutes = locationChangeAction.values.isRoutes
    const isTaskMainRoute = isRoutes && isRoutes.isTaskMainRoute
    const userExtension = getCurrentUserExtension({ state })
    const taskHistoryItems =
      (userExtension && userExtension.taskHistoryItems) || []
    const taskId = getCurrentTaskId({ state })
    const task = taskId && getCurrentTask({ state, taskId })
    const taskSummary = task && task.summary
    const teamId = getCurrentTeamId({ state })
    const userId = state.currentUserId
    const firsTaskHistoryItem = taskHistoryItems[0]
    const isSame = firsTaskHistoryItem && firsTaskHistoryItem.taskId === taskId

    const filteredHistoryItems = taskHistoryItems.filter(
      (viewHistoryTaskId) => {
        return viewHistoryTaskId.taskId !== taskId
      }
    )

    if (isTaskMainRoute && !isSame && taskId && teamId && userId && task) {
      // need to make sure task exists
      const newHistoryItem: TaskHistoryItem = {
        createdSeconds: new Date().getTime() / 1000,
        taskId,
        taskSummary,
      }

      const slicedFilteredHistoryItems = filteredHistoryItems.slice(
        0,
        MAX_VIEW_HISTORY_COUNT
      )
      const newHistoryItems = [newHistoryItem].concat(
        slicedFilteredHistoryItems
      )

      if (!isSame) {
        yield put(
          createPatchUserExtensionRequestDelayAction({
            requestValues: {
              params: {
                teamId,
                userId,
              },
              item: {
                taskHistoryItems: newHistoryItems,
              },
            },
          })
        )
      }
    }
  }
}
