import { patchTeamsRequestF } from './patchTeamsRequestF'
import { patchInviteRequestF } from './patchInviteRequestF'
import { patchUserExtensionRequestDelayF } from './patchUserExtensionRequestDelayF'
import { patchCustomClaimSuccessF } from './patchCustomClaimSuccessF'
import { patchUnscopeUserRequestF } from './patchUnscopeUserRequestF'

// order doesnt matter
export const patchFlows = [
  patchCustomClaimSuccessF,
  patchTeamsRequestF,
  patchInviteRequestF,
  patchUserExtensionRequestDelayF,
  patchUnscopeUserRequestF,
]
