import { connect } from 'react-redux'
import { ModalButton } from './ModalButton'
import { ModalButtonMapState } from './ModalButtonMapState'
import { ModalButtonMapDispatch } from './ModalButtonMapDispatch'

import { ModalButtonMapStateReturnType } from './types/ModalButtonMapStateReturnType'
import { ModalButtonMapDispatchReturnType } from './types/ModalButtonMapDispatchReturnType'
import { ModalButtonConnectedProps } from './types/ModalButtonConnectedProps'


export const ModalButtonConnected = connect<
  ModalButtonMapStateReturnType,
  ModalButtonMapDispatchReturnType,
  ModalButtonConnectedProps>(ModalButtonMapState,
    ModalButtonMapDispatch)(ModalButton)
