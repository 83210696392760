import { StateReducers } from 'state/reducers/types/StateReducers'

import { NotificationsSettingsMainConnectedProps } from './types/NotificationsSettingsMainConnectedProps'
import { NotificationsSettingsMainMapStateReturnType } from './types/NotificationsSettingsMainMapStateReturnType'

export const NotificationsSettingsMainMapState = (
  _state: StateReducers,
  _props: NotificationsSettingsMainConnectedProps
): NotificationsSettingsMainMapStateReturnType => {
  return {}
}
