import { TaskMainDropdownStyles } from './TaskMainDropdownStyles'
import React from 'react'
import { TaskMainDropdownProps } from './types/TaskMainDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { TaskActionListConnected } from 'components/list/TaskActionList'
import { EllipsisHorizontalIcon } from 'components/icon/EllipsisHorizontalIcon/EllipsisHorizontalIcon'
import { EllipsisVertialIcon } from 'components/icon/EllipsisVertialIcon/EllipsisVertialIcon'

export const TaskMainDropdown = ({
  showTaskMainDropdown,
  taskId,
  useHorizintalIcon,
  additionalOnClick,
}: TaskMainDropdownProps): JSX.Element => {
  return (
    <div className={`TaskMainDropdown _${taskId} ` + TaskMainDropdownStyles}>
      <DropdownButtonConnected
        dropdownType="taskDetailDropdown"
        watchSelector={`.TaskMainDropdown._${taskId}`}
        id={taskId}
        title="More options"
        additionalOnClick={additionalOnClick}
      >
        {useHorizintalIcon && <EllipsisHorizontalIcon />}
        {!useHorizintalIcon && <EllipsisVertialIcon />}
      </DropdownButtonConnected>

      {showTaskMainDropdown && (
        <DropdownConnected>
          <TaskActionListConnected
            showMessageButton={true}
            showDeleteButton={true}
            showCloneButton={true}
            taskId={taskId}
          />
        </DropdownConnected>
      )}
    </div>
  )
}
