// import { StateReducers } from 'state/reducers/types/StateReducers'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsRoutesFromState } from 'state/getters/getIsRoutesFromState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCanRead } from 'state/getters/getCanRead'

import {
  // MainWatcherConnectedProps,
  MainWatcherMapStateReturnType,
} from './types'

export const MainWatcherMapState = (
  state: StateReducers
  // props: MainWatcherConnectedProps,
): MainWatcherMapStateReturnType => {
  const isRoutes = getIsRoutesFromState({ state })
  const teamId = getCurrentTeamId({ state })
  const userId = state.currentUserId || undefined
  const canRead = getCanRead({ state })
  const watchInvites =
    isRoutes?.isMembersRoute ||
    isRoutes?.isInviteDetailRoute ||
    isRoutes?.isAddNewMemberRoute

  return {
    teamId,
    userId,
    canRead,
    watchInvites,
  }
}
