import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getTaskOriginalPath } from 'firebaseFunctions/utilities/get/getTaskOriginalPath'
import { getTaskThumbPath } from 'firebaseFunctions/utilities/get/getTaskThumbPath'

import { TaskFileCardConnectedProps } from './types/TaskFileCardConnectedProps'
import { TaskFileCardMapStateReturnType } from './types/TaskFileCardMapStateReturnType'


export const TaskFileCardMapState = (
  state: StateReducers,
  props: TaskFileCardConnectedProps
): TaskFileCardMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const teamId = getCurrentTeamId({ state })
  const { fileName } = props

  const originalPath =
    fileName && teamId && taskId
      ? getTaskOriginalPath({
          teamId,
          taskId,
          fileName,
        })
      : undefined

  const thumbPath =
    fileName && teamId && taskId
      ? getTaskThumbPath({
          teamId,
          taskId,
          fileName,
        })
      : undefined

  return {
    thumbPath,
    originalPath,
  }
}
