import { TEAMS_COLLECTION, TASKS_COLLECTION } from '../../constants'

interface Props {
  teamId: string
  taskId: string
}

export const getTaskStoragePath = ({ teamId, taskId }: Props): string => {
  return `${TEAMS_COLLECTION}/${teamId}/${TASKS_COLLECTION}/${taskId}/`
}
