import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getUserRole } from 'state/getters/getUserRole'
import { getTaggedUserIds } from 'state/getters/getTaggedUserIds'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { createId } from 'firebaseFunctions/utilities/createId'
import { storageSdk } from 'sdks'
import { Uploads } from 'types/Uploads'
import { newMessageSaga } from '../../sagas/newMessageSaga'
import { uploadSuccessNewMessageSaga } from '../../sagas/uploadSuccessNewMessageSaga'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_NEW_MESSAGE, SUBMIT_NEW_MESSAGE_DONE } from 'actions'
import { getMessageFileOriginalPath } from 'firebaseFunctions/utilities/get/getMessageFileOriginalPath'
import { SubmitEditorAction } from 'actionCreators/submit/SubmitEditor'
import { createUploadRequestAction } from 'actionCreators/Upload/createUploadRequestAction'

export const submitNewMessageF = function*() {
  while (1) {
    const action: SubmitEditorAction = yield take([SUBMIT_NEW_MESSAGE])
    const { markdown, replyToMessageId } = action.values
    const state: StateReducers = yield select(selectState)
    const task = getCurrentTask({ state })
    const query = getCurrentQuery({ state })
    const newMessagesUploadFiles = state.uploadFiles?.newMessages
    const taskId = query && query.taskId
    const teamId = query && query.teamId
    const userRole = getUserRole({ state })
    const userId = state.currentUserId
    const userDisplayName = userRole?.displayName
    const userPhotoUrl = userRole?.photoURL
    const messageId = createId()
    const taggedUserIds = taskId ? getTaggedUserIds({ state, taskId }) : []
    const taskSummary = task?.summary
    const replyToMessage = replyToMessageId
      ? state.messages?.[replyToMessageId]
      : undefined

    const repliedToMessageId = replyToMessageId
    const repliedToUserId = replyToMessage?.data.userId
    const repliedToDescription = replyToMessage?.data.description

    if (
      taskId &&
      teamId &&
      userDisplayName &&
      userId &&
      taggedUserIds &&
      userDisplayName &&
      taskSummary
    ) {
      if (newMessagesUploadFiles && newMessagesUploadFiles.length > 0) {
        const uploadsMutable: Uploads = {}

        newMessagesUploadFiles.forEach((file) => {
          const uploadId = createId()
          const ref = storageSdk().ref()
          const fileName = file.name
          const originalPath = getMessageFileOriginalPath({
            teamId,
            taskId,
            fileName,
            messageId,
          })
          const uploadRef = ref.child(originalPath)
          const uploadMeta: firebase.storage.UploadMetadata = {}

          uploadsMutable[uploadId] = {
            data: file,
            fileName,
            ref: uploadRef,
            meta: uploadMeta,
            status: 'uploading',
          }
        })

        yield put(
          createUploadRequestAction({
            requestValues: uploadsMutable,
          })
        )

        // spawn to prevent blocking new messages
        yield spawn(uploadSuccessNewMessageSaga, {
          uploadIds: Object.keys(uploadsMutable),
          taskId,
          teamId,
          taskSummary,
          messageId,
          userId,
          taggedUserIds,
          markdown,
          userDisplayName,
          userPhotoUrl,
          repliedToMessageId,
          repliedToUserId,
          repliedToDescription,
        })
      } else if (markdown) {
        yield spawn(newMessageSaga, {
          taskId,
          teamId,
          messageId,
          userId,
          taggedUserIds,
          markdown,
          userDisplayName,
          userPhotoUrl,
          taskSummary,
          repliedToMessageId,
          repliedToUserId,
          repliedToDescription,
        })
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewMessageF',
        })
      )
    }

    yield put({
      type: SUBMIT_NEW_MESSAGE_DONE,
    })
  }
}
