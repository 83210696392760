import { StateReducers } from '../reducers/types/StateReducers'
import { escapeRegExp } from 'utilities/escapeRegExp'
import { TaskFilters } from 'firebaseFunctions/types/TaskFilters'

interface Props {
  state: StateReducers
}

interface Return {
  taskFilters: TaskFilters
  isActive?: boolean
}

export const getTaskFiltersFromState = ({ state }: Props): Return => {
  const taskFilters = state.sessionStorage?.taskFilters
  const summary = taskFilters?.summary && escapeRegExp(taskFilters.summary)
  const tagIds = taskFilters?.tagIds || null
  const taskStatuses = taskFilters?.taskStatuses || null
  const isActive = !!(tagIds?.length || summary || taskStatuses?.length)

  return {
    taskFilters: {
      tagIds,
      summary,
      taskStatuses,
    },
    isActive,
  }
}
