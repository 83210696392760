import { connect } from 'react-redux'
import { TaskTagDropdown } from './TaskTagDropdown'
import { TaskTagDropdownMapState } from './TaskTagDropdownMapState'
import { TaskTagDropdownMapDispatch } from './TaskTagDropdownMapDispatch'
import { TaskTagDropdownMapStateReturnType } from './types/TaskTagDropdownMapStateReturnType'
import { TaskTagDropdownMapDispatchReturnType } from './types/TaskTagDropdownMapDispatchReturnType'
import { TaskTagDropdownConnectedProps } from './types/TaskTagDropdownConnectedProps'

export const TaskTagDropdownConnected = connect<
  TaskTagDropdownMapStateReturnType,
  TaskTagDropdownMapDispatchReturnType,
  TaskTagDropdownConnectedProps
>(
  TaskTagDropdownMapState,
  TaskTagDropdownMapDispatch
)(TaskTagDropdown)
