import { css } from 'emotion'
import {
  SUCCESS_COLOR,
  BORDER_RADIUS,
  DANGER_COLOR,
} from '../../../constants/styleConstants'

export const ToastListWatcherStyles = css`
  position: fixed;
  bottom: 16px;
  z-index: 1000;
  margin: 0px 0px 16px 32px;
  border-radius: ${BORDER_RADIUS} 0 ${BORDER_RADIUS} 0;
  background-color: ${SUCCESS_COLOR};
  color: white;
  padding: 8px 16px;
  max-width: 200px;
  transition: left 0.1s ease-in-out;

  &.error {
    background-color: ${DANGER_COLOR};
  }
`
