import { GET_TEAM_LOGO_URL_REQUEST } from 'actions'
import { GetTeamLogoUrlRequestAction } from './types/GetTeamLogoUrlRequestAction'
import { GetTeamLogoUrlRequestActionValues } from './types/GetTeamLogoUrlRequestActionValues'

interface Props {
  requestValues: GetTeamLogoUrlRequestActionValues,
}

export const createGetTeamLogoUrlRequestAction = ({ requestValues }: Props): GetTeamLogoUrlRequestAction => {
  return {
    type: GET_TEAM_LOGO_URL_REQUEST,
    requestValues,
  }
}
