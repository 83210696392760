import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { AppConnected } from 'components/App'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { createStore } from 'state/createStore'
import { ReduxRouterC } from 'components/ReduxRouter/ReduxRouterC'
import { Provider } from 'react-redux'
import {
  IS_DEVELOPMENT,
  IS_LOCAL_HOST,
  IS_BETA,
  IS_PRODUCTION,
} from 'constants/environmentConstants'
import { flows } from 'flows/flows'
import { firebaseMessaging } from 'sdks'
import { PushNotification } from 'firebaseFunctions/types'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createWindowResizeAction } from 'actionCreators/createWindowResizeAction'
import { createNewPushNotificationAction } from 'actionCreators/createNewPushNotificationAction'
// import { createWindowClickAction } from 'actionCreators/createWindowClickAction'

const rootEl = document.getElementById('root') as HTMLElement
const history = createBrowserHistory()

const store = createStore({
  flows,
  history,
  enableLogger: IS_LOCAL_HOST || IS_DEVELOPMENT,
})

const dispatchWindowResize = () => {
  var width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  var height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )

  store.dispatch(
    createWindowResizeAction({
      width,
      height,
    })
  )
}

window.onresize = dispatchWindowResize
window.storeDispatch = store.dispatch

dispatchWindowResize()

interface Payload {
  data: PushNotification
  from: string
  priority: string
}

firebaseMessaging?.onMessage((payload: Payload) => {
  store.dispatch(
    createNewPushNotificationAction({
      pushNotification: payload.data,
    })
  )
})

const dispatchCloseModalWithEsc = (event?: KeyboardEvent) => {
  if (event && event.keyCode === 27) {
    store.dispatch(
      createToggleAction({
        description: 'index',
        toggles: {
          showModal: false,
        },
      })
    )
  }
}

window.onkeypress = dispatchCloseModalWithEsc

const providerApp = (
  <Provider store={store}>
    <ReduxRouterC history={history}>
      <AppConnected />
    </ReduxRouterC>
  </Provider>
)

ReactDOM.render(providerApp, rootEl)

if (IS_BETA || IS_PRODUCTION) {
  serviceWorker.register()
} else {
  serviceWorker.unregister()
}
