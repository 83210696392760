import { connect } from 'react-redux'
import { TeamWatcher } from './TeamWatcher'
import { TeamWatcherMapState } from './TeamWatcherMapState'
import { TeamWatcherMapDispatch } from './TeamWatcherMapDispatch'
import { TeamWatcherMapStateReturnType } from './types/TeamWatcherMapStateReturnType'
import { TeamWatcherMapDispatchReturnType } from './types/TeamWatcherMapDispatchReturnType'
import { TeamWatcherConnectedProps } from './types/TeamWatcherConnectedProps'

export const TeamWatcherConnected = connect<
  TeamWatcherMapStateReturnType,
  TeamWatcherMapDispatchReturnType,
  TeamWatcherConnectedProps
>(
  TeamWatcherMapState,
  TeamWatcherMapDispatch
)(TeamWatcher)
