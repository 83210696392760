import { connect } from 'react-redux'
import { HeaderOneText } from './HeaderOneText'
import { HeaderOneTextMapState } from './HeaderOneTextMapState'
import { HeaderOneTextMapDispatch } from './HeaderOneTextMapDispatch'
import { HeaderOneTextMapStateReturnType } from './types/HeaderOneTextMapStateReturnType'
import { HeaderOneTextMapDispatchReturnType } from './types/HeaderOneTextMapDispatchReturnType'
import { HeaderOneTextConnectedProps } from './types/HeaderOneTextConnectedProps'

export const HeaderOneTextConnected = connect<
  HeaderOneTextMapStateReturnType,
  HeaderOneTextMapDispatchReturnType,
  HeaderOneTextConnectedProps
>(
  HeaderOneTextMapState,
  HeaderOneTextMapDispatch
)(HeaderOneText)
