import { StateReducers } from 'state/reducers/types/StateReducers'

import { UploadedFileCardConnectedProps } from './types/UploadedFileCardConnectedProps'
import { UploadedFileCardMapStateReturnType } from './types/UploadedFileCardMapStateReturnType'

import { FirebaseTimestamp } from 'sdks'

export const UploadedFileCardMapState = (
  state: StateReducers,
  props: UploadedFileCardConnectedProps
): UploadedFileCardMapStateReturnType => {
  const uploadedFile = state.uploadedFiles?.[props.uploadedFileId].data
  const created = uploadedFile?.created
  const path = uploadedFile?.path
  const bytes = uploadedFile?.bytes

  const createdSeconds =
    created instanceof FirebaseTimestamp ? created.seconds : undefined

  return {
    createdSeconds,
    path,
    bytes,
  }
}
