import { forEach, pickBy } from 'lodash'
import { getTaskFiltersFromState } from './getTaskFiltersFromState'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { TasksReducer } from 'state/reducers/types/TasksReducer'

interface Props {
  state: StateReducers
}

export const getFilteredTasks = ({
  state,
}: Props): TasksReducer | undefined => {
  const tasks = state.tasks || undefined

  if (!tasks) {
    return
  }

  const { taskFilters, isActive } = getTaskFiltersFromState({ state })
  const { tagIds, summary: summaryFilter, taskStatuses } = taskFilters
  const summaryFilterRegexListMutable: RegExp[] | null = summaryFilter
    ? []
    : null

  summaryFilter
    ?.trim()
    .split(' ')
    .map((summaryPart) => {
      if (summaryPart.length) {
        summaryFilterRegexListMutable?.push(new RegExp(summaryPart, 'i'))
      }
    })

  if (!isActive) {
    return tasks
  }

  const filteredTasks: TasksReducer = pickBy(tasks, (task) => {
    const { summary, tags, status = '' } = task.data

    let match = true

    if (summary && summaryFilterRegexListMutable) {
      const noneMatch = summaryFilterRegexListMutable.every(
        (summaryFilterRegex) => {
          return !summary.match(summaryFilterRegex)
        }
      )

      if (noneMatch) {
        match = match && false
      }
    }

    forEach(tagIds, (id) => {
      if (!tags || !tags[id]) {
        match = match && false
      }
    })

    match =
      match &&
      (taskStatuses && taskStatuses.length
        ? taskStatuses.indexOf(status) > -1
        : true)

    return match
  })

  return filteredTasks
}
