import { StateReducers } from '../reducers/types/StateReducers'
import { IsRoutes } from 'routes'

interface Props {
  state: StateReducers
}

export const getIsRoutesFromState = ({
  state,
}: Props): IsRoutes | undefined => {
  const currentLocation = state.currentLocation || undefined

  return currentLocation?.isRoutes
}
