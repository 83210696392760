import { Dispatch, Action } from 'redux'
import { EnableNotificationMessageMapDispatchReturnType } from './types/EnableNotificationMessageMapDispatchReturnType'
import { createSetCookieStorageAction } from '../../../actionCreators/cookieStorage'
import { SUBMIT_UPDATE_PUSH_SETTINGS } from '../../../actions'
import { createSubmitAction } from '../../../actionCreators/submit/Submit/createSubmitAction'
import { createSetWindowMetaAction } from '../../../actionCreators/createSetWindowMetaAction'

export const EnableNotificationMessageMapDispatch = (
  dispatch: Dispatch<Action>
): EnableNotificationMessageMapDispatchReturnType => {
  return {
    dontAskAgain: () => {
      dispatch(
        createSetCookieStorageAction({
          description: 'EnableNotificationMessageMapDispatch',
          values: {
            dontAskToEnableNotifications: true,
          },
        })
      )
    },

    setPushNotification: (firebaseMessagingToken) => {
      dispatch(
        createSetWindowMetaAction({
          values: { firebaseMessagingToken },
          description: 'EnableNotificationMessageMapDispatch',
        })
      )

      dispatch(
        createSubmitAction({
          type: SUBMIT_UPDATE_PUSH_SETTINGS,
          values: {
            token: firebaseMessagingToken,
            pushNotificationSettings: {
              messagingEnabled: true,
              activityEnabled: true,
            },
          },
          description: 'EnableNotificationMessageMapDispatch',
        })
      )
    },
  }
}
