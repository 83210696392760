import { css } from 'emotion'
import { PRIMARY_COLOR, PRIMARY_COLOR_DARK } from 'constants/styleConstants'

export const TeamSettingsMainStyles = css`
  .IconAndDiv {
    position: relative;

    > div {
      position: absolute;
      left: 2em;
      text-transform: capitalize;
    }
  }

  .upgradeToPro {
    color: ${PRIMARY_COLOR};

    &:hover {
      color: ${PRIMARY_COLOR_DARK};
    }
  }

  .settings {
    > li {
      margin-top: 32px;

      &:first-child {
        margin-top: 0px;
      }

      > a {
        display: block;
      }

      > header {
        display: flex;
        align-items: center;
        margin: 0px 0 16px 0px;

        > a {
          margin-left: 10px;

          .fas {
            font-size: 0.8em;
          }
        }
      }
    }
  }
`
