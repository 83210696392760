// import { Dispatch, Action } from 'redux'

import {
  // MessageCardConnectedProps,
  MessageCardMapDispatchReturnType,
} from './types'

export const MessageCardMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessageCardConnectedProps,
): MessageCardMapDispatchReturnType => {
  return {
  }
}
