import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  // RecaptchaConnectedProps,
  RecaptchaMapDispatchReturnType,
} from './types'

export const RecaptchaMapDispatch = (
  dispatch: Dispatch<Action>
  // props: RecaptchaConnectedProps,
): RecaptchaMapDispatchReturnType => {
  return {
    onRecaptcha: () => {
      const toggleAction = createToggleAction({
        description: 'RecaptchaMapDispatch',
        toggles: {
          showRecaptcha: false,
        },
      })

      dispatch(toggleAction)
    },
  }
}
