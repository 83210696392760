import { Team } from '../../../types'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import { getTeamRef } from '../get/getTeamRef'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  team: Team
}

export const updateTeam = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  team,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getTeamRef({
    firestore,
    teamId,
  }).update(team) as any
}
