import { WINDOW_RESIZE } from 'actions'
import { WindowResizeAction } from './types/WindowResizeAction'
import { WindowResizeActionValues } from './types/WindowResizeActionValues'

export const createWindowResizeAction = (values: WindowResizeActionValues): WindowResizeAction => {
  return {
    type: WINDOW_RESIZE,
    values,
  }
}
