import { STRIPE_SUBSCRIPTION_URL } from 'constants/urlConstants'
import { PostStripeSubscriptionsRequestPayload } from 'firebaseFunctions/stripeSubscriptions/types/PostStripeSubscriptionsRequestPayload'
import { ApiHeaders } from 'firebaseFunctions/types/ApiHeaders'
import Axios, { AxiosPromise } from 'axios'
import { PostStripeSubscriptionsSuccessPayload } from 'firebaseFunctions/stripeSubscriptions/types/PostStripeSubscriptionsSuccessPayload'

interface Props {
  values: PostStripeSubscriptionsRequestPayload
  apiHeaders: ApiHeaders
}

export const postStripeSubscriptionsApi = ({
  values,
  apiHeaders,
}: Props): AxiosPromise<PostStripeSubscriptionsSuccessPayload> => {
  return Axios.post(STRIPE_SUBSCRIPTION_URL, values, {
    headers: apiHeaders,
  })
}
