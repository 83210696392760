// import { Dispatch, Action } from 'redux'

import {
  // MinimalLayoutConnectedProps,
  MinimalLayoutMapDispatchReturnType,
} from './types'

export const MinimalLayoutMapDispatch = (): // dispatch: Dispatch<Action>,
// props: MinimalLayoutConnectedProps,
MinimalLayoutMapDispatchReturnType => {
  return {}
}
