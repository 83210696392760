import { createUnexpectedErrorAction } from './createUnexpectedErrorAction'
import { ErrorAction } from './types/ErrorAction'
import { createFirebaseErrorAction } from './createFirebaseErrorAction'
import { FirebaseErrorAction } from './types/FirebaseErrorAction'
import { UnexpectedAction } from './types/UnexpectedAction'
import { CreateErrorActionProps } from './types/CreateErrorActionProps'
import { ERROR } from 'actions'

export const createErrorAction = ({
  error,
  description,
}: CreateErrorActionProps):
  | ErrorAction
  | FirebaseErrorAction
  | UnexpectedAction => {
  if (error.code) {
    return createFirebaseErrorAction({
      error: error as any,
      description,
    })
  }

  if (error) {
    return {
      type: ERROR,
      error,
      description,
    }
  }

  return createUnexpectedErrorAction({
    description,
  })
}
