import { isInvalidEmail } from 'firebaseFunctions/utilities/is/isInvalidEmail'
import { parseEmailsString } from 'utilities/parseEmailsString'

interface Props {
  value?: string
  maxEmailCount?: number
  blacklist?: string[]
}

// TODO: write tests
export const isInvalidEmails = ({
  value,
  maxEmailCount = 0,
  blacklist = [],
}: Props): string | false => {
  if (!value) {
    return 'Please enter a email address.'
  }

  const emails: string[] = parseEmailsString(value)

  let error: false | string = false

  if (emails.length > maxEmailCount) {
    return `The max number of email adddress is ${maxEmailCount}`
  }

  emails.forEach((email) => {
    const trimmed = email.trim()

    if (blacklist.indexOf(trimmed) > -1) {
      error = `"${trimmed}" is already in use.`
    }

    const isInvalid = isInvalidEmail(trimmed)

    error = isInvalid ? `"${trimmed}" is not a valid email.` : error
  })

  return error
}
