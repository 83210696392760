import { SelectInputStyles } from './SelectInputStyles'
import React from 'react'
import Select from 'react-select'
import { SelectInputProps } from './types/SelectInputProps'
import { SelectOption } from 'types/SelectOption'
import {
  NEUTRAL_HIGH_LIGHT_COLOR,
  PRIMARY_COLOR,
} from 'constants/styleConstants'

export const SelectInput = ({
  isSearchable = true,
  className = '',
  formatOptionLabel,
  ...props
}: SelectInputProps): JSX.Element => {
  const extraClassName = className ? ` ${className}` : ''

  const defaultFormatOptionLabel = (
    option: SelectOption | any,
    _labelMeta: any
  ) => {
    return <div>{option.label || option.value}</div>
  }

  return (
    <Select
      {...props}
      classNamePrefix="SelectInput"
      className={'SelectInput ' + SelectInputStyles + extraClassName}
      formatOptionLabel={formatOptionLabel || defaultFormatOptionLabel}
      theme={(theme: any) => ({
        // https://react-select.com/styles
        ...theme,
        colors: {
          ...theme.colors,
          primary25: NEUTRAL_HIGH_LIGHT_COLOR, // hightlight color
          primary: PRIMARY_COLOR,
        },
      })}
    />
  )
}
