import { take, put, select } from 'redux-saga/effects'
import { auth, UserCredential } from 'sdks'
import { FirebaseError } from 'firebase'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { POST_LOGIN_REQUEST, POST_LOGIN_FAILURE } from 'actions'
import {
  TOO_MANY_REQUESTS,
  WRONG_PASSWORD,
  USER_NOT_FOUND,
} from 'firebaseFunctions/constants/failureCodesConstants'

import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSignInSuccessAction } from 'actionCreators/auth'
import { createSetAlertMessageAction } from 'actionCreators/set/SetAlertMessage/createSetAlertMessageAction'
import { PostLoginRequestAction } from 'actionCreators/post/PostLogin/types/PostLoginRequestAction'
import { createPostLoginSuccessAction } from 'actionCreators/post/PostLogin/createPostLoginSuccessAction'
import { createPostLoginFailureAction } from 'actionCreators/post/PostLogin/createPostLoginFailureAction'

export const postLoginRequestF = function*() {
  while (1) {
    const postLoginRequestAction: PostLoginRequestAction = yield take([
      POST_LOGIN_REQUEST,
    ])

    const { requestValues } = postLoginRequestAction
    const { email, password } = requestValues
    const state: StateReducers = yield select(selectState)
    const { currentUserId } = state

    if (email && password) {
      try {
        const userCredential: UserCredential = yield auth.signInWithEmailAndPassword(
          email,
          password
        )

        if (userCredential.user) {
          const { uid, displayName, emailVerified } = userCredential.user

          const users = {
            [uid]: {
              email,
              displayName: displayName || undefined,
            },
          }

          yield put(
            createPostLoginSuccessAction({
              requestValues,
              successValues: {
                items: users,
              },
            })
          )

          if (currentUserId === uid) {
            // Because FirebaseAuthStateChangedAction will not fire if its the same user.
            yield put(
              createSignInSuccessAction({
                userId: currentUserId,
                user: {
                  email,
                  emailVerified,
                },
              })
            )
          }
        }
      } catch (error) {
        if (error.code === TOO_MANY_REQUESTS) {
          yield put(
            createToggleAction({
              description: 'postLoginRequestF',
              toggles: {
                showRecaptcha: true,
              },
            })
          )
        } else if (
          error.code === WRONG_PASSWORD ||
          error.code === USER_NOT_FOUND
        ) {
          yield put(
            createSetAlertMessageAction({
              description: 'postLoginRequestF',
              values: {
                location: 'form',
                message: 'The email or password is incorrect.',
                actionType: POST_LOGIN_FAILURE,
                type: 'error',
              },
            })
          )
        } else {
          yield put(
            createFirebaseErrorAction({
              error: error as FirebaseError,
              description: 'postLoginRequestF',
            })
          )
        }

        yield put(
          createPostLoginFailureAction({
            requestValues,
          })
        )
      }
    }
  }
}
