// import { DeleteAndConfirmWidgetStyles } from './DeleteAndConfirmWidgetStyles'
import React, { useState } from 'react'
import { DeleteAndConfirmWidgetProps } from './types/DeleteAndConfirmWidgetProps'
import { ActionButton } from 'components/button/ActionButton'
import { TrashIcon } from 'components/icon/TrashIcon/TrashIcon'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'

export const DeleteAndConfirmWidget = ({
  onDeleteClick,
  disabled,
  label = 'Delete',
  className = '',
  confirmButtonClassName = '',
}: DeleteAndConfirmWidgetProps): JSX.Element => {
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <>
      <ActionButton
        title={label}
        disabled={disabled}
        className={className}
        onClick={(e) => {
          setShowConfirm(!showConfirm)
          e.preventDefault()
        }}
      >
        <IconAndDiv icon={<TrashIcon />}>
          {showConfirm ? 'Cancel' : label}
        </IconAndDiv>
      </ActionButton>

      {showConfirm && (
        <ActionButton
          disabled={disabled}
          title="Confirm Delete"
          className={`danger confirmDelete ${confirmButtonClassName}`}
          onClick={onDeleteClick}
        >
          <IconAndDiv icon={<TrashIcon />}>Confirm</IconAndDiv>
        </ActionButton>
      )}
    </>
  )
}
