import { StateReducers } from 'state/reducers/types/StateReducers'
import { TeamWatcherConnectedProps } from './types/TeamWatcherConnectedProps'
import { TeamWatcherMapStateReturnType } from './types/TeamWatcherMapStateReturnType'

export const TeamWatcherMapState = (
  _state: StateReducers,
  _props: TeamWatcherConnectedProps
): TeamWatcherMapStateReturnType => {
  return {}
}
