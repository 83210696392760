import { connect } from 'react-redux'
import { ActionButton } from './ActionButton'
import { ActionButtonMapState } from './ActionButtonMapState'
import { ActionButtonMapDispatch } from './ActionButtonMapDispatch'

import { ActionButtonMapStateReturnType } from './types/ActionButtonMapStateReturnType'
import { ActionButtonMapDispatchReturnType } from './types/ActionButtonMapDispatchReturnType'
import { ActionButtonConnectedProps } from './types/ActionButtonConnectedProps'


export const ActionButtonConnected = connect<
  ActionButtonMapStateReturnType,
  ActionButtonMapDispatchReturnType,
  ActionButtonConnectedProps>(ActionButtonMapState,
    ActionButtonMapDispatch)(ActionButton)
