import { ShowMoreButtonStyles } from './ShowMoreButtonStyles'
import React from 'react'
import { ShowMoreButtonProps } from './types/ShowMoreButtonProps'
import { Button } from 'components/button/Button'
import { Spinner } from 'components/widget/Spinner'

export const ShowMoreButton = ({
  showSpinner,
  ...props
}: ShowMoreButtonProps): JSX.Element => {
  return (
    <Button
      className={'ShowMoreButton ' + ShowMoreButtonStyles}
      disabled={showSpinner}
      {...props}
    >
      {showSpinner && <Spinner />}

      {!showSpinner && <span>show more</span>}
    </Button>
  )
}
