import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'

import { PATCH_CUSTOM_CLAIM_REQUEST } from 'actions'

export const showCustomClaimSpinnerF = function*() {
  while (1) {
    yield take([PATCH_CUSTOM_CLAIM_REQUEST])

    const toggleAction = createToggleAction({
      description: 'showCustomClaimSpinnerF',
      toggles: {
        showCustomClaimSpinner: true,
      },
    })

    yield put(toggleAction)
  }
}
