import { css } from 'emotion'
import { TEXT_COLOR, TEXT_COLOR_LIGHTER } from 'constants/styleConstants'

export const TaskFilterFormStyles = css`
  .TaskStatusButtonsField {
    margin-top: 4px;
  }

  .saveButton {
    margin-top: 8px;
    display: inline-block;
  }

  &.isSummaryFilterActive {
    input[name='summary'] {
      -webkit-animation: color-change 1s infinite;
      -moz-animation: color-change 1s infinite;
      -o-animation: color-change 1s infinite;
      -ms-animation: color-change 1s infinite;
      animation: color-change 1s infinite;

      &:focus {
        -webkit-animation: none;
        -moz-animation: none;
        -o-animation: none;
        -ms-animation: none;
        animation: none;
      }
    }

    @-webkit-keyframes color-change {
      0% {
        color: ${TEXT_COLOR};
      }
      50% {
        color: ${TEXT_COLOR_LIGHTER};
      }
    }
    @-moz-keyframes color-change {
      0% {
        color: ${TEXT_COLOR};
      }
      50% {
        color: ${TEXT_COLOR_LIGHTER};
      }
    }
    @-ms-keyframes color-change {
      0% {
        color: ${TEXT_COLOR};
      }
      50% {
        color: ${TEXT_COLOR_LIGHTER};
      }
    }
    @-o-keyframes color-change {
      0% {
        color: ${TEXT_COLOR};
      }
      50% {
        color: ${TEXT_COLOR_LIGHTER};
      }
    }
    @keyframes color-change {
      0% {
        color: ${TEXT_COLOR};
      }
      50% {
        color: ${TEXT_COLOR_LIGHTER};
      }
    }
  }

  > header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .TextInputField {
      flex-grow: 1;
    }

    > button {
      margin-left: 16px;
      font-size: 1.5em;
    }
  }

  .resetAndDefault {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
