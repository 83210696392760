import { storageSdk } from 'sdks'
import { getTaskStoragePath } from 'firebaseFunctions/utilities/get/getTaskStoragePath'

interface Props {
  teamId: string
  taskId: string
}

export const getTaskFiles = async ({
  teamId,
  taskId,
}: Props): Promise<string[]> => {
  const files: string[] = []
  const storagePath = getTaskStoragePath({ teamId, taskId })

  const ref = await storageSdk()
    .ref()
    .child(storagePath)
    .listAll()

  ref.items.forEach((itemRef) => {
    files.push(itemRef.name)
  })

  return files
}
