import { GET_INVITES_SUCCESS } from 'actions'

import { GetInvitesSuccessAction } from './types/GetInvitesSuccessAction'
import { GetInvitesSuccessActionValues } from './types/GetInvitesSuccessActionValues'
import { GetInvitesRequestActionValues } from './types/GetInvitesRequestActionValues'


interface Props {
  requestValues?: GetInvitesRequestActionValues
  successValues: GetInvitesSuccessActionValues
  description: string
}

export const createGetInvitesSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): GetInvitesSuccessAction => {
  return {
    type: GET_INVITES_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
