import { SET_SESSION_STORAGE } from 'actions'
import { SessionStorage } from 'types/SessionStorage'
import { SetSessionStorageAction } from './types/SetSessionStorageAction'

interface Props {
  sessionStorage: SessionStorage
  replace?: boolean
  description: string
}

export const createSetSessionStorageAction = ({
  sessionStorage,
  replace,
  description,
}: Props): SetSessionStorageAction => {
  return {
    description,
    type: SET_SESSION_STORAGE,
    sessionStorage,
    replace,
  }
}
