import { TeamListStyles } from './TeamListStyles'
import React from 'react'
import { TeamListProps } from './types/TeamListProps'
import { TeamCardConnected } from 'components/card/TeamCard'

export const TeamList = ({
  teamIds = [],
}: TeamListProps): JSX.Element => {
  return (
    <ul className={'TeamList ' + TeamListStyles}>
      {teamIds.map((teamId) => {
        return (
          <li key={teamId}>
            <TeamCardConnected teamId={teamId} />
          </li>
        )
      })}
    </ul>
  )
}
