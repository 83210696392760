import * as React from 'react'
import { TaskTagListConnected } from './TaskTagListConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_TASK_A_ID } from 'mockState'

export const TaskTagListExamples = () => {
  return (
    <div className="TaskTagListExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <TaskTagListConnected taskId={MOCK_TASK_A_ID} />
      </Provider>

      <h1 className="exampleHeader">isDetail=true</h1>

      <Provider store={createMockStore(mockState)}>
        <TaskTagListConnected taskId={MOCK_TASK_A_ID} isDetail={true} />
      </Provider>
    </div>
  )
}
