import { StripeInvoiceCardStyles } from './StripeInvoiceCardStyles'
import React from 'react'
import { StripeInvoiceCardProps } from './types/StripeInvoiceCardProps'
import moment from 'moment'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'

export const StripeInvoiceCard = ({
  stripeInvoice,
}: StripeInvoiceCardProps): JSX.Element => {
  const { createdSeconds, hostedInvoiceUrl } = stripeInvoice
  const date = createdSeconds
    ? moment.unix(createdSeconds).format('YYYY-MM-DD')
    : ''

  return (
    <div className={`StripeInvoiceCard ${StripeInvoiceCardStyles}`}>
      {hostedInvoiceUrl && (
        <a
          href={hostedInvoiceUrl}
          target="_blank"
          rel="noopener noreferrer"
          title="Download receipt"
          className={textLinkStyles}
        >
          {date}
        </a>
      )}

      {!hostedInvoiceUrl && <span>{date}</span>}
    </div>
  )
}
