import { TOGGLE } from 'actions'
import { TogglesR } from 'state/reducers/types/TogglesR'
import { ToggleAction } from './types/ToggleAction'

interface Props {
  toggles: TogglesR
  description: string
}

export const createToggleAction = ({
  toggles,
  description,
}: Props): ToggleAction => {
  return {
    type: TOGGLE,
    toggles,
    description,
  }
}
