import { Dispatch, Action } from 'redux'
import { StripeSubscriptionMainMapDispatchReturnType } from './types/StripeSubscriptionMainMapDispatchReturnType'
import { createErrorAction } from 'actionCreators/createErrorAction'

export const StripeSubscriptionMainMapDispatch = (
  dispatch: Dispatch<Action>
): StripeSubscriptionMainMapDispatchReturnType => {
  return {
    handleError: (error) => {
      dispatch(
        createErrorAction({
          error,
          description: 'StripeSubscriptionMainMapDispatch',
        })
      )
    },
  }
}
