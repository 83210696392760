// import { Dispatch, Action } from 'redux'

import {
  // DashboardNavConnectedProps,
  DashboardNavMapDispatchReturnType,
} from './types'

export const DashboardNavMapDispatch = (): // dispatch: Dispatch<Action>,
// props: DashboardNavConnectedProps,
DashboardNavMapDispatchReturnType => {
  return {}
}
