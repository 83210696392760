import { TaskSizeListStyles } from './TaskSizeListStyles'
import * as React from 'react'
import { TaskSizeListProps } from './types/TaskSizeListProps'

export const TaskSizeList = ({
  taskSizes,
}: TaskSizeListProps): JSX.Element => {
  return (
    <ol className={'TaskSizeList ' + TaskSizeListStyles}>
      {taskSizes && taskSizes.map((size, i) => {
        return (
          <li key={i}>{i + 1}. {size}</li>
        )
      })}
    </ol>
  )
}
