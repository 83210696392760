import { FirestoreClient } from '../../../types'
import { getUserInviteRef } from '../get/getUserInviteRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  email: string
  userInviteId: string
}

export const deleteUserInvite = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  email,
  userInviteId,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getUserInviteRef({
    firestore,
    email,
    userInviteId,
  }).delete() as any
}
