import { put } from 'redux-saga/effects'
import { patchTeamsApi } from 'apis/patch/patchTeamsApi'
import { PatchTeamsApiProps } from 'apis/patch/types/PatchTeamsApiProps'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { PatchTeamsSuccessPayload } from 'firebaseFunctions/teams/types'
import { AxiosResponse } from 'axios'
import { createPatchTeamsFfRequestAction } from 'actionCreators/patch/PatchTeamsFf/createPatchTeamsFfRequestAction'
import { createPatchTeamsFfSuccessAction } from 'actionCreators/patch/PatchTeamsFf/createPatchTeamsFfSuccessAction'
import { createPatchTeamsFfFailureAction } from 'actionCreators/patch/PatchTeamsFf/createPatchTeamsFfFailureAction'

interface Props extends PatchTeamsApiProps {}

export const patchTeamsFfSaga = function*({ values, apiHeaders }: Props) {
  yield put(
    createPatchTeamsFfRequestAction({
      requestValues: values,
    })
  )

  try {
    const results: AxiosResponse<PatchTeamsSuccessPayload> = yield patchTeamsApi(
      {
        apiHeaders,
        values: values,
      }
    )

    yield put(
      createPatchTeamsFfSuccessAction({
        requestValues: values,
        successValues: results.data,
      })
    )
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'patchTeamsFfSaga',
      })
    )

    yield put(
      createPatchTeamsFfFailureAction({
        requestValues: values,
      })
    )
  }
}
