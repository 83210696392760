import * as React from 'react'
import { MessageCardConnected } from './MessageCardConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_MESSAGE_A_ID } from 'mockState'

export const MessageCardExamples = () => {
  return (
    <div className="MessageCardExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <MessageCardConnected messageId={MOCK_MESSAGE_A_ID} />
      </Provider>
    </div>
  )
}
