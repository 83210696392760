import { css } from 'emotion'
import { PRIMARY_COLOR_DARK } from 'constants/styleConstants'

export const TaskActionListStyles = css`
  .ActionButton,
  .ButtonLink {
    padding: 8px 12px;
  }

  .WorkspaceStorageUploadField {
    &:hover {
      .ActionButton {
        background: ${PRIMARY_COLOR_DARK};
      }
    }
  }

  .confirmDelete {
    margin-left: 8px;
  }
`
