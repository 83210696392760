import { LOCATION_CHANGE } from 'actions'
import * as queryString from 'query-string'
import { getIsRoutes } from 'routes'
import { LocationChangeAction } from './types/LocationChangeAction'

import {
  QUERY_NAME_RETURN_PATH,
  QUERY_NAME_RETURN_QUERY,
  QUERY_NAME_TEAM_ID,
  QUERY_NAME_TASK_ID,
  QUERY_NAME_PLAN_ID,
  QUERY_NAME_USER_ID,
  QUERY_NAME_INVITE_ID,
  QUERY_NAME_TEAM_NAME,
  QUERY_NAME_SECRET,
  QUERY_NAME_EMAIL,
  QUERY_NAME_REFRESH_ID_TOKEN,
  QUERY_NAME_SUBSCRIPTION_ID,
} from 'constants/queryNameConstants'

interface Props {
  search?: string
  hash?: string
  pathname: string
}

// You probably want to use createHistoryAction and not this method
export const createLocationChangeAction = ({
  search,
  hash,
  pathname,
}: Props): LocationChangeAction => {
  const query = search ? queryString.parse(search) : {}
  const isRoutes = getIsRoutes({ pathname })
  const returnPath = query[QUERY_NAME_RETURN_PATH]
  const returnQuery = query[QUERY_NAME_RETURN_QUERY]
  const teamId = query[QUERY_NAME_TEAM_ID]
  const userId = query[QUERY_NAME_USER_ID]
  const taskId = query[QUERY_NAME_TASK_ID]
  const planId = query[QUERY_NAME_PLAN_ID]
  const subscriptionId = query[QUERY_NAME_SUBSCRIPTION_ID]
  const inviteId = query[QUERY_NAME_INVITE_ID]
  const teamName = query[QUERY_NAME_TEAM_NAME]
  const secret = query[QUERY_NAME_SECRET]
  const refreshIdToken = query[QUERY_NAME_REFRESH_ID_TOKEN]
  const email = query[QUERY_NAME_EMAIL]

  return {
    type: LOCATION_CHANGE,
    values: {
      query: {
        returnPath,
        returnQuery,
        teamId,
        taskId,
        planId,
        subscriptionId,
        userId,
        inviteId,
        teamName,
        refreshIdToken,
        email,
        secret,
      },
      queryString: search,
      hash,
      pathname,
      isRoutes,
    },
  }
}
