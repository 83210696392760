import { css } from 'emotion'

export const TeamListStyles = css`
  > li {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }
`
