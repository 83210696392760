import { DueDateDropdownStyles } from './DueDateDropdownStyles'
import React from 'react'
import { DueDateDropdownProps } from './types/DueDateDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import Calendar from 'react-calendar'
import { Button } from 'components/button/Button'
import moment from 'moment'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { createDueDateString } from 'utilities/create/createDueDateString'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'
import { CalendarIcon } from 'components/icon/CalendarIcon/CalendarIcon'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'

export const DueDateDropdown = ({
  showDueDateDropdown,
  taskId,
  label,
  seconds,
  setDueDate,
  isCompact,
  unsetDueDate,
  canWrite,
}: DueDateDropdownProps): JSX.Element => {
  const due =
    seconds &&
    createDueDateString({
      seconds,
      isCompact,
    })

  return (
    <div className={`DueDateDropdown _${taskId} ` + DueDateDropdownStyles}>
      {seconds && !isCompact && (
        <Button className="removeButton" onClick={unsetDueDate}>
          <TimesSolidIcon />
        </Button>
      )}

      <DropdownButtonConnected
        dropdownType="dueDate"
        title="Set a date"
        disabled={!canWrite}
        className={`${
          due || label ? textLinkStyles : ''
        } ${textSmallestStyles}`}
        id={taskId}
        watchSelector={`.DueDateDropdown._${taskId}`}
      >
        {due || label}

        {!due && !label && <CalendarIcon />}
      </DropdownButtonConnected>

      {showDueDateDropdown && (
        <DropdownConnected autoAdjust={true}>
          <Calendar
            locale="en-US"
            onChange={setDueDate}
            value={seconds ? moment.unix(seconds).toDate() : undefined}
          />
        </DropdownConnected>
      )}
    </div>
  )
}
