import { PATCH_INVITES_REQUEST } from 'actions'
import { PatchInvitesRequestAction } from './types/PatchInvitesRequestAction'
import { PatchInvitesRequestActionValues } from './types/PatchInvitesRequestActionValues'

interface Props {
  requestValues: PatchInvitesRequestActionValues,
}

export const createPatchInvitesRequestAction = ({ requestValues }: Props): PatchInvitesRequestAction => {
  return {
    type: PATCH_INVITES_REQUEST,
    requestValues,
  }
}
