import { POST_TEAMS_FAILURE } from 'actions'

import { PostTeamsFailureAction } from './types/PostTeamsFailureAction'
import { PostTeamsRequestActionValues } from './types/PostTeamsRequestActionValues'


interface Props {
  requestValues: PostTeamsRequestActionValues
}

export const createPostTeamsFailureAction = ({
  requestValues,
}: Props): PostTeamsFailureAction => {
  return {
    type: POST_TEAMS_FAILURE,
    requestValues,
  }
}
