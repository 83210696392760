import { TeamStat } from '../../../types/TeamStat'
import { STATS_COLLECTION } from '../../../constants'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
}

export const getTeamStatRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<TeamStat>
  : FirebaseFirestore.DocumentReference => {
  return firestore.collection(STATS_COLLECTION).doc(teamId) as any
}
