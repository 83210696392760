 import { StateReducers } from 'state/reducers/types/StateReducers'
import { ToCurrentCycleButtonConnectedProps } from './types/ToCurrentCycleButtonConnectedProps'
import { ToCurrentCycleButtonMapStateReturnType } from './types/ToCurrentCycleButtonMapStateReturnType'

export const ToCurrentCycleButtonMapState = (
  _state: StateReducers,
  _props: ToCurrentCycleButtonConnectedProps,
): ToCurrentCycleButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
