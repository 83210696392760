import { connect } from 'react-redux'
import { TaskMain } from './TaskMain'
import { TaskMainMapState } from './TaskMainMapState'
import { TaskMainMapDispatch } from './TaskMainMapDispatch'

import { TaskMainMapStateReturnType } from './types/TaskMainMapStateReturnType'
import { TaskMainMapDispatchReturnType } from './types/TaskMainMapDispatchReturnType'
import { TaskMainConnectedProps } from './types/TaskMainConnectedProps'


export const TaskMainConnected = connect<
  TaskMainMapStateReturnType,
  TaskMainMapDispatchReturnType,
  TaskMainConnectedProps>(TaskMainMapState,
    TaskMainMapDispatch)(TaskMain)
