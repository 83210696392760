import { Dispatch, Action } from 'redux'
import { SendConfirmEmailButtonMapDispatchReturnType } from './types/SendConfirmEmailButtonMapDispatchReturnType'
import { createErrorAction } from 'actionCreators/createErrorAction'

export const SendConfirmEmailButtonMapDispatch = (
  dispatch: Dispatch<Action>
): SendConfirmEmailButtonMapDispatchReturnType => {
  return {
    handleError: (props) => {
      dispatch(createErrorAction(props))
    },
  }
}
