import * as React from 'react'
import { ActionButtonProps } from './types/ActionButtonProps'
import { actionButtonStyles } from 'styles/actionButtonStyles'

export const ActionButton = ({
  className = '',
  colorVariance,
  sizeVariance,
  type = 'button',
  ...props
}: ActionButtonProps): JSX.Element => {
  const extraClassName = className ? ' ' + className : ''
  const colorClassName = colorVariance || ''
  const sizeClassName = sizeVariance || ''

  return (
    <button
      className={`ActionButton ${actionButtonStyles} ${colorClassName} ${sizeClassName} ${extraClassName}`}
      type={type}
      {...props}
    />
  )
}
