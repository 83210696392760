import React from 'react'
import { UserInviteCardProps } from './types/UserInviteCardProps'
import { ActionButton } from 'components/button/ActionButton'
import { CheckIcon } from 'components/icon/CheckIcon/CheckIcon'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { DeleteAndConfirmWidget } from '../../widget/DeleteAndConfirmWidget'
import { paddingMediumStyles } from '../../../styles/padding/paddingMediumStyles'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'

export const UserInviteCard = ({
  teamDisplayName,
  deleteInvite,
  acceptInvite,
}: UserInviteCardProps): JSX.Element => {
  return (
    <div className={`UserInviteCard`}>
      <p className={`${paddingMediumStyles}`} style={{ paddingBottom: '0px' }}>
        You have been invited to join{' '}
        <span className={textBoldStyles}>{teamDisplayName}</span>.
      </p>

      <div className={`${listActionButtonsStyles}`}>
        <ActionButton title="Accept invitation" onClick={acceptInvite}>
          <IconAndDiv icon={<CheckIcon />}>Accept</IconAndDiv>
        </ActionButton>

        <DeleteAndConfirmWidget label="Decline" onDeleteClick={deleteInvite} />
      </div>
    </div>
  )
}
