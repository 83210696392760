import { HISTORY_ACTION_WITH_CURRENT_QUERY } from 'actions'
import { UrlQuery } from 'types/UrlQuery'
import { HistoryMethod } from 'types/HistoryMethod'
import { HistoryActionWithCurrentQuery } from './types/HistoryActionWithCurrentQuery'

interface Props {
  pathname?: string
  method?: HistoryMethod
  query?: UrlQuery
  description: string
}

export const createHistoryActionWithCurrentQuery = ({
  pathname,
  query,
  method = 'push',
  description,
}: Props): HistoryActionWithCurrentQuery => {
  return {
    type: HISTORY_ACTION_WITH_CURRENT_QUERY,
    description,
    values: {
      method,
      pathname,
      query,
    },
  }
}
