import { GET_TEAMS_SUCCESS } from 'actions'

import { GetTeamsSuccessAction } from './types/GetTeamsSuccessAction'
import { GetTeamsSuccessActionValues } from './types/GetTeamsSuccessActionValues'
import { GetTeamsRequestActionValues } from './types/GetTeamsRequestActionValues'

interface Props {
  successValues: GetTeamsSuccessActionValues
  requestValues?: GetTeamsRequestActionValues
  description: string
}

export const createGetTeamsSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): GetTeamsSuccessAction => {
  return {
    type: GET_TEAMS_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
