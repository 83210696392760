import { css } from 'emotion'

export const TaskDescriptionEditorStyles = css`
  background-color: #fff;
  border-radius: 0px;

  .EmojiDropdown {
    .DropdownButton {
      padding: 0 8px;
    }

    .Dropdown {
      > .chevronContainer {
        left: 7px;
        top: 24px;
        transform: initial;
      }

      .container {
        top: 31px;
        left: 4px;
      }
    }
  }
`
