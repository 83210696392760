import React, { useState } from 'react'
import { PaymentFailedMainProps } from './types/PaymentFailedMainProps'
import { CustomLinkConnected } from '../../link/CustomLink'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'
import { UPGRADE_WORKSPACE_ROUTE } from '../../../routes'
import { CancelSubscriptionButtonConnected } from '../../button/CancelSubscriptionButton/CancelSubscriptionButtonConnected'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { TEAM_NOUN } from '../../../constants/english'
import { lightBoxStyles } from '../../../styles/lightBoxStyles'
import { marginLeftSmallStyles } from '../../../styles/margin/marginLeftSmallStyles'
import { HeaderOneText } from '../../text/HeaderOneText/HeaderOneText'

export const PaymentFailedMain = ({
  className = '',
  canOwn,
  subscriptionCancelled,
  teamId,
}: PaymentFailedMainProps): JSX.Element => {
  const [showCancelButton, setShowCancelButton] = useState(false)

  return (
    <div className={`PaymentFailedMain ${className}`}>
      <HeaderOneText>Subscription payment failed</HeaderOneText>

      {subscriptionCancelled && (
        <p className={lightBoxStyles}>Subscription cancelled</p>
      )}

      {!subscriptionCancelled && !canOwn && (
        <p className={lightBoxStyles}>Please contact the {TEAM_NOUN} admin.</p>
      )}

      {!subscriptionCancelled && canOwn && (
        <div className={lightBoxStyles}>
          <p>
            You can{' '}
            <CustomLinkConnected
              title="Upgrade"
              className={`${textLinkStyles}`}
              withCurrentQuery={true}
              route={UPGRADE_WORKSPACE_ROUTE}
            >
              continue
            </CustomLinkConnected>{' '}
            or{' '}
            <button
              type="button"
              className={textLinkStyles}
              onClick={() => {
                setShowCancelButton(true)
              }}
            >
              cancel
            </button>{' '}
            the subscription.
          </p>

          {showCancelButton && (
            <CancelSubscriptionButtonConnected
              teamId={teamId}
              className={marginTopMediumStyles}
              confirmButtonClassName={marginLeftSmallStyles}
            />
          )}
        </div>
      )}
    </div>
  )
}
