import { POST_PLANS_FAILURE } from 'actions'

import { PostPlansFailureAction } from './types/PostPlansFailureAction'
import { PostPlansRequestActionValues } from './types/PostPlansRequestActionValues'


interface Props {
  requestValues: PostPlansRequestActionValues
}

export const createPostPlansFailureAction = ({
  requestValues,
}: Props): PostPlansFailureAction => {
  return {
    type: POST_PLANS_FAILURE,
    requestValues,
  }
}
