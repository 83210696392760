import { SIGN_IN_SUCCESS } from 'actions'
import { take, put, select } from 'redux-saga/effects'
import { CONFIRM_EMAIL_ROUTE, SELECT_TEAM_ROUTE } from 'routes'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createGetDashboardRequestAction } from 'actionCreators/get/GetDashboard/createGetDashboardRequestAction'
import { createGetUnscopeUserRequestAction } from 'actionCreators/get/GetUnscopeUser/createGetUnscopeUserRequestAction'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { SignInSuccessAction } from 'actionCreators/auth'
import { createSetCookieStorageAction } from 'actionCreators/cookieStorage'

export const signInSuccessF = function*() {
  while (1) {
    const signInSuccessAction: SignInSuccessAction = yield take([
      SIGN_IN_SUCCESS,
    ])
    const { user, userId } = signInSuccessAction.values
    const { emailVerified, email } = user
    const state: StateReducers = yield select(selectState)
    const isLoginRoute = state.currentLocation?.isRoutes?.isLoginRoute
    const isCreateUserRoute = state.currentLocation?.isRoutes?.isCreateUserRoute

    yield put(
      createSetCookieStorageAction({
        description: 'signInSuccessF',
        values: {
          signedIn: true,
        },
      })
    )

    if (emailVerified) {
      // Ignore errors because the user may not have a custom claim yet
      yield put(
        createGetUnscopeUserRequestAction({
          requestValues: {
            userId,
            ignoreErrors: true,
          },
        })
      )
    }

    if (!emailVerified) {
      yield put(
        createHistoryAction({
          description: 'signInSuccessF',
          pathname: CONFIRM_EMAIL_ROUTE,
          method: 'replace',
        })
      )
    } else if (email) {
      yield put(
        createGetDashboardRequestAction({
          requestValues: {
            userId,
            email,
          },
        })
      )

      const isRedirectRoute = isLoginRoute || isCreateUserRoute

      if (isRedirectRoute) {
        yield put(
          createHistoryAction({
            description: 'signInSuccessF',
            pathname: SELECT_TEAM_ROUTE,
            method: 'replace',
          })
        )
      }
    }
  }
}
