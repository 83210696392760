import { connect } from 'react-redux'
import { TaskStatusList } from './TaskStatusList'
import { TaskStatusListMapState } from './TaskStatusListMapState'
import { TaskStatusListMapDispatch } from './TaskStatusListMapDispatch'

import { TaskStatusListMapStateReturnType } from './types/TaskStatusListMapStateReturnType'
import { TaskStatusListMapDispatchReturnType } from './types/TaskStatusListMapDispatchReturnType'
import { TaskStatusListConnectedProps } from './types/TaskStatusListConnectedProps'


export const TaskStatusListConnected = connect<
  TaskStatusListMapStateReturnType,
  TaskStatusListMapDispatchReturnType,
  TaskStatusListConnectedProps>(TaskStatusListMapState,
    TaskStatusListMapDispatch)(TaskStatusList)
