import { take, put, select, race, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { Race } from 'types/Race'
import { firebaseSdk } from 'sdks'
import { DASHBOARD_HOME_ROUTE } from 'routes'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import {
  SUBMIT_ACCEPT_INVITE,
  SUBMIT_ACCEPT_INVITE_DONE,
  PATCH_USER_INVITES_SUCCESS,
  PATCH_USER_INVITES_FAILURE,
  PATCH_USER_INVITES_CANCEL,
  CLEAR_TEAM_SCOPE,
} from 'actions'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { PatchUserInvitesSuccessAction } from 'actionCreators/patch/PatchUserInvites/types/PatchUserInvitesSuccessAction'
import { PatchUserInvitesFailureAction } from 'actionCreators/patch/PatchUserInvites/types/PatchUserInvitesFailureAction'
import { patchUserInvitesSaga } from 'flows/sagas/patch/patchUserInvitesSaga'

export const submitAcceptInviteF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_ACCEPT_INVITE])
    const { inviteId } = action.values
    const state: StateReducers = yield select(selectState)
    const userInvites = state.userInvites || {}
    const userInvite = inviteId && userInvites[inviteId]
    const teamId = userInvite && userInvite.data.teamId
    // TODO: use getIdToken
    const currentUser = firebaseSdk.auth().currentUser

    if (currentUser && inviteId && teamId) {
      const idToken = yield currentUser.getIdToken()

      yield put(
        createToggleAction({
          description: 'submitAcceptInviteF',
          toggles: {
            showModal: {
              type: 'Joining',
            },
          },
        })
      )

      yield spawn(patchUserInvitesSaga, {
        values: {
          items: {
            [inviteId]: {
              data: {
                accepted: true,
                teamId,
              },
            },
          },
        },
        apiHeaders: {
          token: idToken,
        },
      })

      const patchResult: Race<
        PatchUserInvitesSuccessAction,
        PatchUserInvitesFailureAction,
        any
      > = yield race({
        success: take(PATCH_USER_INVITES_SUCCESS),
        failure: take(PATCH_USER_INVITES_FAILURE),
        cancel: take(PATCH_USER_INVITES_CANCEL),
      })

      if (patchResult.success) {
        // custom claim was updated so we need to refresh the token
        yield currentUser.getIdTokenResult(true)

        yield put({
          type: CLEAR_TEAM_SCOPE,
        })

        yield put(
          createToggleAction({
            description: 'submitAcceptInviteF',
            toggles: {
              showModal: false,
            },
          })
        )

        yield put(
          createHistoryAction({
            description: 'submitAcceptInviteF',
            pathname: DASHBOARD_HOME_ROUTE,
            query: {
              teamId,
            },
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({ description: 'submitAcceptInviteF' })
      )
    }

    yield put({
      type: SUBMIT_ACCEPT_INVITE_DONE,
    })
  }
}
