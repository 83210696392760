import { SET_ALERT_MESSAGE } from 'actions'
import { AlertMessageR } from 'state/reducers/types/AlertMessageR'
import { SetAlertMessageAction } from './types/SetAlertMessageAction'

interface Props {
  values: AlertMessageR
  description: string
}

export const createSetAlertMessageAction = ({
  values,
  description,
}: Props): SetAlertMessageAction => {
  return {
    type: SET_ALERT_MESSAGE,
    description,
    values,
  }
}
