import { AvatarStyles } from './AvatarStyles'
import * as React from 'react'
import { AvatarProps } from './types/AvatarProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { UPDATE_AVATAR_ROUTE } from 'routes'
import { UserIcon } from 'components/icon/UserIcon/UserIcon'

export const Avatar = ({
  photoURL,
  displayName,
  size,
  showChange,
  backupDisplayName,
}: AvatarProps): JSX.Element => {
  const sizeClassname = size ? ` ${size}` : ''
  const initialClassname = photoURL ? '' : ' initials'
  const nameParts = (displayName || backupDisplayName || '').split(' ')
  const firstInitial = nameParts && nameParts[0] && nameParts[0][0]

  return (
    <div
      className={'Avatar ' + AvatarStyles + sizeClassname + initialClassname}
      title={displayName}
    >
      {photoURL && <img src={photoURL} alt={displayName} />}

      {!photoURL && firstInitial && (
        <div className="initials">{firstInitial}</div>
      )}

      {!photoURL && !firstInitial && (
        <div className="generic">
          <UserIcon />
        </div>
      )}

      {showChange && (
        <CustomLinkConnected
          route={UPDATE_AVATAR_ROUTE}
          withCurrentQuery={true}
          title="Change profile image"
        >
          Change
        </CustomLinkConnected>
      )}
    </div>
  )
}
