import { css } from 'emotion'
// import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'

export const MinimalLayoutStyles = css`
  min-height: 100vh;
  padding: 16px;
  max-width: 1024px;
  margin: 0 auto;

  > header {
    display: flex;
    justify-content: space-between;
  }

  > main {
    max-width: 540px;
    margin: 0 auto;
  }

  .UserInviteList {
    margin-top: 32px;
  }
`
