import * as React from 'react'
import { MembersMain } from './MembersMain'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const MembersMainExamples = () => {
  return (
    <div className="MembersMainExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <MembersMain />
      </Provider>
    </div>
  )
}
