import { css } from 'emotion'

export const listActionButtonsStyles = css`
  padding: 4px;

  > * {
    display: inline-block;
    margin: 4px;
  }
`
