import { getCurrentTask } from './getCurrentTask'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { forEach } from 'lodash'

interface Props {
  state: StateReducers
  taskId: string
}

export const getTaggedUserIds = ({ state, taskId }: Props): string [] => {
  const task = getCurrentTask({ state, taskId })
  const tags = task && task.tags

  if (!tags) {
    return []
  }

  const userTagsMutable: string[] = []

  forEach(tags, (tag, id) => {
    if (tag.type === 'user') {
      userTagsMutable.push(id)
    }
  })

  return userTagsMutable
}
