import { POST_RESET_PASSWORD_REQUEST } from 'actions'
import { PostResetPasswordRequestAction } from './types/PostResetPasswordRequestAction'
import { PostResetPasswordRequestActionValues } from './types/PostResetPasswordRequestActionValues'

interface Props {
  requestValues: PostResetPasswordRequestActionValues,
}

export const createPostResetPasswordRequestAction = ({ requestValues }: Props): PostResetPasswordRequestAction => {
  return {
    type: POST_RESET_PASSWORD_REQUEST,
    requestValues,
  }
}
