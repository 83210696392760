import { CancelSubscriptionButtonStyles } from './CancelSubscriptionButtonStyles'
import React, { useState } from 'react'
import { Spinner } from 'components/widget/Spinner'
import { CancelSubscriptionButtonProps } from './types/CancelSubscriptionButtonProps'
import { getIdToken } from 'sdks/firebase/getIdToken'
import { deleteStripeSubscriptionsApi } from 'apis/delete/deleteStripeSubscriptionsApi'
import { DeleteAndConfirmWidget } from 'components/widget/DeleteAndConfirmWidget'
import { marginBottomMediumStyles } from '../../../styles/margin/marginBottomMediumStyles'

export const CancelSubscriptionButton = ({
  handleError,
  teamId,
  stripeSubscriptionId,
  style,
  onSuccess,
  label = 'Cancel',
  className = '',
  ...props
}: CancelSubscriptionButtonProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(false)

  const deleteSubscription = async () => {
    try {
      setShowSpinner(true)
      const token = await getIdToken()

      if (token) {
        const values = teamId ? { teamId } : { stripeSubscriptionId }

        const response = await deleteStripeSubscriptionsApi({
          values,
          apiHeaders: {
            token,
          },
        })

        setShowSpinner(false)

        onSuccess?.(response.data.items) // may cause unmount
      } else {
        setShowSpinner(false)
        handleError({ description: 'CancelSubscriptionButton' })
      }
    } catch (error) {
      setShowSpinner(false)
      handleError({ error, description: 'CancelSubscriptionButton' })
    }
  }

  return (
    <div
      className={`CancelSubscriptionButton ${CancelSubscriptionButtonStyles} ${className}`}
      style={style}
    >
      {showSpinner && <Spinner className={marginBottomMediumStyles} />}

      {deleteSubscription && (
        <div>
          <DeleteAndConfirmWidget
            disabled={showSpinner}
            label={label}
            onDeleteClick={deleteSubscription}
            {...props}
          />
        </div>
      )}
    </div>
  )
}
