import { POST_RESET_PASSWORD_SUCCESS } from 'actions'

import { PostResetPasswordSuccessAction } from './types/PostResetPasswordSuccessAction'
import { PostResetPasswordSuccessActionValues } from './types/PostResetPasswordSuccessActionValues'
import { PostResetPasswordRequestActionValues } from './types/PostResetPasswordRequestActionValues'


interface Props {
  successValues: PostResetPasswordSuccessActionValues
  requestValues: PostResetPasswordRequestActionValues
}

export const createPostResetPasswordSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostResetPasswordSuccessAction => {
  return {
    type: POST_RESET_PASSWORD_SUCCESS,
    requestValues,
    successValues,
  }
}
