import { UploadTeamLogoButtonStyles } from './UploadTeamLogoButtonStyles'
import React from 'react'
import { UploadTeamLogoButtonProps } from './types/UploadTeamLogoButtonProps'
import { FileUploadField } from 'components/field/FileUploadField/FileUploadField'
import { ActionButton } from '../ActionButton'
import { FileUploadIcon } from '../../icon/FileUploadIcon/FileUploadIcon'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'

export const UploadTeamLogoButton = ({
  uploadFile,
  style,
}: UploadTeamLogoButtonProps): JSX.Element => {
  return (
    <FileUploadField
      className={`UploadTeamLogoButton ${UploadTeamLogoButtonStyles}`}
      onlyImages={true}
      onValid={uploadFile}
      style={style}
    >
      <ActionButton title="Upload file">
        <IconAndDiv icon={<FileUploadIcon />}>Upload file</IconAndDiv>
      </ActionButton>
    </FileUploadField>
  )
}
