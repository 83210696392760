import { TEAMS_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PostTeamsSuccessPayload } from 'firebaseFunctions/teams/types/PostTeamsSuccessPayload'
import { PostTeamsApiProps } from './types/PostTeamsApiProps'

export const postTeamsApi = ({
  values,
  apiHeaders,
}: PostTeamsApiProps): AxiosPromise<PostTeamsSuccessPayload> => {
  return Axios.post(TEAMS_URL, values, {
    headers: apiHeaders,
  })
}
