import { css } from 'emotion'

export const UpdateWorkspaceLogoMainStyles = css`
  .existingLogo {
    > * {
      display: block;
    }

    img {
      display: inline-block;
      max-height: 100px;
      max-width: 100px;
    }

    .TeamLogoPlaceHolderWidget {
      display: inline-block;
    }

    .confirmDelete {
      margin-left: 8px;
    }
  }

  .UploadTeamLogoButton {
    margin-top: 32px;
  }
`
