import { LinkStyles } from './LinkStyles'
import React from 'react'
import { LinkProps } from './types/LinkProps'
import { Link as RouterLink } from 'react-router-dom'

export const Link = (props: LinkProps): JSX.Element => {
  const { className = '', to, title, children } = props

  const additionalClassName = className ? ` ${className}` : ''

  if (to) {
    return (
      <RouterLink
        title={title}
        {...(props as any)}
        to={to}
        className={'Link ' + LinkStyles + additionalClassName}
      >
        {children}
      </RouterLink>
    )
  }

  return (
    <a
      {...props}
      title={title}
      className={'Link ' + LinkStyles + additionalClassName}
    >
      {children}
    </a>
  )
}
