import { POST_USER_INVITED_FAILURE } from 'actions'

import { PostUserInvitedFailureAction } from './types/PostUserInvitedFailureAction'
import { PostUserInvitedRequestActionValues } from './types/PostUserInvitedRequestActionValues'


interface Props {
  requestValues: PostUserInvitedRequestActionValues
}

export const createPostUserInvitedFailureAction = ({
  requestValues,
}: Props): PostUserInvitedFailureAction => {
  return {
    type: POST_USER_INVITED_FAILURE,
    requestValues,
  }
}
