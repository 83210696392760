import { connect } from 'react-redux'
import { UploadedFileList } from './UploadedFileList'
import { UploadedFileListMapState } from './UploadedFileListMapState'
import { UploadedFileListMapDispatch } from './UploadedFileListMapDispatch'

import { UploadedFileListMapStateReturnType } from './types/UploadedFileListMapStateReturnType'
import { UploadedFileListMapDispatchReturnType } from './types/UploadedFileListMapDispatchReturnType'
import { UploadedFileListConnectedProps } from './types/UploadedFileListConnectedProps'


export const UploadedFileListConnected = connect<
  UploadedFileListMapStateReturnType,
  UploadedFileListMapDispatchReturnType,
  UploadedFileListConnectedProps
>(
  UploadedFileListMapState,
  UploadedFileListMapDispatch
)(UploadedFileList)
