import { Dispatch, Action } from 'redux'
import { ToastListWatcherMapDispatchReturnType } from './types/ToastListWatcherMapDispatchReturnType'
import { UNSET_ALERT_MESSAGE } from '../../../actions'

export const ToastListWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): ToastListWatcherMapDispatchReturnType => {
  return {
    unsetAlertMessage: () => {
      dispatch({
        type: UNSET_ALERT_MESSAGE,
      })
    },
  }
}
