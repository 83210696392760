import { css } from 'emotion'
import { LINK_COLOR } from 'constants/styleConstants'

export const UserAccountHeaderStyles = css`
  position: relative;

  a {
    color: ${LINK_COLOR};

    &:hover {
      text-decoration: underline;
    }
  }

  .DropdownButton {
    padding: 0 4px;

    .Avatar {
      width: 30px;
      height: 30px;
    }
  }

  .Dropdown {
    .chevronContainer {
      top: 34px;
      right: 4px;
    }

    .container {
      right: -134px;
      top: 41px;
    }

    main {
      padding: 8px;
      display: flex;
      flex-direction: rows;
      align-items: center;

      .Avatar {
        margin-bottom: 8px;
        margin-right: 16px;
      }
    }
  }

  &.isMediumScreen {
    .Dropdown {
      .container {
        right: 0px;
        width: 320px;
      }
    }
  }
`
