// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // DropdownConnectedProps,
  DropdownMapDispatchReturnType,
} from './types'

export const DropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: DropdownConnectedProps,
): DropdownMapDispatchReturnType => {
  return {
  }
}
