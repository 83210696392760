import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // DropdownConnectedProps,
  DropdownMapStateReturnType,
} from './types'

export const DropdownMapState = (
  state: StateReducers,
  // props: DropdownConnectedProps,
): DropdownMapStateReturnType => {
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    isMediumScreen,
  }
}
