import { SelectTagInputFieldStyles } from './SelectTagInputFieldStyles'
import React from 'react'
import { SelectTagInputFieldProps } from './types/SelectTagInputFieldProps'
import { SelectInputField } from '../SelectInputField/SelectInputField'
import { TagSelectOption } from 'types/TagSelectOption'
import { SelectTagOption } from 'components/option/SelectTagOption'
import { SelectOption } from 'types/SelectOption'

export const SelectTagInputField = ({
  values,
  labels,
  types,
  onChange,
  selectedOptions,
  ...props
}: SelectTagInputFieldProps): JSX.Element => {
  const options: TagSelectOption[] = values.map((value, i) => {
    return {
      value,
      type: types[i],
      label: labels[i],
    }
  })

  let selectInputValues: TagSelectOption[] = []

  selectedOptions?.map((selectedOption) => {
    const found = options.find((option) => {
      return option.value === selectedOption
    })

    if (found) {
      selectInputValues.push(found)
    }
  })

  return (
    <SelectInputField
      {...props}
      options={options}
      isMulti={true}
      formatOptionLabel={(option: TagSelectOption) => {
        const { type, value } = option

        return <SelectTagOption id={value} type={type} />
      }}
      className={`SelectTagInputField ${SelectTagInputFieldStyles}`}
      onChange={(selectedOptions) => {
        let values = undefined

        if (selectedOptions) {
          values = Array.isArray(selectedOptions)
            ? (selectedOptions as SelectOption[]).map(
                (selectedOption: SelectOption) => {
                  return selectedOption.value
                }
              )
            : [selectedOptions.value]
        }

        onChange?.(values)
      }}
      value={selectInputValues}
    />
  )
}
