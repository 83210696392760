import { UploadWatcherStyles } from './UploadWatcherStyles'
import React, { useEffect, useState, useCallback } from 'react'
import { UploadWatcherProps } from './types/UploadWatcherProps'
import { Button } from 'components/button/Button'
import { FirebaseError } from 'firebase'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'
import { isFileType } from '../../../utilities/is/isFileType'

export const UploadWatcher = ({
  upload,
  failure,
  success,
  uploadId,
}: UploadWatcherProps) => {
  const [progress, setProgress] = useState(0)
  const [canceled, setCanceled] = useState(false)
  const [uploading, setUploading] = useState<firebase.storage.UploadTask>()

  useEffect(() => {
    if (!upload) {
      return
    }

    const { ref, data, meta } = upload

    try {
      const uploading = ref.put(data, meta)

      const handleProgress = (
        snapshot: firebase.storage.UploadTaskSnapshot
      ) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
      }

      const handleError = (error: FirebaseError) => {
        failure({ error, upload, uploadId })
      }

      const handleSuccess = () => {
        // Handle successful uploads on complete
        uploading.snapshot.ref.getDownloadURL().then((url: string) => {
          success({ url, upload, uploadId })
        })
      }

      setUploading(uploading)

      uploading.on('state_changed', handleProgress, handleError, handleSuccess)
    } catch (error) {
      failure({ error, upload, uploadId })
    }
  }, [upload, failure, success, uploadId])

  const cancelCallback = useCallback(() => {
    uploading?.cancel()
    setCanceled(true)
  }, [uploading, setCanceled])

  if (canceled) {
    return null
  }

  const fileName = isFileType(upload?.data) ? upload?.data.name : ''

  return (
    <div className={`UploadWatcher ${UploadWatcherStyles}`}>
      <Button onClick={cancelCallback} title="cancel">
        <TimesSolidIcon />
      </Button>
      Uploading: {Math.floor(progress)}%
      {fileName && (
        <p className="fileName" title={fileName}>
          {fileName}
        </p>
      )}
    </div>
  )
}
