import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { DELETE_PLANS_REQUEST } from 'actions'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { FirebaseError } from 'firebase'
import { DeletePlansRequestAction } from 'actionCreators/delete/DeletePlans/types/DeletePlansRequestAction'
import { createDeletePlansSuccessAction } from 'actionCreators/delete/DeletePlans/createDeletePlansSuccessAction'
import { createDeletePlansFailureAction } from 'actionCreators/delete/DeletePlans/createDeletePlansFailureAction'
import { deletePlan } from '../../firebaseFunctions/utilities/ref/delete/deletePlan'

export const deletePlansRequestF = function*() {
  while (1) {
    const deletePlansRequestAction: DeletePlansRequestAction = yield take([
      DELETE_PLANS_REQUEST,
    ])

    const { requestValues } = deletePlansRequestAction
    const { key: planId, item: firstPlan } = getObjectFirstItem(
      requestValues.items
    )

    if (planId && firstPlan) {
      const { teamId } = firstPlan
      try {
        yield deletePlan({ firestore, teamId, planId })

        yield put(
          createDeletePlansSuccessAction({
            requestValues,
            successValues: {
              [planId]: {},
            },
          })
        )
      } catch (error) {
        yield put(
          createFirebaseErrorAction({
            error: error as FirebaseError,
            description: 'deletePlansRequestF',
          })
        )

        yield put(
          createDeletePlansFailureAction({
            requestValues,
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({ description: 'deletePlansRequestF' })
      )
    }
  }
}
