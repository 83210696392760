import { connect } from 'react-redux'
import { MessageFileCard } from './MessageFileCard'
import { MessageFileCardMapState } from './MessageFileCardMapState'
import { MessageFileCardMapDispatch } from './MessageFileCardMapDispatch'

import { MessageFileCardMapStateReturnType } from './types/MessageFileCardMapStateReturnType'
import { MessageFileCardMapDispatchReturnType } from './types/MessageFileCardMapDispatchReturnType'
import { MessageFileCardConnectedProps } from './types/MessageFileCardConnectedProps'


export const MessageFileCardConnected = connect<
  MessageFileCardMapStateReturnType,
  MessageFileCardMapDispatchReturnType,
  MessageFileCardConnectedProps>(MessageFileCardMapState,
    MessageFileCardMapDispatch)(MessageFileCard)
