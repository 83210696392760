import { MessageNotificationDropdownStyles } from './MessageNotificationDropdownStyles'
import React from 'react'
import { MessageNotificationDropdownProps } from './types/MessageNotificationDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { NotificationListConnected } from 'components/list/NotificationList'
import { CountBubbleWidget } from 'components/widget/CountBubbleWidget'
import { CommentSolidIcon } from 'components/icon/CommentSolidIcon/CommentSolidIcon'

export const MessageNotificationDropdown = ({
  unreadCount = 0,
  showMessageNotificationsDropdown,
  isMediumScreen,
  className = '',
}: MessageNotificationDropdownProps): JSX.Element => {
  const useUnreadCount = unreadCount > 99 ? 99 : unreadCount
  const activeClassName = showMessageNotificationsDropdown ? ' active' : ''
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

  return (
    <div
      className={`MessageNotificationDropdown ${className} ${MessageNotificationDropdownStyles} ${isMediumScreenClassName}`}
    >
      <DropdownButtonConnected
        title="Message notifications"
        watchSelector=".MessageNotificationDropdown"
        className={activeClassName}
        dropdownType="messageNotifications"
      >
        <CommentSolidIcon />

        {useUnreadCount > 0 && (
          <CountBubbleWidget>{useUnreadCount}</CountBubbleWidget>
        )}
      </DropdownButtonConnected>

      {showMessageNotificationsDropdown && (
        <DropdownConnected autoAdjust={true}>
          <NotificationListConnected notificationGroup="messages" />
        </DropdownConnected>
      )}
    </div>
  )
}
