import { take, put, select, spawn } from 'redux-saga/effects'
import { storageSdk } from 'sdks'
import { uploadSuccessTaskFilesSaga } from '../sagas/uploadSuccessTaskFilesSaga'
import { createId } from 'firebaseFunctions/utilities/createId'
import { getTaskOriginalPath } from 'firebaseFunctions/utilities/get/getTaskOriginalPath'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_TASK_FILES, SUBMIT_TASK_FILES_DONE } from 'actions'
import { getTaskFiles } from 'utilities/get/getTaskFiles'
import { createUploadRequestAction } from 'actionCreators/Upload/createUploadRequestAction'

export const submitTaskFilesF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_FILES])
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const { files, taskId } = action.values
    const uploadId = createId()
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName

    if (files && teamId && taskId && currentUserId && currentUserDisplayName) {
      const file = files[0]
      let fileName = file.name

      const existingFiles = yield getTaskFiles({ teamId, taskId })
      const existingFileName = existingFiles.indexOf(fileName) !== -1

      if (existingFileName) {
        let i = 1
        while (existingFiles.indexOf(`${i}_${fileName}`) !== -1) {
          i += 1
        }

        fileName = `${i}_${fileName}`
      }

      const ref = storageSdk().ref()
      const originalPath = getTaskOriginalPath({ teamId, taskId, fileName })
      const fileRef = ref.child(originalPath)
      const uploadMeta: firebase.storage.UploadMetadata = {}

      const uploadRequestAction = createUploadRequestAction({
        requestValues: {
          [uploadId]: {
            data: file,
            fileName,
            description: 'submitTaskFilesF',
            status: 'uploading',
            ref: fileRef,
            meta: uploadMeta,
          },
        },
      })

      yield put(uploadRequestAction)

      yield spawn(uploadSuccessTaskFilesSaga, {
        uploadId,
        taskId,
        teamId,
        userId: currentUserId,
        userDisplayName: currentUserDisplayName,
      })
    } else {
      // TODO: createUnexpectedErrorAction and include where it happened.
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskFilesF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_FILES_DONE,
    })
  }
}
