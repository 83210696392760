import { connect } from 'react-redux'
import { UpdateAvatarForm } from './UpdateAvatarForm'
import { UpdateAvatarFormMapState } from './UpdateAvatarFormMapState'
import { UpdateAvatarFormMapDispatch } from './UpdateAvatarFormMapDispatch'
import { UpdateAvatarFormMapStateReturnType } from './types/UpdateAvatarFormMapStateReturnType'
import { UpdateAvatarFormMapDispatchReturnType } from './types/UpdateAvatarFormMapDispatchReturnType'
import { UpdateAvatarFormConnectedProps } from './types/UpdateAvatarFormConnectedProps'

export const UpdateAvatarFormConnected = connect<
  UpdateAvatarFormMapStateReturnType,
  UpdateAvatarFormMapDispatchReturnType,
  UpdateAvatarFormConnectedProps>(UpdateAvatarFormMapState,
    UpdateAvatarFormMapDispatch)(UpdateAvatarForm)
