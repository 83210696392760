import { API_FLOW_ERROR } from 'actions'
import { ApiFlowErrorActionValues } from './types/ApiFlowErrorActionValues'
import { ApiFlowErrorAction } from './types/ApiFlowErrorAction'

export const createApiFlowErrorAction = (values: ApiFlowErrorActionValues): ApiFlowErrorAction => {
  return {
    type: API_FLOW_ERROR,
    values,
  }
}
