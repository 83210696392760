import React, { useCallback } from 'react'
import { ToPreviousCycleButtonProps } from './types/ToPreviousCycleButtonProps'
import { ActionButton } from '../ActionButton'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { PLAN_NOUN } from '../../../constants/english'
import { ArrowLeftIcon } from '../../icon/ArrowLeftIcon/ArrowLeftIcon'

export const ToPreviousCycleButton = ({
  moveToPreviousCycle,
  taskId,
  className = '',
}: ToPreviousCycleButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    moveToPreviousCycle(taskId)
  }, [taskId, moveToPreviousCycle])

  return (
    <ActionButton
      className={`ToPreviousCycleButton ${className}`}
      onClick={onClickCallback}
      title={`move to previous ${PLAN_NOUN}`}
    >
      <IconAndDiv icon={<ArrowLeftIcon />}>Previous {PLAN_NOUN}</IconAndDiv>
    </ActionButton>
  )
}
