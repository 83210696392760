// import { Dispatch, Action } from 'redux'

import {
  // MemberCardConnectedProps,
  MemberCardMapDispatchReturnType,
} from './types'

export const MemberCardMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MemberCardConnectedProps,
): MemberCardMapDispatchReturnType => {
  return {
  }
}
