import { connect } from 'react-redux'
import { StatusDropdown } from './StatusDropdown'
import { StatusDropdownMapState } from './StatusDropdownMapState'
import { StatusDropdownMapDispatch } from './StatusDropdownMapDispatch'
import { StatusDropdownMapStateReturnType } from './types/StatusDropdownMapStateReturnType'
import { StatusDropdownMapDispatchReturnType } from './types/StatusDropdownMapDispatchReturnType'
import { StatusDropdownConnectedProps } from './types/StatusDropdownConnectedProps'

export const StatusDropdownConnected = connect<
  StatusDropdownMapStateReturnType,
  StatusDropdownMapDispatchReturnType,
  StatusDropdownConnectedProps
>(
  StatusDropdownMapState,
  StatusDropdownMapDispatch
)(StatusDropdown)
