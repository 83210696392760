import { connect } from 'react-redux'
import { ToDoLink } from './ToDoLink'
import { ToDoLinkMapState } from './ToDoLinkMapState'
import { ToDoLinkMapDispatch } from './ToDoLinkMapDispatch'

import { ToDoLinkMapStateReturnType } from './types/ToDoLinkMapStateReturnType'
import { ToDoLinkMapDispatchReturnType } from './types/ToDoLinkMapDispatchReturnType'
import { ToDoLinkConnectedProps } from './types/ToDoLinkConnectedProps'


export const ToDoLinkConnected = connect<
  ToDoLinkMapStateReturnType,
  ToDoLinkMapDispatchReturnType,
  ToDoLinkConnectedProps>(ToDoLinkMapState,
    ToDoLinkMapDispatch)(ToDoLink)
