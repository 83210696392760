import { connect } from 'react-redux'
import { MessageLayout } from './MessageLayout'
import { MessageLayoutMapState } from './MessageLayoutMapState'
import { MessageLayoutMapDispatch } from './MessageLayoutMapDispatch'

import { MessageLayoutMapStateReturnType } from './types/MessageLayoutMapStateReturnType'
import { MessageLayoutMapDispatchReturnType } from './types/MessageLayoutMapDispatchReturnType'
import { MessageLayoutConnectedProps } from './types/MessageLayoutConnectedProps'


export const MessageLayoutConnected = connect<
  MessageLayoutMapStateReturnType,
  MessageLayoutMapDispatchReturnType,
  MessageLayoutConnectedProps>(MessageLayoutMapState,
    MessageLayoutMapDispatch)(MessageLayout)
