import { StateReducers } from 'state/reducers/types/StateReducers'
import { ResetPasswordFormConnectedProps } from './types/ResetPasswordFormConnectedProps'
import { ResetPasswordFormMapStateReturnType } from './types/ResetPasswordFormMapStateReturnType'

export const ResetPasswordFormMapState = (
  _state: StateReducers,
  _props: ResetPasswordFormConnectedProps
): ResetPasswordFormMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
