import { connect } from 'react-redux'
import { PlanTasksWatcher } from './PlanTasksWatcher'
import { PlanTasksWatcherMapState } from './PlanTasksWatcherMapState'
import { PlanTasksWatcherMapDispatch } from './PlanTasksWatcherMapDispatch'

import { PlanTasksWatcherMapStateReturnType } from './types/PlanTasksWatcherMapStateReturnType'
import { PlanTasksWatcherMapDispatchReturnType } from './types/PlanTasksWatcherMapDispatchReturnType'
import { PlanTasksWatcherConnectedProps } from './types/PlanTasksWatcherConnectedProps'


export const PlanTasksWatcherConnected = connect<
  PlanTasksWatcherMapStateReturnType,
  PlanTasksWatcherMapDispatchReturnType,
  PlanTasksWatcherConnectedProps>(PlanTasksWatcherMapState,
    PlanTasksWatcherMapDispatch)(PlanTasksWatcher)
