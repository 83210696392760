import { css } from 'emotion'

import {
  NEUTRAL_BORDER_COLOR,
  NEUTRAL_BORDER_DARK,
  BORDER_RADIUS,
} from 'constants/styleConstants'

export const SignOutButtonStyles = css`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${NEUTRAL_BORDER_COLOR};
  padding: 8px;

  &:hover {
    border-color: ${NEUTRAL_BORDER_DARK};
  }
}`
