interface GetMaxEmailCountProps {
  isPro: boolean
  userCount?: number
  maxUserCountFree?: number
  maxUserCountPro?: number
}

// TODO: write unit tests
export const getMaxInviteEmailCount = ({
  isPro,
  userCount = 0,
  maxUserCountPro = 0,
  maxUserCountFree = 0,
}: GetMaxEmailCountProps): number => {
  let maxEmailCount = 10 // arbiturary limit that I think is good

  if (isPro) {
    const almostToProMax = maxUserCountPro - userCount < maxEmailCount

    if (almostToProMax) {
      maxEmailCount = maxUserCountPro - userCount

      if (maxEmailCount < 0) {
        return 0
      }

      return maxEmailCount
    }

    return maxEmailCount
  }

  const almostToFreeMax = maxUserCountFree - userCount < maxEmailCount

  if (almostToFreeMax) {
    maxEmailCount = maxUserCountFree - userCount

    if (maxEmailCount < 0) {
      return 0
    }

    return maxEmailCount
  }

  return maxEmailCount
}
