import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { forEach } from 'lodash'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'
import { TeamsReducer } from 'state/reducers/types/TeamsReducer'
import { createPatchTeamsSuccessAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsSuccessAction'
import { createPatchTeamsFailureAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsFailureAction'
import { getTeamRef } from 'firebaseFunctions/utilities/ref/get/getTeamRef'

interface Props {
  items: TeamsReducer
}

export const patchTeamsSaga = function*({ items }: Props) {
  yield put(
    createPatchTeamsRequestAction({
      requestValues: {
        items: items,
      },
    })
  )

  try {
    const batch = firestore.batch()

    forEach(items, (teamReducer, teamId) => {
      const { data } = teamReducer
      const teamMutable = { ...data }

      const ref = getTeamRef({
        firestore,
        teamId,
      })

      batch.update(ref, teamMutable)
    })

    yield batch.commit()

    yield put(
      createPatchTeamsSuccessAction({
        requestValues: { items },
        successValues: { items },
      })
    )
  } catch (error) {
    yield put(
      createFirebaseErrorAction({
        error: error as firebase.FirebaseError,
        description: 'patchTeamsSaga',
      })
    )

    yield put(
      createPatchTeamsFailureAction({
        requestValues: { items },
      })
    )
  }
}
