import { POST_INVITES_REQUEST } from 'actions'
import { PostInvitesRequestAction } from './types/PostInvitesRequestAction'
import { PostInvitesRequestActionValues } from './types/PostInvitesRequestActionValues'

interface Props {
  requestValues: PostInvitesRequestActionValues,
}

export const createPostInvitesRequestAction = ({ requestValues }: Props): PostInvitesRequestAction => {
  return {
    type: POST_INVITES_REQUEST,
    requestValues,
  }
}
