import { StateReducers } from 'state/reducers/types/StateReducers'
import { LoginFormConnectedProps } from './types/LoginFormConnectedProps'
import { LoginFormMapStateReturnType } from './types/LoginFormMapStateReturnType'

export const LoginFormMapState = (
  state: StateReducers,
  _props: LoginFormConnectedProps
): LoginFormMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showRecaptcha = toggles && toggles.showRecaptcha

  return {
    showRecaptcha,
  }
}
