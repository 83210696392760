/**
 * This middleware captures CALL_HISTORY_METHOD actions to redirect to the
 * provided history object. This will prevent these actions from reaching your
 * reducer or any middleware that comes after this one.
 */
import { History } from 'history'
import { CALL_HISTORY_METHOD } from 'actions'

import {
  Dispatch,
  Action,
} from 'redux'

export const createRouterMiddleware = (history: History) => {
  return () => {
    return (next: Dispatch<Action>) => {
      return (action: any): any => {
        if (action.type === CALL_HISTORY_METHOD) {
          const { values: { method, location } } = action

          history[method](location)
        }

        return next(action)
      }
    }
  }
}
