import { NoteMessageStyles } from './NoteMessageStyles'
import React from 'react'
import { NoteMessageProps } from './types/NoteMessageProps'

export const NoteMessage = ({
  className = '',
  ...props
}: NoteMessageProps): JSX.Element => {
  const additionalClassname = className ? ` ${className}` : ''

  return (
    <p
      className={'NoteMessage ' + NoteMessageStyles + additionalClassname}
      {...props}
    />
  )
}
