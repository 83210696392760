import { Dispatch, Action } from 'redux'
import { Plan } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { PlansWatcherMapDispatchReturnType } from './types/PlansWatcherMapDispatchReturnType'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetPlansSuccessAction } from 'actionCreators/get/GetPlans/createGetPlansSuccessAction'
import { createGetPlansFailureAction } from 'actionCreators/get/GetPlans/createGetPlansFailureAction'

export const PlansWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): PlansWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'PlansWatcherMapDispatch',
          increment,
          watcherType: 'plans',
        })
      )
    },

    onNext: ({ querySnapshot, teamId }) => {
      const planMutable: PlansReducer = {} // TODO: get rid of mutable

      querySnapshot.forEach((planDoc: firebase.firestore.DocumentSnapshot) => {
        const plan = planDoc.data() as Plan | undefined

        if (!plan || !plan.created) {
          // skip to prevent it overwriting the temp created time
          return
        }

        planMutable[planDoc.id] = {
          data: plan,
          teamId,
        }
      })

      const getPlanSuccessAction = createGetPlansSuccessAction({
        successValues: {
          items: planMutable,
        },
      })

      dispatch(getPlanSuccessAction)
    },

    onFirebaseError: ({ error, teamId }) => {
      const firestoreErrorAction = createFirebaseErrorAction({
        error: error as FirebaseError,
        description: 'PlansWatcherMapDispatch',
      })

      dispatch(firestoreErrorAction)

      const getPlanFailureAction = createGetPlansFailureAction({
        requestValues: {
          teamId,
        },
      })

      dispatch(getPlanFailureAction)
    },
  }
}
