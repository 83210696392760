import { InlineStyleButtonStyles } from './InlineStyleButtonStyles'
import React from 'react'
import { InlineStyleButtonProps } from './types/InlineStyleButtonProps'
import { RichUtils } from 'draft-js'
import { Button } from 'components/button/Button'

export const InlineStyleButton = ({
  styleKey,
  setEditorState,
  editorState,
  children,
  styleType = 'inline',
}: InlineStyleButtonProps): JSX.Element => {
  const onClick = (_e: React.MouseEvent<HTMLButtonElement>) => {
    if (editorState && setEditorState) {
      if (styleType === 'inline') {
        const newState = RichUtils.toggleInlineStyle(editorState, styleKey)
        setEditorState(newState)
      } else {
        setEditorState(RichUtils.toggleBlockType(editorState, styleKey))
      }
    }
  }

  const startKey = editorState?.getSelection()?.getStartKey()

  const blockType =
    startKey &&
    editorState
      ?.getCurrentContent()
      ?.getBlockForKey(startKey)
      ?.getType()

  const isActive =
    styleType === 'inline'
      ? editorState?.getCurrentInlineStyle().has(styleKey)
      : styleKey === blockType

  const isActiveClassName = isActive ? ' active' : ''

  return (
    <Button
      type="button"
      className={
        'InlineStyleButton ' + InlineStyleButtonStyles + isActiveClassName
      }
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
