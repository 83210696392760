import { Team } from '../../../types/Team'
import { TEAMS_COLLECTION } from '../../../constants'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
}

export const getTeamsRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.CollectionReference<Team>
  : FirebaseFirestore.CollectionReference => {
  return firestore.collection(TEAMS_COLLECTION) as any
}
