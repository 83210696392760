import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'

import {
  GET_MESSAGES_SUCCESS,
  POST_MESSAGES_REQUEST,
  DELETE_MESSAGES_REQUEST,
  CLEAR_TEAM_SCOPE,
  PATCH_MESSAGES_REQUEST,
  DELETE_MESSAGES_SUCCESS,
} from 'actions'
import { MessagesReducer } from './types/MessagesReducer'
import { MessageReducer } from './types/MessageReducer'

export const messagesReducer = createGenericReducer<
  MessagesReducer,
  MessageReducer
>({
  getSuccessAction: GET_MESSAGES_SUCCESS,
  postRequestAction: POST_MESSAGES_REQUEST,
  deleteRequestAction: DELETE_MESSAGES_REQUEST,
  deleteSuccessAction: DELETE_MESSAGES_SUCCESS,
  clearActions: [CLEAR_TEAM_SCOPE],
  patchRequestAction: PATCH_MESSAGES_REQUEST,
  // debug: true,
})
