import { StateReducers } from 'state/reducers/types/StateReducers'

import { UserInvitesWatcherConnectedProps } from './types/UserInvitesWatcherConnectedProps'
import { UserInvitesWatcherMapStateReturnType } from './types/UserInvitesWatcherMapStateReturnType'


export const UserInvitesWatcherMapState = (
  _state: StateReducers,
  _props: UserInvitesWatcherConnectedProps
): UserInvitesWatcherMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
