import { css } from 'emotion'
import {
  TEXT_COLOR,
  PRIMARY_BACKGROUND_COLOR,
  FONT_WEIGHT_NORMAL,
  FONT_WEIGHT_BOLDER,
} from 'constants/styleConstants'

export const AppStyles = css`
  color: ${TEXT_COLOR};
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: ${FONT_WEIGHT_NORMAL};
  min-height: 100vh;
  background-color: ${PRIMARY_BACKGROUND_COLOR};

  img {
    overflow: hidden; /* in case of alt text */
  }

  .sandbox {
    margin-top: 100px;
  }

  &.isEndToEndTesting {
    input,
    textarea,
    [contenteditable] {
      caret-color: #fff; /* hide the carret for= snapshot testing */
    }

    transform: scale(0.5);
    transform-origin: top left;
  }

  p {
    line-height: 1.45em;

    * {
      line-height: 1.45em;
    }
  }

  h1 {
    font-size: 1.1em;
  }

  h2 {
    font-size: 1em;
  }

  .emResponsive {
    font-size: 1em;
  }

  &.isMediumScreen {
    h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1.25em;
    }

    .emResponsive {
      font-size: 1.25em;
    }
  }

  /* TODO: remove and use flexSpaceBetweenStyles */
  .spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flexAlign {
    display: flex;
    align-items: center;
  }

  .centerFlex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  strong {
    font-weight: ${FONT_WEIGHT_BOLDER};
  }
`
