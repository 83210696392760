import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import { TagDisplayNameSpanConnectedProps } from './types/TagDisplayNameSpanConnectedProps'
import { TagDisplayNameSpanMapStateReturnType } from './types/TagDisplayNameSpanMapStateReturnType'


export const TagDisplayNameSpanMapState = (
  state: StateReducers,
  props: TagDisplayNameSpanConnectedProps,
): TagDisplayNameSpanMapStateReturnType => {
  const task = getCurrentTask({ state, taskId: props.taskId })
  const tags = task && task.tags
  const tag = tags && tags[props.tagId]
  const tagType = tag && tag.type
  const displayName = tag && tag.displayName

  return {
    tagType,
    displayName,
  }
}
