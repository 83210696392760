import { uniq } from 'lodash'
const STUFF_TO_REMOVE = /\r?\n|\r|,|;/g

export const parseEmailsString = (emails?: string): string[] => {
  if (!emails) {
    return []
  }

  const cleanEmails: string[] = []
  const lines = emails.split(/\r?\n/)

  lines.forEach((line) => {
    line.split(' ').forEach((email) => {
      const cleaned = email.trim().replace(STUFF_TO_REMOVE, '')

      if (cleaned) {
        cleanEmails.push(cleaned)
      }
    })
  })

  return uniq(cleanEmails)
}
