import { WorkingMessageListStyles } from './WorkingMessageListStyles'
import React from 'react'
import { WorkingMessageListProps } from './types/WorkingMessageListProps'
import { WorkingMessageCard } from 'components/card/WorkingMessageCard'
import moment from 'moment'
import { WORKING_STALE_SECONDS } from 'constants/environmentConstants'

export const WorkingMessageList = ({
  seconds,
  userIds,
}: WorkingMessageListProps): JSX.Element | null => {
  if (userIds.length === 0) {
    return null
  }

  return (
    <ul className={'WorkingMessageList ' + WorkingMessageListStyles}>
      {userIds.map((userId, i) => {
        const staleMoment = moment().subtract(WORKING_STALE_SECONDS, 'seconds')
        const workingOnMoment = seconds[i] ? moment.unix(seconds[i]) : undefined
        const isWorking =
          workingOnMoment && workingOnMoment.isAfter(staleMoment)

        if (isWorking) {
          return (
            <li key={userId}>
              <WorkingMessageCard userId={userId} seconds={seconds[i]} />
            </li>
          )
        }

        return null
      })}
    </ul>
  )
}
