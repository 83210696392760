import { connect } from 'react-redux'
import { UpdateWorkspaceLogoMain } from './UpdateWorkspaceLogoMain'
import { UpdateWorkspaceLogoMainMapState } from './UpdateWorkspaceLogoMainMapState'
import { UpdateWorkspaceLogoMainMapDispatch } from './UpdateWorkspaceLogoMainMapDispatch'
import { UpdateWorkspaceLogoMainMapStateReturnType } from './types/UpdateWorkspaceLogoMainMapStateReturnType'
import { UpdateWorkspaceLogoMainMapDispatchReturnType } from './types/UpdateWorkspaceLogoMainMapDispatchReturnType'
import { UpdateWorkspaceLogoMainConnectedProps } from './types/UpdateWorkspaceLogoMainConnectedProps'

export const UpdateWorkspaceLogoMainConnected = connect<
  UpdateWorkspaceLogoMainMapStateReturnType,
  UpdateWorkspaceLogoMainMapDispatchReturnType,
  UpdateWorkspaceLogoMainConnectedProps>(UpdateWorkspaceLogoMainMapState,
    UpdateWorkspaceLogoMainMapDispatch)(UpdateWorkspaceLogoMain)
