import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskFilterFormConnectedProps } from './types/TaskFilterFormConnectedProps'
import { TaskFilterFormMapStateReturnType } from './types/TaskFilterFormMapStateReturnType'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { getCurrentUserExtension } from 'state/getters/getCurrentUserExtension'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { getTaskFiltersFromState } from 'state/getters/getTaskFiltersFromState'

export const TaskFilterFormMapState = (
  state: StateReducers,
  _props: TaskFilterFormConnectedProps
): TaskFilterFormMapStateReturnType => {
  // TODO: memoize for performance
  const sessionStorage = state.sessionStorage || undefined
  const userExtension = getCurrentUserExtension({ state })
  const defaultTaskFilters = userExtension?.defaultTaskFilters
  const showExtraFilters = sessionStorage?.showExtraFilters
  const team = getCurrentTeam({ state })
  const taskStatuses = team && team.taskStatuses
  const { taskFilters } = getTaskFiltersFromState({ state })
  const tagIdsInitialValue = taskFilters?.tagIds || undefined
  const summaryInitialValue = taskFilters?.summary || undefined
  const taskStatusesInitialValue = taskFilters?.taskStatuses || undefined

  const unsetDefault = !!(
    defaultTaskFilters?.summary ||
    defaultTaskFilters?.tagIds?.length ||
    defaultTaskFilters?.taskStatuses?.length
  )

  return {
    taskStatuses,
    showExtraFilters,
    unsetDefault,
    tagIdsInitialValue:
      tagIdsInitialValue &&
      memoizeFlatArray(
        tagIdsInitialValue,
        'TaskFilterFormMapState-tagIdsInitialValue'
      ),
    summaryInitialValue,
    taskStatusesInitialValue:
      taskStatusesInitialValue &&
      memoizeFlatArray(
        taskStatusesInitialValue,
        'TaskFilterFormMapState-taskStatusesInitialValue'
      ),
  }
}
