import { POST_LOGIN_FAILURE } from 'actions'

import { PostLoginFailureAction } from './types/PostLoginFailureAction'
import { PostLoginRequestActionValues } from './types/PostLoginRequestActionValues'


interface Props {
  requestValues?: PostLoginRequestActionValues
}

export const createPostLoginFailureAction = ({
  requestValues,
}: Props): PostLoginFailureAction => {
  return {
    type: POST_LOGIN_FAILURE,
    requestValues,
  }
}
