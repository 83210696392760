import { applyMiddleware } from 'redux'
import isPlainObject from 'lodash/isPlainObject'

const isFunction = (arg: any) => {
  return typeof arg === 'function'
}

export function configureMockStore (middlewares?: any[]) {
  if (!middlewares) {
    middlewares = []
  }

  return function mockStore (getState?: any) {
    if (!getState) {
      getState = {}
    }

    function mockStoreWithoutMiddleware () {
      let actions: any[] = []
      const listeners: any[] = []

      const self = {
        [Symbol.observable]: (): any => {

        },

        getState () {
          return isFunction(getState) ? getState(actions) : getState
        },

        getActions () {
          return actions
        },

        dispatch (action: any) {
          if (!isPlainObject(action)) {
            throw new Error(
              'Actions must be plain objects. ' +
              'Use custom middleware for async actions.',
            )
          }

          if (typeof action.type === 'undefined') {
            throw new Error(
              'Actions may not have an undefined "type" property. ' +
              'Have you misspelled a constant? ' +
              'Action: ' +
              JSON.stringify(action),
            )
          }

          actions.push(action)

          for (const listener of listeners) {
            listener()
          }

          return action
        },

        clearActions () {
          actions = []
        },

        subscribe (cb: any) {
          if (isFunction(cb)) {
            listeners.push(cb)
          }

          return () => {
            const index = listeners.indexOf(cb)

            if (index < 0) {
              return
            }
            listeners.splice(index, 1)
          }
        },

        replaceReducer (nextReducer: any) {
          if (!isFunction(nextReducer)) {
            throw new Error('Expected the nextReducer to be a function.')
          }
        },
      }

      return self
    }

    const mockStoreWithMiddleware = applyMiddleware(
      ...(middlewares as any[]),
    )(mockStoreWithoutMiddleware)

    const nothing: any = null /* hack for TS */

    return mockStoreWithMiddleware(nothing)
  }
}
