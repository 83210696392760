import {
  // DashboardLayoutConnectedProps,
  DashboardLayoutMapStateReturnType,
} from './types'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsRoutesFromState } from 'state/getters/getIsRoutesFromState'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'
import { getIsBigScreen } from 'state/getters/getIsBigScreen'

export const DashboardLayoutMapState = (
  state: StateReducers
  // props: DashboardLayoutConnectedProps,
): DashboardLayoutMapStateReturnType => {
  const isRoutes = getIsRoutesFromState({ state })
  const isPlanRoute = isRoutes?.isPlanRoute
  const isCurrentRoute = isRoutes?.isCurrentRoute
  const isDashboardHomeRoute = isRoutes?.isDashboardHomeRoute
  const isMediumScreen = getIsMediumScreen({ state })
  const isBigScreen = getIsBigScreen({ state })
  let savedScrollPosition = state.scrollPosition?.toDoPosition || 0

  if (isCurrentRoute) {
    savedScrollPosition = state.scrollPosition?.currentPosition || 0
  }

  if (isPlanRoute) {
    savedScrollPosition = state.scrollPosition?.planPosition || 0
  }

  return {
    isPlanRoute,
    isCurrentRoute,
    isDashboardHomeRoute,
    savedScrollPosition,
    isMediumScreen,
    isBigScreen,
  }
}
