// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // InlineEditorToolBarConnectedProps,
  InlineEditorToolBarMapDispatchReturnType,
} from './types'

export const InlineEditorToolBarMapDispatch = (): // dispatch: Dispatch<Action>,
// props: InlineEditorToolBarConnectedProps,
InlineEditorToolBarMapDispatchReturnType => {
  return {}
}
