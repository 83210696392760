import { StateReducers } from 'state/reducers/types/StateReducers'

import { GoogleSignInButtonConnectProps } from './types/GoogleSignInButtonConnectProps'
import { GoogleSignInButtonMapStateReturnType } from './types/GoogleSignInButtonMapStateReturnType'

export const GoogleSignInButtonMapState = (
  state: StateReducers,
  _props: GoogleSignInButtonConnectProps
): GoogleSignInButtonMapStateReturnType => {
  const showSpinner = state.toggles?.showFormSpinner

  return {
    disableClick: showSpinner,
  }
}
