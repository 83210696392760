import { TeamActionListStyles } from './TeamActionListStyles'
import React from 'react'
import { TeamActionListProps } from './types/TeamActionListProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TEAM_NOUN } from 'constants/english'
import { TEAM_SETTINGS_ROUTE } from 'routes'
import { ActionButton } from 'components/button/ActionButton'
import { SignOutIcon } from 'components/icon/SignOutIcon/SignOutIcon'
import { CogIcon } from 'components/icon/CogIcon/CogIcon'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'

export const TeamActionList = ({
  showSettingsLink,
  teamId,
  leave,
  canLeave,
}: TeamActionListProps): JSX.Element => {
  return (
    <ul
      className={`TeamActionList ${TeamActionListStyles} ${listActionButtonsStyles}`}
    >
      {showSettingsLink && (
        <li>
          <CustomLinkConnected
            title="Settings"
            route={TEAM_SETTINGS_ROUTE}
            teamId={teamId}
            className={`settings ${actionButtonStyles}`}
          >
            <CogIcon /> Settings
          </CustomLinkConnected>
        </li>
      )}

      {canLeave && (
        <li>
          <ActionButton title={`Leave ${TEAM_NOUN}`} onClick={leave}>
            <SignOutIcon /> Leave
          </ActionButton>
        </li>
      )}
    </ul>
  )
}
