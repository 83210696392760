import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { forEach } from 'lodash'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import {
  // WorkingMessageListConnectedProps,
  WorkingMessageListMapStateReturnType,
} from './types'
import { FirebaseTimestamp } from 'sdks'

export const WorkingMessageListMapState = (
  state: StateReducers
  // props: WorkingMessageListConnectedProps,
): WorkingMessageListMapStateReturnType => {
  const task = getCurrentTask({ state })
  const currentUserId = state.currentUserId
  const workingOnMessage = task?.workingOnMessage

  const seconds: number[] = []
  const userIds: string[] = []

  forEach(workingOnMessage, (timestamp, userId) => {
    if (timestamp instanceof FirebaseTimestamp && userId !== currentUserId) {
      seconds.push(timestamp.seconds)
      userIds.push(userId)
    }
  })

  return {
    seconds: memoizeFlatArray(seconds, 'WorkingMessageListMapState-seconds'),
    userIds: memoizeFlatArray(userIds, 'WorkingMessageListMapState-userIds'),
  }
}
