import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'
import {
  UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO,
  UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE,
} from 'constants/environmentConstants'
import { getMaxUploadSizeFromTeamStat } from '../../firebaseFunctions/utilities/get/getMaxUploadSizeFromTeamStat'

interface Props {
  state: StateReducers
  teamId?: string
}

interface Return {
  gigabytesPerUserFree: number
  gigabytesPerUserPro: number
}

export const getMaxUploadSize = ({ state, teamId }: Props): Return => {
  const teamReducer = getCurrentTeamReducer({ state, teamId })

  return getMaxUploadSizeFromTeamStat({
    teamStat: teamReducer?.stats,
    configGigabytesPerUserFree: UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE,
    configGigabytesPerUserPro: UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO,
  })
}
