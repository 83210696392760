import { put } from 'redux-saga/effects'
import { patchUserApi } from 'apis/patch/patchUserApi'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { PatchUserSuccessPayload } from 'firebaseFunctions/user/types/PatchUserSuccessPayload'
import { AxiosResponse } from 'axios'
import { createPatchUsersRequestAction } from 'actionCreators/patch/PatchUsers/createPatchUsersRequestAction'
import { createPatchUsersSuccessAction } from 'actionCreators/patch/PatchUsers/createPatchUsersSuccessAction'
import { createPatchUsersFailureAction } from 'actionCreators/patch/PatchUsers/createPatchUsersFailureAction'
import { PatchUserApiProps } from 'apis/patch/types/PatchUserApiProps'

interface Props extends PatchUserApiProps {}

export const patchUsersSaga = function*({ values, apiHeaders }: Props) {
  yield put(
    createPatchUsersRequestAction({
      requestValues: values,
    })
  )

  try {
    const results: AxiosResponse<PatchUserSuccessPayload> = yield patchUserApi({
      apiHeaders,
      values: values,
    })

    yield put(
      createPatchUsersSuccessAction({
        requestValues: values,
        successValues: results.data,
      })
    )
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'patchUsersSaga',
      })
    )

    yield put(
      createPatchUsersFailureAction({
        requestValues: values,
      })
    )
  }
}
