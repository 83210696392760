import { css } from 'emotion'

import {
  NEUTRAL_BORDER_COLOR_LIGHT,
  PRIMARY_BACKGROUND_COLOR,
} from 'constants/styleConstants'

export const MessageListStyles = css`
  overflow-y: auto;
  overscroll-behavior: none;
  box-shadow: inset 0 4px 7px -8px rgba(0, 0, 0, 0.4),
    inset 0 4px 7px -8px rgba(0, 0, 0, 0.4);
  font-size: 15px;
  position: relative;

  > li {
    margin: 16px 0;
  }

  .beginning {
    text-align: center;
    padding: 32px;
  }

  .ShowMoreButton {
    display: block;
    margin: 8px auto;
  }

  .separator {
    text-align: center;
    font-size: 0.75em;
    border-top: 1px solid ${NEUTRAL_BORDER_COLOR_LIGHT};
    margin: 16px 0;
    line-height: 0;

    .DateSeparatorSpan {
      padding: 8px;
      font-weight: bold;
      background-color: ${PRIMARY_BACKGROUND_COLOR};
    }
  }
`
