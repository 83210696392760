import * as React from 'react'
import { MemberCardConnected } from './MemberCardConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const MemberCardExamples = () => {
  return (
    <div className="MemberCardExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <MemberCardConnected />
      </Provider>
    </div>
  )
}
