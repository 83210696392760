import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { DELETE_TASKS_REQUEST } from 'actions'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { DeleteTasksRequestAction } from 'actionCreators/delete/DeleteTasks/types/DeleteTasksRequestAction'
import { createDeleteTasksSuccessAction } from 'actionCreators/delete/DeleteTasks/createDeleteTasksSuccessAction'
import { createDeleteTasksFailureAction } from 'actionCreators/delete/DeleteTasks/createDeleteTasksFailureAction'
import { deleteTask } from '../../firebaseFunctions/utilities/ref/delete/deleteTask'

export const deleteTasksRequestF = function*() {
  while (1) {
    const deleteTasksRequestAction: DeleteTasksRequestAction = yield take([
      DELETE_TASKS_REQUEST,
    ])

    const { requestValues } = deleteTasksRequestAction
    const { key: taskId, item: firstTask } = getObjectFirstItem(
      requestValues.items
    )

    if (taskId && firstTask) {
      try {
        const { teamId } = firstTask
        yield deleteTask({ firestore, teamId, taskId })

        yield put(
          createDeleteTasksSuccessAction({
            requestValues,
            description: 'deleteTasksRequestF',
            successValues: {
              [taskId]: firstTask,
            },
          })
        )
      } catch (error) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'deleteTasksRequestF',
          })
        )

        yield put(
          createDeleteTasksFailureAction({
            requestValues,
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({ description: 'deleteTasksRequestF' })
      )
    }
  }
}
