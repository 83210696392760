import { take, put, select, spawn } from 'redux-saga/effects'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { MEMBERS_ROUTE } from 'routes'
import { SUBMIT_DELETE_INVITE, SUBMIT_DELETE_INVITE_DONE } from 'actions'
import { deleteInvitesSaga } from 'flows/sagas/delete/deleteInvitesSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { InvitesReducer } from 'state/reducers/types/InvitesReducer'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteInviteF = function*() {
  while (1) {
    const submitAction: SubmitAction = yield take([SUBMIT_DELETE_INVITE])
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state })
    const teamId = query && query.teamId
    const inviteId = submitAction.values.inviteId || (query && query.inviteId)
    const invites = state.invites || undefined
    const invite = invites && inviteId && invites[inviteId]

    if (invite && inviteId) {
      const items: InvitesReducer = {
        [inviteId]: invite,
      }

      yield spawn(deleteInvitesSaga, { items })

      yield put(
        createHistoryAction({
          description: 'submitDeleteInviteF',
          pathname: MEMBERS_ROUTE,
          query: {
            teamId,
          },
          method: 'replace',
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteInviteF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_INVITE_DONE,
    })
  }
}
