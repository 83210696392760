import { StateReducers }  from 'state/reducers/types/StateReducers'
import { getCurrentTask } from './getCurrentTask'

interface Props {
  state: StateReducers
  taskId: string
}

export const getSortedTagIds = ({ state, taskId }: Props): string[] => {
  const task = getCurrentTask({ state, taskId })
  const tags = task?.tags
  const tagIds = (tags && Object.keys(tags)) || []

  const sortedTagIds = tagIds.sort((a, b) => {
    const tagA = tags && tags[a]
    const tagB = tags && tags[b]

    if (tagA && tagB) {
      if (tagA.type === 'label') {
        return -1
      }

      return 1
    }

    return 0
  })

  return sortedTagIds
}
