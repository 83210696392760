import { css } from 'emotion'

export const UpdateMessageEditorStyles = css`
  .avatarEditor {
    display: flex;
  }

  .DraftEditor {
    flex-grow: 1;
    margin-left: 16px;
  }

  .EmojiDropdown {
    .DropdownButton {
      padding: 0 8px;
    }

    .Dropdown {
      > .chevronContainer {
        left: 7px;
        top: 24px;
        transform: initial;
      }

      .container {
        top: 31px;
        left: 4px;
      }
    }

    .emoji-mart-search {
      display: none; /* because of editor focusing */
    }
  }
`
