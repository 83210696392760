import { take, put, race } from 'redux-saga/effects'
import { createPostUserRequestAction } from 'actionCreators/post/PostUser/createPostUserRequestAction'
import { createPostLoginRequestAction } from 'actionCreators/post/PostLogin/createPostLoginRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import {
  SUBMIT_USER,
  SUBMIT_USER_DONE,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  POST_USER_CANCEL,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_LOGIN_CANCEL,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitNewUserF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_USER])
    const { email, password, displayName } = action.values

    if (email && password && displayName) {
      const postUserRequestAction = createPostUserRequestAction({
        requestValues: {
          email,
          password,
          displayName,
        },
      })

      yield put(postUserRequestAction)

      const postUserResult = yield race({
        success: take(POST_USER_SUCCESS),
        failureOrCancel: take([POST_USER_FAILURE, POST_USER_CANCEL]),
      })

      if (postUserResult.success) {
        const postLoginRequestAction = createPostLoginRequestAction({
          requestValues: {
            email,
            password,
          },
        })

        yield put(postLoginRequestAction)

        yield race({
          success: take(POST_LOGIN_SUCCESS),
          failureOrCancel: take([POST_LOGIN_FAILURE, POST_LOGIN_CANCEL]),
        })
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewUserF',
        })
      )
    }

    yield put({
      type: SUBMIT_USER_DONE,
    })
  }
}
