import React, { useState, useCallback } from 'react'
import { FeedbackFormProps } from './types/FeedbackFormProps'
import { TextInputField } from '../../field/TextInputField/TextInputField'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'
import { FeedbackFormStyles } from './FeedbackFormStyles'
import { firestore, clientServerTimestamp } from 'sdks'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { setFeedback } from '../../../firebaseFunctions/utilities/ref/set/setFeedback'
import { FEEDBACK_TEAM_ID } from '../../../constants/environmentConstants'
import { createId } from '../../../firebaseFunctions/utilities/createId'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'
import { textSmallStyles } from '../../../styles/text/textSmallStyles'
import { CatIcon } from '../../icon/CatIcon/CatIcon'
import { textBiggerStyles } from '../../../styles/text/textBiggerStyles'
import { textPrimaryColorStyles } from '../../../styles/text/textPrimaryColorStyles'
import { textAlignRight } from '../../../styles/text/textAlignRight'
import { textAlignCenter } from '../../../styles/text/textAlignCenter'

export const FeedbackForm = ({
  className = '',
  showSubmitted = false,
}: FeedbackFormProps): JSX.Element => {
  const [summaryState, setSummaryState] = useState('')
  const [showSubmittedState, setShowSubmittedState] = useState(showSubmitted)
  const [errorState, setErrorState] = useState<string | null>(null)

  const validateSummary = useCallback(() => {
    const trimmed = (summaryState || '').trim()
    const error = !trimmed ? 'Please enter a summary.' : null
    setErrorState(error)

    return error
  }, [summaryState])

  const submit = useCallback(
    async (summary) => {
      const feedbackId = createId()
      setFeedback({
        firestore,
        teamId: FEEDBACK_TEAM_ID,
        feedbackId,
        feedback: {
          created: clientServerTimestamp(),
          summary,
        },
      })

      setShowSubmittedState(true)
    },
    [setShowSubmittedState]
  )

  return (
    <form
      className={`FeedbackForm ${FeedbackFormStyles} ${className}`}
      onSubmit={(e) => {
        e.preventDefault()
        const error = validateSummary()

        if (!error) {
          submit({ summary: summaryState })
        }
      }}
    >
      {showSubmittedState && (
        <div className={textAlignCenter}>
          <CatIcon
            className={`${textBiggerStyles} ${textPrimaryColorStyles}`}
          />
          <p className={`${textBoldStyles} ${textPrimaryColorStyles}`}>
            Thanks for your time
          </p>
          <p>Your feedback improves EggKat.</p>
          <p className={`${marginTopMediumStyles} ${textSmallStyles}`}>
            The EggKat team
          </p>
        </div>
      )}
      {!showSubmittedState && (
        <>
          <TextInputField
            type="textarea"
            value={summaryState}
            placeholder="Feedback about this page?"
            autoFocus={true}
            error={errorState}
            onChange={(e) => {
              setSummaryState(e.target.value)
            }}
            className={errorState ? 'hasError' : ''}
            onFocus={() => {
              setErrorState(null)
            }}
          />

          <footer className={textAlignRight}>
            <button
              type="submit"
              className={`${actionButtonStyles} ${marginTopMediumStyles}`}
            >
              Submit
            </button>
          </footer>
        </>
      )}
    </form>
  )
}
