import { css } from 'emotion'
import { TEXT_COLOR_LIGHTER } from 'constants/styleConstants'

export const UploadedFileCardStyles = css`
  position: relative;
  padding: 16px;

  .file,
  .uploaded {
    margin-top: 8px;
  }

  .label {
    color: ${TEXT_COLOR_LIGHTER};
    font-size: 0.75em;
    text-transform: capitalize;
  }

  .UploadedFileDropdown {
    position: absolute;
    right: 0px;
    top: 8px;
  }
`
