// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // MessageFileCardConnectedProps,
  MessageFileCardMapDispatchReturnType,
} from './types'

export const MessageFileCardMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessageFileCardConnectedProps,
): MessageFileCardMapDispatchReturnType => {
  return {
  }
}
