import { Invite } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
  inviteId: string
}

export const getCurrentInvite = ({ state, inviteId }: Props): Invite | undefined => {
  return state.invites?.[inviteId]?.data
}
