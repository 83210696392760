import { Dispatch, Action } from 'redux'
import { ToBacklogButtonMapDispatchReturnType } from './types/ToBacklogButtonMapDispatchReturnType'
import { createSubmitAction } from '../../../actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_MOVE_TASK_TO_CYCLE } from '../../../actions'

export const ToBacklogButtonMapDispatch = (
  dispatch: Dispatch<Action>
): ToBacklogButtonMapDispatchReturnType => {
  return {
    moveToBackLog: (taskId) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_MOVE_TASK_TO_CYCLE,
          values: {
            cycle: 'backlog',
            taskId,
          },
        })
      )
    },
  }
}
