import { storageSdk } from 'sdks'
import { getMessageStoragePath } from 'firebaseFunctions/utilities/get/getMessageStoragePath'

interface Props {
  teamId: string
  taskId: string
  messageId: string
}

export const deleteMessageFiles = async ({
  teamId,
  taskId,
  messageId,
}: Props): Promise<void> => {
  const storagePath = getMessageStoragePath({ teamId, taskId, messageId })

  const ref = await storageSdk()
    .ref()
    .child(storagePath)
    .listAll()

  ref.items.forEach((itemRef) => {
    itemRef.delete()
  })
}
