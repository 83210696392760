import { take, put, race } from 'redux-saga/effects'
import { RESET_PASSWORD_SENT_ROUTE } from 'routes'
import { Race } from 'types/Race'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import {
  SUBMIT_RESET_PASSWORD,
  SUBMIT_RESET_PASSWORD_DONE,
  POST_RESET_PASSWORD_SUCCESS,
  POST_RESET_PASSWORD_FAILURE,
  POST_RESET_PASSWORD_CANCEL,
} from 'actions'
import { createPostResetPasswordRequestAction } from 'actionCreators/post/PostResetPassword/createPostResetPasswordRequestAction'
import { PostResetPasswordSuccessAction } from 'actionCreators/post/PostResetPassword/types/PostResetPasswordSuccessAction'
import { PostResetPasswordFailureAction } from 'actionCreators/post/PostResetPassword/types/PostResetPasswordFailureAction'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitResetPasswordF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_RESET_PASSWORD])
    const { email } = action.values

    if (email) {
      const postResetPasswordAction = createPostResetPasswordRequestAction({
        requestValues: {
          email,
        },
      })

      yield put(postResetPasswordAction)

      const postResult: Race<
        PostResetPasswordSuccessAction,
        PostResetPasswordFailureAction,
        any
      > = yield race({
        success: take(POST_RESET_PASSWORD_SUCCESS),
        failure: take(POST_RESET_PASSWORD_FAILURE),
        cancel: take(POST_RESET_PASSWORD_CANCEL),
      })

      if (postResult.success) {
        yield put(
          createHistoryAction({
            description: 'submitResetPasswordF',
            pathname: RESET_PASSWORD_SENT_ROUTE,
            method: 'push',
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitResetPasswordF',
        })
      )
    }

    yield put({
      type: SUBMIT_RESET_PASSWORD_DONE,
    })
  }
}
