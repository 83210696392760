import { CustomLinkConnectedProps } from './types/CustomLinkConnectedProps'
import { CustomLinkMapStateReturnType } from './types/CustomLinkMapStateReturnType'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { createLocation } from 'utilities/create/createLocation'
import { UrlQuery } from 'types/UrlQuery'

export const CustomLinkMapState = (
  state: StateReducers,
  props: CustomLinkConnectedProps
): CustomLinkMapStateReturnType => {
  const { currentLocation } = state
  const { route, withCurrentQuery, withReturn } = props
  const query = withCurrentQuery && currentLocation && currentLocation.query

  const returnPath =
    currentLocation && withReturn
      ? currentLocation.pathname
      : query
      ? query.returnPath
      : undefined

  const returnQuery =
    currentLocation && withReturn
      ? currentLocation.queryString
      : query
      ? query.returnQuery
      : undefined

  const mergedQuery: UrlQuery = {
    ...query,
    returnPath,
    returnQuery,
    ...props,
  }

  const { search } = createLocation({ pathname: route, query: mergedQuery })
  const to = `${route}?${search}`

  return {
    to,
  }
}
