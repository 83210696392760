import React from 'react'
import { ToggleOnIconProps } from './types/ToggleOnIconProps'
import { iconStyles } from 'styles/iconStyles'

export const ToggleOnIcon = ({ className }: ToggleOnIconProps): JSX.Element => {
  return (
    <svg
      className={`ToggleOnIcon ${className} ${iconStyles}`}
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M384 64H192C86 64 0 150 0 256s86 192 192 192h192c106 0 192-86 192-192S490 64 384 64zm0 320c-70.8 0-128-57.3-128-128 0-70.8 57.3-128 128-128 70.8 0 128 57.3 128 128 0 70.8-57.3 128-128 128z"
      ></path>
    </svg>
  )
}
