import { put } from 'redux-saga/effects'
import { patchCustomClaimApi } from 'apis/patch/patchCustomClaimApi'
import { PatchCustomClaimApiProps } from 'apis/patch/types/PatchCustomClaimApiProps'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { PatchCustomClaimSuccessPayload } from 'firebaseFunctions/customClaim/types'
import { AxiosResponse } from 'axios'
import { createPatchCustomClaimRequestAction } from 'actionCreators/patch/PatchCustomClaim/createPatchCustomClaimRequestAction'
import { createPatchCustomClaimSuccessAction } from 'actionCreators/patch/PatchCustomClaim/createPatchCustomClaimSuccessAction'
import { createPatchCustomClaimFailureAction } from 'actionCreators/patch/PatchCustomClaim/createPatchCustomClaimFailureAction'

interface Props extends PatchCustomClaimApiProps {
  currentUser: firebase.User
}

export const patchCustomClaimSaga = function*({
  values,
  apiHeaders,
  currentUser,
}: Props) {
  yield put(
    createPatchCustomClaimRequestAction({
      requestValues: values,
    })
  )

  try {
    const results: AxiosResponse<PatchCustomClaimSuccessPayload> = yield patchCustomClaimApi(
      {
        apiHeaders,
        values: values,
      }
    )

    // custom claim was updated so we need to refresh the token
    yield currentUser.getIdTokenResult(true)

    yield put(
      createPatchCustomClaimSuccessAction({
        description: 'patchCustomClaimSaga',
        requestValues: values,
        successValues: results.data,
      })
    )
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'patchCustomClaimSaga',
      })
    )

    yield put(
      createPatchCustomClaimFailureAction({
        requestValues: values,
      })
    )
  }
}
