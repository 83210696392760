import { connect } from 'react-redux'
import { Recaptcha } from './Recaptcha'
import { RecaptchaMapState } from './RecaptchaMapState'
import { RecaptchaMapDispatch } from './RecaptchaMapDispatch'

import { RecaptchaMapStateReturnType } from './types/RecaptchaMapStateReturnType'
import { RecaptchaMapDispatchReturnType } from './types/RecaptchaMapDispatchReturnType'
import { RecaptchaConnectedProps } from './types/RecaptchaConnectedProps'


export const RecaptchaConnected = connect<
  RecaptchaMapStateReturnType,
  RecaptchaMapDispatchReturnType,
  RecaptchaConnectedProps>(RecaptchaMapState,
    RecaptchaMapDispatch)(Recaptcha)
