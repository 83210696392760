import * as React from 'react'
import { GoogleSignInButtonConnected } from './GoogleSignInButtonConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const GoogleSignInButtonExamples = () => {
  return (
    <div className="GoogleSignInButtonExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <GoogleSignInButtonConnected />
      </Provider>
    </div>
  )
}
