import { postLoginRequestF } from './postLoginRequestF'
import { postUserRequestF } from './postUserRequestF'
import { postResetPasswordRequestF } from './postResetPasswordRequestF'
import { postReLoginRequestF } from './postReLoginRequestF'

// order doesnt matter
export const postFlows = [
  postLoginRequestF,
  postUserRequestF,
  postResetPasswordRequestF,
  postReLoginRequestF,
]
