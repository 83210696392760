import { GET_PLANS_SUCCESS } from 'actions'

import { GetPlansSuccessAction } from './types/GetPlansSuccessAction'
import { GetPlansSuccessActionValues } from './types/GetPlansSuccessActionValues'
import { GetPlansRequestActionValues } from './types/GetPlansRequestActionValues'


interface Props {
  successValues: GetPlansSuccessActionValues
  requestValues?: GetPlansRequestActionValues
}

export const createGetPlansSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetPlansSuccessAction => {
  return {
    type: GET_PLANS_SUCCESS,
    requestValues,
    successValues,
  }
}
