import { Dispatch, Action } from 'redux'
import { Task } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { SingleTaskWatcherConnectedProps } from './types/SingleTaskWatcherConnectedProps'
import { SingleTaskWatcherMapDispatchReturnType } from './types/SingleTaskWatcherMapDispatchReturnType'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createUnavailableTaskAction } from 'actionCreators/createUnavailableTaskAction'
import { createGetTasksSuccessAction } from 'actionCreators/get/GetTasks/createGetTasksSuccessAction'

export const SingleTaskWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: SingleTaskWatcherConnectedProps
): SingleTaskWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'SingleTaskWatcherMapDispatch',
          increment,
          watcherType: 'singleTask',
        })
      )
    },

    onNext: (docSnapshot: firebase.firestore.DocumentSnapshot) => {
      const task = docSnapshot.data() as Task | undefined

      if (task) {
        dispatch(
          createGetTasksSuccessAction({
            successValues: {
              items: {
                [docSnapshot.id]: {
                  data: task,
                  teamId: props.teamId,
                },
              },
            },
            description: 'SingleTaskWatcherMapDispatch',
          })
        )
      } else {
        dispatch(
          createUnavailableTaskAction({
            taskId: props.taskId,
          })
        )
      }
    },

    onFirebaseError: (error: FirebaseError) => {
      const firestoreErrorAction = createFirebaseErrorAction({
        error,
        description: 'SingleTaskWatcherMapDispatch',
      })

      dispatch(firestoreErrorAction)
    },
  }
}
