import { MENTION_REGEX_G, MENTION_MARKER } from 'constants/regexConstants'

export const getMentionUserIds = (description: string) => {
  const userIds: string[] = []

  description.replace(MENTION_REGEX_G, (match, _displayName, userId) => {
    const isNewMention = userId.indexOf(MENTION_MARKER) === -1

    if (isNewMention) {
      userIds.push(userId)
    }

    return match
  })

  return userIds
}
