import { firebaseSdk } from './firebaseSdk'

export const getIdToken = async (): Promise<string | undefined> => {
  const currentUser = firebaseSdk.auth().currentUser

  if (currentUser) {
    return await currentUser.getIdToken()
  }

  return undefined
}
