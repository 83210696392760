import { Dispatch, Action } from 'redux'
import { TagSelectOption } from 'types/TagSelectOption'

import { createToggleAction } from 'actionCreators/createToggleAction'

import { SelectTagFieldConnectedProps } from './types/SelectTagFieldConnectedProps'
import { SelectTagFieldMapDispatchReturnType } from './types/SelectTagFieldMapDispatchReturnType'

import { createSubmitTaskTagAction } from 'actionCreators/submit/SubmitTaskTag'

export const SelectTagFieldMapDispatch = (
  dispatch: Dispatch<Action>,
  props: SelectTagFieldConnectedProps
): SelectTagFieldMapDispatchReturnType => {
  return {
    handleChange: (option: TagSelectOption) => {
      dispatch(
        createToggleAction({
          description: 'SelectTagFieldMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )

      dispatch(
        createSubmitTaskTagAction({
          description: 'SelectTagFieldMapDispatch',
          values: {
            id: option.value,
            taskId: props.taskId,
            displayName: option.label || '(no name)',
            type: option.type,
          },
        })
      )
    },
  }
}
