import { Notification } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentUserExtension } from './getCurrentUserExtension'

interface Props {
  state: StateReducers
}

export const getMessageNotifications = ({ state }: Props): Notification[] => {
  const userExtension = getCurrentUserExtension({ state })
  const messageNotifications = userExtension?.messageNotifications || []

  return messageNotifications
}
