import { css } from 'emotion'
import { BORDER_RADIUS, TEXT_COLOR_LIGHTER } from 'constants/styleConstants'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const StripeSubscriptionCardStyles = css`
  padding: 16px;
  border-radius: ${BORDER_RADIUS};
  background-color: white;
  position: relative;

  &.inactive {
    color: ${TEXT_COLOR_LIGHTER};
  }

  .StripeSubscriptionDropdown {
    position: absolute;
    top: 4px;
    right: 8px;
  }
`
