import { FileUploadFieldStyles } from './FileUploadFieldStyles'
import React, { useState, useCallback } from 'react'
import { FileUploadFieldProps } from './types/FileUploadFieldProps'
import { Button } from 'components/button/Button'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { FileUploadIcon } from 'components/icon/FileUploadIcon/FileUploadIcon'

export const FileUploadField = ({
  onlyImages,
  onValid,
  children,
  maxBytes,
  className = '',
  style,
}: FileUploadFieldProps): JSX.Element => {
  const [error, setError] = useState('')

  const onChangeCached = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files

      if (files && files.length > 0) {
        const firstFile = files[0]
        let error = ''

        if (maxBytes !== undefined && firstFile.size > maxBytes) {
          error = `Max file size is ${maxBytes / 1000000}MB`
        }

        if (onlyImages && firstFile.type.indexOf('image/') === -1) {
          error = 'Please select an image file.'
        }

        setError(error)

        if (!error) {
          onValid?.(Array.from(files))
        }
      }
    },
    [onlyImages, onValid, maxBytes]
  )

  return (
    <div
      className={`FileUploadField ${FileUploadFieldStyles} ${className}`}
      style={style}
    >
      <div className="clickableContainer">
        {!children && (
          <Button title="Upload file">
            <FileUploadIcon />
          </Button>
        )}

        {children}

        <input
          type="file"
          accept={onlyImages ? 'image/*' : '*'}
          onChange={onChangeCached}
        />
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}
