import { CountBubbleWidgetStyles } from './CountBubbleWidgetStyles'
import React from 'react'
import { CountBubbleWidgetProps } from './types/CountBubbleWidgetProps'
import { flexCenterCenterStyles } from '../../../styles/flex/flexCenterCenterStyles'

export const CountBubbleWidget = ({
  children,
}: CountBubbleWidgetProps): JSX.Element => {
  return (
    <div
      className={`CountBubbleWidget ${flexCenterCenterStyles} ${CountBubbleWidgetStyles}`}
    >
      {children}
    </div>
  )
}
