// import { DeleteTeamLogoButtonStyles } from './DeleteTeamLogoButtonStyles'
import React, { useCallback } from 'react'
import { DeleteTeamLogoButtonProps } from './types/DeleteTeamLogoButtonProps'
import { DeleteAndConfirmWidget } from '../../widget/DeleteAndConfirmWidget'

export const DeleteTeamLogoButton = ({
  teamId,
  deleteFile,
  logoUrl,
}: DeleteTeamLogoButtonProps): JSX.Element => {
  const onDeleteClickCallback = useCallback(() => {
    if (teamId) {
      deleteFile(teamId)
    }
  }, [teamId, deleteFile])

  return (
    <>
      {logoUrl && (
        <DeleteAndConfirmWidget onDeleteClick={onDeleteClickCallback} />
      )}
    </>
  )
}
