import { CSSProperties } from 'react'
import { getVisibleSelectionRect } from 'draft-js'

interface GetSuggestionsCenterPosition {
  props: {
    open: boolean
    suggestions: any[]
  }
}

export const getSuggestionsCenterPosition = ({
  props,
}: GetSuggestionsCenterPosition): CSSProperties => {
  let transform
  let transition
  const itemHeight = 34
  const selectionRect = getVisibleSelectionRect(window) as DOMRect | undefined

  if (props.open && props.suggestions.length > 0) {
    transform = 'scaleY(1)'
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)'
  } else if (props.open) {
    transform = 'scaleY(0)'
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)'
  }

  var width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  var height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )

  const selectionTop = selectionRect?.top || width / 2
  const selectionLeft = selectionRect?.left || height / 2
  const suggestionHeight = itemHeight * props.suggestions.length + 40
  const isSelectionOnRightHalf = selectionLeft > width / 2

  return {
    transform,
    transition,
    width: '200px',
    left: isSelectionOnRightHalf ? undefined : `${selectionLeft}px`,
    right: isSelectionOnRightHalf ? `${width - selectionLeft}px` : undefined,
    position: 'fixed',
    // top: isCoveringSelection ? undefined : `${top}px`,
    bottom: `${height - selectionTop + 16}px`,
    height: `${suggestionHeight}px`,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    overflowY: 'scroll',
  }
}
