import { patchUserExtensionSaga } from './../sagas/patch/patchUserExtensionSaga'
import { take, delay, race, spawn } from 'redux-saga/effects'
import { PATCH_USER_EXTENSION_REQUEST_DELAY } from 'actions'
import { UserExtension } from 'firebaseFunctions/types'
import { PatchUserExtensionRequestAction } from 'actionCreators/patch/PatchUserExtension/types/PatchUserExtensionRequestAction'

// Can be useful to prevent too many writes

export const patchUserExtensionRequestDelayF = function*() {
  while (1) {
    let combinedUserExtension: UserExtension = {}

    const patchUserExtensionRequestAction: PatchUserExtensionRequestAction = yield take(
      [PATCH_USER_EXTENSION_REQUEST_DELAY]
    )

    const { requestValues } = patchUserExtensionRequestAction
    const { item, params } = requestValues
    const { teamId, userId } = params
    combinedUserExtension = item

    while (1) {
      const raceResult = yield race({
        anotherPatch: take([PATCH_USER_EXTENSION_REQUEST_DELAY]),
        timeout: delay(100),
      })

      if (raceResult.anotherPatch) {
        const anotherPatchAction: PatchUserExtensionRequestAction =
          raceResult.anotherPatch
        const anotherPatchActionParams = anotherPatchAction.requestValues.params
        const isSameParams =
          teamId === anotherPatchActionParams.teamId &&
          userId === anotherPatchActionParams.userId

        if (isSameParams) {
          combinedUserExtension = {
            ...combinedUserExtension,
            ...anotherPatchAction.requestValues.item,
          }
        }
      } else if (teamId && userId) {
        yield spawn(patchUserExtensionSaga, {
          userExtension: combinedUserExtension,
          teamId,
          userId,
        })

        break
      }
    }
  }
}
