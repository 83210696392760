import { Invite } from '../../../types'
import { FirestoreClient } from '../../../types'
import { getInviteRef } from '../get/getInviteRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  inviteId: string
  invite: Invite
}

export const updateInvite = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  inviteId,
  invite,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getInviteRef({
    firestore,
    teamId,
    inviteId,
  }).update(invite) as any
}
