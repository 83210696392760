import { getViewPortWidth } from 'utilities/getViewPortWidth'

export const autoAdjustDropdown = (
  element: HTMLDivElement,
  isMediumScreen?: boolean
) => {
  const { body, documentElement } = document
  const windowWidth = getViewPortWidth()

  element.style.left = ''
  element.style.width = ''
  element.style.display = ''
  element.style.justifyContent = ''

  if (isMediumScreen) {
    const box = element.getBoundingClientRect()
    const overFlowRight = box.right - windowWidth
    const overFlowLeft = box.left < 0

    if (overFlowRight > 0) {
      element.style.left = 'initial'
      element.style.right = '0px'
    }

    if (overFlowLeft) {
      element.style.left = '0px'
      element.style.right = 'initial'
    }
  } else {
    element.style.left = '0px' // need to reset before getBoundingClientRect  in order to calculate leftOffset

    const boxAtZeroLeft = element.getBoundingClientRect()
    const scrollLeft =
      window.pageXOffset || documentElement.scrollLeft || body.scrollLeft
    const clientLeft = documentElement.clientLeft || body.clientLeft || 0
    const leftOffset = boxAtZeroLeft.left + scrollLeft - clientLeft

    element.style.left = -Math.round(leftOffset) + 'px'
    element.style.width = '100vw'
  }
}
