import { Dispatch, Action } from 'redux'
import { NewTeamFormMapDispatchReturnType } from './types/NewTeamFormMapDispatchReturnType'
import { SUBMIT_NEW_TEAM } from 'actions'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const NewTeamFormMapDispatch = (
  dispatch: Dispatch<Action>
): NewTeamFormMapDispatchReturnType => {
  return {
    submit: ({ displayName }) => {
      dispatch(
        createSubmitAction({
          values: { displayName },
          description: 'NewTeamFormMapDispatch',
          type: SUBMIT_NEW_TEAM,
        })
      )
    },
  }
}
