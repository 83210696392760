import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // MessageFileListConnectedProps,
  MessageFileListMapStateReturnType,
} from './types'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'

export const MessageFileListMapState = (
  state: StateReducers
  // props: MessageFileListConnectedProps,
): MessageFileListMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const teamId = getCurrentTeamId({ state })

  return {
    taskId,
    teamId,
  }
}
