import { QuickSearchFieldStyles } from './QuickSearchFieldStyles'
import React, { useCallback, useState, useEffect } from 'react'
import { QuickSearchFieldProps } from './types/QuickSearchFieldProps'
import { SelectInput } from 'components/input/SelectInput'
import { SelectOption } from 'types/SelectOption'
import { getTasksRef } from '../../../firebaseFunctions/utilities/ref/get/getTasksRef'
import { firestore } from 'sdks'
import { TaskQueryDocumentSnapshot } from '../../../types/TaskQueryDocumentSnapshot'
import { Task, GenericObject } from '../../../firebaseFunctions/types'
import { map } from 'lodash'
import { FirebaseTimestamp } from '../../../sdks'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'
import { paddingMediumStyles } from '../../../styles/padding/paddingMediumStyles'
import { flexCenterCenterStyles } from '../../../styles/flex/flexCenterCenterStyles'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'
import { Spinner } from '../../widget/Spinner'

interface TaskOption {
  summary: string
}

// TODO: come back and review this code and test how many time it renders
export const QuickSearchField = ({
  taskIds,
  summaryItems,
  onTaskSelect,
  autoFocus,
  teamId,
  lowestWatchedTaskCreatedSeconds,
  lowestWatchedTaskCreatedNanoSeconds,
  taskPage = 1,
  pageSize,
  handleError,
}: QuickSearchFieldProps): JSX.Element => {
  const [optionMap, setOptionMap] = useState<GenericObject<TaskOption>>({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [taskPageState, setTaskPageState] = useState(taskPage)
  const [
    lastDocState,
    setLastDocState,
  ] = useState<TaskQueryDocumentSnapshot | null>(null)
  const [noMoreTasksState, setNoMoreTasksState] = useState(false)

  const options: SelectOption[] | undefined = map(
    optionMap,
    ({ summary }, taskId) => {
      return {
        label: summary || '',
        value: taskId,
      }
    }
  )

  useEffect(() => {
    setOptionMap((currentOptionMap) => {
      const newOptionMap: GenericObject<TaskOption> = {}

      taskIds?.forEach((taskId, i) => {
        newOptionMap[taskId] = { summary: summaryItems?.[i] || '' }
      })

      return { ...currentOptionMap, ...newOptionMap }
    })
  }, [summaryItems, taskIds])

  const loadMore = useCallback(async () => {
    if (teamId) {
      let startAfter

      if (lastDocState) {
        startAfter = lastDocState
      } else if (
        lowestWatchedTaskCreatedSeconds &&
        lowestWatchedTaskCreatedNanoSeconds &&
        taskPageState === 1
      ) {
        startAfter = new FirebaseTimestamp(
          lowestWatchedTaskCreatedSeconds,
          lowestWatchedTaskCreatedNanoSeconds
        )
      }

      setShowSpinner(true)

      try {
        let query = await getTasksRef({ firestore, teamId })
          .orderBy('created', 'desc')
          .startAfter(startAfter)
          .limit(pageSize)
          .get()

        setShowSpinner(false)
        setTaskPageState((currentTaskPage) => {
          return currentTaskPage + 1
        })

        const lastVisibleDoc = query.docs[query.docs.length - 1]
        setLastDocState(lastVisibleDoc)
        setOptionMap((currentOptionMap) => {
          const newOptionMap: GenericObject<TaskOption> = {}
          query.docs.forEach((doc) => {
            const task = doc.data() as Task | undefined

            if (task && task.summary) {
              newOptionMap[doc.id] = { summary: task.summary }
            }
          })

          return { ...currentOptionMap, ...newOptionMap }
        })

        if (!query.metadata.fromCache) {
          const noMoreItems = query.docs.length < pageSize

          if (noMoreItems) {
            setNoMoreTasksState(true)
          }
        }
      } catch (error) {
        handleError(error)
      }
    }
  }, [
    teamId,
    setNoMoreTasksState,
    setLastDocState,
    lastDocState,
    lowestWatchedTaskCreatedSeconds,
    lowestWatchedTaskCreatedNanoSeconds,
    handleError,
    taskPageState,
    pageSize,
  ])

  const NoOptionsMessage = useCallback(() => {
    return (
      <div
        className={`${paddingMediumStyles} ${flexCenterCenterStyles}`}
        style={{ flexDirection: 'column' }}
      >
        {noMoreTasksState && <p>No more pages</p>}
        {!noMoreTasksState && (
          <>
            <p>No results on this page</p>
            <button
              type="button"
              className={`${actionButtonStyles} ${marginTopSmallStyles}`}
              disabled={showSpinner}
              onClick={() => {
                loadMore()
              }}
            >
              {showSpinner && <Spinner />}
              {!showSpinner && `Search page ${taskPageState + 1}`}
            </button>
          </>
        )}
      </div>
    )
  }, [loadMore, noMoreTasksState, showSpinner, taskPageState])

  return (
    <SelectInput
      options={options}
      components={{
        NoOptionsMessage,
      }}
      autoFocus={autoFocus}
      isSearchable={true}
      className={'QuickSearchField ' + QuickSearchFieldStyles}
      placeholder="Quick search"
      filterOption={(option, searchText) => {
        const summaryFilterRegexListMutable: RegExp[] | null = searchText
          ? []
          : null

        searchText
          ?.trim()
          .split(' ')
          .map((summaryPart) => {
            if (summaryPart.length) {
              summaryFilterRegexListMutable?.push(new RegExp(summaryPart, 'i'))
            }
          })

        const noneMatch = summaryFilterRegexListMutable?.every(
          (summaryFilterRegex) => {
            return !option.label.match(summaryFilterRegex)
          }
        )

        return !noneMatch
      }}
      onChange={(option) => {
        if (option && !Array.isArray(option)) {
          onTaskSelect(option.value)
        }
      }}
    />
  )
}
