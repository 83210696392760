import { RoleTypes } from 'firebaseFunctions/types'
import { roleDescriptions } from './roleDescriptions'

export const roleOptions = [{
  label: roleDescriptions[RoleTypes.Writer],
  value: RoleTypes.Writer,
}, {
  label: roleDescriptions[RoleTypes.Reader],
  value: RoleTypes.Reader,
}, {
  label: roleDescriptions[RoleTypes.Owner],
  value: RoleTypes.Owner,
}]
