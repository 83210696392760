import { BillingMainStyles } from './BillingMainStyles'
import React from 'react'
// import { BillingMainProps } from './types/BillingMainProps'
import { StripeSubscriptionMainConnected } from '../StripeSubscriptionMain'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'

export const BillingMain = (): JSX.Element => {
  return (
    <div className={`BillingMain ${BillingMainStyles}`}>
      <HeaderOneTextConnected>My Subscriptions</HeaderOneTextConnected>

      <StripeSubscriptionMainConnected />
    </div>
  )
}
