import {
  TEAMS_COLLECTION,
  USER_EXTENSIONS_COLLECTION,
} from '../../../constants'
import { FirestoreClient, UserExtension } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  userId: string
}

export const getUserExtensionRef = <
  T extends FirestoreClient | FirestoreAdmin
>({
  firestore,
  teamId,
  userId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<UserExtension>
  : FirebaseFirestore.DocumentReference => {
  return firestore
    .collection(TEAMS_COLLECTION)
    .doc(teamId)
    .collection(USER_EXTENSIONS_COLLECTION)
    .doc(userId) as any
}
