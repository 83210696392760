import { connect } from 'react-redux'
import { UploadFilesCard } from './UploadFilesCard'
import { UploadFilesCardMapState } from './UploadFilesCardMapState'
import { UploadFilesCardMapDispatch } from './UploadFilesCardMapDispatch'

import { UploadFilesCardMapStateReturnType } from './types/UploadFilesCardMapStateReturnType'
import { UploadFilesCardMapDispatchReturnType } from './types/UploadFilesCardMapDispatchReturnType'
import { UploadFilesCardConnectedProps } from './types/UploadFilesCardConnectedProps'


export const UploadFilesCardConnected = connect<
  UploadFilesCardMapStateReturnType,
  UploadFilesCardMapDispatchReturnType,
  UploadFilesCardConnectedProps>(UploadFilesCardMapState,
    UploadFilesCardMapDispatch)(UploadFilesCard)
