import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskActionListConnectedProps } from './types/TaskActionListConnectedProps'
import { TaskActionListMapStateReturnType } from './types/TaskActionListMapStateReturnType'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskActionListMapState = (
  state: StateReducers,
  _props: TaskActionListConnectedProps
): TaskActionListMapStateReturnType => {
  const canWrite = getCanWrite({ state })

  return {
    canWrite,
  }
}
