import { connect } from 'react-redux'
import { MessageCard } from './MessageCard'
import { MessageCardMapState } from './MessageCardMapState'
import { MessageCardMapDispatch } from './MessageCardMapDispatch'

import { MessageCardMapStateReturnType } from './types/MessageCardMapStateReturnType'
import { MessageCardMapDispatchReturnType } from './types/MessageCardMapDispatchReturnType'
import { MessageCardConnectedProps } from './types/MessageCardConnectedProps'


export const MessageCardConnected = connect<
  MessageCardMapStateReturnType,
  MessageCardMapDispatchReturnType,
  MessageCardConnectedProps>(MessageCardMapState,
    MessageCardMapDispatch)(MessageCard)
