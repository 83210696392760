import { isSameFlatArray } from './isSameFlatArray'
import { SimpleArrays } from 'types/SimpleArrays'

const cache = {}

// TODO: allow items to be null or undefined
export const memoizeFlatArray = (items: SimpleArrays, key: string) => {
  if (!cache[key]) {
    cache[key] = items
  } else {
    const isSame = isSameFlatArray(items, cache[key])

    if (!isSame) {
      cache[key] = items
    }
  }

  return cache[key]
}
