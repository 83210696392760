import React from 'react'
import { UserReportMainProps } from './types/UserReportMainProps'
import { USERS_NOUN, TEAM_NOUN } from 'constants/english'
import { UpgradeNowLink } from 'components/link/UpgradeNowLink'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'

export const UserReportMain = ({
  className = '',
  userCount,
  maxUserCountFree,
  maxUserCountPro,
  isPro,
}: UserReportMainProps): JSX.Element => {
  return (
    <div
      className={`UserReportMain ${lightBoxStyles} ${marginTopMediumStyles} ${className}`}
    >
      <h6>
        {userCount} {USERS_NOUN}
      </h6>

      <p className={marginTopSmallStyles}>
        {isPro && `Capacity: ${maxUserCountPro}`}
        {!isPro &&
          `The current ${TEAM_NOUN} provides ${maxUserCountFree} free ${USERS_NOUN}.`}
      </p>

      {!isPro && (
        <p>
          <UpgradeNowLink /> to add more {USERS_NOUN}
        </p>
      )}
    </div>
  )
}
