// import { Dispatch, Action } from 'redux'

import {
  // ActivityNotificationDropdownConnectedProps,
  ActivityNotificationDropdownMapDispatchReturnType,
} from './types'

export const ActivityNotificationDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: ActivityNotificationDropdownConnectedProps,
): ActivityNotificationDropdownMapDispatchReturnType => {
  return {
  }
}
