import { connect } from 'react-redux'
import { MinimalLayout } from './MinimalLayout'
import { MinimalLayoutMapState } from './MinimalLayoutMapState'
import { MinimalLayoutMapDispatch } from './MinimalLayoutMapDispatch'

import { MinimalLayoutMapStateReturnType } from './types/MinimalLayoutMapStateReturnType'
import { MinimalLayoutMapDispatchReturnType } from './types/MinimalLayoutMapDispatchReturnType'
import { MinimalLayoutConnectedProps } from './types/MinimalLayoutConnectedProps'


export const MinimalLayoutConnected = connect<
  MinimalLayoutMapStateReturnType,
  MinimalLayoutMapDispatchReturnType,
  MinimalLayoutConnectedProps
>(
  MinimalLayoutMapState,
  MinimalLayoutMapDispatch
)(MinimalLayout)
