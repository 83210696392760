import { UpdateUserDisplayNameFormStyles } from './UpdateUserDisplayNameFormStyles'
import React, { useState, useCallback, useEffect } from 'react'
import { UpdateUserDisplayNameFormProps } from './types/UpdateUserDisplayNameFormProps'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { isInvalidDisplayName } from 'firebaseFunctions/utilities/is/isInvalidDisplayName'
import { FormFooter } from 'components/footer/FormFooter'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'

export const UpdateUserDisplayNameForm = ({
  submit,
  displayName,
}: UpdateUserDisplayNameFormProps): JSX.Element => {
  const [displayNameState, setDisplayNameState] = useState('')
  const [errorState, setErrorState] = useState<string | null>(null)

  const validateDisplayName = useCallback(() => {
    const error = isInvalidDisplayName(displayNameState)

    setErrorState(error || null)

    return error
  }, [displayNameState])

  useEffect(() => {
    setDisplayNameState(displayName || '')
  }, [displayName])

  return (
    <form
      className={`UpdateUserDisplayNameForm ${UpdateUserDisplayNameFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const error = validateDisplayName()

        if (!error) {
          submit({ displayName: displayNameState })
        }
      }}
    >
      <HeaderOneTextConnected>Upate your name</HeaderOneTextConnected>

      <TextInputField
        type="text"
        value={displayNameState}
        error={errorState}
        onChange={(e) => {
          setDisplayNameState(e.target.value)
        }}
        className={errorState ? 'hasError' : ''}
        onFocus={() => {
          setErrorState(null)
        }}
      />

      <FormFooter label="save" />
    </form>
  )
}
