import { connect } from 'react-redux'
import { MessagesMain } from './MessagesMain'
import { MessagesMainMapState } from './MessagesMainMapState'
import { MessagesMainMapDispatch } from './MessagesMainMapDispatch'

import { MessagesMainMapStateReturnType } from './types/MessagesMainMapStateReturnType'
import { MessagesMainMapDispatchReturnType } from './types/MessagesMainMapDispatchReturnType'
import { MessagesMainConnectedProps } from './types/MessagesMainConnectedProps'


export const MessagesMainConnected = connect<
  MessagesMainMapStateReturnType,
  MessagesMainMapDispatchReturnType,
  MessagesMainConnectedProps>(MessagesMainMapState,
    MessagesMainMapDispatch)(MessagesMain)
