import { MOCK_TASK_A_ID } from '../mockTasks/mockTasksConstants'
import { MOCK_USER_A_ID } from '../mockUsers/mockUserIdConstants'
import { created } from '../created'
import { MOCK_TEAM_A_ID } from '../mockTeams/mockTeamsConstants'
import { PlanReducer } from 'state/reducers/types/PlanReducer'

export const mockPlanA: PlanReducer = {
  data: {
    created,
    status: 'closed',
    taskIds: [MOCK_TASK_A_ID],
    lastModifiedUserId: MOCK_USER_A_ID,
    start: created,
    end: created,
  },
  teamId: MOCK_TEAM_A_ID,
}
