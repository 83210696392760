import { POST_RE_LOGIN_FAILURE } from 'actions'

import { PostReLoginFailureAction } from './types/PostReLoginFailureAction'
import { PostReLoginRequestActionValues } from './types/PostReLoginRequestActionValues'


interface Props {
  requestValues: PostReLoginRequestActionValues
}

export const createPostReLoginFailureAction = ({
  requestValues,
}: Props): PostReLoginFailureAction => {
  return {
    type: POST_RE_LOGIN_FAILURE,
    requestValues,
  }
}
