import { getTeamRef } from 'firebaseFunctions/utilities/ref/get/getTeamRef'
import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { TeamsReducer } from 'state/reducers/types/TeamsReducer'
import { createDeleteTeamsSuccessAction } from 'actionCreators/delete/DeleteTeams/createDeleteTeamsSuccessAction'
import { createDeleteTeamsFailureAction } from 'actionCreators/delete/DeleteTeams/createDeleteTeamsFailureAction'
import { createDeleteTeamsRequestAction } from 'actionCreators/delete/DeleteTeams/createDeleteTeamsRequestAction'

interface Props {
  items: TeamsReducer
}

export const deleteTeamsSaga = function*({ items }: Props) {
  yield put(
    createDeleteTeamsRequestAction({
      requestValues: {
        items,
      },
    })
  )

  const { key: teamId, item: firstTeam } = getObjectFirstItem(items)

  if (teamId && firstTeam) {
    try {
      yield getTeamRef({
        firestore,
        teamId,
      }).delete()

      yield put(
        createDeleteTeamsSuccessAction({
          requestValues: { items },
          successValues: { items },
        })
      )
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'deleteTeamsSaga',
        })
      )

      yield put(
        createDeleteTeamsFailureAction({
          requestValues: { items },
        })
      )
    }
  } else {
    yield put(
      createUnexpectedErrorAction({
        description: 'deleteTeamsRequestF',
      })
    )
  }
}
