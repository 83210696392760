import { connect } from 'react-redux'
import { PageSpinnerWidget } from './PageSpinnerWidget'
import { PageSpinnerWidgetMapState } from './PageSpinnerWidgetMapState'
import { PageSpinnerWidgetMapDispatch } from './PageSpinnerWidgetMapDispatch'

import { PageSpinnerWidgetMapStateReturnType } from './types/PageSpinnerWidgetMapStateReturnType'
import { PageSpinnerWidgetMapDispatchReturnType } from './types/PageSpinnerWidgetMapDispatchReturnType'
import { PageSpinnerWidgetConnectedProps } from './types/PageSpinnerWidgetConnectedProps'


export const PageSpinnerWidgetConnected = connect<
  PageSpinnerWidgetMapStateReturnType,
  PageSpinnerWidgetMapDispatchReturnType,
  PageSpinnerWidgetConnectedProps>(PageSpinnerWidgetMapState,
    PageSpinnerWidgetMapDispatch)(PageSpinnerWidget)
