import { connect } from 'react-redux'
import { EditorEmojiButton } from './EditorEmojiButton'
import { EditorEmojiButtonMapState } from './EditorEmojiButtonMapState'
import { EditorEmojiButtonMapDispatch } from './EditorEmojiButtonMapDispatch'

import { EditorEmojiButtonMapStateReturnType } from './types/EditorEmojiButtonMapStateReturnType'
import { EditorEmojiButtonMapDispatchReturnType } from './types/EditorEmojiButtonMapDispatchReturnType'
import { EditorEmojiButtonConnectedProps } from './types/EditorEmojiButtonConnectedProps'


export const EditorEmojiButtonConnected = connect<
  EditorEmojiButtonMapStateReturnType,
  EditorEmojiButtonMapDispatchReturnType,
  EditorEmojiButtonConnectedProps>(EditorEmojiButtonMapState,
    EditorEmojiButtonMapDispatch)(EditorEmojiButton)
