// took code from https://gist.github.com/bengotow/63462490660da6bfea8d92b3124e09ee
import React from 'react'

import {
  EditorState,
  SelectionState,
  RichUtils,
  ContentState,
  ContentBlock,
  CharacterMetadata,
  Modifier,
} from 'draft-js'

interface LinkData {
  url: string
  explicit?: boolean
}

const isUrl = (text: string) => {
  return text.startsWith('http://') || text.startsWith('https://') // insert your favorite library here
}

export function getCurrentLinkEntityKey(editorState: EditorState) {
  const contentState = editorState.getCurrentContent()
  const startKey = editorState.getSelection().getStartKey()
  const startOffset = editorState.getSelection().getStartOffset()
  const block = contentState.getBlockForKey(startKey)
  const linkKey = block.getEntityAt(Math.min(block.getText().length - 1, startOffset))

  if (linkKey) {
    const linkInstance = contentState.getEntity(linkKey)
    if (linkInstance.getType() === 'LINK') {
      return linkKey
    }
  }

  return null;
}

/*
Returns the URL for the link entity the user is currently within.
*/
export function getCurrentLink(editorState: EditorState) {
  const entityKey = getCurrentLinkEntityKey(editorState)

  return (
    entityKey &&
    editorState
      .getCurrentContent()
      .getEntity(entityKey)
      .getData().url
  )
}

/*
Returns editor state with a link entity created / updated to hold the link @data
for the range specified by @selection
*/
const editorStateSettingLink = (
  editorState: EditorState,
  selection: SelectionState,
  data: LinkData,
) => {
  const contentState = editorState.getCurrentContent()
  const entityKey = getCurrentLinkEntityKey(editorState)

  let nextEditorState = editorState

  if (!entityKey) {
    // if (data.url.length > 10) { TODO: get this working to limit link url size
    //   debugger
    //   contentState = Modifier.replaceText(contentState, selection, 'foo')
    // }

    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', data)
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    nextEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
    nextEditorState = RichUtils.toggleLink(nextEditorState, selection, entityKey)
  } else {
    nextEditorState = EditorState.set(editorState, {
      currentContent: editorState.getCurrentContent().replaceEntityData(entityKey, data),
    })
    // this is a hack that forces the editor to update
    // https://github.com/facebook/draft-js/issues/1047
    nextEditorState = EditorState.forceSelection(nextEditorState, editorState.getSelection())
  }

  return nextEditorState;
}

interface LinkProps {
  data: any
  contentState: ContentState
  entityKey: string
  children: any[]
}

const Link = ({
  data,
  contentState,
  entityKey,
  children,
}: LinkProps): JSX.Element => {
  const url = data ? data.url : contentState.getEntity(entityKey).getData().url

  if (!url) {
    return <span>{children}</span>
  }

  return (
    <a href={url} title={url} target="_blank" rel="noopener noreferrer" onClick={() => window.open(url, '_blank')}>
      {children}
    </a>
  )
}

const onChange = (editorState: EditorState) => {
  /* This method is called as you edit content in the Editor. We use
  some basic logic to keep the LINK entity in sync with the user's text
  and typing.
  */
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()

  if (!selection || !selection.isCollapsed()) {
    return editorState;
  }

  const cursorOffset = selection.getStartOffset()
  const cursorBlockKey = selection.getStartKey()
  const cursorBlock = contentState.getBlockForKey(cursorBlockKey)

  // Comment this out because it wouldnt convert text to links that were in bullets
  // if (cursorBlock.getType() !== 'unstyled') {
  //   debugger
  //   return editorState;
  // }

  // Step 1: Get the word around the cursor by splitting the current block's text
  const text = cursorBlock.getText()
  const currentWordStart = text.lastIndexOf(' ', cursorOffset) + 1
  const cursorOffsetIndex = text.indexOf(' ', cursorOffset)
  const currentWordEnd = cursorOffsetIndex > -1 ? cursorOffsetIndex : text.length
  const currentWord = text.substr(currentWordStart, currentWordEnd - currentWordStart)
  const currentWordIsURL = isUrl(currentWord)

  // Step 2: Find the existing LINK entity beneath the user's cursor
  const offset = Math.min(text.length - 1, cursorOffset)
  const entityKey = cursorBlock.getEntityAt(offset)
  const entity = entityKey && contentState.getEntity(entityKey)
  const isEntityLink = entity && entity.getType() !== 'LINK'

  if (isEntityLink) {
    // Note: we don't touch link values added / removed "explicitly" via the link
    // toolbar button. This means you can make a link with text that doesn't match the link.
    const entityExistingData = contentState.getEntity(entityKey).getData()

    if (entityExistingData.explicit) {
      return editorState
    } else if (currentWordIsURL) {
      // We are modifying the URL - update the entity to reflect the current text
      const contentState = editorState.getCurrentContent()

      const newContentState = contentState.replaceEntityData(entityKey, {
        explicit: false,
        url: currentWord,
      })

      return EditorState.set(editorState, {
        currentContent: newContentState,
      })
    } else {
      // We are no longer in a URL but the entity is still present. Remove it from
      // the current character so the linkifying "ends".
      const entityRange = new SelectionState({
        anchorOffset: currentWordStart - 1,
        anchorKey: cursorBlockKey,
        focusOffset: currentWordStart,
        focusKey: cursorBlockKey,
        isBackward: false,
        hasFocus: true,
      })

      return EditorState.set(editorState, {
        currentContent: Modifier.applyEntity(
          editorState.getCurrentContent(),
          entityRange,
          null
        ),
      })
    }
  } else if (currentWordIsURL) {
    const entityRange = new SelectionState({
      anchorOffset: currentWordStart,
      anchorKey: cursorBlockKey,
      focusOffset: currentWordEnd,
      focusKey: cursorBlockKey,
      isBackward: false,
      hasFocus: false,
    })

    const newEditorState = editorStateSettingLink(editorState, entityRange, {
      explicit: false,
      url: currentWord,
    })

    // reset selection to the initial cursor to avoid selecting the entire links
    const resetSelectionEditorState = EditorState.acceptSelection(newEditorState, selection)

    return resetSelectionEditorState
  }

  return editorState
}

type FindEntityRangesCallback = (start: number, end: number) => void

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: FindEntityRangesCallback,
  contentState: ContentState
) => {
  const filter = (character: CharacterMetadata) => {
    const entityKey = character.getEntity()

    if (entityKey) {
      const entity = contentState.getEntity(entityKey)
      return entity.getType() === 'LINK' && entity.getData().url
    }
  }

  contentBlock.findEntityRanges(filter, callback)
}

export const createLinkifyPlugin = () => {
  return {
    decorators: [
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ],

    onChange,
  }
}
