import { css } from 'emotion'

import {
  BORDER_RADIUS,
} from 'constants/styleConstants'

export const GoogleSignInButtonStyles = css`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: ${BORDER_RADIUS};

  svg {
    margin-right: 16px;
  }
`
