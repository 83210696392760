import { Action } from 'redux'
import { PRIMARY_STORAGE_KEY } from 'constants/cookieConstants'
import { removeUndefined } from 'firebaseFunctions/utilities/removeUndefined'
import { cookieStore } from 'utilities/cookieStore'
import { CookieStorage } from 'types/CookieStorage'
import { GET_COOKIE_STORAGE_SUCCESS, SET_COOKIE_STORAGE } from 'actions'
import {
  SetCookieStorageAction,
  GetCookieStorageSuccessAction,
} from 'actionCreators/cookieStorage'

export const cookieStorageReducer = (
  state?: CookieStorage,
  action?: Action
): CookieStorage | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SET_COOKIE_STORAGE: {
      const setCookieStorageAction = action as SetCookieStorageAction

      const removedUndefined = removeUndefined<CookieStorage>(
        setCookieStorageAction.values
      )

      const mergedWithState = {
        ...state,
        ...removedUndefined,
      }

      const newState = setCookieStorageAction.replace
        ? removedUndefined
        : mergedWithState

      cookieStore.set(PRIMARY_STORAGE_KEY, newState)

      return newState
    }

    case GET_COOKIE_STORAGE_SUCCESS: {
      const cookieStorageSuccessAction = action as GetCookieStorageSuccessAction

      return {
        ...state,
        ...cookieStorageSuccessAction.values,
      }
    }

    default: {
      return state || null
    }
  }
}
