import { TaskTagDropdownStyles } from './TaskTagDropdownStyles'
import React from 'react'
import { TaskTagDropdownProps } from './types/TaskTagDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { RemoveTagButtonConnected } from 'components/button/RemoveTagButton'
import { TaskTagCardConnected } from 'components/card/TaskTagCard'
import { TagDisplayNameSpanConnected } from 'components/span/TagDisplayNameSpan'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'
import { FilterByButtonConnected } from '../../button/FilterByButton/FilterByButtonConnected'
import { createTaskTagDropdownId } from '../../../utilities/create/createDropdownId'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'

export const TaskTagDropdown = ({
  showTaskTagDropdown,
  taskId,
  tagId,
  displayName,
  isDashboardLayoutRoute,
  closeDropdown,
  canWrite,
}: TaskTagDropdownProps): JSX.Element => {
  const dropdownId = createTaskTagDropdownId({ taskId, tagId })

  return (
    <div className={`TaskTagDropdown ${dropdownId} ` + TaskTagDropdownStyles}>
      <DropdownButtonConnected
        dropdownType="taskTag"
        title={displayName || 'See Tag'}
        className={showTaskTagDropdown ? 'active' : ''}
        watchSelector={`.TaskTagDropdown.${dropdownId}`}
        id={dropdownId}
      >
        <TaskTagCardConnected taskId={taskId} tagId={tagId} />
      </DropdownButtonConnected>

      {showTaskTagDropdown && (
        <DropdownConnected>
          <div className={`${marginTopSmallStyles} ${textBoldStyles}`}>
            <TagDisplayNameSpanConnected taskId={taskId} tagId={tagId} />
          </div>

          <ul className={listActionButtonsStyles}>
            {isDashboardLayoutRoute && (
              <li>
                <FilterByButtonConnected
                  tagId={tagId}
                  onClick={closeDropdown}
                />
              </li>
            )}
            {canWrite && (
              <li>
                <RemoveTagButtonConnected taskId={taskId} tagId={tagId} />
              </li>
            )}
          </ul>
        </DropdownConnected>
      )}
    </div>
  )
}
