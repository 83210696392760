import { POST_TEAMS_REQUEST } from 'actions'
import { PostTeamsRequestAction } from './types/PostTeamsRequestAction'
import { PostTeamsRequestActionValues } from './types/PostTeamsRequestActionValues'

interface Props {
  requestValues: PostTeamsRequestActionValues,
}

export const createPostTeamsRequestAction = ({ requestValues }: Props): PostTeamsRequestAction => {
  return {
    type: POST_TEAMS_REQUEST,
    requestValues,
  }
}
