import { connect } from 'react-redux'
import { MessagesWatcher } from './MessagesWatcher'
import { MessagesWatcherMapState } from './MessagesWatcherMapState'
import { MessagesWatcherMapDispatch } from './MessagesWatcherMapDispatch'

import { MessagesWatcherMapStateReturnType } from './types/MessagesWatcherMapStateReturnType'
import { MessagesWatcherMapDispatchReturnType } from './types/MessagesWatcherMapDispatchReturnType'
import { MessagesWatcherConnectedProps } from './types/MessagesWatcherConnectedProps'


export const MessagesWatcherConnected = connect<
  MessagesWatcherMapStateReturnType,
  MessagesWatcherMapDispatchReturnType,
  MessagesWatcherConnectedProps>(MessagesWatcherMapState,
    MessagesWatcherMapDispatch)(MessagesWatcher)
