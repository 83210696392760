import { take, put } from 'redux-saga/effects'
import { UserCredential, firebaseSdk } from 'sdks/firebase'
import { GOOGLE_SIGN_IN_PROXY_SUCCESS } from 'actions'
import { cookieStore } from 'utilities/cookieStore'
import { FIREBASE_ID_TOKEN } from 'constants/cookieConstants'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createGoogleSignInSuccessAction } from 'actionCreators/auth'

export const googleSignInProxySuccessF = function*() {
  while (1) {
    yield take([GOOGLE_SIGN_IN_PROXY_SUCCESS])

    const idToken = cookieStore.get(FIREBASE_ID_TOKEN)

    try {
      const authCredential: firebase.auth.OAuthCredential = yield firebaseSdk.auth.GoogleAuthProvider.credential(
        idToken
      )
      const userCredential: UserCredential = yield firebaseSdk
        .auth()
        .signInWithCredential(authCredential)

      const googleSignInSuccessAction = createGoogleSignInSuccessAction({
        userCredential,
      })

      yield put(googleSignInSuccessAction)
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error,
          description: 'googleSignInProxySuccessF',
        })
      )
    }
  }
}
