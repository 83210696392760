import { NotificationsSettingsMainStyles } from './NotificationsSettingsMainStyles'
import React from 'react'
// import { NotificationsSettingsMainProps } from './types/NotificationsSettingsMainProps'
import { MessagePushNotificationFieldConnected } from 'components/field/MessagePushNotificationField'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
// import { EmailNotificationFieldConnected } from 'components/field/EmailNotificationField'

export const NotificationsSettingsMain = (): JSX.Element => {
  return (
    <div
      className={`NotificationsSettingsMain ${NotificationsSettingsMainStyles}`}
    >
      <HeaderOneTextConnected>Browser Notifications</HeaderOneTextConnected>

      <div className={lightBoxStyles}>
        <MessagePushNotificationFieldConnected />
      </div>

      {/*
    Not sure if we need this feature, will come back to it later.
      <h2 className="emailNotificationsHeader">Email Notifications</h2>

    <EmailNotificationFieldConnected />*/}
    </div>
  )
}
