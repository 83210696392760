import { Dispatch, Action } from 'redux'
import { ToCurrentCycleButtonMapDispatchReturnType } from './types/ToCurrentCycleButtonMapDispatchReturnType'
import { createSubmitAction } from '../../../actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_MOVE_TASK_TO_CYCLE } from '../../../actions'

export const ToCurrentCycleButtonMapDispatch = (
  dispatch: Dispatch<Action>
): ToCurrentCycleButtonMapDispatchReturnType => {
  return {
    moveToCurrentCycle: (taskId) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_MOVE_TASK_TO_CYCLE,
          values: {
            cycle: 'current',
            taskId,
          },
        })
      )
    },
  }
}
