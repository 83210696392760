import { connect } from 'react-redux'
import { EmojiDropdown } from './EmojiDropdown'
import { EmojiDropdownMapState } from './EmojiDropdownMapState'
import { EmojiDropdownMapDispatch } from './EmojiDropdownMapDispatch'

import { EmojiDropdownMapStateReturnType } from './types/EmojiDropdownMapStateReturnType'
import { EmojiDropdownMapDispatchReturnType } from './types/EmojiDropdownMapDispatchReturnType'
import { EmojiDropdownConnectedProps } from './types/EmojiDropdownConnectedProps'


export const EmojiDropdownConnected = connect<
  EmojiDropdownMapStateReturnType,
  EmojiDropdownMapDispatchReturnType,
  EmojiDropdownConnectedProps>(EmojiDropdownMapState,
    EmojiDropdownMapDispatch)(EmojiDropdown)
