import { StateReducers } from '../reducers/types/StateReducers'
import { getUserRoles } from './getUserRoles'
import { getCanPossiblyOwn } from './getCanPossiblyOwn'
import { forEach } from 'lodash'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCanLeave = ({ state, teamId }: Props): boolean => {
  const userRoles = getUserRoles({ state, teamId })
  const canPossiblyOwn = getCanPossiblyOwn({ state, teamId })

  let ownerCount = 0

  forEach(userRoles, ({ role }) => {
    if (role === 'owner') {
      ownerCount = ownerCount + 1
    }
  })

  if (ownerCount === 1 && canPossiblyOwn) {
    return false
  }

  return true
}
