import { combineReducers } from 'redux'
import { messagesReducer } from './messagesReducer'
import { currentLocationReducer } from './currentLocationReducer'
import { currentUserIdReducer } from './currentUserIdReducer'
import { currentUserReducer } from './currentUserReducer'
import { currentUnscopeUserReducer } from './currentUnscopeUserReducer'
import { currentCustomClaimReducer } from './currentCustomClaimReducer'
import { alertMessageReducer } from './alertMessageReducer'
import { invitesGenericReducer } from './invitesGenericReducer'
import { requestsReducer } from './requestsReducer'
import { sessionStorageReducer } from './sessionStorageReducer'
import { cookieStorageReducer } from './cookieStorageReducer'
import { SIGN_OUT_SUCCESS } from 'actions'
import { StateReducers } from './types/StateReducers'
import { tasksReducer } from './tasksReducer'
import { plansReducer } from './plansReducer'
import { teamsReducers } from './teamsReducers'
import { togglesReducer } from './togglesReducer'
import { uploadsReducer } from './uploadsReducer'
import { windowMetaReducer } from './windowMetaReducer'
import { userInvitesReducer } from './userInvitesReducer'
import { statReducer } from './statReducer'
import { uploadFilesReducer } from './uploadFilesReducer'
import { scrollPositionReducer } from './scrollPositionReducer'
import { unavailableTasksReducer } from './unavailableTasksReducer'
import { uploadedFilesReducer } from './uploadedFilesReducer'

const appReducer = combineReducers<StateReducers>({
  messages: messagesReducer,
  currentLocation: currentLocationReducer,
  currentUser: currentUserReducer,
  currentUnscopeUser: currentUnscopeUserReducer,
  currentCustomClaim: currentCustomClaimReducer,
  currentUserId: currentUserIdReducer,
  cookieStorage: cookieStorageReducer,
  alertMessage: alertMessageReducer,
  invites: invitesGenericReducer,
  requests: requestsReducer,
  sessionStorage: sessionStorageReducer,
  tasks: tasksReducer,
  plans: plansReducer,
  teams: teamsReducers,
  toggles: togglesReducer,
  uploads: uploadsReducer,
  windowMeta: windowMetaReducer,
  userInvites: userInvitesReducer,
  stat: statReducer,
  uploadFiles: uploadFilesReducer,
  scrollPosition: scrollPositionReducer,
  unavailableTasks: unavailableTasksReducer,
  uploadedFiles: uploadedFilesReducer,
})

interface Action {
  type: string
}

export const initialState = appReducer({}, { type: 'INITIAL' }) as StateReducers

export const rootReducer = (state: StateReducers, action: Action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    const sessionStorage = state.sessionStorage || undefined
    const cookieStorage = state.cookieStorage || undefined
    const isEndToEndTesting = sessionStorage && sessionStorage.isEndToEndTesting
    const windowMeta = state.windowMeta || undefined

    const newState: StateReducers = {
      ...initialState,
      sessionStorage: {
        ...initialState.sessionStorage,
        isEndToEndTesting,
      },
      windowMeta,
      cookieStorage,
    }

    return appReducer(newState, action)
  }

  return appReducer(state, action)
}
