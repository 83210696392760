import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUnreadCount } from 'state/getters/getUnreadCount'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // ActivityNotificationDropdownConnectedProps,
  ActivityNotificationDropdownMapStateReturnType,
} from './types'

export const ActivityNotificationDropdownMapState = (
  state: StateReducers,
  // props: ActivityNotificationDropdownConnectedProps,
): ActivityNotificationDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const showActivityNotificationsDropdown = showDropdown && showDropdown.type === 'activityNotifications'
  const unreadCount = getUnreadCount({ state, notificationGroup: 'activities' })
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    unreadCount,
    showActivityNotificationsDropdown,
    isMediumScreen
  }
}
