import { UploadedFileListStyles } from './UploadedFileListStyles'
import React from 'react'
import { UploadedFileListProps } from './types/UploadedFileListProps'
import { UploadedFileCardConnected } from 'components/card/UploadedFileCard'

export const UploadedFileList = ({
  uploadedFileIds,
}: UploadedFileListProps): JSX.Element => {
  return (
    <ul className={'UploadedFileList ' + UploadedFileListStyles}>
      {uploadedFileIds &&
        uploadedFileIds.map((uploadedFileId) => {
          return (
            <li key={uploadedFileId}>
              <UploadedFileCardConnected uploadedFileId={uploadedFileId} />
            </li>
          )
        })}
    </ul>
  )
}
