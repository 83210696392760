import { StateReducers } from 'state/reducers/types/StateReducers'
import { FilterByButtonConnectedProps } from './types/FilterByButtonConnectedProps'
import { FilterByButtonMapStateReturnType } from './types/FilterByButtonMapStateReturnType'
import { getTaskFiltersFromState } from '../../../state/getters/getTaskFiltersFromState'
import { memoizeFlatArray } from '../../../utilities/memoizeFlatArray'

export const FilterByButtonMapState = (
  state: StateReducers,
  _props: FilterByButtonConnectedProps
): FilterByButtonMapStateReturnType => {
  const { taskFilters } = getTaskFiltersFromState({ state })
  const tagIds = taskFilters?.tagIds || undefined
  const summary = taskFilters?.summary || undefined
  const taskStatuses = taskFilters?.taskStatuses || undefined

  return {
    tagIds: tagIds && memoizeFlatArray(tagIds, 'FilterByButtonMapState-tagIds'),
    summary,
    taskStatuses:
      taskStatuses &&
      memoizeFlatArray(taskStatuses, 'FilterByButtonMapState-taskStatuses'),
  }
}
