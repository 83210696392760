import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { CatIcon } from 'components/icon/CatIcon/CatIcon'
import { EditIcon } from 'components/icon/EditIcon/EditIcon'
import { FileUploadIcon } from 'components/icon/FileUploadIcon/FileUploadIcon'
import { ListOlSolidIcon } from 'components/icon/ListOlSolidIcon/ListOlSolidIcon'
import { ReceiptIcon } from 'components/icon/ReceiptIcon/ReceiptIcon'
import { ShapesSolidIcon } from 'components/icon/ShapesSolidIcon/ShapesSolidIcon'
import { TagIcon } from 'components/icon/TagIcon/TagIcon'
import { TrashIcon } from 'components/icon/TrashIcon/TrashIcon'
import { UsersIcon } from 'components/icon/UsersIcon/UsersIcon'
import { CustomLinkConnected } from 'components/link/CustomLink'
import {
  LABELS_NOUN,
  TASK_NOUN,
  TEAM_NOUN,
  USERS_NOUN,
} from 'constants/english'
import * as React from 'react'
import {
  DELETE_WORKSPACE_ROUTE,
  LABELS_ROUTE,
  MEMBERS_ROUTE,
  REPORT_ROUTE,
  TASK_SIZES_ROUTE,
  TASK_STATUSES_ROUTE,
  TEAM_FILES_ROUTE,
  UPDATE_TEAM_NAME_ROUTE,
  UPDATE_WORKSPACE_LOGO_ROUTE,
  UPGRADE_WORKSPACE_ROUTE,
  WORKSPACE_SUBSCRIPTION_ROUTE,
} from 'routes'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { ImageIcon } from '../../icon/ImageIcon/ImageIcon'
import { TeamSettingsMainStyles } from './TeamSettingsMainStyles'
import { TeamSettingsMainProps } from './types/TeamSettingsMainProps'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const TeamSettingsMain = ({
  isPro,
}: TeamSettingsMainProps): JSX.Element => {
  return (
    <main className={'TeamSettingsMain ' + TeamSettingsMainStyles}>
      <HeaderOneTextConnected>{TEAM_NOUN} Settings</HeaderOneTextConnected>

      <ul className={`settings ${lightBoxStyles}`}>
        <li className="setting">
          {isPro && (
            <CustomLinkConnected
              title="Workspace subscription"
              className={textLinkStyles}
              route={WORKSPACE_SUBSCRIPTION_ROUTE}
              withCurrentQuery={true}
            >
              <IconAndDiv icon={<CatIcon />}>Pro Subscription</IconAndDiv>
            </CustomLinkConnected>
          )}

          {!isPro && (
            <CustomLinkConnected
              title="Upgrade to Pro"
              route={UPGRADE_WORKSPACE_ROUTE}
              className="upgradeToPro"
              withCurrentQuery={true}
            >
              <IconAndDiv icon={<CatIcon />}>Upgrade to Pro</IconAndDiv>
            </CustomLinkConnected>
          )}
        </li>
        <li className="setting">
          <CustomLinkConnected
            title="edit members"
            route={MEMBERS_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<UsersIcon />}>{USERS_NOUN}</IconAndDiv>
          </CustomLinkConnected>
        </li>
        <li className="setting">
          <CustomLinkConnected
            title="Uploaded files"
            route={TEAM_FILES_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<FileUploadIcon />}>Uploaded Files</IconAndDiv>
          </CustomLinkConnected>
        </li>
        <li className="setting">
          <CustomLinkConnected
            title="Report"
            route={REPORT_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<ReceiptIcon />}>Report</IconAndDiv>
          </CustomLinkConnected>
        </li>
      </ul>

      <HeaderOneTextConnected className={marginTopLargeStyles}>
        Customize {TEAM_NOUN}
      </HeaderOneTextConnected>

      <ul className={`settings ${lightBoxStyles}`}>
        <li>
          <CustomLinkConnected
            title={`Change ${TEAM_NOUN} name`}
            route={UPDATE_TEAM_NAME_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<EditIcon />}>{TEAM_NOUN} name</IconAndDiv>
          </CustomLinkConnected>
        </li>
        <li>
          <CustomLinkConnected
            title={`Change ${TEAM_NOUN} logo`}
            route={UPDATE_WORKSPACE_LOGO_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<ImageIcon />}>{TEAM_NOUN} logo</IconAndDiv>
          </CustomLinkConnected>
        </li>

        <li className="setting">
          <CustomLinkConnected
            title={`Customize ${TASK_NOUN} statuses`}
            route={TASK_STATUSES_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<ListOlSolidIcon />}>
              {TASK_NOUN} Statuses
            </IconAndDiv>
          </CustomLinkConnected>
        </li>
        <li className="setting">
          <CustomLinkConnected
            title={`${LABELS_NOUN}`}
            route={LABELS_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<TagIcon />}>{LABELS_NOUN}</IconAndDiv>
          </CustomLinkConnected>
        </li>
        <li className="setting">
          <CustomLinkConnected
            title={`Customize ${TASK_NOUN} Sizes`}
            route={TASK_SIZES_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<ShapesSolidIcon />}>
              {TASK_NOUN} Sizes
            </IconAndDiv>
          </CustomLinkConnected>
        </li>
      </ul>

      <HeaderOneTextConnected className={marginTopLargeStyles}>
        Danger Zone
      </HeaderOneTextConnected>

      <ul className={`settings ${lightBoxStyles}`}>
        <li className="setting">
          <CustomLinkConnected
            title="Delete workspace"
            route={DELETE_WORKSPACE_ROUTE}
            withCurrentQuery={true}
            className={textLinkStyles}
          >
            <IconAndDiv icon={<TrashIcon />}>Delete Workspace</IconAndDiv>
          </CustomLinkConnected>
        </li>
      </ul>
    </main>
  )
}
