import { connect } from 'react-redux'
import { HeaderTwoText } from './HeaderTwoText'
import { HeaderTwoTextMapState } from './HeaderTwoTextMapState'
import { HeaderTwoTextMapDispatch } from './HeaderTwoTextMapDispatch'
import { HeaderTwoTextMapStateReturnType } from './types/HeaderTwoTextMapStateReturnType'
import { HeaderTwoTextMapDispatchReturnType } from './types/HeaderTwoTextMapDispatchReturnType'
import { HeaderTwoTextConnectedProps } from './types/HeaderTwoTextConnectedProps'

export const HeaderTwoTextConnected = connect<
  HeaderTwoTextMapStateReturnType,
  HeaderTwoTextMapDispatchReturnType,
  HeaderTwoTextConnectedProps>(HeaderTwoTextMapState,
    HeaderTwoTextMapDispatch)(HeaderTwoText)
