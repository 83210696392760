// import { LevelUpAltIconStyles } from './LevelUpAltIconStyles'
import React from 'react'
import { LevelUpAltIconProps } from './types/LevelUpAltIconProps'
import { iconStyles } from '../../../styles/iconStyles'

export const LevelUpAltIcon = ({
  className = '',
  style,
}: LevelUpAltIconProps): JSX.Element => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={`LevelUpAltIcon  ${className} ${iconStyles}`}
      role="img"
      viewBox="0 0 320 512"
      style={style}
    >
      <path
        fill="currentColor"
        d="M313.553 392.331L209.587 504.334c-9.485 10.214-25.676 10.229-35.174 0L70.438 392.331C56.232 377.031 67.062 352 88.025 352H152V80H68.024a11.996 11.996 0 0 1-8.485-3.515l-56-56C-4.021 12.926 1.333 0 12.024 0H208c13.255 0 24 10.745 24 24v328h63.966c20.878 0 31.851 24.969 17.587 40.331z"
      ></path>
    </svg>
  )
}
