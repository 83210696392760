import { GET_PLANS_FAILURE } from 'actions'

import { GetPlansFailureAction } from './types/GetPlansFailureAction'
import { GetPlansRequestActionValues } from './types/GetPlansRequestActionValues'


interface Props {
  requestValues: GetPlansRequestActionValues
}

export const createGetPlansFailureAction = ({
  requestValues,
}: Props): GetPlansFailureAction => {
  return {
    type: GET_PLANS_FAILURE,
    requestValues,
  }
}
