import { connect } from 'react-redux'
import { PaymentFailedMain } from './PaymentFailedMain'
import { PaymentFailedMainMapState } from './PaymentFailedMainMapState'
import { PaymentFailedMainMapDispatch } from './PaymentFailedMainMapDispatch'
import { PaymentFailedMainMapStateReturnType } from './types/PaymentFailedMainMapStateReturnType'
import { PaymentFailedMainMapDispatchReturnType } from './types/PaymentFailedMainMapDispatchReturnType'
import { PaymentFailedMainConnectedProps } from './types/PaymentFailedMainConnectedProps'

export const PaymentFailedMainConnected = connect<
  PaymentFailedMainMapStateReturnType,
  PaymentFailedMainMapDispatchReturnType,
  PaymentFailedMainConnectedProps>(PaymentFailedMainMapState,
    PaymentFailedMainMapDispatch)(PaymentFailedMain)
