import { STRIPE_SUBSCRIPTION_URL } from 'constants/urlConstants'
import { DeleteStripeSubscriptionsRequestPayload } from 'firebaseFunctions/stripeSubscriptions/types/DeleteStripeSubscriptionsRequestPayload'
import { ApiHeaders } from 'firebaseFunctions/types/ApiHeaders'
import Axios, { AxiosPromise } from 'axios'
import { DeleteStripeSubscriptionsSuccessPayload } from 'firebaseFunctions/stripeSubscriptions/types/DeleteStripeSubscriptionsSuccessPayload'
import { createCleanQueryString } from 'utilities/create/createCleanQueryString'

interface Props {
  values: DeleteStripeSubscriptionsRequestPayload
  apiHeaders: ApiHeaders
}

export const deleteStripeSubscriptionsApi = ({
  values,
  apiHeaders,
}: Props): AxiosPromise<DeleteStripeSubscriptionsSuccessPayload> => {
  const cleanedQueryString = createCleanQueryString({ query: values })
  const urlWithQuery =
    STRIPE_SUBSCRIPTION_URL +
    (cleanedQueryString ? `?${cleanedQueryString}` : '')

  return Axios.delete(urlWithQuery, {
    headers: apiHeaders,
  })
}
