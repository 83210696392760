import { StateReducers } from 'state/reducers/types/StateReducers'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { pickBy } from 'lodash'

import {
  // UploadWatcherListConnectedProps,
  UploadWatcherListMapStateReturnType,
} from './types'

export const UploadWatcherListMapState = (
  state: StateReducers
  // props: UploadWatcherListConnectedProps,
): UploadWatcherListMapStateReturnType => {
  const uploads = state.uploads

  const uploading = pickBy(uploads, (upload) => {
    return upload.status === 'uploading'
  })

  const uploadIds = Object.keys(uploading)

  return {
    uploadIds: memoizeFlatArray(
      uploadIds,
      'UploadWatcherListMapState-uploadIds'
    ),
  }
}
