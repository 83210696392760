import { connect } from 'react-redux'
import { TaskSizeList } from './TaskSizeList'
import { TaskSizeListMapState } from './TaskSizeListMapState'
import { TaskSizeListMapDispatch } from './TaskSizeListMapDispatch'

import { TaskSizeListMapStateReturnType } from './types/TaskSizeListMapStateReturnType'
import { TaskSizeListMapDispatchReturnType } from './types/TaskSizeListMapDispatchReturnType'
import { TaskSizeListConnectedProps } from './types/TaskSizeListConnectedProps'


export const TaskSizeListConnected = connect<
  TaskSizeListMapStateReturnType,
  TaskSizeListMapDispatchReturnType,
  TaskSizeListConnectedProps>(TaskSizeListMapState,
    TaskSizeListMapDispatch)(TaskSizeList)
