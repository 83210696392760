import {
  // FormSpinnerConnectProps,
  FormSpinnerMapStateReturnType,
} from './types'

import { StateReducers } from 'state/reducers/types/StateReducers'

export const FormSpinnerMapState = (
  state: StateReducers
  // props: FormSpinnerConnectProps,
): FormSpinnerMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showFormSpinner = toggles && toggles.showFormSpinner

  return {
    showFormSpinner,
  }
}
