import { arraySplit } from './arraySplit'

interface Props <T> {
  items: T[]
  itemToInsert: T | T[]
  index: number
  length?: number
}

export const arrayInsert = <T>({
  items,
  itemToInsert,
  index,
  length = 0,
}: Props<T>): T[] => {
  const { start, end } = arraySplit({
    items,
    index,
    length,
  })

  const concatItem = Array.isArray(itemToInsert) ? itemToInsert : [itemToInsert]

  return start.concat(concatItem, end)
}
