import * as React from 'react'
import { UploadedFileWatcherConnected } from './UploadedFileWatcherConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_TEAM_A_ID } from 'mockState'

export const UploadedFileWatcherExamples = () => {
  return (
    <div className="UploadedFileWatcherExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <UploadedFileWatcherConnected teamId={MOCK_TEAM_A_ID} />
      </Provider>
    </div>
  )
}
