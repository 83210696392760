import { DELETE_TEAMS_FAILURE } from 'actions'
import { DeleteTeamsRequestActionValues } from './types/DeleteTeamsRequestActionValues'
import { DeleteTeamsFailureAction } from './types/DeleteTeamsFailureAction'

interface Props {
  requestValues: DeleteTeamsRequestActionValues
}

export const createDeleteTeamsFailureAction = ({
  requestValues,
}: Props): DeleteTeamsFailureAction => {
  return {
    type: DELETE_TEAMS_FAILURE,
    requestValues,
  }
}
