import { GET_USERS_SUCCESS } from 'actions'
import { GetUsersSuccessAction } from './types/GetUsersSuccessAction'
import { Users } from 'firebaseFunctions/types'

interface Props {
  successValues: Users
  requestValues?: any
}

export const createGetUsersSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetUsersSuccessAction => {
  return {
    type: GET_USERS_SUCCESS,
    requestValues,
    successValues,
  }
}
