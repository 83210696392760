import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const StripeSubscriptionMainStyles = css`
  .subscriptionList {
    > li {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
`
