import { setUserExtension } from '../../firebaseFunctions/utilities/ref/set/setUserExtension'
import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { FirebaseError } from 'firebase'
import { CREATE_USER_EXTENSION, CREATE_USER_EXTENSION_DONE } from 'actions'
import { TEAMS_COLLECTION } from 'firebaseFunctions/constants'
import { Team } from 'firebaseFunctions/types'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'

export const createUserExtensionF = function*() {
  while (1) {
    yield take([CREATE_USER_EXTENSION])

    try {
      // TODO: handle when theres no internet
      const teamsQuerySnapshot: firebase.firestore.QuerySnapshot = yield firestore
        .collection(TEAMS_COLLECTION)
        .get()

      for (let teamI = 0; teamI < teamsQuerySnapshot.docs.length; teamI += 1) {
        let teamDoc = teamsQuerySnapshot.docs[teamI]

        const team = teamDoc.data() as Team | undefined

        if (team?.userRoles) {
          const { userRoles } = team
          const userIds = Object.keys(userRoles)

          for (let userI = 0; userI < userIds.length; userI += 1) {
            yield setUserExtension({
              firestore,
              teamId: teamDoc.id,
              userId: userIds[userI],
              userExtension: {},
            })
          }
        }
      }
    } catch (error) {
      const firestoreErrorAction = createFirebaseErrorAction({
        error: error as FirebaseError,
        description: 'createUserExtensionF',
      })

      yield put(firestoreErrorAction)
    }

    yield put({
      type: CREATE_USER_EXTENSION_DONE,
    })
  }
}
