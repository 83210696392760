import { ToggleInputStyles } from './ToggleInputStyles'
import React from 'react'
import { ToggleInputProps } from './types/ToggleInputProps'
import { Button } from 'components/button/Button'
import { LabelDiv } from 'components/div/LabelDiv'
import { ToggleOnIcon } from 'components/icon/ToggleOnIcon/ToggleOnIcon'
import { ToggleOffIcon } from 'components/icon/ToggleOffIcon/ToggleOffIcon'

export const ToggleInput = ({
  isEnabled,
  toggleOnClick,
  toggleOffClick,
  label,
}: ToggleInputProps): JSX.Element => {
  return (
    <div className={`ToggleInput ${ToggleInputStyles}`}>
      {label && <LabelDiv>{label}</LabelDiv>}

      {isEnabled && (
        <Button className="turnOff" title="Turn off" onClick={toggleOffClick}>
          <ToggleOnIcon /> On
        </Button>
      )}

      {!isEnabled && (
        <Button className="turnOn" title="Turn on" onClick={toggleOnClick}>
          <ToggleOffIcon /> Off
        </Button>
      )}
    </div>
  )
}
