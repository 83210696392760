import { Dispatch, Action } from 'redux'

import { UploadedFileDropdownConnectedProps } from './types/UploadedFileDropdownConnectedProps'
import { UploadedFileDropdownMapDispatchReturnType } from './types/UploadedFileDropdownMapDispatchReturnType'

import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_DELETE_UPLOADED_FILE } from 'actions'

export const UploadedFileDropdownMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UploadedFileDropdownConnectedProps
): UploadedFileDropdownMapDispatchReturnType => {
  return {
    onDeleteClick: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_UPLOADED_FILE,
          values: {
            uploadedFileId: props.uploadedFileId,
          },
        })
      )
    },
  }
}
