import { StateReducers } from '../reducers/types/StateReducers'
import { UserRole } from 'firebaseFunctions/types'
import { getUserRoles } from './getUserRoles'
import { getCurrentQuery } from './getCurrentQuery'

interface Props {
  userId?: string
  state: StateReducers
}

export const getUserRole = ({ state, userId }: Props): UserRole | undefined => {
  const userRoles = getUserRoles({ state })
  const query = getCurrentQuery({ state }) || {}
  const currentUserId = state.currentUserId || undefined
  const useUserId = userId || query.userId || currentUserId

  return userRoles && useUserId ? userRoles[useUserId] : undefined
}
