import { UserInviteListStyles } from './UserInviteListStyles'
import React from 'react'
import { UserInviteListProps } from './types/UserInviteListProps'
import { UserInviteCardConnected } from 'components/card/UserInviteCard'

export const UserInviteList = ({
  inviteIds = [],
}: UserInviteListProps): JSX.Element | null => {
  if (inviteIds.length === 0) {
    return null
  }

  return (
    <ul className={'UserInviteList ' + UserInviteListStyles}>
      {inviteIds.map(inviteId => {
        return (
          <li key={inviteId}>
            <UserInviteCardConnected inviteId={inviteId} />
          </li>
        )
      })}
    </ul>
  )
}
