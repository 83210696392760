// import { Dispatch, Action } from 'redux'

import {
  // TaskFileListConnectedProps,
  TaskFileListMapDispatchReturnType,
} from './types'

export const TaskFileListMapDispatch = (): // dispatch: Dispatch<Action>,
// props: TaskFileListConnectedProps,
TaskFileListMapDispatchReturnType => {
  return {}
}
