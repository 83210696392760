const EMAIL_ADDRESS_REGEXP = /^[^@]+@[^@]+\.[^@]+$/

export const isInvalidEmail = (email: string): false | string => {
  if (!email) {
    return 'Please enter a email address.'
  }

  if (!email.match(EMAIL_ADDRESS_REGEXP)) {
    return 'The email address is not valid.'
  }

  return false
}
