// import { Dispatch, Action } from 'redux'
import { firebaseSdk } from 'sdks/firebase'

import {
  // SignOutButtonConnectProps,
  SignOutButtonMapDispatchReturnType,
} from './types'

export const SignOutButtonMapDispatch = (): // dispatch: Dispatch<Action>,
// props: SignOutButtonConnectProps,
SignOutButtonMapDispatchReturnType => {
  return {
    signOut: () => {
      firebaseSdk.auth().signOut()
    },
  }
}
