import { PATCH_PLANS_FAILURE } from 'actions'

import { PatchPlansFailureAction } from './types/PatchPlansFailureAction'
import { PatchPlansRequestActionValues } from './types/PatchPlansRequestActionValues'


interface Props {
  requestValues: PatchPlansRequestActionValues
}

export const createPatchPlansFailureAction = ({
  requestValues,
}: Props): PatchPlansFailureAction => {
  return {
    type: PATCH_PLANS_FAILURE,
    requestValues,
  }
}
