import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'



import { createGetTeamsRequestAction } from 'actionCreators/get/GetTeams/createGetTeamsRequestAction'

import { createGetUnscopeUserRequestAction } from 'actionCreators/get/GetUnscopeUser/createGetUnscopeUserRequestAction'

import { LOCATION_CHANGE } from 'actions'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'

export const requestTeamsF = function*() {
  while (1) {
    const locationChangeAction: LocationChangeAction = yield take([
      LOCATION_CHANGE,
    ])
    const state: StateReducers = yield select(selectState)
    const { values } = locationChangeAction
    const { isRoutes } = values
    const isSelectTeamRoute = isRoutes && isRoutes.isSelectTeamRoute
    const isTargetedRoute = isSelectTeamRoute
    const userId = state.currentUserId

    if (isTargetedRoute && userId) {
      yield put(
        createGetTeamsRequestAction({
          description: 'requestTeamsF',
          requestValues: {
            userId,
          },
        })
      )

      yield put(
        createGetUnscopeUserRequestAction({
          requestValues: {
            userId,
            ignoreErrors: true,
          },
        })
      )
    }
  }
}
