import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

import {
  // TaskStatusButtonListConnectedProps,
  TaskStatusButtonListMapStateReturnType,
} from './types'
import { getActivePlanTaskIds } from '../../../state/getters/getActivePlanTaskIds'
import { memoizeFlatArray } from '../../../utilities/memoizeFlatArray'

export const TaskStatusButtonListMapState = (
  state: StateReducers
  // props: TaskStatusButtonListConnectedProps,
): TaskStatusButtonListMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const taskStatuses = team?.taskStatuses || []

  const taskIds = getActivePlanTaskIds({ state, useFilter: true })
  const counts =
    taskStatuses?.map((taskStatus) => {
      return taskIds[taskStatus]?.length || 0
    }) || []

  return {
    taskStatuses: memoizeFlatArray(
      taskStatuses,
      'TaskStatusButtonListMapState-statuses'
    ),
    counts: memoizeFlatArray(counts, 'TaskStatusButtonListMapState-counts'),
  }
}
