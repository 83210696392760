import { POST_TEAMS_SUCCESS } from 'actions'

import { PostTeamsSuccessAction } from './types/PostTeamsSuccessAction'
import { PostTeamsSuccessActionValues } from './types/PostTeamsSuccessActionValues'
import { PostTeamsRequestActionValues } from './types/PostTeamsRequestActionValues'


interface Props {
  successValues: PostTeamsSuccessActionValues
  requestValues: PostTeamsRequestActionValues
}

export const createPostTeamsSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostTeamsSuccessAction => {
  return {
    type: POST_TEAMS_SUCCESS,
    requestValues,
    successValues,
  }
}
