import { StripeSubscriptionActionListStyles } from './StripeSubscriptionActionListStyles'
import React from 'react'
import { StripeSubscriptionActionListProps } from './types/StripeSubscriptionActionListProps'
import { CancelSubscriptionButtonConnected } from 'components/button/CancelSubscriptionButton/CancelSubscriptionButtonConnected'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'

export const StripeSubscriptionActionList = ({
  stripeSubscriptionId,
  handleDeletedStripeSubscription,
}: StripeSubscriptionActionListProps): JSX.Element => {
  return (
    <ul
      className={`StripeSubscriptionActionList ${StripeSubscriptionActionListStyles} ${listActionButtonsStyles}`}
    >
      <li>
        <CancelSubscriptionButtonConnected
          onSuccess={handleDeletedStripeSubscription}
          stripeSubscriptionId={stripeSubscriptionId}
        />
      </li>
    </ul>
  )
}
