import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'
import { getIsProFromSubscription } from 'firebaseFunctions/utilities/get/getIsProFromSubscription'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getIsProFromState = ({ state, teamId }: Props): boolean => {
  const teamReducer = getCurrentTeamReducer({ state, teamId })
  const subscription = teamReducer?.stats?.subscription

  return getIsProFromSubscription({ subscription })
}
