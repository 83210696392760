import { take, put, select, spawn } from 'redux-saga/effects'
import { deleteMessageFiles } from 'utilities/deleteMessageFiles'
import { SUBMIT_DELETE_MESSAGE, SUBMIT_DELETE_MESSAGE_DONE } from 'actions'
import { deleteMessagesSaga } from 'flows/sagas/delete/deleteMessagesSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { selectState } from 'state/reducers/selectState'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteMessageF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_MESSAGE])
    const state: StateReducers = yield select(selectState)
    const { messageId } = action.values
    const messageReducer = messageId ? state.messages?.[messageId] : undefined
    const message = messageId ? state.messages?.[messageId]?.data : undefined
    const teamId = messageReducer?.teamId
    const taskId = messageReducer?.taskId

    if (messageId && taskId && teamId && message) {
      const items: MessagesReducer = {
        [messageId]: {
          data: {},
          teamId,
          taskId,
        },
      }

      yield deleteMessageFiles({ teamId, taskId, messageId })

      yield spawn(deleteMessagesSaga, {
        items,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteMessageF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_MESSAGE_DONE,
    })
  }
}
