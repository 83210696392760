// import { Dispatch, Action } from 'redux'

import {
  // TaskSummarySpanConnectedProps,
  TaskSummarySpanMapDispatchReturnType,
} from './types'

export const TaskSummarySpanMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskSummarySpanConnectedProps,
): TaskSummarySpanMapDispatchReturnType => {
  return {
  }
}
