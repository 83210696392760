import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskStatusButtonsInputFieldConnectedProps } from './types/TaskStatusButtonsInputFieldConnectedProps'
import { TaskStatusButtonsInputFieldMapStateReturnType } from './types/TaskStatusButtonsInputFieldMapStateReturnType'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

export const TaskStatusButtonsInputFieldMapState = (
  state: StateReducers,
  _props: TaskStatusButtonsInputFieldConnectedProps
): TaskStatusButtonsInputFieldMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const taskStatuses = team?.taskStatuses

  return {
    taskStatuses: taskStatuses
      ? memoizeFlatArray(
          taskStatuses,
          'TaskStatusButtonsInputFieldMapState-taskStatuses'
        )
      : undefined,
  }
}
