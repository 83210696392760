import { css } from 'emotion'

export const InviteMainStyles = css`
  .confirmDelete {
    border: 1px solid #ccc;
  }

  .ActionButton {
    &.danger {
      margin-left: 16px;
    }
  }
`
