import { connect } from 'react-redux'
import { UpdateTeamNameForm } from './UpdateTeamNameForm'
import { UpdateTeamNameFormMapState } from './UpdateTeamNameFormMapState'
import { UpdateTeamNameFormMapDispatch } from './UpdateTeamNameFormMapDispatch'
import { UpdateTeamNameFormMapStateReturnType } from './types/UpdateTeamNameFormMapStateReturnType'
import { UpdateTeamNameFormMapDispatchReturnType } from './types/UpdateTeamNameFormMapDispatchReturnType'
import { UpdateTeamNameFormConnectedProps } from './types/UpdateTeamNameFormConnectedProps'

export const UpdateTeamNameFormConnected = connect<
  UpdateTeamNameFormMapStateReturnType,
  UpdateTeamNameFormMapDispatchReturnType,
  UpdateTeamNameFormConnectedProps>(UpdateTeamNameFormMapState,
    UpdateTeamNameFormMapDispatch)(UpdateTeamNameForm)
