import {
  CLIENT_QUERY_NAME_INVITE_ID,
  CLIENT_QUERY_NAME_TEAM_ID,
  CLIENT_QUERY_NAME_TASK_ID,
  CLIENT_QUERY_NAME_TEAM_NAME,
  CLIENT_QUERY_NAME_REFRESH_ID_TOKEN,
  CLIENT_QUERY_NAME_EMAIL,
  CLIENT_QUERY_NAME_SECRET,
} from 'firebaseFunctions/constants/clientConstants'

export const QUERY_NAME_END_TO_END_TESTING = 'end-to-end-testing'
export const QUERY_NAME_RETURN_PATH = 'returnPath'
export const QUERY_NAME_RETURN_QUERY = 'returnQuery'
export const QUERY_NAME_USER_ID = 'user_id'
export const QUERY_NAME_TEAM_ID = CLIENT_QUERY_NAME_TEAM_ID
export const QUERY_NAME_INVITE_ID = CLIENT_QUERY_NAME_INVITE_ID
export const QUERY_NAME_TEAM_NAME = CLIENT_QUERY_NAME_TEAM_NAME
export const QUERY_NAME_TASK_ID = CLIENT_QUERY_NAME_TASK_ID
export const QUERY_NAME_PLAN_ID = 'plan_id'
export const QUERY_NAME_SECRET = CLIENT_QUERY_NAME_SECRET
export const QUERY_NAME_EMAIL = CLIENT_QUERY_NAME_EMAIL
export const QUERY_NAME_REFRESH_ID_TOKEN = CLIENT_QUERY_NAME_REFRESH_ID_TOKEN
export const QUERY_NAME_SUBSCRIPTION_ID = 'subscription_id'
