import * as React from 'react'
import { StatusColorWidgetConnected } from './StatusColorWidgetConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_TASK_A_ID } from 'mockState'

export const StatusColorWidgetExamples = () => {
  return (
    <div className="StatusColorWidgetExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <StatusColorWidgetConnected taskId={MOCK_TASK_A_ID} />
      </Provider>
    </div>
  )
}
