// import { MainWatcherStyles } from './MainWatcherStyles'
import React from 'react'
import { MainWatcherProps } from './types/MainWatcherProps'
import { TasksWatcherConnected } from 'components/watcher/TasksWatcher'
import { PlansWatcherConnected } from 'components/watcher/PlansWatcher'
import { TeamStatWatcherConnected } from 'components/watcher/TeamStatWatcher'
import { UserExtensionWatcherConnected } from 'components/watcher/UserExtensionWatcher'
import { UploadWatcherListConnected } from 'components/list/UploadWatcherList'
import { TitleWatcherConnected } from 'components/watcher/TitleWatcher'
import { InvitesWatcherConnected } from '../InvitesWatcher'
import { TeamWatcherConnected } from '../TeamWatcher/TeamWatcherConnected'
import { ToastListWatcherConnected } from '../../list/ToastListWatcher/ToastListWatcherConnected'
// import { InvitesWatcherConnected } from 'components/watcher/InvitesWatcher'

export const MainWatcher = ({
  teamId,
  userId,
  canRead,
  watchInvites,
}: MainWatcherProps): JSX.Element => {
  return (
    <>
      <TitleWatcherConnected />

      <UploadWatcherListConnected />

      <ToastListWatcherConnected />

      {teamId && watchInvites && <InvitesWatcherConnected teamId={teamId} />}

      {teamId && <TeamWatcherConnected teamId={teamId} />}

      {canRead && teamId && (
        <>
          <TasksWatcherConnected teamId={teamId} key={'Tasks' + teamId} />
          <PlansWatcherConnected teamId={teamId} key={'Plans' + teamId} />
          <TeamStatWatcherConnected teamId={teamId} key={'TeamStat' + teamId} />
          {userId && (
            <UserExtensionWatcherConnected
              userId={userId}
              teamId={teamId}
              key={'UserExtension' + userId + teamId}
            />
          )}
        </>
      )}
    </>
  )
}
