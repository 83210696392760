import { NewTaskFormStyles } from './NewTaskFormStyles'
import React, { useState, useCallback } from 'react'
import { NewTaskFormProps } from './types/NewTaskFormProps'
import { isInvalidTaskSummary } from 'utilities/is/isInvalidTaskSummary'
import { FormFooter } from 'components/footer/FormFooter'
import { TextInputField } from 'components/field/TextInputField/TextInputField'

export const NewTaskForm = ({ submit }: NewTaskFormProps): JSX.Element => {
  const [summaryState, setSummaryState] = useState('')
  const [errorState, setErrorState] = useState<string | null>(null)

  const validateSummary = useCallback(() => {
    const error = isInvalidTaskSummary(summaryState)

    setErrorState(error || null)

    return error
  }, [summaryState])

  return (
    <form
      className={`NewTaskForm ${NewTaskFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const error = validateSummary()

        if (!error) {
          submit({ summary: summaryState })
        }
      }}
    >
      <TextInputField
        label="Summary"
        type="textarea"
        value={summaryState}
        autoFocus={true}
        error={errorState}
        onChange={(e) => {
          setSummaryState(e.target.value)
        }}
        className={errorState ? 'hasError' : ''}
        onFocus={() => {
          setErrorState(null)
        }}
      />

      <FormFooter label="Create Task" />
    </form>
  )
}
