import { css } from 'emotion'
import {
  BORDER_RADIUS,
  TEXT_COLOR_LIGHT,
  NEUTRAL_BORDER_COLOR,
} from 'constants/styleConstants'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const UpgradeWorkspaceMainStyles = css`
  .details,
  .summary {
    background-color: white;
    border-radius: ${BORDER_RADIUS};
    padding: 16px;
  }

  > h1 {
    margin-bottom: 32px;
  }

  > h2 {
    margin: 32px 0;
  }

  .flatRate {
    margin-bottom: 4px;
  }

  .tierPrice {
    color: ${TEXT_COLOR_LIGHT};
    font-size: 12px;
  }

  .pricePerMonth {
    font-size: 18px;
    font-weight: bold;
  }

  .upgradePoints {
    margin-top: 16px;
    margin-left: 16px;
    list-style-type: circle;

    > li {
      margin-top: 8px;
      font-size: 14px;
    }
  }

  .summary {
    > li {
      margin-top: 16px;
      border-top: 1px solid ${NEUTRAL_BORDER_COLOR};
      padding-top: 16px;

      &:first-child {
        border-top: none;
        margin-top: 0px;
        padding-top: 0;
      }
    }
  }
`
