import { showFormSpinnerF } from './showFormSpinnerF'
import { hideFormSpinnerF } from './hideFormSpinnerF'
import { hideCustomClaimSpinnerF } from './hideCustomClaimSpinnerF'
import { showCustomClaimSpinnerF } from './showCustomClaimSpinnerF'

export const toggleFlows = [
  showFormSpinnerF,
  hideFormSpinnerF,
  hideCustomClaimSpinnerF,
  showCustomClaimSpinnerF,
]
