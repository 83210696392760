// import { ImageWidgetStyles } from './ImageWidgetStyles'
import React, { useState } from 'react'
import { ImageWidgetProps } from './types/ImageWidgetProps'

export const ImageWidget = ({
  alt,
  className = '',
  ...props
}: ImageWidgetProps): JSX.Element => {
  const [loaded, setLoaded] = useState(false)
  const additionalClassName = className ? ` ${className}` : ''
  const loadedClassName = loaded ? ' loaded' : '' // used to help trigger mutationObservers

  return (
    <img
      className={additionalClassName + loadedClassName}
      {...props}
      alt={alt}
      onLoad={() => {
        setLoaded(true)
      }}
    />
  )
}
