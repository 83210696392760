import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // PageSpinnerWidgetConnectedProps,
  PageSpinnerWidgetMapStateReturnType,
} from './types'

export const PageSpinnerWidgetMapState = (
  state: StateReducers
  // props: PageSpinnerWidgetConnectedProps,
): PageSpinnerWidgetMapStateReturnType => {
  const toggles = state.toggles || undefined
  const signingIn = state.cookieStorage?.signedIn && !state.currentUserId
  const showPageSpinner = toggles?.showPageSpinner || signingIn

  return {
    showPageSpinner,
  }
}
