export const INVITES_COLLECTION = 'invites'
export const MESSAGES_COLLECTION = 'messages'
export const PLANS_COLLECTION = 'plans'
export const TASKS_COLLECTION = 'tasks'
export const TASK_CHANGES_COLLECTION = 'taskChanges'
export const TEAMS_COLLECTION = 'teams'
export const USER_METAS_COLLECTION = 'userMetas'
export const USERS_COLLECTION = 'users'
export const UPLOADED_FILES_COLLECTION = 'uploadedFiles'
export const FEEDBACKS_COLLECTION = 'feedbacks'
export const USER_EXTENSIONS_COLLECTION = 'userExtensions' // start using TEAM_USER_EXTENSIONS_COLLECTION
export const TEAM_USER_EXTENSIONS_COLLECTION = 'userExtensions' // taking over for USER_EXTENSIONS_COLLECTION
export const STATS_COLLECTION = 'stats'
