import { HeaderTwoTextStyles } from './HeaderTwoTextStyles'
import React from 'react'
import { HeaderTwoTextProps } from './types/HeaderTwoTextProps'

export const HeaderTwoText = ({
  isMediumScreen,
  isBigScreen,
  className = '',
  ...props
}: HeaderTwoTextProps): JSX.Element => {
  const isMediumScreenClassname = isMediumScreen ? 'isMediumScreen' : ''
  const isBigScreenClassname = isBigScreen ? 'isBigScreen' : ''

  return (
    <div
      className={`HeaderTwoText ${isMediumScreenClassname} ${isBigScreenClassname} ${HeaderTwoTextStyles} ${className}`}
      {...props}
    />
  )
}
