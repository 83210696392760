import { getCurrentUserTeams } from './../../../state/getters/getCurrentUserTeams'
import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // MinimalLayoutConnectedProps,
  MinimalLayoutMapStateReturnType,
} from './types'

export const MinimalLayoutMapState = (
  state: StateReducers
  // props: MinimalLayoutConnectedProps,
): MinimalLayoutMapStateReturnType => {
  const currentUserTeams = getCurrentUserTeams({ state })
  const teamIds = Object.keys(currentUserTeams)
  const showConfirmEmail = state.currentLocation?.isRoutes?.isConfirmEmailRoute
  const isSelectTeamRoute = state.currentLocation?.isRoutes?.isSelectTeamRoute
  const showLoader = state.teams === null

  return {
    showConfirmEmail,
    isSelectTeamRoute,
    showSelectTeam: teamIds.length > 0,
    showLoader,
  }
}
