import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { DELETE_USER_INVITES_REQUEST } from 'actions'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createDeleteUserInvitesSuccessAction } from 'actionCreators/delete/DeleteUserInvites/createDeleteUserInvitesSuccessAction'
import { DeleteUserInvitesRequestAction } from 'actionCreators/delete/DeleteUserInvites/types/DeleteUserInvitesRequestAction'
import { createDeleteUserInvitesFailureAction } from 'actionCreators/delete/DeleteUserInvites/createDeleteUserInvitesFailureAction'
import { deleteUserInvite } from '../../firebaseFunctions/utilities/ref/delete/deleteUserInvite'

export const deleteUserInvitesRequestF = function*() {
  while (1) {
    const deleteUserInvitesRequestAction: DeleteUserInvitesRequestAction = yield take(
      [DELETE_USER_INVITES_REQUEST]
    )

    const { requestValues } = deleteUserInvitesRequestAction
    const { items } = requestValues
    const { key: inviteId, item: firstUserInvite } = getObjectFirstItem(items)
    const email = firstUserInvite?.data.email

    if (inviteId && email) {
      try {
        yield deleteUserInvite({
          firestore,
          email,
          userInviteId: inviteId,
        })

        yield put(
          createDeleteUserInvitesSuccessAction({
            requestValues,
            successValues: {},
            description: 'deleteUserInvitesRequestF',
          })
        )
      } catch (error) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'deleteUserInvitesRequestF',
          })
        )

        yield put(
          createDeleteUserInvitesFailureAction({
            requestValues,
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'deleteUserInvitesRequestF',
        })
      )
    }
  }
}
