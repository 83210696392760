import { Dispatch, Action } from 'redux'
import { UserExtension } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { UserExtensionWatcherConnectedProps } from './types/UserExtensionWatcherConnectedProps'
import { UserExtensionWatcherMapDispatchReturnType } from './types/UserExtensionWatcherMapDispatchReturnType'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetUserExtensionSuccessAction } from 'actionCreators/get/GetUserExtension/createGetUserExtensionSuccessAction'
import { createGetUserExtensionFailureAction } from 'actionCreators/get/GetUserExtension/createGetUserExtensionFailureAction'

export const UserExtensionWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UserExtensionWatcherConnectedProps
): UserExtensionWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'UserExtensionWatcherMapDispatch',
          increment,
          watcherType: 'userExtension',
        })
      )
    },

    onNext: (querySnapshot: firebase.firestore.DocumentSnapshot) => {
      const item = querySnapshot.data() as UserExtension | undefined

      if (item) {
        const getUserExtensionSuccessAction = createGetUserExtensionSuccessAction(
          {
            requestValues: {
              userId: props.userId,
              teamId: props.teamId,
            },
            successValues: {
              item,
            },
          }
        )

        dispatch(getUserExtensionSuccessAction)
      }
    },

    onFirebaseError: (error: Error) => {
      const firestoreErrorAction = createFirebaseErrorAction({
        error: error as FirebaseError,
        description: 'UserExtensionWatcherMapDispatch',
      })

      dispatch(firestoreErrorAction)

      const getUserExtensionFailureAction = createGetUserExtensionFailureAction(
        {
          requestValues: {
            userId: props.userId,
            teamId: props.teamId,
          },
        }
      )

      dispatch(getUserExtensionFailureAction)
    },
  }
}
