import { getCurrentTask } from './getCurrentTask'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { Tag } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
  taskId: string
  tagId: string
}

export const getTaskTag = ({
  state,
  taskId,
  tagId,
}: Props): undefined | Tag => {
  const task = getCurrentTask({ state, taskId })
  const tags = task && task.tags

  return tags && tags[tagId]
}
