// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // PlansWatcherConnectedProps,
  PlansWatcherMapStateReturnType,
} from './types'

export const PlansWatcherMapState = (): // state: StateReducers,
// props: PlansWatcherConnectedProps,
PlansWatcherMapStateReturnType => {
  return {}
}
