// import { Dispatch, Action } from 'redux'

import {
  // HistoryDropdownConnectedProps,
  HistoryDropdownMapDispatchReturnType,
} from './types'

export const HistoryDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: HistoryDropdownConnectedProps,
): HistoryDropdownMapDispatchReturnType => {
  return {
  }
}
