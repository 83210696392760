import { connect } from 'react-redux'
import { TaskSummarySpan } from './TaskSummarySpan'
import { TaskSummarySpanMapState } from './TaskSummarySpanMapState'
import { TaskSummarySpanMapDispatch } from './TaskSummarySpanMapDispatch'

import { TaskSummarySpanMapStateReturnType } from './types/TaskSummarySpanMapStateReturnType'
import { TaskSummarySpanMapDispatchReturnType } from './types/TaskSummarySpanMapDispatchReturnType'
import { TaskSummarySpanConnectedProps } from './types/TaskSummarySpanConnectedProps'


export const TaskSummarySpanConnected = connect<
  TaskSummarySpanMapStateReturnType,
  TaskSummarySpanMapDispatchReturnType,
  TaskSummarySpanConnectedProps>(TaskSummarySpanMapState,
    TaskSummarySpanMapDispatch)(TaskSummarySpan)
