import { connect } from 'react-redux'
import { ReportMain } from './ReportMain'
import { ReportMainMapState } from './ReportMainMapState'
import { ReportMainMapDispatch } from './ReportMainMapDispatch'
import { ReportMainMapStateReturnType } from './types/ReportMainMapStateReturnType'
import { ReportMainMapDispatchReturnType } from './types/ReportMainMapDispatchReturnType'
import { ReportMainConnectedProps } from './types/ReportMainConnectedProps'

export const ReportMainConnected = connect<
  ReportMainMapStateReturnType,
  ReportMainMapDispatchReturnType,
  ReportMainConnectedProps>(ReportMainMapState,
    ReportMainMapDispatch)(ReportMain)
