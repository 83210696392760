import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCyclePlanIds } from 'state/getters/getCyclePlanIds'

import { TaskPlanSpanConnectedProps } from './types/TaskPlanSpanConnectedProps'
import { TaskPlanSpanMapStateReturnType } from './types/TaskPlanSpanMapStateReturnType'


export const TaskPlanSpanMapState = (
  state: StateReducers,
  props: TaskPlanSpanConnectedProps,
): TaskPlanSpanMapStateReturnType => {
  const task = getCurrentTask({ state, taskId: props.taskId })

  const {
    previousPlanId,
    currentPlanId,
    nextPlanId,
  } = getCyclePlanIds({ state })

  const isPreviousPlan = (previousPlanId && task?.planId === previousPlanId) || false
  const isNextPlan = (nextPlanId && task?.planId === nextPlanId) || false
  const isCurrentPlan = (currentPlanId && task?.planId === currentPlanId) || false
  const isBacklog = !task?.planId

  return {
    isPreviousPlan,
    isNextPlan,
    isCurrentPlan,
    isBacklog,
  }
}
