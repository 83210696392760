import { getTaskCount } from 'state/getters/getTaskCount'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { ReportMainConnectedProps } from './types/ReportMainConnectedProps'
import { ReportMainMapStateReturnType } from './types/ReportMainMapStateReturnType'

export const ReportMainMapState = (
  state: StateReducers,
  _props: ReportMainConnectedProps
): ReportMainMapStateReturnType => {
  const taskCount = getTaskCount({ state })

  return {
    taskCount,
  }
}
