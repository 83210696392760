import { Task } from 'firebaseFunctions/types'
import { MOCK_TEAM_A_ID } from '../mockTeams/mockTeamsConstants'
import { TaskReducer } from 'state/reducers/types/TaskReducer'

const data: Task = {
  summary: 'mockTaskD summary',
  status: 'In Progress',
  order: 4,
}

export const mockTaskD: TaskReducer = {
  data,
  teamId: MOCK_TEAM_A_ID,
}
