// import { ShapesSolidIconStyles } from './ShapesSolidIconStyles'
import React from 'react'
import { ShapesSolidIconProps } from './types/ShapesSolidIconProps'
import { iconStyles } from 'styles/iconStyles'

export const ShapesSolidIcon = ({
  className = '',
}: ShapesSolidIconProps): JSX.Element => {
  return (
    <svg
      className={`ShapesSolidIcon ${className} ${iconStyles}`}
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M128,256A128,128,0,1,0,256,384,128,128,0,0,0,128,256Zm379-54.86L400.07,18.29a37.26,37.26,0,0,0-64.14,0L229,201.14C214.76,225.52,232.58,256,261.09,256H474.91C503.42,256,521.24,225.52,507,201.14ZM480,288H320a32,32,0,0,0-32,32V480a32,32,0,0,0,32,32H480a32,32,0,0,0,32-32V320A32,32,0,0,0,480,288Z"
      ></path>
    </svg>
  )
}
