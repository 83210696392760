import { take, put } from 'redux-saga/effects'
import { GOOGLE_SIGN_IN_SUCCESS } from 'actions'
import {
  GoogleSignInSuccessAction,
  createSignInSuccessAction,
} from 'actionCreators/auth'
import { createGetUsersSuccessAction } from 'actionCreators/get/createGetUsersSuccessAction'
import { User } from '@sentry/browser'

export const googleSignInSuccessF = function*() {
  while (1) {
    const action: GoogleSignInSuccessAction = yield take([
      GOOGLE_SIGN_IN_SUCCESS,
    ])

    const { user } = action.values
    const userId = user?.uid
    const email = user?.email || undefined
    const displayName = user?.displayName || undefined
    const emailVerified = user?.emailVerified || false
    const photoURL = user?.photoURL || undefined

    if (userId && email) {
      const user: User = {
        email,
        emailVerified,
        displayName,
        photoURL,
      }

      yield put(
        createSignInSuccessAction({
          userId,
          user,
        })
      )

      yield put(
        createGetUsersSuccessAction({
          successValues: {
            [userId]: user,
          },
        })
      )
    }
  }
}
