import React, { useCallback } from 'react'
import { CloneTaskButtonProps } from './types/CloneTaskButtonProps'
import { ActionButton } from '../ActionButton'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { CloneIcon } from '../../icon/CloneIcon/CloneIcon'

export const CloneTaskButton = ({
  className = '',
  clone,
  taskId,
}: CloneTaskButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    clone(taskId)
  }, [clone, taskId])

  return (
    <ActionButton
      className={`CloneTaskButton ${className}`}
      title="Clone"
      onClick={onClickCallback}
    >
      <IconAndDiv icon={<CloneIcon />}>Clone</IconAndDiv>
    </ActionButton>
  )
}
