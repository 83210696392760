import { connect } from 'react-redux'
import { UploadedFileWatcher } from './UploadedFileWatcher'
import { UploadedFileWatcherMapState } from './UploadedFileWatcherMapState'
import { UploadedFileWatcherMapDispatch } from './UploadedFileWatcherMapDispatch'

import { UploadedFileWatcherMapStateReturnType } from './types/UploadedFileWatcherMapStateReturnType'
import { UploadedFileWatcherMapDispatchReturnType } from './types/UploadedFileWatcherMapDispatchReturnType'
import { UploadedFileWatcherConnectedProps } from './types/UploadedFileWatcherConnectedProps'


export const UploadedFileWatcherConnected = connect<
  UploadedFileWatcherMapStateReturnType,
  UploadedFileWatcherMapDispatchReturnType,
  UploadedFileWatcherConnectedProps>(UploadedFileWatcherMapState,
    UploadedFileWatcherMapDispatch)(UploadedFileWatcher)
