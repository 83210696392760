import { connect } from 'react-redux'
import { UploadTeamLogoButton } from './UploadTeamLogoButton'
import { UploadTeamLogoButtonMapState } from './UploadTeamLogoButtonMapState'
import { UploadTeamLogoButtonMapDispatch } from './UploadTeamLogoButtonMapDispatch'
import { UploadTeamLogoButtonMapStateReturnType } from './types/UploadTeamLogoButtonMapStateReturnType'
import { UploadTeamLogoButtonMapDispatchReturnType } from './types/UploadTeamLogoButtonMapDispatchReturnType'
import { UploadTeamLogoButtonConnectedProps } from './types/UploadTeamLogoButtonConnectedProps'

export const UploadTeamLogoButtonConnected = connect<
  UploadTeamLogoButtonMapStateReturnType,
  UploadTeamLogoButtonMapDispatchReturnType,
  UploadTeamLogoButtonConnectedProps
>(
  UploadTeamLogoButtonMapState,
  UploadTeamLogoButtonMapDispatch
)(UploadTeamLogoButton)
