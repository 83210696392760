import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'
import { getIsProFromState } from './getIsProFromState'
import { getMaxUploadSize } from './getMaxUploadSize'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getTeamAvailableBytes = ({ state, teamId }: Props): number => {
  const teamReducer = getCurrentTeamReducer({ state, teamId })
  const fileUploadedBytes = Number(teamReducer?.stats?.fileUploadedBytes)
  const isPro = getIsProFromState({ state, teamId })
  const userCount = teamReducer?.stats?.userCount || 0

  const { gigabytesPerUserFree, gigabytesPerUserPro } = getMaxUploadSize({
    state,
    teamId,
  })

  const uploadCapacityGigabytesPerUser = isPro
    ? gigabytesPerUserPro
    : gigabytesPerUserFree
  const capacityBytes = uploadCapacityGigabytesPerUser * userCount * 1000000000

  return capacityBytes - fileUploadedBytes
}
