import { GET_TEAM_STAT_FAILURE } from 'actions'

import { GetTeamStatFailureAction } from './types/GetTeamStatFailureAction'
import { GetTeamStatRequestActionValues } from './types/GetTeamStatRequestActionValues'


interface Props {
  requestValues?: GetTeamStatRequestActionValues
}

export const createGetTeamStatFailureAction = ({
  requestValues,
}: Props): GetTeamStatFailureAction => {
  return {
    type: GET_TEAM_STAT_FAILURE,
    requestValues,
  }
}
