import { Dispatch, Action } from 'redux'
import { NewUserFormMapDispatchReturnType } from './types/NewUserFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_USER_INVITED, SUBMIT_USER } from 'actions'

export const NewUserFormMapDispatch = (
  dispatch: Dispatch<Action>
): NewUserFormMapDispatchReturnType => {
  return {
    submit: ({ isInvite, email, password, displayName }) => {
      dispatch(
        createSubmitAction({
          description: 'NewUserFormMapDispatch',
          values: {
            email,
            password,
            displayName,
          },
          type: isInvite ? SUBMIT_USER_INVITED : SUBMIT_USER,
        })
      )
    },
  }
}
