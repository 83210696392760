import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { pickBy } from 'lodash'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { SUBMIT_DELETE_TASK_TAG, SUBMIT_DELETE_TASK_TAG_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteTaskTagF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_TASK_TAG])
    const { taskId, tagId } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const teamId = getCurrentTeamId({ state })
    const task = taskId ? getCurrentTask({ state, taskId }) : undefined

    if (taskId && teamId && tagId && currentUserId && currentUserDisplayName) {
      const newTags = pickBy(task?.tags, (_existingTag, existingTagId) => {
        return existingTagId !== tagId
      })

      const items: TasksReducer = {
        [taskId]: {
          data: {
            tags: newTags,
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteTaskTagF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_TASK_TAG_DONE,
    })
  }
}
