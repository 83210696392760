import { css } from 'emotion'
import { NEUTRAL_HIGH_LIGHT_COLOR, LINK_COLOR } from 'constants/styleConstants'

export const DueDateDropdownStyles = css`
  position: relative;

  .react-calendar {
    border: none;
  }

  .removeButton {
    margin-right: 8px;
  }

  .Dropdown {
    .container {
      left: -100px;
      width: 352px;
    }
  }

  .react-calendar__tile--now {
    background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
  }

  .react-calendar__tile--active {
    background-color: ${LINK_COLOR};
  }

  .react-calendar__navigation__arrow {
    font-size: 20px;
  }

  .react-calendar__navigation {
    margin-bottom: 0px;
  }

  .react-calendar__year-view .react-calendar__tile {
    padding: 16px 8px;
  }
`
