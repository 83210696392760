import { connect } from 'react-redux'
import { SelectTagField } from './SelectTagField'
import { SelectTagFieldMapState } from './SelectTagFieldMapState'
import { SelectTagFieldMapDispatch } from './SelectTagFieldMapDispatch'

import { SelectTagFieldMapStateReturnType } from './types/SelectTagFieldMapStateReturnType'
import { SelectTagFieldMapDispatchReturnType } from './types/SelectTagFieldMapDispatchReturnType'
import { SelectTagFieldConnectedProps } from './types/SelectTagFieldConnectedProps'


export const SelectTagFieldConnected = connect<
  SelectTagFieldMapStateReturnType,
  SelectTagFieldMapDispatchReturnType,
  SelectTagFieldConnectedProps>(SelectTagFieldMapState,
    SelectTagFieldMapDispatch)(SelectTagField)
