import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // TaskFileFieldConnectedProps,
  TaskFileFieldMapStateReturnType,
} from './types'

export const TaskFileFieldMapState = (
  _state: StateReducers
  // props: TaskFileFieldConnectedProps,
): TaskFileFieldMapStateReturnType => {
  return {}
}
