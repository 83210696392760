import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { WorkspaceSubscriptionMainConnectedProps } from './types/WorkspaceSubscriptionMainConnectedProps'
import { WorkspaceSubscriptionMainMapStateReturnType } from './types/WorkspaceSubscriptionMainMapStateReturnType'
import { getIsProFromState } from '../../../state/getters/getIsProFromState'
import { getMaxUserCount } from '../../../state/getters/getMaxUserCount'
import { getMaxUploadSize } from '../../../state/getters/getMaxUploadSize'

export const WorkspaceSubscriptionMainMapState = (
  state: StateReducers,
  _props: WorkspaceSubscriptionMainConnectedProps
): WorkspaceSubscriptionMainMapStateReturnType => {
  const currentTeamReducer = getCurrentTeamReducer({ state })
  const teamId = getCurrentTeamId({ state })
  const subscription = currentTeamReducer?.stats?.subscription
  const subscriptionUserId = subscription?.userId || undefined
  const isPro = getIsProFromState({ state })
  const { maxUserCountFree, maxUserCountPro } = getMaxUserCount({ state })
  const { gigabytesPerUserFree, gigabytesPerUserPro } = getMaxUploadSize({
    state,
  })

  return {
    subscriptionUserId,
    isPro,
    teamId,
    canGetToken: !!state.currentUserId,
    maxUserCountFree,
    maxUserCountPro,
    gigabytesPerUserFree,
    gigabytesPerUserPro,
  }
}
