import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_TEAM_NAME, SUBMIT_TEAM_NAME_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { TEAM_SETTINGS_ROUTE } from 'routes'
import { patchTeamsSaga } from 'flows/sagas/patch/patchTeamsSaga'

export const submitTeamNameF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TEAM_NAME])
    const { displayName } = action.values
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })

    if (displayName && teamId) {
      try {
        yield spawn(patchTeamsSaga, {
          items: {
            [teamId]: {
              data: {
                displayName,
              },
            },
          },
        })

        yield put(
          createHistoryAction({
            description: 'submitTeamNameF',
            pathname: TEAM_SETTINGS_ROUTE,
            query: {
              teamId,
            },
          })
        )
      } catch (error) {
        const firestoreErrorAction = createFirebaseErrorAction({
          error: error as FirebaseError,
          description: 'submitTeamNameF',
        })

        yield put(firestoreErrorAction)
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTeamNameF',
        })
      )
    }

    yield put({
      type: SUBMIT_TEAM_NAME_DONE,
    })
  }
}
