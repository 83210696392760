import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getIsAndroidNative } from 'state/getters/getIsAndroidNative'
import { googleAuthProvider, UserCredential, firebaseSdk } from 'sdks/firebase'
import { GOOGLE_SIGN_IN_REQUEST } from 'actions'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { POPUP_CLOSED_BY_USER } from 'firebaseFunctions/constants/failureCodesConstants'
import {
  createGoogleSignInSuccessAction,
  createGoogleSignInErrorAction,
} from 'actionCreators/auth'

export const googleSignInF = function*() {
  while (1) {
    yield take([GOOGLE_SIGN_IN_REQUEST])

    const state: StateReducers = yield select(selectState)

    if (getIsAndroidNative({ state })) {
      window.androidInterface?.googleSignIn()
      // yield firebase.auth.GoogleAuthProvider.credential(idToken?: string | null,
      // accessToken?: string | null)
    } else {
      try {
        const userCredential: UserCredential = yield firebaseSdk
          .auth()
          .signInWithPopup(googleAuthProvider)

        const googleSignInSuccessAction = createGoogleSignInSuccessAction({
          userCredential,
        })

        yield put(googleSignInSuccessAction)
      } catch (error) {
        if (error.code !== POPUP_CLOSED_BY_USER) {
          yield put(
            createFirebaseErrorAction({
              error,
              description: 'googleSignInF',
            })
          )

          yield put(createGoogleSignInErrorAction())
        }
      }
    }
  }
}
