import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getNotifications } from 'state/getters/getNotifications'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_READ, SUBMIT_READ_DONE } from 'actions'
import { createPatchUserExtensionRequestDelayAction } from 'actionCreators/patch/PatchUserExtension/createPatchUserExtensionRequestDelayAction'

export const submitReadF = function*() {
  while (1) {
    // TODO: combine with setting task history
    const action: SubmitAction = yield take([SUBMIT_READ])
    const { notificationGroup, index } = action.values
    const state: StateReducers = yield select(selectState)
    const userId = state.currentUserId
    const teamId = getCurrentTeamId({ state })

    if (notificationGroup && index !== undefined && userId) {
      const notifications = getNotifications({ state, notificationGroup })

      const newNotifications = notifications.map((notification, i) => {
        if (index === i) {
          return {
            ...notification,
            unreadCount: 0,
          }
        }

        return notification
      })

      const newUserExtension =
        notificationGroup === 'messages'
          ? { messageNotifications: newNotifications }
          : { activityNotifications: newNotifications }

      yield put(
        createPatchUserExtensionRequestDelayAction({
          requestValues: {
            params: {
              teamId,
              userId,
            },
            item: {
              ...newUserExtension,
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitReadF',
        })
      )
    }

    yield put({
      type: SUBMIT_READ_DONE,
    })
  }
}
