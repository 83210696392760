import { UploadedFileDropdownStyles } from './UploadedFileDropdownStyles'
import React, { useState, useEffect } from 'react'
import { UploadedFileDropdownProps } from './types/UploadedFileDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { ActionButton } from 'components/button/ActionButton'
import { DeleteAndConfirmWidget } from 'components/widget/DeleteAndConfirmWidget'
import { storageSdk } from 'sdks'
import { EllipsisVertialIcon } from 'components/icon/EllipsisVertialIcon/EllipsisVertialIcon'
import { DownloadIcon } from 'components/icon/DownloadIcon/DownloadIcon'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'

export const UploadedFileDropdown = ({
  showUploadedFileDropdown,
  onDeleteClick,
  path,
  uploadedFileId,
}: UploadedFileDropdownProps): JSX.Element => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)

  useEffect(() => {
    const getDownloadURL = async (path: string) => {
      const ref = storageSdk().ref()
      const url = await ref.child(path).getDownloadURL()

      setDownloadUrl(url)
    }

    if (path) {
      getDownloadURL(path)
    }
  }, [path])

  return (
    <div
      className={`UploadedFileDropdown _${uploadedFileId} ${UploadedFileDropdownStyles}`}
    >
      <DropdownButtonConnected
        dropdownType="uploadedFile"
        title="More options"
        watchSelector={`.UploadedFileDropdown._${uploadedFileId}`}
        id={uploadedFileId}
      >
        <EllipsisVertialIcon />
      </DropdownButtonConnected>

      {showUploadedFileDropdown && (
        <DropdownConnected>
          {downloadUrl && (
            <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
              <ActionButton title="download">
                <IconAndDiv icon={<DownloadIcon />}>Download</IconAndDiv>
              </ActionButton>
            </a>
          )}

          <DeleteAndConfirmWidget onDeleteClick={onDeleteClick} />
        </DropdownConnected>
      )}
    </div>
  )
}
