import * as React from 'react'
import { StatusColorDropdownConnected } from './StatusColorDropdownConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const StatusColorDropdownExamples = () => {
  return (
    <div className="StatusColorDropdownExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <StatusColorDropdownConnected status="foo" />
      </Provider>
    </div>
  )
}
