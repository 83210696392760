import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import {
  // TaskFileListConnectedProps,
  TaskFileListMapStateReturnType,
} from './types'
import { FirebaseTimestamp } from 'sdks'

export const TaskFileListMapState = (
  state: StateReducers
  // props: TaskFileListConnectedProps,
): TaskFileListMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const teamId = getCurrentTeamId({ state })
  const task = getCurrentTask({ state })

  const updatedSeconds =
    task?.updated instanceof FirebaseTimestamp
      ? task?.updated.seconds
      : undefined

  return {
    taskId,
    teamId,
    updatedSeconds,
  }
}
