import { SET_SCROLL_POSITION } from 'actions'
import { ScrollPosition } from 'types/ScrollPosition'
import { SetScrollPositionAction } from './types/SetScrollPositionAction'

interface Props {
  scrollPosition: ScrollPosition
  description: string
}

export const createSetScrollPositionAction = ({
  scrollPosition,
  description,
}: Props): SetScrollPositionAction => {
  return {
    type: SET_SCROLL_POSITION,
    scrollPosition,
    description,
  }
}
