import { TaskChangeCardStyles } from './TaskChangeCardStyles'
import React from 'react'
import { TaskChangeCardProps } from './types/TaskChangeCardProps'
import { AvatarConnected } from 'components/widget/Avatar'
import { DisplayNameSpanConnected } from 'components/span/DisplayNameSpan'
import moment from 'moment'
import { MarkdownDiv } from 'components/div/MarkdownDiv'

export const TaskChangeCard = ({
  lastModifiedUserId,
  summaryAfter,
  summaryBefore,
  descriptionAfter,
  descriptionBefore,
  createdSeconds,
  isBigScreen,
}: TaskChangeCardProps): JSX.Element => {
  const isFirstSummary = summaryAfter && !summaryBefore
  const isFirstDescription = descriptionAfter && !descriptionBefore
  const isBigScreenClass = isBigScreen ? ' isBigScreen' : ''

  return (
    <div
      className={'TaskChangeCard ' + TaskChangeCardStyles + isBigScreenClass}
    >
      <header className="flexAlign">
        <AvatarConnected userId={lastModifiedUserId} />

        <div className="displayNameAndTime">
          <DisplayNameSpanConnected userId={lastModifiedUserId} />
          {createdSeconds && (
            <span className="created">
              {moment.unix(createdSeconds).format('MMM Do YY h:mm a')}
            </span>
          )}
        </div>
      </header>

      <main>
        {summaryAfter && (
          <>
            <div className="afterHeader">
              {isFirstSummary && 'New'} Summary {!isFirstSummary && 'After'}
            </div>

            <div className="afterContainer">
              <p>{summaryAfter}</p>
            </div>

            {!isFirstSummary && (
              <>
                <div className="beforeHeader">Summary Before</div>

                <div className="beforeContainer">
                  <p>{summaryBefore}</p>
                </div>
              </>
            )}
          </>
        )}
        {descriptionAfter && (
          <>
            <div className="afterHeader">
              {isFirstDescription && 'New'} Description{' '}
              {!isFirstDescription && 'After'}
            </div>

            <div className="afterContainer">
              <MarkdownDiv>{descriptionAfter}</MarkdownDiv>
            </div>

            {!isFirstDescription && (
              <>
                <div className="beforeHeader">Description Before</div>

                <div className="beforeContainer">
                  <MarkdownDiv>{descriptionBefore}</MarkdownDiv>
                </div>
              </>
            )}
          </>
        )}
      </main>
    </div>
  )
}
