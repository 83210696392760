import { PATCH_TASKS_REQUEST } from 'actions'
import { PatchTasksRequestAction } from './types/PatchTasksRequestAction'
import { PatchTasksRequestActionValues } from './types/PatchTasksRequestActionValues'

interface Props {
  requestValues: PatchTasksRequestActionValues,
}

export const createPatchTasksRequestAction = ({ requestValues }: Props): PatchTasksRequestAction => {
  return {
    type: PATCH_TASKS_REQUEST,
    requestValues,
  }
}
