import { connect } from 'react-redux'
import { MessageActionDropdown } from './MessageActionDropdown'
import { MessageActionDropdownMapState } from './MessageActionDropdownMapState'
import { MessageActionDropdownMapDispatch } from './MessageActionDropdownMapDispatch'

import { MessageActionDropdownMapStateReturnType } from './types/MessageActionDropdownMapStateReturnType'
import { MessageActionDropdownMapDispatchReturnType } from './types/MessageActionDropdownMapDispatchReturnType'
import { MessageActionDropdownConnectedProps } from './types/MessageActionDropdownConnectedProps'


export const MessageActionDropdownConnected = connect<
  MessageActionDropdownMapStateReturnType,
  MessageActionDropdownMapDispatchReturnType,
  MessageActionDropdownConnectedProps>(MessageActionDropdownMapState,
    MessageActionDropdownMapDispatch)(MessageActionDropdown)
