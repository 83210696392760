import { css } from 'emotion'
import {
  NEUTRAL_BORDER_COLOR,
  LINK_COLOR,
  NEUTRAL_HIGH_LIGHT_COLOR,
} from 'constants/styleConstants'

export const NotificationListStyles = css`
  > li {
    border-top: 1px solid ${NEUTRAL_BORDER_COLOR};
    background-color: #fff;

    &:hover {
      background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
    }

    &:first-child {
      border-top: none;
      background-color: initial;
      padding: 0px 8px;
    }
  }

  .empty {
    padding: 16px;
  }

  .ReadAllButton {
    font-size: 0.75em;
    color: ${LINK_COLOR};
  }
`
