import { PATCH_CUSTOM_CLAIM_REQUEST } from 'actions'
import { PatchCustomClaimRequestAction } from './types/PatchCustomClaimRequestAction'
import { PatchCustomClaimRequestActionValues } from './types/PatchCustomClaimRequestActionValues'

interface Props {
  requestValues: PatchCustomClaimRequestActionValues,
}

export const createPatchCustomClaimRequestAction = ({ requestValues }: Props): PatchCustomClaimRequestAction => {
  return {
    type: PATCH_CUSTOM_CLAIM_REQUEST,
    requestValues,
  }
}
