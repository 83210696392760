import { Dispatch, Action } from 'redux'
import { UpdateTaskStatusesFormMapDispatchReturnType } from './types/UpdateTaskStatusesFormMapDispatchReturnType'
import { SUBMIT_UPDATE_TASK_STATUSES } from 'actions'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const UpdateTaskStatusesFormMapDispatch = (
  dispatch: Dispatch<Action>
): UpdateTaskStatusesFormMapDispatchReturnType => {
  return {
    submit: ({ taskStatusesCsv }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_UPDATE_TASK_STATUSES,
          values: { taskStatusesCsv },
          description: 'UpdateTaskStatusesFormMapDispatch',
        })
      )
    },
  }
}
