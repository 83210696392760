import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { Tag } from 'firebaseFunctions/types'
import { SUBMIT_TASK_TAG, SUBMIT_TASK_TAG_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { SubmitTaskTagAction } from 'actionCreators/submit/SubmitTaskTag'
import { createUnexpectedErrorAction } from '../../actionCreators/createUnexpectedErrorAction'

export const submitTaskTagF = function*() {
  while (1) {
    const action: SubmitTaskTagAction = yield take([SUBMIT_TASK_TAG])
    const { id, taskId, type, displayName } = action.values
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const task = getCurrentTask({ state, taskId })
    const tags = task && task.tags

    if (taskId && teamId && currentUserId && type && currentUserDisplayName) {
      const newTag: Tag = {
        type,
        displayName,
      }

      const items: TasksReducer = {
        [taskId]: {
          data: {
            tags: {
              ...tags,
              [id]: newTag,
            },
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items: items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskTagF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_TAG_DONE,
    })
  }
}
