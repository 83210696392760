import { connect } from 'react-redux'
import { ResetPasswordForm } from './ResetPasswordForm'
import { ResetPasswordFormMapState } from './ResetPasswordFormMapState'
import { ResetPasswordFormMapDispatch } from './ResetPasswordFormMapDispatch'
import { ResetPasswordFormMapStateReturnType } from './types/ResetPasswordFormMapStateReturnType'
import { ResetPasswordFormMapDispatchReturnType } from './types/ResetPasswordFormMapDispatchReturnType'
import { ResetPasswordFormConnectedProps } from './types/ResetPasswordFormConnectedProps'

export const ResetPasswordFormConnected = connect<
  ResetPasswordFormMapStateReturnType,
  ResetPasswordFormMapDispatchReturnType,
  ResetPasswordFormConnectedProps
>(
  ResetPasswordFormMapState,
  ResetPasswordFormMapDispatch
)(ResetPasswordForm)
