import { StateReducers } from 'state/reducers/types/StateReducers'
import { getBackLocation } from 'state/getters/getBackLocation'

import {
  // GoBackLinkConnectedProps,
  GoBackLinkMapStateReturnType,
} from './types'

export const GoBackLinkMapState = (
  state: StateReducers
  // props: GoBackLinkConnectedProps,
): GoBackLinkMapStateReturnType => {
  const { pathname, teamId, taskId } = getBackLocation({ state })

  return { pathname, teamId, taskId }
}
