import { LogoWidgetStyles } from './LogoWidgetStyles'
import * as React from 'react'
import { LogoWidgetProps } from './types/LogoWidgetProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { CatIcon } from 'components/icon/CatIcon/CatIcon'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'

export const LogoWidget = ({
  showName,
  route,
}: LogoWidgetProps): JSX.Element => {
  if (route) {
    return (
      <CustomLinkConnected
        title={`EggKat`}
        route={route}
        withCurrentQuery={true}
        className={'LogoWidget ' + LogoWidgetStyles}
      >
        <IconAndDiv icon={<CatIcon />}>{showName && 'EggKat'}</IconAndDiv>
      </CustomLinkConnected>
    )
  } else {
    return (
      <div className={'LogoWidget ' + LogoWidgetStyles}>
        <IconAndDiv icon={<CatIcon />}>{showName && 'EggKat'}</IconAndDiv>
      </div>
    )
  }
}
