import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'

import {
  GET_UPLOADED_FILES_SUCCESS,
  DELETE_UPLOADED_FILES_REQUEST,
  DELETE_UPLOADED_FILES_SUCCESS,
  CLEAR_TEAM_SCOPE,
} from 'actions'
import { UploadedFilesReducer } from './types/UploadedFilesReducer'
import { UploadedFileReducer } from './types/UploadedFileReducer'

export const uploadedFilesReducer = createGenericReducer<
  UploadedFilesReducer,
  UploadedFileReducer
>({
  getSuccessAction: GET_UPLOADED_FILES_SUCCESS,
  deleteRequestAction: DELETE_UPLOADED_FILES_REQUEST,
  deleteSuccessAction: DELETE_UPLOADED_FILES_SUCCESS,
  clearActions: [CLEAR_TEAM_SCOPE],
  // debug: true,
})
