import { put } from 'redux-saga/effects'
import { postUserApi } from 'apis/post/postUserApi'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { PostUserSuccessPayload } from 'firebaseFunctions/user/types/PostUserSuccessPayload'
import { AxiosResponse } from 'axios'
import { createPostUserInvitedRequestAction } from 'actionCreators/post/PostUserInvited/createPostUserInvitedRequestAction'
import { createPostUserInvitedSuccessAction } from 'actionCreators/post/PostUserInvited/createPostUserInvitedSuccessAction'
import { createPostUserInvitedFailureAction } from 'actionCreators/post/PostUserInvited/createPostUserInvitedFailureAction'
import { PostUserApiProps } from 'apis/post/types/PostUserApiProps'

interface Props extends PostUserApiProps {}

export const postUserInvitedSaga = function*({ values }: Props) {
  yield put(
    createPostUserInvitedRequestAction({
      requestValues: values,
    })
  )

  try {
    const results: AxiosResponse<PostUserSuccessPayload> = yield postUserApi({
      values,
    })

    yield put(
      createPostUserInvitedSuccessAction({
        requestValues: values,
        successValues: results.data,
      })
    )
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'postUserInvitedSaga',
      })
    )

    yield put(
      createPostUserInvitedFailureAction({
        requestValues: values,
      })
    )
  }
}
