import { getUserExtensionRef } from 'firebaseFunctions/utilities/ref/get/getUserExtensionRef'
import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { GET_USER_EXTENSION_REQUEST } from 'actions'
import { UserExtension } from 'firebaseFunctions/types'
import { DocumentSnapshot } from '@google-cloud/firestore'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { GetUserExtensionRequestAction } from 'actionCreators/get/GetUserExtension/types/GetUserExtensionRequestAction'
import { createGetUserExtensionSuccessAction } from 'actionCreators/get/GetUserExtension/createGetUserExtensionSuccessAction'
import { createGetUserExtensionFailureAction } from 'actionCreators/get/GetUserExtension/createGetUserExtensionFailureAction'

export const getUserExtensionRequestF = function*() {
  while (1) {
    const action: GetUserExtensionRequestAction = yield take([
      GET_USER_EXTENSION_REQUEST,
    ])
    const { requestValues } = action
    const { userId, teamId } = requestValues

    try {
      const userDocument: DocumentSnapshot = yield getUserExtensionRef({
        firestore,
        teamId,
        userId,
      }).get()

      const userExtension = userDocument.data() as UserExtension | undefined

      if (userExtension) {
        yield put(
          createGetUserExtensionSuccessAction({
            requestValues,
            successValues: {
              item: userExtension,
            },
          })
        )
      }
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'getUserExtensionRequestF',
        })
      )

      yield put(
        createGetUserExtensionFailureAction({
          requestValues,
        })
      )
    }
  }
}
