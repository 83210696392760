import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

import {
  TeamNameWidgetConnectedProps,
  TeamNameWidgetMapStateReturnType,
} from './types'

export const TeamNameWidgetMapState = (
  state: StateReducers,
  props: TeamNameWidgetConnectedProps
): TeamNameWidgetMapStateReturnType => {
  const team = getCurrentTeam({ state, teamId: props.teamId })
  const displayName = team?.displayName

  return {
    displayName,
  }
}
