import { mockTaskA } from './mockTaskA'
import { mockTaskB } from './mockTaskB'
import { mockTaskC } from './mockTaskC'
import { mockTaskD } from './mockTaskD'
import { mockTaskE } from './mockTaskE'
import { TasksReducer }  from 'state/reducers/types/TasksReducer'

import {
  MOCK_TASK_A_ID,
  MOCK_TASK_B_ID,
  MOCK_TASK_C_ID,
  MOCK_TASK_D_ID,
  MOCK_TASK_E_ID,
} from './mockTasksConstants'

export const mockTasks: TasksReducer = {
  [MOCK_TASK_A_ID]: mockTaskA,
  [MOCK_TASK_B_ID]: mockTaskB,
  [MOCK_TASK_C_ID]: mockTaskC,
  [MOCK_TASK_D_ID]: mockTaskD,
  [MOCK_TASK_E_ID]: mockTaskE,
}
