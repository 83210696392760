import { POST_MESSAGES_SUCCESS } from 'actions'

import { PostMessagesSuccessAction } from './types/PostMessagesSuccessAction'
import { PostMessagesSuccessActionValues } from './types/PostMessagesSuccessActionValues'
import { PostMessagesRequestActionValues } from './types/PostMessagesRequestActionValues'


interface Props {
  successValues: PostMessagesSuccessActionValues
  requestValues: PostMessagesRequestActionValues
}

export const createPostMessagesSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostMessagesSuccessAction => {
  return {
    type: POST_MESSAGES_SUCCESS,
    requestValues,
    successValues,
  }
}
