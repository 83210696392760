import { StateReducers } from '../reducers/types/StateReducers'
import { getWindowWidth } from './getWindowWidth'

interface Props {
  state: StateReducers
}

export const getIsBigScreen = ({ state }: Props): boolean => {
  const windowWidth = getWindowWidth({ state })

  return windowWidth >= 1024
}
