import { StateReducers } from 'state/reducers/types/StateReducers'

import { UploadFilesCardConnectedProps } from './types/UploadFilesCardConnectedProps'
import { UploadFilesCardMapStateReturnType } from './types/UploadFilesCardMapStateReturnType'


export const UploadFilesCardMapState = (
  state: StateReducers,
  props: UploadFilesCardConnectedProps
): UploadFilesCardMapStateReturnType => {
  const uploadFiles = state.uploadFiles || undefined
  const newMessageFiles = uploadFiles?.newMessages
  const file = newMessageFiles?.[props.uploadFileIndex]
  const name = file?.name
  const size = file?.size

  return {
    name,
    size,
  }
}
