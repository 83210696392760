import { put } from 'redux-saga/effects'
import { firestore, clientServerTimestamp } from 'sdks'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { forEach } from 'lodash'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPostPlansRequestAction } from 'actionCreators/post/PostPlans/createPostPlansRequestAction'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { createPostPlansSuccessAction } from 'actionCreators/post/PostPlans/createPostPlansSuccessAction'
import { createPostPlansFailureAction } from 'actionCreators/post/PostPlans/createPostPlansFailureAction'
import { getPlanRef } from 'firebaseFunctions/utilities/ref/get/getPlanRef'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

interface Props {
  items: PlansReducer
}

export const postPlansSaga = function*({ items }: Props) {
  yield put(
    createPostPlansRequestAction({
      requestValues: {
        items,
      },
    })
  )

  const { key: planId, item: firstPlan } = getObjectFirstItem(items)

  if (firstPlan && planId) {
    try {
      const batch = firestore.batch()

      forEach(items, (planReducer, planId) => {
        const { data, teamId } = planReducer

        const ref = getPlanRef({
          firestore,
          teamId,
          planId,
        })

        batch.set(ref, {
          ...data,
          created: clientServerTimestamp(),
        })
      })

      yield batch.commit()

      yield put(
        createPostPlansSuccessAction({
          requestValues: { items },
          successValues: { items },
        })
      )
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'postPlansSaga',
        })
      )

      yield put(
        createPostPlansFailureAction({
          requestValues: { items },
        })
      )
    }
  } else {
    yield put(
      createUnexpectedErrorAction({
        description: 'postPlansSaga',
      })
    )
  }
}
