import { Action } from 'redux'
import { SESSION_STORAGE_KEY } from 'constants/environmentConstants'
import { removeUndefined } from 'firebaseFunctions/utilities/removeUndefined'
import { sessionStore } from 'utilities/sessionStore'
import { SessionStorage } from 'types/SessionStorage'
import { GET_SESSION_STORAGE_SUCCESS, SET_SESSION_STORAGE } from 'actions'
import {
  GetSessionStorageSuccessAction,
  SetSessionStorageAction,
} from 'actionCreators/sessionStorage'

export const sessionStorageReducer = (
  state?: SessionStorage,
  action?: Action
): SessionStorage | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SET_SESSION_STORAGE: {
      const setSessionStorageAction = action as SetSessionStorageAction

      const removedUndefined = removeUndefined<SessionStorage>(
        setSessionStorageAction.sessionStorage
      )

      const mergedWithState = {
        ...state,
        ...removedUndefined,
      }

      const newState = setSessionStorageAction.replace
        ? removedUndefined
        : mergedWithState

      sessionStore.set(SESSION_STORAGE_KEY, newState)

      return newState
    }

    case GET_SESSION_STORAGE_SUCCESS: {
      const sessionStorageSuccessAction = action as GetSessionStorageSuccessAction

      return {
        ...state,
        ...sessionStorageSuccessAction.sessionStorage,
      }
    }

    default: {
      return state || null
    }
  }
}
