// import { Dispatch, Action } from 'redux'

import {
  // UserAccountHeaderConnectedProps,
  UserAccountHeaderMapDispatchReturnType,
} from './types'

export const UserAccountHeaderMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: UserAccountHeaderConnectedProps,
): UserAccountHeaderMapDispatchReturnType => {
  return {
  }
}
