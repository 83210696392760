import moment from 'moment'

interface Props {
  seconds: number
  isCompact?: boolean
}

export const createDueDateString = ({ seconds, isCompact }: Props): string => {
  const now = moment()
  const dueMoment = moment.unix(seconds)
  const dayDiff = dueMoment.diff(now, 'days')

  if (!isCompact) {
    return dueMoment.format('M/DD/YY')
  }

  if (dayDiff === 0) {
    return 'today' // TODO: use getistoday when changed to seconds
  }

  if (dayDiff === 1) {
    return 'tomorrow'
  }

  if (dayDiff > 30 || dayDiff < 0) {
    return dueMoment.format('MMM Do')
  }

  return `in ${dayDiff} days`
}
