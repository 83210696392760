// import { ServiceWorkerMessageWatcherStyles } from './ServiceWorkerMessageWatcherStyles'
import { useEffect } from 'react'
import { ServiceWorkerMessageWatcherProps } from './types/ServiceWorkerMessageWatcherProps'

interface ServiceWorkerMessageEvent {
  data: {
    action: 'redirect'
    url: string
  }
}

export const ServiceWorkerMessageWatcher = ({
  redirect,
}: ServiceWorkerMessageWatcherProps): null => {
  useEffect(() => {
    if (navigator && navigator.serviceWorker) {
      const eventListener = (event: ServiceWorkerMessageEvent) => {
        const data = event.data

        if (data.action === 'redirect' && data.url) {
          redirect(data.url)
        }
      }
      navigator.serviceWorker.addEventListener('message', eventListener)

      return () => {
        navigator.serviceWorker.removeEventListener('message', eventListener)
      }
    }

    return
  })

  return null
}
