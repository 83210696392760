import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentUserExtension } from 'state/getters/getCurrentUserExtension'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import {
  // HistoryListConnectedProps,
  HistoryListMapStateReturnType,
} from './types'

export const HistoryListMapState = (
  state: StateReducers
  // props: HistoryListConnectedProps,
): HistoryListMapStateReturnType => {
  const userExtension = getCurrentUserExtension({ state })
  const taskHistoryItems = userExtension?.taskHistoryItems || []

  const historyTaskIds = taskHistoryItems.map(({ taskId }) => {
    return taskId || ''
  })

  const summaryItems = taskHistoryItems.map(({ taskSummary }) => {
    return taskSummary || ''
  })

  const createdSeconds = taskHistoryItems.map(({ createdSeconds }) => {
    return createdSeconds
  })

  return {
    taskIds: memoizeFlatArray(historyTaskIds, 'HistoryListMapState-taskIds'),
    summaryItems: memoizeFlatArray(
      summaryItems,
      'HistoryListMapState-summaryItems'
    ),
    createdSeconds: memoizeFlatArray(
      createdSeconds,
      'HistoryListMapState-createdSeconds'
    ),
  }
}
