import { LabelDropdownStyles } from './LabelDropdownStyles'
import React from 'react'
import { LabelDropdownProps } from './types/LabelDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { ActionButton } from 'components/button/ActionButton'
import { DeleteAndConfirmWidget } from 'components/widget/DeleteAndConfirmWidget'
import { EllipsisVertialIcon } from 'components/icon/EllipsisVertialIcon/EllipsisVertialIcon'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { ArchiveSolidIcon } from 'components/icon/ArchiveSolidIcon/ArchiveSolidIcon'

export const LabelDropdown = ({
  showLabelDropdown,
  deleteLabel,
  labelId,
  updateLabel,
  archived,
}: LabelDropdownProps): JSX.Element => {
  return (
    <div className={`LabelDropdown _${labelId} ${LabelDropdownStyles}`}>
      <DropdownButtonConnected
        dropdownType="label"
        title="More options"
        watchSelector={`.LabelDropdown._${labelId}`}
        id={labelId}
      >
        <EllipsisVertialIcon />
      </DropdownButtonConnected>

      {showLabelDropdown && (
        <DropdownConnected>
          <ActionButton
            title={archived ? 'Unarchive' : 'Archive'}
            onClick={updateLabel}
          >
            <IconAndDiv icon={<ArchiveSolidIcon />}>
              {archived ? 'Unarchive' : 'Archive'}
            </IconAndDiv>
          </ActionButton>
          <DeleteAndConfirmWidget onDeleteClick={deleteLabel} />
        </DropdownConnected>
      )}
    </div>
  )
}
