import * as React from 'react'
import { SelectInput } from './SelectInput'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

const options = [{
  value: 'Writer',
}, {
  value: 'Reader',
}, {
  value: 'Owner',
}]

export const SelectInputExamples = () => {
  return (
    <div className="SelectInputExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <SelectInput
          options={options}
          autoFocus={true}
          isSearchable={true}
          placeholder="Select a tag"
        />
      </Provider>
    </div>
  )
}
