import { CLIENT_QUERY_NAME_REFRESH_ID_TOKEN } from '../constants'

interface Props {
  publicUrl: string
  route: string
}

export const createActionCodeSettings = ({ publicUrl, route }: Props) => {
  return {
    url: `${publicUrl}${route}?${CLIENT_QUERY_NAME_REFRESH_ID_TOKEN}=true`,
  }
}
