import { TaskTagsMainStyles } from './TaskTagsMainStyles'
import React from 'react'
import { TaskTagsMainProps } from './types/TaskTagsMainProps'
import { TaskTagListConnected } from 'components/list/TaskTagList'

export const TaskTagsMain = ({
  taskId,
}: TaskTagsMainProps): JSX.Element => {
  return (
    <div className={'TaskTagsMain ' + TaskTagsMainStyles}>
      {taskId && <TaskTagListConnected isDetail={true} showTagDropdownFirst={true} taskId={taskId} />}
    </div>
  )
}
