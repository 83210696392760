import { css } from 'emotion'
import { LINK_COLOR, TEXT_COLOR_LIGHT } from 'constants/styleConstants'

export const ToggleInputStyles = css`
  .turnOff {
    color: ${LINK_COLOR};
  }

  .turnOn {
    color: ${TEXT_COLOR_LIGHT};
  }
`
