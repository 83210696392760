import { PATCH_TEAMS_FAILURE } from 'actions'

import { PatchTeamsFailureAction } from './types/PatchTeamsFailureAction'
import { PatchTeamsRequestActionValues } from './types/PatchTeamsRequestActionValues'


interface Props {
  requestValues?: PatchTeamsRequestActionValues
}

export const createPatchTeamsFailureAction = ({
  requestValues,
}: Props): PatchTeamsFailureAction => {
  return {
    type: PATCH_TEAMS_FAILURE,
    requestValues,
  }
}
