import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_DELETE_NEW_MESSAGE_FILE } from 'actions'

import { UploadFilesCardConnectedProps } from './types/UploadFilesCardConnectedProps'
import { UploadFilesCardMapDispatchReturnType } from './types/UploadFilesCardMapDispatchReturnType'


export const UploadFilesCardMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UploadFilesCardConnectedProps,
): UploadFilesCardMapDispatchReturnType => {
  return {
    deleteUploadFile: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_NEW_MESSAGE_FILE,
          values: {
            index: props.uploadFileIndex,
          }
        })
      )
    }
  }
}
