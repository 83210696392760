import { StateReducers } from '../reducers/types/StateReducers'
import { UserRoles } from 'firebaseFunctions/types'
import { getCurrentTeam } from './getCurrentTeam'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getUserRoles = ({
  state,
  teamId,
}: Props): UserRoles | undefined => {
  const currentTeam = getCurrentTeam({ state, teamId })
  const userRoles = currentTeam && currentTeam.userRoles

  return userRoles
}
