import { connect } from 'react-redux'
import { MessageActionList } from './MessageActionList'
import { MessageActionListMapState } from './MessageActionListMapState'
import { MessageActionListMapDispatch } from './MessageActionListMapDispatch'

import { MessageActionListMapStateReturnType } from './types/MessageActionListMapStateReturnType'
import { MessageActionListMapDispatchReturnType } from './types/MessageActionListMapDispatchReturnType'
import { MessageActionListConnectedProps } from './types/MessageActionListConnectedProps'


export const MessageActionListConnected = connect<
  MessageActionListMapStateReturnType,
  MessageActionListMapDispatchReturnType,
  MessageActionListConnectedProps>(MessageActionListMapState,
    MessageActionListMapDispatch)(MessageActionList)
