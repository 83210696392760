import { DraftHandleValue } from 'draft-js'

export interface HandleKeyCommandProps {
  command: string
  submit: () => void
}

export const handleKeyCommand = ({
  command,
  submit,
}: HandleKeyCommandProps): DraftHandleValue => {
  if (command === 'save') {
    // Tell Draft that we've taken care of this command
    submit()
    return 'handled'
  }

  // Tell Draft we haven't handled it.
  return 'not-handled'
}
