// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // MessagesWatcherConnectedProps,
  MessagesWatcherMapStateReturnType,
} from './types'

export const MessagesWatcherMapState = (): // state: StateReducers,
// props: MessagesWatcherConnectedProps,
MessagesWatcherMapStateReturnType => {
  return {}
}
