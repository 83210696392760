import { AdminMainStyles } from './AdminMainStyles'
import React from 'react'
import { AdminMainProps } from './types/AdminMainProps'
import { ActionButtonConnected } from 'components/button/ActionButton'
import { FormSpinnerConnected } from 'components/spinner/FormSpinner'

export const AdminMain = ({
  createUserExtension,
}: AdminMainProps): JSX.Element => {
  return (
    <div className={'AdminMain ' + AdminMainStyles}>
      <FormSpinnerConnected />

      <ul>
        <li>
          <ActionButtonConnected
            title="Create"
            type="button"
            onClick={createUserExtension}
          >
            Create User Extension
          </ActionButtonConnected>
        </li>
      </ul>
    </div>
  )
}
