import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getSortedTagIds } from 'state/getters/getSortedTagIds'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { TaskTagListConnectedProps } from './types/TaskTagListConnectedProps'
import { TaskTagListMapStateReturnType } from './types/TaskTagListMapStateReturnType'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskTagListMapState = (
  state: StateReducers,
  props: TaskTagListConnectedProps
): TaskTagListMapStateReturnType => {
  const taskId = props.taskId
  const task = getCurrentTask({ state, taskId: props.taskId })
  const tags = task && task.tags
  const tagIds = taskId ? getSortedTagIds({ state, taskId }) : []
  const canWrite = getCanWrite({ state })

  return {
    tags,
    canWrite,
    tagIds: memoizeFlatArray(tagIds, 'TaskTagListMapState-tagIds'),
  }
}
