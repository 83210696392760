export const SET_TEAM_LOGO_URL = 'SET_TEAM_LOGO_URL' // TODO: remove
export const SET_REACH_MAX_TASK_PAGE = 'SET_REACH_MAX_TASK_PAGE'

export const SET_GOAL_NAME_REQUEST = 'SET_GOAL_NAME_REQUEST'
export const SET_GOAL_NAME_SUCCESS = 'SET_GOAL_NAME_SUCCESS'

export const SET_HOW_MUCH_REQUEST = 'SET_HOW_MUCH_REQUEST'
export const SET_HOW_MUCH_SUCCESS = 'SET_HOW_MUCH_SUCCESS'

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'

export const SET_SHOWN_ITEM_REQUEST = 'SET_SHOWN_ITEM_REQUEST'

export const SET_SESSION_STORAGE = 'SET_SESSION_STORAGE'
export const SET_COOKIE_STORAGE = 'SET_COOKIE_STORAGE'
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE'
export const UNSET_ALERT_MESSAGE = 'UNSET_ALERT_MESSAGE'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
