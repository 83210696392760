// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // TaskTagCardConnectedProps,
  TaskTagCardMapDispatchReturnType,
} from './types'

export const TaskTagCardMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskTagCardConnectedProps,
): TaskTagCardMapDispatchReturnType => {
  return {
  }
}
