import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { PATCH_TEAMS_REQUEST } from 'actions'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { updateTeam } from 'firebaseFunctions/utilities/ref/update/updateTeam'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { PatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/types/PatchTeamsRequestAction'
import { createPatchTeamsSuccessAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsSuccessAction'
import { createPatchTeamsFailureAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsFailureAction'

export const patchTeamsRequestF = function*() {
  while (1) {
    const patchTeamsRequestAction: PatchTeamsRequestAction = yield take([
      PATCH_TEAMS_REQUEST,
    ])

    const { requestValues } = patchTeamsRequestAction
    const { items } = requestValues
    const { key: teamId, item: firstTeam } = getObjectFirstItem(items)

    if (teamId && firstTeam) {
      try {
        // TODO: handle when theres no internet
        yield updateTeam({
          firestore,
          teamId,
          team: firstTeam.data,
        })

        yield put(
          createPatchTeamsSuccessAction({
            requestValues,
            successValues: requestValues,
          })
        )
      } catch (error) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'patchTeamsRequestF',
          })
        )

        yield put(
          createPatchTeamsFailureAction({
            requestValues,
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'patchTeamsRequestF',
        })
      )
    }
  }
}
