import { connect } from 'react-redux'
import { UploadedFilesMain } from './UploadedFilesMain'
import { UploadedFilesMainMapState } from './UploadedFilesMainMapState'
import { UploadedFilesMainMapDispatch } from './UploadedFilesMainMapDispatch'

import { UploadedFilesMainMapStateReturnType } from './types/UploadedFilesMainMapStateReturnType'
import { UploadedFilesMainMapDispatchReturnType } from './types/UploadedFilesMainMapDispatchReturnType'
import { UploadedFilesMainConnectedProps } from './types/UploadedFilesMainConnectedProps'


export const UploadedFilesMainConnected = connect<
  UploadedFilesMainMapStateReturnType,
  UploadedFilesMainMapDispatchReturnType,
  UploadedFilesMainConnectedProps
>(
  UploadedFilesMainMapState,
  UploadedFilesMainMapDispatch
)(UploadedFilesMain)
