import { MOCK_TASK_B_ID, MOCK_TASK_C_ID } from '../mockTasks'
import { MOCK_USER_B_ID } from '../mockUsers'
import { firebaseSdk } from 'sdks/firebase'
import { MOCK_TEAM_A_ID } from '../mockTeams/mockTeamsConstants'
import { PlanReducer } from 'state/reducers/types/PlanReducer'

export const created = firebaseSdk.firestore.Timestamp.fromDate(
  new Date(1000000000001)
)

export const mockPlanB: PlanReducer = {
  data: {
    created,
    status: 'open',
    taskIds: [MOCK_TASK_B_ID, MOCK_TASK_C_ID],
    lastModifiedUserId: MOCK_USER_B_ID,
    start: created,
  },
  teamId: MOCK_TEAM_A_ID,
}
