import { connect } from 'react-redux'
import { UpdateTaskStatusesForm } from './UpdateTaskStatusesForm'
import { UpdateTaskStatusesFormMapState } from './UpdateTaskStatusesFormMapState'
import { UpdateTaskStatusesFormMapDispatch } from './UpdateTaskStatusesFormMapDispatch'
import { UpdateTaskStatusesFormMapStateReturnType } from './types/UpdateTaskStatusesFormMapStateReturnType'
import { UpdateTaskStatusesFormMapDispatchReturnType } from './types/UpdateTaskStatusesFormMapDispatchReturnType'
import { UpdateTaskStatusesFormConnectedProps } from './types/UpdateTaskStatusesFormConnectedProps'

export const UpdateTaskStatusesFormConnected = connect<
  UpdateTaskStatusesFormMapStateReturnType,
  UpdateTaskStatusesFormMapDispatchReturnType,
  UpdateTaskStatusesFormConnectedProps
>(
  UpdateTaskStatusesFormMapState,
  UpdateTaskStatusesFormMapDispatch
)(UpdateTaskStatusesForm)
