import { Dispatch, Action } from 'redux'
import { Message } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'

import { MessagesWatcherConnectedProps } from './types/MessagesWatcherConnectedProps'
import { MessagesWatcherMapDispatchReturnType } from './types/MessagesWatcherMapDispatchReturnType'

import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { MessageReducer } from 'state/reducers/types/MessageReducer'
import { createDeleteMessagesSuccessAction } from 'actionCreators/delete/DeleteMessages/createDeleteMessagesSuccessAction'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetMessagesSuccessAction } from 'actionCreators/get/GetMessages/createGetMessagesSuccessAction'
import { createGetMessagesFailureAction } from 'actionCreators/get/GetMessages/createGetMessagesFailureAction'

export const MessagesWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: MessagesWatcherConnectedProps
): MessagesWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'MessagesWatcherMapDispatch',
          increment,
          watcherType: 'messenger',
        })
      )
    },

    onNext: (querySnapshot: firebase.firestore.QuerySnapshot) => {
      const newMessagesMutable: MessagesReducer = {}
      const deletedMessagesMutable: MessagesReducer = {}

      querySnapshot.docChanges().forEach((change) => {
        const { doc } = change
        const message = doc.data() as Message | undefined
        const messageId = doc.id

        if (!message || !message.created) {
          // skip to prevent it overwriting the temp created time
          return
        }

        const messageReducer: MessageReducer = {
          data: message,
          taskId: props.taskId,
          teamId: props.teamId,
        }

        if (change.type === 'added' || change.type === 'modified') {
          newMessagesMutable[messageId] = messageReducer
        }

        if (change.type === 'removed') {
          deletedMessagesMutable[messageId] = messageReducer
        }
      })

      if (Object.keys(newMessagesMutable).length > 0) {
        dispatch(
          createGetMessagesSuccessAction({
            successValues: {
              items: newMessagesMutable,
            },
            description: 'MessagesWatcherMapDispatch',
          })
        )
      }

      if (Object.keys(deletedMessagesMutable).length > 0) {
        dispatch(
          createDeleteMessagesSuccessAction({
            requestValues: {
              items: deletedMessagesMutable,
            },
            successValues: {
              items: deletedMessagesMutable,
            },
            description: 'MessagesWatcherMapDispatch',
          })
        )
      }
    },

    onFirebaseError: (error: Error) => {
      const firestoreErrorAction = createFirebaseErrorAction({
        error: error as FirebaseError,
        description: 'MessagesWatcherMapDispatch',
      })

      dispatch(firestoreErrorAction)

      if (props.teamId) {
        const getMessageFailureAction = createGetMessagesFailureAction({
          requestValues: {
            teamId: props.teamId,
            taskId: props.taskId,
          },
        })

        dispatch(getMessageFailureAction)
      }
    },
  }
}
