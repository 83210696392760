import { connect } from 'react-redux'
import { LoadStripeInvoicesButton } from './LoadStripeInvoicesButton'
import { LoadStripeInvoicesButtonMapState } from './LoadStripeInvoicesButtonMapState'
import { LoadStripeInvoicesButtonMapDispatch } from './LoadStripeInvoicesButtonMapDispatch'
import { LoadStripeInvoicesButtonMapStateReturnType } from './types/LoadStripeInvoicesButtonMapStateReturnType'
import { LoadStripeInvoicesButtonMapDispatchReturnType } from './types/LoadStripeInvoicesButtonMapDispatchReturnType'
import { LoadStripeInvoicesButtonConnectedProps } from './types/LoadStripeInvoicesButtonConnectedProps'

export const LoadStripeInvoicesButtonConnected = connect<
  LoadStripeInvoicesButtonMapStateReturnType,
  LoadStripeInvoicesButtonMapDispatchReturnType,
  LoadStripeInvoicesButtonConnectedProps
>(
  LoadStripeInvoicesButtonMapState,
  LoadStripeInvoicesButtonMapDispatch
)(LoadStripeInvoicesButton)
