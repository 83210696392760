import { HeaderOneTextStyles } from './HeaderOneTextStyles'
import React from 'react'
import { HeaderOneTextProps } from './types/HeaderOneTextProps'

export const HeaderOneText = ({
  isMediumScreen,
  isBigScreen,
  className = '',
  ...props
}: HeaderOneTextProps): JSX.Element => {
  const isMediumScreenClassname = isMediumScreen ? 'isMediumScreen' : ''
  const isBigScreenClassname = isBigScreen ? 'isBigScreen' : ''

  return (
    <div
      className={`HeaderOneText ${isMediumScreenClassname} ${isBigScreenClassname} ${HeaderOneTextStyles} ${className}`}
      {...props}
    />
  )
}
