import { StateReducers } from 'state/reducers/types/StateReducers'
import { FirebaseTimestamp } from 'sdks'

import { MessageCardConnectedProps } from './types/MessageCardConnectedProps'
import { MessageCardMapStateReturnType } from './types/MessageCardMapStateReturnType'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const MessageCardMapState = (
  state: StateReducers,
  props: MessageCardConnectedProps
): MessageCardMapStateReturnType => {
  const message = state.messages?.[props.messageId]?.data
  const userPhotoUrl = message?.userPhotoUrl
  const userDisplayName = message?.userDisplayName
  const description = message?.description
  const createdSeconds =
    message?.created instanceof FirebaseTimestamp
      ? message?.created.seconds
      : undefined
  const edited = !!message?.edited
  const repliedToUserId = message?.repliedToUserId
  const repliedToDescription = message?.repliedToDescription
  const canWrite = getCanWrite({ state })

  return {
    userPhotoUrl,
    userDisplayName,
    description,
    createdSeconds,
    edited,
    repliedToUserId,
    repliedToDescription,
    canWrite,
  }
}
