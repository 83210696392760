import { getMessageStoragePath } from './getMessageStoragePath'

interface Props {
  teamId: string
  taskId: string
  messageId: string
  fileName: string
}

export const getMessageFileOriginalPath = ({
  teamId,
  taskId,
  messageId,
  fileName,
}: Props): string => {
  const path = getMessageStoragePath({ teamId, taskId, messageId })

  return `${path}${fileName}`
}
