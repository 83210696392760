import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'
import { Action } from 'redux'
import {
  GET_TEAMS_SUCCESS,
  GET_TEAM_STAT_SUCCESS,
  PATCH_TEAMS_REQUEST,
  GET_USER_EXTENSION_SUCCESS,
  PATCH_USER_EXTENSION_SUCCESS,
  GET_TEAM_LOGO_URL_SUCCESS,
  INCREMENT_TASK_PAGE,
  DELETE_TEAMS_REQUEST,
} from 'actions'
import { TeamsReducer } from './types/TeamsReducer'
import { TeamReducer } from './types/TeamReducer'
import { GetTeamLogoUrlSuccessAction } from 'actionCreators/get/GetTeamLogoUrl/types/GetTeamLogoUrlSuccessAction'
import { GetTeamStatSuccessAction } from 'actionCreators/get/GetTeamStat/types/GetTeamStatSuccessAction'
import { GetUserExtensionSuccessAction } from 'actionCreators/get/GetUserExtension/types/GetUserExtensionSuccessAction'
import { PatchUserExtensionSuccessAction } from 'actionCreators/patch/PatchUserExtension/types/PatchUserExtensionSuccessAction'
import { IncrementTaskPageAction } from 'actionCreators/types/IncrementTaskPageAction'
import { SetReachMaxTaskPageAction } from '../../actionCreators/types/SetReachMaxTaskPageAction'

const extend = (state?: TeamsReducer, action?: Action): TeamsReducer | null => {
  if (!action || !state) {
    return state || null
  }

  const teamAction = action as any
  const teamId = teamAction.teamId || teamAction?.requestValues?.teamId
  const team = state?.[teamId]

  if (!team) {
    return state || null
  }

  const newTeamMutable: TeamReducer = {
    ...team,
  }

  switch (action.type) {
    case GET_TEAM_LOGO_URL_SUCCESS: {
      const setTeamLogoUrlAction = action as GetTeamLogoUrlSuccessAction
      newTeamMutable.logoUrl = setTeamLogoUrlAction.successValues.logoUrl

      return {
        ...state,
        [teamId]: newTeamMutable,
      }
    }

    case 'SET_REACH_MAX_TASK_PAGE': {
      const setReachMaxTaskPageAction = action as SetReachMaxTaskPageAction
      newTeamMutable.reachMaxTaskPage = setReachMaxTaskPageAction.reachedMax

      return {
        ...state,
        [teamId]: newTeamMutable,
      }
    }

    case INCREMENT_TASK_PAGE: {
      const incrementTaskPageAction = action as IncrementTaskPageAction
      const taskPage = (newTeamMutable && newTeamMutable.taskPage) || 0

      newTeamMutable.taskPage = taskPage + incrementTaskPageAction.incrementBy

      return {
        ...state,
        [teamId]: newTeamMutable,
      }
    }

    case GET_TEAM_STAT_SUCCESS: {
      const getStatRequestAction = action as GetTeamStatSuccessAction
      newTeamMutable.stats = getStatRequestAction.successValues.item

      return {
        ...state,
        [teamId]: newTeamMutable,
      }
    }

    case GET_USER_EXTENSION_SUCCESS: {
      const getUserExtensionRequest = action as GetUserExtensionSuccessAction
      newTeamMutable.userExtension = getUserExtensionRequest.successValues.item

      return {
        ...state,
        [teamId]: newTeamMutable,
      }
    }

    case PATCH_USER_EXTENSION_SUCCESS: {
      const patchUserExtensionSuccessAction = action as PatchUserExtensionSuccessAction
      const successValues = patchUserExtensionSuccessAction.successValues

      newTeamMutable.userExtension = {
        ...newTeamMutable.userExtension,
        ...successValues.item,
      }

      return {
        ...state,
        [teamId]: newTeamMutable,
      }
    }

    default:
      return state || null
  }
}

export const teamsReducers = createGenericReducer<TeamsReducer, TeamReducer>({
  getSuccessAction: GET_TEAMS_SUCCESS,
  patchRequestAction: PATCH_TEAMS_REQUEST,
  deleteRequestAction: DELETE_TEAMS_REQUEST,
  extend,
})
