import { connect } from 'react-redux'
import { AlertMessage } from './AlertMessage'
import { AlertMessageMapState } from './AlertMessageMapState'
import { AlertMessageMapDispatch } from './AlertMessageMapDispatch'

import { AlertMessageMapStateReturnType } from './types/AlertMessageMapStateReturnType'
import { AlertMessageMapDispatchReturnType } from './types/AlertMessageMapDispatchReturnType'
import { AlertMessageConnectedProps } from './types/AlertMessageConnectedProps'

export const AlertMessageConnected = connect<
  AlertMessageMapStateReturnType,
  AlertMessageMapDispatchReturnType,
  AlertMessageConnectedProps
>(
  AlertMessageMapState,
  AlertMessageMapDispatch
)(AlertMessage)
