import React from 'react'
import { TeamNameLinkProps } from './types/TeamNameLinkProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TeamNameWidgetConnected } from 'components/widget/TeamNameWidget'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { textBigStyles } from '../../../styles/text/textBigStyles'

export const TeamNameLink = ({
  showLogoOnly,
  dashboardRoute,
}: TeamNameLinkProps): JSX.Element => {
  return (
    <CustomLinkConnected
      className={`TeamNameLink ${textLinkStyles}`}
      title="Dashboard"
      route={dashboardRoute}
      withCurrentQuery={true}
    >
      <TeamNameWidgetConnected
        showLogoOnly={showLogoOnly}
        className={textBigStyles}
      />
    </CustomLinkConnected>
  )
}
