import { StateReducers } from 'state/reducers/types/StateReducers'

import { BillingMainConnectedProps } from './types/BillingMainConnectedProps'
import { BillingMainMapStateReturnType } from './types/BillingMainMapStateReturnType'


export const BillingMainMapState = (
  _state: StateReducers,
  _props: BillingMainConnectedProps
): BillingMainMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
