import { connect } from 'react-redux'
import { CollapsibleDiv } from './CollapsibleDiv'
import { CollapsibleDivMapState } from './CollapsibleDivMapState'
import { CollapsibleDivMapDispatch } from './CollapsibleDivMapDispatch'

import { CollapsibleDivMapStateReturnType } from './types/CollapsibleDivMapStateReturnType'
import { CollapsibleDivMapDispatchReturnType } from './types/CollapsibleDivMapDispatchReturnType'
import { CollapsibleDivConnectedProps } from './types/CollapsibleDivConnectedProps'


export const CollapsibleDivConnected = connect<
  CollapsibleDivMapStateReturnType,
  CollapsibleDivMapDispatchReturnType,
  CollapsibleDivConnectedProps>(CollapsibleDivMapState,
    CollapsibleDivMapDispatch)(CollapsibleDiv)
