import { iconStyles } from 'styles/iconStyles'
import { css } from 'emotion'

export const IconAndDivStyles = css`
  display: flex;
  align-items: center;

  .${iconStyles} {
    margin-right: 8px;
  }
`
