// import { Dispatch, Action } from 'redux'
// import { CustomLinkConnectedProps } from './CustomLinkConnectedProps'
import { CustomLinkMapDispatchReturnType } from './types/CustomLinkMapDispatchReturnType'

export const CustomLinkMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: CustomLinkConnectedProps,
): CustomLinkMapDispatchReturnType => {
  return {
  }
}
