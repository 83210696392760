import { StateReducers } from 'state/reducers/types/StateReducers'

import { NotificationPermissionWatcherConnectedProps } from './types/NotificationPermissionWatcherConnectedProps'
import { NotificationPermissionWatcherMapStateReturnType } from './types/NotificationPermissionWatcherMapStateReturnType'


export const NotificationPermissionWatcherMapState = (
  _state: StateReducers,
  _props: NotificationPermissionWatcherConnectedProps
): NotificationPermissionWatcherMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
