import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { PATCH_INVITES_REQUEST } from 'actions'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { updateInvite } from 'firebaseFunctions/utilities/ref/update/updateInvite'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { PatchInvitesRequestAction } from 'actionCreators/patch/PatchInvites/types'
import { createPatchInvitesSuccessAction } from 'actionCreators/patch/PatchInvites/createPatchInvitesSuccessAction'
import { createPatchInvitesFailureAction } from 'actionCreators/patch/PatchInvites/createPatchInvitesFailureAction'

export const patchInviteRequestF = function*() {
  while (1) {
    const patchInviteRequestAction: PatchInvitesRequestAction = yield take([
      PATCH_INVITES_REQUEST,
    ])
    const { requestValues } = patchInviteRequestAction
    const { key: inviteId, item: firstInvite } = getObjectFirstItem(
      requestValues.items
    )

    if (firstInvite && inviteId) {
      try {
        // TODO: handle when theres no internet
        // TODO: figure how to handle types because invite is all optional
        yield updateInvite({
          // TODO: start using util function to enforce types
          firestore,
          teamId: firstInvite.teamId,
          inviteId,
          invite: firstInvite.data,
        })

        const patchInviteSuccessAction = createPatchInvitesSuccessAction({
          successValues: requestValues,
          requestValues,
        })

        yield put(patchInviteSuccessAction)
      } catch (error) {
        const firestoreErrorAction = createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'patchInviteRequestF',
        })

        yield put(firestoreErrorAction)

        const patchInviteFailureAction = createPatchInvitesFailureAction({
          requestValues,
        })

        yield put(patchInviteFailureAction)
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'patchInviteRequestF',
        })
      )
    }
  }
}
