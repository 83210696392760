import { connect } from 'react-redux'
import { TaskStatusSelectField } from './TaskStatusSelectField'
import { TaskStatusSelectFieldMapState } from './TaskStatusSelectFieldMapState'
import { TaskStatusSelectFieldMapDispatch } from './TaskStatusSelectFieldMapDispatch'

import { TaskStatusSelectFieldMapStateReturnType } from './types/TaskStatusSelectFieldMapStateReturnType'
import { TaskStatusSelectFieldMapDispatchReturnType } from './types/TaskStatusSelectFieldMapDispatchReturnType'
import { TaskStatusSelectFieldConnectedProps } from './types/TaskStatusSelectFieldConnectedProps'


export const TaskStatusSelectFieldConnected = connect<
  TaskStatusSelectFieldMapStateReturnType,
  TaskStatusSelectFieldMapDispatchReturnType,
  TaskStatusSelectFieldConnectedProps>(TaskStatusSelectFieldMapState,
    TaskStatusSelectFieldMapDispatch)(TaskStatusSelectField)
