import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'

interface Props {
  state: StateReducers
}

export const getTaskCountSyncMilliSeconds = ({ state }: Props): number => {
  const teamReducer = getCurrentTeamReducer({ state })
  let taskCountSyncMilliSeconds = Number(
    teamReducer?.stats?.taskCountSyncMilliSeconds
  )

  if (isNaN(taskCountSyncMilliSeconds)) {
    taskCountSyncMilliSeconds = 0
  }

  return taskCountSyncMilliSeconds
}
