import { StripeSubscriptionCardStyles } from './StripeSubscriptionCardStyles'
import React, { useState } from 'react'
import { StripeSubscriptionCardProps } from './types/StripeSubscriptionCardProps'
import moment from 'moment'
import { PRO_PRODUCT_NOUN } from 'constants/english'
import { StripeSubscriptionDropdownConnected } from 'components/dropdown/StripeSubscriptionDropdown/StripeSubscriptionDropdownConnected'
import { LoadStripeInvoicesButtonConnected } from '../../button/LoadStripeInvoicesButton/LoadStripeInvoicesButtonConnected'
import { StripeInvoices } from '../../../firebaseFunctions/types/StripeInvoices'
import { StripeInvoiceList } from '../../list/StripeInvoiceList/StripeInvoiceList'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'
import { CustomLinkConnected } from '../../link/CustomLink'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'
import { UPGRADE_WORKSPACE_ROUTE } from '../../../routes'

const statusText = {
  past_due: 'past due',
}

export const StripeSubscriptionCard = ({
  stripeSubscription,
  stripeSubscriptionId,
  handleDeletedStripeSubscription,
}: StripeSubscriptionCardProps): JSX.Element => {
  const [stripeInvoices, setStripeInvoices] = useState<StripeInvoices | null>(
    null
  )

  const { teamDisplayName, status, nextInvoice } = stripeSubscription
  const inactiveStatuses = [
    'incomplete',
    'canceled',
    'incomplete_expired',
    'ended',
  ]
  const isInactive = status && inactiveStatuses.indexOf(status) > -1
  const inactiveClassname = isInactive ? 'inactive' : ''
  const teamId = stripeSubscription?.teamId

  return (
    <div
      className={`StripeSubscriptionCard ${StripeSubscriptionCardStyles} ${inactiveClassname}`}
    >
      <h2>
        {PRO_PRODUCT_NOUN} - {status && (statusText[status] || status)}
      </h2>

      {!isInactive && (
        <StripeSubscriptionDropdownConnected
          stripeSubscriptionId={stripeSubscriptionId}
          handleDeletedStripeSubscription={handleDeletedStripeSubscription}
        />
      )}

      <div className={`${marginTopMediumStyles} ${textBoldStyles}`}>
        {teamDisplayName}
      </div>

      {nextInvoice?.createdSeconds && nextInvoice?.amountDue && (
        <div className={marginTopMediumStyles}>
          Upcoming invoice is for ${nextInvoice?.amountDue} on{' '}
          {moment.unix(nextInvoice.createdSeconds).format('MMM D')}
        </div>
      )}

      {status === 'canceled' && (
        <div className={marginTopMediumStyles}>
          Canceled and partially refunded.
        </div>
      )}

      {status === 'past_due' && (
        <div className={marginTopMediumStyles}>
          Canceled,{' '}
          <CustomLinkConnected
            title="Upgrade"
            className={`${textLinkStyles}`}
            teamId={teamId}
            route={UPGRADE_WORKSPACE_ROUTE}
          >
            click here
          </CustomLinkConnected>{' '}
          to renew.
        </div>
      )}

      {stripeSubscriptionId && !stripeInvoices && (
        <LoadStripeInvoicesButtonConnected
          className={marginTopMediumStyles}
          stripeSubscriptionId={stripeSubscriptionId}
          setStripeInvoices={setStripeInvoices}
        />
      )}

      {stripeInvoices && (
        <StripeInvoiceList
          className={marginTopMediumStyles}
          stripeInvoices={stripeInvoices}
        />
      )}
    </div>
  )
}
