import { USER_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PatchUserSuccessPayload } from 'firebaseFunctions/user/types/PatchUserSuccessPayload'
import { PatchUserApiProps } from './types/PatchUserApiProps'

// TODO: make patchUserApi plural and other stuff
export const patchUserApi = ({
  values,
  apiHeaders,
}: PatchUserApiProps): AxiosPromise<PatchUserSuccessPayload> => {
  return Axios.patch(USER_URL, values, {
    headers: apiHeaders,
  })
}
