import { css } from 'emotion'
import { BORDER_RADIUS } from 'constants/styleConstants'

export const StripeSubscriptionFieldStyles = css`
  .cardElementContainer {
    padding: 16px;
    background-color: white;
    border-radius: ${BORDER_RADIUS};
  }

  .ActionButton {
    width: 100%;
    margin-top: 32px;
  }
`
