import { take, put, select, spawn } from 'redux-saga/effects'
import { storageSdk, clientServerTimestamp } from 'sdks'
import {
  SUBMIT_DELETE_TASK_FILE_NAME,
  SUBMIT_DELETE_TASK_FILE_NAME_DONE,
} from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { getTaskOriginalPath } from 'firebaseFunctions/utilities/get/getTaskOriginalPath'
import { getTaskThumbPath } from 'firebaseFunctions/utilities/get/getTaskThumbPath'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentParams } from 'state/getters/getCurrentParams'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteTaskFileNameF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_TASK_FILE_NAME])
    const { fileName } = action.values
    const state: StateReducers = yield select(selectState)
    const params = getCurrentParams({ state })
    const { taskId, teamId } = params
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName

    const originalPath =
      fileName && teamId && taskId
        ? getTaskOriginalPath({
            teamId,
            taskId,
            fileName,
          })
        : undefined

    const thumbPath =
      fileName && teamId && taskId
        ? getTaskThumbPath({
            teamId,
            taskId,
            fileName,
          })
        : undefined

    if (originalPath) {
      storageSdk()
        .ref()
        .child(originalPath)
        .delete() // dont care if it fails
    }

    if (thumbPath) {
      storageSdk()
        .ref()
        .child(thumbPath)
        .delete() // dont care if it fails
    }

    if (
      taskId &&
      teamId &&
      fileName &&
      currentUserId &&
      currentUserDisplayName
    ) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            updated: clientServerTimestamp(),
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteTaskFileNameF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_TASK_FILE_NAME_DONE,
    })
  }
}
