import { createTaskTagDropdownId } from './../../../utilities/create/createDropdownId'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskTagDropdownConnectedProps } from './types/TaskTagDropdownConnectedProps'
import { TaskTagDropdownMapStateReturnType } from './types/TaskTagDropdownMapStateReturnType'
import { getTaskTag } from 'state/getters/getTaskTag'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskTagDropdownMapState = (
  state: StateReducers,
  props: TaskTagDropdownConnectedProps
): TaskTagDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const { taskId, tagId } = props
  const showDropdown = toggles && toggles.showDropdown
  const canWrite = getCanWrite({ state })
  const isDashboardLayoutRoute =
    state.currentLocation?.isRoutes?.isDashboardLayoutRoute

  const showTaskTagDropdown =
    showDropdown &&
    showDropdown.type === 'taskTag' &&
    showDropdown.id === createTaskTagDropdownId({ taskId, tagId })

  const tag = getTaskTag({ state, taskId, tagId })
  const displayName = tag && tag.displayName

  return {
    showTaskTagDropdown,
    isDashboardLayoutRoute,
    displayName,
    canWrite,
  }
}
