import { CLEAR_TEAM_WATCHED_TASKS } from 'actions'
import { ClearTeamWatchedTasksAction } from './types/ClearTeamWatchedTasksAction'

interface Props {
  description: string
}

export const createClearTeamWatchedTasksAction = ({
  description,
}: Props): ClearTeamWatchedTasksAction => {
  return {
    type: CLEAR_TEAM_WATCHED_TASKS,
    description,
  }
}
