import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { SUBMIT_DELETE_MESSAGE } from 'actions'
import { MessageActionListMapDispatchReturnType } from './types/MessageActionListMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const MessageActionListMapDispatch = (
  dispatch: Dispatch<Action>
): MessageActionListMapDispatchReturnType => {
  return {
    editMessage: (messageId) => {
      dispatch(
        createToggleAction({
          description: 'MessageActionListMapDispatch',
          toggles: {
            showModal: {
              type: 'UpdateMessage',
              messageId,
            },
            showDropdown: false,
          },
        })
      )
    },

    reply: () => {
      dispatch(
        createToggleAction({
          description: 'MessageActionListMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },

    deleteMessage: (messageId) => {
      const submitDeleteMessageAction = createSubmitAction({
        type: SUBMIT_DELETE_MESSAGE,
        values: {
          messageId,
        },
      })

      dispatch(submitDeleteMessageAction)

      dispatch(
        createToggleAction({
          description: 'MessageActionListMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },
  }
}
