import { submitUpdateInviteRoleF } from './submitUpdateInviteRoleF'
import { submitUpdateMessageF } from './submitUpdateMessageF'
import { submitUpdateRoleF } from './submitUpdateRoleF'
import { submitUpdateTaskStatusesF } from './submitUpdateTaskStatusesF'
import { submitUpdatePasswordF } from './submitUpdatePasswordF'
import { submitUpdateTaskSizesF } from './submitUpdateTaskSizesF'
import { submitUpdateLabelF } from './submitUpdateLabelF'
import { submitUpdateEmailSettingsF } from './submitUpdateEmailSettingsF'
import { submitUpdatePushSettingsF } from './submitUpdatePushSettingsF'
import { submitUpdateUserDisplayNameF } from './submitUpdateUserDisplayNameF'

export const submitUpdateFlows = [
  submitUpdateInviteRoleF,
  submitUpdateMessageF,
  submitUpdateTaskStatusesF,
  submitUpdateRoleF,
  submitUpdatePasswordF,
  submitUpdateTaskSizesF,
  submitUpdateLabelF,
  submitUpdateEmailSettingsF,
  submitUpdatePushSettingsF,
  submitUpdateUserDisplayNameF,
]
