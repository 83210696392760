import { connect } from 'react-redux'
import { LoginForm } from './LoginForm'
import { LoginFormMapState } from './LoginFormMapState'
import { LoginFormMapDispatch } from './LoginFormMapDispatch'
import { LoginFormMapStateReturnType } from './types/LoginFormMapStateReturnType'
import { LoginFormMapDispatchReturnType } from './types/LoginFormMapDispatchReturnType'
import { LoginFormConnectedProps } from './types/LoginFormConnectedProps'

export const LoginFormConnected = connect<
  LoginFormMapStateReturnType,
  LoginFormMapDispatchReturnType,
  LoginFormConnectedProps
>(
  LoginFormMapState,
  LoginFormMapDispatch
)(LoginForm)
