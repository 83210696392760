interface GenericObject <T> {
  [key: string]: T
}

interface ReturnType <T> {
  item?: T
  key?: string
}

export const getObjectFirstItem = <T>(collection?: GenericObject<T>): ReturnType<T> => {
  if (collection) {
    const keys = Object.keys(collection)

    if (keys) {
      return {
        item: collection[keys[0]],
        key: keys[0],
      }
    }
  }

  return {
    item: undefined,
    key: undefined,
  }
}
