import { ModalLayoutStyles } from './ModalLayoutStyles'
import * as React from 'react'
import { ModalC } from 'components/widget/Modal'
import { ModalLayoutProps } from './types/ModalLayoutProps'
import { ServerErrorConnected } from 'components/ServerError'
import { UpdateMessageEditorConnected } from 'components/editor/UpdateMessageEditor'
import { NewMessageEditorConnected } from 'components/editor/NewMessageEditor'
import { DotDotDotWidget } from 'components/widget/DotDotDotWidget'
import { FeedbackFormConnected } from '../../form/FeedbackForm/FeedbackFormConnected'

export const ModalLayout = ({
  type,
  isEndToEndTesting,
  onCancel,
  isMediumScreen,
  messageId,
}: ModalLayoutProps) => {
  const isEndToEndTestingClassName = isEndToEndTesting
    ? ' isEndToEndTesting'
    : ''
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

  return (
    <ModalC
      className={
        'ModalLayout App ' +
        ModalLayoutStyles +
        isEndToEndTestingClassName +
        isMediumScreenClassName
      }
      visible={!!type}
      onCancel={onCancel}
    >
      {type === 'Error' && <ServerErrorConnected />}

      {type === 'UpdateMessage' && messageId && (
        <UpdateMessageEditorConnected messageId={messageId} />
      )}

      {type === 'NewMessage' && (
        <NewMessageEditorConnected autoFocus={true} inModal={true} />
      )}

      {type === 'PlanIsFull' && (
        <p>
          Current cycle is full. You can move stuff out or start a new cycle.
        </p>
      )}

      {type === 'Leaving' && (
        <div>
          Leaving
          <DotDotDotWidget />
        </div>
      )}

      {type === 'Joining' && (
        <div>
          Joining
          <DotDotDotWidget />
        </div>
      )}

      {type === 'Feedback' && <FeedbackFormConnected />}
    </ModalC>
  )
}
