// import { Dispatch, Action } from 'redux'

import {
  // TaskTagsMainConnectedProps,
  TaskTagsMainMapDispatchReturnType,
} from './types'

export const TaskTagsMainMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskTagsMainConnectedProps,
): TaskTagsMainMapDispatchReturnType => {
  return {
  }
}
