import { css } from 'emotion'
// import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'

export const UploadedFileListStyles = css`
  > li {
    margin-top: 8px;

    &:first-child {
      margin-top: 0px;
    }
  }
`
