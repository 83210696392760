import * as React from 'react'
import { ServerErrorProps } from './types/ServerErrorProps'
import { captureException } from '@sentry/browser'
import { ActionButton } from 'components/button/ActionButton'
import { DizzyIcon } from '../icon/DizzyIcon/DizzyIcon'
import { textAlignCenter } from '../../styles/text/textAlignCenter'
import { marginTopLargeStyles } from '../../styles/margin/marginTopLargeStyles'
import { marginTopMediumStyles } from '../../styles/margin/marginTopMediumStyles'
import { largeCenterIconStyles } from '../../styles/icon/largeCenterIconStyles'
import { HeaderOneTextConnected } from '../text/HeaderOneText/HeaderOneTextConnected'

export const ServerError = ({
  showDetails,
  type,
  message,
  status,
}: ServerErrorProps): JSX.Element => {
  const details = `status: ${status} | type: ${type} | message: ${message}`

  captureException(details)
  // TODO: use ModalLayout
  return (
    <div className={`ServerError ${textAlignCenter} ${marginTopLargeStyles}`}>
      <HeaderOneTextConnected>
        Oops! Something went wrong.
      </HeaderOneTextConnected>

      <DizzyIcon className={largeCenterIconStyles} />

      <div>
        <ActionButton
          title="Reload"
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload
        </ActionButton>
      </div>

      {showDetails && <p className={marginTopMediumStyles}>{details}</p>}
    </div>
  )
}
