import { ContentLoaderWidgetStyles } from './ContentLoaderWidgetStyles'
import React from 'react'
import { ContentLoaderWidgetProps } from './types/ContentLoaderWidgetProps'

export const ContentLoaderWidget = ({
  className = '',
}: ContentLoaderWidgetProps): JSX.Element => {
  return (
    <div
      className={`ContentLoaderWidget ${ContentLoaderWidgetStyles} ${className}`}
    >
      <div />
      <div />
      <div />
    </div>
  )
}
