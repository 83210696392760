import { GO_BACK } from 'actions'
import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getBackLocation } from 'state/getters/getBackLocation'
import { createHistoryAction } from 'actionCreators/createHistoryAction'

export const goBackF = function*() {
  while (1) {
    yield take([GO_BACK])

    const state: StateReducers = yield select(selectState)
    const { pathname, teamId, taskId } = getBackLocation({ state })

    if (pathname) {
      yield put(
        createHistoryAction({
          description: 'goBackF',
          method: 'push',
          pathname,
          query: { teamId, taskId },
        })
      )
    }
  }
}
