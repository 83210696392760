import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // UploadTeamLogoButtonConnectedProps,
  UploadTeamLogoButtonMapStateReturnType,
} from './types'

export const UploadTeamLogoButtonMapState = (
  _state: StateReducers
  // props: UploadTeamLogoButtonConnectedProps,
): UploadTeamLogoButtonMapStateReturnType => {
  return {}
}
