// Actions that are triggered by the user
// TODO: go through and make sure all user triggered interacitons are submit actions
export * from './submitDeleteActions'
export * from './submitUpdateActions'
export * from './submitNewActions'

export const SUBMIT_USER = 'SUBMIT_USER'
export const SUBMIT_USER_DONE = 'SUBMIT_USER_DONE'
export const SUBMIT_USER_INVITED = 'SUBMIT_USER_INVITED'
export const SUBMIT_USER_INVITED_DONE = 'SUBMIT_USER_INVITED_DONE'
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN'
export const SUBMIT_LOGIN_DONE = 'SUBMIT_LOGIN_DONE'
export const SUBMIT_TASK_STATUS = 'SUBMIT_TASK_STATUS'
export const SUBMIT_TASK_STATUS_DONE = 'SUBMIT_TASK_STATUS_DONE'
export const SUBMIT_SYNC_TASK_COUNT = 'SUBMIT_SYNC_TASK_COUNT'
export const SUBMIT_SYNC_TASK_COUNT_DONE = 'SUBMIT_SYNC_TASK_COUNT_DONE'
export const SUBMIT_TASK_SUMMARY = 'SUBMIT_TASK_SUMMARY'
export const SUBMIT_TASK_SUMMARY_DONE = 'SUBMIT_TASK_SUMMARY_DONE'
export const SUBMIT_WORKING_ON_TASK_SUMMARY = 'SUBMIT_WORKING_ON_TASK_SUMMARY'
export const SUBMIT_WORKING_ON_TASK_SUMMARY_DONE =
  'SUBMIT_WORKING_ON_TASK_SUMMARY_DONE'

export const SUBMIT_WORKING_ON_TASK_MESSAGE = 'SUBMIT_WORKING_ON_TASK_MESSAGE'
export const SUBMIT_WORKING_ON_TASK_MESSAGE_DONE =
  'SUBMIT_WORKING_ON_TASK_MESSAGE_DONE'

export const SUBMIT_WORKING_ON_TASK_DESCRIPTION =
  'SUBMIT_WORKING_ON_TASK_DESCRIPTION'
export const SUBMIT_WORKING_ON_TASK_DESCRIPTION_DONE =
  'SUBMIT_WORKING_ON_TASK_DESCRIPTION_DONE'
export const SUBMIT_TASK_DESCRIPTION = 'SUBMIT_TASK_DESCRIPTION'
export const SUBMIT_TASK_DESCRIPTION_DONE = 'SUBMIT_TASK_DESCRIPTION_DONE'
export const SUBMIT_AVATAR_UPLOAD = 'SUBMIT_AVATAR_UPLOAD'
export const SUBMIT_AVATAR_UPLOAD_DONE = 'SUBMIT_AVATAR_UPLOAD_DONE'
export const SUBMIT_USER_DISPLAY_NAME = 'SUBMIT_USER_DISPLAY_NAME'
export const SUBMIT_USER_DISPLAY_NAME_DONE = 'SUBMIT_USER_DISPLAY_NAME_DONE'
export const SUBMIT_TASK_TAG = 'SUBMIT_TASK_TAG'
export const SUBMIT_TASK_TAG_DONE = 'SUBMIT_TASK_TAG_DONE'
export const SUBMIT_SET_DEFAULT_FILTER = 'SUBMIT_SET_DEFAULT_FILTER'
export const SUBMIT_TASK_FILTER_DONE = 'SUBMIT_TASK_FILTER_DONE'
export const SUBMIT_ACCEPT_INVITE = 'SUBMIT_ACCEPT_INVITE'
export const SUBMIT_ACCEPT_INVITE_DONE = 'SUBMIT_ACCEPT_INVITE_DONE'
export const SUBMIT_DELETE_USER_INVITE = 'SUBMIT_DELETE_USER_INVITE'
export const SUBMIT_DELETE_USER_INVITE_DONE = 'SUBMIT_DELETE_USER_INVITE_DONE'
export const SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD'
export const SUBMIT_RESET_PASSWORD_DONE = 'SUBMIT_RESET_PASSWORD_DONE'
export const SUBMIT_TEAM_NAME = 'SUBMIT_TEAM_NAME'
export const SUBMIT_TEAM_NAME_DONE = 'SUBMIT_TEAM_NAME_DONE'
export const SUBMIT_TASK_FILES = 'SUBMIT_TASK_FILES'
export const SUBMIT_TASK_FILES_DONE = 'SUBMIT_TASK_FILES_DONE'
export const SUBMIT_DELETE_CUSTOM_CLAIM = 'SUBMIT_DELETE_CUSTOM_CLAIM'
export const SUBMIT_DELETE_CUSTOM_CLAIM_DONE = 'SUBMIT_DELETE_CUSTOM_CLAIM_DONE'
export const SUBMIT_TASK_PLAN_DROP = 'SUBMIT_TASK_PLAN_DROP'
export const SUBMIT_TASK_PLAN_DROP_DONE = 'SUBMIT_TASK_PLAN_DROP_DONE'
export const SUBMIT_TASK_STATUS_DROP = 'SUBMIT_TASK_STATUS_DROP'
export const SUBMIT_TASK_STATUS_DROP_DONE = 'SUBMIT_TASK_STATUS_DROP_DONE'
export const SUBMIT_TASK_REORDER_DROP = 'SUBMIT_TASK_REORDER_DROP'
export const SUBMIT_TASK_REORDER_DROP_DONE = 'SUBMIT_TASK_REORDER_DROP_DONE'
export const SUBMIT_CLOSE_PLAN = 'SUBMIT_CLOSE_PLAN'
export const SUBMIT_CLOSE_PLAN_DONE = 'SUBMIT_CLOSE_PLAN_DONE'
export const SUBMIT_REOPEN_PLAN = 'SUBMIT_REOPEN_PLAN'
export const SUBMIT_REOPEN_PLAN_DONE = 'SUBMIT_REOPEN_PLAN_DONE'
export const SUBMIT_READ_ALL = 'SUBMIT_READ_ALL'
export const SUBMIT_READ_ALL_DONE = 'SUBMIT_READ_ALL_DONE'
export const SUBMIT_READ = 'SUBMIT_READ'
export const SUBMIT_READ_DONE = 'SUBMIT_READ_DONE'
export const SUBMIT_TASK_DUE_DATE = 'SUBMIT_TASK_DUE_DATE'
export const SUBMIT_TASK_DUE_DATE_DONE = 'SUBMIT_TASK_DUE_DATE_DONE'
export const SUBMIT_TASK_SIZE = 'SUBMIT_TASK_SIZE'
export const SUBMIT_TASK_SIZE_DONE = 'SUBMIT_TASK_SIZE_DONE'
export const SUBMIT_STATUS_COLOR = 'SUBMIT_STATUS_COLOR'
export const SUBMIT_STATUS_COLOR_DONE = 'SUBMIT_STATUS_COLOR_DONE'
export const SUBMIT_CLONE_TASK = 'SUBMIT_CLONE_TASK'
export const SUBMIT_MOVE_TASK_TO_CYCLE = 'SUBMIT_MOVE_TASK_TO_CYCLE'
export const SUBMIT_MOVE_TASK_TO_NEXT = 'SUBMIT_MOVE_TASK_TO_NEXT'
export const SUBMIT_MOVE_TASK_TO_BACK = 'SUBMIT_MOVE_TASK_TO_BACK'
export const SUBMIT_NEW_STRIPE_SUBSCRIPTION = 'SUBMIT_NEW_STRIPE_SUBSCRIPTION'
export const SUBMIT_NEW_STRIPE_SUBSCRIPTION_DONE =
  'SUBMIT_NEW_STRIPE_SUBSCRIPTION_DONE'
