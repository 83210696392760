import { StateReducers } from '../reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

export const getWindowWidth = ({ state }: Props): number => {
  const { windowMeta } = state

  return (windowMeta && windowMeta.width) || 320
}
