import { css } from 'emotion'

export const NewLabelFormStyles = css`
  > h2 {
    margin: 32px 0 16px 0;
  }

  .iconContainer {
    position: relative;

    .emojiContainer {
      position: absolute;
      right: 8px;
      top: 33px;

      .Dropdown {
        .chevronContainer {
          display: none; /* because the emoji icon is too high */
        }

        .container {
          top: -267px;
        }
      }
    }
  }
`
