import { FormFooterStyles } from './FormFooterStyles'
import * as React from 'react'
import { ActionButtonConnected } from 'components/button/ActionButton'
import { FormFooterProps } from './types/FormFooterProps'
import { FormSpinnerConnected } from 'components/spinner/FormSpinner'
import { AlertMessageConnected } from 'components/message/AlertMessage'

export const FormFooter = ({
  children,
  className = '',
  label = 'Next',
  buttonType = 'submit',
  noSpinnerWithErrorAndSuccess,
  handleClick,
  disabled,
}: FormFooterProps) => {
  const extraClassName = className ? ` ${className}` : ''
  const noSpinnerWithErrorAndSuccessClassName = noSpinnerWithErrorAndSuccess
    ? ' noSpinnerWithErrorAndSuccess'
    : ''

  return (
    <footer
      className={
        'FormFooter ' +
        FormFooterStyles +
        extraClassName +
        noSpinnerWithErrorAndSuccessClassName
      }
    >
      {!noSpinnerWithErrorAndSuccess && (
        <div className="spinnerWithErrorAndSuccess">
          <FormSpinnerConnected />

          <AlertMessageConnected />
        </div>
      )}

      {!children && (
        <ActionButtonConnected
          className="big"
          type={buttonType}
          title="submit"
          disabled={disabled}
          onClick={handleClick}
        >
          {label}
        </ActionButtonConnected>
      )}

      {children && children}
    </footer>
  )
}
