import { connect } from 'react-redux'
import { TaskPlanDropdown } from './TaskPlanDropdown'
import { TaskPlanDropdownMapState } from './TaskPlanDropdownMapState'
import { TaskPlanDropdownMapDispatch } from './TaskPlanDropdownMapDispatch'
import { TaskPlanDropdownMapStateReturnType } from './types/TaskPlanDropdownMapStateReturnType'
import { TaskPlanDropdownMapDispatchReturnType } from './types/TaskPlanDropdownMapDispatchReturnType'
import { TaskPlanDropdownConnectedProps } from './types/TaskPlanDropdownConnectedProps'

export const TaskPlanDropdownConnected = connect<
  TaskPlanDropdownMapStateReturnType,
  TaskPlanDropdownMapDispatchReturnType,
  TaskPlanDropdownConnectedProps
>(
  TaskPlanDropdownMapState,
  TaskPlanDropdownMapDispatch
)(TaskPlanDropdown)
