import { POST_TASKS_REQUEST } from 'actions'
import { PostTasksRequestAction } from './types/PostTasksRequestAction'
import { PostTasksRequestActionValues } from './types/PostTasksRequestActionValues'

interface Props {
  requestValues: PostTasksRequestActionValues,
}

export const createPostTasksRequestAction = ({ requestValues }: Props): PostTasksRequestAction => {
  return {
    type: POST_TASKS_REQUEST,
    requestValues,
  }
}
