import { TeamDropdownStyles } from './TeamDropdownStyles'
import React from 'react'
import { TeamDropdownProps } from './types/TeamDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { TeamActionListConnected } from 'components/list/TeamActionList'
import { EllipsisVertialIcon } from 'components/icon/EllipsisVertialIcon/EllipsisVertialIcon'

export const TeamDropdown = ({
  showTeamDropdown,
  teamId,
  className = '',
}: TeamDropdownProps): JSX.Element => {
  return (
    <div
      className={`TeamDropdown _${teamId} ${TeamDropdownStyles} ${className}`}
    >
      <DropdownButtonConnected
        dropdownType="team"
        title="More options"
        watchSelector={`.TeamDropdown._${teamId}`}
        id={teamId}
      >
        <EllipsisVertialIcon />
      </DropdownButtonConnected>

      {showTeamDropdown && (
        <DropdownConnected>
          <TeamActionListConnected teamId={teamId} />
        </DropdownConnected>
      )}
    </div>
  )
}
