import React from 'react'
import { UserPlusIconProps } from './types/UserPlusIconProps'
import { UserIcon } from '../UserIcon/UserIcon'
import { PlusSolidIcon } from '../PlusSolidIcon/PlusSolidIcon'
import { UserPlusIconStyles } from './UserPlusIconStyles'

export const UserPlusIcon = ({
  style,
  className = '',
}: UserPlusIconProps): JSX.Element => {
  return (
    <div
      className={`UserPlusIcon ${UserPlusIconStyles} ${className}`}
      style={style}
    >
      <PlusSolidIcon />
      <UserIcon />
    </div>
  )
}
