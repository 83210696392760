import { css } from 'emotion'
import { TEXT_COLOR_LIGHTER, TEXT_COLOR } from 'constants/styleConstants'

export const PasswordInputStyles = css`
  position: relative;

  > button {
    position: absolute;
    right: 0;
    top: 5px;
    padding: 8px;
    color: ${TEXT_COLOR_LIGHTER};

    .EyeSolidIcon,
    .EyeSlashSolidIcon {
      height: 1.2em;
    }

    &:hover {
      color: ${TEXT_COLOR};
    }
  }

  input {
    padding-right: 50px;
  }
`
