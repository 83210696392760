import { TaskSummaryMainStyles } from './TaskSummaryMainStyles'
import React, { useEffect, useState } from 'react'
import { TaskSummaryMainProps } from './types/TaskSummaryMainProps'
import moment from 'moment'
import { TaskSummaryFieldConnected } from 'components/field/TaskSummaryField'
import { WORKING_STALE_SECONDS } from 'constants/environmentConstants'
import { UserWorkingWidget } from 'components/widget/UserWorkingWidget'

export const TaskSummaryMain = ({
  currentUserId,
  workingOnSummaryUserId,
  workingOnSummaryUpdatedSeconds,
}: TaskSummaryMainProps): JSX.Element => {
  const [showWorkingState, setShowWorkingState] = useState(true)

  useEffect(() => {
    const staleMoment = moment().subtract(WORKING_STALE_SECONDS, 'seconds')
    const workingOnSummaryMoment = workingOnSummaryUpdatedSeconds
      ? moment.unix(workingOnSummaryUpdatedSeconds)
      : undefined

    const isWorking =
      workingOnSummaryMoment && workingOnSummaryMoment.isAfter(staleMoment)

    if (isWorking && currentUserId !== workingOnSummaryUserId) {
      setShowWorkingState(true)

      const timeout = setTimeout(() => {
        setShowWorkingState(false)
      }, WORKING_STALE_SECONDS * 1000)

      return () => {
        window.clearTimeout(timeout)
      }
    } else {
      setShowWorkingState(false)
    }

    return
  }, [workingOnSummaryUpdatedSeconds, currentUserId, workingOnSummaryUserId])

  return (
    <div className={'TaskSummaryMain ' + TaskSummaryMainStyles}>
      {showWorkingState && workingOnSummaryUserId && (
        <UserWorkingWidget userId={workingOnSummaryUserId} />
      )}

      <TaskSummaryFieldConnected />
    </div>
  )
}
