import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdateUserDisplayNameFormConnectedProps } from './types/UpdateUserDisplayNameFormConnectedProps'
import { UpdateUserDisplayNameFormMapStateReturnType } from './types/UpdateUserDisplayNameFormMapStateReturnType'

export const UpdateUserDisplayNameFormMapState = (
  state: StateReducers,
  _props: UpdateUserDisplayNameFormConnectedProps
): UpdateUserDisplayNameFormMapStateReturnType => {
  const displayName = state.currentUser?.displayName

  return {
    displayName,
  }
}
