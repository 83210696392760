import { EmojiDropdownStyles } from './EmojiDropdownStyles'
import React, { Suspense } from 'react'
import { EmojiDropdownProps } from './types/EmojiDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
// import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { GrinIcon } from 'components/icon/GrinIcon/GrinIcon'
import { Spinner } from '../../widget/Spinner'
import { marginSmallStyles } from '../../../styles/margin/marginSmallStyles'

const Picker = React.lazy(() => {
  return import('emoji-mart').then(({ Picker }) => ({
    default: Picker,
  }))
})

export const EmojiDropdown = ({
  showEmojiDropdown,
  dropdownId,
  onMouseDown,
  onSelectDispatch,
  isTouch,
}: EmojiDropdownProps): JSX.Element | null => {
  const activeClassName = showEmojiDropdown ? ' active' : ''

  if (isTouch) {
    return null
  }

  return (
    <div
      className={`EmojiDropdown _${dropdownId} ` + EmojiDropdownStyles}
      onMouseDown={onMouseDown}
    >
      <DropdownButtonConnected
        title="Select Emoji"
        dropdownType="emoji"
        className={activeClassName}
        watchSelector={`.EmojiDropdown._${dropdownId}`}
        id={dropdownId}
      >
        <GrinIcon />
      </DropdownButtonConnected>

      {showEmojiDropdown && (
        <DropdownConnected autoAdjust={true} autoScroll={true}>
          <Suspense fallback={<Spinner className={marginSmallStyles} />}>
            <Picker
              sheetSize={64}
              emoji="point_up"
              onSelect={onSelectDispatch}
              title=""
            />
          </Suspense>
        </DropdownConnected>
      )}
    </div>
  )
}
