import { HistoryCardStyles } from './HistoryCardStyles'
import React from 'react'
import { HistoryCardProps } from './types/HistoryCardProps'
import { TaskSummarySpanConnected } from 'components/span/TaskSummarySpan'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TASK_DETAIL_ROUTE } from 'routes'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'
import { textSmallStyles } from '../../../styles/text/textSmallStyles'
import moment from 'moment'
import { getIsToday } from '../../../utilities/get/getIsToday'
import { displayBlockStyles } from '../../../styles/display/displayBlockStyles'
import { flexCenterStyles } from '../../../styles/flex/flexCenterStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'

export const HistoryCard = ({
  taskId,
  taskSummary,
  createdSeconds,
  className = '',
}: HistoryCardProps): JSX.Element => {
  let createdString = createdSeconds
    ? moment(createdSeconds).format('MMM D')
    : ''
  const isToday = createdSeconds && getIsToday(createdSeconds)
  createdString = isToday ? 'today' : createdString

  return (
    <div
      className={`HistoryCard ${HistoryCardStyles} ${className} ${paddingSmallStyles} ${textSmallStyles} ${flexCenterStyles}`}
    >
      <div className={textSmallestStyles} style={{ minWidth: '50px' }}>
        {createdString}
      </div>

      <CustomLinkConnected
        route={TASK_DETAIL_ROUTE}
        withCurrentQuery={true}
        className={`${displayBlockStyles} ${textLinkStyles} `}
        title="task summary"
        taskId={taskId}
      >
        <p>
          <TaskSummarySpanConnected taskId={taskId}>
            {taskSummary}
          </TaskSummarySpanConnected>
        </p>
      </CustomLinkConnected>
    </div>
  )
}
