 import { StateReducers } from 'state/reducers/types/StateReducers'
import { AppUpdatedMainConnectedProps } from './types/AppUpdatedMainConnectedProps'
import { AppUpdatedMainMapStateReturnType } from './types/AppUpdatedMainMapStateReturnType'

export const AppUpdatedMainMapState = (
  _state: StateReducers,
  _props: AppUpdatedMainConnectedProps,
): AppUpdatedMainMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
