import { take } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'actions'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'

export const showRootPageF = function*() {
  while (1) {
    const action: LocationChangeAction = yield take(LOCATION_CHANGE)

    const isRootRoute = action.values.isRoutes?.isRootRoute
    const marketingElement = window?.document?.getElementById('mkt-container')
    const appElement = window?.document?.getElementById('root')

    if (marketingElement && marketingElement && appElement) {
      if (isRootRoute) {
        marketingElement.style.display = 'block'
        appElement.style.display = 'none'
      } else {
        marketingElement.style.display = 'none'
        appElement.style.display = 'block'
      }
    }
  }
}
