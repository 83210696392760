import { createPatchUserExtensionFailureAction } from './../../../actionCreators/patch/PatchUserExtension/createPatchUserExtensionFailureAction'
import { setUserExtension } from './../../../firebaseFunctions/utilities/ref/set/setUserExtension'
import { createPatchUserExtensionRequestAction } from './../../../actionCreators/patch/PatchUserExtension/createPatchUserExtensionRequestAction'
import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { UserExtension } from '../../../firebaseFunctions/types'
import { createPatchUserExtensionSuccessAction } from '../../../actionCreators/patch/PatchUserExtension/createPatchUserExtensionSuccessAction'

interface Props {
  userExtension: UserExtension
  teamId: string
  userId: string
}

export const patchUserExtensionSaga = function*({
  userExtension,
  teamId,
  userId,
}: Props) {
  const requestValues = {
    item: userExtension,
    params: {
      teamId,
      userId,
    },
  }

  yield put(
    createPatchUserExtensionRequestAction({
      requestValues,
    })
  )

  try {
    yield setUserExtension({
      firestore,
      teamId,
      userId,
      userExtension,
    })

    yield put(
      createPatchUserExtensionSuccessAction({
        requestValues,
        successValues: requestValues,
      })
    )
  } catch (error) {
    yield put(
      createFirebaseErrorAction({
        error: error as firebase.FirebaseError,
        description: 'patchUserExtensionSaga',
      })
    )

    yield put(
      createPatchUserExtensionFailureAction({
        requestValues,
      })
    )
  }
}
