import { take, put, race, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getQueryInviteId } from 'state/getters/getQueryInviteId'
import { getQuerySecret } from 'state/getters/getQuerySecret'
import { createPostLoginRequestAction } from 'actionCreators/post/PostLogin/createPostLoginRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import {
  SUBMIT_USER_INVITED,
  SUBMIT_USER_INVITED_DONE,
  POST_USER_INVITED_SUCCESS,
  POST_USER_INVITED_FAILURE,
  POST_USER_INVITED_CANCEL,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { postUserInvitedSaga } from 'flows/sagas/post/postUserInvitedSaga'

export const submitNewUserInvitedF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_USER_INVITED])
    const state: StateReducers = yield select(selectState)
    const inviteId = getQueryInviteId({ state })
    const secret = getQuerySecret({ state })
    const { email, password, displayName } = action.values

    if (email && password && displayName && inviteId && secret) {
      yield spawn(postUserInvitedSaga, {
        values: {
          inviteId,
          secret,
          email,
          password,
          displayName,
        },
      })

      const postUserResult = yield race({
        success: take(POST_USER_INVITED_SUCCESS),
        failureOrCancel: take([
          POST_USER_INVITED_FAILURE,
          POST_USER_INVITED_CANCEL,
        ]),
      })

      if (postUserResult.success) {
        const postLoginRequestAction = createPostLoginRequestAction({
          requestValues: {
            email,
            password,
          },
        })

        yield put(postLoginRequestAction)
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewUserInvitedF',
        })
      )
    }

    yield put({
      type: SUBMIT_USER_INVITED_DONE,
    })
  }
}
