import React, { useCallback } from 'react'
import { ToBacklogButtonProps } from './types/ToBacklogButtonProps'
import { ActionButton } from '../ActionButton'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { TimesSolidIcon } from '../../icon/TimesSolidIcon/TimesSolidIcon'

export const ToBacklogButton = ({
  moveToBackLog,
  taskId,
  className = '',
}: ToBacklogButtonProps): JSX.Element => {
  const onClickCallback = useCallback(() => {
    moveToBackLog(taskId)
  }, [moveToBackLog, taskId])

  return (
    <ActionButton
      className={`ToBacklogButton ${className}`}
      onClick={onClickCallback}
      title="Unset"
    >
      <IconAndDiv icon={<TimesSolidIcon />}>Unset</IconAndDiv>
    </ActionButton>
  )
}
