import { TaskSizesMainStyles } from './TaskSizesMainStyles'
import React from 'react'
// import { TaskSizesMainProps } from './types/TaskSizesMainProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TaskSizeListConnected } from 'components/list/TaskSizeList'
import { UPDATE_TASK_SIZES_ROUTE } from 'routes'
import { flexRightStyles } from 'styles/flex/flexRightStyles'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { EditIcon } from 'components/icon/EditIcon/EditIcon'
import { actionButtonStyles } from 'styles/actionButtonStyles'

export const TaskSizesMain = (): JSX.Element => {
  return (
    <main className={'TaskSizesMain ' + TaskSizesMainStyles}>
      <h1>Configure Task Sizes</h1>

      <header className={flexRightStyles}>
        <CustomLinkConnected
          title="configure"
          route={UPDATE_TASK_SIZES_ROUTE}
          className={actionButtonStyles}
          withCurrentQuery={true}
        >
          <IconAndDiv icon={<EditIcon />}>Customize</IconAndDiv>
        </CustomLinkConnected>
      </header>

      <div className={lightBoxStyles}>
        <TaskSizeListConnected />
      </div>
    </main>
  )
}
