import { TaskFilterFormStyles } from './TaskFilterFormStyles'
import React, { useState, useEffect, useCallback } from 'react'
import { TaskFilterFormProps } from './types/TaskFilterFormProps'
import { CollapsibleDivConnected } from 'components/div/CollapsibleDiv'
import { SelectTagInputFieldConnected } from 'components/field/SelectTagInputField/SelectTagInputFieldConnected'
import { ActionButton } from 'components/button/ActionButton'
import { TaskStatusButtonsInputFieldConnected } from 'components/field/TaskStatusButtonsInputField/TaskStatusButtonsInputFieldConnected'
import { CaretSquareLeftIcon } from 'components/icon/CaretSquareLeftIcon/CaretSquareLeftIcon'
import { CaretSquareLeftSolidIcon } from 'components/icon/CaretSquareLeftSolidIcon/CaretSquareLeftSolidIcon'
import { CaretSquareDownIcon } from 'components/icon/CaretSquareDownIcon/CaretSquareDownIcon'
import { QuickSearchFieldConnected } from 'components/field/QuickSearchField/QuickSearchFieldConnected'
import { TextInput } from 'components/input/TextInput'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'

export const TaskFilterForm = ({
  toggleFilters,
  showExtraFilters,
  unsetSessionStorage,
  unsetDefault,
  setDefaultFilter,
  onChange,
  tagIdsInitialValue,
  summaryInitialValue,
  taskStatusesInitialValue,
  showSetAsDefault,
}: TaskFilterFormProps): JSX.Element => {
  const [summaryState, setSummaryState] = useState<string | undefined>(
    undefined
  )
  const [taskStatusesState, setTaskStatusesState] = useState<
    string[] | undefined
  >(undefined)
  const [tagIdsState, setTagIdsState] = useState<string[] | undefined>(
    undefined
  )

  const onClick = () => {
    toggleFilters(!showExtraFilters)
  }

  const isExtraFiltersActive =
    (tagIdsState && tagIdsState.length > 0) ||
    (taskStatusesState && taskStatusesState.length > 0) ||
    (summaryState && summaryState.length > 0)

  const expandIcon = showExtraFilters ? (
    <CaretSquareDownIcon />
  ) : isExtraFiltersActive ? (
    <CaretSquareLeftSolidIcon />
  ) : (
    <CaretSquareLeftIcon />
  )

  const isExtraFiltersActiveClassName = isExtraFiltersActive
    ? ' isExtraFiltersActive'
    : ''

  const isSummaryFilterActiveClassName = !!summaryState
    ? ' isSummaryFilterActive'
    : ''

  useEffect(() => {
    setTagIdsState(tagIdsInitialValue)
  }, [tagIdsInitialValue])

  useEffect(() => {
    setSummaryState(summaryInitialValue)
  }, [summaryInitialValue])

  useEffect(() => {
    setTaskStatusesState(taskStatusesInitialValue)
  }, [taskStatusesInitialValue])

  const resetFilters = useCallback(() => {
    setTagIdsState(tagIdsInitialValue)
    setSummaryState(summaryInitialValue)
    setTaskStatusesState(taskStatusesInitialValue)
    unsetSessionStorage()
  }, [
    setTagIdsState,
    setSummaryState,
    setTaskStatusesState,
    unsetSessionStorage,
    summaryInitialValue,
    tagIdsInitialValue,
    taskStatusesInitialValue,
  ])

  return (
    <form
      className={`TaskFilterForm ${TaskFilterFormStyles} ${isSummaryFilterActiveClassName} ${isExtraFiltersActiveClassName}
    `}
    >
      <header>
        {!showExtraFilters && <QuickSearchFieldConnected />}

        {showExtraFilters && (
          <TextInput
            placeholder="Text search"
            name="summary"
            value={summaryState || ''}
            onChange={(e) => {
              setSummaryState(e.target.value)
              onChange({
                tagIds: tagIdsState,
                summary: e.target.value,
                taskStatuses: taskStatusesState,
              })
            }}
          />
        )}

        <button type="button" onClick={onClick}>
          {expandIcon}
        </button>
      </header>

      <CollapsibleDivConnected show={showExtraFilters}>
        <SelectTagInputFieldConnected
          selectedOptions={tagIdsState}
          onChange={(values) => {
            setTagIdsState(values)

            onChange({
              tagIds: values,
              summary: summaryState,
              taskStatuses: taskStatusesState,
            })
          }}
        />
        <TaskStatusButtonsInputFieldConnected
          values={taskStatusesState}
          onChange={(values) => {
            setTaskStatusesState(values)

            onChange({
              tagIds: tagIdsState,
              summary: summaryState,
              taskStatuses: values,
            })
          }}
        />
        <footer className="resetAndDefault">
          <ActionButton
            colorVariance="hollow"
            title="Clear"
            onClick={resetFilters}
          >
            <TimesSolidIcon /> <span>Clear</span>
          </ActionButton>

          {/* add this feature later if we need it */}
          {showSetAsDefault && (
            <ActionButton
              colorVariance="hollow"
              title="submit"
              onClick={() => {
                if (unsetDefault) {
                  resetFilters()
                }

                setDefaultFilter(
                  unsetDefault
                    ? {}
                    : {
                        tagIds: tagIdsState,
                        summary: summaryState,
                        taskStatuses: taskStatusesState,
                      }
                )

                toggleFilters(false)
              }}
            >
              {unsetDefault ? 'Unset' : 'Set as'} my default
            </ActionButton>
          )}
        </footer>
      </CollapsibleDivConnected>
    </form>
  )
}
