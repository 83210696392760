import { ActivityNotificationDropdownStyles } from './ActivityNotificationDropdownStyles'
import React from 'react'
import { ActivityNotificationDropdownProps } from './types/ActivityNotificationDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { NotificationListConnected } from 'components/list/NotificationList'
import { CountBubbleWidget } from 'components/widget/CountBubbleWidget'
import { BellSolidIcon } from 'components/icon/BellSolidIcon/BellSolidIcon'

export const ActivityNotificationDropdown = ({
  unreadCount = 0,
  showActivityNotificationsDropdown,
  isMediumScreen,
  className = '',
}: ActivityNotificationDropdownProps): JSX.Element => {
  const useUnreadCount = unreadCount > 99 ? 99 : unreadCount
  const activeClassName = showActivityNotificationsDropdown ? ' active' : ''
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

  return (
    <div
      className={`ActivityNotificationDropdown ${className} ${ActivityNotificationDropdownStyles} ${isMediumScreenClassName}`}
    >
      <DropdownButtonConnected
        title="See activities"
        watchSelector=".ActivityNotificationDropdown"
        className={activeClassName}
        dropdownType="activityNotifications"
      >
        <BellSolidIcon />

        {useUnreadCount > 0 && (
          <CountBubbleWidget>{useUnreadCount}</CountBubbleWidget>
        )}
      </DropdownButtonConnected>

      {showActivityNotificationsDropdown && (
        <DropdownConnected autoAdjust={true}>
          <NotificationListConnected notificationGroup="activities" />
        </DropdownConnected>
      )}
    </div>
  )
}
