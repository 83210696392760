import { TeamSettingsMainConnectedProps } from './types/TeamSettingsMainConnectedProps'
import { TeamSettingsMainMapStateReturnType } from './types/TeamSettingsMainMapStateReturnType'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsProFromState } from '../../../state/getters/getIsProFromState'

export const TeamSettingsMainMapState = (
  state: StateReducers,
  _props: TeamSettingsMainConnectedProps
): TeamSettingsMainMapStateReturnType => {
  const isPro = getIsProFromState({ state })

  return {
    isPro,
  }
}
