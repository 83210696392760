import { ReplyMessageCardStyles } from './ReplyMessageCardStyles'
import React from 'react'
import { ReplyMessageCardProps } from './types/ReplyMessageCardProps'
import { MarkdownDiv } from 'components/div/MarkdownDiv'
import { Button } from 'components/button/Button'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'

export const ReplyMessageCard = ({
  description,
  userDisplayName,
  onClose,
}: ReplyMessageCardProps): JSX.Element => {
  return (
    <div className={`ReplyMessageCard ${ReplyMessageCardStyles}`}>
      <Button className="close" onClick={onClose}>
        <TimesSolidIcon />
      </Button>

      <header>
        reply to <span className="displayName">{userDisplayName}</span>:
      </header>
      <MarkdownDiv>{description}</MarkdownDiv>
    </div>
  )
}
