import { forEach } from 'lodash'
import firebase from 'firebase'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { InvitesReducer } from 'state/reducers/types/InvitesReducer'

interface Return {
  ids: string[]
  createdSeconds: (number | null)[]
}

interface Props {
  itemsObject?: TasksReducer | MessagesReducer | PlansReducer | InvitesReducer
  reverse?: boolean
}

interface IdAndCreated {
  itemId: string
  created?: firebase.firestore.Timestamp
}

// TODO: optimize, this function runs about a dozen time
export const getSortedByCreated = ({ itemsObject, reverse }: Props): Return => {
  if (!itemsObject) {
    return { ids: [], createdSeconds: [] }
  }

  const idAndCreatedItemsMutable: IdAndCreated[] = []
  const idsWithNoCreated: string[] = []
  const createdNulls: null[] = []

  forEach(itemsObject, (item, itemId) => {
    const created = item.data?.created

    if (created instanceof firebase.firestore.Timestamp) {
      idAndCreatedItemsMutable.push({
        itemId,
        created,
      })
    } else {
      idsWithNoCreated.push(itemId)
      createdNulls.push(null)
    }
  })

  const sortedItems = idAndCreatedItemsMutable.sort((itemA, itemB) => {
    const { created: itemACreated } = itemA
    const { created: itemBCreated } = itemB

    const itemASeconds = (itemACreated && itemACreated.seconds) || 0
    const itemBSeconds = (itemBCreated && itemBCreated.seconds) || 0

    if (itemASeconds < itemBSeconds) {
      return reverse ? 1 : -1
    }

    if (itemASeconds > itemBSeconds) {
      return reverse ? -1 : 1
    }

    return 0
  })

  const sortedIds = sortedItems.map(({ itemId }) => {
    return itemId
  })

  const sortedSeconds = sortedItems.map(({ created }) => {
    return created ? created.seconds : 0
  })

  const ids = reverse
    ? [...idsWithNoCreated, ...sortedIds]
    : [...sortedIds, ...idsWithNoCreated]

  const createdSeconds = reverse
    ? [...createdNulls, ...sortedSeconds]
    : [...sortedSeconds, ...createdNulls]

  return { ids, createdSeconds }
}
