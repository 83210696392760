import { PATCH_PLANS_SUCCESS } from 'actions'

import { PatchPlansSuccessAction } from './types/PatchPlansSuccessAction'
import { PatchPlansSuccessActionValues } from './types/PatchPlansSuccessActionValues'
import { PatchPlansRequestActionValues } from './types/PatchPlansRequestActionValues'


interface Props {
  successValues: PatchPlansSuccessActionValues
  requestValues: PatchPlansRequestActionValues
}

export const createPatchPlansSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchPlansSuccessAction => {
  return {
    type: PATCH_PLANS_SUCCESS,
    requestValues,
    successValues,
  }
}
