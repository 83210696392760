import { connect } from 'react-redux'
import { RemoveTagButton } from './RemoveTagButton'
import { RemoveTagButtonMapState } from './RemoveTagButtonMapState'
import { RemoveTagButtonMapDispatch } from './RemoveTagButtonMapDispatch'

import { RemoveTagButtonMapStateReturnType } from './types/RemoveTagButtonMapStateReturnType'
import { RemoveTagButtonMapDispatchReturnType } from './types/RemoveTagButtonMapDispatchReturnType'
import { RemoveTagButtonConnectedProps } from './types/RemoveTagButtonConnectedProps'


export const RemoveTagButtonConnected = connect<
  RemoveTagButtonMapStateReturnType,
  RemoveTagButtonMapDispatchReturnType,
  RemoveTagButtonConnectedProps>(RemoveTagButtonMapState,
    RemoveTagButtonMapDispatch)(RemoveTagButton)
