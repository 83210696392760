import { TagDropdownStyles } from './TagDropdownStyles'
import React from 'react'
import { TagDropdownProps } from './types/TagDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { PlusSolidIcon } from 'components/icon/PlusSolidIcon/PlusSolidIcon'
import { TagDropdownMainConnected } from '../../main/TagDropdownMain/TagDropdownMainConnected'

export const TagDropdown = ({
  showTagDropdown,
  taskId,
  isTouchScreen,
}: TagDropdownProps): JSX.Element => {
  return (
    <div className={`TagDropdown _${taskId} ` + TagDropdownStyles}>
      <DropdownButtonConnected
        title="Set tag"
        watchSelector={`.TagDropdown._${taskId}`}
        dropdownType="tag"
        id={taskId}
      >
        <PlusSolidIcon />
      </DropdownButtonConnected>

      {showTagDropdown && (
        <DropdownConnected autoScroll={isTouchScreen ? 'top' : false}>
          <TagDropdownMainConnected taskId={taskId} />
        </DropdownConnected>
      )}
    </div>
  )
}
