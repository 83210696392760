import { Dispatch, Action } from 'redux'
import { TaskFilterFormMapDispatchReturnType } from './types/TaskFilterFormMapDispatchReturnType'
import { createSetSessionStorageAction } from 'actionCreators/sessionStorage'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_SET_DEFAULT_FILTER } from 'actions'

export const TaskFilterFormMapDispatch = (
  dispatch: Dispatch<Action>
): TaskFilterFormMapDispatchReturnType => {
  return {
    toggleFilters: (showExtraFilters) => {
      dispatch(
        createSetSessionStorageAction({
          description: 'TaskFilterFormMapDispatch',
          sessionStorage: {
            showExtraFilters,
          },
        })
      )
    },

    setDefaultFilter: ({ summary, taskStatuses, tagIds }) => {
      dispatch(
        createSubmitAction({
          values: { summary, taskStatuses, tagIds },
          type: SUBMIT_SET_DEFAULT_FILTER,
        })
      )
    },

    onChange: ({ summary, taskStatuses, tagIds }) => {
      dispatch(
        createSetSessionStorageAction({
          description: 'TaskFilterFormMapDispatch',
          sessionStorage: {
            taskFilters: {
              tagIds,
              summary,
              taskStatuses,
            },
          },
        })
      )
    },

    unsetSessionStorage: () => {
      dispatch(
        createSetSessionStorageAction({
          description: 'TaskFilterFormMapDispatch',
          sessionStorage: {
            taskFilters: null,
            showExtraFilters: false,
          },
        })
      )
    },
  }
}
