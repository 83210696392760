import * as React from 'react'
import { TeamListConnected } from './TeamListConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const TeamListExamples = () => {
  return (
    <div className="TeamListExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <TeamListConnected />
      </Provider>
    </div>
  )
}
