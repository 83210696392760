import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  MessageActionListConnectedProps,
  MessageActionListMapStateReturnType,
} from './types'

export const MessageActionListMapState = (
  state: StateReducers,
  props: MessageActionListConnectedProps
): MessageActionListMapStateReturnType => {
  const message = state.messages?.[props.messageId]
  const canEdit = state.currentUserId === message?.data.userId
  const canDelete = state.currentUserId === message?.data.userId

  return {
    canDelete,
    canEdit,
  }
}
