import { css } from 'emotion'

export const TaskMainStyles = css`
  .TaskTagDropdown {
    .Dropdown {
      .removeTagButton {
        background-color: none;
      }
    }
  }

  .TaskFileList {
    margin-top: 16px;
  }

  .TaskChangesMain {
    margin-top: 16px;
  }

  .uploadedFilesHeader {
    margin: 32px 0 0 16px;
  }

  .detailAndMessages {
    display: flex;
    margin-top: 16px;

    .taskDetail {
      background-color: #fff;
      flex-grow: 1;
      padding-bottom: 16px;

      > header {
        padding: 16px;
      }
    }

    .MessagesMain {
      margin-left: 16px;
      width: 450px;
      flex-shrink: 0;

      .MessageList {
        min-height: 500px;
        max-height: 500px;

        .MessageCard {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .NewMessageEditor {
        padding: 0;
        margin-top: 8px;
        background-color: initial;
      }

      .WorkingMessageList {
        padding: 0;
      }
    }
  }
`
