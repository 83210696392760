import { css } from 'emotion'
import { BIG_LAYOUT_WIDTH } from 'constants/styleConstants'

export const OneLayoutStyles = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 0px 128px 0px;

  .BigHeader {
    padding: 8px 8px 32px 8px;
  }

  .plainHeader {
    padding: 8px;
    .GoBackLink {
      margin-top: 32px;
    }
  }

  > main {
    > * {
      max-width: 540px;
      margin: 0 auto;
      padding: 16px;
    }

    .TaskMain {
      padding: 0;
      max-width: 100%;
    }
  }

  &.isMediumScreen {
    .BigHeader {
      padding: 16px 16px 32px 16px;
    }
  }

  &.isBigScreen {
    padding: 0px 0px 128px 0px;

    > main,
    .BigHeader,
    .plainHeader {
      max-width: ${BIG_LAYOUT_WIDTH};
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .BigHeader,
    .plainHeader {
      padding: 16px 0 32px 0;
    }

    > main {
      > * {
        padding: 0;
      }
    }
  }
`
