import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import {
  SUBMIT_WORKING_ON_TASK_DESCRIPTION,
  SUBMIT_WORKING_ON_TASK_DESCRIPTION_DONE,
} from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { clientServerTimestamp } from 'sdks'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitWorkingOnTaskDescriptionF = function*() {
  while (1) {
    const action: SubmitAction = yield take([
      SUBMIT_WORKING_ON_TASK_DESCRIPTION,
    ])
    const working = action.values.working
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const query = getCurrentQuery({ state })
    const taskId = query && query.taskId
    const teamId = getCurrentTeamId({ state })

    if (taskId && teamId && currentUserId) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            workingOnDescription: working
              ? {
                  userId: currentUserId,
                  updated: clientServerTimestamp(),
                }
              : null,
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items: items,
        lastModifiedUserId: 'skip',
        lastModifiedDisplayName: 'skip',
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskDescriptionF',
        })
      )
    }

    yield put({
      type: SUBMIT_WORKING_ON_TASK_DESCRIPTION_DONE,
    })
  }
}
