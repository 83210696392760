import { Notification } from 'firebaseFunctions/types'
import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentUserExtension } from './getCurrentUserExtension'

interface Props {
  state: StateReducers
}

export const getActivityNotifications = ({ state }: Props): Notification[] => {
  const userExtension = getCurrentUserExtension({ state })
  const activityNotifications = userExtension?.activityNotifications || []

  return activityNotifications
}
