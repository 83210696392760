import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'

export const MARK_DOWN_STYLES = `
  word-break: break-word;

  ol, ul {
    display: block;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 8px;

    > li {
      margin-top: 8px;
    }
  }

  ul {
    list-style: inside disc;
  }

  ol {
    list-style: inside decimal;
  }

  h1 {
    font-size: 1.45em;
    line-height: 1.5em;
    margin: 32px 0 16px 0;
    font-weight: normal;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 1.25em;
    line-height: 1.3em;
    margin: 16px 0 8px 0;
    font-weight: normal;
  }

  h3 {
    font-size: 1.05em;
    margin: 16px 0 8px 0;
    font-weight: normal;
  }

  a {
    cursor: pointer;
  }

  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    overflow: auto;
    overflow-y: hidden;
  }

  code {
    background-color: #fff;
    color: #e01e5a;
    border-radius: 3px;
    padding: 2px 4px;
    border: 1px solid ${NEUTRAL_BORDER_COLOR};
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 10px;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
    margin: 16px 0px;
  }
`

export const MarkdownDivStyles = css(MARK_DOWN_STYLES)
