import { connect } from 'react-redux'
import { ReadAllButton } from './ReadAllButton'
import { ReadAllButtonMapState } from './ReadAllButtonMapState'
import { ReadAllButtonMapDispatch } from './ReadAllButtonMapDispatch'

import { ReadAllButtonMapStateReturnType } from './types/ReadAllButtonMapStateReturnType'
import { ReadAllButtonMapDispatchReturnType } from './types/ReadAllButtonMapDispatchReturnType'
import { ReadAllButtonConnectedProps } from './types/ReadAllButtonConnectedProps'


export const ReadAllButtonConnected = connect<
  ReadAllButtonMapStateReturnType,
  ReadAllButtonMapDispatchReturnType,
  ReadAllButtonConnectedProps>(ReadAllButtonMapState,
    ReadAllButtonMapDispatch)(ReadAllButton)
