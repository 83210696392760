import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'
import { getNotifications } from 'state/getters/getNotifications'
import { TASK_DETAIL_ROUTE, TASK_MESSAGES_ROUTE, PLAN_ROUTE } from 'routes'

import { NotificationCardConnectedProps } from './types/NotificationCardConnectedProps'
import { NotificationCardMapStateReturnType } from './types/NotificationCardMapStateReturnType'


export const NotificationCardMapState = (
  state: StateReducers,
  props: NotificationCardConnectedProps
): NotificationCardMapStateReturnType => {
  const { notificationGroup, notificationIndex } = props
  const notifications = getNotifications({ state, notificationGroup })
  const notification = notifications[notificationIndex]
  const unreadCount = notification?.unreadCount
  const userId = notification?.userId
  const userDisplayName = notification?.userDisplayName
  const created = notification?.created
  const teamId = getCurrentTeamId({ state })
  const taskId = notification?.taskId
  const messageId = notification?.messageId
  const tagDisplayName = notification?.tagDisplayName
  const type = notification?.type
  const status = notification?.status
  const isActivities = notificationGroup === 'activities'
  const isMediumScreen = getIsMediumScreen({ state })
  const taskSummary = notification?.taskSummary
  const messageSummary = notification?.messageSummary

  const route =
    type === 'closePlan'
      ? PLAN_ROUTE
      : isMediumScreen || isActivities
      ? TASK_DETAIL_ROUTE
      : TASK_MESSAGES_ROUTE

  return {
    unreadCount,
    created,
    userId,
    userDisplayName,
    teamId,
    taskId,
    messageId,
    type,
    tagDisplayName,
    status,
    clearOnClick: isActivities,
    route,
    taskSummary,
    messageSummary,
  }
}
