import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const TaskFileListStyles = css`
  > li {
    margin: 16px;

    &:first-child {
      margin-top: 0px;
    }
  }
`
