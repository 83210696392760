import { TaskStatusButtonsInputFieldStyles } from './TaskStatusButtonsInputFieldStyles'
import React, { useCallback } from 'react'
import { TaskStatusButtonsInputFieldProps } from './types/TaskStatusButtonsInputFieldProps'
import { Button } from 'components/button/Button'

export const TaskStatusButtonsInputField = ({
  taskStatuses,
  onChange,
  values,
}: TaskStatusButtonsInputFieldProps): JSX.Element => {
  const handleOnClick = useCallback(
    (status: string) => {
      const existing = values && values.indexOf(status) > -1

      const newValuesMutable: string[] = values
        ? values.filter((value) => {
            return status !== value
          })
        : []

      if (!existing) {
        newValuesMutable.push(status)
      }

      onChange?.(newValuesMutable)
    },
    [values, onChange]
  )

  return (
    <ul
      className={
        'TaskStatusButtonsInputField ' + TaskStatusButtonsInputFieldStyles
      }
    >
      {taskStatuses?.map((taskStatus, i) => {
        const isActive = values && values.indexOf(taskStatus) > -1

        return (
          <li key={i} className={isActive ? 'isActive' : ''}>
            <Button
              onClick={() => {
                handleOnClick(taskStatus)
              }}
            >
              {taskStatus}
            </Button>
          </li>
        )
      })}
    </ul>
  )
}
