import { Dispatch, Action } from 'redux'
import { NewTaskFormMapDispatchReturnType } from './types/NewTaskFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_NEW_TASK } from 'actions'

export const NewTaskFormMapDispatch = (
  dispatch: Dispatch<Action>
): NewTaskFormMapDispatchReturnType => {
  return {
    submit: ({ summary }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_NEW_TASK,
          values: { summary },
          description: 'NewTaskFormMapDispatch',
        })
      )
    },
  }
}
