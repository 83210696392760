import { POST_LOGIN_SUCCESS } from 'actions'

import { PostLoginSuccessAction } from './types/PostLoginSuccessAction'
import { PostLoginSuccessActionValues } from './types/PostLoginSuccessActionValues'
import { PostLoginRequestActionValues } from './types/PostLoginRequestActionValues'


interface Props {
  successValues: PostLoginSuccessActionValues
  requestValues?: PostLoginRequestActionValues
}

export const createPostLoginSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostLoginSuccessAction => {
  return {
    type: POST_LOGIN_SUCCESS,
    requestValues,
    successValues,
  }
}
