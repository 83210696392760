import { POST_USER_SUCCESS } from 'actions'

import { PostUserSuccessAction } from './types/PostUserSuccessAction'
import { PostUserSuccessActionValues } from './types/PostUserSuccessActionValues'
import { PostUserRequestActionValues } from './types/PostUserRequestActionValues'


interface Props {
  successValues: PostUserSuccessActionValues
  requestValues?: PostUserRequestActionValues
}

export const createPostUserSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostUserSuccessAction => {
  return {
    type: POST_USER_SUCCESS,
    requestValues,
    successValues,
  }
}
