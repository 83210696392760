import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createPatchInvitesRequestAction } from 'actionCreators/patch/PatchInvites/createPatchInvitesRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import {
  SUBMIT_UPDATE_INVITE_ROLE,
  SUBMIT_UPDATE_INVITE_ROLE_DONE,
} from 'actions'
import { createSetAlertMessageAction } from '../../../actionCreators/set/SetAlertMessage/createSetAlertMessageAction'

export const submitUpdateInviteRoleF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_INVITE_ROLE])
    const { role } = action.values
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state }) || {}
    const teamId = query.teamId
    const inviteId = action.values.inviteId || (query && query.inviteId)

    if (role && teamId && inviteId) {
      yield put(
        createPatchInvitesRequestAction({
          requestValues: {
            items: {
              [inviteId]: {
                data: {
                  teamId,
                  role,
                },
                teamId,
              },
            },
          },
        })
      )

      yield put(
        createSetAlertMessageAction({
          description: 'submitClosePlanF',
          values: {
            location: 'toast',
            type: 'success',
            message: 'Updated',
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateRoleF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_INVITE_ROLE_DONE,
    })
  }
}
