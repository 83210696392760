import { connect } from 'react-redux'
import { ToastListWatcher } from './ToastListWatcher'
import { ToastListWatcherMapState } from './ToastListWatcherMapState'
import { ToastListWatcherMapDispatch } from './ToastListWatcherMapDispatch'
import { ToastListWatcherMapStateReturnType } from './types/ToastListWatcherMapStateReturnType'
import { ToastListWatcherMapDispatchReturnType } from './types/ToastListWatcherMapDispatchReturnType'
import { ToastListWatcherConnectedProps } from './types/ToastListWatcherConnectedProps'

export const ToastListWatcherConnected = connect<
  ToastListWatcherMapStateReturnType,
  ToastListWatcherMapDispatchReturnType,
  ToastListWatcherConnectedProps
>(
  ToastListWatcherMapState,
  ToastListWatcherMapDispatch
)(ToastListWatcher)
