import { POST_PLANS_SUCCESS } from 'actions'

import { PostPlansSuccessAction } from './types/PostPlansSuccessAction'
import { PostPlansSuccessActionValues } from './types/PostPlansSuccessActionValues'
import { PostPlansRequestActionValues } from './types/PostPlansRequestActionValues'


interface Props {
  successValues: PostPlansSuccessActionValues
  requestValues: PostPlansRequestActionValues
}

export const createPostPlansSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostPlansSuccessAction => {
  return {
    type: POST_PLANS_SUCCESS,
    requestValues,
    successValues,
  }
}
