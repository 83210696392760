import React from 'react'
import { TaskPlanActionListProps } from './types/TaskPlanActionListProps'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'
import { ToBacklogButtonConnected } from '../../button/ToBacklogButton/ToBacklogButtonConnected'
import { ToCurrentCycleButtonConnected } from '../../button/ToCurrentCycleButton/ToCurrentCycleButtonConnected'
import { ToNextCycleButtonConnected } from '../../button/ToNextCycleButton/ToNextCycleButtonConnected'
import { ToPreviousCycleButtonConnected } from '../../button/ToPreviousCycleButton/ToPreviousCycleButtonConnected'

export const TaskPlanActionList = ({
  className = '',
  taskCycle,
  taskId,
  previousPlanId,
}: TaskPlanActionListProps): JSX.Element => {
  return (
    <ul
      className={`TaskPlanActionList ${listActionButtonsStyles} ${className}`}
    >
      {taskCycle !== undefined && (
        <li>
          <ToBacklogButtonConnected taskId={taskId} />
        </li>
      )}
      {taskCycle !== 'next' && (
        <li>
          <ToNextCycleButtonConnected taskId={taskId} />
        </li>
      )}
      {taskCycle !== 'current' && (
        <li>
          <ToCurrentCycleButtonConnected taskId={taskId} />
        </li>
      )}
      {taskCycle !== 'previous' && previousPlanId && (
        <li>
          <ToPreviousCycleButtonConnected taskId={taskId} />
        </li>
      )}
    </ul>
  )
}
