import { ResetPasswordSentMainStyles } from './ResetPasswordSentMainStyles'
import React from 'react'
import { EnvelopeIcon } from 'components/icon/EnvelopeIcon/EnvelopeIcon'
// import { ResetPasswordSentMainProps } from './types/ResetPasswordSentMainProps'

export const ResetPasswordSentMain = (): JSX.Element => {
  return (
    <div className={'ResetPasswordSentMain ' + ResetPasswordSentMainStyles}>
      <div className="main">
        <EnvelopeIcon />

        <p>We sent you an email, please check your inbox.</p>
      </div>
    </div>
  )
}
