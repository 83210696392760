import { connect } from 'react-redux'
import { StripeSubscriptionDropdown } from './StripeSubscriptionDropdown'
import { StripeSubscriptionDropdownMapState } from './StripeSubscriptionDropdownMapState'
import { StripeSubscriptionDropdownMapDispatch } from './StripeSubscriptionDropdownMapDispatch'
import { StripeSubscriptionDropdownMapStateReturnType } from './types/StripeSubscriptionDropdownMapStateReturnType'
import { StripeSubscriptionDropdownMapDispatchReturnType } from './types/StripeSubscriptionDropdownMapDispatchReturnType'
import { StripeSubscriptionDropdownConnectedProps } from './types/StripeSubscriptionDropdownConnectedProps'

export const StripeSubscriptionDropdownConnected = connect<
  StripeSubscriptionDropdownMapStateReturnType,
  StripeSubscriptionDropdownMapDispatchReturnType,
  StripeSubscriptionDropdownConnectedProps
>(
  StripeSubscriptionDropdownMapState,
  StripeSubscriptionDropdownMapDispatch
)(StripeSubscriptionDropdown)
