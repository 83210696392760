import { GET_TEAM_LOGO_URL_SUCCESS } from 'actions'

import { GetTeamLogoUrlSuccessAction } from './types/GetTeamLogoUrlSuccessAction'
import { GetTeamLogoUrlSuccessActionValues } from './types/GetTeamLogoUrlSuccessActionValues'
import { GetTeamLogoUrlRequestActionValues } from './types/GetTeamLogoUrlRequestActionValues'


interface Props {
  requestValues: GetTeamLogoUrlRequestActionValues
  successValues: GetTeamLogoUrlSuccessActionValues
}

export const createGetTeamLogoUrlSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetTeamLogoUrlSuccessAction => {
  return {
    type: GET_TEAM_LOGO_URL_SUCCESS,
    requestValues,
    successValues,
  }
}
