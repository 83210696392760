import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

import { SUBMIT_DELETE_INVITE, SUBMIT_UPDATE_INVITE_ROLE } from 'actions'
import { RoleTypes } from 'firebaseFunctions/types'
import { SelectOption } from 'types/SelectOption'

import { InviteMainConnectedProps } from './types/InviteMainConnectedProps'
import { InviteMainMapDispatchReturnType } from './types/InviteMainMapDispatchReturnType'

export const InviteMainMapDispatch = (
  dispatch: Dispatch<Action>,
  props: InviteMainConnectedProps
): InviteMainMapDispatchReturnType => {
  return {
    deleteInvite: () => {
      const action = createSubmitAction({
        values: {
          inviteId: props.inviteId,
        },
        type: SUBMIT_DELETE_INVITE,
      })

      dispatch(action)

      const toggle = createToggleAction({
        description: 'InviteMainMapDispatch',
        toggles: {
          showModal: false,
        },
      })

      dispatch(toggle)
    },

    updateRole: (selectOption: SelectOption) => {
      const action = createSubmitAction({
        values: {
          role: selectOption.value as RoleTypes,
          inviteId: props.inviteId,
        },
        type: SUBMIT_UPDATE_INVITE_ROLE,
      })

      dispatch(action)
    },
  }
}
