import { GET_MESSAGES_SUCCESS } from 'actions'

import { GetMessagesSuccessAction } from './types/GetMessagesSuccessAction'
import { GetMessagesSuccessActionValues } from './types/GetMessagesSuccessActionValues'
import { GetMessagesRequestActionValues } from './types/GetMessagesRequestActionValues'


interface Props {
  successValues: GetMessagesSuccessActionValues
  requestValues?: GetMessagesRequestActionValues
  description: string
}

export const createGetMessagesSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): GetMessagesSuccessAction => {
  return {
    type: GET_MESSAGES_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
