import { StateReducers } from '../reducers/types/StateReducers'
import { forEach } from 'lodash'
import { GenericObject } from 'types/GenericObject'

interface Props {
  state: StateReducers
}

// returning an object instead of an array to allow faster searches
export const getAllPlanTaskIdObject = ({
  state,
}: Props): GenericObject<boolean> => {
  let taskIds: GenericObject<boolean> = {}

  const plans = state.plans

  forEach(plans, (planReducer) => {
    forEach(planReducer.data.taskIds, (taskId) => {
      taskIds[taskId] = true
    })
  })

  return taskIds
}
