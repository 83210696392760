import { connect } from 'react-redux'
import { TaskFileField } from './TaskFileField'
import { TaskFileFieldMapState } from './TaskFileFieldMapState'
import { TaskFileFieldMapDispatch } from './TaskFileFieldMapDispatch'

import { TaskFileFieldMapStateReturnType } from './types/TaskFileFieldMapStateReturnType'
import { TaskFileFieldMapDispatchReturnType } from './types/TaskFileFieldMapDispatchReturnType'
import { TaskFileFieldConnectedProps } from './types/TaskFileFieldConnectedProps'


export const TaskFileFieldConnected = connect<
  TaskFileFieldMapStateReturnType,
  TaskFileFieldMapDispatchReturnType,
  TaskFileFieldConnectedProps>(TaskFileFieldMapState,
    TaskFileFieldMapDispatch)(TaskFileField)
