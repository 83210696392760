import { Dispatch, Action } from 'redux'
import { UpdatePasswordFormMapDispatchReturnType } from './types/UpdatePasswordFormMapDispatchReturnType'
import { SUBMIT_UPDATE_PASSWORD } from 'actions'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const UpdatePasswordFormMapDispatch = (
  dispatch: Dispatch<Action>
): UpdatePasswordFormMapDispatchReturnType => {
  return {
    submit: ({ password, newPassword }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_UPDATE_PASSWORD,
          values: { password, newPassword },
          description: 'UpdatePasswordFormMapDispatch',
        })
      )
    },
  }
}
