import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { TeamLogoWidgetConnectedProps } from './types/TeamLogoWidgetConnectedProps'
import { TeamLogoWidgetMapStateReturnType } from './types/TeamLogoWidgetMapStateReturnType'

export const TeamLogoWidgetMapState = (
  state: StateReducers,
  props: TeamLogoWidgetConnectedProps
): TeamLogoWidgetMapStateReturnType => {
  const teamReducer = getCurrentTeamReducer({ state, teamId: props.teamId })
  const logoUrl = teamReducer?.logoUrl

  return {
    logoUrl,
  }
}
