import { GET_COOKIE_STORAGE_SUCCESS } from 'actions'
import { CookieStorage } from 'types/CookieStorage'
import { GetCookieStorageSuccessAction } from './types/GetCookieStorageSuccessAction'

interface Props {
  values: CookieStorage
}

export const createGetCookieStorageSuccessAction = ({ values }: Props): GetCookieStorageSuccessAction => {
  return {
    type: GET_COOKIE_STORAGE_SUCCESS,
    values,
  }
}
