import { TeamNameWidgetStyles } from './TeamNameWidgetStyles'
import React from 'react'
import { TeamNameWidgetProps } from './types/TeamNameWidgetProps'
import { TeamLogoWidgetConnected } from '../TeamLogoWidget/TeamLogoWidgetConnected'

export const TeamNameWidget = ({
  displayName,
  showLogoOnly,
  teamId,
  className = '',
}: TeamNameWidgetProps): JSX.Element => {
  const showLogoOnlyClassName = showLogoOnly ? ' showLogoOnly' : ''
  const extraClassname = className ? ` ${className}` : ''

  return (
    <div
      className={
        'TeamNameWidget ' +
        TeamNameWidgetStyles +
        showLogoOnlyClassName +
        extraClassname
      }
    >
      {!showLogoOnly && <span className="displayName">{displayName}</span>}
      <TeamLogoWidgetConnected teamId={teamId} />
    </div>
  )
}
