import { css } from 'emotion'

export const ModalStyles = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  padding: 8px;

  .wrapper {
    background-color: #fff;
    margin: 25px auto 11px auto;
    border-radius: 4px;
    max-width: 90vw;
    min-height: 90vh;
    position: relative;
    padding: 32px 16px;
    /*overflow: hidden; lets disable this for now, not sure what the right decision is*/

    .closeButton {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      padding: 8px;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: -1;
  }

  &.isMediumScreen {
    .wrapper {
      margin: 100px auto 44px auto;
      border-radius: 4px;
      max-width: 520px;
      min-height: initial;
    }
  }
`
