import { UPLOAD_REQUEST } from 'actions'
import { UploadRequestAction } from './types/UploadRequestAction'
import { UploadRequestActionValues } from './types/UploadRequestActionValues'

interface Props {
  requestValues: UploadRequestActionValues,
}

export const createUploadRequestAction = ({ requestValues }: Props): UploadRequestAction => {
  return {
    type: UPLOAD_REQUEST,
    requestValues,
  }
}
