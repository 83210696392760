import { connect } from 'react-redux'
import { ToCurrentCycleButton } from './ToCurrentCycleButton'
import { ToCurrentCycleButtonMapState } from './ToCurrentCycleButtonMapState'
import { ToCurrentCycleButtonMapDispatch } from './ToCurrentCycleButtonMapDispatch'
import { ToCurrentCycleButtonMapStateReturnType } from './types/ToCurrentCycleButtonMapStateReturnType'
import { ToCurrentCycleButtonMapDispatchReturnType } from './types/ToCurrentCycleButtonMapDispatchReturnType'
import { ToCurrentCycleButtonConnectedProps } from './types/ToCurrentCycleButtonConnectedProps'

export const ToCurrentCycleButtonConnected = connect<
  ToCurrentCycleButtonMapStateReturnType,
  ToCurrentCycleButtonMapDispatchReturnType,
  ToCurrentCycleButtonConnectedProps>(ToCurrentCycleButtonMapState,
    ToCurrentCycleButtonMapDispatch)(ToCurrentCycleButton)
