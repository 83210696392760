import { getMentionUserIds } from 'utilities/get/getMentionUserIds'
import { uniq } from 'lodash'
import { Message } from 'firebaseFunctions/types'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { spawn } from 'redux-saga/effects'
import { postMessageSaga } from './post/postMessageSaga'

interface Props {
  taskId: string
  teamId: string
  messageId: string
  userId: string
  taggedUserIds: string[]
  markdown: string
  userDisplayName: string
  userPhotoUrl?: string
  taskSummary: string
  repliedToMessageId?: string
  repliedToUserId?: string
  repliedToDescription?: string
}

export const newMessageSaga = function*({
  taskId,
  teamId,
  messageId,
  userId,
  taggedUserIds = [],
  markdown,
  userDisplayName,
  userPhotoUrl,
  taskSummary,
  repliedToMessageId,
  repliedToUserId,
  repliedToDescription,
}: Props) {
  const taggedUserids = taggedUserIds.filter((taggedUserId) => {
    return taggedUserId !== userId
  })

  const mentionUserIds = markdown ? getMentionUserIds(markdown) : []
  const notifyUserIds = uniq(taggedUserids.concat(mentionUserIds))

  const newMessageMutable: Message = {
    userId,
    description: markdown,
    userDisplayName,
    taskSummary,
    notifyUserIds,
  }

  if (repliedToMessageId && repliedToUserId) {
    newMessageMutable.repliedToMessageId = repliedToMessageId
    newMessageMutable.repliedToUserId = repliedToUserId
    newMessageMutable.repliedToDescription = repliedToDescription
  }

  if (userPhotoUrl) {
    newMessageMutable.userPhotoUrl = userPhotoUrl
  }

  const items: MessagesReducer = {
    [messageId]: {
      data: newMessageMutable,
      teamId,
      taskId,
    },
  }

  yield spawn(postMessageSaga, { items })
}
