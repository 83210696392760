import { css } from 'emotion'

export const containerStyles = css`
  top: 39px;
  width: 100vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
`
