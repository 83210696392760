import * as React from 'react'
import { ServiceWorkerMessageWatcherConnected } from './ServiceWorkerMessageWatcherConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const ServiceWorkerMessageWatcherExamples = () => {
  return (
    <div className="ServiceWorkerMessageWatcherExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <ServiceWorkerMessageWatcherConnected />
      </Provider>
    </div>
  )
}
