 import { StateReducers } from 'state/reducers/types/StateReducers'
import { ToBacklogButtonConnectedProps } from './types/ToBacklogButtonConnectedProps'
import { ToBacklogButtonMapStateReturnType } from './types/ToBacklogButtonMapStateReturnType'

export const ToBacklogButtonMapState = (
  _state: StateReducers,
  _props: ToBacklogButtonConnectedProps,
): ToBacklogButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
