import { Location } from 'history'
import { UrlQuery } from 'types/UrlQuery'
import { createCleanQueryString } from './createCleanQueryString'

import {
  QUERY_NAME_END_TO_END_TESTING,
  QUERY_NAME_RETURN_PATH,
  QUERY_NAME_RETURN_QUERY,
  QUERY_NAME_USER_ID,
  QUERY_NAME_TEAM_ID,
  QUERY_NAME_TASK_ID,
  QUERY_NAME_PLAN_ID,
  QUERY_NAME_SUBSCRIPTION_ID,
  QUERY_NAME_INVITE_ID,
  QUERY_NAME_TEAM_NAME,
  QUERY_NAME_SECRET,
  QUERY_NAME_REFRESH_ID_TOKEN,
  QUERY_NAME_EMAIL,
} from 'constants/queryNameConstants'

interface Props {
  pathname?: string
  query?: UrlQuery
  queryString?: string
}

export const createLocation = ({
  pathname = '',
  query = {},
  queryString,
}: Props): Location => {
  const isEndToEndTesting = query?.isEndToEndTesting
  const returnPath = query?.returnPath
  const returnQuery = query?.returnQuery
  const userId = query?.userId
  const teamId = query?.teamId
  const planId = query?.planId
  const subscriptionId = query?.subscriptionId
  const taskId = query?.taskId
  const inviteId = query?.inviteId
  const teamName = query?.teamName
  const refreshIdToken = query?.refreshIdToken
  const email = query?.email
  const secret = query?.secret

  const mappedQuery = {
    [QUERY_NAME_END_TO_END_TESTING]: isEndToEndTesting,
    [QUERY_NAME_RETURN_PATH]: returnPath,
    [QUERY_NAME_RETURN_QUERY]: returnQuery,
    [QUERY_NAME_USER_ID]: userId,
    [QUERY_NAME_TEAM_ID]: teamId,
    [QUERY_NAME_TASK_ID]: taskId,
    [QUERY_NAME_PLAN_ID]: planId,
    [QUERY_NAME_SUBSCRIPTION_ID]: subscriptionId,
    [QUERY_NAME_EMAIL]: email,
    [QUERY_NAME_INVITE_ID]: inviteId,
    [QUERY_NAME_TEAM_NAME]: teamName,
    [QUERY_NAME_REFRESH_ID_TOKEN]: refreshIdToken,
    [QUERY_NAME_SECRET]: secret,
  }

  const search = queryString || createCleanQueryString({ query: mappedQuery })

  return {
    pathname,
    search,
    state: '',
    hash: '',
    key: '',
  }
}
