import { POST_PLANS_REQUEST } from 'actions'
import { PostPlansRequestAction } from './types/PostPlansRequestAction'
import { PostPlansRequestActionValues } from './types/PostPlansRequestActionValues'

interface Props {
  requestValues: PostPlansRequestActionValues,
}

export const createPostPlansRequestAction = ({ requestValues }: Props): PostPlansRequestAction => {
  return {
    type: POST_PLANS_REQUEST,
    requestValues,
  }
}
