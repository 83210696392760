import { DELETE_UPLOADED_FILES_SUCCESS } from 'actions'
import { DeleteUploadedFilesRequestActionValues } from './types/DeleteUploadedFilesRequestActionValues'
import { DeleteUploadedFilesSuccessActionValues } from './types/DeleteUploadedFilesSuccessActionValues'
import { DeleteUploadedFilesSuccessAction } from './types/DeleteUploadedFilesSuccessAction'

interface Props {
  requestValues: DeleteUploadedFilesRequestActionValues
  successValues: DeleteUploadedFilesSuccessActionValues
}

export const createDeleteUploadedFilesSuccessAction = ({
  successValues,
  requestValues,
}: Props): DeleteUploadedFilesSuccessAction => {
  return {
    type: DELETE_UPLOADED_FILES_SUCCESS,
    requestValues,
    successValues,
  }
}
