export const PATCH_TEAMS_REQUEST = 'PATCH_TEAMS_REQUEST'
export const PATCH_TEAMS_SUCCESS = 'PATCH_TEAMS_SUCCESS'
export const PATCH_TEAMS_FAILURE = 'PATCH_TEAMS_FAILURE'
export const PATCH_TEAMS_CANCEL = 'PATCH_TEAMS_CANCEL'

export const PATCH_TEAMS_FF_REQUEST = 'PATCH_TEAMS_FF_REQUEST'
export const PATCH_TEAMS_FF_SUCCESS = 'PATCH_TEAMS_FF_SUCCESS'
export const PATCH_TEAMS_FF_FAILURE = 'PATCH_TEAMS_FF_FAILURE'
export const PATCH_TEAMS_FF_CANCEL = 'PATCH_TEAMS_FF_CANCEL'

export const PATCH_TASKS_REQUEST = 'PATCH_TASKS_REQUEST'
export const PATCH_TASKS_SUCCESS = 'PATCH_TASKS_SUCCESS'
export const PATCH_TASKS_FAILURE = 'PATCH_TASKS_FAILURE'
export const PATCH_TASKS_CANCEL = 'PATCH_TASKS_CANCEL'

export const PATCH_USERS_REQUEST = 'PATCH_USERS_REQUEST'
export const PATCH_USERS_SUCCESS = 'PATCH_USERS_SUCCESS'
export const PATCH_USERS_FAILURE = 'PATCH_USERS_FAILURE'
export const PATCH_USERS_CANCEL = 'PATCH_USERS_CANCEL'

export const PATCH_MESSAGES_REQUEST = 'PATCH_MESSAGES_REQUEST'
export const PATCH_MESSAGES_SUCCESS = 'PATCH_MESSAGES_SUCCESS'
export const PATCH_MESSAGES_FAILURE = 'PATCH_MESSAGES_FAILURE'
export const PATCH_MESSAGES_CANCEL = 'PATCH_MESSAGES_CANCEL'

export const PATCH_INVITES_REQUEST = 'PATCH_INVITES_REQUEST'
export const PATCH_INVITES_SUCCESS = 'PATCH_INVITES_SUCCESS'
export const PATCH_INVITES_FAILURE = 'PATCH_INVITES_FAILURE'
export const PATCH_INVITES_CANCEL = 'PATCH_INVITES_CANCEL'

export const PATCH_USER_EXTENSION_REQUEST_DELAY =
  'PATCH_USER_EXTENSION_REQUEST_DELAY'
export const PATCH_USER_EXTENSION_REQUEST = 'PATCH_USER_EXTENSION_REQUEST'
export const PATCH_USER_EXTENSION_SUCCESS = 'PATCH_USER_EXTENSION_SUCCESS'
export const PATCH_USER_EXTENSION_FAILURE = 'PATCH_USER_EXTENSION_FAILURE'
export const PATCH_USER_EXTENSION_CANCEL = 'PATCH_USER_EXTENSION_CANCEL'

export const PATCH_USER_INVITES_REQUEST = 'PATCH_USER_INVITES_REQUEST'
export const PATCH_USER_INVITES_SUCCESS = 'PATCH_USER_INVITES_SUCCESS'
export const PATCH_USER_INVITES_FAILURE = 'PATCH_USER_INVITES_FAILURE'
export const PATCH_USER_INVITES_CANCEL = 'PATCH_USER_INVITES_CANCEL'

export const PATCH_CUSTOM_CLAIM_REQUEST = 'PATCH_CUSTOM_CLAIM_REQUEST'
export const PATCH_CUSTOM_CLAIM_SUCCESS = 'PATCH_CUSTOM_CLAIM_SUCCESS'
export const PATCH_CUSTOM_CLAIM_FAILURE = 'PATCH_CUSTOM_CLAIM_FAILURE'
export const PATCH_CUSTOM_CLAIM_CANCEL = 'PATCH_CUSTOM_CLAIM_CANCEL'

export const PATCH_TEAM_STAT_REQUEST = 'PATCH_TEAM_STAT_REQUEST'
export const PATCH_TEAM_STAT_SUCCESS = 'PATCH_TEAM_STAT_SUCCESS'
export const PATCH_TEAM_STAT_FAILURE = 'PATCH_TEAM_STAT_FAILURE'
export const PATCH_TEAM_STAT_CANCEL = 'PATCH_TEAM_STAT_CANCEL'

export const PATCH_PLANS_REQUEST = 'PATCH_PLANS_REQUEST'
export const PATCH_PLANS_SUCCESS = 'PATCH_PLANS_SUCCESS'
export const PATCH_PLANS_FAILURE = 'PATCH_PLANS_FAILURE'
export const PATCH_PLANS_CANCEL = 'PATCH_PLANS_CANCEL'

export const PATCH_UNSCOPE_USER_REQUEST = 'PATCH_UNSCOPE_USER_REQUEST'
export const PATCH_UNSCOPE_USER_SUCCESS = 'PATCH_UNSCOPE_USER_SUCCESS'
export const PATCH_UNSCOPE_USER_FAILURE = 'PATCH_UNSCOPE_USER_FAILURE'
export const PATCH_UNSCOPE_USER_CANCEL = 'PATCH_UNSCOPE_USER_CANCEL'
