import { TaskPlanDropdownStyles } from './TaskPlanDropdownStyles'
import React from 'react'
import { TaskPlanDropdownProps } from './types/TaskPlanDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { TaskPlanSpanConnected } from 'components/span/TaskPlanSpan'
import { PLAN_NOUN } from 'constants/english'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'
import { TaskPlanActionListConnected } from '../../list/TaskPlanActionList/TaskPlanActionListConnected'

export const TaskPlanDropdown = ({
  showTaskPlanDropdown,
  taskId,
  canWrite,
}: TaskPlanDropdownProps): JSX.Element => {
  return (
    <div className={`TaskPlanDropdown _${taskId} ` + TaskPlanDropdownStyles}>
      <DropdownButtonConnected
        dropdownType="taskPlan"
        className={`${textLinkStyles} ${textSmallestStyles}`}
        watchSelector={`.TaskPlanDropdown._${taskId}`}
        disabled={!canWrite}
        id={taskId}
        title="Set cycle"
      >
        <TaskPlanSpanConnected taskId={taskId} backLogText={PLAN_NOUN} />
      </DropdownButtonConnected>

      {showTaskPlanDropdown && (
        <DropdownConnected autoAdjust={true}>
          <TaskPlanActionListConnected taskId={taskId} />
        </DropdownConnected>
      )}
    </div>
  )
}
