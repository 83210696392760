export const POST_CONFIRM_EMAIL_REQUEST = 'POST_CONFIRM_EMAIL_REQUEST'
export const POST_CONFIRM_EMAIL_SUCCESS = 'POST_CONFIRM_EMAIL_SUCCESS'
export const POST_CONFIRM_EMAIL_FAILURE = 'POST_CONFIRM_EMAIL_FAILURE'
export const POST_CONFIRM_EMAIL_CANCEL = 'POST_CONFIRM_EMAIL_CANCEL'

export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST'
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS'
export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE'
export const POST_LOGIN_CANCEL = 'POST_LOGIN_CANCEL'

export const POST_USER_REQUEST = 'POST_USER_REQUEST'
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS'
export const POST_USER_FAILURE = 'POST_USER_FAILURE'
export const POST_USER_CANCEL = 'POST_USER_CANCEL'

export const POST_TASKS_REQUEST = 'POST_TASKS_REQUEST'
export const POST_TASKS_SUCCESS = 'POST_TASKS_SUCCESS'
export const POST_TASKS_FAILURE = 'POST_TASKS_FAILURE'
export const POST_TASKS_CANCEL = 'POST_TASKS_CANCEL'

export const POST_INVITES_REQUEST = 'POST_INVITES_REQUEST'
export const POST_INVITES_SUCCESS = 'POST_INVITES_SUCCESS'
export const POST_INVITES_FAILURE = 'POST_INVITES_FAILURE'
export const POST_INVITES_CANCEL = 'POST_INVITES_CANCEL'

export const POST_MESSAGES_REQUEST = 'POST_MESSAGES_REQUEST'
export const POST_MESSAGES_SUCCESS = 'POST_MESSAGES_SUCCESS'
export const POST_MESSAGES_FAILURE = 'POST_MESSAGES_FAILURE'
export const POST_MESSAGES_CANCEL = 'POST_MESSAGES_CANCEL'

export const POST_DEVICES_REQUEST = 'POST_DEVICES_REQUEST'
export const POST_DEVICES_SUCCESS = 'POST_DEVICES_SUCCESS'
export const POST_DEVICES_FAILURE = 'POST_DEVICES_FAILURE'
export const POST_DEVICES_CANCEL = 'POST_DEVICES_CANCEL'

export const POST_TEAMS_REQUEST = 'POST_TEAMS_REQUEST'
export const POST_TEAMS_SUCCESS = 'POST_TEAMS_SUCCESS'
export const POST_TEAMS_FAILURE = 'POST_TEAMS_FAILURE'
export const POST_TEAMS_CANCEL = 'POST_TEAMS_CANCEL'

export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST'
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS'
export const POST_RESET_PASSWORD_FAILURE = 'POST_RESET_PASSWORD_FAILURE'
export const POST_RESET_PASSWORD_CANCEL = 'POST_RESET_PASSWORD_CANCEL'

export const POST_RE_LOGIN_REQUEST = 'POST_RE_LOGIN_REQUEST'
export const POST_RE_LOGIN_SUCCESS = 'POST_RE_LOGIN_SUCCESS'
export const POST_RE_LOGIN_FAILURE = 'POST_RE_LOGIN_FAILURE'
export const POST_RE_LOGIN_CANCEL = 'POST_RE_LOGIN_CANCEL'

export const POST_PLANS_REQUEST = 'POST_PLANS_REQUEST'
export const POST_PLANS_SUCCESS = 'POST_PLANS_SUCCESS'
export const POST_PLANS_FAILURE = 'POST_PLANS_FAILURE'
export const POST_PLANS_CANCEL = 'POST_PLANS_CANCEL'

export const POST_USER_EXTENSION_REQUEST = 'POST_USER_EXTENSION_REQUEST'
export const POST_USER_EXTENSION_SUCCESS = 'POST_USER_EXTENSION_SUCCESS'
export const POST_USER_EXTENSION_FAILURE = 'POST_USER_EXTENSION_FAILURE'
export const POST_USER_EXTENSION_CANCEL = 'POST_USER_EXTENSION_CANCEL'

export const POST_UNSCOPE_USER_REQUEST = 'POST_UNSCOPE_USER_REQUEST'
export const POST_UNSCOPE_USER_SUCCESS = 'POST_UNSCOPE_USER_SUCCESS'
export const POST_UNSCOPE_USER_FAILURE = 'POST_UNSCOPE_USER_FAILURE'
export const POST_UNSCOPE_USER_CANCEL = 'POST_UNSCOPE_USER_CANCEL'

export const POST_USER_INVITED_REQUEST = 'POST_USER_INVITED_REQUEST'
export const POST_USER_INVITED_SUCCESS = 'POST_USER_INVITED_SUCCESS'
export const POST_USER_INVITED_FAILURE = 'POST_USER_INVITED_FAILURE'
export const POST_USER_INVITED_CANCEL = 'POST_USER_INVITED_CANCEL'

export const POST_STRIPE_CUSTOMERS_REQUEST = 'POST_STRIPE_CUSTOMERS_REQUEST'
export const POST_STRIPE_CUSTOMERS_SUCCESS = 'POST_STRIPE_CUSTOMERS_SUCCESS'
export const POST_STRIPE_CUSTOMERS_FAILURE = 'POST_STRIPE_CUSTOMERS_FAILURE'
export const POST_STRIPE_CUSTOMERS_CANCEL = 'POST_STRIPE_CUSTOMERS_CANCEL'

export const POST_STRIPE_SUBSCRIPTIONS_REQUEST =
  'POST_STRIPE_SUBSCRIPTIONS_REQUEST'
export const POST_STRIPE_SUBSCRIPTIONS_SUCCESS =
  'POST_STRIPE_SUBSCRIPTIONS_SUCCESS'
export const POST_STRIPE_SUBSCRIPTIONS_FAILURE =
  'POST_STRIPE_SUBSCRIPTIONS_FAILURE'
export const POST_STRIPE_SUBSCRIPTIONS_CANCEL =
  'POST_STRIPE_SUBSCRIPTIONS_CANCEL'
