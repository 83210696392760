// Only add constants if its common otherwise, styles should be per component
export const PRIMARY_COLOR = '#7950f2'
export const PRIMARY_COLOR_DARK = '#5431bd'
export const BORDER_RADIUS = '4px'
export const NEUTRAL_HIGH_LIGHT_COLOR = '#eee'
export const NEUTRAL_HIGH_LIGHT_COLOR_DARK = '#444'
export const WARNING_HIGHLIGHT_COLOR = 'rgba(249, 221, 137, 1)'
export const WARNING_HIGHLIGHT_COLOR_DARK = 'rgba(245, 211, 109, 1)'
export const NEUTRAL_BORDER_COLOR_LIGHT = '#d7d7d7'
export const NEUTRAL_BORDER_COLOR = '#dfe1e5'
export const NEUTRAL_BORDER_DARK = '#a2a2a4'
export const NEUTRAL_BORDER_DARKER = '#9c9da0'
export const PLACEHOLDER_COLOR = '#ccc'
export const WARNING_COLOR = '#d2b502'
export const WARNING_COLOR_DARK = '#af9700'
export const DANGER_COLOR = '#d73a49'
export const DANGER_COLOR_DARK = '#a02b36'
export const TEXT_COLOR = 'rgb(29, 28, 29)'
export const LINK_COLOR = '#1D4495'
export const LINK_COLOR_DARK = '#0D1423'
export const LINK_HIGHLIGHT_COLOR =
  'rgba(var(--sk_highlight_accent,29,155,209),.1)'
export const LINK_VISITED_COLOR = '#609'
export const TEXT_COLOR_LIGHT = 'rgb(97, 96, 97)'
export const TEXT_COLOR_LIGHTER = '#677578'
export const SUCCESS_COLOR = '#51b474'
export const SUCCESS_COLOR_DARK = '#408f5c'
export const PRIMARY_BACKGROUND_COLOR = '#F2F3F5'
export const BIG_LAYOUT_WIDTH = '1024px'
export const FONT_WEIGHT_NORMAL = '400'
export const FONT_WEIGHT_BOLD = '600'
export const FONT_WEIGHT_BOLDER = '800'
