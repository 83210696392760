import { take, put, select } from 'redux-saga/effects'
import { selectState } from 'state/reducers/selectState'
import { FIRESTORE_FAILURE } from 'actions'
import { SELECT_TEAM_ROUTE } from 'routes'
import {
  EMAIL_ALREADY_IN_USE,
  PERMISSION_DENIED,
} from 'firebaseFunctions/constants/failureCodesConstants'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { FirebaseErrorAction } from 'actionCreators/types/FirebaseErrorAction'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSetAlertMessageAction } from 'actionCreators/set/SetAlertMessage/createSetAlertMessageAction'
import { sentrySdk } from 'sdks/sentry/sentrySdk'
import { SentrySeverity } from 'sdks/sentry/SentrySeverity'
import { StateReducers } from '../state/reducers/types/StateReducers'
import { firebaseSdk } from '../sdks'

export const firestoreFailureF = function*() {
  while (1) {
    const action: FirebaseErrorAction = yield take([FIRESTORE_FAILURE])
    const { code, message } = action.error

    const state: StateReducers = yield select(selectState)
    const isSelectTeamRoute = state.currentLocation?.isRoutes?.isSelectTeamRoute

    sentrySdk.captureMessage(
      `type: ${action.type} code: ${code}`,
      SentrySeverity.Critical
    )

    if (code === EMAIL_ALREADY_IN_USE) {
      yield put(
        createSetAlertMessageAction({
          description: 'firestoreFailureF',
          values: {
            location: 'form',
            actionType: action.type,
            type: 'error',
            message: message,
          },
        })
      )
    } else {
      if (code === PERMISSION_DENIED) {
        if (isSelectTeamRoute) {
          firebaseSdk.auth().signOut()
        } else {
          yield put(
            createHistoryAction({
              pathname: SELECT_TEAM_ROUTE,
              method: 'replace',
              description: 'createHistoryAction',
            })
          )
        }
      }

      yield put(
        createToggleAction({
          description: 'firestoreFailureF',
          toggles: {
            showModal: {
              type: 'Error',
            },
          },
        })
      )
    }
  }
}
