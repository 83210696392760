import { connect } from 'react-redux'
import { DraftEditorHooked } from './DraftEditorHooked'
import { DraftEditorMapState } from './DraftEditorMapState'
import { DraftEditorMapDispatch } from './DraftEditorMapDispatch'

import { DraftEditorMapStateReturnType } from './types/DraftEditorMapStateReturnType'
import { DraftEditorMapDispatchReturnType } from './types/DraftEditorMapDispatchReturnType'
import { DraftEditorConnectedProps } from './types/DraftEditorConnectedProps'


export const DraftEditorConnected = connect<
  DraftEditorMapStateReturnType,
  DraftEditorMapDispatchReturnType,
  DraftEditorConnectedProps
>(
  DraftEditorMapState,
  DraftEditorMapDispatch
)(DraftEditorHooked)
