import { deleteTasksRequestF } from './deleteTasksRequestF'
import { deleteUserInvitesRequestF } from './deleteUserInvitesRequestF'
import { deletePlansRequestF } from './deletePlansRequestF'

// order doesnt matter
export const deleteFlows = [
  deleteTasksRequestF,
  deleteUserInvitesRequestF,
  deletePlansRequestF,
]
