import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentInvite } from 'state/getters/getCurrentInvite'
import firebase from 'firebase'
import { InviteCardConnectedProps } from './types/InviteCardConnectedProps'
import { InviteCardMapStateReturnType } from './types/InviteCardMapStateReturnType'

export const InviteCardMapState = (
  state: StateReducers,
  props: InviteCardConnectedProps
): InviteCardMapStateReturnType => {
  const invite = getCurrentInvite({ state, inviteId: props.inviteId })
  const email = invite?.email
  const role = invite?.role
  const createdSeconds =
    invite?.created instanceof firebase.firestore.Timestamp
      ? invite?.created.seconds
      : undefined

  return {
    email,
    userRole: role,
    createdSeconds,
  }
}
