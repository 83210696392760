import { connect } from 'react-redux'
import { WorkspaceStorageUploadMessage } from './WorkspaceStorageUploadMessage'
import { WorkspaceStorageUploadMessageMapState } from './WorkspaceStorageUploadMessageMapState'
import { WorkspaceStorageUploadMessageMapDispatch } from './WorkspaceStorageUploadMessageMapDispatch'
import { WorkspaceStorageUploadMessageMapStateReturnType } from './types/WorkspaceStorageUploadMessageMapStateReturnType'
import { WorkspaceStorageUploadMessageMapDispatchReturnType } from './types/WorkspaceStorageUploadMessageMapDispatchReturnType'
import { WorkspaceStorageUploadMessageConnectedProps } from './types/WorkspaceStorageUploadMessageConnectedProps'

export const WorkspaceStorageUploadMessageConnected = connect<
  WorkspaceStorageUploadMessageMapStateReturnType,
  WorkspaceStorageUploadMessageMapDispatchReturnType,
  WorkspaceStorageUploadMessageConnectedProps>(WorkspaceStorageUploadMessageMapState,
    WorkspaceStorageUploadMessageMapDispatch)(WorkspaceStorageUploadMessage)
