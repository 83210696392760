import { connect } from 'react-redux'
import { LabelList } from './LabelList'
import { LabelListMapState } from './LabelListMapState'
import { LabelListMapDispatch } from './LabelListMapDispatch'

import { LabelListMapStateReturnType } from './types/LabelListMapStateReturnType'
import { LabelListMapDispatchReturnType } from './types/LabelListMapDispatchReturnType'
import { LabelListConnectedProps } from './types/LabelListConnectedProps'


export const LabelListConnected = connect<
  LabelListMapStateReturnType,
  LabelListMapDispatchReturnType,
  LabelListConnectedProps>(LabelListMapState,
    LabelListMapDispatch)(LabelList)
