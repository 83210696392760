import React, { useState } from 'react'
import { TasksWatcherProps } from './types/TasksWatcherProps'
import { SingleTaskWatcherConnected } from 'components/watcher/SingleTaskWatcher'
import { PlanTasksWatcherConnected } from 'components/watcher/PlanTasksWatcher'
import { TeamTasksSingleWatcherConnected } from '../TeamTasksSingleWatcher/TeamTasksSingleWatcherConnected'

export const TasksWatcher = ({
  teamId,
  taskIds = [],
  planIds = [],
}: TasksWatcherProps): JSX.Element | null => {
  const [localTaskIds, setLocalTaskIds] = useState(taskIds)
  const [localPlanIds, setLocalPlanIds] = useState(planIds)
  const newLocalTaskIds = [...localTaskIds]
  const newLocalPlanIds = [...localPlanIds]

  taskIds.forEach((taskId) => {
    if (newLocalTaskIds.indexOf(taskId) === -1) {
      newLocalTaskIds.push(taskId)
    }
  })

  if (newLocalTaskIds.length !== localTaskIds.length) {
    setLocalTaskIds(newLocalTaskIds)
  }

  planIds.forEach((planId) => {
    if (newLocalPlanIds.indexOf(planId) === -1) {
      newLocalPlanIds.push(planId)
    }
  })

  if (newLocalPlanIds.length !== localPlanIds.length) {
    setLocalPlanIds(newLocalPlanIds)
  }

  if (!teamId) {
    return null
  }

  return (
    <>
      <TeamTasksSingleWatcherConnected teamId={teamId} />

      {localTaskIds.map((taskId) => {
        return (
          <SingleTaskWatcherConnected
            teamId={teamId}
            taskId={taskId}
            key={taskId}
          />
        )
      })}
      {localPlanIds.map((planId) => {
        return (
          <PlanTasksWatcherConnected
            teamId={teamId}
            planId={planId}
            key={planId}
          />
        )
      })}
    </>
  )
}
