import { css } from 'emotion'
import { SUCCESS_COLOR, BORDER_RADIUS } from 'constants/styleConstants'

export const ResetPasswordSentMainStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;

  p {
    margin-top: 16px;
  }

  span {
    margin: 4px;
    color: ${SUCCESS_COLOR};
  }

  .main {
    background-color: white;
    border-radius: ${BORDER_RADIUS};
    padding: 16px;
    text-align: center;
  }

  .EnvelopeIcon {
    width: 60px;
    color: ${SUCCESS_COLOR};
  }
`
