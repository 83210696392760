import { connect } from 'react-redux'
import { UploadWatcherList } from './UploadWatcherList'
import { UploadWatcherListMapState } from './UploadWatcherListMapState'
import { UploadWatcherListMapDispatch } from './UploadWatcherListMapDispatch'

import { UploadWatcherListMapStateReturnType } from './types/UploadWatcherListMapStateReturnType'
import { UploadWatcherListMapDispatchReturnType } from './types/UploadWatcherListMapDispatchReturnType'
import { UploadWatcherListConnectedProps } from './types/UploadWatcherListConnectedProps'


export const UploadWatcherListConnected = connect<
  UploadWatcherListMapStateReturnType,
  UploadWatcherListMapDispatchReturnType,
  UploadWatcherListConnectedProps>(UploadWatcherListMapState,
    UploadWatcherListMapDispatch)(UploadWatcherList)
