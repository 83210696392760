import * as React from 'react'
import { UserWorkingWidget } from './UserWorkingWidget'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_USER_A_ID } from 'mockState'

export const UserWorkingWidgetExamples = () => {
  return (
    <div className="UserWorkingWidgetExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <UserWorkingWidget userId={MOCK_USER_A_ID} />
      </Provider>
    </div>
  )
}
