import { StateReducers } from 'state/reducers/types/StateReducers'
import { LabelDropdownConnectedProps } from './types/LabelDropdownConnectedProps'
import { LabelDropdownMapStateReturnType } from './types/LabelDropdownMapStateReturnType'
import { getLabel } from 'state/getters/getLabel'

export const LabelDropdownMapState = (
  state: StateReducers,
  props: LabelDropdownConnectedProps
): LabelDropdownMapStateReturnType => {
  const showDropdown = state.toggles?.showDropdown
  const showLabelDropdown =
    showDropdown &&
    showDropdown.type === 'label' &&
    showDropdown.id === props.labelId
  const label = getLabel({ state, labelId: props.labelId })
  const archived = label?.archived

  return {
    showLabelDropdown,
    archived,
  }
}
