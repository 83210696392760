import { css } from 'emotion'
import {
  TEXT_COLOR,
  PLACEHOLDER_COLOR,
  DANGER_COLOR,
} from 'constants/styleConstants'

export const TextInputStyles = css`
  height: 44px;
  border-radius: 4px;
  outline: 0;
  color: ${TEXT_COLOR};
  background: #fff;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 10px;
  cursor: text;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }

  &::placeholder {
    color: ${PLACEHOLDER_COLOR};
  }

  &.clean {
    border-color: rgba(0, 0, 0, 0);
  }

  &.hasError {
    border-color: ${DANGER_COLOR};
  }

  &:disabled {
    background: #fff;
    color: rgba(51, 51, 51, 0.2);
    border: 1px solid #d7d7d7;
  }

  &:hover {
    border-color: #bbb;
  }

  &:focus {
    border-color: #7950f2;
  }

  &[type='range'] {
    border-bottom: none;

    &:focus {
      border-bottom: none;
    }
  }
`
