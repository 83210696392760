import { DELETE_PLANS_SUCCESS } from 'actions'
import { DeletePlansSuccessActionValues } from './types/DeletePlansSuccessActionValues'
import { DeletePlansSuccessAction } from './types/DeletePlansSuccessAction'
import { DeletePlansRequestActionValues } from './types/DeletePlansRequestActionValues'

interface Props {
  successValues: DeletePlansSuccessActionValues
  requestValues: DeletePlansRequestActionValues
}

export const createDeletePlansSuccessAction = ({
  successValues,
  requestValues,
}: Props): DeletePlansSuccessAction => {
  return {
    type: DELETE_PLANS_SUCCESS,
    requestValues,
    successValues,
  }
}
