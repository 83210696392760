import { connect } from 'react-redux'
import { TasksWatcher } from './TasksWatcher'
import { TasksWatcherMapState } from './TasksWatcherMapState'
import { TasksWatcherMapDispatch } from './TasksWatcherMapDispatch'

import { TasksWatcherMapStateReturnType } from './types/TasksWatcherMapStateReturnType'
import { TasksWatcherMapDispatchReturnType } from './types/TasksWatcherMapDispatchReturnType'
import { TasksWatcherConnectedProps } from './types/TasksWatcherConnectedProps'


export const TasksWatcherConnected = connect<
  TasksWatcherMapStateReturnType,
  TasksWatcherMapDispatchReturnType,
  TasksWatcherConnectedProps>(TasksWatcherMapState,
    TasksWatcherMapDispatch)(TasksWatcher)
