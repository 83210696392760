// import { WorkspaceStorageUploadMessageStyles } from './WorkspaceStorageUploadMessageStyles'
import React from 'react'
import { WorkspaceStorageUploadMessageProps } from './types/WorkspaceStorageUploadMessageProps'
import { ErrorMessage } from '../ErrorMessage'
import { UpgradeNowLink } from 'components/link/UpgradeNowLink'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TEAM_FILES_ROUTE } from 'routes'
import { PRO_PRODUCT_NOUN } from 'constants/english'
import { textLinkStyles } from 'styles/text/textLinkStyles'

export const WorkspaceStorageUploadMessage = ({
  error,
  isPro,
  isMaxCapacityError,
  canOwn,
}: WorkspaceStorageUploadMessageProps): JSX.Element => {
  return (
    <ErrorMessage>
      {error}{' '}
      {isMaxCapacityError && canOwn && (
        <span>
          {!isPro && (
            <>
              <UpgradeNowLink /> or{' '}
            </>
          )}
          <CustomLinkConnected
            route={TEAM_FILES_ROUTE}
            title="Manage files"
            withCurrentQuery={true}
            className={`${textLinkStyles}`}
          >
            delete files
          </CustomLinkConnected>
        </span>
      )}
      {isMaxCapacityError && !canOwn && (
        <span>
          Please ask an admin to{' '}
          {!isPro && `upgrade to ${PRO_PRODUCT_NOUN} or `} delete files.
        </span>
      )}
    </ErrorMessage>
  )
}
