import { Action } from 'redux'
import { UNAVAILABLE_TASK } from 'actions'
import { UnavailableTaskAction } from 'actionCreators/types/UnavailableTaskAction'

export const unavailableTasksReducer = (
  state?: string[],
  action?: Action
): string[] => {
  if (!action) {
    return state || []
  }

  switch (action.type) {
    case UNAVAILABLE_TASK: {
      const unavailableTaskAction = action as UnavailableTaskAction
      const previousState = state || []

      return previousState.concat([unavailableTaskAction.taskId])
    }

    default:
      return state || []
  }
}
