export const TASK_NOUN = 'task'
export const TASKS_NOUN = 'tasks'
export const PLAN_NOUN = 'cycle'
export const TEAM_NOUN = 'workspace'
export const TEAMS_NOUN = 'workspaces'
export const LABELS_NOUN = 'custom tags'
export const LABEL_NOUN = 'custom tag'
export const USER_NOUN = 'user'
export const USERS_NOUN = 'users'

export const PRO_PRODUCT_NOUN = 'EggKat Pro'
export const FREE_PRODUCT_NOUN = 'EggKat Free'
