import { css } from 'emotion'
import {
  TEXT_COLOR_LIGHTER,
  NEUTRAL_BORDER_COLOR,
  FONT_WEIGHT_BOLD,
  TEXT_COLOR_LIGHT,
} from 'constants/styleConstants'

export const MessageCardStyles = css`
  display: flex;

  .Avatar {
    flex-shrink: 0;
  }

  > main {
    margin-left: 8px;
    width: 100%;

    .displayName {
      font-weight: bold;
    }

    .created,
    .DotDotDotWidget,
    .edited {
      font-size: 0.9em;
      margin-left: 4px;
      color: ${TEXT_COLOR_LIGHTER};
    }

    .displayNameAndButton {
      display: flex;
      justify-content: space-between;
    }
  }

  &:hover {
    .displayNameAndButton {
      background-color: #e8e8e8;
    }
  }

  .repliedToDescription {
    max-height: 50px;
    overflow: scroll;
    padding-left: 8px;
    border-left: 2px solid ${NEUTRAL_BORDER_COLOR};
    margin: 8px 0px 16px 0;
  }

  .repliedTo {
    font-size: 0.8em;
    color: ${TEXT_COLOR_LIGHT};

    .DisplayNameSpan {
      font-weight: ${FONT_WEIGHT_BOLD};
    }
  }
`
