import { connect } from 'react-redux'
import { SingleTaskWatcher } from './SingleTaskWatcher'
import { SingleTaskWatcherMapState } from './SingleTaskWatcherMapState'
import { SingleTaskWatcherMapDispatch } from './SingleTaskWatcherMapDispatch'

import { SingleTaskWatcherMapStateReturnType } from './types/SingleTaskWatcherMapStateReturnType'
import { SingleTaskWatcherMapDispatchReturnType } from './types/SingleTaskWatcherMapDispatchReturnType'
import { SingleTaskWatcherConnectedProps } from './types/SingleTaskWatcherConnectedProps'


export const SingleTaskWatcherConnected = connect<
  SingleTaskWatcherMapStateReturnType,
  SingleTaskWatcherMapDispatchReturnType,
  SingleTaskWatcherConnectedProps>(SingleTaskWatcherMapState,
    SingleTaskWatcherMapDispatch)(SingleTaskWatcher)
