import { DescriptionToolBarStyles } from './DescriptionToolBarStyles'
import React from 'react'
import { DescriptionToolBarProps } from './types/DescriptionToolBarProps'
import { EditorEmojiButtonConnected } from 'components/button/EditorEmojiButton'
import { ActionButton } from 'components/button/ActionButton'

export const DescriptionToolBar = ({
  editorState,
  setEditorState,
  showEmojitButton,
  submit,
  contentChangedSinceSaved,
}: DescriptionToolBarProps): JSX.Element => {
  return (
    <div
      className={'DescriptionToolBar spaceBetween ' + DescriptionToolBarStyles}
    >
      {showEmojitButton && (
        <EditorEmojiButtonConnected
          dropdownId="DescriptionToolList"
          editorState={editorState}
          setEditorState={setEditorState}
        />
      )}

      {contentChangedSinceSaved && (
        <ActionButton title="Save" onClick={submit}>
          Save
        </ActionButton>
      )}
    </div>
  )
}
