import { connect } from 'react-redux'
import { CustomLink } from './CustomLink'

import {
  CustomLinkMapState,
} from './CustomLinkMapState'

import {
  CustomLinkMapDispatch,
} from './CustomLinkMapDispatch'

import { CustomLinkConnectedProps } from './types/CustomLinkConnectedProps'
import { CustomLinkMapDispatchReturnType } from './types/CustomLinkMapDispatchReturnType'
import { CustomLinkMapStateReturnType } from './types/CustomLinkMapStateReturnType'


export const CustomLinkConnected = connect<
  CustomLinkMapStateReturnType,
  CustomLinkMapDispatchReturnType,
  CustomLinkConnectedProps>(CustomLinkMapState,
    CustomLinkMapDispatch)(CustomLink)
