// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // TeamNameLinkConnectedProps,
  TeamNameLinkMapDispatchReturnType,
} from './types'

export const TeamNameLinkMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TeamNameLinkConnectedProps,
): TeamNameLinkMapDispatchReturnType => {
  return {
  }
}
