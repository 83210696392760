// import { Dispatch, Action } from 'redux'

import {
  // NotificationListConnectedProps,
  NotificationListMapDispatchReturnType,
} from './types'

export const NotificationListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: NotificationListConnectedProps,
): NotificationListMapDispatchReturnType => {
  return {
  }
}
