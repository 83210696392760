import { connect } from 'react-redux'
import { UploadFilesList } from './UploadFilesList'
import { UploadFilesListMapState } from './UploadFilesListMapState'
import { UploadFilesListMapDispatch } from './UploadFilesListMapDispatch'

import { UploadFilesListMapStateReturnType } from './types/UploadFilesListMapStateReturnType'
import { UploadFilesListMapDispatchReturnType } from './types/UploadFilesListMapDispatchReturnType'
import { UploadFilesListConnectedProps } from './types/UploadFilesListConnectedProps'


export const UploadFilesListConnected = connect<
  UploadFilesListMapStateReturnType,
  UploadFilesListMapDispatchReturnType,
  UploadFilesListConnectedProps>(UploadFilesListMapState,
    UploadFilesListMapDispatch)(UploadFilesList)
