import { put } from 'redux-saga/effects'
import { firestore, clientServerTimestamp } from 'sdks'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPatchMessagesRequestAction } from 'actionCreators/patch/PatchMessages/createPatchMessagesRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { updateMessage } from 'firebaseFunctions/utilities/ref/update/updateMessage'
import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { createPatchMessagesSuccessAction } from 'actionCreators/patch/PatchMessages/createPatchMessagesSuccessAction'
import { createPatchMessagesFailureAction } from 'actionCreators/patch/PatchMessages/createPatchMessagesFailureAction'

interface Props {
  items: MessagesReducer
}

export const patchMessagesSaga = function*({ items }: Props) {
  yield put(
    createPatchMessagesRequestAction({
      requestValues: {
        items,
      },
    })
  )

  const { key: messageId, item: firstMessage } = getObjectFirstItem(items)

  if (messageId && firstMessage) {
    try {
      const messageMutable = { ...firstMessage.data }
      messageMutable.edited = clientServerTimestamp()

      yield updateMessage({
        firestore,
        teamId: firstMessage.teamId,
        taskId: firstMessage.taskId,
        messageId,
        message: messageMutable,
      })

      yield put(
        createPatchMessagesSuccessAction({
          requestValues: { items },
          successValues: { items },
        })
      )
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'patchMessagesSaga',
        })
      )

      yield put(
        createPatchMessagesFailureAction({
          requestValues: { items },
        })
      )
    }
  } else {
    yield put(
      createUnexpectedErrorAction({
        description: 'patchMessagesSaga',
      })
    )
  }
}
