import { TeamStat } from '../../types'

interface Props {
  teamStat?: TeamStat
  configGigabytesPerUserFree: number
  configGigabytesPerUserPro: number
}

interface Return {
  gigabytesPerUserFree: number
  gigabytesPerUserPro: number
}

export const getMaxUploadSizeFromTeamStat = ({
  teamStat,
  configGigabytesPerUserFree,
  configGigabytesPerUserPro,
}: Props): Return => {
  const teamStatMaxUploadSizeGigabytesFree =
    teamStat?.uploadCapacityGigabytesPerUserFree || 0
  const teamStatMaxUploadSizeGigabytesPro =
    teamStat?.uploadCapacityGigabytesPerUserPro || 0

  const gigabytesPerUserFree =
    teamStatMaxUploadSizeGigabytesFree > configGigabytesPerUserFree
      ? teamStatMaxUploadSizeGigabytesFree
      : configGigabytesPerUserFree

  const gigabytesPerUserPro =
    teamStatMaxUploadSizeGigabytesPro > configGigabytesPerUserPro
      ? teamStatMaxUploadSizeGigabytesPro
      : configGigabytesPerUserPro

  return {
    gigabytesPerUserFree,
    gigabytesPerUserPro,
  }
}
