import { StateReducers } from 'state/reducers/types/StateReducers'
import { ProCanceledMainConnectedProps } from './types/ProCanceledMainConnectedProps'
import { ProCanceledMainMapStateReturnType } from './types/ProCanceledMainMapStateReturnType'
import { getCanOwn } from '../../../state/getters/getCanOwn'

export const ProCanceledMainMapState = (
  state: StateReducers,
  _props: ProCanceledMainConnectedProps
): ProCanceledMainMapStateReturnType => {
  const canOwn = getCanOwn({ state })

  return {
    canOwn,
  }
}
