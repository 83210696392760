import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // AlertMessageConnectedProps,
  AlertMessageMapStateReturnType,
} from './types'

export const AlertMessageMapState = (
  state: StateReducers
  // props: AlertMessageConnectedProps,
): AlertMessageMapStateReturnType => {
  const alertMessage = state.alertMessage || undefined
  const message = alertMessage && alertMessage.message
  const location = alertMessage?.location
  const showMessage = !!message && location === 'form'
  const type = alertMessage?.type

  return {
    showMessage,
    message,
    type,
  }
}
