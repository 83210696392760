import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import {
  // TaskStatusListConnectedProps,
  TaskStatusListMapStateReturnType,
} from './types'

export const TaskStatusListMapState = (
  state: StateReducers,
  // props: TaskStatusListConnectedProps,
): TaskStatusListMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const taskStatuses = team && team.taskStatuses

  return {
    taskStatuses: memoizeFlatArray(taskStatuses || [], 'TaskStatusListMapState-taskStatuses'),
  }
}
