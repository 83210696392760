import { WorkspaceSubscriptionMainStyles } from './WorkspaceSubscriptionMainStyles'
import React from 'react'
import { WorkspaceSubscriptionMainProps } from './types/WorkspaceSubscriptionMainProps'
import { DisplayNameSpanConnected } from 'components/span/DisplayNameSpan'
import { AvatarConnected } from 'components/widget/Avatar'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { UPGRADE_WORKSPACE_ROUTE } from 'routes'
import { CancelSubscriptionButtonConnected } from 'components/button/CancelSubscriptionButton/CancelSubscriptionButtonConnected'
import { ExclamationCircleIcon } from 'components/icon/ExclamationCircleIcon/ExclamationCircleIcon'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { FREE_PRODUCT_NOUN, PRO_PRODUCT_NOUN } from 'constants/english'
import { TEAM_NOUN, USERS_NOUN, USER_NOUN } from '../../../constants/english'
import { UsersIcon } from '../../icon/UsersIcon/UsersIcon'
import { lightBoxStyles } from '../../../styles/lightBoxStyles'
import { FileUploadIcon } from '../../icon/FileUploadIcon/FileUploadIcon'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { HeaderTwoTextConnected } from '../../text/HeaderTwoText/HeaderTwoTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'

export const WorkspaceSubscriptionMain = ({
  subscriptionUserId,
  isPro,
  teamId,
  maxUserCountFree,
  maxUserCountPro,
  gigabytesPerUserFree,
  gigabytesPerUserPro,
}: WorkspaceSubscriptionMainProps): JSX.Element => {
  return (
    <div
      className={`WorkspaceSubscriptionMain ${WorkspaceSubscriptionMainStyles}`}
    >
      <HeaderOneTextConnected>
        {isPro ? PRO_PRODUCT_NOUN : FREE_PRODUCT_NOUN}
      </HeaderOneTextConnected>

      <IconAndDiv className={lightBoxStyles} icon={<UsersIcon />}>
        Up to {isPro ? maxUserCountPro : maxUserCountFree} {USERS_NOUN}
      </IconAndDiv>

      <IconAndDiv
        className={`${lightBoxStyles} ${marginTopMediumStyles}`}
        icon={<FileUploadIcon />}
      >
        {isPro ? gigabytesPerUserPro : gigabytesPerUserFree} GB storage per{' '}
        {USER_NOUN}
      </IconAndDiv>

      {!isPro && (
        <>
          <CustomLinkConnected
            title={`Upgrade to ${PRO_PRODUCT_NOUN}`}
            className={`continueUsing ${actionButtonStyles}`}
            withCurrentQuery={true}
            route={UPGRADE_WORKSPACE_ROUTE}
          >
            Upgrade to {PRO_PRODUCT_NOUN}
          </CustomLinkConnected>
        </>
      )}

      {isPro && (
        <>
          <HeaderTwoTextConnected className={marginTopLargeStyles}>
            Setup By
          </HeaderTwoTextConnected>

          <div className={`setupByMain ${lightBoxStyles}`}>
            <AvatarConnected userId={subscriptionUserId} />
            <DisplayNameSpanConnected userId={subscriptionUserId} />
          </div>
        </>
      )}

      {isPro && teamId && (
        <>
          <HeaderTwoTextConnected className="cancelHeader">
            <IconAndDiv icon={<ExclamationCircleIcon />}>
              Cancel Subscription
            </IconAndDiv>
          </HeaderTwoTextConnected>

          <p className={`cancelParagraph ${lightBoxStyles}`}>
            The {TEAM_NOUN} will be downgraded to {FREE_PRODUCT_NOUN} and there
            may be restrictions.
          </p>

          <CancelSubscriptionButtonConnected
            className={marginTopMediumStyles}
            teamId={teamId}
            label="Cancel"
          />
        </>
      )}
    </div>
  )
}
