import React from 'react'
import { TaskSummarySpanProps } from './types/TaskSummarySpanProps'

export const TaskSummarySpan = ({
  summary,
  children,
}: TaskSummarySpanProps): JSX.Element => {
  return (
    <span className={'TaskSummarySpan'}>{summary || children || '(no longer available)'}</span>
  )
}
