// import { Dispatch, Action } from 'redux'

import {
  // UploadFilesListConnectedProps,
  UploadFilesListMapDispatchReturnType,
} from './types'

export const UploadFilesListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: UploadFilesListConnectedProps,
): UploadFilesListMapDispatchReturnType => {
  return {
  }
}
