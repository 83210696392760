import { css } from 'emotion'

export const HeaderOneTextStyles = css`
  font-size: 1.1em;
  padding-bottom: 16px;
  font-weight: bold;
  text-transform: capitalize;

  &.isMediumScreen {
    font-size: 1.5em;
    padding-bottom: 32px;
  }
`
