import { GET_DASHBOARD_REQUEST } from 'actions'
import { GetDashboardRequestAction } from './types/GetDashboardRequestAction'
import { GetDashboardRequestActionValues } from './types/GetDashboardRequestActionValues'

interface Props {
  requestValues: GetDashboardRequestActionValues,
}

export const createGetDashboardRequestAction = ({ requestValues }: Props): GetDashboardRequestAction => {
  return {
    type: GET_DASHBOARD_REQUEST,
    requestValues,
  }
}
