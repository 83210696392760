// import { TeamTasksSingleWatcherStyles } from './TeamTasksSingleWatcherStyles'
import { useEffect, useState } from 'react'
import { firestore } from 'sdks'
import { TeamTasksSingleWatcherProps } from './types/TeamTasksSingleWatcherProps'
import { TaskQueryDocumentSnapshot } from 'types/TaskQueryDocumentSnapshot'
import { TaskQuerySnapshot } from 'types/TaskQuerySnapshot'
import { getTasksRef } from '../../../firebaseFunctions/utilities/ref/get/getTasksRef'

export const TeamTasksSingleWatcher = ({
  incrementWatcher,
  teamId,
  onNext,
  onFirebaseError,
  taskPage = 0,
  clearTeamWatchedTasks,
  setShowGetTasksSpinner,
  setReachMaxTaskPage,
  pageSize,
}: TeamTasksSingleWatcherProps): null => {
  const [lastDocsState, setlastDocsState] = useState<
    TaskQueryDocumentSnapshot[]
  >([])

  const [
    startAfterState,
    setStartAfterState,
  ] = useState<TaskQueryDocumentSnapshot | null>(null)

  useEffect(() => {
    const startAfter = lastDocsState[taskPage - 1] || null

    setStartAfterState((currentstartAfter) => {
      if (startAfter?.id !== currentstartAfter?.id) {
        return startAfter
      }

      return currentstartAfter
    })
  }, [taskPage, lastDocsState, setStartAfterState])

  useEffect(() => {
    const onSnapshotHandler = (querySnapshot: TaskQuerySnapshot) => {
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
      setShowGetTasksSpinner(false)

      setlastDocsState((currentState) => {
        const cloneMutable = [...currentState]
        cloneMutable[taskPage] = lastDoc
        return cloneMutable
      })

      onNext(querySnapshot, teamId)
      // Commenting out because it doesnt work and not sure why we added it in the first place
      // if (!querySnapshot.metadata.fromCache) {
      const noMoreItems = querySnapshot.docs.length < pageSize

      setReachMaxTaskPage(noMoreItems, teamId)
      // }
    }

    setShowGetTasksSpinner(true)

    let query = getTasksRef({ firestore, teamId }).orderBy('created', 'desc')

    if (startAfterState) {
      query = query.startAfter(startAfterState)
    }

    const unsubscribe = query
      .limit(pageSize) // The pageSize will shift to maintain the count when tasks are created/deleted
      .onSnapshot(onSnapshotHandler, onFirebaseError)

    incrementWatcher(1)

    return () => {
      unsubscribe()
      incrementWatcher(-1)
      clearTeamWatchedTasks()
    }
  }, [
    teamId,
    incrementWatcher,
    onFirebaseError,
    onNext,
    startAfterState,
    pageSize,
    taskPage,
    clearTeamWatchedTasks,
    setReachMaxTaskPage,
    setShowGetTasksSpinner,
  ])
  return null
}
