import { css } from 'emotion'

export const FileUploadFieldStyles = css`
  .clickableContainer {
    position: relative;
    display: flex;
    overflow: hidden;

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      cursor: pointer;
    }
  }
`
