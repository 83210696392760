import { put } from 'redux-saga/effects'
import { firestore, clientServerTimestamp } from 'sdks'
import { map } from 'lodash'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPostTasksRequestAction } from 'actionCreators/post/PostTasks/createPostTasksRequestAction'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { createPostTasksSuccessAction } from 'actionCreators/post/PostTasks/createPostTasksSuccessAction'
import { createPostTasksFailureAction } from 'actionCreators/post/PostTasks/createPostTasksFailureAction'
import { getTaskRef } from 'firebaseFunctions/utilities/ref/get/getTaskRef'

interface Props {
  items: TasksReducer
}

export const postTasksSaga = function*({ items }: Props) {
  yield put(
    createPostTasksRequestAction({
      requestValues: {
        items,
      },
    })
  )

  try {
    const batch = firestore.batch()

    map(items, (taskReducer, taskId) => {
      const { data, teamId } = taskReducer

      const ref = getTaskRef({
        firestore,
        teamId,
        taskId,
      })

      batch.set(ref, {
        ...data,
        created: clientServerTimestamp(),
      })

      return taskId
    })

    yield batch.commit()

    yield put(
      createPostTasksSuccessAction({
        successValues: { items },
        requestValues: { items },
      })
    )
  } catch (error) {
    yield put(
      createFirebaseErrorAction({
        error: error as firebase.FirebaseError,
        description: 'postTasksSaga',
      })
    )

    yield put(
      createPostTasksFailureAction({
        requestValues: { items },
      })
    )
  }
}
