import { connect } from 'react-redux'
import { AdminMain } from './AdminMain'
import { AdminMainMapState } from './AdminMainMapState'
import { AdminMainMapDispatch } from './AdminMainMapDispatch'

import { AdminMainMapStateReturnType } from './types/AdminMainMapStateReturnType'
import { AdminMainMapDispatchReturnType } from './types/AdminMainMapDispatchReturnType'
import { AdminMainConnectedProps } from './types/AdminMainConnectedProps'


export const AdminMainConnected = connect<
  AdminMainMapStateReturnType,
  AdminMainMapDispatchReturnType,
  AdminMainConnectedProps>(AdminMainMapState,
    AdminMainMapDispatch)(AdminMain)
