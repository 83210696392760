import { UnscopeUser } from '../../../types'
import { FirestoreClient } from '../../../types'
import { getUnscopeUserRef } from '../get/getUnscopeUserRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  userId: string
  unscopeUser: UnscopeUser
}

export const setUnscopeUser = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  userId,
  unscopeUser,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getUnscopeUserRef({
    firestore,
    userId,
  }).set(unscopeUser, { merge: true }) as any // use set + merge because the doc may not exist
}
