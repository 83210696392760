import { getFeedbacksRef } from './getFeedbacksRef'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'
import { Feedback } from '../../../types/Feedback'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  feedbackId: string
}

export const getFeedbackRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  feedbackId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<Feedback>
  : FirebaseFirestore.DocumentReference => {
  return getFeedbacksRef({ firestore, teamId }).doc(feedbackId) as any
}
