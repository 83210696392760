import { NotificationListStyles } from './NotificationListStyles'
import * as React from 'react'
import { NotificationListProps } from './types/NotificationListProps'
import { NotificationCardConnected } from 'components/card/NotificationCard'
import { ReadAllButtonConnected } from 'components/button/ReadAllButton'
import { createEmptyArray } from 'utilities/create/createEmptyArray'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'

export const NotificationList = ({
  notificationGroup,
  length,
}: NotificationListProps): JSX.Element => {
  return (
    <ul className={'NotificationList ' + NotificationListStyles}>
      <li className="spaceBetween">
        <ReadAllButtonConnected notificationGroup={notificationGroup} />

        <div className={`${textBoldStyles} ${textSmallestStyles}`}>
          {notificationGroup === 'activities' ? 'Activity' : 'Message'}{' '}
          notifications
        </div>
      </li>

      {createEmptyArray(length).map((index) => {
        return (
          <li key={index}>
            <NotificationCardConnected
              notificationGroup={notificationGroup}
              notificationIndex={index}
            />
          </li>
        )
      })}

      {length === 0 && <li className="empty">No notifications yet</li>}
    </ul>
  )
}
