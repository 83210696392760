import { Dispatch, Action } from 'redux'
import { SUBMIT_TASK_SIZE } from 'actions'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

import { TaskSizeDropdownConnectedProps } from './types/TaskSizeDropdownConnectedProps'
import { TaskSizeDropdownMapDispatchReturnType } from './types/TaskSizeDropdownMapDispatchReturnType'

export const TaskSizeDropdownMapDispatch = (
  dispatch: Dispatch<Action>,
  props: TaskSizeDropdownConnectedProps
): TaskSizeDropdownMapDispatchReturnType => {
  return {
    onUnsetClick: () => {
      dispatch(
        createSubmitAction({
          values: {
            taskId: props.taskId,
            size: null,
          },
          type: SUBMIT_TASK_SIZE,
        })
      )

      dispatch(
        createToggleAction({
          description: 'TaskSizeDropdownMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },

    onSizeClick: (size: string) => {
      dispatch(
        createSubmitAction({
          values: {
            taskId: props.taskId,
            size,
          },
          type: SUBMIT_TASK_SIZE,
        })
      )

      dispatch(
        createToggleAction({
          description: 'TaskSizeDropdownMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },
  }
}
