import { StateReducers } from 'state/reducers/types/StateReducers'
import { SelectTagInputFieldConnectedProps } from './types/SelectTagInputFieldConnectedProps'
import { SelectTagInputFieldMapStateReturnType } from './types/SelectTagInputFieldMapStateReturnType'
import { getTagSelectOptions } from 'state/getters/getTagSelectOptions'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

export const SelectTagInputFieldMapState = (
  state: StateReducers,
  _props: SelectTagInputFieldConnectedProps
): SelectTagInputFieldMapStateReturnType => {
  const { values, labels, types } = getTagSelectOptions({ state })

  return {
    values: memoizeFlatArray(values, 'SelectTagsFieldMapState.values'),
    labels: memoizeFlatArray(labels, 'SelectTagsFieldMapState.labels'),
    types: memoizeFlatArray(types, 'SelectTagsFieldMapState.types'),
  }
}
