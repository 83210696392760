import {
  DraftEditorStyles,
  AndroidDeleteErrorStyles,
} from './DraftEditorStyles'
import { markdownToDraft } from './markdownToDraft'
import React, { RefObject } from 'react'
import { DraftEditorProps } from './types/DraftEditorProps'
import { PluginEditor } from './pluginEditor/PluginEditor'
import { draftToMarkdown } from 'markdown-draft-js'
import { createMentionPlugin } from './mention/createMentionPlugin'
import { defaultSuggestionsFilter } from './mention/defaultSuggestionsFilter'
import { MENTION_REGEX, MENTION_MARKER } from 'constants/regexConstants'
import { createLinkifyPlugin } from './createLinkifyPlugin'
import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'
import 'draft-js/dist/Draft.css'
import { debounce } from 'lodash'
import { InlineEditorToolBarConnected } from 'components/bar/InlineEditorToolBar'
import { ActionButton } from 'components/button/ActionButton'
import { getSuggestionsCenterPosition } from './getSuggestionsCenterPosition'
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  ContentState,
} from 'draft-js'
import { ErrorMessage } from 'components/message/ErrorMessage'

interface State {
  editorState: EditorState
  isFocused: boolean
  suggestions: any
  hasError: boolean
  mentionOpen: boolean
  contentChangedSinceSaved: boolean
}

const MENTION_OPEN = 'mention_open'

const remarkableMentionPlugin = (md: any, _options: any) => {
  md.inline.ruler.push('mention', (state: any, _silent: any) => {
    const matches = state.src.match(MENTION_REGEX)

    if (matches && matches.length === 3) {
      const isMarked = matches[2]?.indexOf(MENTION_MARKER) > -1

      state.push({
        type: MENTION_OPEN,
        id: matches[2] + (isMarked ? '' : MENTION_MARKER),
        name: matches[1], // TODO: use current name if possible
      })
    }
  })
}

// TODO: deprecated in favor of hooked
export class DraftEditor extends React.Component<DraftEditorProps, State> {
  editorRef: RefObject<PluginEditor>
  transitionEndBinded: any
  mentionPlugin: any
  linkifyPlugin: any

  constructor(props: DraftEditorProps) {
    super(props)

    const contentValue = this.getContentValue(props)
    const stateValue =
      contentValue && EditorState.createWithContent(contentValue)
    const useEditorState = stateValue || EditorState.createEmpty()

    this.state = {
      // Don't call this.setState() here!
      editorState: useEditorState,
      isFocused: false,
      hasError: false,
      mentionOpen: false,
      contentChangedSinceSaved: false,
      suggestions: this.createMentions(props),
    }

    this.editorRef = React.createRef<PluginEditor>()

    this.mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      positionSuggestions: getSuggestionsCenterPosition,
    })

    this.linkifyPlugin = createLinkifyPlugin()
  }

  reload = (props: DraftEditorProps) => {
    const contentValue = this.getContentValue(props)
    const stateValue =
      contentValue && EditorState.createWithContent(contentValue)
    const useEditorState = stateValue || EditorState.createEmpty()

    this.setState({
      editorState: useEditorState,
      isFocused: false,
      hasError: false,
      contentChangedSinceSaved: false,
      suggestions: this.createMentions(props),
    })
  }

  getContentValue = (props: DraftEditorProps) => {
    const draftValue =
      props.value &&
      markdownToDraft(props.value, {
        preserveNewlines: true,
        remarkablePlugins: [remarkableMentionPlugin],
        blockEntities: {
          [MENTION_OPEN]: function(item: any) {
            return {
              type: 'mention',
              mutability: 'IMMUTABLE',
              data: {
                mention: {
                  id: item.id,
                  name: item.name,
                },
              },
            }
          },
        },
      })

    return draftValue && convertFromRaw(draftValue)
  }

  createMentions = (props: DraftEditorProps) => {
    return props.mentionIds.map((mentionId, index) => {
      return {
        id: mentionId,
        name: props.mentionNames[index],
        avatar: props.mentionAvatars[index],
      }
    })
  }

  static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(_error: any, _errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  onChange = (editorState: EditorState) => {
    const currentContentState = this.state.editorState.getCurrentContent()
    const newContentState = editorState.getCurrentContent()
    const contentChanged = currentContentState !== newContentState

    if (contentChanged) {
      // Have to put this in here because onChange is triggered when component mounts, not sure why
      this.props.onChange?.()

      if (!this.state.contentChangedSinceSaved) {
        this.setState({
          contentChangedSinceSaved: true,
        })
      }

      if (this.props.autoDebounceSave) {
        this.submitDebounce()
      }

      if (!this.state.isFocused) {
        this.setState({
          isFocused: true,
        })

        setTimeout(() => {
          // settime out, hack because focus will call onChange again before state changes
          this.editorRef.current?.focus()
        })
      }
    } else {
      // The change was triggered by a change in focus/selection
    }

    this.setState({
      editorState,
    })
  }

  onSearchChange = ({ value }: any) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(
        value,
        this.createMentions(this.props)
      ),
    })
  }

  onBlur = () => {
    this.setState({
      isFocused: false,
    })

    this.props.onBlur?.()

    if (this.props.saveOnBlur) {
      this.submit()
    }
  }

  onAddMention = () => {
    // get the mention object selected
  }

  submitDebounce = debounce(() => {
    this.submit()
  }, 30000)

  // Special hacky submit for android due to soft keyboard issues with draftJS
  androidSubmit = () => {
    setTimeout(() => {
      const editor = this.editorRef.current

      if (editor) {
        editor.blur() // will cause draft js to recognize change

        if (!this.props.saveOnBlur) {
          this.submit()
        }
      }
    }, 100) // 100 is a guestimate of how long it will take for draft js to see change
  }

  submit = () => {
    this.submitDebounce.cancel()

    if (this.props.save) {
      this.setState({
        contentChangedSinceSaved: false,
      })

      const content = this.state.editorState.getCurrentContent()
      const raw = convertToRaw(content)

      const markdown = draftToMarkdown(raw, {
        preserveNewlines: true,
        entityItems: {
          mention: {
            open: function(_entity: any) {
              return '['
            },

            close: function(entity: any) {
              return '](@' + entity.data.mention.id + ')'
            },
          },
        },
      })

      this.props.save(markdown)

      if (this.props.clearOnSave) {
        const newEditorState = EditorState.moveFocusToEnd(
          EditorState.push(
            this.state.editorState,
            ContentState.createFromText(''),
            'remove-range'
          )
        )

        this.setState({
          editorState: newEditorState,
        })
      }
    }
  }

  onFocus = () => {
    this.setState({
      isFocused: true,
    })

    this.props.onFocus?.()
  }

  handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === 'save') {
      // Tell Draft that we've taken care of this command
      this.submit()
      return 'handled'
    }

    // Tell Draft we haven't handled it.
    return 'not-handled'
  }

  keyBindingFn = (e: any): any => {
    if (e.key === 'Enter' && !e.shiftKey && this.props.saveOnEnter) {
      return 'save'
    }

    return undefined // make sure to undefined to propregate
  }

  componentDidMount = () => {
    if (this.props.autoFocus) {
      setTimeout(() => {
        // setTimeout hack to fix https://github.com/draft-js-plugins/draft-js-plugins/issues/800
        this.editorRef.current?.focus()
      })
    }
  }

  render() {
    const {
      placeholder,
      className = '',
      headerToolBar,
      footerToolBar,
      isAndroid,
    } = this.props
    const additionalClassname = className ? ` ${className}` : ''
    const focusClassname = this.state.isFocused ? ' isFocused' : ''
    const usePlugins = [this.mentionPlugin, this.linkifyPlugin]
    const { MentionSuggestions } = this.mentionPlugin

    if (this.state.hasError) {
      return (
        <div className={AndroidDeleteErrorStyles}>
          <ErrorMessage>
            Please use the provided delete button in the pop up toolbar.{' '}
            <span role="img" aria-label="Thank you">
              🙏
            </span>
          </ErrorMessage>

          <ActionButton
            title="Reload"
            onClick={() => {
              this.reload(this.props)
            }}
          >
            Reload
          </ActionButton>
        </div>
      )
    }

    return (
      <div
        className={
          'DraftEditor ' +
          DraftEditorStyles +
          additionalClassname +
          focusClassname
        }
      >
        {headerToolBar &&
          headerToolBar({
            editorState: this.state.editorState,
            setEditorState: this.onChange,
            contentChangedSinceSaved: this.state.contentChangedSinceSaved,
            submit: isAndroid ? this.androidSubmit : this.submit,
          })}
        <PluginEditor
          customStyleMap={{
            CODE: {
              backgroundColor: 'rgba(29, 28, 29, 0.04)',
              color: '#e01e5a',
              borderRadius: '3px',
              padding: '2px 4px',
              border: `1px solid ${NEUTRAL_BORDER_COLOR}`,
            },
          }}
          ref={this.editorRef}
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={usePlugins}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          placeholder={placeholder}
          autoCapitalize={isAndroid ? 'off' : 'on'}
          autoComplete={isAndroid ? 'off' : 'on'}
          // autoCorrect="off" will cause a bug in android when user backspace
          // all content and start typing again, the keyboard would go away
          autoCorrect="off"
          spellCheck={isAndroid ? false : true}
          stripPastedStyles={isAndroid ? true : false}
          keyBindingFn={this.keyBindingFn}
          handleKeyCommand={this.handleKeyCommand}
        />

        {footerToolBar &&
          footerToolBar({
            editorState: this.state.editorState,
            setEditorState: this.onChange,
            contentChangedSinceSaved: this.state.contentChangedSinceSaved,
            submit: isAndroid ? this.androidSubmit : this.submit,
          })}

        <InlineEditorToolBarConnected
          editorState={this.state.editorState}
          setEditorState={this.onChange}
          alwaysShowInlineToolbar={this.props.alwaysShowInlineToolbar}
        />

        <MentionSuggestions
          open={this.state.mentionOpen}
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          onAddMention={this.onAddMention}
          onOpenChange={(newOpen: boolean) => {
            this.setState({
              mentionOpen: newOpen,
            })
          }}
        />
      </div>
    )
  }
}
