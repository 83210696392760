import { connect } from 'react-redux'
import { AppUpdatedMain } from './AppUpdatedMain'
import { AppUpdatedMainMapState } from './AppUpdatedMainMapState'
import { AppUpdatedMainMapDispatch } from './AppUpdatedMainMapDispatch'
import { AppUpdatedMainMapStateReturnType } from './types/AppUpdatedMainMapStateReturnType'
import { AppUpdatedMainMapDispatchReturnType } from './types/AppUpdatedMainMapDispatchReturnType'
import { AppUpdatedMainConnectedProps } from './types/AppUpdatedMainConnectedProps'

export const AppUpdatedMainConnected = connect<
  AppUpdatedMainMapStateReturnType,
  AppUpdatedMainMapDispatchReturnType,
  AppUpdatedMainConnectedProps>(AppUpdatedMainMapState,
    AppUpdatedMainMapDispatch)(AppUpdatedMain)
