import { Dispatch, Action } from 'redux'
import { TaskTagButtonFieldMapDispatchReturnType } from './types/TaskTagButtonFieldMapDispatchReturnType'
import { createSubmitTaskTagAction } from '../../../actionCreators/submit/SubmitTaskTag'
import { createSubmitAction } from '../../../actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_DELETE_TASK_TAG } from '../../../actions'

export const TaskTagButtonFieldMapDispatch = (
  dispatch: Dispatch<Action>
): TaskTagButtonFieldMapDispatchReturnType => {
  return {
    submit: (values) => {
      dispatch(
        createSubmitTaskTagAction({
          values,
          description: 'TaskTagButtonFieldMapDispatch',
        })
      )
    },

    removeTag: ({ tagId, taskId }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_TASK_TAG,
          values: {
            tagId,
            taskId,
          },
        })
      )
    },
  }
}
