import { CollapsibleDivStyles } from './CollapsibleDivStyles'
import React, { ReactNode, RefObject } from 'react'
import { CollapsibleDivProps } from './types/CollapsibleDivProps'

interface State {
  transition: null | 'opening' | 'opened' | 'closing' | 'closed'
}

const TRANSITION_TIME_SECONDS = 0.3

export class CollapsibleDiv extends React.Component<
  CollapsibleDivProps,
  State
> {
  ref: RefObject<any>
  transitionEndTimeout: any

  constructor(props: CollapsibleDivProps) {
    super(props)

    // Don't call this.setState() here!
    this.state = {
      transition: props.show ? 'opened' : 'closed',
    }

    this.ref = React.createRef()
  }

  onTransitionEnd = () => {
    if (this.state.transition === 'opening') {
      this.setState({
        transition: 'opened',
      })
    }

    if (this.state.transition === 'closing') {
      this.setState({
        transition: 'closed',
      })
    }
  }

  collapse() {
    this.setState({
      transition: 'closing',
    })

    this.setCollapsedHeight()
  }

  expand() {
    this.setState({
      transition: 'opening',
    })

    this.setExpandedHeight()
  }

  setCollapsedHeight() {
    if (this.ref.current) {
      this.ref.current.style.height = '0px'

      this.transitionEndTimeout = window.setTimeout(
        this.onTransitionEnd,
        TRANSITION_TIME_SECONDS * 1000 + 10
      )
    }
  }

  isHeightDifferent = (): boolean => {
    const element = this.ref.current

    if (element) {
      const newHeight = element.firstElementChild.offsetHeight + 'px'

      return element.style.height !== newHeight
    }

    return false
  }

  setExpandedHeight() {
    const element = this.ref.current

    if (element) {
      element.style.height = element.firstElementChild.offsetHeight + 'px'

      this.transitionEndTimeout = window.setTimeout(
        this.onTransitionEnd,
        TRANSITION_TIME_SECONDS * 1000 + 10
      )
    }
  }

  componentDidUpdate(previousProps: CollapsibleDivProps) {
    const toNotShow = previousProps.show && !this.props.show

    if (this.props.show) {
      // Always want to recalculate height when show is true
      if (this.state.transition !== 'opening' && this.isHeightDifferent()) {
        this.expand()
      }
    }

    if (toNotShow && this.state.transition !== 'closing') {
      this.collapse()
    }
  }

  componentDidMount() {
    const element = this.ref.current

    if (element) {
      if (!this.props.show) {
        this.setCollapsedHeight()
      } else {
        this.setExpandedHeight()
      }
    }
  }

  componentWillUnmount() {
    if (this.transitionEndTimeout) {
      window.clearTimeout(this.transitionEndTimeout)
    }
  }

  render(): ReactNode {
    const closing = this.state.transition === 'closing'
    const showChildren =
      this.props.show || closing || this.props.preserveChildren
    const extraClassName = this.props.className
      ? ` ${this.props.className}`
      : ''
    const overflow = this.state.transition === 'opened' ? 'visible' : 'hidden'
    const containerOverflow =
      this.state.transition === 'opened' ? 'visible' : 'hidden'
    const transition = `height ${TRANSITION_TIME_SECONDS}s ease-in-out`

    return (
      <div
        className={'CollapsibleDiv ' + CollapsibleDivStyles + extraClassName}
        style={{
          overflow,
          transition,
        }}
        ref={this.ref}
      >
        <div className="container" style={{ overflow: containerOverflow }}>
          {showChildren && this.props.children}
        </div>
      </div>
    )
  }
}
