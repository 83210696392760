import { TaskActionListStyles } from './TaskActionListStyles'
import React from 'react'
import { TaskActionListProps } from './types/TaskActionListProps'
import { ActionButton } from 'components/button/ActionButton'
import { DeleteTaskButtonConnected } from 'components/button/DeleteTaskButton'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TASK_MESSAGES_ROUTE } from 'routes'
import { TaskFileFieldConnected } from 'components/field/TaskFileField'
import { CommentSolidIcon } from 'components/icon/CommentSolidIcon/CommentSolidIcon'
import { FileUploadIcon } from 'components/icon/FileUploadIcon/FileUploadIcon'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'
import { CloneTaskButtonConnected } from '../../button/CloneTaskButton/CloneTaskButtonConnected'

export const TaskActionList = ({
  taskId,
  showDeleteButton,
  showMessageButton,
  showCloneButton,
  onUploadStart,
  canWrite,
}: TaskActionListProps): JSX.Element => {
  return (
    <ul
      className={`TaskActionList ${TaskActionListStyles} ${listActionButtonsStyles}`}
    >
      {showMessageButton && (
        <li>
          <CustomLinkConnected
            title="messages"
            route={TASK_MESSAGES_ROUTE}
            withCurrentQuery={true}
            className={actionButtonStyles}
            taskId={taskId}
          >
            <IconAndDiv icon={<CommentSolidIcon />}>Messages</IconAndDiv>
          </CustomLinkConnected>
        </li>
      )}

      {showCloneButton && canWrite && (
        <li>
          <CloneTaskButtonConnected taskId={taskId} />
        </li>
      )}

      {showDeleteButton && canWrite && (
        <li>
          <DeleteTaskButtonConnected taskId={taskId} />
        </li>
      )}

      {canWrite && (
        <li>
          <TaskFileFieldConnected taskId={taskId} onUploadStart={onUploadStart}>
            <ActionButton title="Upload">
              <IconAndDiv icon={<FileUploadIcon />}>Upload</IconAndDiv>
            </ActionButton>
          </TaskFileFieldConnected>
        </li>
      )}
    </ul>
  )
}
