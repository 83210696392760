import { AccountSettingsMainStyles } from './AccountSettingsMainStyles'
import React from 'react'
// import { AccountSettingsMainProps } from './types/AccountSettingsMainProps'
import { DisplayNameSpanConnected } from 'components/span/DisplayNameSpan'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { AvatarConnected } from 'components/widget/Avatar'
import {
  UPDATE_DISPLAY_NAME_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  NOTIFICATION_SETTINGS_ROUTE,
  BILLING_ROUTE,
  UPDATE_AVATAR_ROUTE,
} from 'routes'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'

export const AccountSettingsMain = (): JSX.Element => {
  return (
    <div className={'AccountSettingsMain ' + AccountSettingsMainStyles}>
      <HeaderOneTextConnected>Account settings</HeaderOneTextConnected>

      <ul>
        <li>
          <CustomLinkConnected
            title="Change name"
            route={UPDATE_DISPLAY_NAME_ROUTE}
            withCurrentQuery={true}
          >
            <span>Your name</span>

            <DisplayNameSpanConnected />
          </CustomLinkConnected>
        </li>

        <li>
          <CustomLinkConnected
            title="Change Avatar"
            route={UPDATE_AVATAR_ROUTE}
            withCurrentQuery={true}
          >
            <span>Your avatar</span>

            <AvatarConnected />
          </CustomLinkConnected>
        </li>

        <li>
          <CustomLinkConnected
            title="Change password"
            route={UPDATE_PASSWORD_ROUTE}
            withCurrentQuery={true}
          >
            <span>Password </span>

            <span>*******</span>
          </CustomLinkConnected>
        </li>

        <li>
          <CustomLinkConnected
            title="Notifications settings "
            route={NOTIFICATION_SETTINGS_ROUTE}
            withCurrentQuery={true}
          >
            Notifications
          </CustomLinkConnected>
        </li>

        <li>
          <CustomLinkConnected
            title="Billing"
            route={BILLING_ROUTE}
            withCurrentQuery={true}
          >
            Billing
          </CustomLinkConnected>
        </li>
      </ul>
    </div>
  )
}
