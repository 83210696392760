import { GET_INVITES_REQUEST } from 'actions'
import { GetInvitesRequestAction } from './types/GetInvitesRequestAction'
import { GetInvitesRequestActionValues } from './types/GetInvitesRequestActionValues'

interface Props {
  requestValues: GetInvitesRequestActionValues
}

export const createGetInvitesRequestAction = ({ requestValues }: Props): GetInvitesRequestAction => {
  return {
    type: GET_INVITES_REQUEST,
    requestValues,
  }
}
