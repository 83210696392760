import { Dispatch, Action } from 'redux'
// import { createToggleAction } from 'actionCreators/createToggleAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'
import { BaseEmoji } from 'emoji-mart'
import { EditorState, Modifier } from 'draft-js'

import { EditorEmojiButtonConnectedProps } from './types/EditorEmojiButtonConnectedProps'
import { EditorEmojiButtonMapDispatchReturnType } from './types/EditorEmojiButtonMapDispatchReturnType'

export const EditorEmojiButtonMapDispatch = (
  _dispatch: Dispatch<Action>,
  props: EditorEmojiButtonConnectedProps
): EditorEmojiButtonMapDispatchReturnType => {
  return {
    onEmojiSelect: (emoji: BaseEmoji) => {
      const { editorState } = props
      const contentState = editorState.getCurrentContent()
      const currentSelectionState = editorState.getSelection()

      const afterRemovalContentState = Modifier.removeRange(
        contentState,
        currentSelectionState,
        'backward'
      )

      const targetSelection = afterRemovalContentState.getSelectionAfter()

      const emojiAddedContent = Modifier.insertText(
        afterRemovalContentState,
        targetSelection,
        emoji.native,
        undefined
      )

      const newEditorState = EditorState.push(
        editorState,
        emojiAddedContent,
        'insert-characters'
      )

      props.setEditorState(newEditorState)
    },
  }
}
