import { css } from 'emotion'

import {
  PLACEHOLDER_COLOR,
  PRIMARY_COLOR,
  NEUTRAL_BORDER_COLOR,
  DANGER_COLOR,
} from 'constants/styleConstants'

export const TextAreaInputStyles = css`
  border: 0;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  background: #fff;
  width: 100%;
  font-size: 16px;
  line-height: 1.6;
  padding: 8px 16px;
  border-radius: 4px;
  display: block;
  resize: none;

  &::placeholder {
    color: ${PLACEHOLDER_COLOR};
  }

  &.clean {
    border-color: rgba(0, 0, 0, 0);
  }

  &.showBorder {
    border-color: ${NEUTRAL_BORDER_COLOR};
  }

  &.hasError {
    border-color: ${DANGER_COLOR};
  }

  &:hover {
    border-color: ${NEUTRAL_BORDER_COLOR};
  }

  &:focus {
    border-color: ${PRIMARY_COLOR};
  }
`
