import { WorkingMessageCardStyles } from './WorkingMessageCardStyles'
import React, { useState, useEffect } from 'react'
import { WorkingMessageCardProps } from './types/WorkingMessageCardProps'
import { WORKING_STALE_SECONDS } from 'constants/environmentConstants'
import { UserWorkingWidget } from 'components/widget/UserWorkingWidget'

export const WorkingMessageCard = ({
  userId,
  seconds,
}: WorkingMessageCardProps): JSX.Element | null => {
  const [showWorkingState, setShowWorkingState] = useState(true)
  const [secondsState, setSecondsState] = useState(seconds)

  if (secondsState !== seconds) {
    // Need to compare seconds to show latest
    setShowWorkingState(true)
    setSecondsState(seconds)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowWorkingState(false)
    }, WORKING_STALE_SECONDS * 1000)

    return () => {
      window.clearTimeout(timeout)
    }
  })

  if (showWorkingState) {
    return (
      <div className={'WorkingMessageCard ' + WorkingMessageCardStyles}>
        <UserWorkingWidget userId={userId} />
      </div>
    )
  }

  return null
}
