import { StateReducers }  from 'state/reducers/types/StateReducers'
import { UrlQuery } from 'types/UrlQuery'

interface Props {
  state: StateReducers
}

export const getCurrentQuery = ({ state }: Props): UrlQuery | undefined => {
  const currentLocation = state.currentLocation || undefined

  return currentLocation && currentLocation.query
}
