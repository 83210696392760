import { InviteCardStyles } from './InviteCardStyles'
import * as React from 'react'
import { InviteCardProps } from './types/InviteCardProps'
import { roleDescriptions } from 'constants/roleDescriptions'
import moment from 'moment'
import { DotDotDotWidget } from 'components/widget/DotDotDotWidget'
import { ClockIcon } from 'components/icon/ClockIcon/ClockIcon'
import { lightBoxCardStyles } from 'styles/lightBoxCardStyles'

export const InviteCard = ({
  email,
  userRole,
  createdSeconds,
  className = '',
}: InviteCardProps): JSX.Element => {
  return (
    <div
      className={`InviteCard ${InviteCardStyles} ${lightBoxCardStyles} ${className}`}
    >
      <div className="leftSide">
        <div className="clockContainer">
          <ClockIcon />

          <div className="email">{email}</div>
        </div>

        <div className="createdSeconds">
          {createdSeconds && (
            <span>
              Invitation sent {moment.unix(createdSeconds).format('ll')}
            </span>
          )}

          {!createdSeconds && <DotDotDotWidget />}
        </div>
      </div>

      <div className="roleType">{userRole && roleDescriptions[userRole]}</div>
    </div>
  )
}
