import { POST_TASKS_FAILURE } from 'actions'

import { PostTasksFailureAction } from './types/PostTasksFailureAction'
import { PostTasksRequestActionValues } from './types/PostTasksRequestActionValues'


interface Props {
  requestValues?: PostTasksRequestActionValues
}

export const createPostTasksFailureAction = ({
  requestValues,
}: Props): PostTasksFailureAction => {
  return {
    type: POST_TASKS_FAILURE,
    requestValues,
  }
}
