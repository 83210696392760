import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_READ } from 'actions'

import { NotificationCardConnectedProps } from './types/NotificationCardConnectedProps'
import { NotificationCardMapDispatchReturnType } from './types/NotificationCardMapDispatchReturnType'

// TODO: get rid of props for performance
export const NotificationCardMapDispatch = (
  dispatch: Dispatch<Action>,
  props: NotificationCardConnectedProps
): NotificationCardMapDispatchReturnType => {
  return {
    clear: () => {
      const { notificationIndex, notificationGroup } = props

      dispatch(
        createSubmitAction({
          type: SUBMIT_READ,
          values: {
            notificationGroup,
            index: notificationIndex,
          },
        })
      )
    },
  }
}
