import { ModalButtonStyles } from './ModalButtonStyles'
import React, { useCallback } from 'react'
import { ModalButtonProps } from './types/ModalButtonProps'
import { Button } from 'components/button/Button'

export const ModalButton = ({
  className = '',
  children,
  click,
  showModalType,
  onClick,
  ...props
}: ModalButtonProps): JSX.Element => {
  const onClickCallback = useCallback(
    (e) => {
      click(showModalType)
      onClick?.(e)
    },
    [showModalType, click, onClick]
  )

  return (
    <Button
      className={`ModalButton ${ModalButtonStyles} ${className}`}
      onClick={onClickCallback}
      {...props}
    >
      {children}
    </Button>
  )
}
