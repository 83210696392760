import { take, put, select, spawn } from 'redux-saga/effects'
import { Task } from 'firebaseFunctions/types'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_NEW_TASK, SUBMIT_NEW_TASK_DONE } from 'actions'
import { newTaskSaga } from 'flows/sagas/newTaskSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getBackLogTaskIds } from 'state/getters/getBackLogTaskIds'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getTaskCount } from 'state/getters/getTaskCount'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { getCurrentPlanId } from 'state/getters/getCurrentPlanId'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitNewTaskF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_NEW_TASK])
    const { summary } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId || undefined
    const backLogTaskIds = getBackLogTaskIds({ state })
    const topBackLogTaskId = backLogTaskIds[0]
    const topBackLogTask = getCurrentTask({ state, taskId: topBackLogTaskId })
    const topBackLogTaskOrder = topBackLogTask && topBackLogTask.order
    const nextOrder = (topBackLogTaskOrder || getTaskCount({ state })) + 1000 // TODO: get rid of order
    const query = getCurrentQuery({ state })
    const teamId = query && query.teamId
    const team = getCurrentTeam({ state })
    const taskStatuses = team && team.taskStatuses
    const planId = getCurrentPlanId({ state })

    if (teamId && currentUserId && summary) {
      const taskMutable: Task = {
        summary,
        status: taskStatuses ? taskStatuses[0] : undefined,
        order: nextOrder,
        tags: {
          [currentUserId]: {
            type: 'user',
          },
        },
        lastModifiedUserId: currentUserId,
      }

      if (planId) {
        taskMutable.planId = planId
      }

      yield spawn(newTaskSaga, {
        task: taskMutable,
        teamId,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewTaskF',
        })
      )
    }

    yield put({
      type: SUBMIT_NEW_TASK_DONE,
    })
  }
}
