import { Dispatch, Action } from 'redux'
import { READING_MESSAGES, READING_MESSAGES_DONE } from 'actions'

import {
  // MessageListConnectedProps,
  MessageListMapDispatchReturnType,
} from './types'

export const MessageListMapDispatch = (
  dispatch: Dispatch<Action>,
  // props: MessageListConnectedProps,
): MessageListMapDispatchReturnType => {
  return {
    startReadingMessages: () => {
      dispatch({
        type: READING_MESSAGES,
      })
    },

    stopReadingMessages: () => {
      dispatch({
        type: READING_MESSAGES_DONE,
      })
    }
  }
}
