import { PATCH_USER_INVITES_REQUEST } from 'actions'
import { PatchUserInvitesRequestAction } from './types/PatchUserInvitesRequestAction'
import { PatchUserInvitesRequestActionValues } from './types/PatchUserInvitesRequestActionValues'

interface Props {
  requestValues: PatchUserInvitesRequestActionValues,
}

export const createPatchUserInvitesRequestAction = ({ requestValues }: Props): PatchUserInvitesRequestAction => {
  return {
    type: PATCH_USER_INVITES_REQUEST,
    requestValues,
  }
}
