import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_NEW_MESSAGE_FILES } from 'actions'
// import { NewMessageFileFieldConnectedProps } from './types/NewMessageFileFieldConnectedProps'
import { NewMessageFileFieldMapDispatchReturnType } from './types/NewMessageFileFieldMapDispatchReturnType'

export const NewMessageFileFieldMapDispatch = (
  dispatch: Dispatch<Action>
): NewMessageFileFieldMapDispatchReturnType => {
  return {
    uploadFile: (files: File[]) => {
      dispatch(
        createSubmitAction({
          values: { files },
          type: SUBMIT_NEW_MESSAGE_FILES,
        })
      )
    },
  }
}
