import { Notification } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { NotificationGroup } from 'types/NotificationGroup'
import { getActivityNotifications } from './getActivityNotifications'
import { getMessageNotifications } from './getMessageNotifications'

interface Props {
  state: StateReducers
  notificationGroup: NotificationGroup
}

export const getNotifications = ({ state, notificationGroup }: Props): Notification[] => {
  const isMessages = notificationGroup === 'messages'

  if (isMessages) {
    return getMessageNotifications({ state })
  }

  return getActivityNotifications({ state })
}
