import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  SUBMIT_USER,
  SUBMIT_USER_INVITED,
  SUBMIT_LOGIN,
  SUBMIT_NEW_TEAM,
  SUBMIT_RESET_PASSWORD,
  SUBMIT_USER_DISPLAY_NAME,
  CREATE_USER_EXTENSION,
  SUBMIT_UPDATE_PASSWORD,
  GOOGLE_SIGN_IN_PROXY_SUCCESS,
} from 'actions'

export const showFormSpinnerF = function*() {
  while (1) {
    yield take([
      SUBMIT_USER,
      SUBMIT_USER_INVITED,
      SUBMIT_LOGIN,
      SUBMIT_NEW_TEAM,
      SUBMIT_RESET_PASSWORD,
      SUBMIT_USER_DISPLAY_NAME,
      CREATE_USER_EXTENSION,
      SUBMIT_UPDATE_PASSWORD,
      GOOGLE_SIGN_IN_PROXY_SUCCESS,
    ])

    const toggleAction = createToggleAction({
      description: 'showFormSpinnerF',
      toggles: {
        showFormSpinner: true,
      },
    })

    yield put(toggleAction)
  }
}
