import { Dispatch, Action } from 'redux'
import { QuickSearchFieldMapDispatchReturnType } from './types/QuickSearchFieldMapDispatchReturnType'
import { TASK_DETAIL_ROUTE } from 'routes'
import { createHistoryActionWithCurrentQuery } from 'actionCreators/createHistoryActionWithCurrentQuery'
import { createErrorAction } from '../../../actionCreators/createErrorAction'

export const QuickSearchFieldMapDispatch = (
  dispatch: Dispatch<Action>
): QuickSearchFieldMapDispatchReturnType => {
  return {
    onTaskSelect: (taskId) => {
      dispatch(
        createHistoryActionWithCurrentQuery({
          pathname: TASK_DETAIL_ROUTE,
          description: 'QuickSearchFieldMapDispatch',
          query: {
            taskId,
          },
        })
      )
    },

    handleError: (error) => {
      dispatch(
        createErrorAction({
          error,
          description: 'QuickSearchFieldMapDispatch',
        })
      )
    },
  }
}
