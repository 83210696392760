import { css } from 'emotion'

export const FormFooterStyles = css`
  text-align: center;

  .ActionButton {
    padding: 22px;
    width: 100%;
  }

  .messageSpinner {
    position: relative;
    height: 44px;
    margin-top: -30px;
    font-size: 11px;

    .FormSpinner {
      top: 10px;
    }

    > * {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .ErrorMessage {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .buttons {
    display: flex;
    align-items: stretch;

    button,
    .ButtonLink {
      padding: 18px;
      text-transform: uppercase;
      font-weight: 600;
      width: 100%;
    }

    > *:first-child {
      border-right-width: 0;
    }
  }

  &.noSpinnerWithErrorAndSuccess {
    button {
      margin-top: 30px;
    }

    .buttons {
      button {
        margin-top: 0px;
      }
    }
  }

  &.danger {
    .ActionButton {
      background: #fb9387;

      &:hover {
        background: #de6e61;

        &:disabled,
        &[disabled] {
          background: #d7d7d7;
        }
      }

      &:disabled,
      &[disabled] {
        background: #d7d7d7;
      }
    }
  }

  .spinnerWithErrorAndSuccess {
    text-align: center;
    min-height: 44px;
    position: relative;
    font-size: 11px;

    .FormSpinner {
      margin-top: 10px;
    }

    .AlertMessage {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
