import { connect } from 'react-redux'
import { DeleteTaskButton } from './DeleteTaskButton'
import { DeleteTaskButtonMapState } from './DeleteTaskButtonMapState'
import { DeleteTaskButtonMapDispatch } from './DeleteTaskButtonMapDispatch'
import { DeleteTaskButtonMapStateReturnType } from './types/DeleteTaskButtonMapStateReturnType'
import { DeleteTaskButtonMapDispatchReturnType } from './types/DeleteTaskButtonMapDispatchReturnType'
import { DeleteTaskButtonConnectedProps } from './types/DeleteTaskButtonConnectedProps'

export const DeleteTaskButtonConnected = connect<
  DeleteTaskButtonMapStateReturnType,
  DeleteTaskButtonMapDispatchReturnType,
  DeleteTaskButtonConnectedProps
>(
  DeleteTaskButtonMapState,
  DeleteTaskButtonMapDispatch
)(DeleteTaskButton)
