import { StateReducers } from 'state/reducers/types/StateReducers'

import { EmojiDropdownConnectedProps } from './types/EmojiDropdownConnectedProps'
import { EmojiDropdownMapStateReturnType } from './types/EmojiDropdownMapStateReturnType'


export const EmojiDropdownMapState = (
  state: StateReducers,
  props: EmojiDropdownConnectedProps
): EmojiDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const isTouch = state.windowMeta?.isTouch

  const showEmojiDropdown =
    showDropdown &&
    showDropdown.type === 'emoji' &&
    showDropdown.id === props.dropdownId

  return {
    showEmojiDropdown,
    isTouch,
  }
}
