import { DELETE_MESSAGES_SUCCESS } from 'actions'
import { DeleteMessagesSuccessAction } from './types/DeleteMessagesSuccessAction'
import { DeleteMessagesSuccessActionValues } from './types/DeleteMessagesSuccessActionValues'
import { DeleteMessagesRequestActionValues } from './types/DeleteMessagesRequestActionValues'

interface Props {
  successValues: DeleteMessagesSuccessActionValues
  requestValues?: DeleteMessagesRequestActionValues
  description: string
}

export const createDeleteMessagesSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): DeleteMessagesSuccessAction => {
  return {
    type: DELETE_MESSAGES_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
