import { StateReducers } from '../reducers/types/StateReducers'
import { Label } from 'firebaseFunctions/types'
import { getLabels } from './getLabels'

interface Props {
  state: StateReducers
  labelId?: string
}

export const getLabel = ({ state, labelId }: Props): Label | undefined => {
  const labels = getLabels({ state })
  const label = labels && labelId ? labels[labelId] : undefined

  return label
}
