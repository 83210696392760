import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_DELETE_CUSTOM_CLAIM, REFRESH_TOKEN } from 'actions'

import { TestingMainMapDispatchReturnType } from './types'
import { createSetSessionStorageAction } from '../../../actionCreators/sessionStorage'

export const TestingMainMapDispatch = (
  dispatch: Dispatch<Action>
): TestingMainMapDispatchReturnType => {
  return {
    deleteCustomClaim: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_CUSTOM_CLAIM,
          values: {},
        })
      )
    },

    setSession: (testing) => {
      dispatch(
        createSetSessionStorageAction({
          description: 'TestingMainMapDispatch',
          sessionStorage: {
            testing,
          },
        })
      )
    },

    refreshToken: () => {
      dispatch({
        type: REFRESH_TOKEN,
      })
    },
  }
}
