import { StateReducers } from '../reducers/types/StateReducers'
import { getSortedPlanIds } from './getSortedPlanIds'

interface Props {
  state: StateReducers
}

export const getActivePlanId = ({ state }: Props): string | undefined => {
  const planIds = getSortedPlanIds({ state, status: 'open' })

  return planIds[0]
}
