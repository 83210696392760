import { connect } from 'react-redux'
import { TaskDescriptionEditor } from './TaskDescriptionEditor'
import { TaskDescriptionEditorMapState } from './TaskDescriptionEditorMapState'
import { TaskDescriptionEditorMapDispatch } from './TaskDescriptionEditorMapDispatch'

import { TaskDescriptionEditorMapStateReturnType } from './types/TaskDescriptionEditorMapStateReturnType'
import { TaskDescriptionEditorMapDispatchReturnType } from './types/TaskDescriptionEditorMapDispatchReturnType'
import { TaskDescriptionEditorConnectedProps } from './types/TaskDescriptionEditorConnectedProps'


export const TaskDescriptionEditorConnected = connect<
  TaskDescriptionEditorMapStateReturnType,
  TaskDescriptionEditorMapDispatchReturnType,
  TaskDescriptionEditorConnectedProps>(TaskDescriptionEditorMapState,
    TaskDescriptionEditorMapDispatch)(TaskDescriptionEditor)
