import { css } from 'emotion'

export const MessagesMainStyles = css`
  display: flex;
  flex-direction: column;

  .MessageList {
    flex: 1;
  }

  > footer {
    /* box-shadow: 0 4px 7px 0px rgba(0,0,0,0.4); */

    .unreadCount {
      border-radius: 0px;
      width: 100%;

      .fas {
        margin-left: 8px;
      }
    }
  }

  .NewMessageEditor {
    background-color: #fff;
  }

  .MessageCard {
    margin: 0 16px;
  }
`
