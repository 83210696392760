import { StatusColorWidgetConnectedProps } from './types/StatusColorWidgetConnectedProps'
import { StatusColorWidgetMapStateReturnType } from './types/StatusColorWidgetMapStateReturnType'

import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getStatusColor } from 'state/getters/getStatusColor'

export const StatusColorWidgetMapState = (
  state: StateReducers,
  props: StatusColorWidgetConnectedProps
): StatusColorWidgetMapStateReturnType => {
  const task = getCurrentTask({ state, taskId: props.taskId })
  const status = task?.status
  const statusColor = status ? getStatusColor({ state, status }) : undefined

  return {
    statusColor,
  }
}
