import { css } from 'emotion'
import { TEXT_COLOR_LIGHT } from 'constants/styleConstants'

export const TagDropdownStyles = css`
  position: relative;

  > button {
    width: 30px;
    height: 30px;
    background-color: #dddddd;
    color: ${TEXT_COLOR_LIGHT};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .Dropdown {
    .chevronContainer {
      right: 10px;
    }

    .container {
      right: 0px;
      width: 320px;
    }
  }
`
