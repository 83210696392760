import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

import { StatusColorDropdownConnectedProps } from './types/StatusColorDropdownConnectedProps'
import { StatusColorDropdownMapStateReturnType } from './types/StatusColorDropdownMapStateReturnType'


export const StatusColorDropdownMapState = (
  state: StateReducers,
  props: StatusColorDropdownConnectedProps,
): StatusColorDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const team = getCurrentTeam({ state })
  const taskStatusColors = team && team.taskStatusColors
  const color = taskStatusColors && taskStatusColors[props.status]

  const showStatusColorDropdown = showDropdown &&
    showDropdown.type === 'statusColor' &&
    showDropdown.id === props.status

  return {
    showStatusColorDropdown,
    color,
  }
}
