import { Dispatch, Action } from 'redux'
import { CloneTaskButtonMapDispatchReturnType } from './types/CloneTaskButtonMapDispatchReturnType'
import { SUBMIT_CLONE_TASK } from '../../../actions'
import { createSubmitAction } from '../../../actionCreators/submit/Submit/createSubmitAction'

export const CloneTaskButtonMapDispatch = (
  dispatch: Dispatch<Action>
): CloneTaskButtonMapDispatchReturnType => {
  return {
    clone: (taskId) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_CLONE_TASK,
          values: {
            taskId,
          },
        })
      )
    },
  }
}
