import * as React from 'react'
import { ActionButtonConnected } from './ActionButtonConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'

export const ActionButtonExamples = () => {
  return (
    <div className="ActionButtonExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo">Foo</ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">big</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo" sizeVariance="big">
          Foo
        </ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">danger</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo" colorVariance="danger">
          Foo
        </ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">success</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo" colorVariance="success">
          Foo
        </ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">warning</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo" colorVariance="warning">
          Foo
        </ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">hallow</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo" colorVariance="hollow">
          Foo
        </ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">disabled</h1>

      <Provider store={createMockStore(mockState)}>
        <ActionButtonConnected title="foo" disabled={true}>
          Foo
        </ActionButtonConnected>
      </Provider>

      <h1 className="exampleHeader">with icon</h1>

      <div style={{ display: 'inline-block ' }}>
        <Provider store={createMockStore(mockState)}>
          <ActionButtonConnected title="foo">
            <TimesSolidIcon /> <span>Foo</span>
          </ActionButtonConnected>
        </Provider>
      </div>
    </div>
  )
}
