import { UploadedFilesMainStyles } from './UploadedFilesMainStyles'
import React from 'react'
import { UploadedFilesMainProps } from './types/UploadedFilesMainProps'
import { UploadedFileListConnected } from 'components/list/UploadedFileList'
import { UploadedFileWatcherConnected } from 'components/watcher/UploadedFileWatcher'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { USERS_NOUN, USER_NOUN } from '../../../constants/english'
import { lightBoxStyles } from '../../../styles/lightBoxStyles'
import { textSmallStyles } from '../../../styles/text/textSmallStyles'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'
import { textColorLightStyles } from '../../../styles/text/textColorLightStyles'

const getUsage = (fileUploadedBytes?: number): string | null => {
  if (fileUploadedBytes !== undefined) {
    const gigabytes = fileUploadedBytes / 1000000000
    const roundedGigabytes = Math.ceil(gigabytes * 100) / 100

    return `${roundedGigabytes} GB`
  }

  return null
}

export const UploadedFilesMain = ({
  teamId,
  uploadCapacityGigabytesPerUser = 0,
  userCount = 1,
  fileUploadedBytes,
}: UploadedFilesMainProps): JSX.Element => {
  const usage = getUsage(fileUploadedBytes)
  const capacityGigaBytes = uploadCapacityGigabytesPerUser * userCount

  return (
    <div className={'UploadedFilesMain ' + UploadedFilesMainStyles}>
      <HeaderOneTextConnected>Uploaded Files</HeaderOneTextConnected>

      <div className={lightBoxStyles}>
        {usage && (
          <p>
            <label className={`${textSmallStyles} ${textBoldStyles}`}>
              usage:
            </label>{' '}
            {usage}
          </p>
        )}

        {capacityGigaBytes !== undefined && (
          <p>
            <label className={`${textSmallStyles} ${textBoldStyles}`}>
              capacity:
            </label>{' '}
            {Math.floor(capacityGigaBytes * 100) / 100} GB{' '}
            <span className={`${textColorLightStyles}`}>
              ({Math.floor(uploadCapacityGigabytesPerUser * 100) / 100} GB x{' '}
              {userCount} {userCount > 1 ? USERS_NOUN : USER_NOUN})
            </span>
          </p>
        )}
      </div>
      {teamId && <UploadedFileListConnected teamId={teamId} />}
      {teamId && <UploadedFileWatcherConnected teamId={teamId} />}
    </div>
  )
}
