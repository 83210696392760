import * as React from 'react'
import { UserInvitesWatcherProps } from './types/UserInvitesWatcherProps'
import { firestore } from 'sdks'
import { getUserInvitesRef } from 'firebaseFunctions/utilities/ref/get/getUserInvitesRef'

export class UserInvitesWatcher extends React.Component {
  props: UserInvitesWatcherProps
  unsubscribe?: () => void

  componentWillUnmount() {
    this.unsubscribeAll()
  }

  unsubscribeAll() {
    if (this.unsubscribe) {
      this.unsubscribe()
      this.unsubscribe = undefined
      this.props.incrementWatcher(-1)
    }
  }

  public render(): null {
    this.unsubscribeAll()

    const { email, onNext, onFirebaseError, incrementWatcher } = this.props

    if (!this.unsubscribe) {
      this.unsubscribe = getUserInvitesRef({
        firestore,
        email,
      }).onSnapshot(onNext, onFirebaseError)

      incrementWatcher(1)
    }

    return null
  }
}
