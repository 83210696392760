import { connect } from 'react-redux'
import { MemberList } from './MemberList'
import { MemberListMapState } from './MemberListMapState'
import { MemberListMapDispatch } from './MemberListMapDispatch'

import { MemberListMapStateReturnType } from './types/MemberListMapStateReturnType'
import { MemberListMapDispatchReturnType } from './types/MemberListMapDispatchReturnType'
import { MemberListConnectedProps } from './types/MemberListConnectedProps'


export const MemberListConnected = connect<
  MemberListMapStateReturnType,
  MemberListMapDispatchReturnType,
  MemberListConnectedProps>(MemberListMapState,
    MemberListMapDispatch)(MemberList)
