export const SUBMIT_DELETE_MESSAGE = 'SUBMIT_DELETE_MESSAGE'
export const SUBMIT_DELETE_MESSAGE_DONE = 'SUBMIT_DELETE_MESSAGE_DONE'
export const SUBMIT_DELETE_INVITE = 'SUBMIT_DELETE_INVITE'
export const SUBMIT_DELETE_INVITE_DONE = 'SUBMIT_DELETE_INVITE_DONE'
export const SUBMIT_DELETE_TASK = 'SUBMIT_DELETE_TASK'
export const SUBMIT_DELETE_TASK_DONE = 'SUBMIT_DELETE_TASK_DONE'
export const SUBMIT_DELETE_TASK_TAG = 'SUBMIT_DELETE_TASK_TAG'
export const SUBMIT_DELETE_TASK_TAG_DONE = 'SUBMIT_DELETE_TASK_TAG_DONE'
export const SUBMIT_DELETE_USER_ROLE = 'SUBMIT_DELETE_USER_ROLE'
export const SUBMIT_DELETE_USER_ROLE_DONE = 'SUBMIT_DELETE_USER_ROLE_DONE'
export const SUBMIT_DELETE_TASK_FILE = 'SUBMIT_DELETE_TASK_FILE'
export const SUBMIT_DELETE_TASK_FILE_FAILURE = 'SUBMIT_DELETE_TASK_FILE_FAILURE'
export const SUBMIT_DELETE_TASK_FILE_DONE = 'SUBMIT_DELETE_TASK_FILE_DONE'
export const SUBMIT_DELETE_TASK_FILE_NAME = 'SUBMIT_DELETE_TASK_FILE_NAME'
export const SUBMIT_DELETE_TASK_FILE_NAME_FAILURE =
  'SUBMIT_DELETE_TASK_FILE_NAME_FAILURE'
export const SUBMIT_DELETE_TASK_FILE_NAME_DONE =
  'SUBMIT_DELETE_TASK_FILE_NAME_DONE'
export const SUBMIT_DELETE_LABEL = 'SUBMIT_DELETE_LABEL'
export const SUBMIT_DELETE_LABEL_DONE = 'SUBMIT_DELETE_LABEL_DONE'
export const SUBMIT_UPDATE_LABEL = 'SUBMIT_UPDATE_LABEL'
export const SUBMIT_UPDATE_LABEL_DONE = 'SUBMIT_UPDATE_LABEL_DONE'
export const SUBMIT_DELETE_NEW_MESSAGE_FILE = 'SUBMIT_DELETE_NEW_MESSAGE_FILE'
export const SUBMIT_DELETE_NEW_MESSAGE_FILE_DONE =
  'SUBMIT_DELETE_NEW_MESSAGE_FILE_DONE'
export const SUBMIT_DELETE_FILE = 'SUBMIT_DELETE_FILE'
export const SUBMIT_DELETE_FILE_DONE = 'SUBMIT_DELETE_FILE_DONE'
export const SUBMIT_DELETE_UPLOADED_FILE = 'SUBMIT_DELETE_UPLOADED_FILE'
export const SUBMIT_DELETE_UPLOADED_FILE_DONE =
  'SUBMIT_DELETE_UPLOADED_FILE_DONE'
export const SUBMIT_DELETE_WORKSPACE = 'SUBMIT_DELETE_WORKSPACE'
export const SUBMIT_DELETE_WORKSPACE_DONE = 'SUBMIT_DELETE_WORKSPACE_DONE'
