import { connect } from 'react-redux'
import { UpdatePasswordForm } from './UpdatePasswordForm'
import { UpdatePasswordFormMapState } from './UpdatePasswordFormMapState'
import { UpdatePasswordFormMapDispatch } from './UpdatePasswordFormMapDispatch'
import { UpdatePasswordFormMapStateReturnType } from './types/UpdatePasswordFormMapStateReturnType'
import { UpdatePasswordFormMapDispatchReturnType } from './types/UpdatePasswordFormMapDispatchReturnType'
import { UpdatePasswordFormConnectedProps } from './types/UpdatePasswordFormConnectedProps'

export const UpdatePasswordFormConnected = connect<
  UpdatePasswordFormMapStateReturnType,
  UpdatePasswordFormMapDispatchReturnType,
  UpdatePasswordFormConnectedProps
>(
  UpdatePasswordFormMapState,
  UpdatePasswordFormMapDispatch
)(UpdatePasswordForm)
