import { AppMapStateReturnType } from './types/AppMapStateReturnType'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsRoutesFromState } from 'state/getters/getIsRoutesFromState'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

export const AppMapState = (
  state: StateReducers
  // props: AppConnectProps,
): AppMapStateReturnType => {
  const isRoutes = getIsRoutesFromState({ state })
  const showOneLayout =
    !!isRoutes?.isOneLayoutRoute || !!isRoutes?.isNotFoundRoute
  const showDashboardLayout = !!isRoutes?.isDashboardLayoutRoute
  const showMessageLayout = !!isRoutes?.isTaskMessagesRoute
  const showMinimalLayout =
    !!isRoutes?.isSelectTeamRoute || !!isRoutes?.isConfirmEmailRoute
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    isEndToEndTesting: false,
    showOneLayout,
    showDashboardLayout,
    showMessageLayout,
    showMinimalLayout,
    isMediumScreen,
  }
}
