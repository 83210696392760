import { DraftToolBarStyles } from './DraftToolBarStyles'
import * as React from 'react'
import { DraftToolBarProps } from './types/DraftToolBarProps'
import { InlineStyleButton } from 'components/button/InlineStyleButton'
import { Button } from 'components/button/Button'
import { EditorState, Modifier } from 'draft-js'
import { BackspaceIcon } from 'components/icon/BackspaceIcon/BackspaceIcon'
import { HeadingIcon } from 'components/icon/HeadingIcon/HeadingIcon'
import { BoldIcon } from 'components/icon/BoldIcon/BoldIcon'
import { ItalicIcon } from 'components/icon/ItalicIcon/ItalicIcon'
import { StrikeThroughIcon } from 'components/icon/StrikeThroughIcon/StrikeThroughIcon'
import { ExclamationCircleIcon } from 'components/icon/ExclamationCircleIcon/ExclamationCircleIcon'
import { ListSolidIcon } from 'components/icon/ListSolidIcon/ListSolidIcon'
import { ListOlSolidIcon } from 'components/icon/ListOlSolidIcon/ListOlSolidIcon'
import { QuoteLeftIcon } from 'components/icon/QuoteLeftIcon/QuoteLeftIcon'
import { CodeSolidIcon } from 'components/icon/CodeSolidIcon/CodeSolidIcon'

// TODO: rename to reformating toolbar
export const DraftToolBar = ({
  editorState,
  setEditorState,
  extraOnClick,
  isMediumScreen,
  isTouch,
}: DraftToolBarProps): JSX.Element => {
  const isMediumScreenClass = isMediumScreen ? ' isMediumScreen' : ''
  const isHoverableClass = isTouch ? '' : ' isHoverable'

  return (
    <div
      className={
        'DraftToolBar ' +
        DraftToolBarStyles +
        isMediumScreenClass +
        isHoverableClass
      }
      onMouseDown={(e) => {
        // To keep focus on editor
        e.preventDefault()
      }}
      onClick={(e) => {
        // To keep focus on editor
        e.preventDefault()

        extraOnClick?.()
      }}
    >
      <Button
        title="Backspace"
        onClick={() => {
          const selection = editorState?.getSelection()
          const content = editorState?.getCurrentContent()
          // debugger
          if (
            selection?.getHasFocus() &&
            editorState &&
            content &&
            setEditorState
          ) {
            const startKey = selection.getStartKey()
            const startOffset = selection.getStartOffset()
            const endKey = selection.getEndKey()
            const endOffset = selection.getEndOffset()

            if (startKey !== endKey || startOffset !== endOffset) {
              const newEditorState = EditorState.push(
                editorState,
                Modifier.removeRange(content, selection, 'forward'),
                'remove-range'
              )
              setEditorState(newEditorState)
            }
          }
        }}
      >
        <BackspaceIcon />
      </Button>
      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="header-one"
        styleType="block"
        title="Heading"
      >
        <HeadingIcon />
      </InlineStyleButton>
      {/* 
      <InlineStyleButton editorState={editorState} setEditorState={setEditorState} styleKey="header-two" styleType="block">
        H2
      </InlineStyleButton> */}

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="BOLD"
        styleType="inline"
      >
        <BoldIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="ITALIC"
        styleType="inline"
      >
        <ItalicIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="STRIKETHROUGH"
        styleType="inline"
      >
        <StrikeThroughIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="CODE"
        styleType="inline"
      >
        <ExclamationCircleIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="unordered-list-item"
        styleType="block"
      >
        <ListSolidIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="ordered-list-item"
        styleType="block"
      >
        <ListOlSolidIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="blockquote"
        styleType="block"
      >
        <QuoteLeftIcon />
      </InlineStyleButton>

      <InlineStyleButton
        editorState={editorState}
        setEditorState={setEditorState}
        styleKey="code-block"
        styleType="block"
      >
        <CodeSolidIcon />
      </InlineStyleButton>
    </div>
  )
}
