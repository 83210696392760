import { TaskToolListStyles } from './TaskToolListStyles'
import React from 'react'
import { TaskToolListProps } from './types/TaskToolListProps'
import { StatusDropdownConnected } from 'components/dropdown/StatusDropdown'
import { TaskSizeDropdownConnected } from 'components/dropdown/TaskSizeDropdown'
import { DueDateDropdownConnected } from 'components/dropdown/DueDateDropdown'
import { StatusColorWidgetConnected } from 'components/widget/StatusColorWidget'
import { TaskPlanDropdownConnected } from 'components/dropdown/TaskPlanDropdown'
// import { Button } from 'components/button/Button'

export const TaskToolList = ({ taskId }: TaskToolListProps): JSX.Element => {
  return (
    <div className={'TaskToolList ' + TaskToolListStyles}>
      <StatusColorWidgetConnected taskId={taskId} />
      <StatusDropdownConnected taskId={taskId} />
      <div className="separator" />
      <TaskSizeDropdownConnected taskId={taskId} />
      <div className="separator" />
      <DueDateDropdownConnected isCompact={true} label="date" taskId={taskId} />
      <div className="separator" />
      <TaskPlanDropdownConnected taskId={taskId} />
    </div>
  )
}
