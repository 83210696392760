import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCanPossiblyOwn } from 'state/getters/getCanPossiblyOwn'
import { getCanLeave } from 'state/getters/getCanLeave'

import { TeamActionListConnectedProps } from './types/TeamActionListConnectedProps'
import { TeamActionListMapStateReturnType } from './types/TeamActionListMapStateReturnType'


export const TeamActionListMapState = (
  state: StateReducers,
  props: TeamActionListConnectedProps
): TeamActionListMapStateReturnType => {
  const { teamId } = props
  const canPossiblyOwn = getCanPossiblyOwn({ state, teamId })
  const canLeave = getCanLeave({ state, teamId })

  return {
    showSettingsLink: canPossiblyOwn,
    canLeave,
  }
}
