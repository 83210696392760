import { POST_RE_LOGIN_SUCCESS } from 'actions'

import { PostReLoginSuccessAction } from './types/PostReLoginSuccessAction'
import { PostReLoginSuccessActionValues } from './types/PostReLoginSuccessActionValues'
import { PostReLoginRequestActionValues } from './types/PostReLoginRequestActionValues'


interface Props {
  successValues?: PostReLoginSuccessActionValues
  requestValues?: PostReLoginRequestActionValues
}

export const createPostReLoginSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostReLoginSuccessAction => {
  return {
    type: POST_RE_LOGIN_SUCCESS,
    requestValues,
    successValues,
  }
}
