import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // DashboardNavConnectedProps,
  DashboardNavMapStateReturnType,
} from './types'

export const DashboardNavMapState = (
  state: StateReducers
  // props: DashboardNavConnectedProps,
): DashboardNavMapStateReturnType => {
  const sessionStorage = state.sessionStorage || undefined
  const dashboardView =
    (sessionStorage && sessionStorage.dashboardView) || 'todo'
  const isMediumScreen = getIsMediumScreen({ state })
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {
    dashboardView,
    isMediumScreen,
  }
}
