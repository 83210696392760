import { SelectInput } from 'components/input/SelectInput'
import { TagSelectOption } from 'types/TagSelectOption'
import { SelectTagFieldProps } from './types/SelectTagFieldProps'
import { SelectTagFieldStyles } from './SelectTagFieldStyles'
import React from 'react'
import { SelectTagOption } from 'components/option/SelectTagOption'

export const SelectTagField = ({
  handleChange,
  types,
  values,
  labels,
}: SelectTagFieldProps): JSX.Element => {
  const options: TagSelectOption[] = values.map((value, i) => {
    return {
      value,
      type: types[i],
      label: labels[i],
    }
  })

  return (
    <SelectInput
      options={options}
      autoFocus={true}
      isSearchable={true}
      className={'SelectTagField ' + SelectTagFieldStyles}
      placeholder="Select a tag"
      formatOptionLabel={(option: TagSelectOption) => {
        const { type, value } = option

        return <SelectTagOption id={value} type={type} />
      }}
      onChange={handleChange}
    />
  )
}
