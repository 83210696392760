import { css } from 'emotion'

import {
  TEXT_COLOR_LIGHT,
  TEXT_COLOR,
  LINK_COLOR,
  FONT_WEIGHT_BOLDER,
} from 'constants/styleConstants'

export const NotificationCardStyles = css`
  display: block;
  padding: 8px;
  font-size: 0.85em;
  position: relative;
  color: ${TEXT_COLOR_LIGHT};

  .displayNameContainer {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .userDisplayName {
    font-weight: ${FONT_WEIGHT_BOLDER};
    color: ${TEXT_COLOR};
  }

  strong,
  .messageSummary {
    color: ${TEXT_COLOR};
  }

  .CountBubbleWidget {
    position: absolute;
    top: -4px;
    left: 20px;
    z-index: 1;
  }

  &:visited {
    color: ${TEXT_COLOR_LIGHT};
  }

  &.unread {
    background-color: #edf2fa;
  }

  .created {
    color: ${TEXT_COLOR_LIGHT};
    font-size: 0.7em;
    text-align: center;
    margin-top: 4px;
  }

  .createdAndAvatar {
    /* padding: 8px 0px 0px 8px; */
    position: absolute;
  }

  > main {
    padding-left: 40px;

    .summary {
      margin-top: 4px;
      color: ${LINK_COLOR};
    }
  }
`
