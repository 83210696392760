import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { TASK_STATUSES_ROUTE } from 'routes'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import {
  SUBMIT_UPDATE_TASK_STATUSES,
  SUBMIT_UPDATE_TASK_STATUSES_DONE,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitUpdateTaskStatusesF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_TASK_STATUSES])
    const { taskStatusesCsv } = action.values
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state })
    const teamId = query && query.teamId

    const taskStatuses = taskStatusesCsv?.split(',').map((status) => {
      return status.trim()
    })

    if (teamId && taskStatusesCsv) {
      const patchTeamAction = createPatchTeamsRequestAction({
        requestValues: {
          items: {
            [teamId]: {
              data: {
                taskStatuses,
              },
            },
          },
        },
      })

      yield put(patchTeamAction)

      yield put(
        createHistoryAction({
          description: 'submitUpdateTaskStatusesF',
          pathname: TASK_STATUSES_ROUTE,
          query,
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateTaskStatusesF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_TASK_STATUSES_DONE,
    })
  }
}
