import { connect } from 'react-redux'
import { UpdateMessageEditor } from './UpdateMessageEditor'
import { UpdateMessageEditorMapState } from './UpdateMessageEditorMapState'
import { UpdateMessageEditorMapDispatch } from './UpdateMessageEditorMapDispatch'

import { UpdateMessageEditorMapStateReturnType } from './types/UpdateMessageEditorMapStateReturnType'
import { UpdateMessageEditorMapDispatchReturnType } from './types/UpdateMessageEditorMapDispatchReturnType'
import { UpdateMessageEditorConnectedProps } from './types/UpdateMessageEditorConnectedProps'


export const UpdateMessageEditorConnected = connect<
  UpdateMessageEditorMapStateReturnType,
  UpdateMessageEditorMapDispatchReturnType,
  UpdateMessageEditorConnectedProps>(UpdateMessageEditorMapState,
    UpdateMessageEditorMapDispatch)(UpdateMessageEditor)
