import * as React from 'react'
import { RecaptchaConnected } from './RecaptchaConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const RecaptchaExamples = () => {
  return (
    <div className="RecaptchaExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <RecaptchaConnected />
      </Provider>
    </div>
  )
}
