import { connect } from 'react-redux'
import { NewInviteForm } from './NewInviteForm'
import { NewInviteFormMapState } from './NewInviteFormMapState'
import { NewInviteFormMapDispatch } from './NewInviteFormMapDispatch'
import { NewInviteFormMapStateReturnType } from './types/NewInviteFormMapStateReturnType'
import { NewInviteFormMapDispatchReturnType } from './types/NewInviteFormMapDispatchReturnType'
import { NewInviteFormConnectedProps } from './types/NewInviteFormConnectedProps'

export const NewInviteFormConnected = connect<
  NewInviteFormMapStateReturnType,
  NewInviteFormMapDispatchReturnType,
  NewInviteFormConnectedProps
>(
  NewInviteFormMapState,
  NewInviteFormMapDispatch
)(NewInviteForm)
