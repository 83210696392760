import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_TASK_FILES } from 'actions'

import { TaskFileFieldConnectedProps } from './types/TaskFileFieldConnectedProps'
import { TaskFileFieldMapDispatchReturnType } from './types/TaskFileFieldMapDispatchReturnType'


export const TaskFileFieldMapDispatch = (
  dispatch: Dispatch<Action>,
  props: TaskFileFieldConnectedProps
): TaskFileFieldMapDispatchReturnType => {
  return {
    uploadFile: (files: File[]) => {
      props.onUploadStart?.()

      dispatch(
        createSubmitAction({
          values: { files, taskId: props.taskId },
          type: SUBMIT_TASK_FILES,
        })
      )
    },
  }
}
