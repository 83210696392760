import { GoogleSignInSuccessAction } from './types/GoogleSignInSuccessAction'
import { GOOGLE_SIGN_IN_SUCCESS } from 'actions'
import { auth } from 'firebase'

interface Props {
  userCredential: auth.UserCredential
}

export const createGoogleSignInSuccessAction = ({
  userCredential,
}: Props): GoogleSignInSuccessAction => {
  return {
    type: GOOGLE_SIGN_IN_SUCCESS,
    values: userCredential,
  }
}
