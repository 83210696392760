import { PATCH_USERS_REQUEST } from 'actions'
import { PatchUsersRequestAction } from './types/PatchUsersRequestAction'
import { PatchUsersRequestActionValues } from './types/PatchUsersRequestActionValues'

interface Props {
  requestValues: PatchUsersRequestActionValues,
}

export const createPatchUsersRequestAction = ({ requestValues }: Props): PatchUsersRequestAction => {
  return {
    type: PATCH_USERS_REQUEST,
    requestValues,
  }
}
