import { arrayInsert } from './arrayInsert'

interface Props <T>{
  items: T[]
  subsetItems: T[]
  itemToReoder: T
  toSubsetIndex: number
}

export const reorderArraySubsetItem = <T>({
  items,
  itemToReoder,
  subsetItems,
  toSubsetIndex
}: Props<T>): T[] => {  
  // pick item out of subset if it exists
  const filteredSubsetItems = subsetItems.filter((subsetItem) => {
    return subsetItem !== itemToReoder
  })

  const newSubset = arrayInsert({
    items: filteredSubsetItems,
    itemToInsert: itemToReoder,
    index: toSubsetIndex,
  })

  // pick itemToReoder out of items if it exists
  const filteredItems = items.filter((item) => {
    return item !== itemToReoder
  })

  const subsetStartIndex = filteredItems.indexOf(filteredSubsetItems[0])

  const reorderedItems = arrayInsert({
    items: filteredItems,
    itemToInsert: newSubset,
    index: subsetStartIndex,
    length: filteredSubsetItems.length,
  })

  return reorderedItems
}
