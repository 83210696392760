// import { CaretSquareLeftIconStyles } from './CaretSquareLeftIconStyles'
import React from 'react'
import { CaretSquareLeftIconProps } from './types/CaretSquareLeftIconProps'
import { iconStyles } from 'styles/iconStyles'

export const CaretSquareLeftIcon = ({
  className = '',
}: CaretSquareLeftIconProps): JSX.Element => {
  return (
    <svg
      className={`CaretSquareLeftIcon ${className} ${iconStyles}`}
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M272 157.1v197.8c0 10.7-13 16.1-20.5 8.5l-98.3-98.9c-4.7-4.7-4.7-12.2 0-16.9l98.3-98.9c7.5-7.7 20.5-2.3 20.5 8.4zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
      ></path>
    </svg>
  )
}
