import { take, put, select } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { CLOSE_DROPDOWN } from 'actions'
import { selectState } from 'state/reducers/selectState'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { CloseDropdownAction } from 'actionCreators/types/CloseDropdownAction'

export const closeDropdownF = function*() {
  while (1) {
    const action: CloseDropdownAction = yield take(CLOSE_DROPDOWN)
    const closeDropdown = action.values
    const state: StateReducers = yield select(selectState)
    const currentShowDropdown = state.toggles?.showDropdown

    if (currentShowDropdown) {
      const isTheSame =
        closeDropdown.type === currentShowDropdown.type &&
        closeDropdown.id === currentShowDropdown.id

      if (isTheSame) {
        yield put(
          createToggleAction({
            description: 'closeDropdownF',
            toggles: {
              showDropdown: false,
            },
          })
        )
      }
    }
  }
}
