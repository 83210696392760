import { UpdateTaskStatusesFormStyles } from './UpdateTaskStatusesFormStyles'
import React, { useState, useCallback, useEffect } from 'react'
import { UpdateTaskStatusesFormProps } from './types/UpdateTaskStatusesFormProps'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { FormFooter } from 'components/footer/FormFooter'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const UpdateTaskStatusesForm = ({
  submit,
  taskStatusesCsv,
}: UpdateTaskStatusesFormProps): JSX.Element => {
  const [statusesState, setStatusesState] = useState(taskStatusesCsv)
  const [errorState, setErrorState] = useState<string | null>(null)

  const validateStatuses = useCallback(() => {
    const error = statusesState ? null : 'Please enter some statuses.'

    setErrorState(error || null)

    return error
  }, [statusesState])

  useEffect(() => {
    setStatusesState(taskStatusesCsv)
  }, [taskStatusesCsv])

  return (
    <form
      className={`UpdateTaskStatusesForm ${UpdateTaskStatusesFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const error = validateStatuses()

        if (!error && statusesState) {
          submit({ taskStatusesCsv: statusesState })
        }
      }}
    >
      <HeaderOneTextConnected>Configure Task Statuses</HeaderOneTextConnected>

      <p>
        Separate each status with a comma. Example: Open, In Progress, Closed
      </p>

      <TextInputField
        type="textarea"
        placeholder="Open, In Progress, Closed"
        value={statusesState}
        error={errorState}
        onChange={(e) => {
          setStatusesState(e.target.value)
        }}
        className={`${errorState ? 'hasError' : ''} ${marginTopLargeStyles}`}
        onFocus={() => {
          setErrorState(null)
        }}
      />

      <FormFooter label="Save" />
    </form>
  )
}
