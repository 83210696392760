import { DashboardLayoutStyles } from './DashboardLayoutStyles'
import React, { useEffect, Suspense } from 'react'
import { PageSpinnerWidgetConnected } from 'components/widget/PageSpinnerWidget'
import { DashboardLayoutProps } from './types/DashboardLayoutProps'
import { DashboardNavConnected } from 'components/nav/DashboardNav'
import { BigHeaderConnected } from 'components/header/BigHeader'
import { TaskFilterFormConnected } from 'components/form/TaskFilterForm/TaskFilterFormConnected'
import { ContentLoaderWidget } from '../../widget/ContentLoaderWidget/ContentLoaderWidget'

const PlanMainConnected = React.lazy(() => {
  return import('../../main/PlanMain/PlanMainConnected').then(
    ({ PlanMainConnected }) => ({
      default: PlanMainConnected,
    })
  )
})

const ToDoMainConnected = React.lazy(() => {
  return import('../../main/ToDoMain/ToDoMainConnected').then(
    ({ ToDoMainConnected }) => ({
      default: ToDoMainConnected,
    })
  )
})

const CurrentMainConnected = React.lazy(() => {
  return import('../../main/CurrentMain/CurrentMainConnected').then(
    ({ CurrentMainConnected }) => ({
      default: CurrentMainConnected,
    })
  )
})

export const DashboardLayout = ({
  isPlanRoute,
  isCurrentRoute,
  isDashboardHomeRoute,
  setScrollPosition,
  savedScrollPosition,
  isMediumScreen,
  isBigScreen,
}: DashboardLayoutProps): JSX.Element => {
  useEffect(() => {
    // component mount
    if (savedScrollPosition !== undefined) {
      window.document.documentElement.scrollTop = savedScrollPosition
    }

    return () => {
      // component will unmount
      if (isCurrentRoute) {
        setScrollPosition({
          currentPosition: window.document.documentElement.scrollTop,
        })
      }

      if (isPlanRoute) {
        setScrollPosition({
          planPosition: window.document.documentElement.scrollTop,
        })
      }

      if (isDashboardHomeRoute) {
        setScrollPosition({
          toDoPosition: window.document.documentElement.scrollTop,
        })
      }
    }
  }, [
    savedScrollPosition,
    setScrollPosition,
    isCurrentRoute,
    isPlanRoute,
    isDashboardHomeRoute,
  ])
  const isMediumScreenClassname = isMediumScreen ? ' isMediumScreen' : ''
  const isBigScreenClassName = isBigScreen ? ' isBigScreen' : ''

  return (
    <div
      className={
        'DashboardLayout ' +
        DashboardLayoutStyles +
        isMediumScreenClassname +
        isBigScreenClassName
      }
    >
      <PageSpinnerWidgetConnected />
      <BigHeaderConnected />
      <DashboardNavConnected />
      <TaskFilterFormConnected />

      <div className="content">
        {isPlanRoute && (
          <Suspense fallback={<ContentLoaderWidget />}>
            <PlanMainConnected />
          </Suspense>
        )}
        {isDashboardHomeRoute && (
          <Suspense fallback={<ContentLoaderWidget />}>
            <ToDoMainConnected />
          </Suspense>
        )}
        {isCurrentRoute && (
          <Suspense fallback={<ContentLoaderWidget />}>
            <CurrentMainConnected />
          </Suspense>
        )}
      </div>
    </div>
  )
}
