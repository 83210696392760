import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { ERROR } from 'actions'
import { statusCodes } from 'firebaseFunctions/statusCodes'
import { ErrorAction } from 'actionCreators/types/ErrorAction'

export const errorF = function*() {
  while (1) {
    const action: ErrorAction = yield take(ERROR)
    const { error } = action
    const reponseMessage = error.response?.data?.message
    const responseStatus = error.response?.status
    const message = reponseMessage || error.message || 'Javascript Error'

    const fallbackStatus =
      error.message === 'Network Error'
        ? statusCodes.networkError
        : statusCodes.javascriptError

    const status = responseStatus || fallbackStatus

    const hasMessage = status === statusCodes.unprocessable && message

    if (!hasMessage) {
      yield put(
        createToggleAction({
          description: 'apiErrorF',
          toggles: {
            showModal: {
              type: 'Error',
            },
          },
        })
      )
    }
  }
}
