import { FirestoreClient } from '../../../types'
import { getPlanRef } from '../get/getPlanRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  planId: string
}

export const deletePlan = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  planId,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getPlanRef({
    firestore,
    teamId,
    planId,
  }).delete() as any
}
