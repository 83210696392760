import { StateReducers } from '../reducers/types/StateReducers'
import { Labels } from 'firebaseFunctions/types'
import { getCurrentTeam } from './getCurrentTeam'

interface Props {
  state: StateReducers
}

export const getLabels = ({ state }: Props): Labels | undefined => {
  const currentTeam = getCurrentTeam({ state })
  const labels = currentTeam?.labels

  return labels
}
