import * as React from 'react'
import { UploadFilesCardConnected } from './UploadFilesCardConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const UploadFilesCardExamples = () => {
  return (
    <div className="UploadFilesCardExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <UploadFilesCardConnected uploadFileIndex={0} />
      </Provider>
    </div>
  )
}
