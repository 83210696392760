import { Dispatch, Action } from 'redux'
import { TaskChangesMainMapDispatchReturnType } from './types/TaskChangesMainMapDispatchReturnType'
import { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'

export const TaskChangesMainMapDispatch = (
  dispatch: Dispatch<Action>
): TaskChangesMainMapDispatchReturnType => {
  return {
    onFirebaseError: (error: FirebaseError) => {
      dispatch(
        createFirebaseErrorAction({
          error,
          description: 'SingleTaskWatcherMapDispatch',
        })
      )
    },
  }
}
