// import { MessageFileCardStyles } from './MessageFileCardStyles'
import React from 'react'
import { MessageFileCardProps } from './types/MessageFileCardProps'
import { UploadedImageWidget } from 'components/widget/UploadedImageWidget'

export const MessageFileCard = ({
  thumbPath,
  originalPath,
  fileName,
}: MessageFileCardProps): JSX.Element => {
  return (
    <UploadedImageWidget
      fileName={fileName}
      thumbPath={thumbPath}
      originalPath={originalPath}
    />
  )
}
