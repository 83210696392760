import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import {
  SUBMIT_UPDATE_PUSH_SETTINGS,
  SUBMIT_UPDATE_PUSH_SETTINGS_DONE,
} from 'actions'
import { MAX_MESSAGING_TOKENS } from 'constants/environmentConstants'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { arrayInsert } from 'utilities/arrayInsert'
import { NotificationToken } from 'firebaseFunctions/types/NotificationToken'
import { createPatchUnscopeUserRequestAction } from 'actionCreators/patch/PatchUnscopeUser/createPatchUnscopeUserRequestAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'
import { createSetCookieStorageAction } from '../../../actionCreators/cookieStorage'

export const submitUpdatePushSettingsF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_PUSH_SETTINGS])
    const { pushNotificationSettings, token } = action.values
    const state: StateReducers = yield select(selectState)
    const notificationTokens = state.currentUnscopeUser?.notificationTokens
    const currentUserId = state.currentUserId || undefined

    if (token) {
      const existingTokenIndex = notificationTokens
        ? notificationTokens.findIndex(({ token: existingToken }) => {
            return existingToken === token
          })
        : -1

      const existingSettings: NotificationToken | undefined =
        existingTokenIndex > -1
          ? notificationTokens?.[existingTokenIndex]
          : undefined

      const needToUpdate = existingTokenIndex === -1 || pushNotificationSettings

      if (needToUpdate) {
        let newMessagingTokens: NotificationToken[]

        const newMessagingToken = {
          token,
          ...existingSettings,
          ...pushNotificationSettings,
        }

        if (existingTokenIndex > -1 && notificationTokens) {
          newMessagingTokens = arrayInsert({
            items: notificationTokens,
            itemToInsert: newMessagingToken,
            index: existingTokenIndex,
            length: 1,
          }).filter(({ token: existingToken }, index) => {
            const isDulicate =
              index !== existingTokenIndex && token === existingToken

            return !isDulicate
          })
        } else {
          newMessagingTokens = [
            newMessagingToken,
            ...(notificationTokens || []),
          ].slice(0, MAX_MESSAGING_TOKENS)
        }

        yield put(
          createPatchUnscopeUserRequestAction({
            requestValues: {
              item: {
                notificationTokens: newMessagingTokens,
              },
              params: {
                userId: currentUserId,
              },
            },
          })
        )

        yield put(
          // Since user activated notification, its safe to assume the user would want us to ask again
          createSetCookieStorageAction({
            description: 'submitUpdatePushSettingsF',
            values: {
              dontAskToEnableNotifications: false,
            },
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdatePushSettingsF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_PUSH_SETTINGS_DONE,
    })
  }
}
