import { connect } from 'react-redux'
import { HistoryDropdown } from './HistoryDropdown'
import { HistoryDropdownMapState } from './HistoryDropdownMapState'
import { HistoryDropdownMapDispatch } from './HistoryDropdownMapDispatch'
import { HistoryDropdownMapStateReturnType } from './types/HistoryDropdownMapStateReturnType'
import { HistoryDropdownMapDispatchReturnType } from './types/HistoryDropdownMapDispatchReturnType'
import { HistoryDropdownConnectedProps } from './types/HistoryDropdownConnectedProps'

export const HistoryDropdownConnected = connect<
  HistoryDropdownMapStateReturnType,
  HistoryDropdownMapDispatchReturnType,
  HistoryDropdownConnectedProps
>(
  HistoryDropdownMapState,
  HistoryDropdownMapDispatch
)(HistoryDropdown)
