import React from 'react'

export const Avatar = ({ mention, theme = {} }: any) => {
  if (mention.avatar) {
    return (
      <img
        src={mention.avatar}
        className={theme.mentionSuggestionsEntryAvatar}
        role="presentation"
        alt="avatar"
      />
    )
  }

  return null
}
