import { connect } from 'react-redux'
import { StripeSubscriptionField } from './StripeSubscriptionField'
import { StripeSubscriptionFieldMapState } from './StripeSubscriptionFieldMapState'
import { StripeSubscriptionFieldMapDispatch } from './StripeSubscriptionFieldMapDispatch'

import { StripeSubscriptionFieldMapStateReturnType } from './types/StripeSubscriptionFieldMapStateReturnType'
import { StripeSubscriptionFieldMapDispatchReturnType } from './types/StripeSubscriptionFieldMapDispatchReturnType'
import { StripeSubscriptionFieldConnectedProps } from './types/StripeSubscriptionFieldConnectedProps'


export const StripeSubscriptionFieldConnected = connect<
  StripeSubscriptionFieldMapStateReturnType,
  StripeSubscriptionFieldMapDispatchReturnType,
  StripeSubscriptionFieldConnectedProps
>(
  StripeSubscriptionFieldMapState,
  StripeSubscriptionFieldMapDispatch
)(StripeSubscriptionField)
