import { connect } from 'react-redux'
import { ServiceWorkerMessageWatcher } from './ServiceWorkerMessageWatcher'
import { ServiceWorkerMessageWatcherMapState } from './ServiceWorkerMessageWatcherMapState'
import { ServiceWorkerMessageWatcherMapDispatch } from './ServiceWorkerMessageWatcherMapDispatch'

import { ServiceWorkerMessageWatcherMapStateReturnType } from './types/ServiceWorkerMessageWatcherMapStateReturnType'
import { ServiceWorkerMessageWatcherMapDispatchReturnType } from './types/ServiceWorkerMessageWatcherMapDispatchReturnType'
import { ServiceWorkerMessageWatcherConnectedProps } from './types/ServiceWorkerMessageWatcherConnectedProps'


export const ServiceWorkerMessageWatcherConnected = connect<
  ServiceWorkerMessageWatcherMapStateReturnType,
  ServiceWorkerMessageWatcherMapDispatchReturnType,
  ServiceWorkerMessageWatcherConnectedProps>(ServiceWorkerMessageWatcherMapState,
    ServiceWorkerMessageWatcherMapDispatch)(ServiceWorkerMessageWatcher)
