import { connect } from 'react-redux'
import { ToPreviousCycleButton } from './ToPreviousCycleButton'
import { ToPreviousCycleButtonMapState } from './ToPreviousCycleButtonMapState'
import { ToPreviousCycleButtonMapDispatch } from './ToPreviousCycleButtonMapDispatch'
import { ToPreviousCycleButtonMapStateReturnType } from './types/ToPreviousCycleButtonMapStateReturnType'
import { ToPreviousCycleButtonMapDispatchReturnType } from './types/ToPreviousCycleButtonMapDispatchReturnType'
import { ToPreviousCycleButtonConnectedProps } from './types/ToPreviousCycleButtonConnectedProps'

export const ToPreviousCycleButtonConnected = connect<
  ToPreviousCycleButtonMapStateReturnType,
  ToPreviousCycleButtonMapDispatchReturnType,
  ToPreviousCycleButtonConnectedProps>(ToPreviousCycleButtonMapState,
    ToPreviousCycleButtonMapDispatch)(ToPreviousCycleButton)
