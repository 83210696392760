import { Task } from '../../../types'
import { FirestoreClient } from '../../../types'
import { getTaskRef } from '../get/getTaskRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import { WriteBatchAdmin } from '../../../types/WriteBatchAdmin'
import { WriteBatchClient } from '../../../types/WriteBatchClient'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  taskId: string
  task: Task
  batch: WriteBatchAdmin | WriteBatchClient
}

export const batchUpdateTask = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  taskId,
  task,
  batch,
}: Props<T>): void => {
  const ref = getTaskRef({
    firestore,
    teamId,
    taskId,
  }) as any
  ;(batch as any).update(ref, task)
}
