import { connect } from 'react-redux'
import { NotificationPermissionWatcher } from './NotificationPermissionWatcher'
import { NotificationPermissionWatcherMapState } from './NotificationPermissionWatcherMapState'
import { NotificationPermissionWatcherMapDispatch } from './NotificationPermissionWatcherMapDispatch'

import { NotificationPermissionWatcherMapStateReturnType } from './types/NotificationPermissionWatcherMapStateReturnType'
import { NotificationPermissionWatcherMapDispatchReturnType } from './types/NotificationPermissionWatcherMapDispatchReturnType'
import { NotificationPermissionWatcherConnectedProps } from './types/NotificationPermissionWatcherConnectedProps'


export const NotificationPermissionWatcherConnected = connect<
  NotificationPermissionWatcherMapStateReturnType,
  NotificationPermissionWatcherMapDispatchReturnType,
  NotificationPermissionWatcherConnectedProps>(NotificationPermissionWatcherMapState,
    NotificationPermissionWatcherMapDispatch)(NotificationPermissionWatcher)
