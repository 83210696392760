import { PATCH_PLANS_REQUEST } from 'actions'
import { PatchPlansRequestAction } from './types/PatchPlansRequestAction'
import { PatchPlansRequestActionValues } from './types/PatchPlansRequestActionValues'

interface Props {
  requestValues: PatchPlansRequestActionValues,
}

export const createPatchPlansRequestAction = ({ requestValues }: Props): PatchPlansRequestAction => {
  return {
    type: PATCH_PLANS_REQUEST,
    requestValues,
  }
}
