import { StateReducers } from 'state/reducers/types/StateReducers'
import { ToastListWatcherConnectedProps } from './types/ToastListWatcherConnectedProps'
import { ToastListWatcherMapStateReturnType } from './types/ToastListWatcherMapStateReturnType'

export const ToastListWatcherMapState = (
  state: StateReducers,
  _props: ToastListWatcherConnectedProps
): ToastListWatcherMapStateReturnType => {
  const alertMessage = state.alertMessage
  const type = alertMessage?.type
  const toastMessage =
    alertMessage?.location === 'toast' ? alertMessage.message : undefined

  return {
    type,
    toastMessage,
  }
}
