import { Dispatch, Action } from 'redux'
import { UpdateUserDisplayNameFormMapDispatchReturnType } from './types/UpdateUserDisplayNameFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_USER_DISPLAY_NAME } from 'actions'

export const UpdateUserDisplayNameFormMapDispatch = (
  dispatch: Dispatch<Action>
): UpdateUserDisplayNameFormMapDispatchReturnType => {
  return {
    submit: ({ displayName }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_USER_DISPLAY_NAME,
          values: { displayName },
          description: 'UpdateUserDisplayNameFormMapDispatch',
        })
      )
    },
  }
}
