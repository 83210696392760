import { connect } from 'react-redux'
import { InlineEditorToolBar } from './InlineEditorToolBar'
import { InlineEditorToolBarMapState } from './InlineEditorToolBarMapState'
import { InlineEditorToolBarMapDispatch } from './InlineEditorToolBarMapDispatch'

import { InlineEditorToolBarMapStateReturnType } from './types/InlineEditorToolBarMapStateReturnType'
import { InlineEditorToolBarMapDispatchReturnType } from './types/InlineEditorToolBarMapDispatchReturnType'
import { InlineEditorToolBarConnectedProps } from './types/InlineEditorToolBarConnectedProps'

export const InlineEditorToolBarConnected = connect<
  InlineEditorToolBarMapStateReturnType,
  InlineEditorToolBarMapDispatchReturnType,
  InlineEditorToolBarConnectedProps
>(
  InlineEditorToolBarMapState,
  InlineEditorToolBarMapDispatch
)(InlineEditorToolBar)
