import { StateReducers } from 'state/reducers/types/StateReducers'
import { WorkspaceStorageUploadMessageConnectedProps } from './types/WorkspaceStorageUploadMessageConnectedProps'
import { WorkspaceStorageUploadMessageMapStateReturnType } from './types/WorkspaceStorageUploadMessageMapStateReturnType'
import { getCanOwn } from 'state/getters/getCanOwn'
import { getIsProFromState } from 'state/getters/getIsProFromState'

export const WorkspaceStorageUploadMessageMapState = (
  state: StateReducers,
  _props: WorkspaceStorageUploadMessageConnectedProps
): WorkspaceStorageUploadMessageMapStateReturnType => {
  const canOwn = getCanOwn({ state })
  const isPro = getIsProFromState({ state })

  return {
    canOwn,
    isPro,
  }
}
