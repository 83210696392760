import { POST_RESET_PASSWORD_FAILURE } from 'actions'

import { PostResetPasswordFailureAction } from './types/PostResetPasswordFailureAction'
import { PostResetPasswordRequestActionValues } from './types/PostResetPasswordRequestActionValues'


interface Props {
  requestValues: PostResetPasswordRequestActionValues
}

export const createPostResetPasswordFailureAction = ({
  requestValues,
}: Props): PostResetPasswordFailureAction => {
  return {
    type: POST_RESET_PASSWORD_FAILURE,
    requestValues,
  }
}
