import React, { useCallback } from 'react'
import { FilterByButtonProps } from './types/FilterByButtonProps'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'
import { FilterIcon } from '../../icon/FilterIcon/FilterIcon'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'

export const FilterByButton = ({
  className = '',
  tagId,
  status,
  setSession,
  onClick,
  tagIds,
  summary,
  taskStatuses,
  ...props
}: FilterByButtonProps): JSX.Element => {
  const isExisting =
    (tagId && tagIds?.includes(tagId)) ||
    (status && taskStatuses?.includes(status))

  const onClickCallback = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setSession(
        { tagId, status },
        {
          tagIds,
          taskStatuses,
          summary,
        }
      )

      onClick?.(e)
    },
    [tagId, status, onClick, setSession, tagIds, taskStatuses, summary]
  )

  return (
    <button
      type="button"
      onClick={onClickCallback}
      className={`FilterByButton ${className} ${actionButtonStyles}`}
      {...props}
    >
      <IconAndDiv icon={<FilterIcon />}>
        {isExisting ? 'Unset' : 'Filter'}
      </IconAndDiv>
    </button>
  )
}
