import { useEffect, FC, useCallback } from 'react'
import { PlansWatcherProps } from './types/PlansWatcherProps'
import { firestore } from 'sdks'
import { PlanStatus } from 'firebaseFunctions/types'
import { MAX_OPEN_PLAN_COUNT } from 'constants/environmentConstants'
import { getPlansRef } from '../../../firebaseFunctions/utilities/ref/get/getPlansRef'
import { FirebaseError } from 'firebase'

const CLOSED: PlanStatus = 'closed'
const OPEN: PlanStatus = 'open'

export const PlansWatcher: FC<PlansWatcherProps> = ({
  teamId,
  onNext,
  onFirebaseError,
  incrementWatcher,
}) => {
  const onNextCallback = useCallback(
    (querySnapshot: firebase.firestore.QuerySnapshot) => {
      onNext({ querySnapshot, teamId })
    },
    [teamId, onNext]
  )

  const onErrorCallback = useCallback(
    (error: FirebaseError) => {
      onFirebaseError({ error, teamId })
    },
    [teamId, onFirebaseError]
  )

  useEffect(() => {
    const closedUnsubscribe = getPlansRef({
      firestore,
      teamId,
    })
      .where('status', '==', CLOSED)
      .orderBy('created', 'desc')
      .limit(1)
      .onSnapshot(onNextCallback, onErrorCallback)

    const openUnsubscribe = getPlansRef({
      firestore,
      teamId,
    })
      .where('status', '==', OPEN)
      .orderBy('created', 'asc')
      .limit(MAX_OPEN_PLAN_COUNT + 1) // need + 1 in case a plan is closed
      .onSnapshot(onNextCallback, onErrorCallback)

    incrementWatcher(2)

    return () => {
      closedUnsubscribe()
      openUnsubscribe()
      incrementWatcher(-2)
    }
  }, [teamId, onNextCallback, onErrorCallback, incrementWatcher])

  return null
}
