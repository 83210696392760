import { UPLOAD_SUCCESS } from 'actions'
import { take, put, spawn } from 'redux-saga/effects'
import { firebaseSdk } from 'sdks'
import { FirebaseError } from 'firebase'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { UploadSuccessAction } from 'actionCreators/Upload/types/UploadSuccessAction'
import { patchUsersSaga } from './patch/patchUsersSaga'

export const uploadSuccessAvatarSaga = function*(uploadId: string) {
  while (1) {
    const uploadSuccessAction: UploadSuccessAction = yield take([
      UPLOAD_SUCCESS,
    ])
    const { key, item: upload } = getObjectFirstItem(
      uploadSuccessAction.successValues
    )
    const url = upload?.url

    if (key === uploadId) {
      const currentUser = firebaseSdk.auth().currentUser

      if (currentUser) {
        try {
          const idToken: string = yield currentUser.getIdToken(true)

          yield spawn(patchUsersSaga, {
            values: {
              items: {
                [currentUser.uid]: {
                  photoURL: url,
                },
              },
            },
            apiHeaders: {
              token: idToken,
            },
          })
        } catch (error) {
          const firestoreErrorAction = createFirebaseErrorAction({
            error: error as FirebaseError,
            description: 'uploadSuccessAvatarSaga',
          })

          yield put(firestoreErrorAction)
        }
      } else {
        yield put(
          createUnexpectedErrorAction({
            description: 'uploadSuccessF',
          })
        )
      }

      return
    }
  }
}
