import * as React from 'react'
import { FormFooter } from './FormFooter'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const FormFooterExamples = () => {
  return (
    <div className="FormFooterExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <FormFooter />
      </Provider>
    </div>
  )
}
