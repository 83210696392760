import { FirestoreClient } from '../../../types'
import { getMessageRef } from '../get/getMessageRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  messageId: string
  taskId: string
}

export const deleteMessage = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  messageId,
  taskId,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getMessageRef({
    firestore,
    teamId,
    messageId,
    taskId,
  }).delete() as any
}
