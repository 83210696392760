import { connect } from 'react-redux'
import { NotificationsSettingsMain } from './NotificationsSettingsMain'
import { NotificationsSettingsMainMapState } from './NotificationsSettingsMainMapState'
import { NotificationsSettingsMainMapDispatch } from './NotificationsSettingsMainMapDispatch'

import { NotificationsSettingsMainMapStateReturnType } from './types/NotificationsSettingsMainMapStateReturnType'
import { NotificationsSettingsMainMapDispatchReturnType } from './types/NotificationsSettingsMainMapDispatchReturnType'
import { NotificationsSettingsMainConnectedProps } from './types/NotificationsSettingsMainConnectedProps'


export const NotificationsSettingsMainConnected = connect<
  NotificationsSettingsMainMapStateReturnType,
  NotificationsSettingsMainMapDispatchReturnType,
  NotificationsSettingsMainConnectedProps>(NotificationsSettingsMainMapState,
    NotificationsSettingsMainMapDispatch)(NotificationsSettingsMain)
