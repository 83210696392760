import { StateReducers } from 'state/reducers/types/StateReducers'
import { NewTaskFormConnectedProps } from './types/NewTaskFormConnectedProps'
import { NewTaskFormMapStateReturnType } from './types/NewTaskFormMapStateReturnType'

export const NewTaskFormMapState = (
  _state: StateReducers,
  _props: NewTaskFormConnectedProps
): NewTaskFormMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
