// import { TaskFileFieldStyles } from './TaskFileFieldStyles'
import React from 'react'
import { TaskFileFieldProps } from './types/TaskFileFieldProps'
import { WorkspaceStorageUploadFieldConnected } from 'components/field/WorkspaceStorageUploadField/WorkspaceStorageUploadFieldConnected'

export const TaskFileField = ({
  uploadFile,
  children,
}: TaskFileFieldProps): JSX.Element => {
  return (
    <WorkspaceStorageUploadFieldConnected
      onValid={uploadFile}
      children={children}
    />
  )
}
