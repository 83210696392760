import { connect } from 'react-redux'
import { CloneTaskButton } from './CloneTaskButton'
import { CloneTaskButtonMapState } from './CloneTaskButtonMapState'
import { CloneTaskButtonMapDispatch } from './CloneTaskButtonMapDispatch'
import { CloneTaskButtonMapStateReturnType } from './types/CloneTaskButtonMapStateReturnType'
import { CloneTaskButtonMapDispatchReturnType } from './types/CloneTaskButtonMapDispatchReturnType'
import { CloneTaskButtonConnectedProps } from './types/CloneTaskButtonConnectedProps'

export const CloneTaskButtonConnected = connect<
  CloneTaskButtonMapStateReturnType,
  CloneTaskButtonMapDispatchReturnType,
  CloneTaskButtonConnectedProps>(CloneTaskButtonMapState,
    CloneTaskButtonMapDispatch)(CloneTaskButton)
