import { Action } from 'redux'

import {
  UNSET_ALERT_MESSAGE,
  SET_ALERT_MESSAGE,
  LOCATION_CHANGE,
  SUBMIT_LOGIN,
  SUBMIT_RESET_PASSWORD,
  SUBMIT_UPDATE_PASSWORD,
  SUBMIT_USER,
} from 'actions'
import { AlertMessageR } from './types/AlertMessageR'
import { SetAlertMessageAction } from 'actionCreators/set/SetAlertMessage/types/SetAlertMessageAction'

export const alertMessageReducer = (
  state?: AlertMessageR,
  action?: Action
): AlertMessageR | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case LOCATION_CHANGE:
    case UNSET_ALERT_MESSAGE:
    case SUBMIT_LOGIN:
    case SUBMIT_RESET_PASSWORD:
    case SUBMIT_UPDATE_PASSWORD:
    case SUBMIT_USER:
      return null

    case SET_ALERT_MESSAGE: {
      const setAlertMessageAction = action as SetAlertMessageAction

      return setAlertMessageAction.values
    }

    default:
      return state || null
  }
}
