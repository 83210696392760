import {
  LOGIN_ROUTE,
  ROOT_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  SERVER_ERROR_TEST_ROUTE,
  JS_ERROR_TEST_ROUTE,
  NETWORK_ERROR_TEST_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_PASSWORD_SENT_ROUTE,
} from './preAndPostLoginRoutes'

import {
  ACCOUNT_SETTINGS_ROUTE,
  ADMIN_ROUTE,
  CREATE_TASK_ROUTE,
  INVITE_DETAIL_ROUTE,
  MEMBER_DETAIL_ROUTE,
  ADD_NEW_MEMBER_ROUTE,
  TASK_DETAIL_ROUTE,
  TASK_TAGS_ROUTE,
  TEAM_SETTINGS_ROUTE,
  DELETE_WORKSPACE_ROUTE,
  REPORT_ROUTE,
  UPDATE_TASK_STATUSES_ROUTE,
  UPDATE_DISPLAY_NAME_ROUTE,
  UPDATE_TEAM_NAME_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  ADD_NEW_LABEL_ROUTE,
  TASK_STATUSES_ROUTE,
  MEMBERS_ROUTE,
  LABELS_ROUTE,
  TASK_SIZES_ROUTE,
  UPDATE_TASK_SIZES_ROUTE,
  ACTIVITY_NOTIFICATION_ROUTE,
  TEAM_FILES_ROUTE,
  NOTIFICATION_SETTINGS_ROUTE,
  UPGRADE_WORKSPACE_ROUTE,
  BILLING_ROUTE,
  WELCOME_TO_PRO_ROUTE,
  WORKSPACE_SUBSCRIPTION_ROUTE,
  PRO_CANCELED_ROUTE,
  PAYMENT_FAILED_ROUTE,
  UPDATE_WORKSPACE_LOGO_ROUTE,
  UPDATE_AVATAR_ROUTE,
} from './postLoginRoutes'

export const oneLayoutRoutes = [
  ACCOUNT_SETTINGS_ROUTE,
  TEAM_FILES_ROUTE,
  ADMIN_ROUTE,
  CREATE_TASK_ROUTE,
  INVITE_DETAIL_ROUTE,
  MEMBER_DETAIL_ROUTE,
  ADD_NEW_MEMBER_ROUTE,
  TASK_DETAIL_ROUTE,
  TASK_TAGS_ROUTE,
  TEAM_SETTINGS_ROUTE,
  DELETE_WORKSPACE_ROUTE,
  REPORT_ROUTE,
  UPDATE_TASK_STATUSES_ROUTE,
  UPDATE_DISPLAY_NAME_ROUTE,
  UPDATE_TEAM_NAME_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  ADD_NEW_LABEL_ROUTE,
  TASK_STATUSES_ROUTE,
  MEMBERS_ROUTE,
  LABELS_ROUTE,
  TASK_SIZES_ROUTE,
  UPDATE_TASK_SIZES_ROUTE,
  ACTIVITY_NOTIFICATION_ROUTE,
  LOGIN_ROUTE,
  ROOT_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  SERVER_ERROR_TEST_ROUTE,
  JS_ERROR_TEST_ROUTE,
  NETWORK_ERROR_TEST_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_PASSWORD_SENT_ROUTE,
  NOTIFICATION_SETTINGS_ROUTE,
  UPGRADE_WORKSPACE_ROUTE,
  BILLING_ROUTE,
  WELCOME_TO_PRO_ROUTE,
  WORKSPACE_SUBSCRIPTION_ROUTE,
  PRO_CANCELED_ROUTE,
  PAYMENT_FAILED_ROUTE,
  UPDATE_WORKSPACE_LOGO_ROUTE,
  UPDATE_AVATAR_ROUTE,
]
