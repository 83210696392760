// import { Dispatch, Action } from 'redux'

import {
  // ServerErrorConnectedProps,
  ServerErrorMapDispatchReturnType,
} from './types'

export const ServerErrorMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: ServerErrorConnectedProps,
): ServerErrorMapDispatchReturnType => {
  return {
  }
}
