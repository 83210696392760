import { StateReducers } from 'state/reducers/types/StateReducers'
import { DropdownButtonConnectedProps } from './types/DropdownButtonConnectedProps'
import { DropdownButtonMapStateReturnType } from './types/DropdownButtonMapStateReturnType'

export const DropdownButtonMapState = (
  state: StateReducers,
  props: DropdownButtonConnectedProps
): DropdownButtonMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const showDropdownType = showDropdown ? showDropdown.type : undefined
  const currentId = showDropdown ? showDropdown.id : undefined
  const isSameDropdownType =
    showDropdownType === props.dropdownType && currentId === props.id

  return {
    isSameDropdownType,
  }
}
