import { connect } from 'react-redux'
import { TaskSummaryField } from './TaskSummaryField'
import { TaskSummaryFieldMapState } from './TaskSummaryFieldMapState'
import { TaskSummaryFieldMapDispatch } from './TaskSummaryFieldMapDispatch'

import { TaskSummaryFieldMapStateReturnType } from './types/TaskSummaryFieldMapStateReturnType'
import { TaskSummaryFieldMapDispatchReturnType } from './types/TaskSummaryFieldMapDispatchReturnType'
import { TaskSummaryFieldConnectedProps } from './types/TaskSummaryFieldConnectedProps'


export const TaskSummaryFieldConnected = connect<
  TaskSummaryFieldMapStateReturnType,
  TaskSummaryFieldMapDispatchReturnType,
  TaskSummaryFieldConnectedProps>(TaskSummaryFieldMapState,
    TaskSummaryFieldMapDispatch)(TaskSummaryField)
