import { css } from 'emotion'

import {
  PRIMARY_COLOR,
  NEUTRAL_HIGH_LIGHT_COLOR,
} from 'constants/styleConstants'

export const DraftToolBarStyles = css`
  overflow: scroll;
  max-width: 260px;
  display: flex;

  button {
    padding: 8px;
    min-width: 40px;
    line-height: 0;

    /* .BackspaceIcon {
      width: 22px;
    } */

    &.active {
      background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
    }
  }

  &.isHoverable {
    button {
      &.active {
        &:hover {
          background-color: ${PRIMARY_COLOR};
          color: #fff;
        }
      }

      &:hover {
        background-color: ${PRIMARY_COLOR};
        color: #fff;
      }
    }
  }

  &.isMediumScreen {
    max-width: 480px;
  }
`
