import { connect } from 'react-redux'
import { StripeSubscriptionMain } from './StripeSubscriptionMain'
import { StripeSubscriptionMainMapState } from './StripeSubscriptionMainMapState'
import { StripeSubscriptionMainMapDispatch } from './StripeSubscriptionMainMapDispatch'

import { StripeSubscriptionMainMapStateReturnType } from './types/StripeSubscriptionMainMapStateReturnType'
import { StripeSubscriptionMainMapDispatchReturnType } from './types/StripeSubscriptionMainMapDispatchReturnType'
import { StripeSubscriptionMainConnectedProps } from './types/StripeSubscriptionMainConnectedProps'


export const StripeSubscriptionMainConnected = connect<
  StripeSubscriptionMainMapStateReturnType,
  StripeSubscriptionMainMapDispatchReturnType,
  StripeSubscriptionMainConnectedProps>(StripeSubscriptionMainMapState,
    StripeSubscriptionMainMapDispatch)(StripeSubscriptionMain)
