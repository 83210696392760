import { connect } from 'react-redux'
import { TagDisplayNameSpan } from './TagDisplayNameSpan'
import { TagDisplayNameSpanMapState } from './TagDisplayNameSpanMapState'
import { TagDisplayNameSpanMapDispatch } from './TagDisplayNameSpanMapDispatch'

import { TagDisplayNameSpanMapStateReturnType } from './types/TagDisplayNameSpanMapStateReturnType'
import { TagDisplayNameSpanMapDispatchReturnType } from './types/TagDisplayNameSpanMapDispatchReturnType'
import { TagDisplayNameSpanConnectedProps } from './types/TagDisplayNameSpanConnectedProps'


export const TagDisplayNameSpanConnected = connect<
  TagDisplayNameSpanMapStateReturnType,
  TagDisplayNameSpanMapDispatchReturnType,
  TagDisplayNameSpanConnectedProps>(TagDisplayNameSpanMapState,
    TagDisplayNameSpanMapDispatch)(TagDisplayNameSpan)
