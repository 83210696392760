import { css } from 'emotion'
import {
  LINK_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_LIGHT,
  LINK_COLOR_DARK,
} from 'constants/styleConstants'

export const buttonStyles = css`
  color: ${TEXT_COLOR_LIGHT};
  letter-spacing: 0.2px;

  > * {
    vertical-align: middle;
  }

  &:hover,
  &:active {
    color: ${TEXT_COLOR};
  }

  &.standOut {
    color: ${LINK_COLOR};

    &:hover,
    &:active {
      color: ${LINK_COLOR_DARK};
      text-decoration: underline;
    }

    &.active {
      background-color: ${LINK_COLOR};
      color: #fff;
    }
  }

  &.active {
    color: ${LINK_COLOR};

    &:hover,
    &:active {
      color: ${LINK_COLOR};
    }
  }
`
