import { connect } from 'react-redux'
import { TaskDescriptionMain } from './TaskDescriptionMain'
import { TaskDescriptionMainMapState } from './TaskDescriptionMainMapState'
import { TaskDescriptionMainMapDispatch } from './TaskDescriptionMainMapDispatch'

import { TaskDescriptionMainMapStateReturnType } from './types/TaskDescriptionMainMapStateReturnType'
import { TaskDescriptionMainMapDispatchReturnType } from './types/TaskDescriptionMainMapDispatchReturnType'
import { TaskDescriptionMainConnectedProps } from './types/TaskDescriptionMainConnectedProps'


export const TaskDescriptionMainConnected = connect<
  TaskDescriptionMainMapStateReturnType,
  TaskDescriptionMainMapDispatchReturnType,
  TaskDescriptionMainConnectedProps>(TaskDescriptionMainMapState,
    TaskDescriptionMainMapDispatch)(TaskDescriptionMain)
