import { CALL_HISTORY_METHOD } from 'actions'
import { createLocation } from 'utilities/create/createLocation'
import { HistoryAction } from './types/HistoryAction'
import { UrlQuery } from 'types/UrlQuery'
import { HistoryMethod } from 'types/HistoryMethod'

interface Props {
  pathname?: string
  method?: HistoryMethod
  query?: UrlQuery
  queryString?: string
  description: string
}

export const createHistoryAction = ({
  pathname,
  query,
  method = 'push',
  queryString,
  description,
}: Props): HistoryAction => {
  const location = pathname
    ? createLocation({ pathname, query, queryString })
    : undefined

  return {
    type: CALL_HISTORY_METHOD,
    description,
    values: {
      method,
      location,
    },
  }
}
