import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUserRole } from 'state/getters/getUserRole'

import { DisplayNameSpanConnectedProps } from './types/DisplayNameSpanConnectedProps'
import { DisplayNameSpanMapStateReturnType } from './types/DisplayNameSpanMapStateReturnType'

export const DisplayNameSpanMapState = (
  state: StateReducers,
  props: DisplayNameSpanConnectedProps
): DisplayNameSpanMapStateReturnType => {
  let displayName

  if (props.labelId) {
    displayName = props.labelId
  } else if (props.userId) {
    const userRole = getUserRole({ state, userId: props.userId })

    displayName = userRole?.displayName
  } else {
    displayName = state.currentUser?.displayName
  }

  return {
    displayName,
  }
}
