import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'
// import { BORDER_RADIUS } from 'constants/styleConstants'

export const lightBoxCardStyles = css`
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: #fff;

  &:hover,
  &.isActive {
    border-color: ${NEUTRAL_BORDER_COLOR};
  }

  &.noBorder {
    border: none;
  }
`
