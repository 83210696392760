import { forEach } from 'lodash'
import { UploadedFilesReducer }  from 'state/reducers/types/UploadedFilesReducer'

interface Props {
  itemsObject?: UploadedFilesReducer
  reverse?: boolean
}

interface IdAndBytes {
  itemId: string
  bytes?: number
}

// TODO: optimize, this function runs about a dozen time
export const getSortedByBytes = ({ itemsObject, reverse }: Props): string[] => {
  if (!itemsObject) {
    return []
  }

  const idAndBytesItemsMutable: IdAndBytes[] = []

  forEach(itemsObject, (item, itemId) => {
    const bytes = item.data?.bytes

    idAndBytesItemsMutable.push({
      itemId,
      bytes,
    })
  })

  const sortedItems = idAndBytesItemsMutable.sort((firstItem, secondItem) => {
    const { bytes: firstItemBytes = 0 } = firstItem
    const { bytes: secondItemBytes = 0 } = secondItem

    if (firstItemBytes < secondItemBytes) {
      return reverse ? 1 : -1
    }

    if (firstItemBytes > secondItemBytes) {
      return reverse ? -1 : 1
    }

    return 0
  })

  const sortedIds = sortedItems.map(({ itemId }) => {
    return itemId
  })

  return sortedIds
}
