import { MentionItem } from './createMentionPlugin'

// Get the first 5 suggestions that match

export const defaultSuggestionsFilter = (
  searchValue: string,
  suggestions: MentionItem[]
): MentionItem[] => {
  const value = searchValue.toLowerCase()

  const filteredSuggestions = suggestions.filter(
    (suggestion: any) =>
      !value || suggestion.name.toLowerCase().indexOf(value) > -1
  )

  const length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5

  return filteredSuggestions.slice(0, length)
}
