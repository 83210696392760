import { StateReducers } from 'state/reducers/types/StateReducers'
import { WorkspaceStorageUploadFieldConnectedProps } from './types/WorkspaceStorageUploadFieldConnectedProps'
import { WorkspaceStorageUploadFieldMapStateReturnType } from './types/WorkspaceStorageUploadFieldMapStateReturnType'
import { getTeamAvailableBytes } from 'state/getters/getTeamAvailableBytes'

export const WorkspaceStorageUploadFieldMapState = (
  state: StateReducers,
  _props: WorkspaceStorageUploadFieldConnectedProps
): WorkspaceStorageUploadFieldMapStateReturnType => {
  const teamAvailableBytes = getTeamAvailableBytes({ state })

  return {
    teamAvailableBytes,
  }
}
