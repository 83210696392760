import { SET_COOKIE_STORAGE } from 'actions'
import { CookieStorage } from 'types/CookieStorage'
import { SetCookieStorageAction } from './types/SetCookieStorageAction'

interface Props {
  values: CookieStorage
  replace?: boolean
  description: string
}

export const createSetCookieStorageAction = ({
  values,
  replace,
  description,
}: Props): SetCookieStorageAction => {
  return {
    type: SET_COOKIE_STORAGE,
    values,
    replace,
    description,
  }
}
