import { SuccessMessageStyles } from './SuccessMessageStyles'
import React from 'react'
import { SuccessMessageProps } from './types/SuccessMessageProps'

export const SuccessMessage = ({
  className = '',
  ...props
}: SuccessMessageProps): JSX.Element => {
  const extraClassName = className ? ` ${className}` : ''

  return (
    <p
      className={'SuccessMessage ' + SuccessMessageStyles + extraClassName}
      {...props}
    />
  )
}
