import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamTaskStatuses } from 'state/getters/getCurrentTeamTaskStatuses'
import { TaskStatusSelectFieldConnectedProps } from './types/TaskStatusSelectFieldConnectedProps'
import { TaskStatusSelectFieldMapStateReturnType } from './types/TaskStatusSelectFieldMapStateReturnType'

export const TaskStatusSelectFieldMapState = (
  state: StateReducers,
  _props: TaskStatusSelectFieldConnectedProps
): TaskStatusSelectFieldMapStateReturnType => {
  const taskStatuses = getCurrentTeamTaskStatuses({ state })

  return {
    taskStatuses,
  }
}
