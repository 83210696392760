import { take, put, select, spawn } from 'redux-saga/effects'
import { reorderArraySubsetItem } from 'utilities/reorderArraySubsetItem'
import { uniq } from 'lodash'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_TASK_STATUS_DROP, SUBMIT_TASK_STATUS_DROP_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { patchPlansSaga } from 'flows/sagas/patch/patchPlansSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getActivePlan } from 'state/getters/getActivePlan'
import { getActivePlanId } from 'state/getters/getActivePlanId'
import { getActivePlanTaskIds } from 'state/getters/getActivePlanTaskIds'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitTaskStatusDropF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_STATUS_DROP])
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const activePlan = getActivePlan({ state })
    const planId = getActivePlanId({ state })
    const activePlanTaskIds: string[] =
      activePlan && Array.isArray(activePlan.taskIds) ? activePlan.taskIds : []
    const statusGroups = getActivePlanTaskIds({ state, useFilter: true })

    const { dropResult } = action.values
    const taskId = dropResult && dropResult.draggableId
    const task = taskId ? getCurrentTask({ state, taskId }) : undefined
    const taskStatus = task?.status
    const destination = dropResult && dropResult.destination
    const newStatus = destination && destination.droppableId

    const toStatusGroup: string[] = (newStatus && statusGroups[newStatus]) || []
    const toIndex = (destination && destination.index) || 0

    if (taskId && teamId && planId && currentUserId && currentUserDisplayName) {
      if (newStatus && taskStatus !== newStatus) {
        const items: TasksReducer = {
          [taskId]: {
            data: {
              status: newStatus,
            },
            teamId,
          },
        }

        yield spawn(patchTasksSaga, {
          items: items,
          lastModifiedUserId: currentUserId,
          lastModifiedDisplayName: currentUserDisplayName,
        })
      }

      if (toStatusGroup.length > 0) {
        const newPlanTaskIds = reorderArraySubsetItem({
          items: activePlanTaskIds,
          subsetItems: toStatusGroup,
          itemToReoder: taskId,
          toSubsetIndex: toIndex,
        })

        const items: PlansReducer = {
          [planId]: {
            data: {
              taskIds: uniq(newPlanTaskIds),
            },
            teamId,
          },
        }

        yield spawn(patchPlansSaga, {
          items,
          lastModifiedUserId: currentUserId,
          lastModifiedDisplayName: currentUserDisplayName,
        })
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskStatusDropF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_STATUS_DROP_DONE,
    })
  }
}
