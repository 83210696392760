import { getSessionStorageF } from './getSessionStorageF'
import { getDashboardRequestF } from './getDashboardRequestF'
import { getTeamsRequestF } from './getTeamsRequestF'
import { getTeamsSuccessF } from './getTeamsSuccessF'
import { getUserExtensionRequestF } from './getUserExtensionRequestF'
import { getUserInvitesRequestF } from './getUserInvitesRequestF'
import { getCookieStorageF } from './getCookieStorageF'
import { getUnscopeUserRequestF } from './getUnscopeUserRequestF'
import { getTeamLogoUrlRequestF } from './getTeamLogoUrlRequestF'

export const getFlows = [
  getSessionStorageF,
  getDashboardRequestF,
  getTeamsRequestF,
  getTeamsSuccessF,
  getUserExtensionRequestF,
  getUserInvitesRequestF,
  getCookieStorageF,
  getUnscopeUserRequestF,
  getTeamLogoUrlRequestF,
]
