import { StateReducers } from 'state/reducers/types/StateReducers'
import { UserReportMainConnectedProps } from './types/UserReportMainConnectedProps'
import { UserReportMainMapStateReturnType } from './types/UserReportMainMapStateReturnType'
import { getMaxUserCount } from '../../../state/getters/getMaxUserCount'
import { getUserCount } from '../../../state/getters/getUserCount'
import { getIsProFromState } from '../../../state/getters/getIsProFromState'

export const UserReportMainMapState = (
  state: StateReducers,
  _props: UserReportMainConnectedProps
): UserReportMainMapStateReturnType => {
  const { maxUserCountFree, maxUserCountPro } = getMaxUserCount({ state })
  const userCount = getUserCount({ state })
  const isPro = getIsProFromState({ state })

  return {
    userCount,
    maxUserCountFree,
    maxUserCountPro,
    isPro,
  }
}
