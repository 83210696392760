import { css } from 'emotion'
import {
  PLACEHOLDER_COLOR,
  NEUTRAL_BORDER_COLOR,
} from 'constants/styleConstants'

export const SelectInputStyles = css`
  line-height: 1.6em;

  &.noBorder {
    .SelectInput__control {
      border: none;
      box-shadow: none;
    }
  }

  .SelectInput__control {
    border-color: rgba(0, 0, 0, 0);
    width: 100%;

    &:hover {
      border-color: ${NEUTRAL_BORDER_COLOR};
    }
  }

  .SelectInput__menu {
    z-index: 1000;
  }

  .SelectInput__menu-list {
    max-height: 360px;
  }

  .SelectInput__placeholder {
    color: ${PLACEHOLDER_COLOR};
  }

  &.clean {
    .SelectInput__control {
      border-color: rgba(0, 0, 0, 0);

      &:hover {
        border-color: #bbb;
      }
    }
  }

  .SelectInput__indicator-separator {
    display: none;
  }
`
