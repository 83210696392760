// import { Dispatch, Action } from 'redux'

import {
  // InviteCardConnectedProps,
  InviteCardMapDispatchReturnType,
} from './types'

export const InviteCardMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: InviteCardConnectedProps,
): InviteCardMapDispatchReturnType => {
  return {
  }
}
