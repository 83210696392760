import { connect } from 'react-redux'
import { PrimaryDropdown } from './PrimaryDropdown'
import { PrimaryDropdownMapState } from './PrimaryDropdownMapState'
import { PrimaryDropdownMapDispatch } from './PrimaryDropdownMapDispatch'

import { PrimaryDropdownMapStateReturnType } from './types/PrimaryDropdownMapStateReturnType'
import { PrimaryDropdownMapDispatchReturnType } from './types/PrimaryDropdownMapDispatchReturnType'
import { PrimaryDropdownConnectedProps } from './types/PrimaryDropdownConnectedProps'

export const PrimaryDropdownConnected = connect<
  PrimaryDropdownMapStateReturnType,
  PrimaryDropdownMapDispatchReturnType,
  PrimaryDropdownConnectedProps
>(
  PrimaryDropdownMapState,
  PrimaryDropdownMapDispatch
)(PrimaryDropdown)
