import { connect } from 'react-redux'
import { SelectTagInputField } from './SelectTagInputField'
import { SelectTagInputFieldMapState } from './SelectTagInputFieldMapState'
import { SelectTagInputFieldMapDispatch } from './SelectTagInputFieldMapDispatch'
import { SelectTagInputFieldMapStateReturnType } from './types/SelectTagInputFieldMapStateReturnType'
import { SelectTagInputFieldMapDispatchReturnType } from './types/SelectTagInputFieldMapDispatchReturnType'
import { SelectTagInputFieldConnectedProps } from './types/SelectTagInputFieldConnectedProps'

export const SelectTagInputFieldConnected = connect<
  SelectTagInputFieldMapStateReturnType,
  SelectTagInputFieldMapDispatchReturnType,
  SelectTagInputFieldConnectedProps
>(
  SelectTagInputFieldMapState,
  SelectTagInputFieldMapDispatch
)(SelectTagInputField)
