import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_STATUS_COLOR, SUBMIT_STATUS_COLOR_DONE } from 'actions'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'

export const submitStatusColorF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_STATUS_COLOR])
    const { color, status } = action.values
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const team = getCurrentTeam({ state })
    const taskStatusColors = (team && team.taskStatusColors) || {}
    const taskStatuses = (team && team.taskStatuses) || []

    if (color && status && teamId) {
      const newStatusColorMutable = {}

      taskStatuses.forEach((taskStatus) => {
        if (taskStatus === status) {
          newStatusColorMutable[taskStatus] = color
        } else if (taskStatusColors[taskStatus]) {
          newStatusColorMutable[taskStatus] = taskStatusColors[taskStatus]
        }
      })

      const patchTeamsRequestAction = createPatchTeamsRequestAction({
        requestValues: {
          items: {
            [teamId]: {
              data: {
                taskStatusColors: newStatusColorMutable,
              },
            },
          },
        },
      })

      yield put(patchTeamsRequestAction)

      yield put(
        createToggleAction({
          description: 'submitStatusColorF',
          toggles: {
            showDropdown: false,
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitStatusColorF',
        })
      )
    }

    yield put({
      type: SUBMIT_STATUS_COLOR_DONE,
    })
  }
}
