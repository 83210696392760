import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'

import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import { SUBMIT_DELETE_LABEL, SUBMIT_DELETE_LABEL_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitDeleteLabelF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_LABEL])
    const { labelId } = action.values
    const state: StateReducers = yield select(selectState)
    const team = getCurrentTeam({ state })
    const teamId = getCurrentTeamId({ state })
    const labels = team && team.labels

    if (teamId && labelId) {
      const newLabelsMutable = { ...labels }

      delete newLabelsMutable[labelId]

      yield put(
        createPatchTeamsRequestAction({
          requestValues: {
            items: {
              [teamId]: {
                data: {
                  labels: newLabelsMutable,
                },
              },
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteLabelF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_LABEL_DONE,
    })
  }
}
