import { take, put } from 'redux-saga/effects'
import { storageSdk } from 'sdks'
import { getTeamLogoStoragePath } from 'firebaseFunctions/utilities/getTeamLogoStoragePath'
import { SUBMIT_DELETE_FILE, SUBMIT_DELETE_FILE_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createGetTeamLogoUrlSuccessAction } from 'actionCreators/get/GetTeamLogoUrl/createGetTeamLogoUrlSuccessAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

export const submitDeleteTeamLogoF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_FILE])
    const { teamId } = action.values

    if (teamId) {
      const { originalPath, thumbPath } = getTeamLogoStoragePath({ teamId })

      storageSdk()
        .ref()
        .child(originalPath)
        .delete() // dont care if it fails

      storageSdk()
        .ref()
        .child(thumbPath)
        .delete() // dont care if it fails

      yield put(
        createGetTeamLogoUrlSuccessAction({
          requestValues: {
            teamId,
          },
          successValues: {
            logoUrl: undefined,
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteTeamLogoF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_FILE_DONE,
    })
  }
}
