import { PATCH_INVITES_SUCCESS } from 'actions'

import { PatchInvitesSuccessAction } from './types/PatchInvitesSuccessAction'
import { PatchInvitesSuccessActionValues } from './types/PatchInvitesSuccessActionValues'
import { PatchInvitesRequestActionValues } from './types/PatchInvitesRequestActionValues'


interface Props {
  successValues: PatchInvitesSuccessActionValues
  requestValues: PatchInvitesRequestActionValues
}

export const createPatchInvitesSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchInvitesSuccessAction => {
  return {
    type: PATCH_INVITES_SUCCESS,
    requestValues,
    successValues,
  }
}
