import { FullImageWidgetStyles } from './FullImageWidgetStyles'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { FullImageWidgetProps } from './types/FullImageWidgetProps'
import { ImageWidget } from 'components/widget/ImageWidget'
import { ActionButton } from 'components/button/ActionButton'
import { Spinner } from '../Spinner'
import { ArrowLeftIcon } from 'components/icon/ArrowLeftIcon/ArrowLeftIcon'
import { SearchIcon } from 'components/icon/SearchIcon/SearchIcon'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { DeleteAndConfirmWidget } from '../DeleteAndConfirmWidget'
import { flexCenterStyles } from '../../../styles/flex/flexCenterStyles'

export const FullImageWidget = ({
  onCloseClick,
  onDeleteClick,
  src,
  isMediumScreen,
  canWrite,
  fileName,
  ...props
}: FullImageWidgetProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(isMediumScreen ? 2 : 1)

  const onZoom = () => {
    if (zoomLevel > 0) {
      setZoomLevel(zoomLevel - 1)
    } else {
      setZoomLevel(isMediumScreen ? 2 : 1)
    }
  }

  const element = (
    <div className={'FullImageWidget ' + FullImageWidgetStyles}>
      <header className={flexCenterStyles}>
        {onCloseClick && (
          <ActionButton title="Go back" onClick={onCloseClick}>
            <ArrowLeftIcon />
          </ActionButton>
        )}

        <ActionButton title="Zoom" onClick={onZoom}>
          <IconAndDiv icon={<SearchIcon />}>
            {zoomLevel === 0 ? 'Original' : zoomLevel === 1 ? '100%' : '50%'}
          </IconAndDiv>
        </ActionButton>

        {isDeleting && <Spinner />}

        {onDeleteClick && !isDeleting && canWrite && (
          <>
            <DeleteAndConfirmWidget
              onDeleteClick={() => {
                setIsDeleting(true)
                onDeleteClick()
              }}
            />
          </>
        )}
      </header>

      {fileName && <div className="fileName">{fileName}</div>}

      <ImageWidget
        src={src}
        {...props}
        onClick={onZoom}
        style={{
          width: zoomLevel === 0 ? undefined : zoomLevel === 1 ? '100%' : '50%',
        }}
      />
    </div>
  )

  return ReactDOM.createPortal(element, document.getElementById('portal')!)
}
