import { connect } from 'react-redux'
import { TaskTagCard } from './TaskTagCard'
import { TaskTagCardMapState } from './TaskTagCardMapState'
import { TaskTagCardMapDispatch } from './TaskTagCardMapDispatch'

import { TaskTagCardMapStateReturnType } from './types/TaskTagCardMapStateReturnType'
import { TaskTagCardMapDispatchReturnType } from './types/TaskTagCardMapDispatchReturnType'
import { TaskTagCardConnectedProps } from './types/TaskTagCardConnectedProps'


export const TaskTagCardConnected = connect<
  TaskTagCardMapStateReturnType,
  TaskTagCardMapDispatchReturnType,
  TaskTagCardConnectedProps>(TaskTagCardMapState,
    TaskTagCardMapDispatch)(TaskTagCard)
