import { css } from 'emotion'
import { lightBoxStyles } from 'styles/lightBoxStyles'

export const WelcomeToProMainStyles = css`
  h1 {
    margin-bottom: 32px;
  }

  .${lightBoxStyles} {
    margin-top: 16px;
  }

  .IconAndDiv {
    svg {
      margin-right: 16px;
    }
  }
`
