import { UPLOAD_FAILURE } from 'actions'

import { UploadFailureAction } from './types/UploadFailureAction'
import { UploadRequestActionValues } from './types/UploadRequestActionValues'
import { UploadFailureActionValues } from './types/UploadFailureActionValues'


interface Props {
  requestValues: UploadRequestActionValues
  failureValues: UploadFailureActionValues
}

export const createUploadFailureAction = ({
  requestValues,
  failureValues,
}: Props): UploadFailureAction => {
  return {
    type: UPLOAD_FAILURE,
    requestValues,
    failureValues,
  }
}
