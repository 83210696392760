import { IconAndDivStyles } from './IconAndDivStyles'
import React from 'react'
import { IconAndDivProps } from './types/IconAndDivProps'

export const IconAndDiv = ({
  icon,
  children,
  className = '',
  ...props
}: IconAndDivProps): JSX.Element => {
  return (
    <div className={`IconAndDiv ${IconAndDivStyles} ${className}`} {...props}>
      {icon}
      <div>{children}</div>
    </div>
  )
}
