import { MessageFileListStyles } from './MessageFileListStyles'
import React, { useState, useEffect } from 'react'
import { MessageFileListProps } from './types/MessageFileListProps'
import { getMessageFiles } from 'utilities/get/getMessageFiles'
import { THUMB_FILE_NAME_PREFIX } from 'firebaseFunctions/constants'
import { MessageFileCardConnected } from 'components/card/MessageFileCard'

export const MessageFileList = ({
  teamId,
  taskId,
  messageId,
}: MessageFileListProps): JSX.Element => {
  const [fileNamesState, setFilesState] = useState<string[] | null>(null)

  useEffect(() => {
    const getFileNames = async () => {
      if (teamId && taskId && messageId) {
        const fileNames = await getMessageFiles({ teamId, taskId, messageId })

        setFilesState(fileNames)
      }
    }

    getFileNames()
  }, [teamId, taskId, messageId])

  return (
    <ul className={'MessageFileList ' + MessageFileListStyles}>
      {fileNamesState &&
        fileNamesState.map((fileName) => {
          const isThumb = fileName.indexOf(THUMB_FILE_NAME_PREFIX) === 0

          if (!isThumb) {
            return (
              <li key={fileName}>
                <MessageFileCardConnected
                  fileName={fileName}
                  messageId={messageId}
                />
              </li>
            )
          }

          return null
        })}
    </ul>
  )
}
