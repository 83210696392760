import * as React from 'react'
import { UserAccountHeaderConnected } from './UserAccountHeaderConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'
import { StateReducers } from 'state/reducers/types/StateReducers'

const mockStateToggled: StateReducers = {
  ...mockState,
  toggles: {
    showAccountDropdown: true,
  },
}

export const UserAccountHeaderExamples = () => {
  return (
    <div className="UserAccountHeaderExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockStateToggled)}>
        <UserAccountHeaderConnected />
      </Provider>
    </div>
  )
}
