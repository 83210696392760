import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const TeamNameWidgetStyles = css`
  display: flex;
  align-items: center;

  .displayName {
    margin-right: 8px;
  }

  img {
    max-height: 32px;
    max-width: 32px;
    margin-left: 8px;
  }

  &.showLogoOnly {
    img {
      margin-left: 0px;
    }
  }
`
