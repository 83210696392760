import { SyncTaskCountButtonStyles } from './SyncTaskCountButtonStyles'
import React, { useState, useEffect, useCallback } from 'react'
import { SyncTaskCountButtonProps } from './types/SyncTaskCountButtonProps'
import { patchTeamStatApi } from 'apis/patch/patchTeamStatApi'
import { getIdToken } from 'sdks/firebase/getIdToken'
import { Spinner } from 'components/widget/Spinner'
import { ActionButton } from '../ActionButton'
import { TASK_COUNT_SYNC_COOLDOWN_MS } from 'constants/environmentConstants'

export const SyncTaskCountButton = ({
  teamId,
  handleError,
  taskCountSyncMilliSeconds,
  className = '',
}: SyncTaskCountButtonProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [cooldownTimer, setCooldownTimer] = useState<null | number>(null)

  const onClick = async () => {
    if (teamId) {
      try {
        setShowSpinner(true)
        const token = await getIdToken()

        if (token) {
          await patchTeamStatApi({ values: { teamId }, apiHeaders: { token } })
        } else {
          handleError({ description: 'SyncTaskCountButton no token' })
        }
      } catch (error) {
        handleError({ error, description: 'SyncTaskCountButton' })
      }

      setShowSpinner(false)
    }
  }

  const getCooldownMilliSeconds = useCallback(() => {
    const diff = new Date().getTime() - (taskCountSyncMilliSeconds || 0)
    const cooldownTime = TASK_COUNT_SYNC_COOLDOWN_MS || 18000000

    if (diff < cooldownTime) {
      return cooldownTime - diff
    }

    return 0
  }, [taskCountSyncMilliSeconds])

  useEffect(() => {
    const cooldown = getCooldownMilliSeconds()

    if (cooldown) {
      setCooldownTimer(cooldown)
    }
  }, [getCooldownMilliSeconds])

  useEffect(() => {
    if (cooldownTimer) {
      const timeout = setTimeout(() => {
        const newCooldownTimer = getCooldownMilliSeconds()

        if (newCooldownTimer) {
          setCooldownTimer(newCooldownTimer)
        } else {
          setCooldownTimer(null)
        }
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }

    return
  }, [cooldownTimer, getCooldownMilliSeconds])

  return (
    <ActionButton
      onClick={onClick}
      className={`SyncTaskCountButton ${SyncTaskCountButtonStyles} ${className}`}
      disabled={showSpinner || !!cooldownTimer}
      title="Sync Task Count"
    >
      {showSpinner && <Spinner />}
      {!showSpinner && !cooldownTimer && 'Sync Task Count'}
      {cooldownTimer && `cooldown: ${Math.floor(cooldownTimer / 1000)} seconds`}
    </ActionButton>
  )
}
