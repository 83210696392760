import { POST_USER_INVITED_SUCCESS } from 'actions'

import { PostUserInvitedSuccessAction } from './types/PostUserInvitedSuccessAction'
import { PostUserInvitedSuccessActionValues } from './types/PostUserInvitedSuccessActionValues'
import { PostUserInvitedRequestActionValues } from './types/PostUserInvitedRequestActionValues'


interface Props {
  requestValues: PostUserInvitedRequestActionValues
  successValues: PostUserInvitedSuccessActionValues
}

export const createPostUserInvitedSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostUserInvitedSuccessAction => {
  return {
    type: POST_USER_INVITED_SUCCESS,
    requestValues,
    successValues,
  }
}
