import { connect } from 'react-redux'
import { LabelCard } from './LabelCard'
import { LabelCardMapState } from './LabelCardMapState'
import { LabelCardMapDispatch } from './LabelCardMapDispatch'

import { LabelCardMapStateReturnType } from './types/LabelCardMapStateReturnType'
import { LabelCardMapDispatchReturnType } from './types/LabelCardMapDispatchReturnType'
import { LabelCardConnectedProps } from './types/LabelCardConnectedProps'


export const LabelCardConnected = connect<
  LabelCardMapStateReturnType,
  LabelCardMapDispatchReturnType,
  LabelCardConnectedProps>(LabelCardMapState,
    LabelCardMapDispatch)(LabelCard)
