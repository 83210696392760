import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'

interface Props {
  state: StateReducers
}

export const getTaskCount = ({ state }: Props): number => {
  const teamReducer = getCurrentTeamReducer({ state })
  const taskCount = Number(teamReducer?.stats?.taskCount)

  if (isNaN(taskCount)) {
    return 0
  }

  return taskCount
}
