import { TextAreaInputStyles } from './TextAreaInputStyles'
import * as React from 'react'
import { TextAreaInputProps } from './types/TextAreaInputProps'
import TextareaAutosize from 'react-textarea-autosize'

export const TextAreaInput = ({
  className = '',
  value,
  onChange,
  onBlur,
  placeholder,
  onFocus,
  disabled,
  autoFocus,
}: TextAreaInputProps): JSX.Element => {
  const additionalClassName = className ? ` ${className}` : ''

  return (
    <TextareaAutosize
      value={value as any}
      onChange={onChange as any}
      onFocus={onFocus as any}
      onBlur={onBlur as any}
      autoFocus={autoFocus}
      disabled={disabled}
      placeholder={placeholder}
      className={'TextAreaInput ' + TextAreaInputStyles + additionalClassName}
    />
  )
}
