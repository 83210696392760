import { PATCH_USERS_SUCCESS } from 'actions'

import { PatchUsersSuccessAction } from './types/PatchUsersSuccessAction'
import { PatchUsersSuccessActionValues } from './types/PatchUsersSuccessActionValues'
import { PatchUsersRequestActionValues } from './types/PatchUsersRequestActionValues'


interface Props {
  successValues: PatchUsersSuccessActionValues
  requestValues?: PatchUsersRequestActionValues
}

export const createPatchUsersSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchUsersSuccessAction => {
  return {
    type: PATCH_USERS_SUCCESS,
    requestValues,
    successValues,
  }
}
