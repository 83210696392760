export const isInvalidPassword = (password: string): false | string => {
  if (!password) {
    return 'Please enter a password.'
  }

  if (password.length < 6) {
    return 'The password must have at least 6 characters.'
  }

  return false
}
