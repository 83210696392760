import React, { FC } from 'react'
import { AppUpdatedMainProps } from './types/AppUpdatedMainProps'
import { ActionButton } from '../../button/ActionButton'
import { DownloadIcon } from '../../icon/DownloadIcon/DownloadIcon'
import { textAlignCenter } from '../../../styles/text/textAlignCenter'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { largeCenterIconStyles } from '../../../styles/icon/largeCenterIconStyles'

export const AppUpdatedMain: FC<AppUpdatedMainProps> = ({ className = '' }) => {
  return (
    <div
      className={`AppUpdatedMain ${className} ${textAlignCenter} ${marginTopLargeStyles}`}
    >
      <HeaderOneTextConnected>EggKat has been updated</HeaderOneTextConnected>
      <DownloadIcon className={`${largeCenterIconStyles}`} />
      <div>
        <ActionButton
          title="Reload"
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload
        </ActionButton>
      </div>
    </div>
  )
}
