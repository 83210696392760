interface Props {
  min: number
  max: number
}

export const getInBetweenNumber = ({ min, max }: Props): number => {
  const middle = (max + min) / 2
  const middleParts = middle.toString().split('.')
  const decimals = middleParts[1] || ''

  let estimatedMiddle = Number(middleParts[0])
  const isInBetween = estimatedMiddle > min && estimatedMiddle < max

  if (isInBetween) {
    return estimatedMiddle
  }

  for (let i = 0; i < decimals.length; i++) {
    var decimal = Number(decimals.charAt(i)) / (10 * (i+1))
    estimatedMiddle = estimatedMiddle + decimal

    if (estimatedMiddle > min && estimatedMiddle < max) {
      return estimatedMiddle
    }
  }

  return middle
}
