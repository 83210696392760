import React from 'react'
import { HeadingIconProps } from './types/HeadingIconProps'
import { iconStyles } from 'styles/iconStyles'

export const HeadingIcon = ({ className }: HeadingIconProps): JSX.Element => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={`HeadingIcon ${className} ${iconStyles}`}
      role="img"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M448 96v320h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H320a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V288H160v128h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32V96H32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16h-32v128h192V96h-32a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16z"
      ></path>
    </svg>
  )
}
