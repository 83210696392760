import * as React from 'react'
import { SingleTaskWatcherProps } from './types/SingleTaskWatcherProps'
import { firestore } from 'sdks'
import { getTaskRef } from '../../../firebaseFunctions/utilities/ref/get/getTaskRef'

export class SingleTaskWatcher extends React.Component {
  props: SingleTaskWatcherProps
  unsubscribe?: () => void

  unsubscribeAll() {
    if (this.unsubscribe) {
      this.unsubscribe()
      this.unsubscribe = undefined
      this.props.incrementWatcher(1)
    }
  }

  componentWillUnmount() {
    this.unsubscribeAll()
  }

  public render(): null {
    const {
      teamId,
      taskId,
      onNext,
      onFirebaseError,
      incrementWatcher,
    } = this.props

    if (!this.unsubscribe) {
      this.unsubscribe = getTaskRef({ firestore, teamId, taskId }).onSnapshot(
        onNext,
        onFirebaseError
      )

      incrementWatcher(1)
    }

    return null
  }
}
