import { NewUserFormStyles } from './NewUserFormStyles'
import React, { useState, useCallback } from 'react'
import { NewUserFormProps } from './types/NewUserFormProps'
import { isInvalidEmail } from 'firebaseFunctions/utilities/is/isInvalidEmail'
import { isInvalidPassword } from 'firebaseFunctions/utilities/is/isInvalidPassword'
import { FormFooter } from 'components/footer/FormFooter'
import { isInvalidDisplayName } from 'firebaseFunctions/utilities/is/isInvalidDisplayName'
import { TextInputField } from 'components/field/TextInputField/TextInputField'

export const NewUserForm = ({
  teamName,
  email,
  submit,
}: NewUserFormProps): JSX.Element => {
  const [emailState, setEmailState] = useState(email || '')
  const [emailErrorState, setEmailErrorState] = useState<string | null>(null)

  const [passwordState, setPasswordState] = useState('')
  const [passwordErrorState, setPasswordErrorState] = useState<string | null>(
    null
  )

  const [confirmPasswordState, setConfirmPasswordState] = useState('')
  const [confirmPasswordErrorState, setConfirmPasswordErrorState] = useState<
    string | null
  >(null)

  const [displayNameState, setDisplayNameState] = useState('')
  const [displayNameErrorState, setDisplayNameErrorState] = useState<
    string | null
  >(null)

  const validateEmail = useCallback(() => {
    const error = isInvalidEmail(emailState)

    setEmailErrorState(error || null)

    return error
  }, [emailState, setEmailErrorState])

  const validatePassword = useCallback(() => {
    const error = isInvalidPassword(passwordState)

    setPasswordErrorState(error || null)

    return error
  }, [passwordState])

  const validateConfirmPassword = useCallback(() => {
    let error = isInvalidPassword(confirmPasswordState)

    if (confirmPasswordState !== passwordState) {
      error = 'Confirm password does not match.'
    }

    setConfirmPasswordErrorState(error || null)

    return error
  }, [confirmPasswordState, passwordState, setConfirmPasswordErrorState])

  const validateDisplayName = () => {
    const error = isInvalidDisplayName(displayNameState)

    if (error) {
      setDisplayNameErrorState(error)
    }

    return error
  }

  return (
    <form
      className={`NewUserForm ${NewUserFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const passwordError = validatePassword()
        const confirmPasswordError = validateConfirmPassword()
        const emailError = validateEmail()
        const displayNameError = validateDisplayName()

        if (
          !emailError &&
          !passwordError &&
          !displayNameError &&
          !confirmPasswordError
        ) {
          submit({
            email: emailState,
            password: passwordState,
            displayName: displayNameState,
            isInvite: !!email,
          })
        }
      }}
    >
      <h2>
        <span>Create a new account</span>

        {teamName && <span> to join team</span>}

        {teamName && <span className="teamName"> {teamName}</span>}
      </h2>

      <TextInputField
        label="Email Address"
        value={email}
        name="email"
        type="email"
        disabled={!!email}
        error={emailErrorState}
        onChange={(e) => {
          setEmailErrorState(null)
          setEmailState(e.target.value)
        }}
      />

      <TextInputField
        type="password"
        name="password"
        label="Password"
        error={passwordErrorState}
        onChange={(e) => {
          setPasswordErrorState(null)
          setPasswordState(e.target.value)
        }}
      />

      <TextInputField
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        error={confirmPasswordErrorState}
        onChange={(e) => {
          setConfirmPasswordErrorState(null)
          setConfirmPasswordState(e.target.value)
        }}
      />

      <TextInputField
        label="Your name"
        name="displayName"
        type="text"
        error={displayNameErrorState}
        onChange={(e) => {
          setDisplayNameErrorState(null)
          setDisplayNameState(e.target.value)
        }}
      />

      <FormFooter />
    </form>
  )
}
