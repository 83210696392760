import { INCREMENT_WATCHER } from 'actions'
import { IncrementWatcherAction } from './types/IncrementWatcherAction'
import { WatcherTypes } from 'types/WatcherTypes'

interface Props {
  increment: number
  watcherType: WatcherTypes
  description: string
}

export const createIncrementWatcherAction = ({
  increment,
  watcherType,
  description,
}: Props): IncrementWatcherAction => {
  return {
    type: INCREMENT_WATCHER,
    description,
    watcherType,
    increment,
  }
}
