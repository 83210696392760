import React, { useState } from 'react'
import { TagDropdownMainProps } from './types/TagDropdownMainProps'
import { SelectTagFieldConnected } from 'components/field/SelectTagField'
import { CustomLinkConnected } from '../../link/CustomLink'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { ADD_NEW_MEMBER_ROUTE, ADD_NEW_LABEL_ROUTE } from '../../../routes'
import { UserPlusIcon } from '../../icon/UserPlusIcon/UserPlusIcon'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'
import { USER_NOUN, LABEL_NOUN } from '../../../constants/english'
import { TagPlusIcon } from '../../icon/TagPlusIcon/TagPlusIcon'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { TaskTagButtonFieldConnected } from '../../field/TaskTagButtonField/TaskTagButtonFieldConnected'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'
import { displayBlockStyles } from '../../../styles/display/displayBlockStyles'
import { footerDarkStyles } from '../../../styles/footerDarkStyles'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'
import { flexSpaceBetweenStyles } from '../../../styles/flex/flexSpaceBetweenStyles'

export const TagDropdownMain = ({
  className = '',
  canOwn,
  taskId,
}: TagDropdownMainProps): JSX.Element => {
  const [showLinks, setShowLinks] = useState(false)

  return (
    <div className={`TagDropdownMain ${className}`}>
      <div className={paddingSmallStyles}>
        <SelectTagFieldConnected taskId={taskId} />

        <TaskTagButtonFieldConnected
          className={marginTopMediumStyles}
          taskId={taskId}
        />
      </div>

      {canOwn && (
        <div className={`${footerDarkStyles} ${paddingSmallStyles}`}>
          <div className={`${flexSpaceBetweenStyles}`}>
            <div />
            <button
              type="button"
              style={{ display: 'block' }}
              onClick={() => {
                setShowLinks(!showLinks)
              }}
              className={`${textSmallestStyles} ${textLinkStyles} ${displayBlockStyles}`}
            >
              Configure
            </button>
          </div>

          {showLinks && (
            <ul className={listActionButtonsStyles}>
              <li>
                <CustomLinkConnected
                  route={ADD_NEW_MEMBER_ROUTE}
                  className={`${actionButtonStyles}`}
                  withCurrentQuery={true}
                  title={`Add ${USER_NOUN}`}
                >
                  <IconAndDiv icon={<UserPlusIcon />}>{USER_NOUN}</IconAndDiv>
                </CustomLinkConnected>
              </li>
              <li>
                <CustomLinkConnected
                  route={ADD_NEW_LABEL_ROUTE}
                  className={`${actionButtonStyles}`}
                  withCurrentQuery={true}
                  title={`Add ${LABEL_NOUN}`}
                >
                  <IconAndDiv icon={<TagPlusIcon />}>{LABEL_NOUN}</IconAndDiv>
                </CustomLinkConnected>
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  )
}
