import { Dispatch, Action } from 'redux'
import { NewInviteFormMapDispatchReturnType } from './types/NewInviteFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_NEW_INVITE } from 'actions'

export const NewInviteFormMapDispatch = (
  dispatch: Dispatch<Action>
): NewInviteFormMapDispatchReturnType => {
  return {
    submit: ({ emails, role }) => {
      dispatch(
        createSubmitAction({
          values: { emails, role },
          type: SUBMIT_NEW_INVITE,
          description: 'NewInviteFormMapDispatch',
        })
      )
    },
  }
}
