import { TaskSizeDropdownStyles } from './TaskSizeDropdownStyles'
import React from 'react'
import { TaskSizeDropdownProps } from './types/TaskSizeDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { TaskSizeSelectFieldConnected } from 'components/field/TaskSizeSelectField'
import { TaskSizeFieldConnected } from 'components/field/TaskSizeField'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { CustomLinkConnected } from '../../link/CustomLink'
import { TASK_SIZES_ROUTE } from '../../../routes'
import { displayBlockStyles } from '../../../styles/display/displayBlockStyles'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'
import { footerDarkStyles } from '../../../styles/footerDarkStyles'
import { flexSpaceBetweenStyles } from '../../../styles/flex/flexSpaceBetweenStyles'

export const TaskSizeDropdown = ({
  showTaskSizeDropdown,
  taskId,
  size,
  showSelectField,
  onSizeClick,
  onUnsetClick,
  canOwn,
  canWrite,
}: TaskSizeDropdownProps): JSX.Element => {
  return (
    <div className={`TaskSizeDropdown _${taskId} ` + TaskSizeDropdownStyles}>
      <DropdownButtonConnected
        dropdownType="taskSize"
        title="Set task size"
        disabled={!canWrite}
        className={`${textLinkStyles} ${textSmallestStyles}`}
        watchSelector={`.TaskSizeDropdown._${taskId}`}
        id={taskId}
      >
        {size || <span className="placeholder">size</span>}
      </DropdownButtonConnected>

      {showTaskSizeDropdown && (
        <DropdownConnected autoAdjust={true}>
          {showSelectField && (
            <TaskSizeSelectFieldConnected
              taskId={taskId}
              onSizeClick={onSizeClick}
            />
          )}
          <TaskSizeFieldConnected
            className={`${showSelectField ? marginTopMediumStyles : ''}`}
            taskId={taskId}
            onUnsetClick={onUnsetClick}
            onSizeClick={onSizeClick}
          />

          {canOwn && (
            <div
              className={`${footerDarkStyles} ${paddingSmallStyles} ${flexSpaceBetweenStyles}`}
            >
              <div />
              <CustomLinkConnected
                withCurrentQuery={true}
                className={`${textSmallestStyles} ${textLinkStyles} ${displayBlockStyles}`}
                title="Configure sizes"
                route={TASK_SIZES_ROUTE}
              >
                Configure
              </CustomLinkConnected>
            </div>
          )}
        </DropdownConnected>
      )}
    </div>
  )
}
