import { connect } from 'react-redux'
import { UserAccountHeader } from './UserAccountHeader'
import { UserAccountHeaderMapState } from './UserAccountHeaderMapState'
import { UserAccountHeaderMapDispatch } from './UserAccountHeaderMapDispatch'
import { UserAccountHeaderMapStateReturnType } from './types/UserAccountHeaderMapStateReturnType'
import { UserAccountHeaderMapDispatchReturnType } from './types/UserAccountHeaderMapDispatchReturnType'
import { UserAccountHeaderConnectedProps } from './types/UserAccountHeaderConnectedProps'

export const UserAccountHeaderConnected = connect<
  UserAccountHeaderMapStateReturnType,
  UserAccountHeaderMapDispatchReturnType,
  UserAccountHeaderConnectedProps
>(
  UserAccountHeaderMapState,
  UserAccountHeaderMapDispatch
)(UserAccountHeader)
