import { take, put, select, spawn } from 'redux-saga/effects'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_TASK_SIZE, SUBMIT_TASK_SIZE_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitTaskSizeF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_SIZE])
    const { size, taskId } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const teamId = getCurrentTeamId({ state })

    if (taskId && teamId && currentUserId && currentUserDisplayName) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            size: size || null,
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items: items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskSizeF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_SIZE_DONE,
    })
  }
}
