import { CUSTOM_CLAIM_URL } from 'constants/urlConstants'
import { PatchCustomClaimSuccessPayload } from 'firebaseFunctions/customClaim/types'
import Axios, { AxiosPromise } from 'axios'
import { PatchCustomClaimApiProps } from './types/PatchCustomClaimApiProps'

export const patchCustomClaimApi = ({
  values,
  apiHeaders,
}: PatchCustomClaimApiProps): AxiosPromise<PatchCustomClaimSuccessPayload> => {
  return Axios.patch(CUSTOM_CLAIM_URL, values, {
    headers: apiHeaders,
  })
}
