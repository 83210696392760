import React, { useCallback, useState } from 'react'
import { MessagePushNotificationFieldProps } from './types/MessagePushNotificationFieldProps'
import { ToggleInput } from 'components/input/ToggleInput'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { getFirebaseMessagingToken } from '../../../utilities/get/getFirebaseMessagingToken'
import { Spinner } from '../../widget/Spinner'
import { marginSmallStyles } from '../../../styles/margin/marginSmallStyles'
import { marginBottomSmallStyles } from '../../../styles/margin/marginBottomSmallStyles'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const MessagePushNotificationField = ({
  isMessagingEnabled,
  isActivityEnabled,
  notificationPermission,
  toggle,
  setWindowMeta,
}: MessagePushNotificationFieldProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(false)

  const getToken = useCallback(async () => {
    if (notificationPermission === 'denied') {
      return null
    }

    if (notificationPermission === 'prompt') {
      setShowSpinner(true)
    }

    const firebaseMessagingToken = await getFirebaseMessagingToken({
      askForPermission: true,
    })

    setWindowMeta({
      firebaseMessagingToken,
    })

    setShowSpinner(false)

    return firebaseMessagingToken
  }, [notificationPermission, setWindowMeta])

  const enableMessagingCallback = useCallback(async () => {
    const token = await getToken()
    if (token) {
      toggle({
        token,
        pushNotificationSettings: { messagingEnabled: true },
      })
    }
  }, [toggle, getToken])

  const disableMessagingCallback = useCallback(async () => {
    const token = await getToken()
    if (token) {
      toggle({
        token,
        pushNotificationSettings: { messagingEnabled: false },
      })
    }
  }, [toggle, getToken])

  const enableActivityCallback = useCallback(async () => {
    const token = await getToken()
    if (token) {
      toggle({
        token,
        pushNotificationSettings: { activityEnabled: true },
      })
    }
  }, [toggle, getToken])

  const disableActivityCallback = useCallback(async () => {
    const token = await getToken()
    if (token) {
      toggle({
        token,
        pushNotificationSettings: { activityEnabled: false },
      })
    }
  }, [toggle, getToken])

  return (
    <div className={`MessagePushNotificationField`}>
      {showSpinner && <Spinner className={marginSmallStyles} />}
      <ul>
        <li>
          <h6 className={marginBottomSmallStyles}>Messaging</h6>
          <ToggleInput
            isEnabled={isMessagingEnabled}
            toggleOnClick={enableMessagingCallback}
            toggleOffClick={disableMessagingCallback}
          />
        </li>

        <li className={marginTopLargeStyles}>
          <h6 className={marginBottomSmallStyles}>Activity</h6>
          <ToggleInput
            isEnabled={isActivityEnabled}
            toggleOnClick={enableActivityCallback}
            toggleOffClick={disableActivityCallback}
          />
        </li>
      </ul>

      {notificationPermission === 'denied' && (
        <ErrorMessage className={marginTopMediumStyles}>
          Your browser is blocking notifications.
        </ErrorMessage>
      )}
    </div>
  )
}
