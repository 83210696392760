import { Dispatch, Action } from 'redux'
import { Team } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createGetTeamLogoUrlRequestAction } from 'actionCreators/get/GetTeamLogoUrl/createGetTeamLogoUrlRequestAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetTeamsSuccessAction } from 'actionCreators/get/GetTeams/createGetTeamsSuccessAction'
import { createGetTeamsFailureAction } from 'actionCreators/get/GetTeams/createGetTeamsFailureAction'
import { TeamWatcherMapDispatchReturnType } from './types/TeamWatcherMapDispatchReturnType'

export const TeamWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): TeamWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'TeamWatcherMapDispatch',
          increment,
          watcherType: 'team',
        })
      )
    },

    clearTeamScope: () => {
      dispatch({
        type: 'CLEAR_TEAM_SCOPE',
      })
    },

    onNext: (docSnapshot: firebase.firestore.DocumentSnapshot) => {
      const team = docSnapshot.data() as Team | undefined

      if (team) {
        dispatch(
          createGetTeamsSuccessAction({
            description: 'TeamWatcherMapDispatch',
            successValues: {
              items: {
                [docSnapshot.id]: {
                  data: team,
                },
              },
            },
          })
        )

        dispatch(
          createGetTeamLogoUrlRequestAction({
            requestValues: {
              teamId: docSnapshot.id,
            },
          })
        )
      }
    },

    onFirebaseError: (error: Error) => {
      dispatch(
        createFirebaseErrorAction({
          error: error as FirebaseError,
          description: 'TeamWatcherMapDispatch',
        })
      )

      dispatch(
        createGetTeamsFailureAction({
          description: 'TeamWatcherMapDispatch',
        })
      )
    },
  }
}
