import { connect } from 'react-redux'
import { ServerError } from './ServerError'
import { ServerErrorMapState } from './ServerErrorMapState'
import { ServerErrorMapDispatch } from './ServerErrorMapDispatch'

import { ServerErrorMapStateReturnType } from './types/ServerErrorMapStateReturnType'
import { ServerErrorMapDispatchReturnType } from './types/ServerErrorMapDispatchReturnType'
import { ServerErrorConnectedProps } from './types/ServerErrorConnectedProps'


export const ServerErrorConnected = connect<
  ServerErrorMapStateReturnType,
  ServerErrorMapDispatchReturnType,
  ServerErrorConnectedProps>(ServerErrorMapState,
    ServerErrorMapDispatch)(ServerError)
