import { connect } from 'react-redux'
import { TagDropdown } from './TagDropdown'
import { TagDropdownMapState } from './TagDropdownMapState'
import { TagDropdownMapDispatch } from './TagDropdownMapDispatch'

import { TagDropdownMapStateReturnType } from './types/TagDropdownMapStateReturnType'
import { TagDropdownMapDispatchReturnType } from './types/TagDropdownMapDispatchReturnType'
import { TagDropdownConnectedProps } from './types/TagDropdownConnectedProps'


export const TagDropdownConnected = connect<
  TagDropdownMapStateReturnType,
  TagDropdownMapDispatchReturnType,
  TagDropdownConnectedProps>(TagDropdownMapState,
    TagDropdownMapDispatch)(TagDropdown)
