import * as React from 'react'
import { UserExtensionWatcherProps } from './types/UserExtensionWatcherProps'
import { firestore } from 'sdks'
import { getUserExtensionRef } from '../../../firebaseFunctions/utilities/ref/get/getUserExtensionRef'

export class UserExtensionWatcher extends React.Component {
  props: UserExtensionWatcherProps
  unsubscribe: () => void

  componentWillUnmount() {
    this.unsubscribeAll()
  }

  unsubscribeAll() {
    if (this.unsubscribe) {
      this.unsubscribe()

      this.props.incrementWatcher(-1)
    }
  }

  public render(): null {
    this.unsubscribeAll()

    const {
      teamId,
      onNext,
      onFirebaseError,
      userId,
      incrementWatcher,
    } = this.props

    this.unsubscribe = getUserExtensionRef({
      firestore,
      teamId,
      userId,
    }).onSnapshot(onNext, onFirebaseError)

    incrementWatcher(1)

    return null
  }
}
