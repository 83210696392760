import { connect } from 'react-redux'
import { PlansWatcher } from './PlansWatcher'
import { PlansWatcherMapState } from './PlansWatcherMapState'
import { PlansWatcherMapDispatch } from './PlansWatcherMapDispatch'

import { PlansWatcherMapStateReturnType } from './types/PlansWatcherMapStateReturnType'
import { PlansWatcherMapDispatchReturnType } from './types/PlansWatcherMapDispatchReturnType'
import { PlansWatcherConnectedProps } from './types/PlansWatcherConnectedProps'


export const PlansWatcherConnected = connect<
  PlansWatcherMapStateReturnType,
  PlansWatcherMapDispatchReturnType,
  PlansWatcherConnectedProps>(PlansWatcherMapState,
    PlansWatcherMapDispatch)(PlansWatcher)
