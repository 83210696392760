import { css } from 'emotion'

const chevronWidth = 12
const diagonal = chevronWidth * Math.sqrt(2)

export const chevronContainerStyles = css`
  position: absolute;
  top: 32px;
  right: 6px;
  width: ${diagonal}px;
  height: ${diagonal/2}px;
  z-index: 101;
  overflow: hidden;

  .chevron {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, .2);
    width: ${chevronWidth}px;
    height: ${chevronWidth}px;
    background-color: #fff;
    transform: rotate(45deg);
    z-index: 2;
    top: 2px;
    right: 2px;
    border-right: none;
    border-bottom: none;
  }
`
