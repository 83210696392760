/**
 * Creates a composite decorator based on the provided plugins
 */

import React from 'react'
import { List } from 'immutable'
import { CompositeDecorator } from 'draft-js'

export const createCompositeDecorator = (
  decorators: any,
  getEditorState: any,
  setEditorState: any
) => {
  const convertedDecorators = List(decorators)
    .map((decorator: any) => {
      const Component = decorator.component

      const DecoratedComponent = (props: any) => (
        <Component
          {...props}
          getEditorState={getEditorState}
          setEditorState={setEditorState}
        />
      )

      return {
        ...decorator,
        component: DecoratedComponent,
      }
    })
    .toJS()

  return new CompositeDecorator(convertedDecorators)
}
