import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdatePasswordFormConnectedProps } from './types/UpdatePasswordFormConnectedProps'
import { UpdatePasswordFormMapStateReturnType } from './types/UpdatePasswordFormMapStateReturnType'

export const UpdatePasswordFormMapState = (
  _state: StateReducers,
  _props: UpdatePasswordFormConnectedProps
): UpdatePasswordFormMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
