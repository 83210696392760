import { UploadFilesCardStyles } from './UploadFilesCardStyles'
import React from 'react'
import { UploadFilesCardProps } from './types/UploadFilesCardProps'
import { Button } from 'components/button/Button'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'

export const UploadFilesCard = ({
  name,
  // size,
  deleteUploadFile,
}: UploadFilesCardProps): JSX.Element => {
  return (
    <div className={'UploadFilesCard ' + UploadFilesCardStyles}>
      <Button onClick={deleteUploadFile}>
        <TimesSolidIcon />
      </Button>

      {name}
    </div>
  )
}
