import { connect } from 'react-redux'
import { TaskPlanActionList } from './TaskPlanActionList'
import { TaskPlanActionListMapState } from './TaskPlanActionListMapState'
import { TaskPlanActionListMapDispatch } from './TaskPlanActionListMapDispatch'
import { TaskPlanActionListMapStateReturnType } from './types/TaskPlanActionListMapStateReturnType'
import { TaskPlanActionListMapDispatchReturnType } from './types/TaskPlanActionListMapDispatchReturnType'
import { TaskPlanActionListConnectedProps } from './types/TaskPlanActionListConnectedProps'

export const TaskPlanActionListConnected = connect<
  TaskPlanActionListMapStateReturnType,
  TaskPlanActionListMapDispatchReturnType,
  TaskPlanActionListConnectedProps>(TaskPlanActionListMapState,
    TaskPlanActionListMapDispatch)(TaskPlanActionList)
