import { INVITES_COLLECTION, USERS_COLLECTION } from '../../../constants'
import { FirestoreClient, UserInviteData } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  email: string
}

export const getUserInvitesRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  email,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.CollectionReference<UserInviteData>
  : FirebaseFirestore.CollectionReference => {
  return firestore
    .collection(USERS_COLLECTION)
    .doc(email)
    .collection(INVITES_COLLECTION) as any
}
