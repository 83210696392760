import { TEAMS_COLLECTION, TASKS_COLLECTION } from '../../../constants'
import { FirestoreClient, Task } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
}

export const getTasksRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.CollectionReference<Task>
  : FirebaseFirestore.CollectionReference => {
  return firestore
    .collection(TEAMS_COLLECTION)
    .doc(teamId)
    .collection(TASKS_COLLECTION) as any
}
