import { PATCH_TEAMS_REQUEST } from 'actions'
import { PatchTeamsRequestAction } from './types/PatchTeamsRequestAction'
import { PatchTeamsRequestActionValues } from './types/PatchTeamsRequestActionValues'

interface Props {
  requestValues: PatchTeamsRequestActionValues
}

export const createPatchTeamsRequestAction = ({ requestValues }: Props): PatchTeamsRequestAction => {
  return {
    type: PATCH_TEAMS_REQUEST,
    requestValues,
  }
}
