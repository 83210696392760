import { connect } from 'react-redux'
import { LabelDropdown } from './LabelDropdown'
import { LabelDropdownMapState } from './LabelDropdownMapState'
import { LabelDropdownMapDispatch } from './LabelDropdownMapDispatch'
import { LabelDropdownMapStateReturnType } from './types/LabelDropdownMapStateReturnType'
import { LabelDropdownMapDispatchReturnType } from './types/LabelDropdownMapDispatchReturnType'
import { LabelDropdownConnectedProps } from './types/LabelDropdownConnectedProps'

export const LabelDropdownConnected = connect<
  LabelDropdownMapStateReturnType,
  LabelDropdownMapDispatchReturnType,
  LabelDropdownConnectedProps>(LabelDropdownMapState,
    LabelDropdownMapDispatch)(LabelDropdown)
