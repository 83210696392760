import { css } from 'emotion'
import { TEXT_COLOR } from 'constants/styleConstants'

export const WorkingMessageListStyles = css`
  padding: 8px 8px;
  display: flex;
  flex-wrap: wrap;

  .DotDotDotWidget {
    color: ${TEXT_COLOR};
  }

  > li {
    padding: 8px;
  }
`
