import { getCurrentTaskId } from './../../../state/getters/getCurrentTaskId'
import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  DeleteTaskButtonConnectedProps,
  DeleteTaskButtonMapStateReturnType,
} from './types'

export const DeleteTaskButtonMapState = (
  state: StateReducers,
  props: DeleteTaskButtonConnectedProps
): DeleteTaskButtonMapStateReturnType => {
  const taskId = props.taskId || getCurrentTaskId({ state })

  return {
    taskId,
  }
}
