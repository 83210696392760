import { take, put } from 'redux-saga/effects'
// import { firebaseSdk } from 'sdks'

// import {
//   State,
//   selectState,
//   getCurrentTeamId,
//   getCurrentTask,
//   getCurrentTaskId,
//   getCurrentParams,
// } from 'state'

import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  SUBMIT_NEW_MESSAGE_FILES,
  SUBMIT_NEW_MESSAGE_FILES_DONE,
} from 'actions'

export const submitNewMessageFilesF = function*() {
  while (1) {
    yield take([SUBMIT_NEW_MESSAGE_FILES])

    yield put(
      createToggleAction({
        description: 'submitNewMessageFilesF',
        toggles: {
          showDropdown: false,
        },
      })
    )

    yield put({
      type: SUBMIT_NEW_MESSAGE_FILES_DONE,
    })
  }
}
