import { connect } from 'react-redux'
import { MessageFileList } from './MessageFileList'
import { MessageFileListMapState } from './MessageFileListMapState'
import { MessageFileListMapDispatch } from './MessageFileListMapDispatch'

import { MessageFileListMapStateReturnType } from './types/MessageFileListMapStateReturnType'
import { MessageFileListMapDispatchReturnType } from './types/MessageFileListMapDispatchReturnType'
import { MessageFileListConnectedProps } from './types/MessageFileListConnectedProps'


export const MessageFileListConnected = connect<
  MessageFileListMapStateReturnType,
  MessageFileListMapDispatchReturnType,
  MessageFileListConnectedProps>(MessageFileListMapState,
    MessageFileListMapDispatch)(MessageFileList)
