// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // SignOutButtonConnectedProps,
  SignOutButtonMapStateReturnType,
} from './types'

export const SignOutButtonMapState = (): // state: StateReducers,
// props: SignOutButtonConnectedProps,
SignOutButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
