import { take, put, select, race } from 'redux-saga/effects'

import {
  READING_MESSAGES,
  GET_USER_EXTENSION_SUCCESS,
  READING_MESSAGES_DONE,
} from 'actions'

import { StateReducers } from 'state/reducers/types/StateReducers'
import { getMessageNotifications } from 'state/getters/getMessageNotifications'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { selectState } from 'state/reducers/selectState'
import { createPatchUserExtensionRequestDelayAction } from 'actionCreators/patch/PatchUserExtension/createPatchUserExtensionRequestDelayAction'

export const readingMessagesF = function*() {
  while (1) {
    yield take([READING_MESSAGES])

    while (1) {
      const state: StateReducers = yield select(selectState)
      const taskId = getCurrentTaskId({ state })
      const userId = state.currentUserId
      const teamId = getCurrentTeamId({ state })

      const notifications = getMessageNotifications({
        state,
      })

      let needToPatch = false // important to prevent infinite loop

      const newNotifications = notifications.map((notification) => {
        const unreadCount = notification.unreadCount || 0

        if (notification.taskId === taskId && unreadCount > 0) {
          needToPatch = true

          return {
            ...notification,
            unreadCount: 0,
          }
        }

        return notification
      })

      if (needToPatch && userId && teamId) {
        yield put(
          createPatchUserExtensionRequestDelayAction({
            requestValues: {
              params: {
                teamId,
                userId,
              },
              item: {
                messageNotifications: newNotifications,
              },
            },
          })
        )
      }

      const result = yield race({
        moreNotifications: take(GET_USER_EXTENSION_SUCCESS),
        stop: take(READING_MESSAGES_DONE),
      })

      if (result.stop) {
        break
      }
    }
  }
}
