import { css } from 'emotion'
// import { PRIMARY_COLOR, PRIMARY_COLOR_DARK } from 'constants/styleConstants'

export const MessagingToolBarStyles = css`
  .actions {
    .Button {
      padding: 8px;
      font-size: 1.15em;
    }
  }
`
