import { DELETE_MESSAGES_FAILURE } from 'actions'
import { DeleteMessagesFailureAction } from './types/DeleteMessagesFailureAction'
import { DeleteMessagesRequestActionValues } from './types/DeleteMessagesRequestActionValues'

interface Props {
  requestValues: DeleteMessagesRequestActionValues
}

export const createDeleteMessagesFailureAction = ({
  requestValues,
}: Props): DeleteMessagesFailureAction => {
  return {
    type: DELETE_MESSAGES_FAILURE,
    requestValues,
  }
}
