import { StateReducers } from 'state/reducers/types/StateReducers'
import { MessagePushNotificationFieldConnectedProps } from './types/MessagePushNotificationFieldConnectedProps'
import { MessagePushNotificationFieldMapStateReturnType } from './types/MessagePushNotificationFieldMapStateReturnType'
import { getNotificationSettings } from '../../../state/getters/getNotificationSettings'

export const MessagePushNotificationFieldMapState = (
  state: StateReducers,
  _props: MessagePushNotificationFieldConnectedProps
): MessagePushNotificationFieldMapStateReturnType => {
  const notificationPermission = state.windowMeta?.notificationPermission

  const { isMessagingEnabled, isActivityEnabled } = getNotificationSettings({
    state,
  })

  return {
    isMessagingEnabled,
    isActivityEnabled,
    notificationPermission,
  }
}
