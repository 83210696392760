import { AccountSettingsMain } from 'components/main/AccountSettingsMain'
import { AdminMainConnected } from 'components/main/AdminMain'
import { InviteMainConnected } from 'components/main/InviteMain'
import { LoginFormConnected } from 'components/form/LoginForm/LoginFormConnected'
import { MemberMainConnected } from 'components/main/MemberMain'
import { OneLayoutProps } from './types/OneLayoutProps'
import { OneLayoutStyles } from './OneLayoutStyles'
import { PageSpinnerWidgetConnected } from 'components/widget/PageSpinnerWidget'
import { ResetPasswordSentMain } from 'components/main/ResetPasswordSentMain'
import { TaskMainConnected } from 'components/main/TaskMain'
import { TaskTagsMainConnected } from 'components/main/TaskTagsMain'
import { TeamSettingsMainConnected } from 'components/main/TeamSettingsMain'
import { TaskStatusesMain } from 'components/main/TaskStatusesMain'
import { MembersMain } from 'components/main/MembersMain'
import { LabelsMain } from 'components/main/LabelsMain'
import { NotFoundMain } from 'components/main/NotFoundMain'
import * as React from 'react'
import { NotificationListConnected } from 'components/list/NotificationList'
import { BigHeaderConnected } from 'components/header/BigHeader'
import { LogoWidget } from 'components/widget/LogoWidget'
import { GoBackLinkConnected } from 'components/link/GoBackLink'
import { UploadedFilesMainConnected } from 'components/main/UploadedFilesMain'
import { DeleteWorkspaceMainConnected } from 'components/main/DeleteWorkspaceMain'
import { NotificationsSettingsMainConnected } from 'components/main/NotificationsSettingsMain'
import { UpgradeWorkspaceMainConnected } from 'components/main/UpgradeWorkspaceMain'
import { BillingMainConnected } from 'components/main/BillingMain'
import { WelcomeToProMain } from 'components/main/WelcomeToProMain'
import { WorkspaceSubscriptionMainConnected } from 'components/main/WorkspaceSubscriptionMain'
import { ProCanceledMainConnected } from 'components/main/ProCanceledMain/ProCanceledMainConnected'
import { NewTaskFormConnected } from 'components/form/NewTaskForm/NewTaskFormConnected'
import { NewUserFormConnected } from 'components/form/NewUserForm/NewUserFormConnected'
import { ResetPasswordFormConnected } from 'components/form/ResetPasswordForm/ResetPasswordFormConnected'
import { NewInviteFormConnected } from 'components/form/NewInviteForm/NewInviteFormConnected'
import { UpdateTaskStatusesFormConnected } from 'components/form/UpdateTaskStatusesForm/UpdateTaskStatusesFormConnected'
import { UpdateTaskSizesFormConnected } from 'components/form/UpdateTaskSizesForm/UpdateTaskSizesFormConnected'
import { TaskSizesMain } from 'components/main/TaskSizesMain'
import { NewLabelFormConnected } from 'components/form/NewLabelForm/NewLabelFormConnected'
import { UpdatePasswordFormConnected } from 'components/form/UpdatePasswordForm/UpdatePasswordFormConnected'
import { UpdateUserDisplayNameFormConnected } from 'components/form/UpdateUserDisplayNameForm/UpdateUserDisplayNameFormConnected'
import { UpdateAvatarFormConnected } from 'components/form/UpdateAvatarForm/UpdateAvatarFormConnected'
import { ReportMainConnected } from 'components/main/ReportMain/ReportMainConnected'
import { UpdateTeamNameFormConnected } from 'components/form/UpdateTeamNameForm/UpdateTeamNameFormConnected'
import { UpdateWorkspaceLogoMainConnected } from '../../main/UpdateWorkspaceLogoMain/UpdateWorkspaceLogoMainConnected'
import { PaymentFailedMainConnected } from '../../main/PaymentFailedMain/PaymentFailedMainConnected'

export const OneLayout = ({
  inviteId,
  isActivityNotificationRoute,
  isBigScreen,
  isMediumScreen,
  isTeamFilesRoute,
  isAccountSettingsRoute,
  isAdminRoute,
  isUsingBigHeader,
  isCreateTaskRoute,
  isCreateUserRoute,
  isInviteDetailRoute,
  isLabelsRoute,
  isLoginRoute,
  isMemberDetailRoute,
  isMembersRoute,
  isAddNewLabelRoute,
  isAddNewMemberRoute,
  isNotFoundRoute,
  isResetPasswordRoute,
  isResetPasswordSentRoute,
  isTaskMainRoute,
  isTaskSizesRoute,
  isTaskStatusesRoute,
  isTaskTagsRoute,
  isTeamSettingsRoute,
  isUpdateDisplayNameRoute,
  isUpdatePasswordRoute,
  isUpdateTaskSizesRoute,
  isUpdateTaskStatusesRoute,
  isDeleteWorkspaceRoute,
  isNotificationSettingsRoute,
  isUpgradeWorkspaceRoute,
  isBillingRoute,
  isWelcomeToProRoute,
  isWorkspaceSubscriptionRoute,
  isProCanceledRoute,
  isUpdateAvatarRoute,
  isReportRoute,
  isUpdateTeamNameRoute,
  isUpdateWorkspaceLogoRoute,
  isPaymentFailedRoute,
}: OneLayoutProps) => {
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''
  const isBigScreenClassName = isBigScreen ? ' isBigScreen' : ''

  const getLogoWidgetRoute = () => {
    if (isLoginRoute || isCreateUserRoute || isResetPasswordSentRoute) {
      return '/'
    }

    return undefined
  }

  return (
    <div
      className={
        'OneLayout ' +
        OneLayoutStyles +
        isMediumScreenClassName +
        isBigScreenClassName
      }
    >
      <PageSpinnerWidgetConnected />
      {isUsingBigHeader && <BigHeaderConnected />}

      {!isUsingBigHeader && (
        <header className="plainHeader">
          <LogoWidget showName={true} route={getLogoWidgetRoute()} />

          <GoBackLinkConnected />
        </header>
      )}

      <main>
        {isNotFoundRoute && <NotFoundMain />}
        {isLoginRoute && <LoginFormConnected />}
        {isCreateUserRoute && <NewUserFormConnected />}
        {isCreateTaskRoute && <NewTaskFormConnected />}
        {isUpdateTaskStatusesRoute && <UpdateTaskStatusesFormConnected />}
        {isTaskMainRoute && <TaskMainConnected />}
        {isTeamSettingsRoute && <TeamSettingsMainConnected />}
        {isAccountSettingsRoute && <AccountSettingsMain />}
        {isAdminRoute && <AdminMainConnected />}
        {isAddNewMemberRoute && <NewInviteFormConnected />}
        {isInviteDetailRoute && inviteId && (
          <InviteMainConnected inviteId={inviteId} />
        )}
        {isMemberDetailRoute && <MemberMainConnected />}
        {isTaskTagsRoute && <TaskTagsMainConnected />}
        {isResetPasswordRoute && <ResetPasswordFormConnected />}
        {isResetPasswordSentRoute && <ResetPasswordSentMain />}
        {isUpdateDisplayNameRoute && <UpdateUserDisplayNameFormConnected />}
        {isUpdatePasswordRoute && <UpdatePasswordFormConnected />}
        {isAddNewLabelRoute && <NewLabelFormConnected />}
        {isTaskStatusesRoute && <TaskStatusesMain />}
        {isMembersRoute && <MembersMain />}
        {isLabelsRoute && <LabelsMain />}
        {isTaskSizesRoute && <TaskSizesMain />}
        {isUpdateTaskSizesRoute && <UpdateTaskSizesFormConnected />}
        {isTeamFilesRoute && <UploadedFilesMainConnected />}
        {isActivityNotificationRoute && (
          <NotificationListConnected notificationGroup="activities" />
        )}
        {isNotificationSettingsRoute && <NotificationsSettingsMainConnected />}
        {isDeleteWorkspaceRoute && <DeleteWorkspaceMainConnected />}
        {isUpgradeWorkspaceRoute && <UpgradeWorkspaceMainConnected />}
        {isBillingRoute && <BillingMainConnected />}
        {isWelcomeToProRoute && <WelcomeToProMain />}
        {isWorkspaceSubscriptionRoute && <WorkspaceSubscriptionMainConnected />}
        {isProCanceledRoute && <ProCanceledMainConnected />}
        {isReportRoute && <ReportMainConnected />}
        {isUpdateAvatarRoute && <UpdateAvatarFormConnected />}
        {isUpdateTeamNameRoute && <UpdateTeamNameFormConnected />}
        {isUpdateWorkspaceLogoRoute && <UpdateWorkspaceLogoMainConnected />}
        {isPaymentFailedRoute && <PaymentFailedMainConnected />}
      </main>
    </div>
  )
}
