import { StateReducers } from '../reducers/types/StateReducers'
import { getSortedPlanIds } from './getSortedPlanIds'

interface Props {
  state: StateReducers
}

interface Return {
  previousPlanId?: string
  currentPlanId?: string
  nextPlanId?: string
}

export const getCyclePlanIds = ({ state }: Props): Return => {
  const openPlanIds = getSortedPlanIds({ state, status: 'open' })
  const closedPlanIds = getSortedPlanIds({ state, status: 'closed' })

  const latestClosedPlanId = closedPlanIds.length
    ? closedPlanIds[closedPlanIds.length - 1]
    : undefined

  return {
    previousPlanId: latestClosedPlanId,
    currentPlanId: openPlanIds[0],
    nextPlanId: openPlanIds[1],
  }
}
