import { MessageActionDropdownStyles } from './MessageActionDropdownStyles'
import React from 'react'
import { MessageActionDropdownProps } from './types/MessageActionDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { MessageActionListConnected } from 'components/list/MessageActionList'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { CheveronDownIcon } from 'components/icon/CheveronDownIcon/CheveronDownIcon'

export const MessageActionDropdown = ({
  showMessageActionDropdown,
  messageId,
  replyClick,
}: MessageActionDropdownProps): JSX.Element => {
  return (
    <div
      className={
        `MessageActionDropdown _${messageId} ` + MessageActionDropdownStyles
      }
    >
      <DropdownButtonConnected
        dropdownType="messageActions"
        title="View more options"
        watchSelector={`.MessageActionDropdown._${messageId}`}
        id={messageId}
      >
        <CheveronDownIcon />
      </DropdownButtonConnected>

      {showMessageActionDropdown && (
        <DropdownConnected>
          <MessageActionListConnected
            messageId={messageId}
            replyClick={replyClick}
          />
        </DropdownConnected>
      )}
    </div>
  )
}
