import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { SUBMIT_UPDATE_MESSAGE } from 'actions'

import { UpdateMessageEditorConnectedProps } from './types/UpdateMessageEditorConnectedProps'
import { UpdateMessageEditorMapDispatchReturnType } from './types/UpdateMessageEditorMapDispatchReturnType'

import { createSubmitEditorAction } from 'actionCreators/submit/SubmitEditor'

export const UpdateMessageEditorMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UpdateMessageEditorConnectedProps
): UpdateMessageEditorMapDispatchReturnType => {
  return {
    save: (markdown: string) => {
      const submitUpdateMessageAction = createSubmitEditorAction({
        values: { markdown, id: props.messageId },
        type: SUBMIT_UPDATE_MESSAGE,
      })

      dispatch(submitUpdateMessageAction)

      const toggleAction = createToggleAction({
        description: 'UpdateMessageEditorMapDispatch',
        toggles: {
          showModal: false,
        },
      })

      dispatch(toggleAction)
    },
  }
}
