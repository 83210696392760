// import { CaretSquareLeftSolidIconStyles } from './CaretSquareLeftSolidIconStyles'
import React from 'react'
import { CaretSquareLeftSolidIconProps } from './types/CaretSquareLeftSolidIconProps'
import { iconStyles } from 'styles/iconStyles'

export const CaretSquareLeftSolidIcon = ({
  className = '',
}: CaretSquareLeftSolidIconProps): JSX.Element => {
  return (
    <svg
      className={`CaretSquareLeftSolidIcon ${className} ${iconStyles}`}
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM259.515 124.485l-123.03 123.03c-4.686 4.686-4.686 12.284 0 16.971l123.029 123.029c7.56 7.56 20.485 2.206 20.485-8.485V132.971c.001-10.691-12.925-16.045-20.484-8.486z"
      ></path>
    </svg>
  )
}
