import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_TASK_SUMMARY, SUBMIT_TASK_SUMMARY_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitTaskSummaryF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_SUMMARY])
    const { summary } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const query = getCurrentQuery({ state })
    const taskId = query && query.taskId
    const teamId = getCurrentTeamId({ state })

    if (
      taskId &&
      teamId &&
      currentUserId &&
      currentUserDisplayName &&
      summary
    ) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            workingOnSummary: null,
            summary,
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items: items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskSummaryF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_SUMMARY_DONE,
    })
  }
}
