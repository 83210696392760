import { DESCRIPTION_MAX_LENGTH } from 'constants/environmentConstants'

export const isInvalidTaskSummary = (summary?: string): false | string => {
  const trimmed = (summary || '').trim()

  if (!trimmed) {
    return 'Please enter a summary.'
  }

  if (trimmed.length > DESCRIPTION_MAX_LENGTH) {
    return `Max length is ${DESCRIPTION_MAX_LENGTH} characters.`
  }

  return false
}
