import { connect } from 'react-redux'
import { InviteMain } from './InviteMain'
import { InviteMainMapState } from './InviteMainMapState'
import { InviteMainMapDispatch } from './InviteMainMapDispatch'

import { InviteMainMapStateReturnType } from './types/InviteMainMapStateReturnType'
import { InviteMainMapDispatchReturnType } from './types/InviteMainMapDispatchReturnType'
import { InviteMainConnectedProps } from './types/InviteMainConnectedProps'


export const InviteMainConnected = connect<
  InviteMainMapStateReturnType,
  InviteMainMapDispatchReturnType,
  InviteMainConnectedProps>(InviteMainMapState,
    InviteMainMapDispatch)(InviteMain)
