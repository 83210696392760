import { connect } from 'react-redux'
import { Avatar } from './Avatar'
import { AvatarMapState } from './AvatarMapState'
import { AvatarMapDispatch } from './AvatarMapDispatch'

import { AvatarMapStateReturnType } from './types/AvatarMapStateReturnType'
import { AvatarMapDispatchReturnType } from './types/AvatarMapDispatchReturnType'
import { AvatarConnectedProps } from './types/AvatarConnectedProps'


export const AvatarConnected = connect<
  AvatarMapStateReturnType,
  AvatarMapDispatchReturnType,
  AvatarConnectedProps>(AvatarMapState,
    AvatarMapDispatch)(Avatar)
