import { DELETE_TASKS_SUCCESS } from 'actions'
import { DeleteTasksSuccessActionValues } from './types/DeleteTasksSuccessActionValues'
import { DeleteTasksRequestActionValues } from './types/DeleteTasksRequestActionValues'
import { DeleteTasksSuccessAction } from './types/DeleteTasksSuccessAction'

interface Props {
  successValues: DeleteTasksSuccessActionValues
  requestValues?: DeleteTasksRequestActionValues
  description: string
}

export const createDeleteTasksSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): DeleteTasksSuccessAction => {
  return {
    type: DELETE_TASKS_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
