import { PATCH_UNSCOPE_USER_SUCCESS } from 'actions'

import { PatchUnscopeUserSuccessAction } from './types/PatchUnscopeUserSuccessAction'
import { PatchUnscopeUserSuccessActionValues } from './types/PatchUnscopeUserSuccessActionValues'
import { PatchUnscopeUserRequestActionValues } from './types/PatchUnscopeUserRequestActionValues'


interface Props {
  requestValues: PatchUnscopeUserRequestActionValues
  successValues: PatchUnscopeUserSuccessActionValues
}

export const createPatchUnscopeUserSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchUnscopeUserSuccessAction => {
  return {
    type: PATCH_UNSCOPE_USER_SUCCESS,
    requestValues,
    successValues,
  }
}
