import { css } from 'emotion'
// import { NEUTRAL_BORDER_COLOR, BORDER_RADIUS } from 'constants/styleConstants'

export const TeamDropdownStyles = css`
  position: relative;

  .DropdownButton {
    display: inline-block;
    padding: 4px 8px;
    margin-right: 8px;
  }

  .Dropdown {
    .chevronContainer {
      right: 10px;
      top: 30px;
    }

    .container {
      width: 160px;
      right: 6px;
      top: 37px;
    }
  }
`
