import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR, NEUTRAL_HIGH_LIGHT_COLOR } from 'constants/styleConstants'

export const HistoryListStyles = css`
  max-height: 500px;
  overflow-y: auto;

  > li {
    border-top: 1px solid ${NEUTRAL_BORDER_COLOR};

    &:hover {
      background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
    }

    &:first-child {
      border-top: none;
    }

    &.empty {
      padding: 16px;
    }
  }

  .header {
    padding: 16px;
    font-size: 0.75em;
    font-weight: bold;
  }
`
