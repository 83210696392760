import { DELETE_USER_INVITES_REQUEST } from 'actions'
import { DeleteUserInvitesRequestActionValues } from './types/DeleteUserInvitesRequestActionValues'
import { DeleteUserInvitesRequestAction } from './types/DeleteUserInvitesRequestAction'

interface Props {
  requestValues: DeleteUserInvitesRequestActionValues
}

export const createDeleteUserInvitesRequestAction = ({
  requestValues,
}: Props): DeleteUserInvitesRequestAction => {
  return {
    type: DELETE_USER_INVITES_REQUEST,
    requestValues,
  }
}
