import { StateReducers } from 'state/reducers/types/StateReducers'
import { forEach } from 'lodash'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import {
  // DraftEditorConnectedProps,
  DraftEditorMapStateReturnType,
} from './types'
import { getUserRoles } from 'state/getters/getUserRoles'

export const DraftEditorMapState = (
  state: StateReducers
  // props: DraftEditorConnectedProps,
): DraftEditorMapStateReturnType => {
  const userRoles = getUserRoles({ state })
  const mentionIdsMutable: string[] = []
  const mentionNamesMutable: string[] = []
  const mentionAvatarsMutable: string[] = []
  const isAndroid = state.windowMeta?.isAndroid

  forEach(userRoles, ({ displayName, photoURL }, id) => {
    mentionIdsMutable.push(id)
    mentionNamesMutable.push(displayName || '(no name)')
    mentionAvatarsMutable.push(photoURL || '')
  })

  return {
    isAndroid,
    mentionIds: memoizeFlatArray(
      mentionIdsMutable,
      'DraftEditorMapState-mentionIds'
    ),
    mentionNames: memoizeFlatArray(
      mentionNamesMutable,
      'DraftEditorMapState-mentionNames'
    ),
    mentionAvatars: memoizeFlatArray(
      mentionAvatarsMutable,
      'DraftEditorMapState-mentionAvatars'
    ),
  }
}
