import { DELETE_MESSAGES_REQUEST } from 'actions'
import { DeleteMessagesRequestActionValues } from './types/DeleteMessagesRequestActionValues'
import { DeleteMessagesRequestAction } from './types/DeleteMessagesRequestAction'

interface Props {
  requestValues: DeleteMessagesRequestActionValues
}

export const createDeleteMessagesRequestAction = ({
  requestValues,
}: Props): DeleteMessagesRequestAction => {
  return {
    type: DELETE_MESSAGES_REQUEST,
    requestValues,
  }
}
