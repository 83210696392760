import { take, put, select, race } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { ACCOUNT_SETTINGS_ROUTE } from 'routes'
import { firebaseSdk } from 'sdks'
import { Race } from 'types/Race'
import { FirebaseError } from 'firebase'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'

import {
  SUBMIT_UPDATE_PASSWORD,
  SUBMIT_UPDATE_PASSWORD_DONE,
  POST_RE_LOGIN_SUCCESS,
  POST_RE_LOGIN_FAILURE,
  POST_RE_LOGIN_CANCEL,
} from 'actions'
import { createPostReLoginRequestAction } from 'actionCreators/post/PostReLogin/createPostReLoginRequestAction'
import { PostReLoginSuccessAction } from 'actionCreators/post/PostReLogin/types/PostReLoginSuccessAction'
import { PostReLoginFailureAction } from 'actionCreators/post/PostReLogin/types/PostReLoginFailureAction'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitUpdatePasswordF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_PASSWORD])
    const { password, newPassword } = action.values
    const user = firebaseSdk.auth().currentUser
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })

    if (password && user && newPassword) {
      yield put(
        createPostReLoginRequestAction({
          requestValues: {
            password,
          },
        })
      )

      const postResult: Race<
        PostReLoginSuccessAction,
        PostReLoginFailureAction,
        any
      > = yield race({
        success: take(POST_RE_LOGIN_SUCCESS),
        failure: take(POST_RE_LOGIN_FAILURE),
        cancel: take(POST_RE_LOGIN_CANCEL),
      })

      if (postResult.success) {
        try {
          yield user.updatePassword(newPassword)

          yield put(
            createHistoryAction({
              description: 'submitUpdatePasswordF',
              pathname: ACCOUNT_SETTINGS_ROUTE,
              query: {
                teamId,
              },
            })
          )
        } catch (error) {
          yield put(
            createFirebaseErrorAction({
              error: error as FirebaseError,
              description: 'submitUpdatePasswordF',
            })
          )
        }
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdatePasswordF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_PASSWORD_DONE,
    })
  }
}
