import { connect } from 'react-redux'
import { HistoryList } from './HistoryList'
import { HistoryListMapState } from './HistoryListMapState'
import { HistoryListMapDispatch } from './HistoryListMapDispatch'

import { HistoryListMapStateReturnType } from './types/HistoryListMapStateReturnType'
import { HistoryListMapDispatchReturnType } from './types/HistoryListMapDispatchReturnType'
import { HistoryListConnectedProps } from './types/HistoryListConnectedProps'


export const HistoryListConnected = connect<
  HistoryListMapStateReturnType,
  HistoryListMapDispatchReturnType,
  HistoryListConnectedProps>(HistoryListMapState,
    HistoryListMapDispatch)(HistoryList)
