import { take, put, select } from 'redux-saga/effects'
import { GET_TEAMS_SUCCESS } from 'actions'
import { auth } from 'sdks'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { CustomClaim } from 'firebaseFunctions/types'
import { createGetCustomClaimSuccessAction } from 'actionCreators/get/GetCustomClaim/createGetCustomClaimSuccessAction'
import { patchCustomClaimSaga } from 'flows/sagas/patch/patchCustomClaimSaga'

export const getTeamsSuccessF = function*() {
  while (1) {
    yield take([GET_TEAMS_SUCCESS])
    const state: StateReducers = yield select(selectState)
    const currentTeam = getCurrentTeam({ state })
    const currentTeamId = getCurrentTeamId({ state })
    const currentUser = auth.currentUser
    const currentUserId = state.currentUserId

    if (currentTeam && currentUser && currentTeamId && currentUserId) {
      const userRoles = currentTeam && currentTeam.userRoles
      const userRole = userRoles && userRoles[currentUserId]
      const role = userRole && userRole.role
      const tokenResult = yield currentUser.getIdTokenResult()
      const customClaim: CustomClaim = tokenResult.claims
      const customClaimRole = customClaim.roles?.[currentTeamId]

      if (customClaimRole === role) {
        yield put(
          createGetCustomClaimSuccessAction({
            requestValues: {},
            successValues: {
              item: customClaim,
            },
          })
        )
      } else if (userRole) {
        yield patchCustomClaimSaga({
          currentUser,
          values: {
            teamId: currentTeamId,
          },
          apiHeaders: { token: tokenResult.token },
        })
      }
    }
  }
}
