import { NEW_PUSH_NOTIFICATION } from 'actions'
import { PushNotification } from 'firebaseFunctions/types'
import { NewPushNotificationAction } from './types/NewPushNotificationAction'

interface Props {
  pushNotification: PushNotification
}

export const createNewPushNotificationAction = ({
  pushNotification,
}: Props): NewPushNotificationAction => {
  return {
    type: NEW_PUSH_NOTIFICATION,
    pushNotification,
  }
}
