import { MessagesMainStyles } from './MessagesMainStyles'
import React, { useState, useEffect, useRef } from 'react'
import { MessagesMainProps } from './types/MessagesMainProps'
import { MessageListConnected } from 'components/list/MessageList'
import { NewMessageEditorConnected } from 'components/editor/NewMessageEditor'
import { ActionButton } from 'components/button/ActionButton'
import { MessagesWatcherConnected } from 'components/watcher/MessagesWatcher'
import { WorkingMessageListConnected } from 'components/list/WorkingMessageList'
import { ArrowDownIcon } from 'components/icon/ArrowDownIcon/ArrowDownIcon'

export const MessagesMain = ({
  taskId,
  teamId,
  canWrite,
}: MessagesMainProps): JSX.Element => {
  const [unreadCount, setUnreadCount] = useState(0)
  const [page, setPage] = useState(0)
  const [reachedMaxPage, setReachedMaxPage] = useState(false)
  const [fetchingMessages, setFetchingMessages] = useState(false)
  const [replyToMessageId, setReplyToMessageId] = useState<null | string>(null)

  const messageRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    setPage(0)
    setReachedMaxPage(false)
  }, [taskId, teamId, setPage, setReachedMaxPage])

  return (
    <div className={'MessagesMain ' + MessagesMainStyles}>
      {teamId && taskId && (
        <MessagesWatcherConnected
          teamId={teamId}
          taskId={taskId}
          page={page}
          setReachedMaxPage={setReachedMaxPage}
          setFetchingMessages={setFetchingMessages}
        />
      )}

      <MessageListConnected
        messageRef={messageRef}
        setReplyToMessageId={setReplyToMessageId}
        taskId={taskId}
        setUnreadCount={setUnreadCount}
        fetchingMessages={fetchingMessages}
        showMoreButton={!reachedMaxPage}
        showMoreOnClick={() => {
          setPage(page + 1)
        }}
      />

      <WorkingMessageListConnected />

      <footer>
        {unreadCount > 0 && (
          <ActionButton
            title="Scroll down"
            className="unreadCount"
            onClick={() => {
              const element = messageRef.current

              if (element) {
                element.scrollTop = element.scrollHeight
              }

              setUnreadCount(0)
            }}
          >
            <span>
              {unreadCount} new {unreadCount > 1 ? 'messages' : 'message'}
            </span>

            <ArrowDownIcon />
          </ActionButton>
        )}

        {canWrite && (
          <NewMessageEditorConnected
            setReplyToMessageId={setReplyToMessageId}
            replyToMessageId={replyToMessageId || undefined}
          />
        )}
      </footer>
    </div>
  )
}
