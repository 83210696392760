import { connect } from 'react-redux'
import { ConfirmEmailMain } from './ConfirmEmailMain'
import { ConfirmEmailMainMapState } from './ConfirmEmailMainMapState'
import { ConfirmEmailMainMapDispatch } from './ConfirmEmailMainMapDispatch'

import { ConfirmEmailMainMapStateReturnType } from './types/ConfirmEmailMainMapStateReturnType'
import { ConfirmEmailMainMapDispatchReturnType } from './types/ConfirmEmailMainMapDispatchReturnType'
import { ConfirmEmailMainConnectedProps } from './types/ConfirmEmailMainConnectedProps'


export const ConfirmEmailMainConnected = connect<
  ConfirmEmailMainMapStateReturnType,
  ConfirmEmailMainMapDispatchReturnType,
  ConfirmEmailMainConnectedProps>(ConfirmEmailMainMapState,
    ConfirmEmailMainMapDispatch)(ConfirmEmailMain)
