import { UpdateTeamNameFormStyles } from './UpdateTeamNameFormStyles'
import React, { useState, useCallback, useEffect } from 'react'
import { UpdateTeamNameFormProps } from './types/UpdateTeamNameFormProps'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { TEAM_NOUN } from 'constants/english'
import { isInvalidTeamName } from 'firebaseFunctions/utilities/is/isInvalidTeamName'
import { FormFooter } from 'components/footer/FormFooter'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'

export const UpdateTeamNameForm = ({
  submit,
  displayName,
  showBorder,
}: UpdateTeamNameFormProps): JSX.Element => {
  const [displayNameState, setDisplayNameState] = useState(displayName || '')
  const [errorState, setErrorState] = useState<string | null>(null)

  useEffect(() => {
    setDisplayNameState(displayName || '')
  }, [displayName])

  const validateDisplayName = useCallback(() => {
    const error = isInvalidTeamName(displayNameState)

    setErrorState(error || null)

    return error
  }, [displayNameState])

  const showBorderClassname = showBorder ? 'showBorder' : ''
  const hasErrorClassname = errorState ? 'hasError' : ''

  return (
    <form
      className={`UpdateTeamNameForm ${UpdateTeamNameFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()

        const error = validateDisplayName()

        if (!error) {
          submit({ displayName: displayNameState })
        }
      }}
    >
      <HeaderOneTextConnected>Update {TEAM_NOUN} name</HeaderOneTextConnected>

      <TextInputField
        type="textarea"
        value={displayNameState}
        error={errorState}
        onChange={(e) => {
          setDisplayNameState(e.target.value)
        }}
        className={`${hasErrorClassname} ${showBorderClassname}`}
        onFocus={() => {
          setErrorState(null)
        }}
      />

      <FormFooter label="save" />
    </form>
  )
}
