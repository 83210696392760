import { css } from 'emotion'
import {
  FONT_WEIGHT_BOLD,
  TEXT_COLOR_LIGHT,
  NEUTRAL_BORDER_COLOR,
} from 'constants/styleConstants'

export const ReplyMessageCardStyles = css`
  font-size: 0.8em;
  padding: 8px;
  position: relative;

  > header {
    margin-bottom: 8px;
    color: ${TEXT_COLOR_LIGHT};

    .displayName {
      font-weight: ${FONT_WEIGHT_BOLD};
    }
  }

  .MarkdownDiv {
    max-height: 50px;
    overflow: scroll;

    padding-left: 8px;
    border-left: 2px solid ${NEUTRAL_BORDER_COLOR};
  }

  .close {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 8px;
  }
`
