import React, { useEffect, useCallback } from 'react'
import { ToastListWatcherProps } from './types/ToastListWatcherProps'
import { ToastListWatcherStyles } from './ToastListWatcherStyles'
import { CheckIcon } from '../../icon/CheckIcon/CheckIcon'
import { IconAndDiv } from '../../div/IconAndDiv/IconAndDiv'
import { TimesSolidIcon } from '../../icon/TimesSolidIcon/TimesSolidIcon'

export const ToastListWatcher = ({
  toastMessage,
  className = '',
  type,
  unsetAlertMessage,
}: ToastListWatcherProps): JSX.Element => {
  const unsetAlertMessageCallback = useCallback(() => {
    unsetAlertMessage()
  }, [unsetAlertMessage])

  useEffect(() => {
    if (toastMessage) {
      const timeoutRef = setTimeout(() => {
        unsetAlertMessageCallback()
      }, 3000)

      return () => {
        clearTimeout(timeoutRef)
      }
    }

    return
  }, [toastMessage, unsetAlertMessageCallback])

  const icon = type === 'success' ? <CheckIcon /> : <TimesSolidIcon />

  return (
    <div
      style={{ left: toastMessage ? '0px' : '-200px' }}
      className={`ToastListWatcher ${className} ${ToastListWatcherStyles} ${type}`}
    >
      <IconAndDiv icon={icon}>{toastMessage}</IconAndDiv>
    </div>
  )
}
