import { DropdownStyles } from './DropdownStyles'
import { chevronContainerStyles } from './chevronContainerStyles'
import { containerStyles } from './containerStyles'
import React, { useEffect, RefObject } from 'react'
import { DropdownProps } from './types/DropdownProps'
import { autoAdjustDropdown } from './autoAdjustDropdown'
import { autoScrollDropdown } from './autoScrollDropdown'

export const Dropdown = ({
  children,
  autoAdjust = true,
  isMediumScreen,
  autoScroll,
}: DropdownProps): JSX.Element | null => {
  const containerRef: RefObject<HTMLDivElement> = React.createRef<
    HTMLDivElement
  >()

  useEffect(() => {
    const element = containerRef?.current

    const useAutoScroll =
      autoScroll || (autoScroll === undefined && !isMediumScreen)

    if (useAutoScroll && element) {
      autoScrollDropdown({ element, toTop: autoScroll === 'top' })
    }
  }, [isMediumScreen, containerRef, autoScroll])

  useEffect(() => {
    const element = containerRef?.current

    if (autoAdjust && element) {
      autoAdjustDropdown(element, isMediumScreen)
    }
  }, [isMediumScreen, containerRef, autoAdjust])

  const isMediumScreenClassname = isMediumScreen ? ' isMediumScreen' : ''

  return (
    <div className={'Dropdown ' + DropdownStyles + isMediumScreenClassname}>
      <div className={'chevronContainer ' + chevronContainerStyles}>
        <div className="chevron" />
      </div>

      <div className={'container ' + containerStyles} ref={containerRef}>
        {children}
      </div>
    </div>
  )
}
