import { StateReducers } from 'state/reducers/types/StateReducers'
import { getDashboardRoute } from 'state/getters/getDashboardRoute'

import {
  // TeamNameLinkConnectedProps,
  TeamNameLinkMapStateReturnType,
} from './types'

export const TeamNameLinkMapState = (
  state: StateReducers
  // props: TeamNameLinkConnectedProps,
): TeamNameLinkMapStateReturnType => {
  const dashboardRoute = getDashboardRoute({ state })

  return {
    dashboardRoute,
  }
}
