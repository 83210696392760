import { POST_USER_REQUEST } from 'actions'
import { PostUserRequestAction } from './types/PostUserRequestAction'
import { PostUserRequestActionValues } from './types/PostUserRequestActionValues'

interface Props {
  requestValues: PostUserRequestActionValues,
}

export const createPostUserRequestAction = ({ requestValues }: Props): PostUserRequestAction => {
  return {
    type: POST_USER_REQUEST,
    requestValues,
  }
}
