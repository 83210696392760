import { GET_CUSTOM_CLAIM_SUCCESS } from 'actions'

import { GetCustomClaimSuccessAction } from './types/GetCustomClaimSuccessAction'
import { GetCustomClaimSuccessActionValues } from './types/GetCustomClaimSuccessActionValues'
import { GetCustomClaimRequestActionValues } from './types/GetCustomClaimRequestActionValues'


interface Props {
  successValues: GetCustomClaimSuccessActionValues
  requestValues: GetCustomClaimRequestActionValues
}

export const createGetCustomClaimSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetCustomClaimSuccessAction => {
  return {
    type: GET_CUSTOM_CLAIM_SUCCESS,
    requestValues,
    successValues,
  }
}
