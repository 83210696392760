import { StateReducers } from 'state/reducers/types/StateReducers'
import { RoleTypes } from 'firebaseFunctions/types'
import { getCustomClaimTeamRole } from './getCustomClaimTeamRole'

interface Props {
  state: StateReducers
}

export const getCanWrite = ({ state }: Props): boolean => {
  const role = getCustomClaimTeamRole({ state })

  return role === RoleTypes.Owner || role === RoleTypes.Writer
}
