import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentInvite } from 'state/getters/getCurrentInvite'

import { InviteMainConnectedProps } from './types/InviteMainConnectedProps'
import { InviteMainMapStateReturnType } from './types/InviteMainMapStateReturnType'


export const InviteMainMapState = (
  state: StateReducers,
  props: InviteMainConnectedProps,
): InviteMainMapStateReturnType => {
  const invite = getCurrentInvite({ state, inviteId: props.inviteId })
  const role = invite && invite.role

  return { role }
}
