import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { ModalButtonMapDispatchReturnType } from './types/ModalButtonMapDispatchReturnType'

export const ModalButtonMapDispatch = (
  dispatch: Dispatch<Action>
): ModalButtonMapDispatchReturnType => {
  return {
    click: (showModalType) => {
      dispatch(
        createToggleAction({
          description: 'ModalButtonMapDispatch',
          toggles: {
            showModal: {
              type: showModalType,
            },
          },
        })
      )
    },
  }
}
