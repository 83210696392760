// import { Dispatch, Action } from 'redux'

import {
  // MemberListConnectedProps,
  MemberListMapDispatchReturnType,
} from './types'

export const MemberListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MemberListConnectedProps,
): MemberListMapDispatchReturnType => {
  return {
  }
}
