import { take, put, select } from 'redux-saga/effects'
import { LABELS_ROUTE } from 'routes'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { createPatchTeamsRequestAction } from 'actionCreators/patch/PatchTeams/createPatchTeamsRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_NEW_LABEL, SUBMIT_NEW_LABEL_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitNewLabelF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_NEW_LABEL])
    const { icon, displayName } = action.values
    const state: StateReducers = yield select(selectState)
    const currentTeam = getCurrentTeam({ state })
    const currentTeamLabels = (currentTeam && currentTeam.labels) || {}
    const teamId = getCurrentTeamId({ state })
    const query = getCurrentQuery({ state })
    const trimmedDisplayName = displayName?.trim()

    if (teamId && trimmedDisplayName && icon) {
      const newLabels = {
        ...currentTeamLabels,
        [trimmedDisplayName]: {
          icon,
        },
      }

      yield put(
        createPatchTeamsRequestAction({
          requestValues: {
            items: {
              [teamId]: {
                data: {
                  labels: newLabels,
                },
              },
            },
          },
        })
      )

      yield put(
        createHistoryAction({
          description: 'submitNewLabelF',
          pathname: LABELS_ROUTE,
          method: 'push',
          query,
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewLabelF',
        })
      )
    }

    yield put({
      type: SUBMIT_NEW_LABEL_DONE,
    })
  }
}
