import { connect } from 'react-redux'
import { TeamList } from './TeamList'
import { TeamListMapState } from './TeamListMapState'
import { TeamListMapDispatch } from './TeamListMapDispatch'

import { TeamListMapStateReturnType } from './types/TeamListMapStateReturnType'
import { TeamListMapDispatchReturnType } from './types/TeamListMapDispatchReturnType'
import { TeamListConnectedProps } from './types/TeamListConnectedProps'


export const TeamListConnected = connect<
  TeamListMapStateReturnType,
  TeamListMapDispatchReturnType,
  TeamListConnectedProps>(TeamListMapState,
    TeamListMapDispatch)(TeamList)
