import { connect } from 'react-redux'
import { TaskSizeField } from './TaskSizeField'
import { TaskSizeFieldMapState } from './TaskSizeFieldMapState'
import { TaskSizeFieldMapDispatch } from './TaskSizeFieldMapDispatch'

import { TaskSizeFieldMapStateReturnType } from './types/TaskSizeFieldMapStateReturnType'
import { TaskSizeFieldMapDispatchReturnType } from './types/TaskSizeFieldMapDispatchReturnType'
import { TaskSizeFieldConnectedProps } from './types/TaskSizeFieldConnectedProps'


export const TaskSizeFieldConnected = connect<
  TaskSizeFieldMapStateReturnType,
  TaskSizeFieldMapDispatchReturnType,
  TaskSizeFieldConnectedProps>(TaskSizeFieldMapState,
    TaskSizeFieldMapDispatch)(TaskSizeField)
