import { SubmitAction } from './types/SubmitAction'
import { SubmitActionValues } from './types/SubmitActionValues'

interface Props {
  values: SubmitActionValues
  type: string
  description?: string
}

export const createSubmitAction = ({
  values,
  type,
  description,
}: Props): SubmitAction => {
  return {
    type,
    values,
    description,
  }
}
