import { Dispatch, Action } from 'redux'
import { SyncTaskCountButtonMapDispatchReturnType } from './types/SyncTaskCountButtonMapDispatchReturnType'
import { createErrorAction } from 'actionCreators/createErrorAction'

export const SyncTaskCountButtonMapDispatch = (
  dispatch: Dispatch<Action>
): SyncTaskCountButtonMapDispatchReturnType => {
  return {
    handleError: (props) => {
      dispatch(createErrorAction(props))
    },
  }
}
