import { connect } from 'react-redux'
import { NewMessageEditor } from './NewMessageEditor'
import { NewMessageEditorMapState } from './NewMessageEditorMapState'
import { NewMessageEditorMapDispatch } from './NewMessageEditorMapDispatch'

import { NewMessageEditorMapStateReturnType } from './types/NewMessageEditorMapStateReturnType'
import { NewMessageEditorMapDispatchReturnType } from './types/NewMessageEditorMapDispatchReturnType'
import { NewMessageEditorConnectedProps } from './types/NewMessageEditorConnectedProps'


export const NewMessageEditorConnected = connect<
  NewMessageEditorMapStateReturnType,
  NewMessageEditorMapDispatchReturnType,
  NewMessageEditorConnectedProps>(NewMessageEditorMapState,
    NewMessageEditorMapDispatch)(NewMessageEditor)
