import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getSortedPlanIds } from 'state/getters/getSortedPlanIds'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { clientServerTimestamp } from 'sdks'
import { MAX_OPEN_PLAN_COUNT } from 'constants/environmentConstants'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  SUBMIT_CLOSE_PLAN,
  SUBMIT_NEW_PLAN,
  SUBMIT_CLOSE_PLAN_DONE,
} from 'actions'
import { patchPlansSaga } from 'flows/sagas/patch/patchPlansSaga'
import { createSetSessionStorageAction } from '../../actionCreators/sessionStorage'
import { createSetAlertMessageAction } from '../../actionCreators/set/SetAlertMessage/createSetAlertMessageAction'

export const submitClosePlanF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_CLOSE_PLAN])
    const state: StateReducers = yield select(selectState)
    const sortedOpenPlanIds = getSortedPlanIds({ state, status: 'open' })
    const nextOpenPlanId = sortedOpenPlanIds[1]
    const { planId } = action.values
    const teamId = getCurrentTeamId({ state })
    const currentUserId = state?.currentUserId
    const currentUserDisplayName = state?.currentUser?.displayName

    if (planId && currentUserId && teamId && currentUserDisplayName) {
      const planEnd = state.plans?.[planId]?.data.end
      const timeStamp = clientServerTimestamp()
      const planStart = state.plans?.[nextOpenPlanId]?.data.start || timeStamp

      const newItemsMutable: PlansReducer = {
        [planId]: {
          data: {
            status: 'closed',
            end: planEnd || timeStamp,
          },
          teamId,
        },
      }

      if (nextOpenPlanId) {
        newItemsMutable[nextOpenPlanId] = {
          data: {
            start: planStart,
          },
          teamId,
        }
      }

      yield spawn(patchPlansSaga, {
        items: newItemsMutable,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })

      if (sortedOpenPlanIds.length <= MAX_OPEN_PLAN_COUNT) {
        yield put({
          type: SUBMIT_NEW_PLAN,
        })
      }

      yield put(
        createSetSessionStorageAction({
          description: 'submitClosePlanF.ts',
          sessionStorage: {
            showCurrentPlan: true,
            showNextPlan: true,
          },
        })
      )

      yield put(
        createSetAlertMessageAction({
          description: 'submitClosePlanF',
          values: {
            location: 'toast',
            type: 'success',
            message: 'Done',
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitClosePlanF',
        })
      )
    }

    yield put(
      createToggleAction({
        description: 'submitClosePlanF',
        toggles: {
          showDropdown: false,
        },
      })
    )

    yield put({
      type: SUBMIT_CLOSE_PLAN_DONE,
    })
  }
}
