import React from 'react'
import { CurrentLinkProps } from './types/CurrentLinkProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { CURRENT_ROUTE } from 'routes'

export const CurrentLink = ({
  clearCurrentScrollPosition,
  children,
}: CurrentLinkProps): JSX.Element => {
  return (
    <CustomLinkConnected withCurrentQuery={true} route={CURRENT_ROUTE} title="current cycle" onClick={clearCurrentScrollPosition}>{children}</CustomLinkConnected>
  )
}
