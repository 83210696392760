import { take, put, select, spawn } from 'redux-saga/effects'
import { firebaseSdk } from 'sdks'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_TASK_DUE_DATE, SUBMIT_TASK_DUE_DATE_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitTaskDueDateF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_DUE_DATE])
    const { date, taskId } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const teamId = getCurrentTeamId({ state })

    if (
      date !== undefined &&
      taskId &&
      teamId &&
      currentUserId &&
      currentUserDisplayName
    ) {
      const dueDate = date
        ? firebaseSdk.firestore.Timestamp.fromDate(date)
        : null

      const items: TasksReducer = {
        [taskId]: {
          data: {
            dueDate,
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskDueDateF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_DUE_DATE_DONE,
    })
  }
}
