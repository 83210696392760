import * as React from 'react'
import { AvatarConnected } from './AvatarConnected'
import { Provider } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  mockState,
  createMockStore,
  MOCK_USER_A_ID,
  MOCK_TEAM_A_ID,
  mockUserRoleA,
  mockTeamA,
} from 'mockState'

const noUrlState: StateReducers = {
  ...mockState,
  teams: {
    ...mockState.teams,
    [MOCK_TEAM_A_ID]: {
      data: {
        ...mockTeamA.data,
        userRoles: {
          ...mockTeamA.data.userRoles,
          [MOCK_USER_A_ID]: {
            ...mockUserRoleA,
            photoURL: undefined,
          },
        },
      },
    },
  },
}

export const AvatarExamples = () => {
  return (
    <div className="AvatarExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <AvatarConnected userId={MOCK_USER_A_ID} />
      </Provider>

      <h1 className="exampleHeader">small</h1>

      <Provider store={createMockStore(mockState)}>
        <AvatarConnected userId={MOCK_USER_A_ID} />
      </Provider>

      <h1 className="exampleHeader">no url</h1>

      <Provider store={createMockStore(noUrlState)}>
        <AvatarConnected userId={MOCK_USER_A_ID} />
      </Provider>

      <h1 className="exampleHeader">small and no url</h1>

      <Provider store={createMockStore(noUrlState)}>
        <AvatarConnected userId={MOCK_USER_A_ID} />
      </Provider>

      <h1 className="exampleHeader">show change</h1>

      <Provider store={createMockStore(noUrlState)}>
        <AvatarConnected userId={MOCK_USER_A_ID} showChange={true} />
      </Provider>
    </div>
  )
}
