import { TEAMS_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PatchTeamsApiProps } from './types/PatchTeamsApiProps'
import { PatchTeamsSuccessPayload } from 'firebaseFunctions/teams/types'

export const patchTeamsApi = ({
  values,
  apiHeaders,
}: PatchTeamsApiProps): AxiosPromise<PatchTeamsSuccessPayload> => {
  return Axios.patch(TEAMS_URL, values, {
    headers: apiHeaders,
  })
}
