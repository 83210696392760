import { PATCH_USER_INVITES_SUCCESS } from 'actions'

import { PatchUserInvitesSuccessAction } from './types/PatchUserInvitesSuccessAction'
import { PatchUserInvitesSuccessActionValues } from './types/PatchUserInvitesSuccessActionValues'
import { PatchUserInvitesRequestActionValues } from './types/PatchUserInvitesRequestActionValues'


interface Props {
  successValues: PatchUserInvitesSuccessActionValues
  requestValues: PatchUserInvitesRequestActionValues
}

export const createPatchUserInvitesSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchUserInvitesSuccessAction => {
  return {
    type: PATCH_USER_INVITES_SUCCESS,
    requestValues,
    successValues,
  }
}
