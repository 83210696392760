import { Dispatch, Action } from 'redux'
import { SUBMIT_DELETE_TASK_TAG } from 'actions'
import { RemoveTagButtonMapDispatchReturnType } from './types/RemoveTagButtonMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const RemoveTagButtonMapDispatch = (
  dispatch: Dispatch<Action>
): RemoveTagButtonMapDispatchReturnType => {
  return {
    removeTag: ({ tagId, taskId }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_TASK_TAG,
          values: {
            tagId,
            taskId,
          },
        })
      )
    },
  }
}
