import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { TaskDescriptionEditorConnectedProps } from './types/TaskDescriptionEditorConnectedProps'
import { TaskDescriptionEditorMapStateReturnType } from './types/TaskDescriptionEditorMapStateReturnType'

export const TaskDescriptionEditorMapState = (
  state: StateReducers,
  _props: TaskDescriptionEditorConnectedProps
): TaskDescriptionEditorMapStateReturnType => {
  const task = getCurrentTask({ state })
  const description = task && task.description
  const gotTask = !!task
  const isTouch = state.windowMeta?.isTouch

  return {
    gotTask,
    description,
    isTouch,
  }
}
