import { getCurrentTeam } from './getCurrentTeam'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { RoleTypes } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getTeamRole = ({
  state,
  teamId,
}: Props): RoleTypes | undefined => {
  const team = getCurrentTeam({ state, teamId })
  const userRoles = team?.userRoles
  const userRole =
    userRoles && state.currentUserId && userRoles[state.currentUserId]

  if (userRole) {
    return userRole.role
  }

  return undefined
}
