import { GET_USER_INVITES_SUCCESS } from 'actions'

import { GetUserInvitesSuccessAction } from './types/GetUserInvitesSuccessAction'
import { GetUserInvitesSuccessActionValues } from './types/GetUserInvitesSuccessActionValues'
import { GetUserInvitesRequestActionValues } from './types/GetUserInvitesRequestActionValues'


interface Props {
  successValues: GetUserInvitesSuccessActionValues
  requestValues: GetUserInvitesRequestActionValues
  description: string
}

export const createGetUserInvitesSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): GetUserInvitesSuccessAction => {
  return {
    type: GET_USER_INVITES_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
