import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsEndToEndTesting } from 'state/getters/getIsEndToEndTesting'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // ModalLayoutConnectedProps,
  ModalLayoutMapStateReturnType,
} from './types'

export const ModalLayoutMapState = (
  state: StateReducers,
  // props: ModalLayoutConnectedProps,
): ModalLayoutMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showModal = toggles && toggles.showModal
  const type = showModal ? showModal.type : undefined
  const messageId = showModal ? showModal.messageId : undefined
  const userId = showModal ? showModal.userId : undefined
  const inviteId = showModal ? showModal.inviteId : undefined
  const isEndToEndTesting = getIsEndToEndTesting({ state })
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    isEndToEndTesting,
    type,
    isMediumScreen,
    messageId,
    userId,
    inviteId,
  }
}
