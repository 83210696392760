import { connect } from 'react-redux'
import { UploadedFileDropdown } from './UploadedFileDropdown'
import { UploadedFileDropdownMapState } from './UploadedFileDropdownMapState'
import { UploadedFileDropdownMapDispatch } from './UploadedFileDropdownMapDispatch'

import { UploadedFileDropdownMapStateReturnType } from './types/UploadedFileDropdownMapStateReturnType'
import { UploadedFileDropdownMapDispatchReturnType } from './types/UploadedFileDropdownMapDispatchReturnType'
import { UploadedFileDropdownConnectedProps } from './types/UploadedFileDropdownConnectedProps'


export const UploadedFileDropdownConnected = connect<
  UploadedFileDropdownMapStateReturnType,
  UploadedFileDropdownMapDispatchReturnType,
  UploadedFileDropdownConnectedProps>(UploadedFileDropdownMapState,
    UploadedFileDropdownMapDispatch)(UploadedFileDropdown)
