import { Dispatch, Action } from 'redux'
import { SelectTagInputFieldConnectedProps } from './types/SelectTagInputFieldConnectedProps'
import { SelectTagInputFieldMapDispatchReturnType } from './types/SelectTagInputFieldMapDispatchReturnType'

export const SelectTagInputFieldMapDispatch = (
  _dispatch: Dispatch<Action>,
  _props: SelectTagInputFieldConnectedProps
): SelectTagInputFieldMapDispatchReturnType => {
  return {}
}
