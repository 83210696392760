export const SUBMIT_UPDATE_INVITE_ROLE = 'SUBMIT_UPDATE_INVITE_ROLE'
export const SUBMIT_UPDATE_INVITE_ROLE_DONE = 'SUBMIT_UPDATE_INVITE_ROLE_DONE'
export const SUBMIT_UPDATE_EMAIL_SETTINGS = 'SUBMIT_UPDATE_EMAIL_SETTINGS'
export const SUBMIT_UPDATE_EMAIL_SETTINGS_DONE =
  'SUBMIT_UPDATE_EMAIL_SETTINGS_DONE'
export const SUBMIT_UPDATE_PUSH_SETTINGS = 'SUBMIT_UPDATE_PUSH_SETTINGS'
export const SUBMIT_UPDATE_PUSH_SETTINGS_DONE =
  'SUBMIT_UPDATE_PUSH_SETTINGS_DONE'

export const SUBMIT_UPDATE_MESSAGE = 'SUBMIT_UPDATE_MESSAGE'
export const SUBMIT_UPDATE_MESSAGE_DONE = 'SUBMIT_UPDATE_MESSAGE_DONE'
export const SUBMIT_UPDATE_ROLE = 'SUBMIT_UPDATE_ROLE'
export const SUBMIT_UPDATE_ROLE_DONE = 'SUBMIT_UPDATE_ROLE_DONE'
export const SUBMIT_UPDATE_TASK_STATUSES = 'SUBMIT_UPDATE_TASK_STATUSES'
export const SUBMIT_UPDATE_TASK_STATUSES_DONE =
  'SUBMIT_UPDATE_TASK_STATUSES_DONE'
export const SUBMIT_UPDATE_PASSWORD = 'SUBMIT_UPDATE_PASSWORD'
export const SUBMIT_UPDATE_PASSWORD_DONE = 'SUBMIT_UPDATE_PASSWORD_DONE'
export const SUBMIT_UPDATE_TASK_SIZES = 'SUBMIT_UPDATE_TASK_SIZES'
export const SUBMIT_UPDATE_TASK_SIZES_DONE = 'SUBMIT_UPDATE_TASK_SIZES_DONE'
