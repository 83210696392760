import { UploadedImageWidgetStyles } from './UploadedImageWidgetStyles'
import React, { useState, useEffect } from 'react'
import { UploadedImageWidgetProps } from './types/UploadedImageWidgetProps'
import { storageSdk } from 'sdks'
import { ImageWidget } from 'components/widget/ImageWidget'
import { FullImageWidgetConnected } from 'components/widget/FullImageWidget'
import { DeleteAndConfirmWidget } from '../DeleteAndConfirmWidget'
import { Button } from 'components/button/Button'
import { ButtonLink } from 'components/link/ButtonLink'
import { DownloadIcon } from 'components/icon/DownloadIcon/DownloadIcon'
import { StickyNoteIcon } from 'components/icon/StickyNoteIcon/StickyNoteIcon'
import { textBoldStyles } from '../../../styles/text/textBoldStyles'

interface Metadata {
  contentType?: string
}

export const UploadedImageWidget = ({
  url,
  onDeleteClick,
  thumbPath,
  originalPath,
  fileName,
}: UploadedImageWidgetProps): JSX.Element => {
  const [thumbDownloadUrl, setThumbDownloadUrl] = useState('')
  const [originalDownloadUrl, setOriginalDownloadUrl] = useState('')
  const [showFullImage, setShowFullImage] = useState(false)
  const [isImage, setIsImage] = useState(false)
  const [showFileActions, setShowFileActions] = useState(false)

  useEffect(() => {
    let cancelled = false

    const getFileData = async () => {
      if (!url) {
        const ref = storageSdk().ref()

        if (!thumbDownloadUrl && thumbPath) {
          try {
            const thumbUrl = await ref.child(thumbPath).getDownloadURL()

            if (!cancelled) {
              setThumbDownloadUrl(thumbUrl)
            }
          } catch (error) {
            // Dont need to handle error case
          }
        }

        if (!originalDownloadUrl && originalPath) {
          try {
            const originalUrlPromise = ref.child(originalPath).getDownloadURL()
            const metadataPromise = ref.child(originalPath).getMetadata()

            const [originalUrl, metadata]: [
              string,
              Metadata
            ] = await Promise.all([originalUrlPromise, metadataPromise])
            const imageContentType =
              metadata.contentType &&
              metadata.contentType.indexOf('image/') === 0
            setIsImage(!!imageContentType)

            if (!cancelled) {
              setOriginalDownloadUrl(originalUrl)
            }
          } catch {
            // Dont need to handle error case
          }
        }
      }
    }

    getFileData()

    return () => {
      cancelled = true
    }
  }, [
    thumbPath,
    originalPath,
    setThumbDownloadUrl,
    url,
    thumbDownloadUrl,
    originalDownloadUrl,
    setOriginalDownloadUrl,
  ])

  const originalUrl = url || originalDownloadUrl
  const thumbUrl = url || thumbDownloadUrl || originalDownloadUrl

  return (
    <div className={'UploadedImageWidget ' + UploadedImageWidgetStyles}>
      {fileName && (
        <div className={`fileName ${textBoldStyles}`}>{fileName}</div>
      )}

      {showFullImage && (
        <FullImageWidgetConnected
          src={originalUrl}
          fileName={fileName}
          onCloseClick={() => {
            setShowFullImage(false)
          }}
          onDeleteClick={onDeleteClick}
        />
      )}

      {isImage && (
        <>
          {thumbUrl && (
            <ImageWidget
              src={thumbUrl}
              className="thumbNail"
              onClick={() => {
                setShowFullImage(true)
              }}
              alt="uploaded file"
            />
          )}
          {!thumbUrl && (
            <div
              className="imagePlaceHolder"
              onClick={() => {
                setShowFullImage(true)
              }}
            />
          )}
        </>
      )}

      {!isImage && (
        <div className="fileContainer flexAlign">
          <Button
            title="options"
            className="showFileActionsButton"
            onClick={() => {
              setShowFileActions(!showFileActions)
            }}
          >
            <StickyNoteIcon />
          </Button>

          {showFileActions && (
            <div className="fileActions flexAlign">
              <ButtonLink
                href={originalUrl}
                title="download"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon />
              </ButtonLink>
              {onDeleteClick && (
                <DeleteAndConfirmWidget onDeleteClick={onDeleteClick} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
