import { GoBackLinkStyles } from './GoBackLinkStyles'
import React from 'react'
import { GoBackLinkProps } from './types/GoBackLinkProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { ArrowLeftIcon } from 'components/icon/ArrowLeftIcon/ArrowLeftIcon'

export const GoBackLink = ({
  pathname,
  teamId,
  taskId,
  onClick,
}: GoBackLinkProps): JSX.Element | null => {
  if (!pathname) {
    return null
  }

  return (
    <CustomLinkConnected
      title="go back"
      route={pathname}
      teamId={teamId}
      taskId={taskId}
      onClick={onClick}
      className={'GoBackLink ' + GoBackLinkStyles}
    >
      <ArrowLeftIcon />
    </CustomLinkConnected>
  )
}
