import { TextInputFieldStyles } from './TextInputFieldStyles'
import React from 'react'
import { TextInputFieldProps } from './types/TextInputFieldProps'
import { labelStyles } from 'styles/labelStyles'
import { TextInput } from 'components/input/TextInput'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { PasswordInput } from 'components/input/PasswordInput/PasswordInput'
import { TextAreaInput } from 'components/input/TextAreaInput'
import { marginLeftSmallestStyles } from '../../../styles/margin/marginLeftSmallestStyles'

export const TextInputField = ({
  label,
  error,
  type,
  className = '',
  ...props
}: TextInputFieldProps): JSX.Element => {
  return (
    <div className={`TextInputField ${TextInputFieldStyles} ${className}`}>
      <label>
        {label && (
          <div className={`${labelStyles} ${marginLeftSmallestStyles}`}>
            {label}
          </div>
        )}

        {type === 'password' && <PasswordInput {...props} />}

        {(type === 'text' || type === 'email') && <TextInput {...props} />}

        {type === 'textarea' && <TextAreaInput {...props} />}
      </label>

      <ErrorMessage style={{ height: !error ? '0px' : '20px' }}>
        {error}
      </ErrorMessage>
    </div>
  )
}
