// import { Dispatch, Action } from 'redux'

import {
  // TasksWatcherConnectedProps,
  TasksWatcherMapDispatchReturnType,
} from './types'

export const TasksWatcherMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TasksWatcherConnectedProps,
): TasksWatcherMapDispatchReturnType => {
  return {
  }
}
