import { StateReducers } from '../reducers/types/StateReducers'
import { getActivePlan } from './getActivePlan'
import { GenericObject } from 'firebaseFunctions/types'
import { getFilteredTasks } from './getFilteredTasks'

interface Props {
  state: StateReducers
  useFilter?: boolean
}

type TaskIdsByStatus = GenericObject<string[]>

// active plan is current cycle
export const getActivePlanTaskIds = ({
  state,
  useFilter,
}: Props): TaskIdsByStatus => {
  const plan = getActivePlan({ state })
  let taskIds = plan && Array.isArray(plan.taskIds) ? plan.taskIds : []

  if (useFilter) {
    const filteredTasks = getFilteredTasks({ state }) || {}

    taskIds = taskIds.filter((taskId) => {
      return filteredTasks[taskId]
    })
  }

  const tasks = state.tasks || undefined
  const groups = {}

  taskIds.forEach((taskId) => {
    const task = tasks?.[taskId]?.data
    const status = task && task.status

    if (status) {
      if (!groups[status]) {
        groups[status] = []
      }

      groups[status].push(taskId)
    }
  })

  return groups
}
