import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const DeleteAndConfirmWidgetStyles = css`
  display: inline-block;

  .confirmDelete {
    margin-left: 8px;
  }
`
