import { css } from 'emotion'

export const listHorizontalScrollStyles = css`
  display: flex;
  align-items: center;
  overflow-x: scroll;

  > li {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }

    > * {
      white-space: nowrap;
    }
  }
`
