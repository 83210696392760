import { take, put, select } from 'redux-saga/effects'
import { selectState } from 'state/reducers/selectState'
import { LOCATION_CHANGE } from 'actions'
import { SELECT_TEAM_ROUTE } from 'routes'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { StateReducers } from '../state/reducers/types/StateReducers'

export const alreadyLoggedInF = function*() {
  while (1) {
    yield take([LOCATION_CHANGE])

    const state: StateReducers = yield select(selectState)
    const isLoginRoute = state.currentLocation?.isRoutes?.isLoginRoute
    const emailVerified = state.currentUser?.emailVerified

    if (isLoginRoute && emailVerified) {
      yield put(
        createHistoryAction({
          pathname: SELECT_TEAM_ROUTE,
          method: 'replace',
          description: 'createHistoryAction',
        })
      )
    }
  }
}
