import { css } from 'emotion'
import { PRIMARY_BACKGROUND_COLOR } from 'constants/styleConstants'

export const FullImageWidgetStyles = css`
  position: fixed;
  z-index: 1000;
  background-color: ${PRIMARY_BACKGROUND_COLOR};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;

  .fileName {
    margin: 8px;
    font-size: 0.8em;
  }

  .Spinner {
    margin-left: 8px;
  }

  > header {
    padding: 8px;

    button {
      margin-left: 8px;
    }
  }
`
