import { put, select, spawn } from 'redux-saga/effects'
import { createId } from 'firebaseFunctions/utilities/createId'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { Task } from 'firebaseFunctions/types'
import { TASK_DETAIL_ROUTE } from 'routes'
import { postTasksSaga } from './post/postTasksSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentPlan } from 'state/getters/getCurrentPlan'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { patchPlansSaga } from './patch/patchPlansSaga'

interface Props {
  task: Task
  teamId: string
}

export const newTaskSaga = function*({ task, teamId }: Props) {
  const taskId = createId()
  const planId = task.planId

  const taskMutable: Task = {
    ...task,
  }

  const items: TasksReducer = {
    [taskId]: {
      data: taskMutable,
      teamId,
    },
  }

  yield spawn(postTasksSaga, { items })

  if (planId) {
    const state: StateReducers = yield select(selectState)
    const plan = planId && getCurrentPlan({ state, planId })
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName

    if (plan && currentUserId && currentUserDisplayName) {
      const taskIds = Array.isArray(plan?.taskIds)
        ? [taskId].concat(plan?.taskIds)
        : [taskId]

      yield spawn(patchPlansSaga, {
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
        items: {
          [planId]: {
            data: {
              taskIds,
            },
            teamId,
          },
        },
      })
    }
  }

  const historyAction = createHistoryAction({
    pathname: TASK_DETAIL_ROUTE,
    method: 'push',
    description: 'newTaskSaga',
    query: {
      teamId,
      taskId,
    },
  })

  yield put(historyAction)
}
