import { connect } from 'react-redux'
import { ReplyMessageCard } from './ReplyMessageCard'
import { ReplyMessageCardMapState } from './ReplyMessageCardMapState'
import { ReplyMessageCardMapDispatch } from './ReplyMessageCardMapDispatch'

import { ReplyMessageCardMapStateReturnType } from './types/ReplyMessageCardMapStateReturnType'
import { ReplyMessageCardMapDispatchReturnType } from './types/ReplyMessageCardMapDispatchReturnType'
import { ReplyMessageCardConnectedProps } from './types/ReplyMessageCardConnectedProps'


export const ReplyMessageCardConnected = connect<
  ReplyMessageCardMapStateReturnType,
  ReplyMessageCardMapDispatchReturnType,
  ReplyMessageCardConnectedProps>(ReplyMessageCardMapState,
    ReplyMessageCardMapDispatch)(ReplyMessageCard)
