import { connect } from 'react-redux'
import { NewLabelForm } from './NewLabelForm'
import { NewLabelFormMapState } from './NewLabelFormMapState'
import { NewLabelFormMapDispatch } from './NewLabelFormMapDispatch'
import { NewLabelFormMapStateReturnType } from './types/NewLabelFormMapStateReturnType'
import { NewLabelFormMapDispatchReturnType } from './types/NewLabelFormMapDispatchReturnType'
import { NewLabelFormConnectedProps } from './types/NewLabelFormConnectedProps'

export const NewLabelFormConnected = connect<
  NewLabelFormMapStateReturnType,
  NewLabelFormMapDispatchReturnType,
  NewLabelFormConnectedProps
>(
  NewLabelFormMapState,
  NewLabelFormMapDispatch
)(NewLabelForm)
