import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // PrimaryDropdownConnectedProps,
  PrimaryDropdownMapStateReturnType,
} from './types'
import { getCanOwn } from '../../../state/getters/getCanOwn'

export const PrimaryDropdownMapState = (
  state: StateReducers
  // props: PrimaryDropdownConnectedProps,
): PrimaryDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const canOwn = getCanOwn({ state })
  const showPrimaryDropdown =
    showDropdown && showDropdown.type === 'primaryDropdown'

  return {
    showPrimaryDropdown,
    canOwn,
  }
}
