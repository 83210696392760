import { css } from 'emotion'

export const InviteCardStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .clockContainer {
    display: flex;

    .far {
      font-size: 24px;
    }

    .email {
      margin-left: 8px;
      font-weight: bold;
    }
  }

  .roleType {
    margin-top: 4px;
    font-style: italic;
  }

  .createdSeconds {
    margin-top: 8px;
  }
`
