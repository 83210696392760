import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { GET_USER_INVITES_REQUEST } from 'actions'
import { QuerySnapshot } from '@google-cloud/firestore'
import { Invite } from 'firebaseFunctions/types'
import { getUserInvitesRef } from 'firebaseFunctions/utilities/ref/get/getUserInvitesRef'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { UserInvites } from 'firebaseFunctions/types'
import { GetUserInvitesRequestAction } from 'actionCreators/get/GetUserInvites/types/GetUserInvitesRequestAction'
import { createGetUserInvitesSuccessAction } from 'actionCreators/get/GetUserInvites/createGetUserInvitesSuccessAction'
import { createGetUserInvitesFailureAction } from 'actionCreators/get/GetUserInvites/createGetUserInvitesFailureAction'

export const getUserInvitesRequestF = function*() {
  while (1) {
    const action: GetUserInvitesRequestAction = yield take([
      GET_USER_INVITES_REQUEST,
    ])
    const { requestValues } = action
    const { email, ignoreErrors } = requestValues

    try {
      const userInvitesMutable: UserInvites = {} // TODO: get rid of mutable

      const userInviteQuery: QuerySnapshot = yield getUserInvitesRef({
        firestore,
        email,
      }).get()

      userInviteQuery.forEach((userInviteDoc) => {
        const userInvite = userInviteDoc.data() as Invite | undefined

        userInvitesMutable[userInviteDoc.id] = {
          data: {
            ...userInvite,
          },
        }
      })

      const getUserInviteSuccessAction = createGetUserInvitesSuccessAction({
        requestValues,
        successValues: {
          items: userInvitesMutable,
        },
        description: 'getUserInvitesRequestF',
      })

      yield put(getUserInviteSuccessAction)
    } catch (error) {
      if (!ignoreErrors) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'getUserInvitesRequestF',
          })
        )
      }

      yield put(
        createGetUserInvitesFailureAction({
          requestValues,
        })
      )
    }
  }
}
