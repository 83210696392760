import { TeamStat } from '../../types'

interface Props {
  teamStat?: TeamStat
  configMaxUserCountFree: number
  configMaxUserCountPro: number
}

interface Return {
  maxUserCountFree: number
  maxUserCountPro: number
}

export const getMaxUserCountFromTeamStat = ({
  teamStat,
  configMaxUserCountFree,
  configMaxUserCountPro,
}: Props): Return => {
  const teamStatMaxUserCountFree = teamStat?.maxUserCountFree || 0
  const teamStatMaxUserCountPro = teamStat?.maxUserCountPro || 0

  const maxUserCountFree =
    teamStatMaxUserCountFree > configMaxUserCountFree
      ? teamStatMaxUserCountFree
      : configMaxUserCountFree

  const maxUserCountPro =
    teamStatMaxUserCountPro > configMaxUserCountPro
      ? teamStatMaxUserCountPro
      : configMaxUserCountPro

  return {
    maxUserCountFree,
    maxUserCountPro,
  }
}
