// import { Dispatch, Action } from 'redux'

import {
  // MessageNotificationDropdownConnectedProps,
  MessageNotificationDropdownMapDispatchReturnType,
} from './types'

export const MessageNotificationDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessageNotificationDropdownConnectedProps,
): MessageNotificationDropdownMapDispatchReturnType => {
  return {
  }
}
