import { MentionSuggestionListConnectedProps } from './types/MentionSuggestionListConnectedProps'
import { MentionSuggestionListMapStateReturnType } from './types/MentionSuggestionListMapStateReturnType'

import { memoizeFlatArray } from 'utilities/memoizeFlatArray'
import { getUserRoles } from 'state/getters/getUserRoles'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { forEach } from 'lodash'

export const MentionSuggestionListMapState = (
  state: StateReducers,
  _props: MentionSuggestionListConnectedProps
): MentionSuggestionListMapStateReturnType => {
  const userRoles = getUserRoles({ state })
  const mentionIdsMutable: string[] = []
  const mentionNamesMutable: string[] = []
  const mentionAvatarsMutable: string[] = []

  forEach(userRoles, ({ displayName, photoURL }, id) => {
    mentionIdsMutable.push(id)
    mentionNamesMutable.push(displayName || '(no name)')
    mentionAvatarsMutable.push(photoURL || '')
  })

  return {
    mentionIds: memoizeFlatArray(
      mentionIdsMutable,
      'DraftEditorMapState-mentionIds'
    ),
    mentionNames: memoizeFlatArray(
      mentionNamesMutable,
      'DraftEditorMapState-mentionNames'
    ),
    mentionAvatars: memoizeFlatArray(
      mentionAvatarsMutable,
      'DraftEditorMapState-mentionAvatars'
    ),
  }
}
