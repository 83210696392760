import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_NEW_TEAM_LOGO } from 'actions'
import { UploadTeamLogoButtonMapDispatchReturnType } from './types/UploadTeamLogoButtonMapDispatchReturnType'

export const UploadTeamLogoButtonMapDispatch = (
  dispatch: Dispatch<Action>
): UploadTeamLogoButtonMapDispatchReturnType => {
  return {
    uploadFile: (files: File[]) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_NEW_TEAM_LOGO,
          values: { files },
        })
      )
    },
  }
}
