import {
  CLIENT_CREATE_ACCOUNT_ROUTE,
  CLIENT_LOGIN_ROUTE,
} from 'firebaseFunctions/constants/clientConstants'

export const LOGIN_ROUTE = CLIENT_LOGIN_ROUTE
export const ROOT_ROUTE = '/'
export const CREATE_ACCOUNT_ROUTE = CLIENT_CREATE_ACCOUNT_ROUTE
export const CONFIRM_EMAIL_ROUTE = '/confirm-email'
export const SERVER_ERROR_TEST_ROUTE = '/server-error-test'
export const JS_ERROR_TEST_ROUTE = '/js-error-test'
export const NETWORK_ERROR_TEST_ROUTE = '/network-error-test'
export const RESET_PASSWORD_ROUTE = '/reset-password'
export const RESET_PASSWORD_SENT_ROUTE = '/reset-password-sent'

export const preAndPostLoginRoutes: string[] = [
  LOGIN_ROUTE,
  ROOT_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  CONFIRM_EMAIL_ROUTE,
  SERVER_ERROR_TEST_ROUTE,
  JS_ERROR_TEST_ROUTE,
  NETWORK_ERROR_TEST_ROUTE,
  RESET_PASSWORD_ROUTE,
  RESET_PASSWORD_SENT_ROUTE,
]
