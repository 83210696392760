import { PATCH_USER_EXTENSION_FAILURE } from 'actions'

import { PatchUserExtensionFailureAction } from './types/PatchUserExtensionFailureAction'
import { PatchUserExtensionRequestActionValues } from './types/PatchUserExtensionRequestActionValues'


interface Props {
  requestValues: PatchUserExtensionRequestActionValues
}

export const createPatchUserExtensionFailureAction = ({
  requestValues,
}: Props): PatchUserExtensionFailureAction => {
  return {
    type: PATCH_USER_EXTENSION_FAILURE,
    requestValues,
  }
}
