import { take, put } from 'redux-saga/effects'
import { DashboardView } from 'types/DashboardView'
import { LOCATION_CHANGE } from 'actions'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'
import { createSetSessionStorageAction } from 'actionCreators/sessionStorage'

const getDashBoardView = (
  locationChangeAction: LocationChangeAction
): DashboardView | undefined => {
  const isRoutes = locationChangeAction.values.isRoutes
  const isDashboardHomeRoute = isRoutes && isRoutes.isDashboardHomeRoute
  const isPlanRoute = isRoutes && isRoutes.isPlanRoute
  const isCurrentRoute = isRoutes && isRoutes.isCurrentRoute

  if (isDashboardHomeRoute) {
    return 'todo'
  }

  if (isPlanRoute) {
    return 'plan'
  }

  if (isCurrentRoute) {
    return 'current'
  }

  return undefined
}

export const setDashboardViewF = function*() {
  while (1) {
    const locationChangeAction: LocationChangeAction = yield take([
      LOCATION_CHANGE,
    ])
    const dashboardView = getDashBoardView(locationChangeAction)

    if (dashboardView) {
      yield put(
        createSetSessionStorageAction({
          description: 'setDashboardViewF',
          sessionStorage: {
            dashboardView,
          },
        })
      )
    }
  }
}
