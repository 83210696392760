import { connect } from 'react-redux'
import { UploadedFileCard } from './UploadedFileCard'
import { UploadedFileCardMapState } from './UploadedFileCardMapState'
import { UploadedFileCardMapDispatch } from './UploadedFileCardMapDispatch'

import { UploadedFileCardMapStateReturnType } from './types/UploadedFileCardMapStateReturnType'
import { UploadedFileCardMapDispatchReturnType } from './types/UploadedFileCardMapDispatchReturnType'
import { UploadedFileCardConnectedProps } from './types/UploadedFileCardConnectedProps'


export const UploadedFileCardConnected = connect<
  UploadedFileCardMapStateReturnType,
  UploadedFileCardMapDispatchReturnType,
  UploadedFileCardConnectedProps
>(
  UploadedFileCardMapState,
  UploadedFileCardMapDispatch
)(UploadedFileCard)
