import { GET_TEAMS_REQUEST } from 'actions'
import { GetTeamsRequestAction } from './types/GetTeamsRequestAction'
import { GetTeamsRequestActionValues } from './types/GetTeamsRequestActionValues'

interface Props {
  requestValues: GetTeamsRequestActionValues
  description: string
}

export const createGetTeamsRequestAction = ({ requestValues, description }: Props): GetTeamsRequestAction => {
  return {
    type: GET_TEAMS_REQUEST,
    description,
    requestValues,
  }
}
