import { TaskChangesMainStyles } from './TaskChangesMainStyles'
import React, { useState } from 'react'
import { TaskChangesMainProps } from './types/TaskChangesMainProps'
import {
  QuerySnapshot,
  firestore,
  QueryDocumentSnapshot,
  FirebaseTimestamp,
} from 'sdks'
import { getTaskChangesRef } from 'firebaseFunctions/utilities/ref/get/getTaskChangesRef'
import { TaskChange } from 'firebaseFunctions/types'
import { TaskChangeCard } from 'components/card/TaskChangeCard'
import { Button } from 'components/button/Button'
import { Spinner } from 'components/widget/Spinner'
import { CollapsibleDiv } from 'components/div/CollapsibleDiv'
import { CheveronRightIcon } from 'components/icon/CheveronRightIcon/CheveronRightIcon'
import { CheveronLeftIcon } from 'components/icon/CheveronLeftIcon/CheveronLeftIcon'
import { CheveronDownIcon } from 'components/icon/CheveronDownIcon/CheveronDownIcon'

export const TaskChangesMain = ({
  teamId,
  taskId,
  isBigScreen,
  onFirebaseError,
  className = '',
}: TaskChangesMainProps): JSX.Element => {
  const [showChangeHistory, setShowChangeHistory] = useState(false)
  const [currentPage, setCurrentPage] = useState(-1)
  const [noMore, setNoMore] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [
    lastVisibleDoc,
    setLastVisibleDoc,
  ] = useState<QueryDocumentSnapshot | null>(null)
  const [taskChanges, setTaskChanges] = useState<TaskChange[] | null>(null)

  const getTaskChanges = async () => {
    if (teamId && taskId) {
      try {
        setShowSpinner(true)
        const newTaskChangesMutable: TaskChange[] = [...(taskChanges || [])]

        let ref = getTaskChangesRef({
          firestore,
          teamId,
          taskId,
        }).orderBy('created', 'desc')

        if (lastVisibleDoc) {
          ref = ref.startAfter(lastVisibleDoc)
        }

        const querySnapshot: QuerySnapshot = await ref.limit(5).get()

        querySnapshot.forEach((taskChangeDoc) => {
          const taskChange = taskChangeDoc.data() as TaskChange | undefined

          if (taskChange) {
            newTaskChangesMutable.push(taskChange)
          }
        })

        if (querySnapshot.size === 0) {
          setNoMore(true)
        } else {
          setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1])
          setTaskChanges(newTaskChangesMutable)
          setCurrentPage(currentPage + 1)
        }
        setShowSpinner(false)
      } catch (error) {
        onFirebaseError(error)
      }
    }
  }

  const currentTaskChange = taskChanges?.[currentPage]
  const created = currentTaskChange?.created
  const lastModifiedUserId = currentTaskChange?.lastModifiedUserId
  const summaryAfter = currentTaskChange?.summaryAfter
  const summaryBefore = currentTaskChange?.summaryBefore
  const descriptionAfter = currentTaskChange?.descriptionAfter
  const descriptionBefore = currentTaskChange?.descriptionBefore
  const maxPage = taskChanges?.length || 0
  const isFirstChange = summaryAfter && !summaryBefore
  const isMediumScreenClass = isBigScreen ? ' isBigScreen' : ''
  const createdSeconds =
    created instanceof FirebaseTimestamp ? created.seconds : undefined
  const nextDisabled = (noMore && currentPage >= maxPage) || !!isFirstChange

  return (
    <div
      className={`TaskChangesMain ${TaskChangesMainStyles} ${isMediumScreenClass} ${className}`}
    >
      <Button
        className="showChangeHistory"
        onClick={() => {
          if (!showChangeHistory && currentPage === -1) {
            getTaskChanges()
          }

          setShowChangeHistory(!showChangeHistory)
        }}
      >
        <span>Change History</span>

        {showChangeHistory && <CheveronDownIcon />}
        {!showChangeHistory && <CheveronLeftIcon />}
      </Button>

      <CollapsibleDiv random={Math.random()} show={showChangeHistory}>
        {currentTaskChange && (
          <TaskChangeCard
            isBigScreen={isBigScreen}
            lastModifiedUserId={lastModifiedUserId}
            summaryAfter={summaryAfter}
            descriptionAfter={descriptionAfter}
            descriptionBefore={descriptionBefore}
            summaryBefore={summaryBefore}
            createdSeconds={createdSeconds}
          />
        )}

        <nav className="nextPrevNav spaceBetween">
          <Button
            onClick={() => {
              setCurrentPage(currentPage - 1)
            }}
            disabled={currentPage < 1}
            style={{
              visibility: currentPage < 1 ? 'hidden' : 'visible',
            }}
          >
            <CheveronLeftIcon />
          </Button>

          {showSpinner && (
            <div className="centerFlex spinnerContainer">
              <Spinner />
            </div>
          )}

          <Button
            disabled={nextDisabled}
            style={{
              visibility: nextDisabled ? 'hidden' : 'visible',
            }}
            onClick={() => {
              const nextPage = currentPage + 1
              if (nextPage >= maxPage) {
                getTaskChanges()
              } else {
                setCurrentPage(nextPage)
              }
            }}
          >
            <CheveronRightIcon />
          </Button>
        </nav>
      </CollapsibleDiv>
    </div>
  )
}
