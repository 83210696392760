import { ModalStyles } from './ModalStyles'
import * as React from 'react'
import { ModalProps } from './types/ModalProps'
import { TimesSolidIcon } from '../../icon/TimesSolidIcon/TimesSolidIcon'

// TODO: animation when opening and closing

export const Modal = ({
  children,
  visible,
  onCancel,
  className = '',
  isMediumScreen,
}: ModalProps): JSX.Element | null => {
  const extraClassName = className ? ' ' + className : ''
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

  if (visible) {
    return (
      <div
        className={
          'Modal ' + ModalStyles + extraClassName + isMediumScreenClassName
        }
      >
        <div className="wrapper">
          <button className="closeButton" type="button" onClick={onCancel}>
            <TimesSolidIcon />
          </button>

          <main>{children}</main>
        </div>

        <div className="mask" onClick={onCancel} />
      </div>
    )
  }

  return null
}
