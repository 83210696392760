import { CaretSquareDownIconStyles } from './CaretSquareDownIconStyles'
import React from 'react'
import { CaretSquareDownIconProps } from './types/CaretSquareDownIconProps'
import { CaretSquareLeftIcon } from '../CaretSquareLeftIcon/CaretSquareLeftIcon'

export const CaretSquareDownIcon = ({
  className = '',
}: CaretSquareDownIconProps): JSX.Element => {
  return (
    <CaretSquareLeftIcon
      className={`CaretSquareDownIcon ${className} ${CaretSquareDownIconStyles}`}
    />
  )
}
