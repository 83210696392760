import { GET_SESSION_STORAGE_SUCCESS } from 'actions'
import { SessionStorage } from 'types/SessionStorage'
import { GetSessionStorageSuccessAction } from './types/GetSessionStorageSuccessAction'

interface Props {
  sessionStorage: SessionStorage
}

export const createGetSessionStorageSuccessAction = ({ sessionStorage }: Props): GetSessionStorageSuccessAction => {
  return {
    type: GET_SESSION_STORAGE_SUCCESS,
    sessionStorage,
  }
}
