import React, { useCallback, useEffect } from 'react'
import { TaskDescriptionEditorProps } from './types/TaskDescriptionEditorProps'
import { DraftEditorConnected } from 'components/editor/DraftEditor'
import { TaskDescriptionEditorStyles } from './TaskDescriptionEditorStyles'
import { DescriptionToolBar } from 'components/bar/DescriptionToolBar'
import { debounce } from 'lodash'

export const TaskDescriptionEditor = ({
  save,
  description,
  gotTask,
  isTouch,
  setWorking,
}: TaskDescriptionEditorProps): JSX.Element | null => {
  const debouncedSetWorking = useCallback(
    debounce(setWorking, 10000, { leading: true, maxWait: 50 }),
    [setWorking]
  )

  useEffect(() => {
    return () => {
      debouncedSetWorking.cancel()
    }
  }, [debouncedSetWorking])

  if (gotTask) {
    // TODO: dont know how to reinitialize draftEditor
    return (
      <DraftEditorConnected
        value={description}
        save={(markdown) => {
          const bothEmpty = !description && !markdown
          debouncedSetWorking.cancel()

          if (markdown !== description && !bothEmpty) {
            save(markdown)
          } else {
            setWorking(false)
          }
        }}
        autoDebounceSave={true}
        saveOnBlur={true}
        onFocus={() => {
          setWorking(true)
        }}
        onChange={() => {
          debouncedSetWorking()
        }}
        alwaysShowInlineToolbar={true}
        placeholder="Write a description"
        footerToolBar={({
          editorState,
          setEditorState,
          contentChangedSinceSaved,
          submit,
        }) => {
          return (
            <DescriptionToolBar
              showEmojitButton={!isTouch}
              editorState={editorState}
              submit={submit}
              setEditorState={setEditorState}
              contentChangedSinceSaved={contentChangedSinceSaved}
            />
          )
        }}
        className={'TaskDescriptionEditor clean ' + TaskDescriptionEditorStyles}
      />
    )
  }

  return null
}
