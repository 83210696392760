import { AppStyles } from './AppStyles'
import * as React from 'react'
import { AppProps } from './types/AppProps'
import { OneLayoutConnected } from 'components/layout/OneLayout'
import { DashboardLayoutConnected } from 'components/layout/DashboardLayout'
import { ModalLayoutConnected } from 'components/layout/ModalLayout'
import { MessageLayoutConnected } from 'components/layout/MessageLayout'
import { MinimalLayoutConnected } from 'components/layout/MinimalLayout'
import { TestingMainConnected } from 'components/main/TestingMain'
import { ServerError } from 'components/ServerError'
import { MainWatcherConnected } from 'components/watcher/MainWatcher'
import { NotificationPermissionWatcherConnected } from 'components/watcher/NotificationPermissionWatcher'
import { ServiceWorkerMessageWatcherConnected } from 'components/watcher/ServiceWorkerMessageWatcher'
import { displayNoneStyles } from '../../styles/display/displayNoneStyles'
import { EnableNotificationMessageConnected } from '../message/EnableNotificationMessage/EnableNotificationMessageConnected'
import { sentrySdk } from '../../sdks/sentry/sentrySdk'
import { SentrySeverity } from '../../sdks/sentry/SentrySeverity'
import { AppUpdatedMainConnected } from '../main/AppUpdatedMain/AppUpdatedMainConnected'

interface State {
  hasError: boolean | 'ChunkLoadError'
}

export class App extends React.Component<AppProps, State> {
  constructor(props: AppProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: error?.name === 'ChunkLoadError' ? 'ChunkLoadError' : true,
    }
  }

  componentDidCatch(error: any, _errorInfo: any) {
    const errorString: string =
      typeof error === 'string' ? error : JSON.stringify(error)

    sentrySdk.captureMessage(
      `getDerivedStateFromError: ${errorString}`,
      SentrySeverity.Critical
    )
  }

  render() {
    const {
      isEndToEndTesting = false,
      showOneLayout,
      showDashboardLayout,
      showMessageLayout,
      showMinimalLayout,
      isMediumScreen,
    } = this.props

    const extraClassname = isEndToEndTesting ? ' isEndToEndTesting' : ''
    const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''
    const className =
      'App ' + AppStyles + extraClassname + isMediumScreenClassName

    return (
      <div className={className}>
        {this.state.hasError === 'ChunkLoadError' && (
          <AppUpdatedMainConnected />
        )}

        {this.state.hasError === true && <ServerError />}

        {this.state.hasError === false && (
          <>
            <img
              alt="preloading emojis"
              src="https://unpkg.com/emoji-datasource-apple@4.0.4/img/apple/sheets-256/64.png"
              className={displayNoneStyles}
            />
            <ModalLayoutConnected />

            <MainWatcherConnected />

            <TestingMainConnected />

            <EnableNotificationMessageConnected />

            <NotificationPermissionWatcherConnected />

            <ServiceWorkerMessageWatcherConnected />

            {showOneLayout && <OneLayoutConnected />}

            {showDashboardLayout && <DashboardLayoutConnected />}

            {showMessageLayout && <MessageLayoutConnected />}

            {showMinimalLayout && <MinimalLayoutConnected />}
          </>
        )}
      </div>
    )
  }
}
