export const GET_SESSION_STORAGE_SUCCESS = 'GET_SESSION_STORAGE_SUCCESS'
export const GET_SESSION_STORAGE_FAILURE = 'GET_SESSION_STORAGE_FAILURE'
export const GET_SESSION_STORAGE_CANCEL = 'GET_SESSION_STORAGE_CANCEL'

export const GET_COOKIE_STORAGE_SUCCESS = 'GET_COOKIE_STORAGE_SUCCESS'
export const GET_COOKIE_STORAGE_FAILURE = 'GET_COOKIE_STORAGE_FAILURE'
export const GET_COOKIE_STORAGE_CANCEL = 'GET_COOKIE_STORAGE_CANCEL'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'
export const GET_USERS_CANCEL = 'GET_USERS_CANCEL'

export const GET_TASK_CHANGES_REQUEST = 'GET_TASK_CHANGES_REQUEST'
export const GET_TASK_CHANGES_SUCCESS = 'GET_TASK_CHANGES_SUCCESS'
export const GET_TASK_CHANGES_FAILURE = 'GET_TASK_CHANGES_FAILURE'
export const GET_TASK_CHANGES_CANCEL = 'GET_TASK_CHANGES_CANCEL'

export const GET_TEAM_LOGO_URL_REQUEST = 'GET_TEAM_LOGO_URL_REQUEST'
export const GET_TEAM_LOGO_URL_SUCCESS = 'GET_TEAM_LOGO_URL_SUCCESS'
export const GET_TEAM_LOGO_URL_FAILURE = 'GET_TEAM_LOGO_URL_FAILURE'
export const GET_TEAM_LOGO_URL_CANCEL = 'GET_TEAM_LOGO_URL_CANCEL'

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST'
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS'
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE'
export const GET_TEAMS_CANCEL = 'GET_TEAMS_CANCEL'

export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_CANCEL = 'GET_TASKS_CANCEL'

export const GET_UPLOADED_FILES_REQUEST = 'GET_UPLOADED_FILES_REQUEST'
export const GET_UPLOADED_FILES_SUCCESS = 'GET_UPLOADED_FILES_SUCCESS'
export const GET_UPLOADED_FILES_FAILURE = 'GET_UPLOADED_FILES_FAILURE'
export const GET_UPLOADED_FILES_CANCEL = 'GET_UPLOADED_FILES_CANCEL'

export const GET_INVITES_REQUEST = 'GET_INVITES_REQUEST'
export const GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS'
export const GET_INVITES_FAILURE = 'GET_INVITES_FAILURE'
export const GET_INVITES_CANCEL = 'GET_INVITES_CANCEL'

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE'
export const GET_MESSAGES_CANCEL = 'GET_MESSAGES_CANCEL'

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE'
export const GET_DASHBOARD_CANCEL = 'GET_DASHBOARD_CANCEL'

export const GET_USER_EXTENSION_REQUEST = 'GET_USER_EXTENSION_REQUEST'
export const GET_USER_EXTENSION_SUCCESS = 'GET_USER_EXTENSION_SUCCESS'
export const GET_USER_EXTENSION_FAILURE = 'GET_USER_EXTENSION_FAILURE'
export const GET_USER_EXTENSION_CANCEL = 'GET_USER_EXTENSION_CANCEL'

export const GET_USER_INVITES_REQUEST = 'GET_USER_INVITES_REQUEST'
export const GET_USER_INVITES_SUCCESS = 'GET_USER_INVITES_SUCCESS'
export const GET_USER_INVITES_FAILURE = 'GET_USER_INVITES_FAILURE'
export const GET_USER_INVITES_CANCEL = 'GET_USER_INVITES_CANCEL'

export const GET_CUSTOM_CLAIM_REQUEST = 'GET_CUSTOM_CLAIM_REQUEST'
export const GET_CUSTOM_CLAIM_SUCCESS = 'GET_CUSTOM_CLAIM_SUCCESS'
export const GET_CUSTOM_CLAIM_FAILURE = 'GET_CUSTOM_CLAIM_FAILURE'
export const GET_CUSTOM_CLAIM_CANCEL = 'GET_CUSTOM_CLAIM_CANCEL'

export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE'
export const GET_PLANS_CANCEL = 'GET_PLANS_CANCEL'

export const GET_TEAM_STAT_REQUEST = 'GET_TEAM_STAT_REQUEST'
export const GET_TEAM_STAT_SUCCESS = 'GET_TEAM_STAT_SUCCESS'
export const GET_TEAM_STAT_FAILURE = 'GET_TEAM_STAT_FAILURE'
export const GET_TEAM_STAT_CANCEL = 'GET_TEAM_STAT_CANCEL'

export const GET_UNSCOPE_USER_REQUEST = 'GET_UNSCOPE_USER_REQUEST'
export const GET_UNSCOPE_USER_SUCCESS = 'GET_UNSCOPE_USER_SUCCESS'
export const GET_UNSCOPE_USER_FAILURE = 'GET_UNSCOPE_USER_FAILURE'
export const GET_UNSCOPE_USER_CANCEL = 'GET_UNSCOPE_USER_CANCEL'

export const GET_STRIPE_SUBSCRIPTIONS_REQUEST =
  'GET_STRIPE_SUBSCRIPTIONS_REQUEST'
export const GET_STRIPE_SUBSCRIPTIONS_SUCCESS =
  'GET_STRIPE_SUBSCRIPTIONS_SUCCESS'
export const GET_STRIPE_SUBSCRIPTIONS_FAILURE =
  'GET_STRIPE_SUBSCRIPTIONS_FAILURE'
export const GET_STRIPE_SUBSCRIPTIONS_CANCEL = 'GET_STRIPE_SUBSCRIPTIONS_CANCEL'
