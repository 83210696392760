import { take, put } from 'redux-saga/effects'
import { CLEAR_TEAM_SCOPE } from 'actions'
import { createSetSessionStorageAction } from 'actionCreators/sessionStorage'

export const clearTeamScopeF = function*() {
  while (1) {
    yield take([CLEAR_TEAM_SCOPE])

    yield put(
      createSetSessionStorageAction({
        description: 'clearTeamScopeF',
        sessionStorage: {
          taskFilters: null,
          showExtraFilters: false,
        },
      })
    )
  }
}
