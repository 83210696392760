import { connect } from 'react-redux'
import { TaskFilterForm } from './TaskFilterForm'
import { TaskFilterFormMapState } from './TaskFilterFormMapState'
import { TaskFilterFormMapDispatch } from './TaskFilterFormMapDispatch'
import { TaskFilterFormMapStateReturnType } from './types/TaskFilterFormMapStateReturnType'
import { TaskFilterFormMapDispatchReturnType } from './types/TaskFilterFormMapDispatchReturnType'
import { TaskFilterFormConnectedProps } from './types/TaskFilterFormConnectedProps'

export const TaskFilterFormConnected = connect<
  TaskFilterFormMapStateReturnType,
  TaskFilterFormMapDispatchReturnType,
  TaskFilterFormConnectedProps
>(
  TaskFilterFormMapState,
  TaskFilterFormMapDispatch
)(TaskFilterForm)
