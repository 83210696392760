import { take, select } from 'redux-saga/effects'
import { parseUrl } from 'query-string'
import { QUERY_NAME_TEAM_ID } from 'constants/queryNameConstants'
import { NEW_PUSH_NOTIFICATION } from 'actions'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { NewPushNotificationAction } from 'actionCreators/types/NewPushNotificationAction'

export const newPushNofiticationF = function*() {
  while (1) {
    const action: NewPushNotificationAction = yield take([
      NEW_PUSH_NOTIFICATION,
    ])
    const { title, body, url } = action.pushNotification
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const { query } = parseUrl(url || '')

    if (query[QUERY_NAME_TEAM_ID] !== teamId) {
      new Notification(title, {
        body,
        vibrate: [300, 100, 400, 100, 400, 100, 400],
        icon: '/favicon.ico',
      }).addEventListener('click', () => {
        // Stuck here, need to determine to focus on another window or open
      })
    }
  }
}
