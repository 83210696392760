import { Dispatch, Action } from 'redux'
import { UpdateTaskSizesFormMapDispatchReturnType } from './types/UpdateTaskSizesFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_UPDATE_TASK_SIZES } from 'actions'

export const UpdateTaskSizesFormMapDispatch = (
  dispatch: Dispatch<Action>
): UpdateTaskSizesFormMapDispatchReturnType => {
  return {
    submit: ({ taskSizesCsv }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_UPDATE_TASK_SIZES,
          values: { taskSizesCsv },
          description: 'UpdateTaskSizesFormMapDispatch',
        })
      )
    },
  }
}
