 import { StateReducers } from 'state/reducers/types/StateReducers'
import { ToNextCycleButtonConnectedProps } from './types/ToNextCycleButtonConnectedProps'
import { ToNextCycleButtonMapStateReturnType } from './types/ToNextCycleButtonMapStateReturnType'

export const ToNextCycleButtonMapState = (
  _state: StateReducers,
  _props: ToNextCycleButtonConnectedProps,
): ToNextCycleButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
