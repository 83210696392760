import { IsPostLoginRoutes } from './types/IsPostLoginRoutes'

import {
  ACCOUNT_SETTINGS_ROUTE,
  ACTIVITY_NOTIFICATION_ROUTE,
  ADD_NEW_LABEL_ROUTE,
  ADD_NEW_MEMBER_ROUTE,
  ADMIN_ROUTE,
  CREATE_TASK_ROUTE,
  CURRENT_ROUTE,
  DASHBOARD_HOME_ROUTE,
  INVITE_DETAIL_ROUTE,
  LABELS_ROUTE,
  MEMBERS_ROUTE,
  MEMBER_DETAIL_ROUTE,
  PLAN_ROUTE,
  SELECT_TEAM_ROUTE,
  TASK_DETAIL_ROUTE,
  TASK_MESSAGES_ROUTE,
  TASK_SIZES_ROUTE,
  TASK_STATUSES_ROUTE,
  TASK_TAGS_ROUTE,
  TEAM_FILES_ROUTE,
  TEAM_SETTINGS_ROUTE,
  DELETE_WORKSPACE_ROUTE,
  REPORT_ROUTE,
  UPDATE_DISPLAY_NAME_ROUTE,
  UPDATE_TEAM_NAME_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  UPDATE_TASK_SIZES_ROUTE,
  UPDATE_TASK_STATUSES_ROUTE,
  NOTIFICATION_SETTINGS_ROUTE,
  UPGRADE_WORKSPACE_ROUTE,
  BILLING_ROUTE,
  postLoginRoutes,
  WELCOME_TO_PRO_ROUTE,
  WORKSPACE_SUBSCRIPTION_ROUTE,
  PRO_CANCELED_ROUTE,
  PAYMENT_FAILED_ROUTE,
  UPDATE_WORKSPACE_LOGO_ROUTE,
  UPDATE_AVATAR_ROUTE,
} from './postLoginRoutes'

interface Props {
  pathname: string
}

export const getIsPostLoginRoutes = ({
  pathname = '',
}: Props): IsPostLoginRoutes => {
  return {
    isAccountSettingsRoute: pathname === ACCOUNT_SETTINGS_ROUTE,
    isActivityNotificationRoute: pathname === ACTIVITY_NOTIFICATION_ROUTE,
    isAddNewLabelRoute: pathname === ADD_NEW_LABEL_ROUTE,
    isAddNewMemberRoute: pathname === ADD_NEW_MEMBER_ROUTE,
    isAdminRoute: pathname === ADMIN_ROUTE,
    isBillingRoute: pathname === BILLING_ROUTE,
    isCreateTaskRoute: pathname === CREATE_TASK_ROUTE,
    isCurrentRoute: pathname === CURRENT_ROUTE,
    isDashboardHomeRoute: pathname === DASHBOARD_HOME_ROUTE,
    isDeleteWorkspaceRoute: pathname === DELETE_WORKSPACE_ROUTE,
    isReportRoute: pathname === REPORT_ROUTE,
    isInviteDetailRoute: pathname === INVITE_DETAIL_ROUTE,
    isLabelsRoute: pathname === LABELS_ROUTE,
    isMemberDetailRoute: pathname === MEMBER_DETAIL_ROUTE,
    isMembersRoute: pathname === MEMBERS_ROUTE,
    isNotificationSettingsRoute: pathname === NOTIFICATION_SETTINGS_ROUTE,
    isPlanRoute: pathname === PLAN_ROUTE,
    isPostLoginRoute: postLoginRoutes.indexOf(pathname) > -1,
    isProCanceledRoute: pathname === PRO_CANCELED_ROUTE,
    isPaymentFailedRoute: pathname === PAYMENT_FAILED_ROUTE,
    isUpdateWorkspaceLogoRoute: pathname === UPDATE_WORKSPACE_LOGO_ROUTE,
    isSelectTeamRoute: pathname === SELECT_TEAM_ROUTE,
    isTaskMainRoute: pathname === TASK_DETAIL_ROUTE,
    isTaskMessagesRoute: pathname === TASK_MESSAGES_ROUTE,
    isTaskSizesRoute: pathname === TASK_SIZES_ROUTE,
    isTaskStatusesRoute: pathname === TASK_STATUSES_ROUTE,
    isTaskTagsRoute: pathname === TASK_TAGS_ROUTE,
    isTeamFilesRoute: pathname === TEAM_FILES_ROUTE,
    isTeamSettingsRoute: pathname === TEAM_SETTINGS_ROUTE,
    isUpdateDisplayNameRoute: pathname === UPDATE_DISPLAY_NAME_ROUTE,
    isUpdateTeamNameRoute: pathname === UPDATE_TEAM_NAME_ROUTE,
    isUpdatePasswordRoute: pathname === UPDATE_PASSWORD_ROUTE,
    isUpdateTaskSizesRoute: pathname === UPDATE_TASK_SIZES_ROUTE,
    isUpdateTaskStatusesRoute: pathname === UPDATE_TASK_STATUSES_ROUTE,
    isUpgradeWorkspaceRoute: pathname === UPGRADE_WORKSPACE_ROUTE,
    isWelcomeToProRoute: pathname === WELCOME_TO_PRO_ROUTE,
    isWorkspaceSubscriptionRoute: pathname === WORKSPACE_SUBSCRIPTION_ROUTE,
    isUpdateAvatarRoute: pathname === UPDATE_AVATAR_ROUTE,
  }
}
