import { css } from 'emotion'

import {
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARK,
  LINK_COLOR,
  BORDER_RADIUS,
  DANGER_COLOR,
  DANGER_COLOR_DARK,
  WARNING_COLOR,
  WARNING_COLOR_DARK,
  TEXT_COLOR,
  NEUTRAL_BORDER_COLOR,
  SUCCESS_COLOR,
  SUCCESS_COLOR_DARK,
} from 'constants/styleConstants'

export const ActionButtonStyles = css`
  background-color: ${PRIMARY_COLOR};
  border: none;
  border-radius: ${BORDER_RADIUS};
  color: #fff;
  padding: 8px 16px;
  font-size: 0.9em;
  line-height: 1.5em;
  cursor: pointer;
  letter-spacing: 1px;
  text-align: center;
  white-space: initial;
  text-decoration: none;
  display: inline-block;
  text-transform: capitalize;

  svg,
  span {
    vertical-align: middle;
  }

  /* svg {
    margin-right: 2px;

    &:only-child {
      margin-right: 0;
    }
  } */

  &.ButtonLink {
    color: #fff;
  }

  &.big {
    padding: 22px;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5em;
    text-transform: uppercase;
  }

  &.active {
    background: ${LINK_COLOR};

    &:hover {
      background: ${LINK_COLOR};
    }
  }

  &.danger {
    background: ${DANGER_COLOR};
  }

  &.success {
    background: ${SUCCESS_COLOR};
  }

  &.neutral {
    background: #aaa;
  }

  &.warning {
    background: ${WARNING_COLOR};
  }

  &.hollow {
    background-color: initial;
    color: ${TEXT_COLOR};
    border: 1px solid ${NEUTRAL_BORDER_COLOR};
  }

  &:disabled,
  &[disabled] {
    background: #d7d7d7;
  }

  &:hover,
  &:active {
    background: ${PRIMARY_COLOR_DARK};

    &.danger {
      background: ${DANGER_COLOR_DARK};
    }

    &.success {
      background: ${SUCCESS_COLOR_DARK};
    }

    &.warning {
      background: ${WARNING_COLOR_DARK};
    }

    &.neutral {
      background: #999;
    }

    &.hollow {
      color: #fff;
    }

    &:disabled,
    &[disabled] {
      background: #d7d7d7;
    }
  }
`
