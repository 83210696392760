import { ColorPickerWidgetStyles } from './ColorPickerWidgetStyles'
import React from 'react'
import { ColorPickerWidgetProps } from './types/ColorPickerWidgetProps'

const colors = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
]

export const ColorPickerWidget = ({
  onColorClick,
}: ColorPickerWidgetProps): JSX.Element => {
  const onClick = (e: React.MouseEvent<HTMLUListElement>) => {
    const target = e.target as any

    if (onColorClick && target.getAttribute) {
      onColorClick(
        target.getAttribute('value')
      )
    }
  }

  return (
    <ul className={'ColorPickerWidget ' + ColorPickerWidgetStyles} onClick={onClick}>
      {colors.map((color, i) => {
        return (
          <li key={i} style={{
            backgroundColor: color,
          }} value={color} />
        )
      })}
    </ul>
  )
}
