import { UserAccountHeaderStyles } from './UserAccountHeaderStyles'
import * as React from 'react'
import { UserAccountHeaderProps } from './types/UserAccountHeaderProps'
import { AvatarConnected } from 'components/widget/Avatar'
import { SignOutButtonConnected } from 'components/button/SignOutButton'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { ACCOUNT_SETTINGS_ROUTE } from 'routes'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DisplayNameSpanConnected } from '../../span/DisplayNameSpan'
import { UserEmailSpanConnected } from '../../span/UserEmailSpan/UserEmailSpanConnected'
import { textSmallestStyles } from '../../../styles/text/textSmallestStyles'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'
import { footerDarkStyles } from '../../../styles/footerDarkStyles'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'
import { flexSpaceBetweenStyles } from '../../../styles/flex/flexSpaceBetweenStyles'
import { displayBlockStyles } from '../../../styles/display/displayBlockStyles'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'
import { textSmallStyles } from '../../../styles/text/textSmallStyles'

export const UserAccountHeader = ({
  showAccountDropdown,
  isMediumScreen,
  className = '',
}: UserAccountHeaderProps): JSX.Element => {
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''

  return (
    <div
      className={`UserAccountHeader ${UserAccountHeaderStyles} ${isMediumScreenClassName} ${className}`}
    >
      <DropdownButtonConnected
        title={`Your account`}
        dropdownType="account"
        watchSelector=".UserAccountHeader"
      >
        <AvatarConnected />
      </DropdownButtonConnected>

      {showAccountDropdown && (
        <DropdownConnected autoAdjust={true}>
          <main>
            <AvatarConnected showChange={true} size="big" />

            <div>
              <div>
                <DisplayNameSpanConnected />
              </div>

              <div className={`${textSmallestStyles} ${marginTopSmallStyles}`}>
                <UserEmailSpanConnected />
              </div>
            </div>
          </main>

          <footer
            className={`${footerDarkStyles} ${paddingSmallStyles} ${flexSpaceBetweenStyles}`}
          >
            <CustomLinkConnected
              title="account settings"
              route={ACCOUNT_SETTINGS_ROUTE}
              className={`${textSmallStyles} ${textLinkStyles} ${paddingSmallStyles} ${displayBlockStyles}`}
              withCurrentQuery={true}
            >
              Account settings
            </CustomLinkConnected>

            <SignOutButtonConnected />
          </footer>
        </DropdownConnected>
      )}
    </div>
  )
}
