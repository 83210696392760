import { UploadFilesListStyles } from './UploadFilesListStyles'
import React from 'react'
import { UploadFilesListProps } from './types/UploadFilesListProps'
import { createEmptyArray } from 'utilities/create/createEmptyArray'
import { UploadFilesCardConnected } from 'components/card/UploadFilesCard'

export const UploadFilesList = ({
  length,
}: UploadFilesListProps): JSX.Element => {
  return (
    <ul className={'UploadFilesList ' + UploadFilesListStyles}>
      {createEmptyArray(length).map((index) => {
        return (
          <li key={index}>
            <UploadFilesCardConnected uploadFileIndex={index} />
          </li>
        )
      })}
    </ul>
  )
}
