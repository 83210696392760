import { LOCATION_CHANGE } from 'actions'
import { Action } from 'redux'
import { CurrentLocationR } from './types/CurrentLocationR'
import { LocationChangeAction } from 'actionCreators/types/LocationChangeAction'

export const currentLocationReducer = (
  state?: CurrentLocationR,
  action?: Action
): CurrentLocationR | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case LOCATION_CHANGE: {
      const locationChangeAction = action as LocationChangeAction
      const { values } = locationChangeAction

      return values
    }

    default: {
      return state || null
    }
  }
}
