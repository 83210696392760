import { Task } from 'firebaseFunctions/types'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from './getCurrentTaskId'

interface Props {
  state: StateReducers
  taskId?: string
}

export const getCurrentTask = ({ state, taskId }: Props): Task | undefined => {
  const useTaskId = taskId || getCurrentTaskId({ state })
  const task = useTaskId ? state.tasks?.[useTaskId] : undefined

  return task?.data
}
