import React from 'react'
import clsx from 'clsx'

const MentionLink = ({ mention, children, className }: any) => (
  <a href={mention.link} className={className} spellCheck={false}>
    {children}
  </a>
)

const MentionText = ({ children, className }: any) => (
  <span className={className} spellCheck={false}>
    {children}
  </span>
)

export const Mention = (props: any) => {
  const {
    entityKey,
    theme = {},
    mentionComponent,
    children,
    decoratedText,
    className = '',
    contentState,
  } = props

  const combinedClassName = clsx(theme.mention, className)
  const mention = contentState.getEntity(entityKey).getData().mention

  const Component =
    mentionComponent || (mention.link ? MentionLink : MentionText)

  return (
    <Component
      entityKey={entityKey}
      mention={mention}
      theme={theme}
      className={combinedClassName}
      decoratedText={decoratedText}
    >
      {children}
    </Component>
  )
}
