import { TaskFileListStyles } from './TaskFileListStyles'
import React, { useEffect, useState } from 'react'
import { TaskFileListProps } from './types/TaskFileListProps'
import { getTaskFiles } from 'utilities/get/getTaskFiles'
import { THUMB_FILE_NAME_PREFIX } from 'firebaseFunctions/constants'
import { TaskFileCardConnected } from 'components/card/TaskFileCard'

export const TaskFileList = ({
  taskId,
  teamId,
  updatedSeconds,
  setHasUploadedFiles,
}: TaskFileListProps): JSX.Element => {
  const [fileNamesState, setFilesState] = useState<string[] | null>(null)

  useEffect(() => {
    const getFileNames = async () => {
      if (teamId && taskId) {
        const fileNames = await getTaskFiles({ teamId, taskId })

        setFilesState(fileNames)

        if (fileNames.length) {
          setHasUploadedFiles?.(true)
        }
      }
    }

    getFileNames()
  }, [updatedSeconds, teamId, taskId, setHasUploadedFiles])

  return (
    <ul className={'TaskFileList ' + TaskFileListStyles}>
      {fileNamesState &&
        fileNamesState.map((fileName) => {
          const isThumb = fileName.indexOf(THUMB_FILE_NAME_PREFIX) === 0

          if (!isThumb) {
            return (
              <li key={fileName}>
                <TaskFileCardConnected fileName={fileName} />
              </li>
            )
          }

          return null
        })}
    </ul>
  )
}
