import { CheveronRightIconStyles } from './CheveronRightIconStyles'
import React from 'react'
import { CheveronRightIconProps } from './types/CheveronRightIconProps'
import { CheveronLeftIcon } from '../CheveronLeftIcon/CheveronLeftIcon'

export const CheveronRightIcon = ({
  className = '',
}: CheveronRightIconProps): JSX.Element => {
  return (
    <CheveronLeftIcon
      className={`CheveronRightIcon ${CheveronRightIconStyles} ${className}`}
    />
  )
}
