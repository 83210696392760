import { StripeInvoiceListStyles } from './StripeInvoiceListStyles'
import React from 'react'
import { StripeInvoiceListProps } from './types/StripeInvoiceListProps'
import { StripeInvoiceCard } from '../../card/StripeInvoiceCard/StripeInvoiceCard'
import { map } from 'lodash'

export const StripeInvoiceList = ({
  className = '',
  stripeInvoices,
}: StripeInvoiceListProps): JSX.Element => {
  return (
    <ul className={`StripeInvoiceList ${StripeInvoiceListStyles} ${className}`}>
      {map(stripeInvoices, (stripeInvoice, id) => {
        return (
          <li key={id}>
            <StripeInvoiceCard stripeInvoice={stripeInvoice} />
          </li>
        )
      })}
    </ul>
  )
}
