import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'

interface Props {
  state: StateReducers
}

export const getUserCount = ({ state }: Props): number => {
  const teamReducer = getCurrentTeamReducer({ state })
  const userCount = Number(teamReducer?.stats?.userCount)

  if (isNaN(userCount)) {
    return 0
  }

  return userCount
}
