import { TaskStatusesMainStyles } from './TaskStatusesMainStyles'
import React from 'react'
// import { TaskStatusesMainProps } from './types/TaskStatusesMainProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { TaskStatusListConnected } from 'components/list/TaskStatusList'
import { UPDATE_TASK_STATUSES_ROUTE } from 'routes'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { EditIcon } from 'components/icon/EditIcon/EditIcon'
import { flexRightStyles } from 'styles/flex/flexRightStyles'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'

export const TaskStatusesMain = (): JSX.Element => {
  return (
    <main className={'TaskStatusesMain ' + TaskStatusesMainStyles}>
      <HeaderOneTextConnected>Configure Task Statuses</HeaderOneTextConnected>

      <header className={flexRightStyles}>
        <CustomLinkConnected
          title="configure task statuses"
          route={UPDATE_TASK_STATUSES_ROUTE}
          className={actionButtonStyles}
          withCurrentQuery={true}
        >
          <IconAndDiv icon={<EditIcon />}>Customize</IconAndDiv>
        </CustomLinkConnected>
      </header>

      <div className={`${lightBoxStyles} ${marginTopMediumStyles}`}>
        <TaskStatusListConnected />
      </div>
    </main>
  )
}
