import * as React from 'react'
import { DisplayNameSpanConnected } from './DisplayNameSpanConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const DisplayNameSpanExamples = () => {
  return (
    <div className="DisplayNameSpanExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <DisplayNameSpanConnected />
      </Provider>
    </div>
  )
}
