import { put } from 'redux-saga/effects'
import { deleteCustomClaimApi } from 'apis/delete/deleteCustomClaimApi'
import { createDeleteCustomClaimSuccessAction } from 'actionCreators/delete/DeleteCustomClaim/createDeleteCustomClaimSuccessAction'
import { createDeleteCustomClaimFailureAction } from 'actionCreators/delete/DeleteCustomClaim/createDeleteCustomClaimFailureAction'
import { createDeleteCustomClaimRequestAction } from 'actionCreators/delete/DeleteCustomClaim/createDeleteCustomClaimRequestAction'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { DeleteCustomClaimApiProps } from 'apis/delete/DeleteCustomClaimApiProps'

interface Props extends DeleteCustomClaimApiProps {}

export const deleteCustomClaimSaga = function*({ apiHeaders }: Props) {
  yield put(
    createDeleteCustomClaimRequestAction({
      requestValues: {},
    })
  )

  try {
    yield deleteCustomClaimApi({
      apiHeaders,
    })

    yield put(createDeleteCustomClaimSuccessAction())
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'deleteCustomClaimSaga',
      })
    )

    yield put(createDeleteCustomClaimFailureAction())
  }
}
