import { PasswordInputStyles } from './PasswordInputStyles'
import React, { useState } from 'react'
import { PasswordInputProps } from './types/PasswordInputProps'
import { Button } from 'components/button/Button'
import { TextInput } from '../TextInput'
import { EyeSolidIcon } from '../../icon/EyeSolidIcon/EyeSolidIcon'
import { EyeSlashSolidIcon } from '../../icon/EyeSlashSolidIcon/EyeSlashSolidIcon'

export const PasswordInput = ({
  className = '',
  ...props
}: PasswordInputProps): JSX.Element => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password')
  const isHidden = inputType === 'password'

  const handleOnClick = () => {
    setInputType(isHidden ? 'text' : 'password')
  }

  return (
    <div className={`PasswordInput ${PasswordInputStyles} ${className}`}>
      <TextInput {...props} type={inputType} />

      <Button
        type="button"
        className="toggleVisibility"
        onClick={handleOnClick}
        tabIndex={-1}
        title={isHidden ? 'show password' : 'hide password'}
      >
        {isHidden && <EyeSolidIcon />}
        {!isHidden && <EyeSlashSolidIcon />}
      </Button>
    </div>
  )
}
