import { TaskPlanSpanStyles } from './TaskPlanSpanStyles'
import React from 'react'
import { TaskPlanSpanProps } from './types/TaskPlanSpanProps'

export const TaskPlanSpan = ({
  isPreviousPlan,
  isNextPlan,
  isCurrentPlan,
  isBacklog,
  backLogText,
}: TaskPlanSpanProps): JSX.Element => {
  const isArchived =
    !isPreviousPlan && !isNextPlan && !isCurrentPlan && !isBacklog

  return (
    <span className={'TaskPlanSpan ' + TaskPlanSpanStyles}>
      {isPreviousPlan && 'Previous'}

      {isNextPlan && 'Next'}

      {isCurrentPlan && 'Current'}

      {isArchived && 'Archived'}

      {isBacklog && backLogText}
    </span>
  )
}
