import { connect } from 'react-redux'
import { TeamStatWatcher } from './TeamStatWatcher'
import { TeamStatWatcherMapState } from './TeamStatWatcherMapState'
import { TeamStatWatcherMapDispatch } from './TeamStatWatcherMapDispatch'

import { TeamStatWatcherMapStateReturnType } from './types/TeamStatWatcherMapStateReturnType'
import { TeamStatWatcherMapDispatchReturnType } from './types/TeamStatWatcherMapDispatchReturnType'
import { TeamStatWatcherConnectedProps } from './types/TeamStatWatcherConnectedProps'


export const TeamStatWatcherConnected = connect<
  TeamStatWatcherMapStateReturnType,
  TeamStatWatcherMapDispatchReturnType,
  TeamStatWatcherConnectedProps>(TeamStatWatcherMapState,
    TeamStatWatcherMapDispatch)(TeamStatWatcher)
