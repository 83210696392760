import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { storageSdk } from 'sdks'
import { Uploads } from 'types/Uploads'
import { createId } from 'firebaseFunctions/utilities/createId'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { getTeamLogoStoragePath } from 'firebaseFunctions/utilities/getTeamLogoStoragePath'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import {
  SUBMIT_NEW_TEAM_LOGO,
  UPLOAD_SUCCESS,
  SUBMIT_NEW_TEAM_LOGO_DONE,
} from 'actions'
import { createGetTeamLogoUrlSuccessAction } from 'actionCreators/get/GetTeamLogoUrl/createGetTeamLogoUrlSuccessAction'
import { createUploadRequestAction } from 'actionCreators/Upload/createUploadRequestAction'
import { UploadSuccessAction } from 'actionCreators/Upload/types/UploadSuccessAction'

export const submitNewTeamLogoF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_NEW_TEAM_LOGO])

    const uploadsMutable: Uploads = {}
    const file = action.values.files?.[0]
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })

    if (file && teamId) {
      try {
        const uploadId = createId()
        const ref = storageSdk().ref()
        const { originalPath } = getTeamLogoStoragePath({ teamId })
        const uploadRef = ref.child(originalPath)
        const uploadMeta: firebase.storage.UploadMetadata = {}

        uploadsMutable[uploadId] = {
          data: file,
          ref: uploadRef,
          meta: uploadMeta,
          status: 'uploading',
        }

        yield put(
          createUploadRequestAction({
            requestValues: uploadsMutable,
          })
        )

        const uploadSuccessAction: UploadSuccessAction = yield take([
          UPLOAD_SUCCESS,
        ])
        const { key, item: upload } = getObjectFirstItem(
          uploadSuccessAction.successValues
        )
        const url = upload?.url

        if (url && key && uploadId === key) {
          yield put(
            createGetTeamLogoUrlSuccessAction({
              requestValues: {
                teamId,
              },
              successValues: {
                logoUrl: url,
              },
            })
          )
        }
      } catch (error) {
        const firestoreErrorAction = createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'submitNewTeamLogoF',
        })

        yield put(firestoreErrorAction)
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewTeamLogoF.ts',
        })
      )
    }

    // TODO: move all the submit done to the end
    yield put({
      type: SUBMIT_NEW_TEAM_LOGO_DONE,
    })
  }
}
