import { PATCH_MESSAGES_SUCCESS } from 'actions'

import { PatchMessagesSuccessAction } from './types/PatchMessagesSuccessAction'
import { PatchMessagesSuccessActionValues } from './types/PatchMessagesSuccessActionValues'
import { PatchMessagesRequestActionValues } from './types/PatchMessagesRequestActionValues'


interface Props {
  successValues: PatchMessagesSuccessActionValues
  requestValues: PatchMessagesRequestActionValues
}

export const createPatchMessagesSuccessAction = ({
  successValues,
  requestValues,
}: Props): PatchMessagesSuccessAction => {
  return {
    type: PATCH_MESSAGES_SUCCESS,
    requestValues,
    successValues,
  }
}
