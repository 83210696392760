import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { SUBMIT_TASK_STATUS } from 'actions'
import { StatusDropdownMapDispatchReturnType } from './types/StatusDropdownMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const StatusDropdownMapDispatch = (
  dispatch: Dispatch<Action>
): StatusDropdownMapDispatchReturnType => {
  return {
    closeDropdown: () => {
      dispatch(
        createToggleAction({
          description: 'StatusDropdownMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },

    submitStatus: ({ status, taskId }) => {
      dispatch(
        createSubmitAction({
          values: {
            taskId,
            status,
          },
          type: SUBMIT_TASK_STATUS,
        })
      )
    },
  }
}
