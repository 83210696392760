import * as React from 'react'
import { TaskSizeFieldConnected } from './TaskSizeFieldConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const TaskSizeFieldExamples = () => {
  return (
    <div className="TaskSizeFieldExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <TaskSizeFieldConnected taskId="1212" />
      </Provider>
    </div>
  )
}
