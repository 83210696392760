import { StateReducers } from 'state/reducers/types/StateReducers'

import { MessageActionDropdownConnectedProps } from './types/MessageActionDropdownConnectedProps'
import { MessageActionDropdownMapStateReturnType } from './types/MessageActionDropdownMapStateReturnType'


export const MessageActionDropdownMapState = (
  state: StateReducers,
  props: MessageActionDropdownConnectedProps
): MessageActionDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const showMessageActionDropdown =
    showDropdown &&
    showDropdown.type === 'messageActions' &&
    showDropdown.id === props.messageId

  return {
    showMessageActionDropdown,
  }
}
