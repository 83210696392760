import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  PATCH_CUSTOM_CLAIM_SUCCESS,
  PATCH_CUSTOM_CLAIM_FAILURE,
  PATCH_CUSTOM_CLAIM_CANCEL,
} from 'actions'

export const hideCustomClaimSpinnerF = function*() {
  while (1) {
    yield take([
      PATCH_CUSTOM_CLAIM_SUCCESS,
      PATCH_CUSTOM_CLAIM_FAILURE,
      PATCH_CUSTOM_CLAIM_CANCEL,
    ])

    const toggleAction = createToggleAction({
      description: 'hideCustomClaimSpinnerF',
      toggles: {
        showCustomClaimSpinner: false,
      },
    })

    yield put(toggleAction)
  }
}
