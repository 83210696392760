import { connect } from 'react-redux'
import { MemberMain } from './MemberMain'
import { MemberMainMapState } from './MemberMainMapState'
import { MemberMainMapDispatch } from './MemberMainMapDispatch'

import { MemberMainMapStateReturnType } from './types/MemberMainMapStateReturnType'
import { MemberMainMapDispatchReturnType } from './types/MemberMainMapDispatchReturnType'
import { MemberMainConnectedProps } from './types/MemberMainConnectedProps'


export const MemberMainConnected = connect<
  MemberMainMapStateReturnType,
  MemberMainMapDispatchReturnType,
  MemberMainConnectedProps>(MemberMainMapState,
    MemberMainMapDispatch)(MemberMain)
