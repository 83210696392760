import { connect } from 'react-redux'
import { WorkingMessageList } from './WorkingMessageList'
import { WorkingMessageListMapState } from './WorkingMessageListMapState'
import { WorkingMessageListMapDispatch } from './WorkingMessageListMapDispatch'

import { WorkingMessageListMapStateReturnType } from './types/WorkingMessageListMapStateReturnType'
import { WorkingMessageListMapDispatchReturnType } from './types/WorkingMessageListMapDispatchReturnType'
import { WorkingMessageListConnectedProps } from './types/WorkingMessageListConnectedProps'


export const WorkingMessageListConnected = connect<
  WorkingMessageListMapStateReturnType,
  WorkingMessageListMapDispatchReturnType,
  WorkingMessageListConnectedProps>(WorkingMessageListMapState,
    WorkingMessageListMapDispatch)(WorkingMessageList)
