import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { storageSdk } from 'sdks'
import { uploadSuccessAvatarSaga } from '../sagas/uploadSuccessAvatarSaga'
import { createId } from 'firebaseFunctions/utilities/createId'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { SUBMIT_AVATAR_UPLOAD, SUBMIT_AVATAR_UPLOAD_DONE } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { ACCOUNT_SETTINGS_ROUTE } from 'routes'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { createUploadRequestAction } from 'actionCreators/Upload/createUploadRequestAction'

export const submitAvatarUploadF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_AVATAR_UPLOAD])
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const teamId = getCurrentTeamId({ state })
    const { fileBlob } = action.values
    const uploadId = createId()

    if (fileBlob && currentUserId) {
      const ref = storageSdk().ref()
      const avatarsRef = ref.child(`${currentUserId}/avatar.jpg`)
      const uploadMeta: firebase.storage.UploadMetadata = {}

      const uploadRequestAction = createUploadRequestAction({
        requestValues: {
          [uploadId]: {
            data: fileBlob,
            status: 'uploading',
            description: 'submitAvatarUploadF',
            ref: avatarsRef,
            meta: uploadMeta,
          },
        },
      })

      yield put(uploadRequestAction)

      yield put(
        createHistoryAction({
          description: 'submitAvatarUploadF',
          pathname: ACCOUNT_SETTINGS_ROUTE,
          query: {
            teamId,
          },
        })
      )

      yield spawn(uploadSuccessAvatarSaga, uploadId)
    } else {
      // TODO: createUnexpectedErrorAction and include where it happened.
      yield put(
        createUnexpectedErrorAction({
          description: 'submitAvatarUploadF',
        })
      )
    }

    yield put({
      type: SUBMIT_AVATAR_UPLOAD_DONE,
    })
  }
}
