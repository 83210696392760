import { HistoryListStyles } from './HistoryListStyles'
import React from 'react'
import { HistoryListProps } from './types/HistoryListProps'
import { HistoryCard } from 'components/card/HistoryCard'

export const HistoryList = ({
  taskIds = [],
  summaryItems = [],
  createdSeconds = [],
}: HistoryListProps): JSX.Element => {
  return (
    <ul className={'HistoryList ' + HistoryListStyles}>
      <li className="header">View History</li>

      {taskIds.length === 0 && <li className="empty">No history</li>}

      {taskIds.map((taskId, i) => {
        return (
          <li key={i}>
            <HistoryCard
              taskId={taskId}
              createdSeconds={createdSeconds[i]}
              taskSummary={summaryItems[i]}
            />
          </li>
        )
      })}
    </ul>
  )
}
