import { ActionButtonConnectedProps } from './types/ActionButtonConnectedProps'
import { ActionButtonMapStateReturnType } from './types/ActionButtonMapStateReturnType'

import { StateReducers } from 'state/reducers/types/StateReducers'

export const ActionButtonMapState = (
  state: StateReducers,
  props: ActionButtonConnectedProps
): ActionButtonMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showFormSpinner = toggles && toggles.showFormSpinner
  const showRecaptcha = toggles && toggles.showRecaptcha

  return {
    disabled: showFormSpinner || showRecaptcha || props.disabled,
  }
}
