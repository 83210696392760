import { UpdateTaskSizesFormStyles } from './UpdateTaskSizesFormStyles'
import React, { useEffect, useCallback, useState } from 'react'
import { UpdateTaskSizesFormProps } from './types/UpdateTaskSizesFormProps'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { FormFooter } from 'components/footer/FormFooter'

export const UpdateTaskSizesForm = ({
  taskSizesCsv,
  submit,
}: UpdateTaskSizesFormProps): JSX.Element => {
  const [sizesState, setSizesState] = useState(taskSizesCsv)
  const [errorState, setErrorState] = useState<string | null>(null)

  const validateSizes = useCallback(() => {
    const error = sizesState ? null : 'Please enter some sizes.'

    setErrorState(error || null)

    return error
  }, [sizesState])

  useEffect(() => {
    setSizesState(taskSizesCsv)
  }, [taskSizesCsv])

  return (
    <form
      className={`UpdateTaskSizesForm ${UpdateTaskSizesFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const error = validateSizes()

        if (!error && sizesState) {
          submit({ taskSizesCsv: sizesState })
        }
      }}
    >
      <h1>Configure Task Sizes</h1>

      <p>
        Separate each status with a comma. <strong>Example:</strong> Small,
        Medium, Large or 1, 3, 5, 7
      </p>

      <TextInputField
        type="textarea"
        placeholder="Open, In Progress, Closed"
        value={sizesState}
        error={errorState}
        onChange={(e) => {
          setSizesState(e.target.value)
        }}
        className={errorState ? 'hasError' : ''}
        onFocus={() => {
          setErrorState(null)
        }}
      />

      <FormFooter label="Save" />
    </form>
  )
}
