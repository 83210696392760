import React from 'react'
import { DisplayNameSpanProps } from './types/DisplayNameSpanProps'

export const DisplayNameSpan = ({
  displayName,
  children,
}: DisplayNameSpanProps): JSX.Element => {
  return (
    <span className={'DisplayNameSpan'}>
      {displayName || children || 'Unkown'}
    </span>
  )
}
