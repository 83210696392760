import { connect } from 'react-redux'
import { TaskActionList } from './TaskActionList'
import { TaskActionListMapState } from './TaskActionListMapState'
import { TaskActionListMapDispatch } from './TaskActionListMapDispatch'

import { TaskActionListMapStateReturnType } from './types/TaskActionListMapStateReturnType'
import { TaskActionListMapDispatchReturnType } from './types/TaskActionListMapDispatchReturnType'
import { TaskActionListConnectedProps } from './types/TaskActionListConnectedProps'


export const TaskActionListConnected = connect<
  TaskActionListMapStateReturnType,
  TaskActionListMapDispatchReturnType,
  TaskActionListConnectedProps>(TaskActionListMapState,
    TaskActionListMapDispatch)(TaskActionList)
