import { take, put, select, spawn } from 'redux-saga/effects'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { SUBMIT_DELETE_WORKSPACE, SUBMIT_DELETE_WORKSPACE_DONE } from 'actions'
import { SELECT_TEAM_ROUTE } from 'routes'
import { deleteTeamsSaga } from 'flows/sagas/delete/deleteTeamsSaga'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TeamsReducer } from 'state/reducers/types/TeamsReducer'
import { createSetAlertMessageAction } from '../../../actionCreators/set/SetAlertMessage/createSetAlertMessageAction'
import { createUnexpectedErrorAction } from '../../../actionCreators/createUnexpectedErrorAction'

// TODO: clean up the delete, remove all uploaded files and delete all children

export const submitDeleteWorkspaceF = function*() {
  while (1) {
    yield take([SUBMIT_DELETE_WORKSPACE])

    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })

    if (teamId) {
      const items: TeamsReducer = {
        [teamId]: {
          data: {},
        },
      }

      yield spawn(deleteTeamsSaga, { items })

      yield put(
        createHistoryAction({
          description: 'submitDeleteWorkspaceF',
          pathname: SELECT_TEAM_ROUTE,
        })
      )

      yield put(
        createSetAlertMessageAction({
          description: 'submitClosePlanF',
          values: {
            location: 'toast',
            type: 'success',
            message: 'Deleted',
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitDeleteWorkspaceF',
        })
      )
    }

    yield put({
      type: SUBMIT_DELETE_WORKSPACE_DONE,
    })
  }
}
