import { css } from 'emotion'

export const AvatarStyles = css`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 0.95em;
  position: relative;

  img {
    width: 100%;
  }

  &.big {
    width: 75px;
    height: 75px;
    font-size: 1.75em;
  }

  &.small {
    width: 24px;
    height: 24px;
    font-size: 0.75em;
  }

  &.initials {
    border: 1px solid #333;
    background-color: #455a64;
    color: #d3d8da;
  }

  .initials {
    text-align: center;
    text-transform: capitalize;
  }

  .CustomLink {
    position: absolute;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    letter-spacing: 0.5px;
    color: white;
    width: 100%;
    bottom: 0px;
    padding: 4px 0px 8px 0px;
    text-align: center;
  }
`
