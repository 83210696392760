import { GET_UNSCOPE_USER_REQUEST } from 'actions'
import { GetUnscopeUserRequestAction } from './types/GetUnscopeUserRequestAction'
import { GetUnscopeUserRequestActionValues } from './types/GetUnscopeUserRequestActionValues'

interface Props {
  requestValues: GetUnscopeUserRequestActionValues,
}

export const createGetUnscopeUserRequestAction = ({ requestValues }: Props): GetUnscopeUserRequestAction => {
  return {
    type: GET_UNSCOPE_USER_REQUEST,
    requestValues,
  }
}
