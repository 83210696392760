import * as React from 'react'
import { TaskStatusButtonListConnected } from './TaskStatusButtonListConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const TaskStatusButtonListExamples = () => {
  return (
    <div className="TaskStatusButtonListExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <TaskStatusButtonListConnected />
      </Provider>
    </div>
  )
}
