import { StateReducers } from 'state/reducers/types/StateReducers'
import { StripeSubscriptionDropdownConnectedProps } from './types/StripeSubscriptionDropdownConnectedProps'
import { StripeSubscriptionDropdownMapStateReturnType } from './types/StripeSubscriptionDropdownMapStateReturnType'

export const StripeSubscriptionDropdownMapState = (
  state: StateReducers,
  props: StripeSubscriptionDropdownConnectedProps
): StripeSubscriptionDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown

  const showStripeSubscriptionDropdown =
    showDropdown &&
    showDropdown.type === 'stripeSubscription' &&
    showDropdown.id === props.stripeSubscriptionId

  return {
    showStripeSubscriptionDropdown,
  }
}
