// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // MainWatcherConnectedProps,
  MainWatcherMapDispatchReturnType,
} from './types'

export const MainWatcherMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MainWatcherConnectedProps,
): MainWatcherMapDispatchReturnType => {
  return {
  }
}
