import { put } from 'redux-saga/effects'
import { cookieStore } from 'utilities/cookieStore'
import { CookieStorage } from 'types/CookieStorage'
import { PLATFORM, PRIMARY_STORAGE_KEY } from 'constants/cookieConstants'
import { createGetCookieStorageSuccessAction } from 'actionCreators/cookieStorage'

export const getCookieStorageF = function*() {
  const values: CookieStorage = cookieStore.get(PRIMARY_STORAGE_KEY) || {}
  const platform = cookieStore.get(PLATFORM)

  yield put(
    createGetCookieStorageSuccessAction({
      values: {
        ...values,
        platform,
      },
    })
  )
}
