import { getCurrentTeamId } from './getCurrentTeamId'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { TeamReducer } from 'state/reducers/types/TeamReducer'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCurrentTeamReducer = ({
  state,
  teamId,
}: Props): TeamReducer | undefined => {
  const useTeamId = teamId || getCurrentTeamId({ state })
  const teams = state.teams || undefined
  const teamReducer = useTeamId ? teams?.[useTeamId] : undefined

  return teamReducer
}
