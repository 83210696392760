import { useEffect } from 'react'
import { TeamWatcherProps } from './types/TeamWatcherProps'
import { firestore } from 'sdks'
import { getTeamRef } from '../../../firebaseFunctions/utilities/ref/get/getTeamRef'

export const TeamWatcher = ({
  incrementWatcher,
  teamId,
  onNext,
  onFirebaseError,
  clearTeamScope,
}: TeamWatcherProps): null => {
  useEffect(() => {
    const unsubscribe = getTeamRef({ firestore, teamId }).onSnapshot(
      onNext,
      onFirebaseError
    )

    incrementWatcher(1)

    return () => {
      clearTeamScope()
      unsubscribe()
      incrementWatcher(-1)
    }
  }, [teamId, incrementWatcher, onFirebaseError, onNext, clearTeamScope])

  return null
}
