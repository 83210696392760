import { StateReducers }  from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

export const getCurrentPlanId = ({ state }: Props): string | undefined => {
  const currentLocation = state.currentLocation || undefined

  return currentLocation && currentLocation?.query?.planId
}
