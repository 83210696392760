import { TaskDescriptionMainStyles } from './TaskDescriptionMainStyles'
import React, { useEffect, useState } from 'react'
import { TaskDescriptionMainProps } from './types/TaskDescriptionMainProps'
import moment from 'moment'
import { TaskDescriptionEditorConnected } from 'components/editor/TaskDescriptionEditor'
import { WORKING_STALE_SECONDS } from 'constants/environmentConstants'
import { UserWorkingWidget } from 'components/widget/UserWorkingWidget'
import { MarkdownDiv } from '../../div/MarkdownDiv'
import { paddingLeftRightMediumStyles } from '../../../styles/padding/paddingLeftRightMediumStyles'

export const TaskDescriptionMain = ({
  currentUserId,
  workingOnDescriptionUserId,
  workingOnDescriptionUpdatedSeconds,
  taskId,
  canWrite,
  description,
}: TaskDescriptionMainProps): JSX.Element => {
  const [showWorkingState, setShowWorkingState] = useState(true)

  // Code is very similar to TaskSummaryMain, dont want to DRY yet because its not worth it
  useEffect(() => {
    const staleMoment = moment().subtract(WORKING_STALE_SECONDS, 'seconds')
    const workingOnDescriptionMoment = workingOnDescriptionUpdatedSeconds
      ? moment.unix(workingOnDescriptionUpdatedSeconds)
      : undefined

    const isWorking =
      workingOnDescriptionMoment &&
      workingOnDescriptionMoment.isAfter(staleMoment)

    if (isWorking && currentUserId !== workingOnDescriptionUserId) {
      setShowWorkingState(true)

      const timeout = setTimeout(() => {
        setShowWorkingState(false)
      }, WORKING_STALE_SECONDS * 1000)

      return () => {
        window.clearTimeout(timeout)
      }
    } else {
      setShowWorkingState(false)
    }

    return
  }, [
    workingOnDescriptionUpdatedSeconds,
    currentUserId,
    workingOnDescriptionUserId,
  ])

  return (
    <div className={'TaskDescriptionMain ' + TaskDescriptionMainStyles}>
      {showWorkingState && workingOnDescriptionUserId && (
        <UserWorkingWidget userId={workingOnDescriptionUserId} />
      )}

      {
        // use key hack to desconstruct TaskDescriptionEditorConnected when taskId changes
        // TODO: remove this hack
      }
      {taskId && canWrite && <TaskDescriptionEditorConnected key={taskId} />}

      {taskId && !canWrite && (
        <MarkdownDiv className={paddingLeftRightMediumStyles}>
          {description}
        </MarkdownDiv>
      )}
    </div>
  )
}
