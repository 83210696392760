import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
// import { DropdownButtonConnectedProps } from './types/DropdownButtonConnectedProps'
import { DropdownButtonMapDispatchReturnType } from './types/DropdownButtonMapDispatchReturnType'
import { createCloseDropdownAction } from 'actionCreators/createCloseDropdownAction'

export const DropdownButtonMapDispatch = (
  dispatch: Dispatch<Action>
): DropdownButtonMapDispatchReturnType => {
  return {
    showDropdown: (showDropdown) => {
      dispatch(
        createToggleAction({
          description: 'DropdownButtonMapDispatch',
          toggles: {
            showDropdown,
          },
        })
      )
    },

    hideDropdown: (showDropdown) => {
      dispatch(
        createCloseDropdownAction({
          description: 'DropdownButtonMapDispatch',
          values: showDropdown,
        })
      )
    },
  }
}
