import { PATCH_CUSTOM_CLAIM_FAILURE } from 'actions'
import { PatchCustomClaimFailureAction } from './types/PatchCustomClaimFailureAction'
import { PatchCustomClaimRequestActionValues } from './types/PatchCustomClaimRequestActionValues'

interface Props {
  requestValues: PatchCustomClaimRequestActionValues
}

export const createPatchCustomClaimFailureAction = ({
  requestValues,
}: Props): PatchCustomClaimFailureAction => {
  return {
    type: PATCH_CUSTOM_CLAIM_FAILURE,
    requestValues,
  }
}
