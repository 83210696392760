import { Dispatch, Action } from 'redux'
import { LoginFormMapDispatchReturnType } from './types/LoginFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_LOGIN } from 'actions'

export const LoginFormMapDispatch = (
  dispatch: Dispatch<Action>
): LoginFormMapDispatchReturnType => {
  return {
    submit: ({ email, password }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_LOGIN,
          values: { email, password },
          description: 'LoginFormMapDispatch',
        })
      )
    },
  }
}
