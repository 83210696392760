import { DELETE_USER_INVITES_SUCCESS } from 'actions'
import { DeleteUserInvitesSuccessActionValues } from './types/DeleteUserInvitesSuccessActionValues'
import { DeleteUserInvitesRequestActionValues } from './types/DeleteUserInvitesRequestActionValues'
import { DeleteUserInvitesSuccessAction } from './types/DeleteUserInvitesSuccessAction'

interface Props {
  successValues: DeleteUserInvitesSuccessActionValues
  requestValues: DeleteUserInvitesRequestActionValues
  description: string
}

export const createDeleteUserInvitesSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): DeleteUserInvitesSuccessAction => {
  return {
    type: DELETE_USER_INVITES_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
