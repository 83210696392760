import { UploadedFileWatcherStyles } from './UploadedFileWatcherStyles'
import React, { useEffect, useState } from 'react'
import { UploadedFileWatcherProps } from './types/UploadedFileWatcherProps'
import { firestore, QueryDocumentSnapshot } from 'sdks'
import { getUploadedFilesRef } from 'firebaseFunctions/utilities/ref/get/getUploadedFilesRef'
import { ActionButton } from 'components/button/ActionButton'
import { Spinner } from 'components/widget/Spinner'

const LIMIT = 20

export const UploadedFileWatcher = ({
  teamId,
  onNext,
  onFirebaseError,
}: UploadedFileWatcherProps): JSX.Element => {
  const [showGetMore, setShowGetMore] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [
    lastVisibleDoc,
    setLastVisibleDoc,
  ] = useState<QueryDocumentSnapshot | null>(null)

  const [
    useLastVisibleDoc,
    setUseLastVisibleDoc,
  ] = useState<QueryDocumentSnapshot | null>(null)

  useEffect(() => {
    const getFiles = async () => {
      try {
        let listRef = getUploadedFilesRef({ teamId, firestore })
          .orderBy('bytes', 'desc')
          .limit(LIMIT)

        if (useLastVisibleDoc) {
          listRef = listRef.startAfter(useLastVisibleDoc)
        }

        setShowSpinner(true)
        const querySnapshot = await listRef.get()
        setShowSpinner(false)

        setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1])

        if (!querySnapshot.metadata.fromCache) {
          const noMoreItems = querySnapshot.docs.length < LIMIT

          if (noMoreItems) {
            setShowGetMore(false)
          } else {
            setShowGetMore(true)
          }
        }

        onNext(querySnapshot)
      } catch (error) {
        onFirebaseError(error)
      }
    }

    getFiles()
  }, [
    teamId,
    onFirebaseError,
    onNext,
    setShowSpinner,
    setShowGetMore,
    useLastVisibleDoc,
  ])

  return (
    <div className={`UploadedFileWatcher ${UploadedFileWatcherStyles}`}>
      {showGetMore && (
        <ActionButton
          title="Load more"
          onClick={
            showSpinner
              ? undefined
              : () => {
                  setUseLastVisibleDoc(lastVisibleDoc)
                }
          }
          disabled={showSpinner}
        >
          {showSpinner && <Spinner />}

          {!showSpinner && <span>load more</span>}
        </ActionButton>
      )}
    </div>
  )
}
