import { getObjectFirstItem } from './../../../firebaseFunctions/utilities/get/getObjectFirstItem'
import { put } from 'redux-saga/effects'
import { postTeamsApi } from 'apis/post/postTeamsApi'
import { createErrorAction } from 'actionCreators/createErrorAction'
import { PostTeamsSuccessPayload } from 'firebaseFunctions/teams/types/PostTeamsSuccessPayload'
import { AxiosResponse } from 'axios'
import { createPostTeamsRequestAction } from 'actionCreators/post/PostTeams/createPostTeamsRequestAction'
import { createPostTeamsSuccessAction } from 'actionCreators/post/PostTeams/createPostTeamsSuccessAction'
import { createPostTeamsFailureAction } from 'actionCreators/post/PostTeams/createPostTeamsFailureAction'
import { PostTeamsApiProps } from 'apis/post/types/PostTeamsApiProps'
import { createPatchCustomClaimSuccessAction } from 'actionCreators/patch/PatchCustomClaim/createPatchCustomClaimSuccessAction'

interface Props extends PostTeamsApiProps {
  currentUser: firebase.User
}

export const postTeamsSaga = function*({
  values,
  apiHeaders,
  currentUser,
}: Props) {
  const { key: teamId } = getObjectFirstItem(values.items)

  if (!teamId) {
    yield put(
      createErrorAction({
        description: 'postTeamsSaga !teamId',
      })
    )

    return
  }

  yield put(
    createPostTeamsRequestAction({
      requestValues: values,
    })
  )

  try {
    const results: AxiosResponse<PostTeamsSuccessPayload> = yield postTeamsApi({
      apiHeaders,
      values: values,
    })

    // custom claim was updated so we need to refresh the token
    yield currentUser.getIdTokenResult(true)

    yield put(
      createPostTeamsSuccessAction({
        requestValues: values,
        successValues: results.data,
      })
    )

    yield put(
      createPatchCustomClaimSuccessAction({
        description: 'postTeamsSaga',
        requestValues: { teamId },
        successValues: { item: results.data.customClaim },
      })
    )
  } catch (error) {
    yield put(
      createErrorAction({
        error,
        description: 'postTeamsSaga',
      })
    )

    yield put(
      createPostTeamsFailureAction({
        requestValues: values,
      })
    )
  }
}
