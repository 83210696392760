import { StateReducers } from 'state/reducers/types/StateReducers'
import { UploadedFileDropdownConnectedProps } from './types/UploadedFileDropdownConnectedProps'
import { UploadedFileDropdownMapStateReturnType } from './types/UploadedFileDropdownMapStateReturnType'

export const UploadedFileDropdownMapState = (
  state: StateReducers,
  props: UploadedFileDropdownConnectedProps
): UploadedFileDropdownMapStateReturnType => {
  const uploadedFile = state.uploadedFiles?.[props.uploadedFileId].data
  const path = uploadedFile?.path

  const showDropdown = state.toggles?.showDropdown
  const showUploadedFileDropdown =
    showDropdown &&
    showDropdown.type === 'uploadedFile' &&
    showDropdown.id === props.uploadedFileId

  return {
    path,
    showUploadedFileDropdown,
  }
}
