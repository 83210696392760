import { UPLOAD_SUCCESS } from 'actions'
import { take, spawn } from 'redux-saga/effects'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { newMessageSaga } from './newMessageSaga'
import { UploadSuccessAction } from 'actionCreators/Upload/types/UploadSuccessAction'

interface Props {
  uploadIds: string[]
  taskId: string
  teamId: string
  messageId: string
  userId: string
  taggedUserIds: string[]
  markdown: string
  userDisplayName: string
  userPhotoUrl?: string
  taskSummary: string
  repliedToMessageId?: string
  repliedToUserId?: string
  repliedToDescription?: string
}

export const uploadSuccessNewMessageSaga = function*({
  uploadIds,
  taskId,
  teamId,
  messageId,
  userId,
  taggedUserIds = [],
  markdown,
  userDisplayName,
  userPhotoUrl,
  taskSummary,
  repliedToMessageId,
  repliedToUserId,
  repliedToDescription,
}: Props) {
  const uploadFileNamesMutable = []

  while (1) {
    const uploadSuccessAction: UploadSuccessAction = yield take([
      UPLOAD_SUCCESS,
    ])
    const { key, item: upload } = getObjectFirstItem(
      uploadSuccessAction.successValues
    )
    const fileName = upload?.fileName

    if (fileName && key && uploadIds.indexOf(key) > -1) {
      uploadFileNamesMutable.push(fileName)
    }

    if (uploadFileNamesMutable.length === uploadIds.length) {
      // spawn to prevent blocking new message upload successes
      yield spawn(newMessageSaga, {
        taskId,
        teamId,
        messageId,
        userId,
        taggedUserIds,
        markdown,
        taskSummary,
        userDisplayName,
        userPhotoUrl,
        repliedToMessageId,
        repliedToUserId,
        repliedToDescription,
      })

      return
    }
  }
}
