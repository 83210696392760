import { TEAM_STAT_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PatchTeamStatSuccessPayload } from 'firebaseFunctions/teamStat/types/PatchTeamStatSuccessPayload'
import { PatchTeamStatApiProps } from './types/PatchTeamStatApiProps'

export const patchTeamStatApi = ({
  values,
  apiHeaders,
}: PatchTeamStatApiProps): AxiosPromise<PatchTeamStatSuccessPayload> => {
  return Axios.patch(TEAM_STAT_URL, values, {
    headers: apiHeaders,
  })
}
