import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'

import {
  SUBMIT_USER_DONE,
  SUBMIT_USER_INVITED_DONE,
  SUBMIT_LOGIN_DONE,
  SUBMIT_RESET_PASSWORD_DONE,
  SUBMIT_USER_DISPLAY_NAME_DONE,
  CREATE_USER_EXTENSION_DONE,
  SUBMIT_UPDATE_PASSWORD_DONE,
  SUBMIT_NEW_TEAM_DONE,
  GOOGLE_SIGN_IN_SUCCESS,
} from 'actions'

export const hideFormSpinnerF = function*() {
  while (1) {
    yield take([
      SUBMIT_USER_DONE,
      SUBMIT_USER_INVITED_DONE,
      SUBMIT_LOGIN_DONE,
      SUBMIT_RESET_PASSWORD_DONE,
      SUBMIT_USER_DISPLAY_NAME_DONE,
      CREATE_USER_EXTENSION_DONE,
      SUBMIT_UPDATE_PASSWORD_DONE,
      SUBMIT_NEW_TEAM_DONE,
      GOOGLE_SIGN_IN_SUCCESS,
    ])

    const toggleAction = createToggleAction({
      description: 'hideFormSpinnerF',
      toggles: {
        showFormSpinner: false,
      },
    })

    yield put(toggleAction)
  }
}
