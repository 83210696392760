import { css } from 'emotion'

import {
  PRIMARY_COLOR,
  NEUTRAL_BORDER_COLOR,
  PLACEHOLDER_COLOR,
  LINK_HIGHLIGHT_COLOR,
  LINK_COLOR,
} from 'constants/styleConstants'

import { MARK_DOWN_STYLES } from 'components/div/MarkdownDiv/MarkdownDivStyles'

export const AndroidDeleteErrorStyles = css`
  padding: 8px 16px;
`

export const DraftEditorStyles = css`
  border: 1px solid rgba(0, 0, 0, 0);
  position: relative;

  .saveButton {
    padding: 8px;
  }

  &:hover {
    border-color: ${NEUTRAL_BORDER_COLOR};
  }

  .androidMessage {
    display: none;
    color: ${PRIMARY_COLOR};
    padding: 8px 16px;
  }

  &.isFocused {
    border-color: ${PRIMARY_COLOR};

    .androidMessage {
      display: block;
    }

    &:hover {
      border-color: ${PRIMARY_COLOR};
    }

    .saveButton {
      opacity: 1;
      color: ${PRIMARY_COLOR};
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }

  .DraftEditor-root {
    flex-grow: 1;
    padding: 0px;
    background-color: #fff;
    line-height: 1.5em;

    ${MARK_DOWN_STYLES}

    ul {
      list-style: disc;
    }

    .DraftEditor-editorContainer {
      display: flex;

      .public-DraftEditor-content {
        width: 100%;
        padding: 8px 16px;
      }
    }

    .public-DraftEditorPlaceholder-root {
      margin: 8px 0px 0px 16px;
      color: ${PLACEHOLDER_COLOR};
    }

    .public-DraftStyleDefault-pre {
      background: #efefef;
      font-family: Monaco, Menlo, Consolas, Courier New, monospace;
      font-size: 12px;
      padding: 8px;
      margin: 16px 0px;
    }
  }

  .draftJsToolbar__buttonWrapper__1Dmqh {
    display: inline-block;
  }

  .draftJsToolbar__button__qi1gf {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
  }

  .draftJsToolbar__button__qi1gf svg {
    fill: #888;
  }

  .draftJsToolbar__button__qi1gf:hover,
  .draftJsToolbar__button__qi1gf:focus {
    background: #f3f3f3;
    outline: 0; /* reset for :focus */
  }

  .draftJsToolbar__active__3qcpF {
    background: #efefef;
    color: #444;
  }

  .draftJsToolbar__active__3qcpF svg {
    fill: #444;
  }
  .draftJsToolbar__separator__3U7qt {
    display: inline-block;
    border-right: 1px solid #ddd;
    height: 24px;
    margin: 0 0.5em;
  }
  .draftJsToolbar__toolbar__dNtBH {
    left: 50%;
    -webkit-transform: translate(-50%) scale(0);
    transform: translate(-50%) scale(0);
    position: absolute;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
    z-index: 2;
    box-sizing: border-box;
  }

  .draftJsToolbar__toolbar__dNtBH:after,
  .draftJsToolbar__toolbar__dNtBH:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .draftJsToolbar__toolbar__dNtBH:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px;
  }
  .draftJsToolbar__toolbar__dNtBH:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 6px;
    margin-left: -6px;
  }

  /* mentions */

  .draftJsMentionPlugin__mention__29BEd,
  .draftJsMentionPlugin__mention__29BEd:visited {
    color: ${LINK_COLOR};
    cursor: pointer;
    display: inline-block;
    background: ${LINK_HIGHLIGHT_COLOR};
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
    text-decoration: none;
  }

  .draftJsMentionPlugin__mention__29BEd:hover,
  .draftJsMentionPlugin__mention__29BEd:focus {
    color: #677584;
    background: #edf5fd;
    outline: 0; /* reset for :focus */
  }

  .draftJsMentionPlugin__mention__29BEd:active {
    /* color: #222;
    background: #455261; */
  }
  .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  }

  .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
    background-color: #cce7ff;
  }

  .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
    background-color: #e6f3ff;
  }

  .draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
    display: inline-block;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 368px;
    font-size: 0.9em;
    margin-bottom: 0.2em;
  }

  .draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }
  .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    border: 1px solid #eee;
    margin-top: 0.4em;
    position: absolute;
    min-width: 220px;
    max-width: 440px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 2;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
`
