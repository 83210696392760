import { merge, forIn, isObject } from 'lodash'

// TODO: deprecare this because its unpredictable
export const removeUndefined = <T>(item: T): T => {
  if (isObject(item) && !Array.isArray(item)) {
    const obj = merge({}, item)

    forIn(obj, (val: any, key: string) => {
      if (typeof val !== 'function' && !Array.isArray(val)) {
        if (val === undefined) {
          delete obj[key]
        } else if (typeof val === 'object') {
          obj[key] = removeUndefined(val)
        }
      }
    })

    return obj
  }

  return item
}
