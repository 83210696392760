import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import { LabelListConnectedProps, LabelListMapStateReturnType } from './types'

export const LabelListMapState = (
  state: StateReducers,
  props: LabelListConnectedProps
): LabelListMapStateReturnType => {
  const { showOnlyActive, showOnlyArchive } = props
  const team = getCurrentTeam({ state })
  const labels = (team && team.labels) || {}
  const labelIds = Object.keys(labels).filter((labelId) => {
    if (showOnlyActive) {
      return !labels[labelId].archived
    }
    if (showOnlyArchive) {
      return labels[labelId].archived
    }
    return true
  })

  return {
    labelIds: memoizeFlatArray(labelIds, 'LabelListMapState-labelIds'),
  }
}
