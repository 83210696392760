import { StateReducers } from 'state/reducers/types/StateReducers'
import { SyncTaskCountButtonConnectedProps } from './types/SyncTaskCountButtonConnectedProps'
import { SyncTaskCountButtonMapStateReturnType } from './types/SyncTaskCountButtonMapStateReturnType'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getTaskCountSyncMilliSeconds } from 'state/getters/getTaskCountSyncMilliSeconds'

export const SyncTaskCountButtonMapState = (
  state: StateReducers,
  _props: SyncTaskCountButtonConnectedProps
): SyncTaskCountButtonMapStateReturnType => {
  const teamId = getCurrentTeamId({ state })
  const taskCountSyncMilliSeconds = getTaskCountSyncMilliSeconds({ state })

  return {
    teamId,
    taskCountSyncMilliSeconds,
  }
}
