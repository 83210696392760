import * as React from 'react'
import { MessageNotificationDropdownConnected } from './MessageNotificationDropdownConnected'
import { MessageNotificationDropdown } from './MessageNotificationDropdown'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const MessageNotificationDropdownExamples = () => {
  return (
    <div className="MessageNotificationDropdownExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <MessageNotificationDropdownConnected />
      </Provider>

      <h1 className="exampleHeader">long number</h1>

      <Provider store={createMockStore(mockState)}>
        <MessageNotificationDropdown unreadCount={100} />
      </Provider>
    </div>
  )
}
