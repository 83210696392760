import { Dispatch, Action } from 'redux'
import {
  SUBMIT_TASK_DESCRIPTION,
  SUBMIT_WORKING_ON_TASK_DESCRIPTION,
} from 'actions'

import {
  // TaskDescriptionEditorConnectedProps,
  TaskDescriptionEditorMapDispatchReturnType,
} from './types'
import { createSubmitEditorAction } from 'actionCreators/submit/SubmitEditor'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const TaskDescriptionEditorMapDispatch = (
  dispatch: Dispatch<Action>
  // props: TaskDescriptionEditorConnectedProps,
): TaskDescriptionEditorMapDispatchReturnType => {
  return {
    save: (markdown: string) => {
      const submitTaskDescriptionAction = createSubmitEditorAction({
        values: { markdown },
        type: SUBMIT_TASK_DESCRIPTION,
      })

      dispatch(submitTaskDescriptionAction)
    },

    setWorking: (working: boolean = true) => {
      dispatch(
        createSubmitAction({
          values: {
            working,
          },
          type: SUBMIT_WORKING_ON_TASK_DESCRIPTION,
        })
      )
    },
  }
}
