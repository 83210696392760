import { UploadedFileCardStyles } from './UploadedFileCardStyles'
import React from 'react'
import { UploadedFileCardProps } from './types/UploadedFileCardProps'
import moment from 'moment'
import { UploadedFileDropdownConnected } from 'components/dropdown/UploadedFileDropdown'
import { lightBoxCardStyles } from 'styles/lightBoxCardStyles'

export const UploadedFileCard = ({
  createdSeconds,
  path,
  bytes,
  uploadedFileId,
}: UploadedFileCardProps): JSX.Element => {
  const fileName = path ? path.split('/').pop() : 'unknown'

  return (
    <div
      className={`UploadedFileCard ${UploadedFileCardStyles} ${lightBoxCardStyles} noBorder`}
    >
      <UploadedFileDropdownConnected uploadedFileId={uploadedFileId} />
      <div className="size">
        <span className="label">Size:</span> {bytes ? bytes / 1000 : 0} KB
      </div>

      <div className="file">
        <span className="label">File:</span> {fileName}
      </div>

      <div className="uploaded">
        <span className="label">Uploaded:</span>{' '}
        {moment.unix(createdSeconds || 0).format('MM/DD/YYYY')}{' '}
      </div>
    </div>
  )
}
