import { css } from 'emotion'
import { PRIMARY_COLOR } from 'constants/styleConstants'

export const SpinnerStyles = css `
  transform: rotate(45deg);
  animation: rotate 1.2s infinite linear;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  span {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: ${PRIMARY_COLOR};
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: rotateMove 1s infinite linear alternate;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      right: 0;
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      bottom: 0;
      right: 0;
      animation-delay: 0.8s;
    }

    &:nth-child(4) {
      bottom: 0;
      left: 0;
      animation-delay: 1.2s;
    }
  }

  &.large {
    width: 30px;
    height: 30px;

    span {
      width: 14px;
      height: 14px;
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(405deg);
    }
  }

  @keyframes rotateMove {
    to {
      opacity: 1;
    }
  }
}`
