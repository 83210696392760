import { OneLayoutMapStateReturnType } from './types/OneLayoutMapStateReturnType'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'
import { getIsBigScreen } from 'state/getters/getIsBigScreen'
import { getIsRoutesFromState } from 'state/getters/getIsRoutesFromState'
import { getQueryInviteId } from 'state/getters/getQueryInviteId'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'

export const OneLayoutMapState = (
  state: StateReducers
  // props: OneLayoutConnectProps,
): OneLayoutMapStateReturnType => {
  const isRoutes = getIsRoutesFromState({ state })
  const isMediumScreen = getIsMediumScreen({ state })
  const isBigScreen = getIsBigScreen({ state })
  const teams = state.teams || undefined
  const { key: teamId } = getObjectFirstItem(teams)
  const inviteId = getQueryInviteId({ state })
  const isUsingBigHeader = !!(
    state.currentUserId && getCurrentTeamId({ state })
  )

  return {
    inviteId,
    isBigScreen,
    isUsingBigHeader,
    isMediumScreen,
    teamId,
    ...isRoutes,
  }
}
