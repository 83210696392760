import { connect } from 'react-redux'
import { MessageList } from './MessageList'
import { MessageListMapState } from './MessageListMapState'
import { MessageListMapDispatch } from './MessageListMapDispatch'

import { MessageListMapStateReturnType } from './types/MessageListMapStateReturnType'
import { MessageListMapDispatchReturnType } from './types/MessageListMapDispatchReturnType'
import { MessageListConnectedProps } from './types/MessageListConnectedProps'


export const MessageListConnected = connect<
  MessageListMapStateReturnType,
  MessageListMapDispatchReturnType,
  MessageListConnectedProps>(MessageListMapState,
    MessageListMapDispatch)(MessageList)
