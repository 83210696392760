import { GET_DASHBOARD_SUCCESS } from 'actions'

import { GetDashboardSuccessAction } from './types/GetDashboardSuccessAction'
import { GetDashboardSuccessActionValues } from './types/GetDashboardSuccessActionValues'
import { GetDashboardRequestActionValues } from './types/GetDashboardRequestActionValues'


interface Props {
  successValues: GetDashboardSuccessActionValues
  requestValues: GetDashboardRequestActionValues
}

export const createGetDashboardSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetDashboardSuccessAction => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    requestValues,
    successValues,
  }
}
