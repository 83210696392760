import { getIsBigScreen } from 'state/getters/getIsBigScreen'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { HeaderTwoTextConnectedProps } from './types/HeaderTwoTextConnectedProps'
import { HeaderTwoTextMapStateReturnType } from './types/HeaderTwoTextMapStateReturnType'
import { getIsMediumScreen } from '../../../state/getters/getIsMediumScreen'

export const HeaderTwoTextMapState = (
  state: StateReducers,
  _props: HeaderTwoTextConnectedProps
): HeaderTwoTextMapStateReturnType => {
  const isMediumScreen = getIsMediumScreen({ state })
  const isBigScreen = getIsBigScreen({ state })

  return {
    isMediumScreen,
    isBigScreen,
  }
}
