import { forEach } from 'lodash'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { TeamsReducer } from 'state/reducers/types/TeamsReducer'

interface Props {
  state: StateReducers
}

export const getCurrentUserTeams = ({ state }: Props): TeamsReducer => {
  const teams = state.teams || undefined
  const currentUserId = state.currentUserId || undefined

  if (!currentUserId) {
    return {}
  }

  const teamsMutable: TeamsReducer = {}

  if (teams) {
    forEach(teams, (team, id) => {
      if (team.data?.userRoles?.[currentUserId]) {
        teamsMutable[id] = { ...team }
      }
    })
  }

  return teamsMutable
}
