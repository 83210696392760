import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'

import {
  // MessagesMainConnectedProps,
  MessagesMainMapStateReturnType,
} from './types'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const MessagesMainMapState = (
  state: StateReducers
  // props: MessagesMainConnectedProps,
): MessagesMainMapStateReturnType => {
  const teamId = getCurrentTeamId({ state })
  const canWrite = getCanWrite({ state })

  return {
    teamId,
    canWrite,
  }
}
