import { css } from 'emotion'

export const SelectTagOptionStyles = css`
  display: flex;
  align-items: center;

  .DisplayNameSpan {
    margin-left: 8px;
  }
`
