import { Dispatch, Action } from 'redux'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { TaskReducer } from 'state/reducers/types/TaskReducer'
import { createDeleteTasksSuccessAction } from 'actionCreators/delete/DeleteTasks/createDeleteTasksSuccessAction'
import { createGetTasksSuccessAction } from 'actionCreators/get/GetTasks/createGetTasksSuccessAction'
import { TaskQuerySnapshot } from 'types/TaskQuerySnapshot'

interface Props {
  querySnapshot: TaskQuerySnapshot
  dispatch: Dispatch<Action>
  teamId: string
  description: string
}

export const handleTaskQuerySnapshot = ({
  querySnapshot,
  dispatch,
  teamId,
  description,
}: Props) => {
  const newTaskMutable: TasksReducer = {}
  const deletedTaskMutable: TasksReducer = {}

  querySnapshot.docChanges().forEach((change) => {
    const task = change.doc.data()
    const taskId = change.doc.id

    if (!task?.created) {
      // skip to prevent it overwriting the temp created time
      return
    }

    const taskReducer: TaskReducer = {
      data: task,
      teamId: teamId,
      teamWatched: true,
    }

    if (change.type === 'added' || change.type === 'modified') {
      newTaskMutable[taskId] = taskReducer
    }

    if (change.type === 'removed') {
      deletedTaskMutable[taskId] = taskReducer
    }
  })

  if (Object.keys(newTaskMutable).length > 0) {
    dispatch(
      createGetTasksSuccessAction({
        successValues: {
          items: newTaskMutable,
        },
        description,
      })
    )
  }

  if (Object.keys(deletedTaskMutable).length > 0) {
    dispatch(
      createDeleteTasksSuccessAction({
        requestValues: {
          items: deletedTaskMutable,
        },
        successValues: {
          items: deletedTaskMutable,
        },
        description,
      })
    )
  }
}
