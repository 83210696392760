import { connect } from 'react-redux'
import { TaskSummaryMain } from './TaskSummaryMain'
import { TaskSummaryMainMapState } from './TaskSummaryMainMapState'
import { TaskSummaryMainMapDispatch } from './TaskSummaryMainMapDispatch'

import { TaskSummaryMainMapStateReturnType } from './types/TaskSummaryMainMapStateReturnType'
import { TaskSummaryMainMapDispatchReturnType } from './types/TaskSummaryMainMapDispatchReturnType'
import { TaskSummaryMainConnectedProps } from './types/TaskSummaryMainConnectedProps'


export const TaskSummaryMainConnected = connect<
  TaskSummaryMainMapStateReturnType,
  TaskSummaryMainMapDispatchReturnType,
  TaskSummaryMainConnectedProps>(TaskSummaryMainMapState,
    TaskSummaryMainMapDispatch)(TaskSummaryMain)
