import { connect } from 'react-redux'
import { TaskMainDropdown } from './TaskMainDropdown'
import { TaskMainDropdownMapState } from './TaskMainDropdownMapState'
import { TaskMainDropdownMapDispatch } from './TaskMainDropdownMapDispatch'

import { TaskMainDropdownMapStateReturnType } from './types/TaskMainDropdownMapStateReturnType'
import { TaskMainDropdownMapDispatchReturnType } from './types/TaskMainDropdownMapDispatchReturnType'
import { TaskMainDropdownConnectedProps } from './types/TaskMainDropdownConnectedProps'


export const TaskMainDropdownConnected = connect<
  TaskMainDropdownMapStateReturnType,
  TaskMainDropdownMapDispatchReturnType,
  TaskMainDropdownConnectedProps>(TaskMainDropdownMapState,
    TaskMainDropdownMapDispatch)(TaskMainDropdown)
