import { StateReducers } from 'state/reducers/types/StateReducers'
import { getUnreadCount } from 'state/getters/getUnreadCount'

import {
  // TitleWatcherConnectedProps,
  TitleWatcherMapStateReturnType,
} from './types'

export const TitleWatcherMapState = (
  state: StateReducers,
  // props: TitleWatcherConnectedProps,
): TitleWatcherMapStateReturnType => {
  // const currentLocation = state.currentLocation
  // const pathname = currentLocation?.pathname || ''
  const activityUnreadCount = getUnreadCount({ state, notificationGroup: 'activities' })
  const messagingUnreadCount = getUnreadCount({ state, notificationGroup: 'messages' })

  return {
    messagingUnreadCount,
    activityUnreadCount,
  }
}
