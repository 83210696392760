import { getPlanRef } from 'firebaseFunctions/utilities/ref/get/getPlanRef'
import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { forEach } from 'lodash'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPatchPlansRequestAction } from 'actionCreators/patch/PatchPlans/createPatchPlansRequestAction'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { createPatchPlansSuccessAction } from 'actionCreators/patch/PatchPlans/createPatchPlansSuccessAction'
import { createPatchPlansFailureAction } from 'actionCreators/patch/PatchPlans/createPatchPlansFailureAction'
import { Plan } from '../../../firebaseFunctions/types/Plan'

interface Props {
  items: PlansReducer
  lastModifiedUserId: 'skip' | string
  lastModifiedDisplayName: 'skip' | string
}

export const patchPlansSaga = function*({
  items,
  lastModifiedUserId,
  lastModifiedDisplayName,
}: Props) {
  yield put(
    createPatchPlansRequestAction({
      requestValues: {
        lastModifiedUserId,
        lastModifiedDisplayName,
        items,
      },
    })
  )

  try {
    const batch = firestore.batch()

    forEach(items, (planReducer, planId) => {
      const { data, teamId } = planReducer
      const planMutable: Plan = { ...data }

      if (lastModifiedUserId !== 'skip') {
        planMutable.lastModifiedUserId = lastModifiedUserId
      }

      if (lastModifiedDisplayName !== 'skip') {
        planMutable.lastModifiedDisplayName = lastModifiedDisplayName
      }

      const ref = getPlanRef({
        firestore,
        teamId,
        planId,
      })

      batch.update(ref, planMutable) // TODO: update has no typings
    })

    yield batch.commit()

    yield put(
      createPatchPlansSuccessAction({
        requestValues: { items, lastModifiedUserId, lastModifiedDisplayName },
        successValues: { items },
      })
    )
  } catch (error) {
    yield put(
      createFirebaseErrorAction({
        error: error as firebase.FirebaseError,
        description: 'patchPlansSaga',
      })
    )

    yield put(
      createPatchPlansFailureAction({
        requestValues: { items, lastModifiedUserId, lastModifiedDisplayName },
      })
    )
  }
}
