import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { GET_UNSCOPE_USER_REQUEST } from 'actions'
import { DocumentSnapshot } from '@google-cloud/firestore'
import { UnscopeUser } from 'firebaseFunctions/types'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { GetUnscopeUserRequestAction } from 'actionCreators/get/GetUnscopeUser/types/GetUnscopeUserRequestAction'
import { createGetUnscopeUserSuccessAction } from 'actionCreators/get/GetUnscopeUser/createGetUnscopeUserSuccessAction'
import { createGetUnscopeUserFailureAction } from 'actionCreators/get/GetUnscopeUser/createGetUnscopeUserFailureAction'
import { getUnscopeUserRef } from '../../firebaseFunctions/utilities/ref/get/getUnscopeUserRef'

export const getUnscopeUserRequestF = function*() {
  while (1) {
    const action: GetUnscopeUserRequestAction = yield take([
      GET_UNSCOPE_USER_REQUEST,
    ])
    const { requestValues } = action
    const { userId, ignoreErrors } = requestValues

    try {
      const getUser = getUnscopeUserRef({ firestore, userId }).get()
      const userDocument: DocumentSnapshot = yield getUser
      const unscopeUser = userDocument.data() as UnscopeUser | undefined

      if (unscopeUser) {
        yield put(
          createGetUnscopeUserSuccessAction({
            requestValues,
            successValues: {
              item: unscopeUser,
            },
          })
        )
      }
    } catch (error) {
      if (!ignoreErrors) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'getUnscopeUserRequestF',
          })
        )
      }

      yield put(
        createGetUnscopeUserFailureAction({
          requestValues,
        })
      )
    }
  }
}
