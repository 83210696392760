import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'

import {
  GET_USER_INVITES_SUCCESS,
  DELETE_USER_INVITES_REQUEST,
  PATCH_USER_INVITES_REQUEST,
} from 'actions'

import { UserInvites, UserInvite } from 'firebaseFunctions/types'

export const userInvitesReducer = createGenericReducer<UserInvites, UserInvite>(
  {
    getSuccessAction: GET_USER_INVITES_SUCCESS,
    deleteRequestAction: DELETE_USER_INVITES_REQUEST,
    patchRequestAction: PATCH_USER_INVITES_REQUEST,
    // debug: true,
  }
)
