import { connect } from 'react-redux'
import { UserEmailSpan } from './UserEmailSpan'
import { UserEmailSpanMapState } from './UserEmailSpanMapState'
import { UserEmailSpanMapDispatch } from './UserEmailSpanMapDispatch'
import { UserEmailSpanMapStateReturnType } from './types/UserEmailSpanMapStateReturnType'
import { UserEmailSpanMapDispatchReturnType } from './types/UserEmailSpanMapDispatchReturnType'
import { UserEmailSpanConnectedProps } from './types/UserEmailSpanConnectedProps'

export const UserEmailSpanConnected = connect<
  UserEmailSpanMapStateReturnType,
  UserEmailSpanMapDispatchReturnType,
  UserEmailSpanConnectedProps>(UserEmailSpanMapState,
    UserEmailSpanMapDispatch)(UserEmailSpan)
