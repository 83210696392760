import { MessageCardStyles } from './MessageCardStyles'
import React, { useCallback } from 'react'
import { MessageCardProps } from './types/MessageCardProps'
import { Avatar } from 'components/widget/Avatar'
import moment from 'moment'
import { MarkdownDiv } from 'components/div/MarkdownDiv'
import { MessageActionDropdownConnected } from 'components/dropdown/MessageActionDropdown'
import { MessageFileListConnected } from 'components/list/MessageFileList'
import { DotDotDotWidget } from 'components/widget/DotDotDotWidget'
import { DisplayNameSpanConnected } from 'components/span/DisplayNameSpan'

export const MessageCard = ({
  userPhotoUrl,
  userDisplayName,
  description,
  createdSeconds,
  messageId,
  edited,
  setReplyToMessageId,
  repliedToUserId,
  repliedToDescription,
  canWrite,
}: MessageCardProps): JSX.Element => {
  const replyClick = useCallback(() => {
    setReplyToMessageId?.(messageId)
  }, [setReplyToMessageId, messageId])

  return (
    <div className={'MessageCard ' + MessageCardStyles}>
      <Avatar photoURL={userPhotoUrl} displayName={userDisplayName} />

      <main>
        <div className="displayNameAndButton">
          <div className="displayNameAndTime">
            <span className="displayName">{userDisplayName}</span>

            {createdSeconds && (
              <span className="created">
                {moment.unix(createdSeconds).format('h:mm a')}
              </span>
            )}
            {!createdSeconds && <DotDotDotWidget />}
            {edited && <span className="edited">- edited</span>}
          </div>

          {canWrite && (
            <MessageActionDropdownConnected
              messageId={messageId}
              replyClick={replyClick}
            />
          )}
        </div>

        <div className="description">
          <MessageFileListConnected messageId={messageId} />

          {repliedToDescription && (
            <>
              <div className="repliedTo">
                replied to <DisplayNameSpanConnected userId={repliedToUserId} />
                :{' '}
              </div>
              <MarkdownDiv className="repliedToDescription">
                {repliedToDescription}
              </MarkdownDiv>
            </>
          )}

          <MarkdownDiv>{description}</MarkdownDiv>
        </div>
      </main>
    </div>
  )
}
