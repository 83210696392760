import { connect } from 'react-redux'
import { DisplayNameSpan } from './DisplayNameSpan'
import { DisplayNameSpanMapState } from './DisplayNameSpanMapState'
import { DisplayNameSpanMapDispatch } from './DisplayNameSpanMapDispatch'

import { DisplayNameSpanMapStateReturnType } from './types/DisplayNameSpanMapStateReturnType'
import { DisplayNameSpanMapDispatchReturnType } from './types/DisplayNameSpanMapDispatchReturnType'
import { DisplayNameSpanConnectedProps } from './types/DisplayNameSpanConnectedProps'


export const DisplayNameSpanConnected = connect<
  DisplayNameSpanMapStateReturnType,
  DisplayNameSpanMapDispatchReturnType,
  DisplayNameSpanConnectedProps>(DisplayNameSpanMapState,
    DisplayNameSpanMapDispatch)(DisplayNameSpan)
