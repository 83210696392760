import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { Dispatch, Action } from 'redux'
import { LabelDropdownConnectedProps } from './types/LabelDropdownConnectedProps'
import { LabelDropdownMapDispatchReturnType } from './types/LabelDropdownMapDispatchReturnType'
import { SUBMIT_DELETE_LABEL, SUBMIT_UPDATE_LABEL } from 'actions'
import { createToggleAction } from 'actionCreators/createToggleAction'

export const LabelDropdownMapDispatch = (
  dispatch: Dispatch<Action>,
  props: LabelDropdownConnectedProps
): LabelDropdownMapDispatchReturnType => {
  return {
    deleteLabel: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_LABEL,
          description: 'LabelDropdownMapDispatch',
          values: {
            labelId: props.labelId,
          },
        })
      )
    },

    updateLabel: () => {
      dispatch(
        createToggleAction({
          description: 'LabelDropdownMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )

      dispatch(
        createSubmitAction({
          type: SUBMIT_UPDATE_LABEL,
          values: {
            labelId: props.labelId,
          },
        })
      )
    },
  }
}
