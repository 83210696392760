import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // TaskChangesMainConnectedProps,
  TaskChangesMainMapStateReturnType,
} from './types'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getIsBigScreen } from 'state/getters/getIsBigScreen'

export const TaskChangesMainMapState = (
  state: StateReducers
  // props: TaskChangesMainConnectedProps,
): TaskChangesMainMapStateReturnType => {
  const teamId = getCurrentTeamId({ state })
  const taskId = getCurrentTaskId({ state })
  const isBigScreen = getIsBigScreen({ state })

  return {
    teamId,
    taskId,
    isBigScreen,
  }
}
