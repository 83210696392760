import { Dispatch, Action } from 'redux'
import { CancelSubscriptionButtonMapDispatchReturnType } from './types/CancelSubscriptionButtonMapDispatchReturnType'
import { createErrorAction } from 'actionCreators/createErrorAction'

export const CancelSubscriptionButtonMapDispatch = (
  dispatch: Dispatch<Action>
): CancelSubscriptionButtonMapDispatchReturnType => {
  return {
    handleError: (props) => {
      dispatch(createErrorAction(props))
    },
  }
}
