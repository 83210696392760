import { StripeSubscriptionMainStyles } from './StripeSubscriptionMainStyles'
import React, { useEffect, useState, useCallback } from 'react'
import { StripeSubscriptionMainProps } from './types/StripeSubscriptionMainProps'
import { getStripeSubscriptionsApi } from 'apis/get/getStripeSubscriptionsApi'
import { getIdToken } from 'sdks/firebase/getIdToken'
import { StripeSubscriptions } from 'firebaseFunctions/types/StripeSubscriptions'
import { Spinner } from 'components/widget/Spinner'
import { map } from 'lodash'
import { StripeSubscriptionCard } from 'components/card/StripeSubscriptionCard'
import { forEach } from 'lodash'
import { StripeSubscription } from '../../../firebaseFunctions/types/StripeSubscription'

export const StripeSubscriptionMain = ({
  canGetToken,
  handleError,
}: StripeSubscriptionMainProps): JSX.Element => {
  const [
    stripeSubscriptionsState,
    setStripeSubscriptionsState,
  ] = useState<StripeSubscriptions | null>(null)

  const [showSpinner, setShowSpinner] = useState(true)

  const handleDeletedStripeSubscription = useCallback(
    (stripeSubscriptions: StripeSubscriptions) => {
      const stripeSubscriptionsClone = { ...stripeSubscriptionsState }
      forEach(stripeSubscriptions, (stripeSubscription, id) => {
        stripeSubscriptionsClone[id] = {
          ...stripeSubscriptionsClone[id],
          ...stripeSubscription,
        }
      })

      setStripeSubscriptionsState(stripeSubscriptionsClone)
    },
    [stripeSubscriptionsState]
  )

  useEffect(() => {
    let cancelled = false
    const getSubscriptions = async () => {
      try {
        const token = await getIdToken()

        if (cancelled) {
          return
        }

        const response = await getStripeSubscriptionsApi({
          apiHeaders: { token },
        })

        if (cancelled) {
          return
        }

        setShowSpinner(false)
        setStripeSubscriptionsState(response.data.items)
      } catch (error) {
        if (!cancelled) {
          return
        }
        setShowSpinner(false)
        handleError(error)
      }
    }

    if (canGetToken) {
      getSubscriptions()

      return () => {
        cancelled = true
      }
    }

    return
  }, [canGetToken, setShowSpinner, handleError])

  const isNone =
    !stripeSubscriptionsState ||
    Object.keys(stripeSubscriptionsState).length === 0

  const renderItem = (
    stripeSubscription: StripeSubscription,
    stripeSubscriptionId: string
  ) => {
    return (
      <li key={stripeSubscriptionId}>
        <StripeSubscriptionCard
          stripeSubscriptionId={stripeSubscriptionId}
          stripeSubscription={stripeSubscription}
          handleDeletedStripeSubscription={handleDeletedStripeSubscription}
        />
      </li>
    )
  }

  return (
    <div className={`StripeSubscriptionMain ${StripeSubscriptionMainStyles}`}>
      {showSpinner && <Spinner />}

      {!showSpinner && isNone && <div>None</div>}

      {!isNone && (
        <ul className="subscriptionList">
          {map(
            stripeSubscriptionsState,
            (stripeSubscription, stripeSubscriptionId) => {
              return stripeSubscription.status === 'active'
                ? renderItem(stripeSubscription, stripeSubscriptionId)
                : null
            }
          )}

          {map(
            stripeSubscriptionsState,
            (stripeSubscription, stripeSubscriptionId) => {
              return stripeSubscription.status === 'past_due'
                ? renderItem(stripeSubscription, stripeSubscriptionId)
                : null
            }
          )}

          {map(
            stripeSubscriptionsState,
            (stripeSubscription, stripeSubscriptionId) => {
              return stripeSubscription.status !== 'active' &&
                stripeSubscription.status !== 'past_due'
                ? renderItem(stripeSubscription, stripeSubscriptionId)
                : null
            }
          )}
        </ul>
      )}
    </div>
  )
}
