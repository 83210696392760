import { UNAVAILABLE_TASK } from 'actions'
import { UnavailableTaskAction } from './types/UnavailableTaskAction'

interface Props {
  taskId: string
}

export const createUnavailableTaskAction = ({
  taskId,
}: Props): UnavailableTaskAction => {
  return {
    type: UNAVAILABLE_TASK,
    taskId,
  }
}
