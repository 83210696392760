import { StateReducers } from 'state/reducers/types/StateReducers'
import { getSortedTeamIds } from 'state/getters/getSortedTeamIds'
import { getCurrentUserTeams } from 'state/getters/getCurrentUserTeams'

import {
  // TeamListConnectedProps,
  TeamListMapStateReturnType,
} from './types'

export const TeamListMapState = (
  state: StateReducers
  // props: TeamListConnectedProps,
): TeamListMapStateReturnType => {
  const currentUserTeams = getCurrentUserTeams({ state })
  const sortedTeamIds = getSortedTeamIds({ teams: currentUserTeams })

  return {
    teamIds: sortedTeamIds,
  }
}
