import { firebaseSdk } from './firebaseSdk'
import { PUBLIC_VAPID_KEY } from 'constants/sdkConstants'

export let firebaseMessaging:
  | firebase.messaging.Messaging
  | undefined = undefined

try {
  firebaseMessaging = firebaseSdk.messaging && firebaseSdk.messaging()

  if (firebaseMessaging) {
    firebaseMessaging.usePublicVapidKey(PUBLIC_VAPID_KEY)
  }
} catch (error) {}
