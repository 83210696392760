import { connect } from 'react-redux'
import { DashboardLayout } from './DashboardLayout'
import { DashboardLayoutMapState } from './DashboardLayoutMapState'
import { DashboardLayoutMapDispatch } from './DashboardLayoutMapDispatch'

import { DashboardLayoutMapStateReturnType } from './types/DashboardLayoutMapStateReturnType'
import { DashboardLayoutMapDispatchReturnType } from './types/DashboardLayoutMapDispatchReturnType'
import { DashboardLayoutConnectedProps } from './types/DashboardLayoutConnectedProps'


export const DashboardLayoutConnected = connect<
  DashboardLayoutMapStateReturnType,
  DashboardLayoutMapDispatchReturnType,
  DashboardLayoutConnectedProps>(DashboardLayoutMapState,
    DashboardLayoutMapDispatch)(DashboardLayout)
