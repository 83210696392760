import { connect } from 'react-redux'
import { FormSpinner } from './FormSpinner'
import { FormSpinnerConnectProps } from './types/FormSpinnerConnectProps'
import { FormSpinnerMapStateReturnType } from './types/FormSpinnerMapStateReturnType'
import { FormSpinnerMapDispatchReturnType } from './types/FormSpinnerMapDispatchReturnType'

import {
  FormSpinnerMapState,
} from './FormSpinnerMapState'

import {
  FormSpinnerMapDispatch,
} from './FormSpinnerMapDispatch'

export const FormSpinnerConnected = connect<
  FormSpinnerMapStateReturnType,
  FormSpinnerMapDispatchReturnType,
  FormSpinnerConnectProps>(FormSpinnerMapState,
    FormSpinnerMapDispatch)(FormSpinner)
