import { StateReducers } from 'state/reducers/types/StateReducers'
import { getTagSelectOptions } from 'state/getters/getTagSelectOptions'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import {
  // SelectTagFieldConnectedProps,
  SelectTagFieldMapStateReturnType,
} from './types'

export const SelectTagFieldMapState = (
  state: StateReducers,
  // props: SelectTagFieldConnectedProps,
): SelectTagFieldMapStateReturnType => {
  const { values, labels, types } = getTagSelectOptions({ state })

  return {
    values: memoizeFlatArray(values, 'SelectTagFieldMapState.values'),
    labels: memoizeFlatArray(labels, 'SelectTagFieldMapState.labels'),
    types: memoizeFlatArray(types, 'SelectTagFieldMapState.types'),
  }
}
