import { CheveronDownIconStyles } from './CheveronDownIconStyles'
import React from 'react'
import { CheveronDownIconProps } from './types/CheveronDownIconProps'
import { CheveronLeftIcon } from '../CheveronLeftIcon/CheveronLeftIcon'

export const CheveronDownIcon = ({
  className = '',
}: CheveronDownIconProps): JSX.Element => {
  return (
    <CheveronLeftIcon
      className={`CheveronDownIcon ${CheveronDownIconStyles} ${className}`}
    />
  )
}
