import { take, put, race } from 'redux-saga/effects'

import {
  GET_DASHBOARD_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_TEAMS_CANCEL,
} from 'actions'
import { GetDashboardRequestAction } from 'actionCreators/get/GetDashboard/types/GetDashboardRequestAction'
import { createGetTeamsRequestAction } from 'actionCreators/get/GetTeams/createGetTeamsRequestAction'
import { createGetDashboardSuccessAction } from 'actionCreators/get/GetDashboard/createGetDashboardSuccessAction'

export const getDashboardRequestF = function*() {
  while (1) {
    const action: GetDashboardRequestAction = yield take([
      GET_DASHBOARD_REQUEST,
    ])
    const { userId } = action.requestValues

    // Ignore errors here because user may not have any teams yet
    yield put(
      createGetTeamsRequestAction({
        description: 'getDashboardRequestF',
        requestValues: {
          ignoreErrors: true,
          userId,
        },
      })
    )

    yield race({
      success: take(GET_TEAMS_SUCCESS),
      failureOrCancel: take([GET_TEAMS_FAILURE, GET_TEAMS_CANCEL]),
    })

    yield put(
      createGetDashboardSuccessAction({
        requestValues: action.requestValues,
        successValues: {},
      })
    )
  }
}
