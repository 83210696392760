import { StateReducers } from 'state/reducers/types/StateReducers'
import { UserEmailSpanConnectedProps } from './types/UserEmailSpanConnectedProps'
import { UserEmailSpanMapStateReturnType } from './types/UserEmailSpanMapStateReturnType'

export const UserEmailSpanMapState = (
  state: StateReducers,
  _props: UserEmailSpanConnectedProps
): UserEmailSpanMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {
    email: state.currentUser?.email,
  }
}
