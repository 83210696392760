import { TaskTagCardStyles } from './TaskTagCardStyles'
import React from 'react'
import { TaskTagCardProps } from './types/TaskTagCardProps'
import { AvatarConnected } from 'components/widget/Avatar'
import { LabelIconSpanConnected } from 'components/span/LabelIconSpan'
import { RemoveTagButtonConnected } from 'components/button/RemoveTagButton'
import { TagDisplayNameSpanConnected } from 'components/span/TagDisplayNameSpan'
import { flexCenterStyles } from '../../../styles/flex/flexCenterStyles'

export const TaskTagCard = ({
  tagId,
  tagType,
  showRemove,
  showDisplayName,
  displayName,
  taskId,
}: TaskTagCardProps): JSX.Element => {
  const isLabelType = tagType === 'label'
  const isUserType = tagType === 'user'

  return (
    <div className={'TaskTagCard ' + TaskTagCardStyles} title={displayName}>
      <div className={flexCenterStyles}>
        {isUserType && (
          <AvatarConnected userId={tagId} backupDisplayName={displayName} />
        )}

        {isLabelType && tagId && <LabelIconSpanConnected labelId={tagId} />}

        {showDisplayName && (
          <TagDisplayNameSpanConnected taskId={taskId} tagId={tagId} />
        )}
      </div>

      {showRemove && <RemoveTagButtonConnected taskId={taskId} tagId={tagId} />}
    </div>
  )
}
