import { css } from 'emotion'

// TODO: use spaceBetween
export const LabelCardStyles = css`
  position: relative;

  .LabelDropdown {
    position: absolute;
    top: 4px;
    right: 0px;
  }

  .iconAndDisplayName {
    margin: 8px 0px;
    display: flex;
    align-items: center;

    .icon {
      font-size: 1.8em;
    }
  }

  .displayName {
    margin-left: 16px;
  }

  .trash {
    margin-left: 16px;
  }
`
