import { Action } from 'redux'
import merge from 'lodash/merge'
import { LOCATION_CHANGE, TOGGLE } from 'actions'
import { TogglesR } from './types/TogglesR'
import { ToggleAction } from 'actionCreators/types/ToggleAction'

type PartialTogglesR = Partial<TogglesR>

interface ToggleStateByKeysProps {
  state?: TogglesR
  toggles: PartialTogglesR
}

const toggleStateByKeys = ({ state, toggles }: ToggleStateByKeysProps) => {
  const mergedState = merge({}, state, toggles) as TogglesR

  return mergedState
}

export const togglesReducer = (state?: TogglesR, action?: Action): TogglesR => {
  if (!action) {
    return state || {}
  }

  switch (action.type) {
    case TOGGLE: {
      const toggleAction = action as ToggleAction
      const { toggles } = toggleAction
      const nextState = toggleStateByKeys({ state, toggles })

      if (document && document.body) {
        if (nextState.showModal) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'initial'
        }
      }

      return nextState
    }

    case LOCATION_CHANGE:
      return {}

    default:
      return state || {}
  }
}
