import { connect } from 'react-redux'
import { SignOutButton } from './SignOutButton'
import { SignOutButtonMapState } from './SignOutButtonMapState'
import { SignOutButtonMapDispatch } from './SignOutButtonMapDispatch'

import { SignOutButtonMapStateReturnType } from './types/SignOutButtonMapStateReturnType'
import { SignOutButtonMapDispatchReturnType } from './types/SignOutButtonMapDispatchReturnType'
import { SignOutButtonConnectedProps } from './types/SignOutButtonConnectedProps'


export const SignOutButtonConnected = connect<
  SignOutButtonMapStateReturnType,
  SignOutButtonMapDispatchReturnType,
  SignOutButtonConnectedProps>(SignOutButtonMapState,
    SignOutButtonMapDispatch)(SignOutButton)
