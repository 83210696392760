import { createToggleAction } from './../../../actionCreators/createToggleAction'
import { Dispatch, Action } from 'redux'
import { TaskTagDropdownMapDispatchReturnType } from './types'

export const TaskTagDropdownMapDispatch = (
  dispatch: Dispatch<Action>
): TaskTagDropdownMapDispatchReturnType => {
  return {
    closeDropdown: () => {
      dispatch(
        createToggleAction({
          toggles: {
            showDropdown: false,
          },
          description: 'TaskTagDropdownMapDispatch',
        })
      )
    },
  }
}
