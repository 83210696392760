import { connect } from 'react-redux'
import { WorkspaceStorageUploadField } from './WorkspaceStorageUploadField'
import { WorkspaceStorageUploadFieldMapState } from './WorkspaceStorageUploadFieldMapState'
import { WorkspaceStorageUploadFieldMapDispatch } from './WorkspaceStorageUploadFieldMapDispatch'
import { WorkspaceStorageUploadFieldMapStateReturnType } from './types/WorkspaceStorageUploadFieldMapStateReturnType'
import { WorkspaceStorageUploadFieldMapDispatchReturnType } from './types/WorkspaceStorageUploadFieldMapDispatchReturnType'
import { WorkspaceStorageUploadFieldConnectedProps } from './types/WorkspaceStorageUploadFieldConnectedProps'

export const WorkspaceStorageUploadFieldConnected = connect<
  WorkspaceStorageUploadFieldMapStateReturnType,
  WorkspaceStorageUploadFieldMapDispatchReturnType,
  WorkspaceStorageUploadFieldConnectedProps
>(
  WorkspaceStorageUploadFieldMapState,
  WorkspaceStorageUploadFieldMapDispatch
)(WorkspaceStorageUploadField)
