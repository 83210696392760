import { connect } from 'react-redux'
import { TaskChangesMain } from './TaskChangesMain'
import { TaskChangesMainMapState } from './TaskChangesMainMapState'
import { TaskChangesMainMapDispatch } from './TaskChangesMainMapDispatch'

import { TaskChangesMainMapStateReturnType } from './types/TaskChangesMainMapStateReturnType'
import { TaskChangesMainMapDispatchReturnType } from './types/TaskChangesMainMapDispatchReturnType'
import { TaskChangesMainConnectedProps } from './types/TaskChangesMainConnectedProps'


export const TaskChangesMainConnected = connect<
  TaskChangesMainMapStateReturnType,
  TaskChangesMainMapDispatchReturnType,
  TaskChangesMainConnectedProps>(TaskChangesMainMapState,
    TaskChangesMainMapDispatch)(TaskChangesMain)
