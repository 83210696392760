import { connect } from 'react-redux'
import { NotificationList } from './NotificationList'
import { NotificationListMapState } from './NotificationListMapState'
import { NotificationListMapDispatch } from './NotificationListMapDispatch'

import { NotificationListMapStateReturnType } from './types/NotificationListMapStateReturnType'
import { NotificationListMapDispatchReturnType } from './types/NotificationListMapDispatchReturnType'
import { NotificationListConnectedProps } from './types/NotificationListConnectedProps'


export const NotificationListConnected = connect<
  NotificationListMapStateReturnType,
  NotificationListMapDispatchReturnType,
  NotificationListConnectedProps>(NotificationListMapState,
    NotificationListMapDispatch)(NotificationList)
