import { css } from 'emotion'
import { BIG_LAYOUT_WIDTH } from 'constants/styleConstants'

export const MessageLayoutStyles = css`
  position: fixed;
  height: 100%;
  width: 100%;

  .mainHeader {
    /* Beareful with using margins because the layout uses JS to adjust */
    .TaggedUserList {
      padding: 4px;
    }

    .BigHeader {
      padding: 8px 8px 8px 8px;
    }
  }

  &.isBigScreen {
    .MessagesMain,
    .mainHeader {
      max-width: ${BIG_LAYOUT_WIDTH};
      margin: 0 auto;
    }

    .BigHeader {
      padding: 16px 0 32px 0;
    }

    .mainHeader {
      padding-bottom: 16px;

      .backAndSummary {
        margin-left: 0;
        margin-right: 0;

        .summary {
          font-size: 1em;
          font-weight: bold;
        }
      }
    }
  }
`
