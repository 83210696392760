import { Dispatch, Action } from 'redux'
import { SUBMIT_NEW_MESSAGE } from 'actions'

import { SUBMIT_WORKING_ON_TASK_MESSAGE } from 'actions'

import { NewMessageEditorConnectedProps } from './types/NewMessageEditorConnectedProps'
import { NewMessageEditorMapDispatchReturnType } from './types/NewMessageEditorMapDispatchReturnType'

import { createSubmitEditorAction } from 'actionCreators/submit/SubmitEditor'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const NewMessageEditorMapDispatch = (
  dispatch: Dispatch<Action>,
  props: NewMessageEditorConnectedProps
): NewMessageEditorMapDispatchReturnType => {
  return {
    save: (markdown: string) => {
      dispatch(
        createSubmitEditorAction({
          values: { markdown, replyToMessageId: props.replyToMessageId },
          type: SUBMIT_NEW_MESSAGE,
        })
      )

      if (props.inModal) {
        dispatch(
          createToggleAction({
            description: 'NewMessageEditorMapDispatch',
            toggles: {
              showModal: false,
            },
          })
        )
      }
    },

    setWorking: (working: boolean = true) => {
      dispatch(
        createSubmitAction({
          values: {
            working,
          },
          type: SUBMIT_WORKING_ON_TASK_MESSAGE,
        })
      )
    },
  }
}
