import { StateReducers } from 'state/reducers/types/StateReducers'
import { DeleteTeamLogoButtonConnectedProps } from './types/DeleteTeamLogoButtonConnectedProps'
import { DeleteTeamLogoButtonMapStateReturnType } from './types/DeleteTeamLogoButtonMapStateReturnType'
import { getCurrentTeamId } from '../../../state/getters/getCurrentTeamId'
import { getCurrentTeamReducer } from '../../../state/getters/getCurrentTeamReducer'

export const DeleteTeamLogoButtonMapState = (
  state: StateReducers,
  _props: DeleteTeamLogoButtonConnectedProps
): DeleteTeamLogoButtonMapStateReturnType => {
  const teamId = getCurrentTeamId({ state })
  const team = getCurrentTeamReducer({ state })

  return {
    teamId,
    logoUrl: team?.logoUrl,
  }
}
