// import { Dispatch, Action } from 'redux'

import {
  // TaskSummaryMainConnectedProps,
  TaskSummaryMainMapDispatchReturnType,
} from './types'

export const TaskSummaryMainMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskSummaryMainConnectedProps,
): TaskSummaryMainMapDispatchReturnType => {
  return {
  }
}
