import { StateReducers } from 'state/reducers/types/StateReducers'

import { UserInviteCardConnectedProps } from './types/UserInviteCardConnectedProps'
import { UserInviteCardMapStateReturnType } from './types/UserInviteCardMapStateReturnType'


export const UserInviteCardMapState = (
  state: StateReducers,
  props: UserInviteCardConnectedProps
): UserInviteCardMapStateReturnType => {
  const userInvites = state.userInvites || {}
  const userInvite = userInvites[props.inviteId]?.data
  const teamDisplayName = userInvite && userInvite.teamDisplayName

  return {
    teamDisplayName,
  }
}
