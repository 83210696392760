import { getIdToken } from 'sdks/firebase/getIdToken'
import { take, put, select, race, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { FirebaseError } from 'firebase'
import { ACCOUNT_SETTINGS_ROUTE } from 'routes'
import { Race } from 'types/Race'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createHistoryAction } from 'actionCreators/createHistoryAction'

import {
  SUBMIT_USER_DISPLAY_NAME,
  SUBMIT_USER_DISPLAY_NAME_DONE,
  PATCH_USERS_SUCCESS,
  PATCH_USERS_FAILURE,
  PATCH_USERS_CANCEL,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { PatchUsersSuccessAction } from 'actionCreators/patch/PatchUsers/types/PatchUsersSuccessAction'
import { PatchUsersFailureAction } from 'actionCreators/patch/PatchUsers/types/PatchUsersFailureAction'
import { patchUsersSaga } from 'flows/sagas/patch/patchUsersSaga'

export const submitUpdateUserDisplayNameF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_USER_DISPLAY_NAME])
    const { displayName } = action.values
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const currentUserId = state.currentUserId
    const idToken: string = yield getIdToken()

    if (currentUserId && idToken) {
      try {
        yield spawn(patchUsersSaga, {
          values: {
            items: {
              [currentUserId]: {
                displayName,
              },
            },
          },
          apiHeaders: {
            token: idToken,
          },
        })

        const patchResult: Race<
          PatchUsersSuccessAction,
          PatchUsersFailureAction,
          any
        > = yield race({
          success: take(PATCH_USERS_SUCCESS),
          failure: take(PATCH_USERS_FAILURE),
          cancel: take(PATCH_USERS_CANCEL),
        })

        if (patchResult.success) {
          yield put(
            createHistoryAction({
              description: 'submitUserDisplayNameF',
              pathname: ACCOUNT_SETTINGS_ROUTE,
              query: {
                teamId,
              },
            })
          )
        }
      } catch (error) {
        const firestoreErrorAction = createFirebaseErrorAction({
          error: error as FirebaseError,
          description: 'submitUserDisplayNameF',
        })

        yield put(firestoreErrorAction)
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUserDisplayNameF',
        })
      )
    }

    yield put({
      type: SUBMIT_USER_DISPLAY_NAME_DONE,
    })
  }
}
