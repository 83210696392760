import { css } from 'emotion'

export const LabelIconSpanStyles = css`
  font-size: 1.8em;
  display: block;
  min-width: 30px;
  overflow: hidden;
  min-height: 30px;
  height: 30px; /* keep height at 30 to prevent jumping */

  .QuestionIcon {
    font-size: 16px;
  }
`
