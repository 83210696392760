import { Task } from 'firebaseFunctions/types'
import { MOCK_TEAM_A_ID } from '../mockTeams/mockTeamsConstants'
import { TaskReducer } from 'state/reducers/types/TaskReducer'
import { MOCK_PLAN_A_ID } from '../mockPlans/mockPlansConstants'

const data: Task = {
  summary: 'mockTaskE summary',
  status: 'In Progress',
  planId: MOCK_PLAN_A_ID,
  order: 5,
}

export const mockTaskE: TaskReducer = {
  data,
  teamId: MOCK_TEAM_A_ID,
}
