import { css } from 'emotion'
import { DANGER_COLOR } from 'constants/styleConstants'

export const WorkspaceSubscriptionMainStyles = css`
  .cancelHeader {
    color: ${DANGER_COLOR};
    margin-top: 32px;
  }

  .setupByMain {
    display: flex;
    align-items: center;

    .DisplayNameSpan {
      margin-left: 8px;
    }
  }

  .continueUsing {
    margin-top: 16px;
  }

  .ActionButton.danger {
    margin-left: 16px;
  }
`
