import { DELETE_CUSTOM_CLAIM_REQUEST } from 'actions'
import { DeleteCustomClaimRequestAction } from './types/DeleteCustomClaimRequestAction'
import { DeleteCustomClaimRequestActionValues } from './types/DeleteCustomClaimRequestActionValues'

export const createDeleteCustomClaimRequestAction = (
  requestValues: DeleteCustomClaimRequestActionValues
): DeleteCustomClaimRequestAction => {
  return {
    type: DELETE_CUSTOM_CLAIM_REQUEST,
    requestValues,
  }
}
