// import { Dispatch, Action } from 'redux'

import {
  // AppConnectedProps,
  AppMapDispatchReturnType,
} from './types'

export const AppMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: AppConnectedProps,
): AppMapDispatchReturnType => {
  return {
  }
}
