import { Dispatch, Action } from 'redux'
import { NewLabelFormMapDispatchReturnType } from './types/NewLabelFormMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_NEW_LABEL } from 'actions'

export const NewLabelFormMapDispatch = (
  dispatch: Dispatch<Action>
): NewLabelFormMapDispatchReturnType => {
  return {
    submit: ({ displayName, icon }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_NEW_LABEL,
          description: 'NewLabelFormMapDispatch',
          values: {
            displayName,
            icon,
          },
        })
      )
    },
  }
}
