import { connect } from 'react-redux'
import { MentionSuggestionList } from './MentionSuggestionList'
import { MentionSuggestionListMapState } from './MentionSuggestionListMapState'
import { MentionSuggestionListMapDispatch } from './MentionSuggestionListMapDispatch'

import { MentionSuggestionListMapStateReturnType } from './types/MentionSuggestionListMapStateReturnType'
import { MentionSuggestionListMapDispatchReturnType } from './types/MentionSuggestionListMapDispatchReturnType'
import { MentionSuggestionListConnectedProps } from './types/MentionSuggestionListConnectedProps'


export const MentionSuggestionListConnected = connect<
  MentionSuggestionListMapStateReturnType,
  MentionSuggestionListMapDispatchReturnType,
  MentionSuggestionListConnectedProps>(MentionSuggestionListMapState,
    MentionSuggestionListMapDispatch)(MentionSuggestionList)
