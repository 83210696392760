import * as React from 'react'
import { HistoryDropdownConnected } from './HistoryDropdownConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const HistoryDropdownExamples = () => {
  return (
    <div className="HistoryDropdownExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <HistoryDropdownConnected />
      </Provider>
    </div>
  )
}
