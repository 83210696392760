import React from 'react'
import { PlanLinkProps } from './types/PlanLinkProps'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { PLAN_ROUTE } from 'routes'

export const PlanLink = ({
  clearPlanScrollPosition,
  children,
  title,
}: PlanLinkProps): JSX.Element => {
  return (
    <CustomLinkConnected withCurrentQuery={true} route={PLAN_ROUTE} title={title} onClick={clearPlanScrollPosition}>{children}</CustomLinkConnected>
  )
}
