import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // UploadFilesListConnectedProps,
  UploadFilesListMapStateReturnType,
} from './types'

export const UploadFilesListMapState = (
  state: StateReducers
  // props: UploadFilesListConnectedProps,
): UploadFilesListMapStateReturnType => {
  const uploadFiles = state.uploadFiles || undefined
  const newMessageFiles = uploadFiles?.newMessages
  const length = newMessageFiles?.length || 0

  return {
    length,
  }
}
