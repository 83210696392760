import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import { Message } from '../../../types'
import { FirestoreClient } from '../../../types'
import { getMessageRef } from '../get/getMessageRef'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  taskId: string
  messageId: string
  message: Message
}

export const updateMessage = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  taskId,
  messageId,
  message,
}: Props<T>): T extends FirestoreClient
  ? Promise<any>
  : Promise<FirebaseFirestore.WriteResult> => {
  return getMessageRef({
    firestore,
    teamId,
    taskId,
    messageId,
  }).update(message) as any
}
