import { StateReducers }  from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

export const getIsEndToEndTesting = ({ state }: Props): boolean | undefined => {
  const currentLocation = state.currentLocation || undefined
  const sessionStorage = state.sessionStorage || undefined
  const isEndToEndTesting =
    sessionStorage?.isEndToEndTesting ||
    currentLocation?.query?.isEndToEndTesting

  return isEndToEndTesting
}
