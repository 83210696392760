import { Dispatch, Action } from 'redux'
import { MessagePushNotificationFieldMapDispatchReturnType } from './types/MessagePushNotificationFieldMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_UPDATE_PUSH_SETTINGS } from 'actions'
import { createSetWindowMetaAction } from '../../../actionCreators/createSetWindowMetaAction'

export const MessagePushNotificationFieldMapDispatch = (
  dispatch: Dispatch<Action>
): MessagePushNotificationFieldMapDispatchReturnType => {
  return {
    toggle: ({ token, pushNotificationSettings }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_UPDATE_PUSH_SETTINGS,
          values: {
            token,
            pushNotificationSettings,
          },
          description: 'EmailNotificationFieldMapDispatch-toggle',
        })
      )
    },

    setWindowMeta: (values) => {
      dispatch(
        createSetWindowMetaAction({
          values,
          description: 'MessagePushNotificationFieldMapDispatch',
        })
      )
    },
  }
}
