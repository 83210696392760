import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { TaskSizeDropdownConnectedProps } from './types/TaskSizeDropdownConnectedProps'
import { TaskSizeDropdownMapStateReturnType } from './types/TaskSizeDropdownMapStateReturnType'
import { getCanOwn } from '../../../state/getters/getCanOwn'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskSizeDropdownMapState = (
  state: StateReducers,
  props: TaskSizeDropdownConnectedProps
): TaskSizeDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const task = getCurrentTask({ state, taskId: props.taskId })
  const team = getCurrentTeam({ state })
  const taskSizesLength = team?.taskSizes?.length || 0
  const size = task && task.size
  const showDropdown = toggles && toggles.showDropdown
  const showSelectField = taskSizesLength > 10
  const canOwn = getCanOwn({ state })
  const canWrite = getCanWrite({ state })

  const showTaskSizeDropdown =
    showDropdown &&
    showDropdown.type === 'taskSize' &&
    showDropdown.id === props.taskId

  return {
    showTaskSizeDropdown,
    canOwn,
    size,
    showSelectField,
    canWrite,
  }
}
