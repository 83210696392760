import { EllipsisVertialIconStyles } from './EllipsisVertialIconStyles'
import React from 'react'
import { EllipsisVertialIconProps } from './types/EllipsisVertialIconProps'
import { EllipsisHorizontalIcon } from '../EllipsisHorizontalIcon/EllipsisHorizontalIcon'

export const EllipsisVertialIcon = ({
  className = '',
  ...props
}: EllipsisVertialIconProps): JSX.Element => {
  return (
    <EllipsisHorizontalIcon
      className={`EllipsisVertialIcon ${EllipsisVertialIconStyles} ${className}`}
      {...props}
    />
  )
}
