import { Action } from 'redux'
import { CLEAR_TEAM_SCOPE } from 'actions'
import { RequestsReducer } from './types/RequestsReducer'

export const requestsReducer = (
  state?: RequestsReducer,
  action?: Action
): RequestsReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case CLEAR_TEAM_SCOPE:
      return {
        ...state,
        gotInvites: undefined,
      }

    default:
      return state || null
  }
}
