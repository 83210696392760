import { css } from 'emotion'
import { NEUTRAL_BORDER_DARK } from 'constants/styleConstants'

export const TaskToolListStyles = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .StatusColorWidget {
    margin-right: 4px;
  }

  .separator {
    margin: 4px 7px 4px 8px;
    border-right: 1px solid ${NEUTRAL_BORDER_DARK};
    width: 1px;
    height: 16px;
  }

  .StatusDropdown {
    .DropdownButton {
      font-weight: bold;
    }
  }

  .DropdownButton,
  .react-date-picker__button {
    padding: 4px 0px;
  }
`
