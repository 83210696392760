import React from 'react'
import { ConfirmEmailMainProps } from './types/ConfirmEmailMainProps'
import { Link } from 'components/link/Link'
import { LOGIN_ROUTE } from 'routes'
import { SendConfirmEmailButtonConnected } from 'components/button/SendConfirmEmailButton/SendConfirmEmailButtonConnected'
import { lightBoxStyles } from 'styles/lightBoxStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { HeaderOneText } from '../../text/HeaderOneText/HeaderOneText'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const ConfirmEmailMain = ({
  email,
}: ConfirmEmailMainProps): JSX.Element => {
  return (
    <div className={'ConfirmEmailMain '}>
      <HeaderOneText className={marginTopLargeStyles}>
        Confirm email address
      </HeaderOneText>

      <div className={lightBoxStyles}>
        <p>
          We need to confirm your email address: <strong>{email}</strong>.
          Please check your inbox.{' '}
        </p>

        <p className={marginTopMediumStyles}>
          Can't find the email? Click below and we'll send you another one.
        </p>

        <SendConfirmEmailButtonConnected className={marginTopMediumStyles} />
      </div>

      <div className={`${lightBoxStyles} ${marginTopLargeStyles}`}>
        <p>
          Already confirmed?{' '}
          <Link title="Login" to={LOGIN_ROUTE}>
            Click here
          </Link>{' '}
          to login.
        </p>
      </div>
    </div>
  )
}
