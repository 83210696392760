// import { TitleWatcherStyles } from './TitleWatcherStyles'
import { useEffect } from 'react'
import { TitleWatcherProps } from './types/TitleWatcherProps'

export const TitleWatcher = ({
  messagingUnreadCount = 0,
  activityUnreadCount = 0,
}: TitleWatcherProps): null => {
  useEffect(() => {
    if (document.hidden && messagingUnreadCount > 0) {
      let flag = true

      const interval = setInterval(() => {
        if (flag) {
          window.document.title =
            `(${messagingUnreadCount}) message` +
            (messagingUnreadCount > 0 ? 's' : '')

          flag = false
        } else {
          window.document.title = 'EggKat - Get things done!'
          flag = true
        }
      }, 1000)

      const visibilityChange = () => {
        window.document.title =
          `(${messagingUnreadCount}) message` +
          (messagingUnreadCount > 0 ? 's' : '')

        clearInterval(interval)
      }

      document.addEventListener('visibilitychange', visibilityChange)

      return () => {
        clearInterval(interval)
        document.removeEventListener('visibilitychange', visibilityChange)
      }
    } else if (messagingUnreadCount > 0 || activityUnreadCount > 0) {
      window.document.title = `(${messagingUnreadCount ||
        activityUnreadCount}) EggKat`
    } else {
      window.document.title = 'EggKat'
    }

    return undefined
  }, [messagingUnreadCount, activityUnreadCount])

  return null
}
