export const SUBMIT_NEW_BOARD = 'SUBMIT_NEW_BOARD'
export const SUBMIT_NEW_BOARD_DONE = 'SUBMIT_NEW_BOARD_DONE'
export const SUBMIT_NEW_TASK = 'SUBMIT_NEW_TASK'
export const SUBMIT_NEW_TASK_DONE = 'SUBMIT_NEW_TASK_DONE'
export const SUBMIT_NEW_INVITE = 'SUBMIT_NEW_INVITE'
export const SUBMIT_NEW_INVITE_DONE = 'SUBMIT_NEW_INVITE_DONE'
export const SUBMIT_NEW_MESSAGE = 'SUBMIT_NEW_MESSAGE'
export const SUBMIT_NEW_MESSAGE_DONE = 'SUBMIT_NEW_MESSAGE_DONE'
export const SUBMIT_NEW_TEAM = 'SUBMIT_NEW_TEAM'
export const SUBMIT_NEW_TEAM_DONE = 'SUBMIT_NEW_TEAM_DONE'
export const SUBMIT_LEAVE_TEAM = 'SUBMIT_LEAVE_TEAM'
export const SUBMIT_LEAVE_TEAM_DONE = 'SUBMIT_LEAVE_TEAM_DONE'
export const SUBMIT_NEW_PLAN = 'SUBMIT_NEW_PLAN'
export const SUBMIT_NEW_PLAN_DONE = 'SUBMIT_NEW_PLAN_DONE'
export const SUBMIT_NEW_LABEL = 'SUBMIT_NEW_LABEL'
export const SUBMIT_NEW_LABEL_DONE = 'SUBMIT_NEW_LABEL_DONE'
export const SUBMIT_NEW_MESSAGE_FILES = 'SUBMIT_NEW_MESSAGE_FILES'
export const SUBMIT_NEW_MESSAGE_FILES_DONE = 'SUBMIT_NEW_MESSAGE_FILES_DONE'
export const SUBMIT_NEW_TEAM_LOGO = 'SUBMIT_NEW_TEAM_LOGO'
export const SUBMIT_NEW_TEAM_LOGO_DONE = 'SUBMIT_NEW_TEAM_LOGO_DONE'
