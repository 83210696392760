import { PATCH_USER_EXTENSION_REQUEST_DELAY } from 'actions'
import { PatchUserExtensionRequestAction } from './types/PatchUserExtensionRequestAction'
import { PatchUserExtensionRequestActionValues } from './types/PatchUserExtensionRequestActionValues'

interface Props {
  requestValues: PatchUserExtensionRequestActionValues,
}

// Only works when params are the same
export const createPatchUserExtensionRequestDelayAction = ({ requestValues }: Props): PatchUserExtensionRequestAction => {
  return {
    type: PATCH_USER_EXTENSION_REQUEST_DELAY,
    requestValues,
  }
}
