import { MessagesReducer } from 'state/reducers/types/MessagesReducer'
import { put } from 'redux-saga/effects'
import { firestore, clientServerTimestamp } from 'sdks'
import { forEach } from 'lodash'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPostMessagesRequestAction } from 'actionCreators/post/PostMessages/createPostMessagesRequestAction'
import { createPostMessagesSuccessAction } from 'actionCreators/post/PostMessages/createPostMessagesSuccessAction'
import { createPostMessagesFailureAction } from 'actionCreators/post/PostMessages/createPostMessagesFailureAction'
import { getMessageRef } from 'firebaseFunctions/utilities/ref/get/getMessageRef'

interface Props {
  items: MessagesReducer
}

export const postMessageSaga = function*({ items }: Props) {
  yield put(
    createPostMessagesRequestAction({
      requestValues: {
        items,
      },
    })
  )

  try {
    const batch = firestore.batch()

    forEach(items, (messageReducer, messageId) => {
      const { teamId, data, taskId } = messageReducer
      const messageMutable = { ...data }
      messageMutable.created = clientServerTimestamp()

      const ref = getMessageRef({
        firestore,
        teamId,
        taskId,
        messageId,
      })

      batch.set(ref, messageMutable)
    })

    yield batch.commit()

    yield put(
      createPostMessagesSuccessAction({
        successValues: {
          items,
        },
        requestValues: {
          items,
        },
      })
    )
  } catch (error) {
    const firestoreErrorAction = createFirebaseErrorAction({
      error: error as firebase.FirebaseError,
      description: 'postMessageSaga',
    })

    yield put(firestoreErrorAction)

    // TODO: just duplicated, errors currently fail silently, need to be more redundant
    const postMessagesFailureAction = createPostMessagesFailureAction({
      requestValues: {
        items,
      },
    })

    yield put(postMessagesFailureAction)
  }
}
