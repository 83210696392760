import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const EmojiDropdownStyles = css`
  position: relative;

  .Dropdown {
    > .chevronContainer {
      left: 3px;
      top: -13px;
      transform: rotate(180deg);
    }

    > .container {
      width: 340px;
      top: -438px;
      left: 0px;
    }
  }

  .emoji-mart {
    border: none;
  }
`
