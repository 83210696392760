import { POST_RE_LOGIN_REQUEST } from 'actions'
import { PostReLoginRequestAction } from './types/PostReLoginRequestAction'
import { PostReLoginRequestActionValues } from './types/PostReLoginRequestActionValues'

interface Props {
  requestValues: PostReLoginRequestActionValues,
}

export const createPostReLoginRequestAction = ({ requestValues }: Props): PostReLoginRequestAction => {
  return {
    type: POST_RE_LOGIN_REQUEST,
    requestValues,
  }
}
