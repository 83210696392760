import { SubmitTaskTagAction } from './types/SubmitTaskTagAction'
import { SubmitTaskTagActionValues } from './types/SubmitTaskTagActionValues'
import { SUBMIT_TASK_TAG } from 'actions'

interface Props {
  values: SubmitTaskTagActionValues
  description: string
}

export const createSubmitTaskTagAction = ({
  values,
  description,
}: Props): SubmitTaskTagAction => {
  return {
    type: SUBMIT_TASK_TAG,
    values,
    description,
  }
}
