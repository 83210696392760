import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'

import {
  // TaskTagsMainConnectedProps,
  TaskTagsMainMapStateReturnType,
} from './types'

export const TaskTagsMainMapState = (
  state: StateReducers,
  // props: TaskTagsMainConnectedProps,
): TaskTagsMainMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })

  return {
    taskId,
  }
}
