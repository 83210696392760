import { useEffect } from 'react'
import { NotificationPermissionWatcherProps } from './types/NotificationPermissionWatcherProps'
import { getFirebaseMessagingToken } from 'utilities/get/getFirebaseMessagingToken'

type RemoveEventListener = () => void

const convertToPermissionState = (
  permission: NotificationPermission
): PermissionState => {
  if (permission === 'granted') {
    return 'granted'
  }

  if (permission === 'denied') {
    return 'denied'
  }

  return 'prompt'
}

export const NotificationPermissionWatcher = ({
  setWindowMeta,
  unsetDontAskAgain,
}: NotificationPermissionWatcherProps): null => {
  useEffect(() => {
    const intialCall = async () => {
      const permissionState = convertToPermissionState(Notification.permission)
      let firebaseMessagingToken = null

      if (permissionState === 'granted') {
        firebaseMessagingToken = await getFirebaseMessagingToken()
      }

      setWindowMeta({
        firebaseMessagingToken,
        notificationPermission: permissionState,
      })
    }

    intialCall()
  }, [setWindowMeta])

  useEffect(() => {
    let removeEventListener: undefined | RemoveEventListener

    const watchPermissionStatus = async () => {
      if (navigator?.permissions) {
        const permissionStatus = await navigator.permissions.query({
          name: 'notifications',
        })

        const changeListener = async () => {
          if (
            permissionStatus.state === 'granted' ||
            permissionStatus.state === 'prompt'
          ) {
            unsetDontAskAgain()
          }

          if (permissionStatus.state === 'granted') {
            // Do not get getFirebaseMessagingToken because there might be a conflict causing two tokens
            setWindowMeta({
              notificationPermission: permissionStatus.state,
            })
          } else {
            setWindowMeta({
              firebaseMessagingToken: null,
              notificationPermission: permissionStatus.state,
            })
          }
        }

        permissionStatus.addEventListener('change', changeListener)

        removeEventListener = () => {
          permissionStatus.removeEventListener('change', changeListener)
        }
      }
      return
    }

    watchPermissionStatus()

    return () => {
      removeEventListener?.()
    }
  }, [setWindowMeta, unsetDontAskAgain])

  return null
}
