import { connect } from 'react-redux'
import { CancelSubscriptionButton } from './CancelSubscriptionButton'
import { CancelSubscriptionButtonMapState } from './CancelSubscriptionButtonMapState'
import { CancelSubscriptionButtonMapDispatch } from './CancelSubscriptionButtonMapDispatch'
import { CancelSubscriptionButtonMapStateReturnType } from './types/CancelSubscriptionButtonMapStateReturnType'
import { CancelSubscriptionButtonMapDispatchReturnType } from './types/CancelSubscriptionButtonMapDispatchReturnType'
import { CancelSubscriptionButtonConnectedProps } from './types/CancelSubscriptionButtonConnectedProps'

export const CancelSubscriptionButtonConnected = connect<
  CancelSubscriptionButtonMapStateReturnType,
  CancelSubscriptionButtonMapDispatchReturnType,
  CancelSubscriptionButtonConnectedProps>(CancelSubscriptionButtonMapState,
    CancelSubscriptionButtonMapDispatch)(CancelSubscriptionButton)
