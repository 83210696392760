import { connect } from 'react-redux'
import { StatusColorWidget } from './StatusColorWidget'
import { StatusColorWidgetMapState } from './StatusColorWidgetMapState'
import { StatusColorWidgetMapDispatch } from './StatusColorWidgetMapDispatch'

import { StatusColorWidgetMapStateReturnType } from './types/StatusColorWidgetMapStateReturnType'
import { StatusColorWidgetMapDispatchReturnType } from './types/StatusColorWidgetMapDispatchReturnType'
import { StatusColorWidgetConnectedProps } from './types/StatusColorWidgetConnectedProps'


export const StatusColorWidgetConnected = connect<
  StatusColorWidgetMapStateReturnType,
  StatusColorWidgetMapDispatchReturnType,
  StatusColorWidgetConnectedProps>(StatusColorWidgetMapState,
    StatusColorWidgetMapDispatch)(StatusColorWidget)
