interface Props {
  element: HTMLDivElement
  toTop?: boolean
}

export const autoScrollDropdown = ({ element, toTop }: Props) => {
  const bounding = element.getBoundingClientRect()
  const windowHeight = Math.min(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )
  const windowHalfHeight = windowHeight / 2
  const windowScrollHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight
  const windowScrollPosition =
    document.documentElement.scrollTop || document.body.scrollTop
  const halfWayOverflow = bounding.top - windowHalfHeight
  const elementHeight = bounding.bottom - bounding.top
  const maxScrollPosition = windowScrollHeight - windowHeight
  const isAtMaxScroll = maxScrollPosition === windowScrollPosition

  if (halfWayOverflow > 0 && !isAtMaxScroll) {
    const idealTopInViewport = toTop
      ? 100
      : windowHalfHeight - elementHeight / 2
    const distanceToIdealTop = bounding.top - idealTopInViewport

    window.scrollTo({
      top: windowScrollPosition + distanceToIdealTop,
      behavior: 'smooth',
    })
  }
}
