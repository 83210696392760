import * as React from 'react'
import { DeleteWorkspaceMainConnected } from './DeleteWorkspaceMainConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const DeleteWorkspaceMainExamples = () => {
  return (
    <div className="DeleteWorkspaceMainExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <DeleteWorkspaceMainConnected />
      </Provider>
    </div>
  )
}
