import { TeamsReducer }  from 'state/reducers/types/TeamsReducer'

interface Props {
  teams: TeamsReducer
}

export const getSortedTeamIds = ({ teams }: Props): string[] => {
  const teamIds = Object.keys(teams)

  const sortedTeamIds = teamIds.sort((a, b) => {
    const displayNameA = teams?.[a]?.data.displayName
    const displayNameB = teams?.[b]?.data.displayName

    if (displayNameA && displayNameB) {
      if (displayNameA < displayNameB) {
        return -1
      }

      return 1
    }

    return 0
  })

  return sortedTeamIds
}
