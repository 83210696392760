import { LabelIconSpanStyles } from './LabelIconSpanStyles'
import React from 'react'
import { LabelIconSpanProps } from './types/LabelIconSpanProps'
import { QuestionIcon } from '../../icon/QuestionIcon/QuestionIcon'
import { flexCenterCenterStyles } from '../../../styles/flex/flexCenterCenterStyles'

export const LabelIconSpan = ({
  labelId,
  icon,
}: LabelIconSpanProps): JSX.Element => {
  return (
    <span
      title={labelId}
      className={`LabelIconSpan ${flexCenterCenterStyles} ${LabelIconSpanStyles}`}
    >
      {icon || <QuestionIcon />}
    </span>
  )
}
