import { UpdatePasswordFormStyles } from './UpdatePasswordFormStyles'
import React, { useState, useCallback } from 'react'
import { UpdatePasswordFormProps } from './types/UpdatePasswordFormProps'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { isInvalidPassword } from 'firebaseFunctions/utilities/is/isInvalidPassword'
import { FormFooter } from 'components/footer/FormFooter'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const UpdatePasswordForm = ({
  submit,
}: UpdatePasswordFormProps): JSX.Element => {
  const [passwordState, setPasswordState] = useState('')
  const [passwordErrorState, setPasswordErrorState] = useState<string | null>(
    null
  )

  const [newPasswordState, setNewPasswordState] = useState('')
  const [newPasswordErrorState, setNewPasswordErrorState] = useState<
    string | null
  >(null)

  const [confirmPasswordState, setConfirmPasswordState] = useState('')
  const [confirmPasswordErrorState, setConfirmPasswordErrorState] = useState<
    string | null
  >(null)

  const validatePassword = useCallback(() => {
    const error = isInvalidPassword(passwordState)

    setPasswordErrorState(error || null)

    return error
  }, [passwordState])

  const validateNewPassword = useCallback(() => {
    const error = isInvalidPassword(newPasswordState)

    setNewPasswordErrorState(error || null)

    return error
  }, [newPasswordState])

  const validateConfirmPassword = useCallback(() => {
    let error = isInvalidPassword(confirmPasswordState)

    if (confirmPasswordState !== newPasswordState) {
      error = 'Confirm password does not match.'
    }

    setConfirmPasswordErrorState(error || null)

    return error
  }, [confirmPasswordState, newPasswordState, setConfirmPasswordErrorState])

  return (
    <form
      className={`UpdatePasswordForm ${UpdatePasswordFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const passwordError = validatePassword()
        const newPasswordError = validateNewPassword()
        const confirmPasswordError = validateConfirmPassword()

        if (!passwordError && !newPasswordError && !confirmPasswordError) {
          submit({
            newPassword: newPasswordState,
            password: passwordState,
          })
        }
      }}
    >
      <HeaderOneTextConnected>Update password</HeaderOneTextConnected>

      <TextInputField
        type="password"
        name="password"
        label="Current Password"
        error={passwordErrorState}
        onChange={(e) => {
          setPasswordErrorState(null)
          setPasswordState(e.target.value)
        }}
      />

      <TextInputField
        type="password"
        name="newPassword"
        label="New Password"
        className={marginTopLargeStyles}
        error={newPasswordErrorState}
        onChange={(e) => {
          setNewPasswordErrorState(null)
          setNewPasswordState(e.target.value)
        }}
      />

      <TextInputField
        type="password"
        name="confirmPassword"
        className={marginTopLargeStyles}
        label="Confirm Password"
        error={confirmPasswordErrorState}
        onChange={(e) => {
          setConfirmPasswordErrorState(null)
          setConfirmPasswordState(e.target.value)
        }}
      />

      <FormFooter />
    </form>
  )
}
