import { connect } from 'react-redux'
import { ActivityNotificationDropdown } from './ActivityNotificationDropdown'
import { ActivityNotificationDropdownMapState } from './ActivityNotificationDropdownMapState'
import { ActivityNotificationDropdownMapDispatch } from './ActivityNotificationDropdownMapDispatch'
import { ActivityNotificationDropdownMapStateReturnType } from './types/ActivityNotificationDropdownMapStateReturnType'
import { ActivityNotificationDropdownMapDispatchReturnType } from './types/ActivityNotificationDropdownMapDispatchReturnType'
import { ActivityNotificationDropdownConnectedProps } from './types/ActivityNotificationDropdownConnectedProps'

export const ActivityNotificationDropdownConnected = connect<
  ActivityNotificationDropdownMapStateReturnType,
  ActivityNotificationDropdownMapDispatchReturnType,
  ActivityNotificationDropdownConnectedProps
>(
  ActivityNotificationDropdownMapState,
  ActivityNotificationDropdownMapDispatch
)(ActivityNotificationDropdown)
