import { connect } from 'react-redux'
import { Modal } from './Modal'
import { ModalMapState } from './ModalMapState'
import { ModalMapDispatch } from './ModalMapDispatch'

import { ModalMapStateReturnType } from './types/ModalMapStateReturnType'
import { ModalMapDispatchReturnType } from './types/ModalMapDispatchReturnType'
import { ModalConnectProps } from './types/ModalConnectProps'


export const ModalC = connect<
  ModalMapStateReturnType,
  ModalMapDispatchReturnType,
  ModalConnectProps>(ModalMapState,
    ModalMapDispatch)(Modal)
