// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // BigHeaderConnectedProps,
  BigHeaderMapDispatchReturnType,
} from './types'

export const BigHeaderMapDispatch = (): // dispatch: Dispatch<Action>,
// props: BigHeaderConnectedProps,
BigHeaderMapDispatchReturnType => {
  return {}
}
