import { getCurrentTeamId } from './getCurrentTeamId'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { RoleTypes } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
  teamId?: string
}

export const getCustomClaimTeamRole = ({
  state,
  teamId,
}: Props): RoleTypes | undefined => {
  const useTeamId = teamId || getCurrentTeamId({ state })
  const roles = state.currentCustomClaim?.roles
  const role = roles && useTeamId && roles[useTeamId]

  return role || undefined
}
