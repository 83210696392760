import { PageSpinnerWidgetStyles } from './PageSpinnerWidgetStyles'
import React from 'react'
import { PageSpinnerWidgetProps } from './types/PageSpinnerWidgetProps'
import { Spinner } from 'components/widget/Spinner'

export const PageSpinnerWidget = ({
  showPageSpinner,
}: PageSpinnerWidgetProps): JSX.Element | null => {
  if (!showPageSpinner) {
    return null
  }

  return (
    <div className={'PageSpinnerWidget ' + PageSpinnerWidgetStyles}>
      <Spinner size="large" />
    </div>
  )
}
