import { connect } from 'react-redux'
import { TeamNameWidget } from './TeamNameWidget'
import { TeamNameWidgetMapState } from './TeamNameWidgetMapState'
import { TeamNameWidgetMapDispatch } from './TeamNameWidgetMapDispatch'

import { TeamNameWidgetMapStateReturnType } from './types/TeamNameWidgetMapStateReturnType'
import { TeamNameWidgetMapDispatchReturnType } from './types/TeamNameWidgetMapDispatchReturnType'
import { TeamNameWidgetConnectedProps } from './types/TeamNameWidgetConnectedProps'


export const TeamNameWidgetConnected = connect<
  TeamNameWidgetMapStateReturnType,
  TeamNameWidgetMapDispatchReturnType,
  TeamNameWidgetConnectedProps>(TeamNameWidgetMapState,
    TeamNameWidgetMapDispatch)(TeamNameWidget)
