import { PrimaryDropdownStyles } from './PrimaryDropdownStyles'
import React from 'react'
import { PrimaryDropdownProps } from './types/PrimaryDropdownProps'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { TeamOwnerActionList } from 'components/list/TeamOwnerActionList'
import { EllipsisVertialIcon } from 'components/icon/EllipsisVertialIcon/EllipsisVertialIcon'
import { ModalButtonConnected } from '../../button/ModalButton'
import { TextInputStyles } from '../../input/TextInput/TextInputStyles'
import { footerDarkStyles } from '../../../styles/footerDarkStyles'
import { textAlignLeft } from '../../../styles/text/textAlignLeft'
import { textColorLightStyles } from '../../../styles/text/textColorLightStyles'
import { textSmallStyles } from '../../../styles/text/textSmallStyles'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'

export const PrimaryDropdown = ({
  showPrimaryDropdown,
  className = '',
  canOwn,
}: PrimaryDropdownProps): JSX.Element => {
  return (
    <div className={`PrimaryDropdown ${className} ${PrimaryDropdownStyles}`}>
      <DropdownButtonConnected
        title="Add"
        watchSelector=".PrimaryDropdown"
        dropdownType="primaryDropdown"
      >
        <EllipsisVertialIcon />
      </DropdownButtonConnected>

      {showPrimaryDropdown && (
        <DropdownConnected autoAdjust={true}>
          {canOwn && <TeamOwnerActionList />}

          <div className={`${footerDarkStyles} ${paddingSmallStyles}`}>
            <ModalButtonConnected
              className={`${TextInputStyles} ${textAlignLeft} ${textColorLightStyles} ${textSmallStyles}`}
              showModalType="Feedback"
            >
              Feedback about this page?
            </ModalButtonConnected>
          </div>
        </DropdownConnected>
      )}
    </div>
  )
}
