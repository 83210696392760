import React, { useCallback, useState } from 'react'
import { EnableNotificationMessageProps } from './types/EnableNotificationMessageProps'
import { EnableNotificationMessageStyles } from './EnableNotificationMessageStyles'
import { actionButtonStyles } from '../../../styles/actionButtonStyles'
import { textLinkStyles } from '../../../styles/text/textLinkStyles'
import { marginSmallStyles } from '../../../styles/margin/marginSmallStyles'
import { getFirebaseMessagingToken } from '../../../utilities/get/getFirebaseMessagingToken'
import { ErrorMessage } from '../ErrorMessage'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'
import { Spinner } from '../../widget/Spinner'

export const EnableNotificationMessage: React.FC<EnableNotificationMessageProps> = ({
  className = '',
  askToEnableNotifications,
  setPushNotification,
  dontAskAgain,
  notificationPermission,
}) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const dontAskAgainCallback = useCallback(() => {
    dontAskAgain()
  }, [dontAskAgain])

  const askCallback = useCallback(() => {
    const getToken = async () => {
      setShowSpinner(true)
      const token = await getFirebaseMessagingToken({ askForPermission: true })

      if (token) {
        setPushNotification(token)
      }

      setShowSpinner(false)
    }

    getToken()
  }, [setPushNotification])

  if (askToEnableNotifications) {
    return (
      <div
        className={`EnableNotificationMessage ${className} ${EnableNotificationMessageStyles}`}
      >
        <p>EggKat needs your permission to enable notifications.</p>
        {showSpinner && <Spinner className={marginSmallStyles} />}
        {!showSpinner && (
          <div>
            <button
              type="button"
              className={`${actionButtonStyles} ${marginSmallStyles}`}
              onClick={askCallback}
            >
              Sure
            </button>{' '}
            <button
              type="button"
              className={textLinkStyles}
              onClick={dontAskAgainCallback}
            >
              Don't ask again
            </button>
          </div>
        )}
        {notificationPermission === 'denied' && (
          <ErrorMessage className={marginTopSmallStyles}>
            Your browser is blocking notifications.
          </ErrorMessage>
        )}
      </div>
    )
  }

  return null
}
