import { css } from 'emotion'
import { BIG_LAYOUT_WIDTH } from 'constants/styleConstants'

export const DashboardLayoutStyles = css`
  padding: 0px 0px 128px 0px;

  .BigHeader {
    padding: 8px;
  }

  .TaskFilterForm {
    margin: 0px 8px;
  }

  .CustomClaimSpinner {
    text-align: center;
    margin-top: 32px;
  }

  .ShowMoreTasksMain {
    margin-top: 32px;
  }

  .BigHeader {
    padding: 8px;
  }

  .DashboardNav {
    margin: 32px 0px 16px 0px;
  }

  .content {
    margin-top: 16px;
  }

  &.isMediumScreen {
    .BigHeader {
      padding: 16px;
    }

    .TaskFilterForm {
      margin: 0px 16px;
    }

    .content {
      margin: 16px;
    }
  }

  &.isBigScreen {
    padding: 0px 0px 128px 0px;

    .TaskFilterForm {
      padding: 0;
    }

    .BigHeader {
      padding: 16px 0 0 0;
    }

    .content,
    .TaskFilterForm,
    .BigHeader {
      max-width: ${BIG_LAYOUT_WIDTH};
      margin-left: auto;
      margin-right: auto;
    }
  }
`
