import { TEAMS_COLLECTION, FEEDBACKS_COLLECTION } from '../../../constants'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'
import { Feedback } from '../../../types/Feedback'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
}

export const getFeedbacksRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.CollectionReference<Feedback>
  : FirebaseFirestore.CollectionReference => {
  return firestore
    .collection(TEAMS_COLLECTION)
    .doc(teamId)
    .collection(FEEDBACKS_COLLECTION) as any
}
