import { css } from 'emotion'
import {
  WARNING_HIGHLIGHT_COLOR,
  WARNING_HIGHLIGHT_COLOR_DARK,
} from '../../../constants/styleConstants'

export const UploadWatcherStyles = css`
  background-color: ${WARNING_HIGHLIGHT_COLOR};
  padding: 8px;

  .Button {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${WARNING_HIGHLIGHT_COLOR_DARK};
  }

  .fileName {
    margin-top: 8px;
    max-width: 200px;
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
