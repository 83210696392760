import { DELETE_UPLOADED_FILES_REQUEST } from 'actions'
import { DeleteUploadedFilesRequestActionValues } from './types/DeleteUploadedFilesRequestActionValues'
import { DeleteUploadedFilesRequestAction } from './types/DeleteUploadedFilesRequestAction'

interface Props {
  requestValues: DeleteUploadedFilesRequestActionValues
}

export const createDeleteUploadedFilesRequestAction = ({
  requestValues,
}: Props): DeleteUploadedFilesRequestAction => {
  return {
    type: DELETE_UPLOADED_FILES_REQUEST,
    requestValues,
  }
}
