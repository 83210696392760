import { connect } from 'react-redux'
import { TaskTagButtonField } from './TaskTagButtonField'
import { TaskTagButtonFieldMapState } from './TaskTagButtonFieldMapState'
import { TaskTagButtonFieldMapDispatch } from './TaskTagButtonFieldMapDispatch'
import { TaskTagButtonFieldMapStateReturnType } from './types/TaskTagButtonFieldMapStateReturnType'
import { TaskTagButtonFieldMapDispatchReturnType } from './types/TaskTagButtonFieldMapDispatchReturnType'
import { TaskTagButtonFieldConnectedProps } from './types/TaskTagButtonFieldConnectedProps'

export const TaskTagButtonFieldConnected = connect<
  TaskTagButtonFieldMapStateReturnType,
  TaskTagButtonFieldMapDispatchReturnType,
  TaskTagButtonFieldConnectedProps>(TaskTagButtonFieldMapState,
    TaskTagButtonFieldMapDispatch)(TaskTagButtonField)
