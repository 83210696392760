import { connect } from 'react-redux'
import { MemberCard } from './MemberCard'
import { MemberCardMapState } from './MemberCardMapState'
import { MemberCardMapDispatch } from './MemberCardMapDispatch'

import { MemberCardMapStateReturnType } from './types/MemberCardMapStateReturnType'
import { MemberCardMapDispatchReturnType } from './types/MemberCardMapDispatchReturnType'
import { MemberCardConnectedProps } from './types/MemberCardConnectedProps'


export const MemberCardConnected = connect<
  MemberCardMapStateReturnType,
  MemberCardMapDispatchReturnType,
  MemberCardConnectedProps>(MemberCardMapState,
    MemberCardMapDispatch)(MemberCard)
