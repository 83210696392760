import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import { TaskSizeSelectFieldConnectedProps } from './types/TaskSizeSelectFieldConnectedProps'
import { TaskSizeSelectFieldMapStateReturnType } from './types/TaskSizeSelectFieldMapStateReturnType'


export const TaskSizeSelectFieldMapState = (
  state: StateReducers,
  props: TaskSizeSelectFieldConnectedProps,
): TaskSizeSelectFieldMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const task = getCurrentTask({ state, taskId: props.taskId })
  const taskSizes = team?.taskSizes
  const size = task?.size || undefined

  return {
    size,
    taskSizes,
  }
}
