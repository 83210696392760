// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // MessagesMainConnectedProps,
  MessagesMainMapDispatchReturnType,
} from './types'

export const MessagesMainMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessagesMainConnectedProps,
): MessagesMainMapDispatchReturnType => {
  return {
  }
}
