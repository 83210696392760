import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // ConfirmEmailMainConnectedProps,
  ConfirmEmailMainMapStateReturnType,
} from './types'

export const ConfirmEmailMainMapState = (
  state: StateReducers
  // props: ConfirmEmailMainConnectedProps,
): ConfirmEmailMainMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  const email = state.currentUser?.email

  return {
    email,
  }
}
