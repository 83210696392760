import { useState, useEffect, useCallback } from 'react'
import { MessagesWatcherProps } from './types/MessagesWatcherProps'
import { firestore } from 'sdks'
import { GET_MESSAGES_LIMIT } from 'constants/environmentConstants'
import { getMessagesRef } from '../../../firebaseFunctions/utilities/ref/get/getMessagesRef'

type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot
type QuerySnapshot = firebase.firestore.QuerySnapshot
type SimpleFunction = () => void

export const MessagesWatcher = ({
  onNext,
  setReachedMaxPage,
  setFetchingMessages,
  teamId,
  taskId,
  onFirebaseError,
  incrementWatcher,
  page = 0,
}: MessagesWatcherProps): null => {
  const [
    lastVisibleDoc,
    setLastVisibleDoc,
  ] = useState<QueryDocumentSnapshot | null>(null)
  const incrementWatcherCallBack = useCallback(incrementWatcher, [])
  const [teamIdState, setTeamIdState] = useState<string | null>(null)
  const [taskIdState, setTaskIdState] = useState<string | null>(null)
  const [pageState, setPageState] = useState<number | null>(null)
  const [unsubscribe, setUnsubscribe] = useState<SimpleFunction | null>(null)

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }

      incrementWatcherCallBack(-1)
    }
  }, [incrementWatcherCallBack, unsubscribe])

  const handleQuery = (querySnapshot: QuerySnapshot) => {
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
    const noMoreItems = querySnapshot.docs.length < GET_MESSAGES_LIMIT

    setLastVisibleDoc(lastVisibleDoc)
    onNext(querySnapshot)
    if (noMoreItems) {
      setReachedMaxPage(true)
    }

    setFetchingMessages(false) // should go after onNext
  }

  const baseQuery = getMessagesRef({ firestore, teamId, taskId }).orderBy(
    'created',
    'desc'
  )

  const scopeChanged = teamIdState !== teamId || taskIdState !== taskId
  const pageChanged = page !== pageState

  if (scopeChanged) {
    incrementWatcher(1)
    setTeamIdState(teamId)
    setTaskIdState(taskId)
    setFetchingMessages(true)

    const newUnsubscribe = baseQuery
      .limit(GET_MESSAGES_LIMIT)
      .onSnapshot(handleQuery, onFirebaseError)

    setUnsubscribe(() => {
      return newUnsubscribe
    })
  } else if (pageChanged && page > 0) {
    setFetchingMessages(true)
    setPageState(page)
    baseQuery
      .startAfter(lastVisibleDoc)
      .limit(GET_MESSAGES_LIMIT)
      .get()
      .then(handleQuery)
  }

  return null
}
