// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // HistoryCardConnectedProps,
  HistoryCardMapStateReturnType,
} from './types'

export const HistoryCardMapState = (): // state: StateReducers,
// props: HistoryCardConnectedProps,
HistoryCardMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
