import { StateReducers } from 'state/reducers/types/StateReducers'
import { NewUserFormConnectedProps } from './types/NewUserFormConnectedProps'
import { NewUserFormMapStateReturnType } from './types/NewUserFormMapStateReturnType'
import { getQueryTeamName } from 'state/getters/getQueryTeamName'
import { getQueryEmail } from 'state/getters/getQueryEmail'

export const NewUserFormMapState = (
  state: StateReducers,
  _props: NewUserFormConnectedProps
): NewUserFormMapStateReturnType => {
  const teamName = getQueryTeamName({ state })
  const email = getQueryEmail({ state })

  return {
    teamName,
    email,
  }
}
