import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { UploadedFilesReducer } from 'state/reducers/types/UploadedFilesReducer'
import { getUploadedFileRef } from 'firebaseFunctions/utilities/ref/get/getUploadedFileRef'
import { createDeleteUploadedFilesSuccessAction } from 'actionCreators/delete/DeleteUploadedFiles/createDeleteUploadedFilesSuccessAction'
import { createDeleteUploadedFilesFailureAction } from 'actionCreators/delete/DeleteUploadedFiles/createDeleteUploadedFilesFailureAction'
import { createDeleteUploadedFilesRequestAction } from 'actionCreators/delete/DeleteUploadedFiles/createDeleteUploadedFilesRequestAction'

interface Props {
  items: UploadedFilesReducer
}

export const deleteUploadedFilesSaga = function*({ items }: Props) {
  yield put(
    createDeleteUploadedFilesRequestAction({
      requestValues: {
        items,
      },
    })
  )

  const { key: uploadedFileId, item: firstUploadedFile } = getObjectFirstItem(
    items
  )

  if (uploadedFileId && firstUploadedFile) {
    try {
      yield getUploadedFileRef({
        firestore,
        teamId: firstUploadedFile.teamId,
        uploadedFileId,
      }).delete()

      yield put(
        createDeleteUploadedFilesSuccessAction({
          requestValues: { items },
          successValues: { items },
        })
      )
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'deleteUploadedFilesSaga',
        })
      )

      yield put(
        createDeleteUploadedFilesFailureAction({
          requestValues: { items },
        })
      )
    }
  } else {
    yield put(
      createUnexpectedErrorAction({
        description: 'deleteUploadedFilesRequestF',
      })
    )
  }
}
