import { Dispatch, Action } from 'redux'
import { FilterByButtonMapDispatchReturnType } from './types/FilterByButtonMapDispatchReturnType'
import { createSetSessionStorageAction } from '../../../actionCreators/sessionStorage'
import { uniq } from 'lodash'
import { TaskFilters } from '../../../firebaseFunctions/types'

export const FilterByButtonMapDispatch = (
  dispatch: Dispatch<Action>
): FilterByButtonMapDispatchReturnType => {
  return {
    setSession: ({ tagId, status }, { tagIds, summary, taskStatuses }) => {
      const getNewValues = (needle: string, heystack: string[]): string[] => {
        if (heystack.includes(needle)) {
          return heystack.filter((item) => {
            return item !== needle
          })
        } else {
          return uniq([...(heystack || []), needle])
        }
      }

      let taskFilters: TaskFilters | null = null

      if (tagId) {
        taskFilters = {
          summary,
          taskStatuses,
          tagIds: getNewValues(tagId, tagIds || []),
        }
      } else if (status) {
        taskFilters = {
          summary,
          taskStatuses: getNewValues(status, taskStatuses || []),
          tagIds,
        }
      }

      dispatch(
        createSetSessionStorageAction({
          description: 'FilterByButtonMapDispatch',
          sessionStorage: {
            showExtraFilters: true,
            taskFilters,
          },
        })
      )
    },
  }
}
