import { css } from 'emotion'

export const StatusColorDropdownStyles = css`
  position: relative;

  .DropdownButton {
    width: 20px;
    height: 20px;
  }

  .Dropdown {
    .chevron {
      right: 6px;
      top: 26px;
    }

    .container {
      width: 216px;
      right: 0px;
      top: 31.3px;
      padding: 8px;
    }
  }
`
