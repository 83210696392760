import { GET_UPLOADED_FILES_SUCCESS } from 'actions'

import { GetUploadedFilesSuccessAction } from './types/GetUploadedFilesSuccessAction'
import { GetUploadedFilesSuccessActionValues } from './types/GetUploadedFilesSuccessActionValues'
import { GetUploadedFilesRequestActionValues } from './types/GetUploadedFilesRequestActionValues'


interface Props {
  requestValues: GetUploadedFilesRequestActionValues
  successValues: GetUploadedFilesSuccessActionValues
}

export const createGetUploadedFilesSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetUploadedFilesSuccessAction => {
  return {
    type: GET_UPLOADED_FILES_SUCCESS,
    requestValues,
    successValues,
  }
}
