import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTeamTaskSizes } from 'state/getters/getCurrentTeamTaskSizes'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import { TaskSizeFieldConnectedProps } from './types/TaskSizeFieldConnectedProps'
import { TaskSizeFieldMapStateReturnType } from './types/TaskSizeFieldMapStateReturnType'


export const TaskSizeFieldMapState = (
  state: StateReducers,
  props: TaskSizeFieldConnectedProps,
): TaskSizeFieldMapStateReturnType => {
  const task = getCurrentTask({ state, taskId: props.taskId })
  const size = task?.size || undefined
  const taskSizes = getCurrentTeamTaskSizes({ state })

  return {
    taskSizes: memoizeFlatArray(taskSizes || [], 'TaskSizeFieldMapState-taskSizes'),
    size,
  }
}
