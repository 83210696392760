import { DELETE_PLANS_FAILURE } from 'actions'
import { DeletePlansFailureAction } from './types/DeletePlansFailureAction'
import { DeletePlansRequestActionValues } from './types/DeletePlansRequestActionValues'

interface Props {
  requestValues: DeletePlansRequestActionValues
}

export const createDeletePlansFailureAction = ({
  requestValues,
}: Props): DeletePlansFailureAction => {
  return {
    type: DELETE_PLANS_FAILURE,
    requestValues,
  }
}
