import { WorkspaceStorageUploadFieldStyles } from './WorkspaceStorageUploadFieldStyles'
import React, { useState, useCallback } from 'react'
import { WorkspaceStorageUploadFieldProps } from './types/WorkspaceStorageUploadFieldProps'
import { Button } from 'components/button/Button'
import { TEAM_NOUN } from 'constants/english'
import { WorkspaceStorageUploadMessageConnected } from 'components/message/WorkspaceStorageUploadMessage/WorkspaceStorageUploadMessageConnected'
import { FileUploadIcon } from 'components/icon/FileUploadIcon/FileUploadIcon'

const maxCapacityError = `Your ${TEAM_NOUN} has reached max capacity.`
// TODO: this file is similar to FileUploadField, should we DRY?

export const WorkspaceStorageUploadField = ({
  onlyImages,
  onValid,
  children,
  teamAvailableBytes,
}: WorkspaceStorageUploadFieldProps): JSX.Element => {
  const [error, setError] = useState('')

  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files

      if (files && files.length > 0) {
        const firstFile = files[0]
        let error = ''

        if (
          teamAvailableBytes !== undefined &&
          firstFile.size > teamAvailableBytes
        ) {
          error = maxCapacityError
        }

        if (onlyImages && firstFile.type.indexOf('image/') === -1) {
          error = 'Please select an image file.'
        }

        setError(error)

        if (!error) {
          onValid?.(Array.from(files))
        }
      }
    },
    [onlyImages, onValid, teamAvailableBytes]
  )

  return (
    <div
      className={
        'WorkspaceStorageUploadField ' + WorkspaceStorageUploadFieldStyles
      }
    >
      <div className="clickableContainer">
        {!children && (
          <Button title="Upload file">
            <FileUploadIcon />
          </Button>
        )}

        {children}

        <input
          type="file"
          accept={onlyImages ? 'image/*' : '*'}
          onChange={onChangeCallback}
        />
      </div>

      {error && (
        <WorkspaceStorageUploadMessageConnected
          error={error}
          isMaxCapacityError={error === maxCapacityError}
        />
      )}
    </div>
  )
}
