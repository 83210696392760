import { take, put, select } from 'redux-saga/effects'
import { firebaseSdk } from 'sdks'
import { FirebaseError } from 'firebase'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { POST_RE_LOGIN_REQUEST, POST_RE_LOGIN_FAILURE } from 'actions'
import {
  TOO_MANY_REQUESTS,
  WRONG_PASSWORD,
  USER_NOT_FOUND,
} from 'firebaseFunctions/constants/failureCodesConstants'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSetAlertMessageAction } from 'actionCreators/set/SetAlertMessage/createSetAlertMessageAction'
import { PostReLoginRequestAction } from 'actionCreators/post/PostReLogin/types/PostReLoginRequestAction'
import { createPostReLoginSuccessAction } from 'actionCreators/post/PostReLogin/createPostReLoginSuccessAction'
import { createPostReLoginFailureAction } from 'actionCreators/post/PostReLogin/createPostReLoginFailureAction'

export const postReLoginRequestF = function*() {
  while (1) {
    const postReLoginRequestAction: PostReLoginRequestAction = yield take([
      POST_RE_LOGIN_REQUEST,
    ])

    const { requestValues } = postReLoginRequestAction
    const { password } = requestValues
    const state: StateReducers = yield select(selectState)
    const { currentUser } = state
    const email = currentUser && currentUser.email
    const authCurrentUser = firebaseSdk.auth().currentUser

    if (email && password && authCurrentUser) {
      try {
        const credential = firebaseSdk.auth.EmailAuthProvider.credential(
          email,
          password
        )

        yield authCurrentUser.reauthenticateWithCredential(credential)

        // Because FirebaseAuthStateChangedAction will not fire if its the same user.
        yield put(
          createPostReLoginSuccessAction({
            requestValues,
            successValues: {},
          })
        )
      } catch (error) {
        if (error.code === TOO_MANY_REQUESTS) {
          yield put(
            createToggleAction({
              description: 'postReLoginRequestF',
              toggles: {
                showRecaptcha: true,
              },
            })
          )
        } else if (
          error.code === WRONG_PASSWORD ||
          error.code === USER_NOT_FOUND
        ) {
          yield put(
            createSetAlertMessageAction({
              description: 'postReLoginRequestF',
              values: {
                location: 'form',
                message: 'The current password is incorrect.',
                actionType: POST_RE_LOGIN_FAILURE,
                type: 'error',
              },
            })
          )
        } else {
          yield put(
            createFirebaseErrorAction({
              error: error as FirebaseError,
              description: 'postReLoginRequestF',
            })
          )
        }

        yield put(
          createPostReLoginFailureAction({
            requestValues,
          })
        )
      }
    }
  }
}
