import { TaskMainStyles } from './TaskMainStyles'
import React, { useEffect, useState } from 'react'
import { TaskMainProps } from './types/TaskMainProps'
import { TaskDescriptionMainConnected } from 'components/main/TaskDescriptionMain'
import { TaskTagListConnected } from 'components/list/TaskTagList'
import { TaskMainDropdownConnected } from 'components/dropdown/TaskMainDropdown'
import { Spinner } from 'components/widget/Spinner'
import { MessagesMainConnected } from 'components/main/MessagesMain'
import { TaskToolList } from 'components/list/TaskToolList'
import { TaskChangesMainConnected } from '../TaskChangesMain'
import { TaskSummaryMainConnected } from '../TaskSummaryMain'
import { TaskFileListConnected } from 'components/list/TaskFileList'
import { CustomLinkConnected } from '../../link/CustomLink'
import { TASK_MESSAGES_ROUTE } from '../../../routes'
import { buttonStyles } from '../../../styles/button/buttonStyles'
import { CheveronRightIcon } from '../../icon/CheveronRightIcon/CheveronRightIcon'
import { flexSpaceBetweenStyles } from '../../../styles/flex/flexSpaceBetweenStyles'
import { paddingLeftRightMediumStyles } from '../../../styles/padding/paddingLeftRightMediumStyles'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'

export const TaskMain = ({
  taskId,
  isBigScreen,
  showGetTasksSpinner,
  isNotAvailable,
}: TaskMainProps): JSX.Element => {
  // const isMediumScreenClassname = isMediumScreen ? ' isMediumScreen' : ''
  // const isBigScreenClassname = isBigScreen ? ' isBigScreen' : ''
  const [hasUploadedFiles, setHasUploadedFiles] = useState(false)

  useEffect(() => {
    // component mount
    document.documentElement.scrollTop = 0
  }, [])

  if (showGetTasksSpinner) {
    return <Spinner />
  } else if (isNotAvailable || !taskId) {
    return <h1>This task is no longer available.</h1>
  } else {
    return (
      <div className={'TaskMain ' + TaskMainStyles}>
        <TaskTagListConnected taskId={taskId} showTagDropdownLast={true} />

        <div className="detailAndMessages">
          <div className="taskDetail">
            <header className="spaceBetween">
              <TaskToolList taskId={taskId} />
              <TaskMainDropdownConnected taskId={taskId} />
            </header>

            <TaskSummaryMainConnected />

            <TaskDescriptionMainConnected key={taskId} />

            {hasUploadedFiles && (
              <h2 className="uploadedFilesHeader">Uploaded Files</h2>
            )}

            <TaskFileListConnected setHasUploadedFiles={setHasUploadedFiles} />
          </div>

          {isBigScreen && taskId && <MessagesMainConnected taskId={taskId} />}
        </div>

        {!isBigScreen && taskId && (
          <div
            className={`${paddingLeftRightMediumStyles} ${marginTopLargeStyles}`}
          >
            <CustomLinkConnected
              title="messages"
              route={TASK_MESSAGES_ROUTE}
              className={`${buttonStyles} ${flexSpaceBetweenStyles}`}
              withCurrentQuery={true}
              taskId={taskId}
            >
              <div>Messages</div>

              <CheveronRightIcon />
            </CustomLinkConnected>
          </div>
        )}

        <TaskChangesMainConnected />
      </div>
    )
  }
}
