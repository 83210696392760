import {
  TEAMS_COLLECTION,
  LOGO_FILE_NAME_PREFIX,
  THUMB_FILE_NAME_PREFIX,
} from '../constants'

interface Props {
  teamId: string
}

interface Return {
  originalPath: string
  thumbPath: string
}

export const getTeamLogoStoragePath = ({ teamId }: Props): Return => {
  const path = `${TEAMS_COLLECTION}/${teamId}/public/`

  return {
    originalPath: `${path}${LOGO_FILE_NAME_PREFIX}`,
    thumbPath: `${path}${THUMB_FILE_NAME_PREFIX}${LOGO_FILE_NAME_PREFIX}`,
  }
}
