import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdateTeamNameFormConnectedProps } from './types/UpdateTeamNameFormConnectedProps'
import { UpdateTeamNameFormMapStateReturnType } from './types/UpdateTeamNameFormMapStateReturnType'
import { getCurrentTeam } from 'state/getters/getCurrentTeam'

export const UpdateTeamNameFormMapState = (
  state: StateReducers,
  _props: UpdateTeamNameFormConnectedProps
): UpdateTeamNameFormMapStateReturnType => {
  const team = getCurrentTeam({ state })
  const displayName = team?.displayName

  return {
    displayName,
  }
}
