import { css } from 'emotion'
import { TEXT_COLOR_LIGHT } from 'constants/styleConstants'

export const TaskMainDropdownStyles = css`
  position: relative;

  > button {
    font-size: 1.25em;
    padding: 0px 8px;
    color: ${TEXT_COLOR_LIGHT};
  }

  .Dropdown {
    .chevronContainer {
      top: 27px;
      right: 8px;
    }

    .container {
      top: 34px;
      width: 285px;
    }
  }

`
