import { connect } from 'react-redux'
import { DeleteWorkspaceMain } from './DeleteWorkspaceMain'
import { DeleteWorkspaceMainMapState } from './DeleteWorkspaceMainMapState'
import { DeleteWorkspaceMainMapDispatch } from './DeleteWorkspaceMainMapDispatch'

import { DeleteWorkspaceMainMapStateReturnType } from './types/DeleteWorkspaceMainMapStateReturnType'
import { DeleteWorkspaceMainMapDispatchReturnType } from './types/DeleteWorkspaceMainMapDispatchReturnType'
import { DeleteWorkspaceMainConnectedProps } from './types/DeleteWorkspaceMainConnectedProps'


export const DeleteWorkspaceMainConnected = connect<
  DeleteWorkspaceMainMapStateReturnType,
  DeleteWorkspaceMainMapDispatchReturnType,
  DeleteWorkspaceMainConnectedProps>(DeleteWorkspaceMainMapState,
    DeleteWorkspaceMainMapDispatch)(DeleteWorkspaceMain)
