import { put } from 'redux-saga/effects'
import { firestore, clientServerTimestamp } from 'sdks'
import { forEach } from 'lodash'
import { createPostInvitesRequestAction } from 'actionCreators/post/PostInvites/createPostInvitesRequestAction'
import { createGetInvitesRequestAction } from 'actionCreators/get/GetInvites/createGetInvitesRequestAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { InvitesReducer } from 'state/reducers/types/InvitesReducer'
import { createPostInvitesSuccessAction } from 'actionCreators/post/PostInvites/createPostInvitesSuccessAction'
import { createPostInvitesFailureAction } from '../../../actionCreators/post/PostInvites/createPostInvitesFailureAction'
import { getInviteRef } from '../../../firebaseFunctions/utilities/ref/get/getInviteRef'

interface Props {
  items: InvitesReducer
}

interface InviteAndTeamId {
  inviteId: string
  teamId: string
}

export const postInviteSaga = function*({ items }: Props) {
  yield put(
    createPostInvitesRequestAction({
      requestValues: {
        items,
      },
    })
  )

  try {
    const batch = firestore.batch()
    const inviteAndTeamIds: InviteAndTeamId[] = []

    forEach(items, (inviteReducer, inviteId) => {
      const inviteMutable = { ...inviteReducer.data }
      inviteMutable.created = clientServerTimestamp()
      const teamId = inviteReducer.data.teamId

      if (teamId) {
        const ref = getInviteRef({
          firestore,
          teamId,
          inviteId,
        })

        inviteAndTeamIds.push({
          teamId,
          inviteId,
        })

        batch.set(ref, inviteMutable) // make sure to type check invite
      }
    })

    yield batch.commit()

    // Need to get the updated versions for created date
    for (let i = 0; i < inviteAndTeamIds.length; i += 1) {
      yield put(
        createGetInvitesRequestAction({
          requestValues: {
            teamId: inviteAndTeamIds[i].teamId,
            inviteId: inviteAndTeamIds[i].inviteId,
          },
        })
      )
    }

    yield put(
      createPostInvitesSuccessAction({
        successValues: { items },
        requestValues: { items },
      })
    )
  } catch (error) {
    yield put(
      createFirebaseErrorAction({
        error: error as firebase.FirebaseError,
        description: 'postInviteSaga',
      })
    )

    yield put(
      createPostInvitesFailureAction({
        requestValues: { items },
      })
    )
  }
}
