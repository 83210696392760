import { css } from 'emotion'
import { PRIMARY_COLOR, NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'

export const InlineEditorToolBarStyles = css`
  position: fixed;
  display: flex;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid ${NEUTRAL_BORDER_COLOR};

  .closeInlineToolBar {
    color: ${PRIMARY_COLOR};
    line-height: 0;
    padding: 8px;
    border-radius: 20px;
    box-shadow: 10px 0px 14px -9px rgba(0, 0, 0, 0.75);
  }
`
