// import { Dispatch, Action } from 'redux'

import {
  // MessageLayoutConnectedProps,
  MessageLayoutMapDispatchReturnType,
} from './types'

export const MessageLayoutMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessageLayoutConnectedProps,
): MessageLayoutMapDispatchReturnType => {
  return {
  }
}
