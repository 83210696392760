import { put } from 'redux-saga/effects'
import { sessionStore } from 'utilities/sessionStore'
import { SESSION_STORAGE_KEY } from 'constants/environmentConstants'
import { SessionStorage } from 'types/SessionStorage'
import { createGetSessionStorageSuccessAction } from 'actionCreators/sessionStorage'

export const getSessionStorageF = function*() {
  const sessionStorage: SessionStorage =
    sessionStore.get(SESSION_STORAGE_KEY) || {}

  yield put(
    createGetSessionStorageSuccessAction({
      sessionStorage: {
        ...sessionStorage,
        isEndToEndTesting: !!sessionStorage.isEndToEndTesting,
      },
    })
  )
}
