import { StateReducers } from 'state/reducers/types/StateReducers'
import { TaskTagButtonFieldConnectedProps } from './types/TaskTagButtonFieldConnectedProps'
import { TaskTagButtonFieldMapStateReturnType } from './types/TaskTagButtonFieldMapStateReturnType'
import { getTagSelectOptions } from '../../../state/getters/getTagSelectOptions'
import { memoizeFlatArray } from '../../../utilities/memoizeFlatArray'
import { getCurrentTask } from '../../../state/getters/getCurrentTask'

export const TaskTagButtonFieldMapState = (
  state: StateReducers,
  props: TaskTagButtonFieldConnectedProps
): TaskTagButtonFieldMapStateReturnType => {
  const task = getCurrentTask({ state, taskId: props.taskId })
  const tagIds = task?.tags ? Object.keys(task?.tags) : []
  const { values, labels, types } = getTagSelectOptions({ state })

  return {
    tagIds: memoizeFlatArray(tagIds, 'SelectTagFieldMapState.tagIds'),
    values: memoizeFlatArray(values, 'SelectTagFieldMapState.values'),
    labels: memoizeFlatArray(labels, 'SelectTagFieldMapState.labels'),
    types: memoizeFlatArray(types, 'SelectTagFieldMapState.types'),
  }
}
