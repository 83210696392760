import { STRIPE_INVOICES_URL } from 'constants/urlConstants'
import { ApiHeaders } from 'firebaseFunctions/types/ApiHeaders'
import Axios, { AxiosPromise } from 'axios'
import { GetStripeInvoicesSuccessPayload } from 'firebaseFunctions/stripeInvoices/types/GetStripeInvoicesSuccessPayload'
import { GetStripeInvoicesRequestPayload } from '../../firebaseFunctions/stripeInvoices/types/GetStripeInvoicesRequestPayload'
import { createCleanQueryString } from '../../utilities/create/createCleanQueryString'

interface Props {
  apiHeaders: ApiHeaders
  values: GetStripeInvoicesRequestPayload
}

export const getStripeInvoicesApi = ({
  apiHeaders,
  values,
}: Props): AxiosPromise<GetStripeInvoicesSuccessPayload> => {
  const cleanedQueryString = createCleanQueryString({ query: values })
  const urlWithQuery =
    STRIPE_INVOICES_URL + (cleanedQueryString ? `?${cleanedQueryString}` : '')

  return Axios.get(urlWithQuery, {
    headers: apiHeaders,
  })
}
