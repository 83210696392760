import { UploadWatcherListStyles } from './UploadWatcherListStyles'
import React from 'react'
import { UploadWatcherListProps } from './types/UploadWatcherListProps'
import { UploadWatcherConnected } from 'components/watcher/UploadWatcher'

// TODO: make this draggable because it may block stuff
export const UploadWatcherList = ({
  uploadIds = [],
}: UploadWatcherListProps): JSX.Element => {
  return (
    <ul className={'UploadWatcherList ' + UploadWatcherListStyles}>
      {uploadIds.map((uploadId) => {
        return (
          <li key={uploadId}>
            <UploadWatcherConnected uploadId={uploadId} />
          </li>
        )
      })}
    </ul>
  )
}
