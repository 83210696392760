import { WindowMetaR } from './../reducers/types/WindowMetaR'
import { StateReducers } from 'state/reducers/types/StateReducers'

interface Props {
  state: StateReducers
}

interface Return extends Pick<WindowMetaR, 'firebaseMessagingToken'> {
  isMessagingEnabled: boolean
  isActivityEnabled: boolean
}

// TODO: write unit tests
export const getNotificationSettings = ({ state }: Props): Return => {
  const notificationTokens = state.currentUnscopeUser?.notificationTokens || []
  const firebaseMessagingToken = state.windowMeta?.firebaseMessagingToken

  let isMessagingEnabled = false
  let isActivityEnabled = false

  notificationTokens.forEach(({ messagingEnabled, activityEnabled, token }) => {
    if (token === firebaseMessagingToken) {
      isMessagingEnabled = !!messagingEnabled
      isActivityEnabled = !!activityEnabled
    }
  })

  return {
    isMessagingEnabled,
    isActivityEnabled,
    firebaseMessagingToken,
  }
}
