import { take, put } from 'redux-saga/effects'
import { auth } from 'sdks'
import { PATCH_CUSTOM_CLAIM_SUCCESS } from 'actions'
import { CustomClaim } from 'firebaseFunctions/types/CustomClaim'
import { createGetCustomClaimSuccessAction } from 'actionCreators/get/GetCustomClaim/createGetCustomClaimSuccessAction'

export const patchCustomClaimSuccessF = function*() {
  while (1) {
    yield take([PATCH_CUSTOM_CLAIM_SUCCESS])

    const currentUser = auth.currentUser

    if (currentUser) {
      const refreshTokenResult = yield currentUser.getIdTokenResult(true)

      yield put(
        createGetCustomClaimSuccessAction({
          requestValues: {},
          successValues: {
            item: refreshTokenResult.claims as CustomClaim,
          },
        })
      )
    }
  }
}
