import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getBackLogTaskIds } from 'state/getters/getBackLogTaskIds'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { getInBetweenNumber } from 'utilities/get/getInBetweenNumber'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import {
  SUBMIT_TASK_REORDER_DROP,
  SUBMIT_TASK_REORDER_DROP_DONE,
  FIX_TASK_ORDERS,
} from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'

export const submitTaskReorderDropF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_REORDER_DROP])
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const taskIds = getBackLogTaskIds({ state, useFilter: true })
    const { dropResult } = action.values
    const destination = dropResult && dropResult.destination
    const source = dropResult && dropResult.source
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const sourceIndex: number = (source && source.index) || 0
    const destinationIndex: number = (destination && destination.index) || 0
    const isComingFromPlan = source && source.droppableId !== 'null'
    const isGoingUp = sourceIndex > destinationIndex
    const draggableId = (dropResult && dropResult.draggableId) || ''

    const aboveIndex =
      isGoingUp || isComingFromPlan ? destinationIndex - 1 : destinationIndex
    const aboveTaskId = aboveIndex >= 0 && taskIds[aboveIndex]
    const aboveTask =
      aboveTaskId && getCurrentTask({ state, taskId: aboveTaskId })
    const max = aboveTask && aboveTask.order

    const belowIndex =
      isGoingUp || isComingFromPlan ? destinationIndex : destinationIndex + 1
    const belowTaskId = belowIndex < taskIds.length && taskIds[belowIndex]
    const belowTask =
      belowTaskId && getCurrentTask({ state, taskId: belowTaskId })
    const min = belowTask && belowTask.order

    let newOrderNumber = 0

    if (min && max) {
      newOrderNumber = getInBetweenNumber({ min, max })
    } else if (min) {
      newOrderNumber = min + 1000
    } else if (max) {
      newOrderNumber = max - 1000
    }

    if (draggableId && currentUserId && teamId && currentUserDisplayName) {
      const maxReached = newOrderNumber.toString().length > 17
      const sameOrders = newOrderNumber === min || newOrderNumber === max

      if (maxReached || sameOrders) {
        // Expected to fail in user eyes, should be ok
        // Could fix orders first then update based on drag and drop as
        yield put({
          type: FIX_TASK_ORDERS,
        })
      } else {
        const items: TasksReducer = {
          [draggableId]: {
            data: {
              order: newOrderNumber,
            },
            teamId,
          },
        }

        yield spawn(patchTasksSaga, {
          items: items,
          lastModifiedUserId: currentUserId,
          lastModifiedDisplayName: currentUserDisplayName,
        })
      }
    }

    yield put({
      type: SUBMIT_TASK_REORDER_DROP_DONE,
    })
  }
}
