import { css } from 'emotion'

// import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'

export const TeamCardStyles = css`
  text-transform: none;
  align-items: stretch;
  display: flex;

  .teamLink {
    flex: 1;
    padding: 16px;
  }

  .TeamDropdown {
    .DropdownButton {
      padding: 10px 8px 8px 8px;
    }
  }
`
