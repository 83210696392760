import { CropImageFieldStyles } from './CropImageFieldStyles'
import React, { useState, useEffect } from 'react'
import { CropImageFieldProps } from './types/CropImageFieldProps'
import ReactCrop, { Crop } from 'react-image-crop'

const getCroppedImg = (
  image: HTMLImageElement,
  crop: Crop,
  _fileName: string,
  resizeWidth?: number,
  resizeHeight?: number
): Promise<Blob | null> => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = resizeWidth || crop.width || 0
  canvas.height = resizeHeight || crop.height || 0
  const ctx = canvas.getContext('2d')
  const cropX = crop.x || 0
  const cropY = crop.y || 0
  const cropWidth = crop.width || 0
  const cropHeight = crop.height || 0

  return new Promise((resolve, reject) => {
    if (ctx) {
      ctx.drawImage(
        image,
        cropX * scaleX,
        cropY * scaleY,
        cropWidth * scaleX,
        cropHeight * scaleY,
        0,
        0,
        resizeWidth || cropWidth,
        resizeHeight || cropHeight
      )

      canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/jpeg')
    } else {
      reject()
    }
  })
}

const defaultCrop: Crop = {
  unit: '%',
  width: 50,
  height: 50,
}

export const CropImageField = ({
  src,
  crop = defaultCrop,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  keepSelection,
  resizeWidth,
  resizeHeight,
  onChange,
  className = '',
}: CropImageFieldProps): JSX.Element => {
  const [cropState, setCrop] = useState(crop)
  const [imageState, setImage] = useState<HTMLImageElement>()

  useEffect(() => {
    const handleChange = async () => {
      if (imageState && cropState) {
        const newCroppedImageBlob = await getCroppedImg(
          imageState,
          cropState,
          'newFile.jpeg',
          resizeWidth,
          resizeHeight
        )

        onChange?.(newCroppedImageBlob)
      }
    }

    handleChange()
  }, [cropState, imageState, onChange, resizeWidth, resizeHeight])

  const onImageLoaded = (image: HTMLImageElement) => {
    setImage(image)
  }

  return (
    <ReactCrop
      className={`CropImageField ${CropImageFieldStyles} ${className}`}
      src={src}
      onImageLoaded={onImageLoaded}
      crop={cropState}
      onChange={setCrop}
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      keepSelection={keepSelection}
    />
  )
}
