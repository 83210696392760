import { connect } from 'react-redux'
import { InvitesWatcher } from './InvitesWatcher'
import { InvitesWatcherMapState } from './InvitesWatcherMapState'
import { InvitesWatcherMapDispatch } from './InvitesWatcherMapDispatch'

import { InvitesWatcherMapStateReturnType } from './types/InvitesWatcherMapStateReturnType'
import { InvitesWatcherMapDispatchReturnType } from './types/InvitesWatcherMapDispatchReturnType'
import { InvitesWatcherConnectedProps } from './types/InvitesWatcherConnectedProps'


export const InvitesWatcherConnected = connect<
  InvitesWatcherMapStateReturnType,
  InvitesWatcherMapDispatchReturnType,
  InvitesWatcherConnectedProps
>(
  InvitesWatcherMapState,
  InvitesWatcherMapDispatch
)(InvitesWatcher)
