import { StateReducers } from '../reducers/types/StateReducers'
import { getCurrentTeamReducer } from './getCurrentTeamReducer'
import {
  WORKSPACE_MAX_USER_COUNT_PRO,
  WORKSPACE_MAX_USER_COUNT_FREE,
} from 'constants/environmentConstants'
import { getMaxUserCountFromTeamStat } from '../../firebaseFunctions/utilities/get/getMaxUserCountFromTeamStat'

interface Props {
  state: StateReducers
}

interface Return {
  maxUserCountFree: number
  maxUserCountPro: number
}

export const getMaxUserCount = ({ state }: Props): Return => {
  const teamReducer = getCurrentTeamReducer({ state })

  return getMaxUserCountFromTeamStat({
    teamStat: teamReducer?.stats,
    configMaxUserCountFree: WORKSPACE_MAX_USER_COUNT_FREE,
    configMaxUserCountPro: WORKSPACE_MAX_USER_COUNT_PRO,
  })
}
