import * as React from 'react'
import { PlanTasksWatcherProps } from './types/PlanTasksWatcherProps'
import { firestore } from 'sdks'
import { getTasksRef } from '../../../firebaseFunctions/utilities/ref/get/getTasksRef'

export class PlanTasksWatcher extends React.Component {
  props: PlanTasksWatcherProps
  unsubscribe?: () => void

  unsubscribeAll() {
    if (this.unsubscribe) {
      this.unsubscribe()
      this.unsubscribe = undefined
      this.props.incrementWatcher(1)
    }
  }

  componentWillUnmount() {
    this.unsubscribeAll()
  }

  public render(): null {
    const {
      teamId,
      planId,
      onNext,
      onFirebaseError,
      incrementWatcher,
    } = this.props

    if (!this.unsubscribe) {
      this.unsubscribe = getTasksRef({ firestore, teamId })
        .where('planId', '==', planId)
        .onSnapshot(onNext, onFirebaseError)

      incrementWatcher(1)
    }

    return null
  }
}
