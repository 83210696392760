import React from 'react'
import { TeamOwnerActionListProps } from './types/TeamOwnerActionListProps'
import { MEMBERS_ROUTE, TEAM_SETTINGS_ROUTE } from 'routes'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { UsersIcon } from 'components/icon/UsersIcon/UsersIcon'
import { actionButtonStyles } from 'styles/actionButtonStyles'
import { USERS_NOUN } from '../../../constants/english'
import { CogIcon } from '../../icon/CogIcon/CogIcon'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'

export const TeamOwnerActionList = ({
  className = '',
}: TeamOwnerActionListProps): JSX.Element => {
  return (
    <ul
      className={`TeamOwnerActionList ${className} ${listActionButtonsStyles}`}
    >
      <li>
        <CustomLinkConnected
          route={MEMBERS_ROUTE}
          withCurrentQuery={true}
          className={actionButtonStyles}
          title={`Manage ${USERS_NOUN}`}
        >
          <UsersIcon /> {USERS_NOUN}
        </CustomLinkConnected>
      </li>

      <li>
        <CustomLinkConnected
          route={TEAM_SETTINGS_ROUTE}
          withCurrentQuery={true}
          className={actionButtonStyles}
          title="add new tag"
        >
          <CogIcon /> Settings
        </CustomLinkConnected>
      </li>
    </ul>
  )
}
