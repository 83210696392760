import { Task } from 'firebaseFunctions/types'
import { TaskReducer } from 'state/reducers/types/TaskReducer'
import { MOCK_USER_A_ID, MOCK_USER_B_ID } from '../mockUsers'
import { MOCK_LABEL_A_ID } from '../mockLabels'
import { MOCK_TEAM_A_ID } from '../mockTeams/mockTeamsConstants'
import firebase from 'firebase'

const data: Task = {
  created: firebase.firestore.Timestamp.fromDate(new Date(9999)),
  summary:
    'mockTaskA Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  status: 'new',
  tags: {
    [MOCK_USER_A_ID]: {
      type: 'user',
    },
    [MOCK_USER_B_ID]: {
      type: 'user',
    },
    [MOCK_LABEL_A_ID]: {
      type: 'label',
    },
  },
}

export const mockTaskA: TaskReducer = {
  data,
  teamId: MOCK_TEAM_A_ID,
}
