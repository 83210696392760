import { POST_USER_FAILURE } from 'actions'

import { PostUserFailureAction } from './types/PostUserFailureAction'
import { PostUserRequestActionValues } from './types/PostUserRequestActionValues'


interface Props {
  requestValues?: PostUserRequestActionValues
}

export const createPostUserFailureAction = ({
  requestValues,
}: Props): PostUserFailureAction => {
  return {
    type: POST_USER_FAILURE,
    requestValues,
  }
}
