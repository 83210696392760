import { DeleteWorkspaceMainConnectedProps } from './types/DeleteWorkspaceMainConnectedProps'
import { DeleteWorkspaceMainMapStateReturnType } from './types/DeleteWorkspaceMainMapStateReturnType'

import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'

export const DeleteWorkspaceMainMapState = (
  state: StateReducers,
  _props: DeleteWorkspaceMainConnectedProps
): DeleteWorkspaceMainMapStateReturnType => {
  const currentTeamReducer = getCurrentTeamReducer({ state })
  const displayName = currentTeamReducer?.data?.displayName

  return {
    displayName,
  }
}
