import { connect } from 'react-redux'
import { TaskFileCard } from './TaskFileCard'
import { TaskFileCardMapState } from './TaskFileCardMapState'
import { TaskFileCardMapDispatch } from './TaskFileCardMapDispatch'

import { TaskFileCardMapStateReturnType } from './types/TaskFileCardMapStateReturnType'
import { TaskFileCardMapDispatchReturnType } from './types/TaskFileCardMapDispatchReturnType'
import { TaskFileCardConnectedProps } from './types/TaskFileCardConnectedProps'


export const TaskFileCardConnected = connect<
  TaskFileCardMapStateReturnType,
  TaskFileCardMapDispatchReturnType,
  TaskFileCardConnectedProps>(TaskFileCardMapState,
    TaskFileCardMapDispatch)(TaskFileCard)
