import { all, spawn, call } from 'redux-saga/effects'
import { apiFlowErrorF } from './apiFlowErrorF'
import { authFlows } from './auth/authFlows'
import { deleteFlows } from './delete/deleteFlows'
import { patchFlows } from './patch/patchFlows'
import { firestoreFailureF } from './firestoreFailureF'
import { getFlows } from './get/getFlows'
import { postFlows } from './post/postFlows'
import { signInSuccessF } from './signInSuccessF'
import { submitFlows } from './submit/submitFlows'
import { requestFlows } from './request/requestFlows'
import { toggleFlows } from './toggles/toggleFlows'
import { testErrorsF } from './testErrorsF'
import { goBackF } from './goBackF'
import { unexpectedErrorF } from './unexpectedErrorF'
import { adminFlows } from './admin/adminFlows'
import { readingMessagesF } from './readingMessagesF'
import { setFlows } from './set/setFlows'
// import { waitToJoinTeamF } from './waitToJoinTeamF'
import { refreshTokenF } from './refreshTokenF'
import { fixTaskOrdersF } from './fixTaskOrdersF'
import { newPushNofiticationF } from './newPushNofiticationF'
import { clearTeamScopeF } from './clearTeamScopeF'
import { errorF } from './errorF'
import { proCanceledF } from './proCanceledF'
import { historyWithCurrentQueryF } from './historyWithCurrentQueryF'
import { closeDropdownF } from './closeDropdownF'
import { showRootPageF } from './showRootPageF'
import { alreadyLoggedInF } from './alreadyLoggedInF'

export const flows = function* root() {
  const allFlows = [
    ...authFlows,
    ...getFlows,
    ...postFlows,
    ...submitFlows,
    ...toggleFlows,
    ...deleteFlows,
    ...patchFlows,
    ...requestFlows,
    ...adminFlows,
    ...setFlows,
    signInSuccessF,
    firestoreFailureF,
    apiFlowErrorF,
    errorF,
    testErrorsF,
    goBackF,
    unexpectedErrorF,
    readingMessagesF,
    // waitToJoinTeamF,
    refreshTokenF,
    fixTaskOrdersF,
    newPushNofiticationF,
    clearTeamScopeF,
    proCanceledF,
    historyWithCurrentQueryF,
    closeDropdownF,
    showRootPageF,
    alreadyLoggedInF,
  ]

  yield all(
    allFlows.map((flow) =>
      spawn(function*() {
        while (true) {
          try {
            yield call(flow)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}
