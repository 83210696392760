import { StatusColorDropdownStyles } from './StatusColorDropdownStyles'
import React, { useCallback } from 'react'
import { StatusColorDropdownProps } from './types/StatusColorDropdownProps'
import { DropdownButtonConnected } from 'components/button/DropdownButton'
import { DropdownConnected } from 'components/dropdown/Dropdown'
import { ColorPickerWidget } from 'components/widget/ColorPickerWidget'

export const StatusColorDropdown = ({
  showStatusColorDropdown,
  status,
  color = '#000',
  onColorClick,
}: StatusColorDropdownProps): JSX.Element => {
  const onColorClickCallback = useCallback(
    (newColor: string) => {
      onColorClick({ status, color: newColor })
    },
    [status, onColorClick]
  )

  return (
    <div
      className={`StatusColorDropdown _${status} ` + StatusColorDropdownStyles}
    >
      <DropdownButtonConnected
        dropdownType="statusColor"
        title="Select status color"
        watchSelector={`.StatusColorDropdown._${status}`}
        id={status}
        style={{
          backgroundColor: color,
        }}
      />

      {showStatusColorDropdown && (
        <DropdownConnected>
          <ColorPickerWidget onColorClick={onColorClickCallback} />
        </DropdownConnected>
      )}
    </div>
  )
}
