import React from 'react'
import { Map } from 'immutable'
import { Mention } from './Mention'
import { MentionSuggestions } from './MentionSuggestions' // eslint-disable-line import/no-named-as-default
import { MentionSuggestionsPortal } from './MentionSuggestionsPortal'
import { defaultRegExp } from './defaultRegExp'
import { mentionStrategy } from './mentionStrategy'
import { mentionSuggestionsStrategy } from './mentionSuggestionsStrategy'
import { defaultPositionSuggestions } from './defaultPositionSuggestions'
import { defaultTheme } from './theme'
import { EditorState } from 'draft-js'

export type MentionSuggestionsType = (props: any) => JSX.Element

export type MentionItem = {
  id: string
  name: string
  avatar: string
}

export interface MentionPlugin {
  MentionSuggestions: MentionSuggestionsType
  decorators: any
  getAccessibilityProps: any

  initialize: any

  keyBindingFn: any

  handleReturn: any

  onChange: (editorState: EditorState) => EditorState
}

export const createMentionPlugin = (config: any = {}): MentionPlugin => {
  const callbacks: any = {
    keyBindingFn: undefined,
    handleKeyCommand: undefined,
    handleReturn: undefined,
    onChange: undefined,
  }

  const ariaProps = {
    ariaHasPopup: 'false',
    ariaExpanded: false,
    ariaOwneeID: undefined,
    ariaActiveDescendantID: undefined,
  }

  let searches = Map()
  let escapedSearch: any
  let clientRectFunctions: any = Map()
  let isOpened: any

  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
    getPortalClientRect: (offsetKey: any) =>
      clientRectFunctions.get(offsetKey)(),
    getAllSearches: () => searches,
    isEscaped: (offsetKey: any) => escapedSearch === offsetKey,
    escapeSearch: (offsetKey: any) => {
      escapedSearch = offsetKey
    },

    resetEscapedSearch: () => {
      escapedSearch = undefined
    },

    register: (offsetKey: any) => {
      searches = searches.set(offsetKey, offsetKey)
    },

    updatePortalClientRect: (offsetKey: any, func: any) => {
      clientRectFunctions = clientRectFunctions.set(offsetKey, func)
    },

    unregister: (offsetKey: any) => {
      searches = searches.delete(offsetKey)
      clientRectFunctions = clientRectFunctions.delete(offsetKey)
    },

    getIsOpened: () => isOpened,
    setIsOpened: (nextIsOpened: any) => {
      isOpened = nextIsOpened
    },
  }

  // Styles are overwritten instead of merged as merging causes a lot of confusion.
  //
  // Why? Because when merging a developer needs to know all of the underlying
  // styles which needs a deep dive into the code. Merging also makes it prone to
  // errors when upgrading as basically every styling change would become a major
  // breaking change. 1px of an increased padding can break a whole layout.
  const {
    mentionPrefix = '',
    theme = defaultTheme,
    positionSuggestions = defaultPositionSuggestions,
    mentionComponent,
    mentionSuggestionsComponent: MentionSuggestionsComponent = MentionSuggestions,
    entityMutability = 'SEGMENTED',
    mentionTrigger = '@',
    mentionRegExp = defaultRegExp,
    supportWhitespace = false,
  } = config
  const mentionSearchProps = {
    ariaProps,
    callbacks,
    theme,
    store,
    entityMutability,
    positionSuggestions,
    mentionTrigger,
    mentionPrefix,
  }
  const DecoratedMentionSuggestionsComponent = (props: any) => (
    <MentionSuggestionsComponent {...props} {...mentionSearchProps} />
  )
  const DecoratedMention = (props: any) => (
    <Mention {...props} theme={theme} mentionComponent={mentionComponent} />
  )
  const DecoratedMentionSuggestionsPortal = (props: any) => (
    <MentionSuggestionsPortal {...props} store={store} />
  )
  return {
    MentionSuggestions: DecoratedMentionSuggestionsComponent,
    decorators: [
      {
        strategy: mentionStrategy(mentionTrigger),
        component: DecoratedMention,
      },
      {
        strategy: mentionSuggestionsStrategy(
          mentionTrigger,
          supportWhitespace,
          mentionRegExp
        ),
        component: DecoratedMentionSuggestionsPortal,
      },
    ],
    getAccessibilityProps: () => ({
      role: 'combobox',
      ariaAutoComplete: 'list',
      ariaHasPopup: ariaProps.ariaHasPopup,
      ariaExpanded: ariaProps.ariaExpanded,
      ariaActiveDescendantID: ariaProps.ariaActiveDescendantID,
      ariaOwneeID: ariaProps.ariaOwneeID,
    }),

    initialize: ({ getEditorState, setEditorState }: any) => {
      store.getEditorState = getEditorState
      store.setEditorState = setEditorState
    },

    keyBindingFn: (keyboardEvent: any) => {
      if (callbacks.keyBindingFn) {
        return callbacks.keyBindingFn(keyboardEvent)
      }
    },

    handleReturn: (keyboardEvent: any) => {
      if (callbacks.handleReturn) {
        return callbacks.handleReturn(keyboardEvent)
      }
    },

    onChange: (editorState: any) => {
      if (callbacks.onChange) {
        return callbacks.onChange(editorState)
      }

      return editorState
    },
  }
}
