import { GET_TEAM_STAT_SUCCESS } from 'actions'

import { GetTeamStatSuccessAction } from './types/GetTeamStatSuccessAction'
import { GetTeamStatSuccessActionValues } from './types/GetTeamStatSuccessActionValues'
import { GetTeamStatRequestActionValues } from './types/GetTeamStatRequestActionValues'


interface Props {
  successValues: GetTeamStatSuccessActionValues
  requestValues: GetTeamStatRequestActionValues
}

export const createGetTeamStatSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetTeamStatSuccessAction => {
  return {
    type: GET_TEAM_STAT_SUCCESS,
    requestValues,
    successValues,
  }
}
