import { MarkdownDivStyles } from './MarkdownDivStyles'
import * as React from 'react'
import { MarkdownDivProps } from './types/MarkdownDivProps'
import ReactMarkdown from 'react-markdown'
import { MentionLink } from 'components/link/MentionLink'

export const MarkdownDiv = ({
  children = '',
  className = '',
}: MarkdownDivProps): JSX.Element => {
  const renderers: any = {
    link: (props: any) => {
      const { href, children } = props

      if (href && href[0] === '@') {
        return <MentionLink>{children}</MentionLink>
      } else {
        return (
          <a href={href} target="blank" rel="noopener noreferrer">
            {children}
          </a>
        )
      }
    },
  }

  return (
    <ReactMarkdown
      className={`MarkdownDiv ${MarkdownDivStyles} ${className}`}
      source={children}
      renderers={renderers}
    />
  )
}
