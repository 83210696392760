// import { Dispatch, Action } from 'redux'

import {
  // TaskMainDropdownConnectedProps,
  TaskMainDropdownMapDispatchReturnType,
} from './types'

export const TaskMainDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskMainDropdownConnectedProps,
): TaskMainDropdownMapDispatchReturnType => {
  return {
  }
}
