import { take, put, race } from 'redux-saga/effects'
import { createPostLoginRequestAction } from 'actionCreators/post/PostLogin/createPostLoginRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import {
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_DONE,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_LOGIN_CANCEL,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

export const submitLoginF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_LOGIN])
    const { email, password } = action.values

    if (email && password) {
      const postLoginAction = createPostLoginRequestAction({
        requestValues: {
          email,
          password,
        },
      })

      yield put(postLoginAction)

      yield race({
        success: take(POST_LOGIN_SUCCESS),
        failureOrCancel: take([POST_LOGIN_FAILURE, POST_LOGIN_CANCEL]),
      })

      // if (postLoginResults.success) {
      //   const getTeamsSuccessAction: GetTeamsSuccessAction = yield take([GET_TEAMS_SUCCESS])
      //   const teams = getTeamsSuccessAction.successValues.items
      //   const { key: firstTeamId } = getObjectFirstItem<Team>(teams)
      //
      //   if (firstTeamId) {
      //     const historyAction = createHistoryAction({
      //       pathname: DASHBOARD_HOME_ROUTE,
      //       method: 'push',
      //       query: {
      //         teamId: firstTeamId,
      //       },
      //     })
      //
      //     yield put(historyAction)
      //   }
      // }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitLoginF',
        })
      )
    }

    yield put({
      type: SUBMIT_LOGIN_DONE,
    })
  }
}
