import { connect } from 'react-redux'
import { TaskChangeCard } from './TaskChangeCard'
import { TaskChangeCardMapState } from './TaskChangeCardMapState'
import { TaskChangeCardMapDispatch } from './TaskChangeCardMapDispatch'

import { TaskChangeCardMapStateReturnType } from './types/TaskChangeCardMapStateReturnType'
import { TaskChangeCardMapDispatchReturnType } from './types/TaskChangeCardMapDispatchReturnType'
import { TaskChangeCardConnectedProps } from './types/TaskChangeCardConnectedProps'


export const TaskChangeCardConnected = connect<
  TaskChangeCardMapStateReturnType,
  TaskChangeCardMapDispatchReturnType,
  TaskChangeCardConnectedProps>(TaskChangeCardMapState,
    TaskChangeCardMapDispatch)(TaskChangeCard)
