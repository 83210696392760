import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { PlansReducer } from 'state/reducers/types/PlansReducer'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { uniq } from 'lodash'
import { MAX_PLAN_TASK_COUNT } from 'constants/environmentConstants'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createToggleAction } from 'actionCreators/createToggleAction'

import { SUBMIT_TASK_PLAN_DROP, SUBMIT_TASK_PLAN_DROP_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { patchPlansSaga } from 'flows/sagas/patch/patchPlansSaga'

export const submitTaskPlanDropF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_TASK_PLAN_DROP])
    const values = action.values
    const state: StateReducers = yield select(selectState)
    const teamId = getCurrentTeamId({ state })
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const plans = state.plans || undefined
    const { fromPlanId, toPlanId, taskId, toIndex, fromIndex } = values
    const fromPlan = fromPlanId ? plans?.[fromPlanId] : undefined
    const fromTaskIds = fromPlan?.data.taskIds || []
    const toPlan = toPlanId ? plans?.[toPlanId] : undefined
    const toTaskIds = toPlan?.data.taskIds || []
    const toPlanIsFull = Array.isArray(toTaskIds)
      ? toTaskIds.length > MAX_PLAN_TASK_COUNT
      : false
    const isOnlyReodering = toPlanId === fromPlanId

    if (toPlanIsFull && !isOnlyReodering) {
      yield put(
        createToggleAction({
          description: 'submitTaskPlanDropF',
          toggles: {
            showModal: {
              type: 'PlanIsFull',
            },
          },
        })
      )
    } else if (taskId && teamId && currentUserId && currentUserDisplayName) {
      const itemsMutable: PlansReducer = {}

      if (toIndex !== undefined && toPlanId) {
        const uniqueToTaskIds = Array.isArray(toTaskIds) ? uniq(toTaskIds) : []

        const filteredToTaskIds = uniqueToTaskIds.filter((toTaskId) => {
          return toTaskId !== taskId
        })

        const newToTaskIdsMutable = [...filteredToTaskIds]

        newToTaskIdsMutable.splice(toIndex, 0, taskId)

        itemsMutable[toPlanId] = {
          data: {
            taskIds: newToTaskIdsMutable,
          },
          teamId,
        }
      }

      if (fromIndex !== undefined && fromPlanId && fromPlanId !== toPlanId) {
        const uniqueFromTaskIds = Array.isArray(fromTaskIds)
          ? uniq(fromTaskIds)
          : []

        const newFromTaskIds = uniqueFromTaskIds.filter((_fromTaskId, i) => {
          return i !== fromIndex
        })

        itemsMutable[fromPlanId] = {
          data: {
            taskIds: newFromTaskIds,
          },
          teamId,
        }
      }

      if (Object.keys(itemsMutable).length > 0) {
        yield spawn(patchPlansSaga, {
          items: itemsMutable,
          lastModifiedUserId: currentUserId,
          lastModifiedDisplayName: currentUserDisplayName,
        })

        if (!isOnlyReodering) {
          const items: TasksReducer = {
            [taskId]: {
              data: {
                planId: toPlanId || null,
              },
              teamId,
            },
          }

          yield spawn(patchTasksSaga, {
            items: items,
            lastModifiedUserId: currentUserId,
            lastModifiedDisplayName: currentUserDisplayName,
          })
        }
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskPlanDropF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_PLAN_DROP_DONE,
    })
  }
}
