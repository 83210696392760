import { firebaseMessaging } from 'sdks'
import { cookieStore } from 'utilities/cookieStore'
import { FCM_TOKEN } from 'constants/cookieConstants'

interface Props {
  askForPermission?: boolean
}

export const getFirebaseMessagingToken = async ({
  askForPermission,
}: Props = {}): Promise<string | null> => {
  let token = cookieStore.get(FCM_TOKEN)
  if (typeof Notification !== 'undefined' && firebaseMessaging) {
    if (askForPermission) {
      const perimission = await Notification.requestPermission()

      if (perimission !== 'granted') {
        return null
      }
    }
    token = await firebaseMessaging.getToken()
  }

  return token
}
