import { connect } from 'react-redux'
import { Dropdown } from './Dropdown'
import { DropdownMapState } from './DropdownMapState'
import { DropdownMapDispatch } from './DropdownMapDispatch'

import { DropdownMapStateReturnType } from './types/DropdownMapStateReturnType'
import { DropdownMapDispatchReturnType } from './types/DropdownMapDispatchReturnType'
import { DropdownConnectedProps } from './types/DropdownConnectedProps'


export const DropdownConnected = connect<
  DropdownMapStateReturnType,
  DropdownMapDispatchReturnType,
  DropdownConnectedProps>(DropdownMapState,
    DropdownMapDispatch)(Dropdown)
