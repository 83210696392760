import { css } from 'emotion'

export const TaskSizeDropdownStyles = css`
  position: relative;

  .Dropdown {
    .chevronContainer {
      left: 6px;
      top: 21px;
    }

    .container {
      width: 320px;
      left: 0px;
      top: 28px;
    }
  }
`
