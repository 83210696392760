import { HISTORY_ACTION_WITH_CURRENT_QUERY } from 'actions'
import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { HistoryActionWithCurrentQuery } from 'actionCreators/types/HistoryActionWithCurrentQuery'

export const historyWithCurrentQueryF = function*() {
  while (1) {
    const action: HistoryActionWithCurrentQuery = yield take([
      HISTORY_ACTION_WITH_CURRENT_QUERY,
    ])
    const state: StateReducers = yield select(selectState)
    const currentQuery = getCurrentQuery({ state })
    const { method, pathname, query } = action.values
    const { description } = action

    yield put(
      createHistoryAction({
        description,
        method,
        pathname,
        query: {
          ...currentQuery,
          ...query,
        },
      })
    )
  }
}
