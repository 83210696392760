import { Location } from 'history'
import { Dispatch, Action } from 'redux'
import { createLocationChangeAction } from 'actionCreators/createLocationChangeAction'

export interface ReduxRouterMapDispatchReturnType {
  locationChange(location: Location): void
}

export const ReduxRouterMapDispatch = (
  dispatch: Dispatch<Action>
): ReduxRouterMapDispatchReturnType => {
  return {
    locationChange(location: Location): void {
      const locationChangeAction = createLocationChangeAction({
        search: location.search,
        hash: location.hash,
        pathname: location.pathname,
      })

      dispatch(locationChangeAction)
    },
  }
}
