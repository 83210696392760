import { UpdateMessageEditorStyles } from './UpdateMessageEditorStyles'
import { UpdateMessageEditorProps } from './types/UpdateMessageEditorProps'
import React from 'react'
import { DraftEditorConnected } from 'components/editor/DraftEditor'
import { AvatarConnected } from 'components/widget/Avatar'
import { MessagingToolBar } from 'components/bar/MessagingToolBar'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'

export const UpdateMessageEditor = ({
  save,
  description,
}: UpdateMessageEditorProps): JSX.Element => {
  return (
    <div className={'UpdateMessageEditor ' + UpdateMessageEditorStyles}>
      <HeaderOneTextConnected>Edit Message</HeaderOneTextConnected>

      <div className="avatarEditor">
        <AvatarConnected />

        <DraftEditorConnected
          save={save}
          value={description}
          autoFocus={true}
          headerToolBar={(props) => {
            return (
              <MessagingToolBar {...props} dropdownId="UpdateMessageEditor" />
            )
          }}
          placeholder="Write a new message"
        />
      </div>
    </div>
  )
}
