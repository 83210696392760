import { css } from 'emotion'

export const TagPlusIconStyles = css`
  > * {
    vertical-align: middle;
  }

  .PlusSolidIcon {
    height: 10px;
    margin: 0 2px 0 0;
  }
`
