import { Dispatch, Action } from 'redux'
import { UploadedFileWatcherConnectedProps } from './types/UploadedFileWatcherConnectedProps'
import { UploadedFileWatcherMapDispatchReturnType } from './types/UploadedFileWatcherMapDispatchReturnType'
import { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { UploadedFile } from 'firebaseFunctions/types'
import { UploadedFilesReducer } from 'state/reducers/types/UploadedFilesReducer'
import { UploadedFileReducer } from 'state/reducers/types/UploadedFileReducer'
import { createDeleteUploadedFilesSuccessAction } from 'actionCreators/delete/DeleteUploadedFiles/createDeleteUploadedFilesSuccessAction'
import { createGetUploadedFilesSuccessAction } from 'actionCreators/get/GetUploadedFiles/createGetUploadedFilesSuccessAction'

export const UploadedFileWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: UploadedFileWatcherConnectedProps
): UploadedFileWatcherMapDispatchReturnType => {
  return {
    onNext: (querySnapshot: firebase.firestore.QuerySnapshot) => {
      const newUploadedFileMutable: UploadedFilesReducer = {}
      const deletedUploadedFileMutable: UploadedFilesReducer = {}

      querySnapshot.docChanges().forEach((change) => {
        // TODO: use docChanges
        const uploadedFile = change.doc.data() as UploadedFile | undefined
        const uploadedFileId = change.doc.id

        if (!uploadedFile || !uploadedFile.created) {
          // skip to prevent it overwriting the temp created time
          return
        }

        const uploadedFileReducer: UploadedFileReducer = {
          data: uploadedFile,
          teamId: props.teamId,
        }

        if (change.type === 'added' || change.type === 'modified') {
          newUploadedFileMutable[uploadedFileId] = uploadedFileReducer
        }

        if (change.type === 'removed') {
          deletedUploadedFileMutable[uploadedFileId] = uploadedFileReducer
        }
      })

      if (Object.keys(newUploadedFileMutable).length > 0) {
        dispatch(
          createGetUploadedFilesSuccessAction({
            requestValues: { teamId: props.teamId },
            successValues: {
              items: newUploadedFileMutable,
            },
          })
        )
      }

      if (Object.keys(deletedUploadedFileMutable).length > 0) {
        dispatch(
          createDeleteUploadedFilesSuccessAction({
            requestValues: {
              items: deletedUploadedFileMutable,
            },
            successValues: {
              items: deletedUploadedFileMutable,
            },
          })
        )
      }
    },

    onFirebaseError: (error: FirebaseError) => {
      dispatch(
        createFirebaseErrorAction({
          error,
          description: 'UploadedFileListMapDispatch',
        })
      )
    },
  }
}
