import { css } from 'emotion'

export const CountBubbleWidgetStyles = css`
  color: #fff;
  padding: 4px;
  min-width: 14px;
  background-color: #fa3e3e;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 0.7em;
`
