import { getTypeByTrigger } from './getTypeByTrigger'

export const mentionStrategy = (trigger: any) => (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === getTypeByTrigger(trigger)
    )
  }, callback)
}
