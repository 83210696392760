import { StateReducers } from 'state/reducers/types/StateReducers'
import { NewInviteFormConnectedProps } from './types/NewInviteFormConnectedProps'
import { NewInviteFormMapStateReturnType } from './types/NewInviteFormMapStateReturnType'
import { getUserRoles } from 'state/getters/getUserRoles'
import { getCurrentTeamReducer } from 'state/getters/getCurrentTeamReducer'
import { getIsProFromState } from 'state/getters/getIsProFromState'
import { getMaxInviteEmailCount } from 'utilities/get/getMaxInviteEmailCount'
import { forEach } from 'lodash'
import { getMaxUserCount } from '../../../state/getters/getMaxUserCount'

export const NewInviteFormMapState = (
  state: StateReducers,
  _props: NewInviteFormConnectedProps
): NewInviteFormMapStateReturnType => {
  const userRoles = getUserRoles({ state })
  const teamReducer = getCurrentTeamReducer({ state })
  const inviteCount = Object.keys(state.invites || {}).length
  const userCount = (teamReducer?.stats?.userCount || 0) + inviteCount
  const isPro = getIsProFromState({ state })
  const { maxUserCountFree, maxUserCountPro } = getMaxUserCount({ state })
  const maxUserCountReachedFree = !isPro && userCount >= maxUserCountFree
  const maxUserCountReachedPro = userCount >= maxUserCountPro
  const invites = state.invites
  const blacklistMuttable: string[] = []
  const maxEmailCount = getMaxInviteEmailCount({
    isPro,
    userCount,
    maxUserCountFree,
    maxUserCountPro,
  })

  forEach(userRoles, ({ email }) => {
    if (email) {
      blacklistMuttable.push(email)
    }
  })

  forEach(invites, (inviteReducer) => {
    const { email } = inviteReducer.data

    if (email) {
      blacklistMuttable.push(email)
    }
  })

  return {
    maxUserCountReachedFree,
    maxUserCountReachedPro,
    maxEmailCount,
    isPro,
    blacklist: blacklistMuttable,
  }
}
