import { connect } from 'react-redux'
import { FeedbackForm } from './FeedbackForm'
import { FeedbackFormMapState } from './FeedbackFormMapState'
import { FeedbackFormMapDispatch } from './FeedbackFormMapDispatch'
import { FeedbackFormMapStateReturnType } from './types/FeedbackFormMapStateReturnType'
import { FeedbackFormMapDispatchReturnType } from './types/FeedbackFormMapDispatchReturnType'
import { FeedbackFormConnectedProps } from './types/FeedbackFormConnectedProps'

export const FeedbackFormConnected = connect<
  FeedbackFormMapStateReturnType,
  FeedbackFormMapDispatchReturnType,
  FeedbackFormConnectedProps>(FeedbackFormMapState,
    FeedbackFormMapDispatch)(FeedbackForm)
