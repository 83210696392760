import { STRIPE_SUBSCRIPTION_URL } from 'constants/urlConstants'
import { ApiHeaders } from 'firebaseFunctions/types/ApiHeaders'
import Axios, { AxiosPromise } from 'axios'
import { GetStripeSubscriptionsSuccessPayload } from 'firebaseFunctions/stripeSubscriptions/types/GetStripeSubscriptionsSuccessPayload'

interface Props {
  apiHeaders: ApiHeaders
}

export const getStripeSubscriptionsApi = ({
  apiHeaders,
}: Props): AxiosPromise<GetStripeSubscriptionsSuccessPayload> => {
  return Axios.get(STRIPE_SUBSCRIPTION_URL, {
    headers: apiHeaders,
  })
}
