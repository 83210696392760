import { GET_USER_EXTENSION_SUCCESS } from 'actions'

import { GetUserExtensionSuccessAction } from './types/GetUserExtensionSuccessAction'
import { GetUserExtensionSuccessActionValues } from './types/GetUserExtensionSuccessActionValues'
import { GetUserExtensionRequestActionValues } from './types/GetUserExtensionRequestActionValues'


interface Props {
  successValues: GetUserExtensionSuccessActionValues
  requestValues: GetUserExtensionRequestActionValues
}

export const createGetUserExtensionSuccessAction = ({
  successValues,
  requestValues,
}: Props): GetUserExtensionSuccessAction => {
  return {
    type: GET_USER_EXTENSION_SUCCESS,
    requestValues,
    successValues,
  }
}
