import React from 'react'
import { TagPlusIconProps } from './types/TagPlusIconProps'
import { TagIcon } from '../TagIcon/TagIcon'
import { PlusSolidIcon } from '../PlusSolidIcon/PlusSolidIcon'
import { TagPlusIconStyles } from './TagPlusIconStyles'

export const TagPlusIcon = ({
  style,
  className = '',
}: TagPlusIconProps): JSX.Element => {
  return (
    <div
      className={`TagPlusIcon ${TagPlusIconStyles} ${className}`}
      style={style}
    >
      <PlusSolidIcon />
      <TagIcon />
    </div>
  )
}
