// import { TagDisplayNameSpanStyles } from './TagDisplayNameSpanStyles'
import React from 'react'
import { TagDisplayNameSpanProps } from './types/TagDisplayNameSpanProps'
import { DisplayNameSpanConnected } from 'components/span/DisplayNameSpan'

export const TagDisplayNameSpan = ({
  tagType,
  displayName,
  tagId,
}: TagDisplayNameSpanProps): JSX.Element => {
  const isUserType = tagType === 'user'

  if (isUserType) {
    return (
      <DisplayNameSpanConnected userId={tagId}>
        {displayName}
      </DisplayNameSpanConnected>
    )
  }

  return (
    <DisplayNameSpanConnected labelId={tagId}>
      {displayName}
    </DisplayNameSpanConnected>
  )
}
