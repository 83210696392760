import { connect } from 'react-redux'
import { StatusColorDropdown } from './StatusColorDropdown'
import { StatusColorDropdownMapState } from './StatusColorDropdownMapState'
import { StatusColorDropdownMapDispatch } from './StatusColorDropdownMapDispatch'

import { StatusColorDropdownMapStateReturnType } from './types/StatusColorDropdownMapStateReturnType'
import { StatusColorDropdownMapDispatchReturnType } from './types/StatusColorDropdownMapDispatchReturnType'
import { StatusColorDropdownConnectedProps } from './types/StatusColorDropdownConnectedProps'


export const StatusColorDropdownConnected = connect<
  StatusColorDropdownMapStateReturnType,
  StatusColorDropdownMapDispatchReturnType,
  StatusColorDropdownConnectedProps>(StatusColorDropdownMapState,
    StatusColorDropdownMapDispatch)(StatusColorDropdown)
