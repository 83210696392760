import { POST_LOGIN_REQUEST } from 'actions'
import { PostLoginRequestAction } from './types/PostLoginRequestAction'
import { PostLoginRequestActionValues } from './types/PostLoginRequestActionValues'

interface Props {
  requestValues: PostLoginRequestActionValues,
}

export const createPostLoginRequestAction = ({ requestValues }: Props): PostLoginRequestAction => {
  return {
    type: POST_LOGIN_REQUEST,
    requestValues,
  }
}
