import { Dispatch, Action } from 'redux'
import { GOOGLE_SIGN_IN_REQUEST } from 'actions'

import {
  // GoogleSignInButtonConnectProps,
  GoogleSignInButtonMapDispatchReturnType,
} from './types'

export const GoogleSignInButtonMapDispatch = (
  dispatch: Dispatch<Action>,
  // props: GoogleSignInButtonConnectProps,
): GoogleSignInButtonMapDispatchReturnType => {
  return {
    onClick: () => {
      dispatch({
        type: GOOGLE_SIGN_IN_REQUEST,
      })
    },
  }
}
