import { SendConfirmEmailButtonStyles } from './SendConfirmEmailButtonStyles'
import React, { useState } from 'react'
import { SendConfirmEmailButtonProps } from './types/SendConfirmEmailButtonProps'
import { ActionButton } from '../ActionButton'
import { getIdToken } from 'sdks/firebase/getIdToken'
import { postConfirmEmailApi } from 'apis/post/postConfirmEmailApi'
import { SuccessMessage } from 'components/message/SuccessMessage/SuccessMessage'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { Spinner } from 'components/widget/Spinner'
import { AUTH_INTERNAL_ERROR } from 'firebaseFunctions/constants/failureCodesConstants'

export const SendConfirmEmailButton = ({
  handleError,
  className,
}: SendConfirmEmailButtonProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onClick = async () => {
    try {
      setShowSpinner(true)
      setSuccessMessage(null)
      setErrorMessage(null)
      const token = await getIdToken()

      if (token) {
        await postConfirmEmailApi({
          values: {},
          apiHeaders: {
            token,
          },
        })

        setShowSpinner(false)
        setSuccessMessage('Email sent!')
      } else {
        setShowSpinner(false)
        handleError({ description: 'SendConfirmEmailButton' })
      }
    } catch (error) {
      setShowSpinner(false)
      const code = error.response?.data?.code
      const message = error.response?.data?.message

      if (code === AUTH_INTERNAL_ERROR && message) {
        setErrorMessage(message)
      } else {
        handleError({ description: 'SendConfirmEmailButton', error })
      }
    }
  }

  return (
    <div
      className={`SendConfirmEmailButton ${SendConfirmEmailButtonStyles} ${className}`}
    >
      <ActionButton
        title="Resend verification email"
        onClick={onClick}
        disabled={showSpinner}
      >
        {showSpinner && <Spinner />}
        {!showSpinner && 'Resend Email'}
      </ActionButton>

      {successMessage && <SuccessMessage>Email sent!</SuccessMessage>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}
