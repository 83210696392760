// import { UpgradeNowLinkStyles } from './UpgradeNowLinkStyles'
import React from 'react'
// import { UpgradeNowLinkProps } from './types/UpgradeNowLinkProps'
import { PRO_PRODUCT_NOUN } from 'constants/english'
import { UPGRADE_WORKSPACE_ROUTE } from 'routes'
import { CustomLinkConnected } from '../CustomLink'
import { textLinkStyles } from 'styles/text/textLinkStyles'

export const UpgradeNowLink = (): JSX.Element => {
  return (
    <CustomLinkConnected
      route={UPGRADE_WORKSPACE_ROUTE}
      withCurrentQuery={true}
      title="Upgrade now"
      className={`UpgradeNowLink ${textLinkStyles}`}
    >
      Upgrade to {PRO_PRODUCT_NOUN}
    </CustomLinkConnected>
  )
}
