import { take, put, select, race, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { DASHBOARD_HOME_ROUTE } from 'routes'
import { auth } from 'sdks'
import { firebaseSdk } from 'sdks'
import { createId } from 'firebaseFunctions/utilities/createId'
import { Race } from 'types/Race'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import {
  SUBMIT_NEW_TEAM,
  SUBMIT_NEW_TEAM_DONE,
  POST_TEAMS_SUCCESS,
  POST_TEAMS_FAILURE,
  POST_TEAMS_CANCEL,
  CLEAR_TEAM_SCOPE,
} from 'actions'
import { createHistoryAction } from 'actionCreators/createHistoryAction'
import { PostTeamsSuccessAction } from 'actionCreators/post/PostTeams/types/PostTeamsSuccessAction'
import { PostTeamsFailureAction } from 'actionCreators/post/PostTeams/types/PostTeamsFailureAction'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { postTeamsSaga } from 'flows/sagas/post/postTeamsSaga'

export const submitNewTeamF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_NEW_TEAM])
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentUser = firebaseSdk.auth().currentUser
    const { displayName } = action.values
    const authCurrentUser = auth.currentUser
    const teamId = createId()

    if (displayName && currentUser && authCurrentUser && currentUserId) {
      const idToken = yield currentUser.getIdToken()

      yield spawn(postTeamsSaga, {
        currentUser,
        values: {
          items: {
            [teamId]: {
              displayName,
            },
          },
        },
        apiHeaders: {
          token: idToken,
        },
      })

      const postResult: Race<
        PostTeamsSuccessAction,
        PostTeamsFailureAction,
        any
      > = yield race({
        success: take(POST_TEAMS_SUCCESS),
        failure: take(POST_TEAMS_FAILURE),
        cancel: take(POST_TEAMS_CANCEL),
      })

      if (postResult.success) {
        yield put({
          type: CLEAR_TEAM_SCOPE,
        })

        yield put(
          createHistoryAction({
            description: 'submitNewTeamF',
            pathname: DASHBOARD_HOME_ROUTE,
            query: {
              teamId,
            },
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitNewTeamF.ts',
        })
      )
    }

    yield put({
      type: SUBMIT_NEW_TEAM_DONE,
    })
  }
}
