// import { Dispatch, Action } from 'redux'

import {
  // MessageActionDropdownConnectedProps,
  MessageActionDropdownMapDispatchReturnType,
} from './types'

export const MessageActionDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: MessageActionDropdownConnectedProps,
): MessageActionDropdownMapDispatchReturnType => {
  return {
  }
}
