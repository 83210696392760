import { StateReducers } from 'state/reducers/types/StateReducers'
import { mockTeams, MOCK_TEAM_A_ID } from './mockTeams'
import { mockUserA, MOCK_USER_A_ID } from './mockUsers'
import { mockTasks, MOCK_TASK_A_ID } from './mockTasks'
import { mockPlans } from './mockPlans'
import { mockInvites } from './mockInvites'
import { mockMessages } from './mockMessages'
import { mockSessionStorageR } from './mockSessionStorageR'
import { RoleTypes } from 'firebaseFunctions/types'

export const mockState: StateReducers = {
  currentUserId: MOCK_USER_A_ID,
  currentUser: mockUserA,
  currentCustomClaim: {
    roles: {
      [MOCK_TEAM_A_ID]: RoleTypes.Owner,
    },
  },
  sessionStorage: mockSessionStorageR,
  teams: mockTeams,
  tasks: mockTasks,
  invites: mockInvites,
  messages: mockMessages,
  plans: mockPlans,
  currentLocation: {
    query: {
      teamId: MOCK_TEAM_A_ID,
      taskId: MOCK_TASK_A_ID,
    },
    isRoutes: {
      isRootRoute: false,
      isPreAndPostLoginRoute: false,
      isLoginRoute: false,
      isCreateUserRoute: true,
      isConfirmEmailRoute: false,
      isServerErrorTestRoute: false,
      isJsErrorTestRoute: false,
      isNetworkErrorTestRoute: false,
      isResetPasswordRoute: false,
      isResetPasswordSentRoute: false,
      isOneLayoutRoute: false,
      isDashboardLayoutRoute: false,
      isNotFoundRoute: false,
      isDeprecatedRoute: false,
      isCreateTaskRoute: false,
      isDashboardHomeRoute: false,
      isPostLoginRoute: false,
      isTaskMainRoute: false,
      isTeamSettingsRoute: false,
      isUpdateTaskStatusesRoute: false,
      isAccountSettingsRoute: false,
      isAdminRoute: false,
      isAddNewMemberRoute: false,
      isInviteDetailRoute: false,
      isMemberDetailRoute: false,
      isTaskMessagesRoute: false,
      isTaskTagsRoute: false,
      isSelectTeamRoute: false,
      isUpdateDisplayNameRoute: false,
      isUpdatePasswordRoute: false,
      isPlanRoute: false,
      isCurrentRoute: false,
      isAddNewLabelRoute: false,
      isTaskStatusesRoute: false,
      isMembersRoute: false,
      isLabelsRoute: false,
      isTaskSizesRoute: false,
      isUpdateTaskSizesRoute: false,
      isActivityNotificationRoute: false,
      isTeamFilesRoute: false,
      isDeleteWorkspaceRoute: false,
      isNotificationSettingsRoute: false,
      isUpgradeWorkspaceRoute: false,
      isBillingRoute: false,
      isWelcomeToProRoute: false,
      isUpdateTeamNameRoute: false,
    },
  },
}
