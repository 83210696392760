import { NewLabelFormStyles } from './NewLabelFormStyles'
import React, { useState, useCallback } from 'react'
import { NewLabelFormProps } from './types/NewLabelFormProps'
import { isInvalidDisplayName } from 'firebaseFunctions/utilities/is/isInvalidDisplayName'
import { TextInputField } from 'components/field/TextInputField/TextInputField'
import { LABEL_NOUN } from 'constants/english'
import { isInvalidIcon } from 'utilities/is/isInvalidIcon'
import { EmojiDropdownConnected } from 'components/dropdown/EmojiDropdown'
import { BaseEmoji } from 'emoji-mart'
import { NoteMessage } from 'components/message/NoteMessage'
import { FormFooter } from 'components/footer/FormFooter'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'

export const NewLabelForm = ({
  isTouch,
  submit,
}: NewLabelFormProps): JSX.Element => {
  const [displayNameState, setDisplayNameState] = useState('')
  const [errorState, setErrorState] = useState<string | null>(null)
  const [iconState, setIconState] = useState('')
  const [iconErrorState, setIconErrorState] = useState<string | null>(null)

  const validateDisplayName = useCallback(() => {
    const error = isInvalidDisplayName(displayNameState)

    setErrorState(error || null)

    return error
  }, [displayNameState])

  const validateIcon = useCallback(() => {
    const error = isInvalidIcon(iconState)

    setIconErrorState(error || null)

    return error
  }, [iconState])

  return (
    <form
      className={`NewLabelForm ${NewLabelFormStyles}`}
      onSubmit={(e) => {
        e.preventDefault()
        const iconError = validateIcon()
        const displayNameError = validateDisplayName()

        if (!iconError && !displayNameError) {
          submit({ displayName: displayNameState, icon: iconState })
        }
      }}
    >
      <HeaderOneTextConnected>New {LABEL_NOUN}</HeaderOneTextConnected>

      <TextInputField
        label={`Name`}
        type="textarea"
        error={errorState}
        onChange={(e) => {
          setDisplayNameState(e.target.value)
        }}
        className={errorState ? 'hasError' : ''}
        onFocus={() => {
          setErrorState(null)
        }}
        onBlur={validateDisplayName}
      />

      <div className={`iconContainer ${marginTopLargeStyles}`}>
        <TextInputField
          label="Choose an emoji"
          type="text"
          name="icon"
          value={iconState}
          error={iconErrorState}
          onChange={(e) => {
            setIconState(e.target.value)
          }}
          className={iconErrorState ? 'hasError' : ''}
          onFocus={() => {
            setIconErrorState(null)
          }}
          onBlur={validateIcon}
        />

        {!isTouch && (
          <div className="emojiContainer">
            <EmojiDropdownConnected
              dropdownId="NewLabelForm"
              closeOnSelect={true}
              onEmojiSelect={(emoji: BaseEmoji) => {
                setIconState(emoji.native)
              }}
            />
          </div>
        )}

        <NoteMessage className={marginTopSmallStyles}>
          Some emojis may not be supported by your device.
        </NoteMessage>
      </div>

      <FormFooter label="Submit" />
    </form>
  )
}
