import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsTouchScreen } from 'state/getters/getIsTouchScreen'
import { TagDropdownConnectedProps } from './types/TagDropdownConnectedProps'
import { TagDropdownMapStateReturnType } from './types/TagDropdownMapStateReturnType'

export const TagDropdownMapState = (
  state: StateReducers,
  props: TagDropdownConnectedProps
): TagDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown

  const showTagDropdown =
    showDropdown &&
    showDropdown.type === 'tag' &&
    showDropdown.id === props.taskId

  const isTouchScreen = getIsTouchScreen({ state })

  return {
    showTagDropdown,
    isTouchScreen,
  }
}
