import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCyclePlanIds } from 'state/getters/getCyclePlanIds'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { SUBMIT_MOVE_TASK_TO_CYCLE, SUBMIT_TASK_PLAN_DROP } from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const submitMoveTaskToCycleF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_MOVE_TASK_TO_CYCLE])
    const { taskId, cycle } = action.values
    const state: StateReducers = yield select(selectState)
    const plans = state.plans || undefined
    const task = taskId ? getCurrentTask({ state, taskId }) : undefined
    const { previousPlanId, currentPlanId, nextPlanId } = getCyclePlanIds({
      state,
    })
    const fromPlanId = task?.planId

    let toPlanId = null

    if (cycle === 'previous') {
      toPlanId = previousPlanId
    }

    if (cycle === 'current') {
      toPlanId = currentPlanId
    }

    if (cycle === 'next') {
      toPlanId = nextPlanId
    }

    const toIndex = 0
    const plan = fromPlanId ? plans?.[fromPlanId] : undefined
    const planTaskIds = plan?.data?.taskIds
    const fromIndex =
      taskId && Array.isArray(planTaskIds)
        ? planTaskIds?.indexOf(taskId)
        : undefined

    yield put(
      createSubmitAction({
        values: {
          fromPlanId,
          toPlanId,
          toIndex,
          taskId,
          fromIndex,
        },
        type: SUBMIT_TASK_PLAN_DROP,
      })
    )

    yield put(
      createToggleAction({
        description: 'submitMoveTaskToCycleF',
        toggles: {
          showDropdown: false,
        },
      })
    )
  }
}
