import { GET_TASKS_SUCCESS } from 'actions'

import { GetTasksSuccessAction } from './types/GetTasksSuccessAction'
import { GetTasksSuccessActionValues } from './types/GetTasksSuccessActionValues'
import { GetTasksRequestActionValues } from './types/GetTasksRequestActionValues'


interface Props {
  successValues: GetTasksSuccessActionValues
  requestValues?: GetTasksRequestActionValues
  description: string
}

export const createGetTasksSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): GetTasksSuccessAction => {
  return {
    type: GET_TASKS_SUCCESS,
    description,
    requestValues,
    successValues,
  }
}
