import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { RoleTypes } from 'firebaseFunctions/types'
import { GET_TEAMS_REQUEST } from 'actions'
import { QuerySnapshot } from '@google-cloud/firestore'
import { Team } from 'firebaseFunctions/types'
import { TeamsReducer } from 'state/reducers/types/TeamsReducer'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { GetTeamsRequestAction } from 'actionCreators/get/GetTeams/types/GetTeamsRequestAction'
import { createGetTeamsSuccessAction } from 'actionCreators/get/GetTeams/createGetTeamsSuccessAction'
import { createGetTeamsFailureAction } from 'actionCreators/get/GetTeams/createGetTeamsFailureAction'
import { getTeamRef } from '../../firebaseFunctions/utilities/ref/get/getTeamRef'
import { getTeamsRef } from '../../firebaseFunctions/utilities/ref/get/getTeamsRef'

export const getTeamsRequestF = function*() {
  while (1) {
    const action: GetTeamsRequestAction = yield take([GET_TEAMS_REQUEST])
    const { requestValues, description } = action
    const { userId, teamId, ignoreErrors } = requestValues

    try {
      const teamMutable: TeamsReducer = {}

      if (teamId) {
        const teamDoc = yield getTeamRef({ firestore, teamId }).get()

        const team = teamDoc.data() as Team | undefined

        if (team) {
          teamMutable[teamDoc.id] = {
            data: team,
          }
        }
      } else {
        const ownerTeams = getTeamsRef({ firestore })
          .where(`userRoles.${userId}.role`, '==', RoleTypes.Owner)
          .get()

        const writerTeams = getTeamsRef({ firestore })
          .where(`userRoles.${userId}.role`, '==', RoleTypes.Writer)
          .get()

        const readerTeams = getTeamsRef({ firestore })
          .where(`userRoles.${userId}.role`, '==', RoleTypes.Reader)
          .get()

        const ownerTeamsSnapshot: QuerySnapshot = yield ownerTeams
        const writerTeamsSnapshot: QuerySnapshot = yield writerTeams
        const readerTeamsSnapshot: QuerySnapshot = yield readerTeams

        // const [
        //   ownerTeamsSnapshot,
        //   writerTeamsSnapshot,
        //   readerTeamsSnapshot,
        // ]: [
        //   QuerySnapshot,
        //   QuerySnapshot,
        //   QuerySnapshot,
        // ] = yield [
        //   ownerTeams,
        //   writerTeams,
        //   readerTeams,
        // ]

        ownerTeamsSnapshot.forEach((teamDoc) => {
          const data = teamDoc.data() as Team

          teamMutable[teamDoc.id] = {
            data,
          }
        })

        writerTeamsSnapshot.forEach((teamDoc) => {
          const data = teamDoc.data() as Team

          teamMutable[teamDoc.id] = {
            data,
          }
        })

        readerTeamsSnapshot.forEach((teamDoc) => {
          const data = teamDoc.data() as Team

          teamMutable[teamDoc.id] = {
            data,
          }
        })
      }

      yield put(
        createGetTeamsSuccessAction({
          description: 'getTeamsRequestF',
          requestValues,
          successValues: {
            items: teamMutable,
          },
        })
      )
    } catch (error) {
      if (!ignoreErrors) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: `${description} | getTeamsRequestF`,
          })
        )
      }

      yield put(
        createGetTeamsFailureAction({
          requestValues,
          description: `${description} | getTeamsRequestF`,
        })
      )
    }
  }
}
