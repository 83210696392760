import { css } from 'emotion'
import { NEUTRAL_BORDER_COLOR } from 'constants/styleConstants'

export const TaskSizeListStyles = css`
  > li {
    padding: 16px 0 16px 0;
    border-top: 1px solid ${NEUTRAL_BORDER_COLOR};

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`
