interface Return <T>{
  start: T[]
  end: T[]
}

interface Props <T> {
  items: T[]
  index: number
  length?: number
}

export const arraySplit = <T>({
  items,
  index,
  length = 0,
}: Props<T>): Return<T> => {
  const start = items.slice(0, index)
  const end = items.slice(index + length)

  return {
    start,
    end,
  }
}
