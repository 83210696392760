import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import {
  SUBMIT_WORKING_ON_TASK_MESSAGE,
  SUBMIT_WORKING_ON_TASK_MESSAGE_DONE,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'

import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { clientServerTimestamp } from 'sdks'

export const submitWorkingOnTaskMessageF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_WORKING_ON_TASK_MESSAGE])
    const working = action.values.working
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const query = getCurrentQuery({ state })
    const taskId = query && query.taskId
    const teamId = getCurrentTeamId({ state })
    const task = getCurrentTask({ state })
    const existingWorkingOnMessage = task?.workingOnMessage || {}

    if (taskId && teamId && currentUserId) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            workingOnMessage: {
              ...existingWorkingOnMessage,
              [currentUserId]: working ? clientServerTimestamp() : null,
            },
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items: items,
        lastModifiedUserId: 'skip',
        lastModifiedDisplayName: 'skip',
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskMessageF',
        })
      )
    }

    yield put({
      type: SUBMIT_WORKING_ON_TASK_MESSAGE_DONE,
    })
  }
}
