import { createSetReachMaxTaskPageAction } from 'actionCreators/createSetReachMaxTaskPageAction'
import { Dispatch, Action } from 'redux'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { TeamTasksSingleWatcherMapDispatchReturnType } from './types/TeamTasksSingleWatcherMapDispatchReturnType'
import { handleTaskQuerySnapshot } from 'utilities/handleTaskQuerySnapshot'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createClearTeamWatchedTasksAction } from 'actionCreators/createClearTeamWatchedTasksAction'
import { createToggleAction } from 'actionCreators/createToggleAction'

export const TeamTasksSingleWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): TeamTasksSingleWatcherMapDispatchReturnType => {
  return {
    setShowGetTasksSpinner: (show) => {
      dispatch(
        createToggleAction({
          description: 'TeamTasksWatcherMapDispatch',
          toggles: {
            showGetTasksSpinner: show,
          },
        })
      )
    },

    incrementWatcher: (increment) => {
      dispatch(
        createIncrementWatcherAction({
          increment,
          watcherType: 'teamTask',
          description: 'TeamTasksSingleWatcherMapDispatch',
        })
      )
    },

    setReachMaxTaskPage: (reachedMax, teamId) => {
      dispatch(
        createSetReachMaxTaskPageAction({
          teamId,
          reachedMax,
          description: 'TeamTasksSingleWatcherMapDispatch',
        })
      )
    },

    clearTeamWatchedTasks: () => {
      dispatch(
        createClearTeamWatchedTasksAction({
          description: 'TeamTasksSingleWatcherMapDispatch',
        })
      )
    },

    onNext: (querySnapshot, teamId) => {
      handleTaskQuerySnapshot({
        querySnapshot,
        teamId: teamId,
        dispatch,
        description: 'TeamTasksSingleWatcherMapDispatch',
      })
    },

    onFirebaseError: (error) => {
      dispatch(
        createFirebaseErrorAction({
          error,
          description: 'TeamTasksSingleWatcherMapDispatch',
        })
      )
    },
  }
}
