import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_STATUS_COLOR } from 'actions'
import { StatusColorDropdownMapDispatchReturnType } from './types/StatusColorDropdownMapDispatchReturnType'

export const StatusColorDropdownMapDispatch = (
  dispatch: Dispatch<Action>
): StatusColorDropdownMapDispatchReturnType => {
  return {
    onColorClick: ({ color, status }) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_STATUS_COLOR,
          values: {
            color,
            status,
          },
        })
      )
    },
  }
}
