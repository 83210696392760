import { FirestoreClient } from '../../../types'
import { getUploadedFilesRef } from './getUploadedFilesRef'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'
import { UploadedFile } from '../../../types'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
  uploadedFileId: string
}

export const getUploadedFileRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
  uploadedFileId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<UploadedFile>
  : FirebaseFirestore.DocumentReference => {
  return getUploadedFilesRef({
    firestore,
    teamId,
  }).doc(uploadedFileId) as any
}
