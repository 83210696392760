import { POST_INVITES_SUCCESS } from 'actions'

import { PostInvitesSuccessAction } from './types/PostInvitesSuccessAction'
import { PostInvitesSuccessActionValues } from './types/PostInvitesSuccessActionValues'
import { PostInvitesRequestActionValues } from './types/PostInvitesRequestActionValues'


interface Props {
  successValues: PostInvitesSuccessActionValues
  requestValues?: PostInvitesRequestActionValues
}

export const createPostInvitesSuccessAction = ({
  successValues,
  requestValues,
}: Props): PostInvitesSuccessAction => {
  return {
    type: POST_INVITES_SUCCESS,
    requestValues,
    successValues,
  }
}
