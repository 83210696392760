import { SubmitEditorActionValues } from './types/SubmitEditorActionValues'
import { SubmitEditorAction } from 'actionCreators/submit/SubmitEditor'

interface Props {
  values: SubmitEditorActionValues
  type: string
}

export const createSubmitEditorAction = ({
  values,
  type,
}: Props): SubmitEditorAction => {
  return {
    type,
    values,
  }
}
