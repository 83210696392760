import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const LabelDropdownStyles = css`
  position: relative;
  .DropdownButton {
    padding: 0 8px;
  }

  .Dropdown {
    .chevronContainer {
      right: 4px;
      top: 22px;
    }

    .container {
      width: 230px;
      right: 0px;
      top: 29px;
      padding: 8px;

      > * {
        display: inline-block;
        margin: 4px;
      }
    }
  }
`
