// import { Dispatch, Action } from 'redux'

import {
  // ActionButtonConnectedProps,
  ActionButtonMapDispatchReturnType,
} from './types'

export const ActionButtonMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: ActionButtonConnectedProps,
): ActionButtonMapDispatchReturnType => {
  return {
  }
}
