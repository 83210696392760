import { css } from 'emotion'
import { TEXT_COLOR } from 'constants/styleConstants'

export const MemberListStyles = css`
  a {
    color: ${TEXT_COLOR};
    display: block;
  }

  > li {
    margin-top: 8px;
  }
`
