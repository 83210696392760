import { css } from 'emotion'
import { PRIMARY_COLOR_DARK } from '../../../constants/styleConstants'

export const LogoWidgetStyles = css`
  font-size: 1.25em;
  letter-spacing: 1px;
  color: ${PRIMARY_COLOR_DARK};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);

  .CatIcon {
    height: 30px;
  }
`
