import * as React from 'react'
import { SelectTagFieldConnected } from './SelectTagFieldConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore, MOCK_TASK_A_ID } from 'mockState'

export const SelectTagFieldExamples = () => {
  return (
    <div className="SelectTagFieldExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <SelectTagFieldConnected taskId={MOCK_TASK_A_ID} />
      </Provider>
    </div>
  )
}
