import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // ModalConnectProps,
  ModalMapStateReturnType,
} from './types'

export const ModalMapState = (
  state: StateReducers,
  // props: ModalConnectProps,
): ModalMapStateReturnType => {
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    isMediumScreen,
  }
}
