import { Dispatch, Action } from 'redux'
import { TeamStat } from 'firebaseFunctions/types'
import firebase, { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { TeamStatWatcherConnectedProps } from './types/TeamStatWatcherConnectedProps'
import { TeamStatWatcherMapDispatchReturnType } from './types/TeamStatWatcherMapDispatchReturnType'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'
import { createGetTeamStatSuccessAction } from 'actionCreators/get/GetTeamStat/createGetTeamStatSuccessAction'
import { createGetTeamStatFailureAction } from 'actionCreators/get/GetTeamStat/createGetTeamStatFailureAction'

export const TeamStatWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: TeamStatWatcherConnectedProps
): TeamStatWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'TeamStatWatcherMapDispatch',
          increment,
          watcherType: 'teamStat',
        })
      )
    },

    onNext: (docSnapshot: firebase.firestore.DocumentSnapshot) => {
      const stat: TeamStat | undefined = docSnapshot.data()

      if (stat) {
        dispatch(
          createGetTeamStatSuccessAction({
            requestValues: {
              teamId: props.teamId,
            },
            successValues: {
              item: stat,
            },
          })
        )
      }
    },

    onFirebaseError: (error: Error) => {
      dispatch(
        createFirebaseErrorAction({
          error: error as FirebaseError,
          description: 'TeamStatWatcherMapDispatch',
        })
      )

      dispatch(createGetTeamStatFailureAction({}))
    },
  }
}
