import { MinimalLayoutStyles } from './MinimalLayoutStyles'
import React from 'react'
import { MinimalLayoutProps } from './types/MinimalLayoutProps'
import { TeamListConnected } from 'components/list/TeamList'
import { LogoWidget } from 'components/widget/LogoWidget'
import { TEAM_NOUN } from 'constants/english'
import { ConfirmEmailMainConnected } from 'components/main/ConfirmEmailMain'
import { CONFIRM_EMAIL_ROUTE } from 'routes'
import { UserInvitesMainConnected } from 'components/main/UserInvitesMain'
import { NewTeamFormConnected } from 'components/form/NewTeamForm/NewTeamFormConnected'
import { ContentLoaderWidget } from '../../widget/ContentLoaderWidget/ContentLoaderWidget'
import { marginTopLargeStyles } from '../../../styles/margin/marginTopLargeStyles'
import { HeaderOneText } from '../../text/HeaderOneText/HeaderOneText'
import { UserAccountHeaderConnected } from '../../header/UserAccountHeader'

export const MinimalLayout = ({
  showSelectTeam,
  showConfirmEmail,
  isSelectTeamRoute,
  showLoader,
}: MinimalLayoutProps): JSX.Element => {
  return (
    <div className={'MinimalLayout ' + MinimalLayoutStyles}>
      <header>
        <LogoWidget
          showName={true}
          route={showConfirmEmail ? CONFIRM_EMAIL_ROUTE : undefined}
        />

        <UserAccountHeaderConnected />
      </header>

      <main>
        {isSelectTeamRoute && (
          <>
            <UserInvitesMainConnected />

            {showSelectTeam && (
              <HeaderOneText className={marginTopLargeStyles}>
                Select {TEAM_NOUN}
              </HeaderOneText>
            )}

            {showLoader && (
              <ContentLoaderWidget className={marginTopLargeStyles} />
            )}

            {showSelectTeam && <TeamListConnected />}

            <HeaderOneText className={marginTopLargeStyles}>
              Create new {TEAM_NOUN}
            </HeaderOneText>

            <NewTeamFormConnected />
          </>
        )}

        {showConfirmEmail && <ConfirmEmailMainConnected />}
      </main>
    </div>
  )
}
