import {
  CLIENT_DASHBOARD_HOME_ROUTE,
  CLIENT_TASK_MESSAGES_ROUTE,
  CLIENT_TASK_DETAIL_ROUTE,
  CLIENT_SELECT_TEAM_ROUTE,
} from 'firebaseFunctions/constants/clientConstants'

export const ACCOUNT_SETTINGS_ROUTE = '/account-settings'
export const ACTIVITY_NOTIFICATION_ROUTE = '/activity-notification'
export const ADD_NEW_LABEL_ROUTE = '/new-label'
export const ADD_NEW_MEMBER_ROUTE = '/new-member'
export const ADD_NEW_STATUS_ROUTE = '/new-status'
export const ADMIN_ROUTE = '/admin'
export const BILLING_ROUTE = '/billing'
export const CREATE_TASK_ROUTE = '/create-task'
export const CURRENT_ROUTE = '/current'
export const DASHBOARD_HOME_ROUTE = CLIENT_DASHBOARD_HOME_ROUTE
export const DELETE_WORKSPACE_ROUTE = '/delete-workspace'
export const INVITE_DETAIL_ROUTE = '/invite-detail'
export const LABELS_ROUTE = '/labels'
export const MEMBERS_ROUTE = '/members'
export const UPDATE_WORKSPACE_LOGO_ROUTE = '/update-workspace-logo'
export const MEMBER_DETAIL_ROUTE = '/member-detail'
export const NOTIFICATION_SETTINGS_ROUTE = '/notification-settings'
export const PLAN_ROUTE = '/plan'
export const PRO_CANCELED_ROUTE = '/pro-canceled'
export const REPORT_ROUTE = '/report'
export const SELECT_TEAM_ROUTE = CLIENT_SELECT_TEAM_ROUTE
export const TASK_DETAIL_ROUTE = CLIENT_TASK_DETAIL_ROUTE
export const TASK_MESSAGES_ROUTE = CLIENT_TASK_MESSAGES_ROUTE
export const TASK_SIZES_ROUTE = '/task-sizes'
export const TASK_STATUSES_ROUTE = '/task-statuses'
export const TASK_TAGS_ROUTE = '/task-tags'
export const TEAM_FILES_ROUTE = '/team-files' // TODO: change urls to use workspace instead of team
export const TEAM_SETTINGS_ROUTE = '/team-settings'
export const UPDATE_AVATAR_ROUTE = '/update-avatar'
export const UPDATE_DISPLAY_NAME_ROUTE = '/update-display-name'
export const UPDATE_PASSWORD_ROUTE = '/update-password'
export const UPDATE_TASK_SIZES_ROUTE = '/configure-task-sizes'
export const UPDATE_TASK_STATUSES_ROUTE = '/configure-task-statuses'
export const UPDATE_TEAM_NAME_ROUTE = '/update-workspace-name'
export const UPGRADE_WORKSPACE_ROUTE = '/upgrade-workspace'
export const WELCOME_TO_PRO_ROUTE = '/welcome-to-pro'
export const PAYMENT_FAILED_ROUTE = '/payment-failed'
export const WORKSPACE_SUBSCRIPTION_ROUTE = '/workspace-subscription'

export const postLoginRoutes: string[] = [
  ACCOUNT_SETTINGS_ROUTE,
  ADMIN_ROUTE,
  CREATE_TASK_ROUTE,
  DASHBOARD_HOME_ROUTE,
  INVITE_DETAIL_ROUTE,
  LABELS_ROUTE,
  MEMBER_DETAIL_ROUTE,
  MEMBERS_ROUTE,
  ADD_NEW_LABEL_ROUTE,
  ADD_NEW_MEMBER_ROUTE,
  SELECT_TEAM_ROUTE,
  TASK_DETAIL_ROUTE,
  TASK_MESSAGES_ROUTE,
  TASK_SIZES_ROUTE,
  TASK_STATUSES_ROUTE,
  TASK_TAGS_ROUTE,
  TEAM_FILES_ROUTE,
  DELETE_WORKSPACE_ROUTE,
  TEAM_SETTINGS_ROUTE,
  UPDATE_DISPLAY_NAME_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  UPDATE_TASK_SIZES_ROUTE,
  UPDATE_TASK_STATUSES_ROUTE,
  NOTIFICATION_SETTINGS_ROUTE,
  UPGRADE_WORKSPACE_ROUTE,
  BILLING_ROUTE,
  WELCOME_TO_PRO_ROUTE,
  WORKSPACE_SUBSCRIPTION_ROUTE,
  PRO_CANCELED_ROUTE,
  REPORT_ROUTE,
]
