// import { Dispatch, Action } from 'redux'

import {
  // UploadWatcherListConnectedProps,
  UploadWatcherListMapDispatchReturnType,
} from './types'

export const UploadWatcherListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: UploadWatcherListConnectedProps,
): UploadWatcherListMapDispatchReturnType => {
  return {
  }
}
