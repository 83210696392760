import { Dispatch, Action } from 'redux'
import { FirebaseError } from 'firebase'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { PlanTasksWatcherConnectedProps } from './types/PlanTasksWatcherConnectedProps'
import { PlanTasksWatcherMapDispatchReturnType } from './types/PlanTasksWatcherMapDispatchReturnType'
import { handleTaskQuerySnapshot } from 'utilities/handleTaskQuerySnapshot'
import { createIncrementWatcherAction } from 'actionCreators/createIncrementWatcherAction'

export const PlanTasksWatcherMapDispatch = (
  dispatch: Dispatch<Action>,
  props: PlanTasksWatcherConnectedProps
): PlanTasksWatcherMapDispatchReturnType => {
  return {
    incrementWatcher: (increment: number) => {
      dispatch(
        createIncrementWatcherAction({
          description: 'PlanTasksWatcherMapDispatch',
          increment,
          watcherType: 'planTask',
        })
      )
    },

    onNext: (querySnapshot) => {
      handleTaskQuerySnapshot({
        querySnapshot,
        dispatch,
        teamId: props.teamId,
        description: 'PlanTasksWatcherMapDispatch',
      })
    },

    onFirebaseError: (error: FirebaseError) => {
      const firestoreErrorAction = createFirebaseErrorAction({
        error,
        description: 'PlanTasksWatcherMapDispatch',
      })

      dispatch(firestoreErrorAction)
    },
  }
}
