import { Dispatch, Action } from 'redux'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { SUBMIT_TASK_DUE_DATE } from 'actions'

import { DueDateDropdownConnectedProps } from './types/DueDateDropdownConnectedProps'
import { DueDateDropdownMapDispatchReturnType } from './types/DueDateDropdownMapDispatchReturnType'

import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const DueDateDropdownMapDispatch = (
  dispatch: Dispatch<Action>,
  props: DueDateDropdownConnectedProps
): DueDateDropdownMapDispatchReturnType => {
  return {
    unsetDueDate: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_TASK_DUE_DATE,
          values: {
            taskId: props.taskId,
            date: null,
          },
        })
      )
    },

    setDueDate: (date: Date | null) => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_TASK_DUE_DATE,
          values: {
            taskId: props.taskId,
            date,
          },
        })
      )

      dispatch(
        createToggleAction({
          description: 'DueDateDropdownMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },
  }
}
