import { StateReducers } from 'state/reducers/types/StateReducers'

import { StripeSubscriptionMainConnectedProps } from './types/StripeSubscriptionMainConnectedProps'
import { StripeSubscriptionMainMapStateReturnType } from './types/StripeSubscriptionMainMapStateReturnType'

export const StripeSubscriptionMainMapState = (
  state: StateReducers,
  _props: StripeSubscriptionMainConnectedProps
): StripeSubscriptionMainMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {
    canGetToken: !!state.currentUserId,
  }
}
