import { connect } from 'react-redux'
import { DashboardNav } from './DashboardNav'
import { DashboardNavMapState } from './DashboardNavMapState'
import { DashboardNavMapDispatch } from './DashboardNavMapDispatch'

import { DashboardNavMapStateReturnType } from './types/DashboardNavMapStateReturnType'
import { DashboardNavMapDispatchReturnType } from './types/DashboardNavMapDispatchReturnType'
import { DashboardNavConnectedProps } from './types/DashboardNavConnectedProps'

export const DashboardNavConnected = connect<
  DashboardNavMapStateReturnType,
  DashboardNavMapDispatchReturnType,
  DashboardNavConnectedProps
>(
  DashboardNavMapState,
  DashboardNavMapDispatch
)(DashboardNav)
