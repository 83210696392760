import { SignInSuccessAction } from './types/SignInSuccessAction'
import { SignInSuccessActionValues } from './types/SignInSuccessActionValues'
import { SIGN_IN_SUCCESS } from 'actions'

export const createSignInSuccessAction = (values: SignInSuccessActionValues): SignInSuccessAction => {
  return {
    type: SIGN_IN_SUCCESS,
    values,
  }
}
