import { StateReducers } from '../reducers/types/StateReducers'
import { getSortedByCreated } from 'utilities/getSortedByCreated'
import { PlanStatus } from 'firebaseFunctions/types'

interface Props {
  state: StateReducers
  status: PlanStatus
}

export const getSortedPlanIds = ({
  state,
  status = 'open',
}: Props): string[] => {
  const plans = state.plans || undefined

  if (!plans) {
    return []
  }

  const { ids: sortedPlanIds } = getSortedByCreated({ itemsObject: plans })

  const filteredPlanIds = sortedPlanIds.filter((planId) => {
    return plans?.[planId]?.data?.status === status
  })

  return filteredPlanIds
}
