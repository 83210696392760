import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const TaskPlanDropdownStyles = css`
  position: relative;

  .Dropdown {
    .chevronContainer {
      left: 10px;
      top: 22px;
    }

    .container {
      top: 29px;
      left: 0px;
      width: 268px;
    }
  }
`
