import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const TaskSummaryFieldStyles = css`
  .TextAreaInput {
    border-radius: 0px;
    font-weight: 400;
    font-size: 1.2em;
  }
`
