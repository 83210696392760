import { mockUserRoles } from '../mockUserRoles'
import { mockLabels } from '../mockLabels'
import { TeamReducer } from 'state/reducers/types/TeamReducer'
import { MOCK_USER_A_ID } from '../mockUsers'

export const mockTeamA: TeamReducer = {
  stats: {
    subscription: {
      userId: MOCK_USER_A_ID,
      currentPeriodEndSeconds: 1000000000,
      stripeSubscriptionId: 'string',
      stripeSubscriptionItemId: 'string',
    },
  },
  data: {
    displayName: 'TeamA',
    userRoles: mockUserRoles,
    labels: mockLabels,
    taskStatuses: ['Open', 'In Progress', 'Closed'],
  },
}
