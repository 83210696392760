import { TextInputStyles } from './TextInputStyles'
import * as React from 'react'
import { TextInputProps } from './types/TextInputProps'

const NOT_NUMBER_REGEX = /\D/g

export const TextInput = (props: TextInputProps): JSX.Element => {
  const additionalClassName = props.className ? ` ${props.className}` : ''
  const isNumbersOnly = props.type === 'NumbersOnly'

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): boolean | void => {
    if (props.onChange) {
      if (isNumbersOnly) {
        const { value } = event.target
        const numbersOnlyValue = value
          .replace(NOT_NUMBER_REGEX, '')
          .substr(0, props.softMaxLength || props.maxLength)

        if (numbersOnlyValue === props.value) {
          return false
        } else {
          event.target.value = numbersOnlyValue
        }
      }

      props.onChange(event)
    }
  }

  return (
    <input
      {...props}
      type={isNumbersOnly ? 'text' : props.type}
      maxLength={256}
      className={'TextInput ' + TextInputStyles + additionalClassName}
      onChange={isNumbersOnly ? onChange : props.onChange}
    />
  )
}
