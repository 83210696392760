import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createPatchUnscopeUserRequestAction } from 'actionCreators/patch/PatchUnscopeUser/createPatchUnscopeUserRequestAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import {
  SUBMIT_UPDATE_EMAIL_SETTINGS,
  SUBMIT_UPDATE_EMAIL_SETTINGS_DONE,
} from 'actions'

export const submitUpdateEmailSettingsF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_UPDATE_EMAIL_SETTINGS])
    const { emailSettings } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentEmailSettings = state.currentUnscopeUser?.emailSettings
    const newEmailSettings = {
      ...currentEmailSettings,
      ...emailSettings,
    }

    if (currentUserId) {
      yield put(
        createPatchUnscopeUserRequestAction({
          requestValues: {
            item: {
              emailSettings: newEmailSettings,
            },
            params: {
              userId: currentUserId,
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitUpdateRoleF',
        })
      )
    }

    yield put({
      type: SUBMIT_UPDATE_EMAIL_SETTINGS_DONE,
    })
  }
}
