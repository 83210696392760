import { TaskStatusListStyles } from './TaskStatusListStyles'
import * as React from 'react'
import { TaskStatusListProps } from './types/TaskStatusListProps'
import { StatusColorDropdownConnected } from 'components/dropdown/StatusColorDropdown'
import { flexSpaceBetweenStyles } from 'styles/flex/flexSpaceBetweenStyles'

export const TaskStatusList = ({
  taskStatuses,
}: TaskStatusListProps): JSX.Element => {
  return (
    <ol className={'TaskStatusList ' + TaskStatusListStyles}>
      {taskStatuses &&
        taskStatuses.map((status, i) => {
          return (
            <li key={i} className={flexSpaceBetweenStyles}>
              <p>
                {i + 1}. {status}
              </p>

              <StatusColorDropdownConnected status={status} />
            </li>
          )
        })}
    </ol>
  )
}
