import { StateReducers } from 'state/reducers/types/StateReducers'
import { PaymentFailedMainConnectedProps } from './types/PaymentFailedMainConnectedProps'
import { PaymentFailedMainMapStateReturnType } from './types/PaymentFailedMainMapStateReturnType'
import { getCanOwn } from '../../../state/getters/getCanOwn'
import { getCurrentTeamReducer } from '../../../state/getters/getCurrentTeamReducer'
import { getCurrentTeamId } from '../../../state/getters/getCurrentTeamId'

export const PaymentFailedMainMapState = (
  state: StateReducers,
  _props: PaymentFailedMainConnectedProps
): PaymentFailedMainMapStateReturnType => {
  const canOwn = getCanOwn({ state })
  const teamReducer = getCurrentTeamReducer({ state })
  const subscriptionCancelled = !teamReducer?.stats?.subscription
  const teamId = getCurrentTeamId({ state })

  return {
    canOwn,
    teamId,
    subscriptionCancelled,
  }
}
