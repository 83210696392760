import { Dispatch, Action } from 'redux'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
import { SUBMIT_DELETE_TASK_FILE_NAME } from 'actions'

import { TaskFileCardConnectedProps } from './types/TaskFileCardConnectedProps'
import { TaskFileCardMapDispatchReturnType } from './types/TaskFileCardMapDispatchReturnType'


export const TaskFileCardMapDispatch = (
  dispatch: Dispatch<Action>,
  props: TaskFileCardConnectedProps
): TaskFileCardMapDispatchReturnType => {
  return {
    deleteFileName: () => {
      const { fileName } = props

      if (fileName !== undefined) {
        dispatch(
          createSubmitAction({
            values: {
              fileName,
            },
            type: SUBMIT_DELETE_TASK_FILE_NAME,
          })
        )
      }
    },
  }
}
