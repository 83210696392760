// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // ReadAllButtonConnectedProps,
  ReadAllButtonMapStateReturnType,
} from './types'

export const ReadAllButtonMapState = (): // state: StateReducers,
// props: ReadAllButtonConnectedProps,
ReadAllButtonMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
