export const getCanBeThumb = (fileName?: string): boolean => {
  if (!fileName) {
    return false
  }

  const fileNameParts = fileName.toLowerCase().split('.') || []
  const fileExtension = fileNameParts.pop()

  return fileExtension === 'jpg' ||
    fileExtension === 'jpeg' ||
    fileExtension === 'png' ||
    fileExtension === 'tiff' ||
    fileExtension === 'webp'
}