import { NotificationCardStyles } from './NotificationCardStyles'
import React from 'react'
import { NotificationCardProps } from './types/NotificationCardProps'
import { AvatarConnected } from 'components/widget/Avatar'
import { TaskSummarySpanConnected } from 'components/span/TaskSummarySpan'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { CountBubbleWidget } from 'components/widget/CountBubbleWidget'
import { getIsToday } from 'utilities/get/getIsToday'
import { TASK_NOUN } from 'constants/english'
import moment from 'moment'

export const NotificationCard = ({
  userId,
  created = 0,
  teamId,
  taskId,
  type,
  unreadCount = 0,
  status,
  clearOnClick,
  clear,
  tagDisplayName,
  route,
  taskSummary,
  messageSummary,
  userDisplayName,
}: NotificationCardProps): JSX.Element => {
  const dateMoment = moment(created)
  const isToday = getIsToday(created)
  const createdString = isToday ? 'today' : dateMoment.format('MMM D')
  const unreadClassName = unreadCount > 0 ? ' unread' : ''
  const showCountBubble =
    unreadCount > 0 && (type === 'mention' || type === 'message')

  return (
    <CustomLinkConnected
      route={route}
      title="notification"
      teamId={teamId}
      taskId={taskId}
      onClick={clearOnClick ? clear : undefined}
      className={'NotificationCard ' + NotificationCardStyles + unreadClassName}
    >
      <div className="createdAndAvatar">
        {showCountBubble && (
          <CountBubbleWidget>{unreadCount}</CountBubbleWidget>
        )}

        <AvatarConnected userId={userId} />

        <div className="created">{createdString}</div>
      </div>

      <main>
        <p className="displayNameContainer">
          <span className="userDisplayName">{userDisplayName}</span>:{' '}
          {type === 'mention' && <span> mentioned you</span>}
          {type === 'message' && (
            <span className="messageSummary">{messageSummary}</span>
          )}
          {type === 'userTag' && (
            <span>
              {' '}
              tagged <strong>you</strong>
            </span>
          )}
          {type === 'labelTag' && (
            <span>
              {' '}
              tagged <strong>{tagDisplayName}</strong>
            </span>
          )}
          {type === 'status' && (
            <span>
              {' '}
              set status to <strong>{status}</strong>
            </span>
          )}
          {type === 'closePlan' && (
            <span>
              {' '}
              <strong>closed</strong> the <strong>current cycle</strong>.
            </span>
          )}
          {type === 'nextPlan' && (
            <span>
              {' '}
              moved {TASK_NOUN} to the <strong>next cycle</strong>
            </span>
          )}
          {type === 'currentPlan' && (
            <span>
              {' '}
              moved {TASK_NOUN} to the <strong>current cycle</strong>
            </span>
          )}
        </p>

        {taskSummary && taskId && (
          <p className="summary">
            <TaskSummarySpanConnected taskId={taskId}>
              {taskSummary}
            </TaskSummarySpanConnected>
          </p>
        )}

        {type === 'closePlan' && <p>A new cycle has started.</p>}
      </main>
    </CustomLinkConnected>
  )
}
