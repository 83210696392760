// import { Dispatch, Action } from 'redux'

import {
  // TaskDescriptionMainConnectedProps,
  TaskDescriptionMainMapDispatchReturnType,
} from './types'

export const TaskDescriptionMainMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskDescriptionMainConnectedProps,
): TaskDescriptionMainMapDispatchReturnType => {
  return {
  }
}
