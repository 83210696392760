import { PATCH_USERS_FAILURE } from 'actions'

import { PatchUsersFailureAction } from './types/PatchUsersFailureAction'
import { PatchUsersRequestActionValues } from './types/PatchUsersRequestActionValues'


interface Props {
  requestValues: PatchUsersRequestActionValues
}

export const createPatchUsersFailureAction = ({
  requestValues,
}: Props): PatchUsersFailureAction => {
  return {
    type: PATCH_USERS_FAILURE,
    requestValues,
  }
}
