import { PATCH_CUSTOM_CLAIM_SUCCESS } from 'actions'
import { PatchCustomClaimSuccessAction } from './types/PatchCustomClaimSuccessAction'
import { PatchCustomClaimSuccessActionValues } from './types/PatchCustomClaimSuccessActionValues'
import { PatchCustomClaimRequestActionValues } from './types/PatchCustomClaimRequestActionValues'

interface Props {
  successValues: PatchCustomClaimSuccessActionValues
  requestValues: PatchCustomClaimRequestActionValues
  description: string
}

export const createPatchCustomClaimSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): PatchCustomClaimSuccessAction => {
  return {
    type: PATCH_CUSTOM_CLAIM_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
