import React, { useState, useCallback } from 'react'
import { MentionSuggestionListProps } from './types/MentionSuggestionListProps'
import { defaultSuggestionsFilter } from 'components/editor/DraftEditor/mention/defaultSuggestionsFilter'
import { MentionItem } from 'components/editor/DraftEditor/mention/createMentionPlugin'

export const MentionSuggestionList = ({
  MentionSuggestionComponent,
  mentionIds = [],
  mentionNames = [],
  mentionAvatars = [],
}: MentionSuggestionListProps): JSX.Element => {
  const [suggestions, setSuggestions] = useState<MentionItem[] | null>(null)
  const [open, setOpen] = useState(false)

  const mentions = mentionIds.map((mentionId, index) => {
    return {
      id: mentionId,
      name: mentionNames[index],
      avatar: mentionAvatars[index],
    }
  })

  const onSearchChange = useCallback(
    ({ value }: any) => {
      const filteredMentionItem = defaultSuggestionsFilter(value, mentions)

      setSuggestions(filteredMentionItem)
    },
    [mentions]
  )

  return (
    <MentionSuggestionComponent
      open={open}
      onSearchChange={onSearchChange}
      suggestions={suggestions || []}
      onOpenChange={setOpen}
    />
  )
}
