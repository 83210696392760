import { SetReachMaxTaskPageAction } from './types/SetReachMaxTaskPageAction'

interface Props {
  teamId: string
  reachedMax?: boolean
  description: string
}

export const createSetReachMaxTaskPageAction = ({
  teamId,
  reachedMax,
  description,
}: Props): SetReachMaxTaskPageAction => {
  return {
    type: 'SET_REACH_MAX_TASK_PAGE',
    reachedMax,
    teamId,
    description,
  }
}
