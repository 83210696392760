import { StateReducers } from 'state/reducers/types/StateReducers'
import { NotificationGroup } from 'types/NotificationGroup'
import { getNotifications } from './getNotifications'

interface Props {
  state: StateReducers
  notificationGroup: NotificationGroup
}

export const getUnreadCount = ({ state, notificationGroup }: Props): number => {
  const notifications = getNotifications({ state, notificationGroup })

  let unreadCount = 0

  notifications.forEach((notification) => {
    const notificationUnreadCount = notification.unreadCount || 0

    if (notificationUnreadCount > 0) {
      unreadCount = unreadCount + 1
    }
  })

  return unreadCount
}
