import { UPLOAD_SUCCESS } from 'actions'

import { UploadSuccessAction } from './types/UploadSuccessAction'
import { UploadSuccessActionValues } from './types/UploadSuccessActionValues'
import { UploadRequestActionValues } from './types/UploadRequestActionValues'


interface Props {
  successValues: UploadSuccessActionValues
  requestValues?: UploadRequestActionValues
}

export const createUploadSuccessAction = ({
  successValues,
  requestValues,
}: Props): UploadSuccessAction => {
  return {
    type: UPLOAD_SUCCESS,
    requestValues,
    successValues,
  }
}
