import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK  } from 'constants/styleConstants'

export const UserWorkingWidgetStyles = css`
  display: flex;
  min-width: 60px;

  .DotDotDotWidget {
    margin: 0 0 0 8px;
    font-size: 1.4em;
    color: #fff;
  }
`
