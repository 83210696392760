import { TaskSizeFieldStyles } from './TaskSizeFieldStyles'
import React, { useCallback } from 'react'
import { TaskSizeFieldProps } from './types/TaskSizeFieldProps'
import { ActionButton } from 'components/button/ActionButton'
import { TimesSolidIcon } from 'components/icon/TimesSolidIcon/TimesSolidIcon'
import { listActionButtonsStyles } from '../../../styles/list/listActionButtonsStyles'
import { listHorizontalScrollStyles } from '../../../styles/list/listHorizontalScrollStyles'
import { marginSmallStyles } from '../../../styles/margin/marginSmallStyles'

export const TaskSizeField = ({
  size,
  taskSizes = [],
  className = '',
  onUnsetClick,
  onSizeClick,
  onClick,
}: TaskSizeFieldProps): JSX.Element => {
  const listStyles =
    taskSizes.length > 10
      ? `${listHorizontalScrollStyles} ${marginSmallStyles}`
      : listActionButtonsStyles

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLUListElement>) => {
      const target = e.target as HTMLButtonElement
      const isRemove = target.classList.contains('remove')

      if (onUnsetClick && isRemove) {
        onUnsetClick()
      }

      if (onSizeClick && target.value) {
        onSizeClick(target.value)
      }

      if (onClick) {
        onClick(e)
      }
    },
    [onUnsetClick, onSizeClick, onClick]
  )

  return (
    <ul
      className={`TaskSizeField ${TaskSizeFieldStyles} ${className} ${listStyles}`}
      onClick={handleOnClick}
    >
      {size && (
        <li key="delete">
          <ActionButton className="small remove" title="Remove">
            <TimesSolidIcon className="remove" />
          </ActionButton>
        </li>
      )}

      {taskSizes.map((taskSize, i) => {
        return (
          <li key={i} className={taskSize === size ? 'isActive' : ''}>
            <ActionButton value={taskSize} title={`Select ${taskSize}`}>
              {taskSize}
            </ActionButton>
          </li>
        )
      })}
    </ul>
  )
}
