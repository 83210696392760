import { StateReducers } from '../reducers/types/StateReducers'

import { DASHBOARD_HOME_ROUTE, PLAN_ROUTE, CURRENT_ROUTE } from 'routes'

interface Props {
  state: StateReducers
}

export const getDashboardRoute = ({ state }: Props): string => {
  const sessionStorage = state.sessionStorage || undefined
  const dashboardView = sessionStorage?.dashboardView

  if (dashboardView === 'plan') {
    return PLAN_ROUTE
  }

  if (dashboardView === 'current') {
    return CURRENT_ROUTE
  }

  return DASHBOARD_HOME_ROUTE
}
