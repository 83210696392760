import { connect } from 'react-redux'
import { UserReportMain } from './UserReportMain'
import { UserReportMainMapState } from './UserReportMainMapState'
import { UserReportMainMapDispatch } from './UserReportMainMapDispatch'
import { UserReportMainMapStateReturnType } from './types/UserReportMainMapStateReturnType'
import { UserReportMainMapDispatchReturnType } from './types/UserReportMainMapDispatchReturnType'
import { UserReportMainConnectedProps } from './types/UserReportMainConnectedProps'

export const UserReportMainConnected = connect<
  UserReportMainMapStateReturnType,
  UserReportMainMapDispatchReturnType,
  UserReportMainConnectedProps>(UserReportMainMapState,
    UserReportMainMapDispatch)(UserReportMain)
