import { DELETE_INVITES_SUCCESS } from 'actions'
import { DeleteInvitesSuccessAction } from './types/DeleteInvitesSuccessAction'
import { DeleteInvitesSuccessActionValues } from './types/DeleteInvitesSuccessActionValues'
import { DeleteInvitesRequestActionValues } from './types/DeleteInvitesRequestActionValues'

interface Props {
  successValues: DeleteInvitesSuccessActionValues
  requestValues?: DeleteInvitesRequestActionValues
  description: string
}

export const createDeleteInvitesSuccessAction = ({
  successValues,
  requestValues,
  description,
}: Props): DeleteInvitesSuccessAction => {
  return {
    type: DELETE_INVITES_SUCCESS,
    requestValues,
    successValues,
    description,
  }
}
