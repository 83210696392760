import { connect } from 'react-redux'
import { UserInviteCard } from './UserInviteCard'
import { UserInviteCardMapState } from './UserInviteCardMapState'
import { UserInviteCardMapDispatch } from './UserInviteCardMapDispatch'

import { UserInviteCardMapStateReturnType } from './types/UserInviteCardMapStateReturnType'
import { UserInviteCardMapDispatchReturnType } from './types/UserInviteCardMapDispatchReturnType'
import { UserInviteCardConnectedProps } from './types/UserInviteCardConnectedProps'


export const UserInviteCardConnected = connect<
  UserInviteCardMapStateReturnType,
  UserInviteCardMapDispatchReturnType,
  UserInviteCardConnectedProps>(UserInviteCardMapState,
    UserInviteCardMapDispatch)(UserInviteCard)
