import { connect } from 'react-redux'
import { NewUserForm } from './NewUserForm'
import { NewUserFormMapState } from './NewUserFormMapState'
import { NewUserFormMapDispatch } from './NewUserFormMapDispatch'
import { NewUserFormMapStateReturnType } from './types/NewUserFormMapStateReturnType'
import { NewUserFormMapDispatchReturnType } from './types/NewUserFormMapDispatchReturnType'
import { NewUserFormConnectedProps } from './types/NewUserFormConnectedProps'

export const NewUserFormConnected = connect<
  NewUserFormMapStateReturnType,
  NewUserFormMapDispatchReturnType,
  NewUserFormConnectedProps
>(
  NewUserFormMapState,
  NewUserFormMapDispatch
)(NewUserForm)
