import { take, put, select } from 'redux-saga/effects'
import { FIREBASE_AUTH_STATE_CHANGED } from 'actions'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import {
  FirebaseAuthStateChangedAction,
  createSignInSuccessAction,
  createSignOutSuccessAction,
} from 'actionCreators/auth'

export const firebaseAuthStateChangedF = function*() {
  while (1) {
    const action: FirebaseAuthStateChangedAction = yield take([
      FIREBASE_AUTH_STATE_CHANGED,
    ])
    const user = action.values
    const userId = user?.uid
    const emailVerified = user?.emailVerified || false
    const email = user?.email
    const displayName = user?.displayName
    const photoURL = user?.photoURL
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state })
    const refreshIdToken = query && query.refreshIdToken

    if (user && refreshIdToken) {
      yield user.getIdToken(true)
    }

    if (userId && email && userId !== state.currentUserId) {
      // Make sure user is not already signed in from google sign in
      const signInSuccessAction = createSignInSuccessAction({
        userId,
        user: {
          emailVerified,
          email,
          displayName: displayName || undefined,
          photoURL: photoURL || undefined,
        },
      })

      yield put(signInSuccessAction)
    }

    if (!userId && state.currentLocation?.isRoutes?.isPostLoginRoute) {
      const signOutSuccessAction = createSignOutSuccessAction()

      yield put(signOutSuccessAction)
    }
  }
}
