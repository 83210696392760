import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { StripeSubscriptionFieldConnectedProps } from './types/StripeSubscriptionFieldConnectedProps'
import { StripeSubscriptionFieldMapStateReturnType } from './types/StripeSubscriptionFieldMapStateReturnType'

export const StripeSubscriptionFieldMapState = (
  state: StateReducers,
  _props: StripeSubscriptionFieldConnectedProps
): StripeSubscriptionFieldMapStateReturnType => {
  const teamId = getCurrentTeamId({ state })

  return {
    canGetToken: !!state.currentUserId,
    teamId,
  }
}
