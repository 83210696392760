export interface KeyBindingProps {
  e: any
  saveOnEnter?: boolean
}

export const keyBinding = ({ e, saveOnEnter }: KeyBindingProps): any => {
  if (e.key === 'Enter' && !e.shiftKey && saveOnEnter) {
    return 'save'
  }

  return undefined // make sure to undefined to propregate
}
