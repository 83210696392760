import { DeleteWorkspaceMainStyles } from './DeleteWorkspaceMainStyles'
import React, { useState } from 'react'
import { DeleteWorkspaceMainProps } from './types/DeleteWorkspaceMainProps'
import { TextInput } from 'components/input/TextInput'
import { LabelDiv } from 'components/div/LabelDiv'
import { ActionButton } from 'components/button/ActionButton'
import { ErrorMessage } from 'components/message/ErrorMessage'
import { ExclamationCircleIcon } from 'components/icon/ExclamationCircleIcon/ExclamationCircleIcon'
import { IconAndDiv } from 'components/div/IconAndDiv/IconAndDiv'
import { HeaderOneTextConnected } from '../../text/HeaderOneText/HeaderOneTextConnected'
import { dangerColorStyles } from '../../../styles/color/dangerColorStyles'
import { marginTopSmallStyles } from '../../../styles/margin/marginTopSmallStyles'
import { marginTopMediumStyles } from '../../../styles/margin/marginTopMediumStyles'
import { marginLeftSmallStyles } from '../../../styles/margin/marginLeftSmallStyles'

export const DeleteWorkspaceMain = ({
  displayName,
  submitDelete,
}: DeleteWorkspaceMainProps): JSX.Element => {
  const [workspaceName, setWorkspaceName] = useState('')
  const [error, setError] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <div className={`DeleteWorkspaceMain ${DeleteWorkspaceMainStyles}`}>
      <HeaderOneTextConnected className={dangerColorStyles}>
        <IconAndDiv icon={<ExclamationCircleIcon />}>
          Permanently Delete Workspace
        </IconAndDiv>
      </HeaderOneTextConnected>

      <LabelDiv>
        Please type <strong>{displayName}</strong> to delete it:
      </LabelDiv>

      <TextInput
        value={workspaceName}
        onChange={(e) => {
          setError('')
          setWorkspaceName(e.target.value)
        }}
      />

      {error && (
        <ErrorMessage className={marginTopSmallStyles}>{error}</ErrorMessage>
      )}

      <ActionButton
        title="Delete Workspace"
        className={marginTopMediumStyles}
        onClick={() => {
          setShowConfirm(!showConfirm)
        }}
      >
        Delete workspace
      </ActionButton>

      {showConfirm && (
        <ActionButton
          className={`danger ${marginTopMediumStyles} ${marginLeftSmallStyles}`}
          title="Confirm Delete Workspace"
          onClick={() => {
            if (workspaceName && workspaceName === displayName) {
              submitDelete()
            } else {
              setError('The name does not match.')
            }
          }}
        >
          Confirm
        </ActionButton>
      )}
    </div>
  )
}
