import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK } from 'constants/styleConstants'

export const HistoryDropdownStyles = css`
  position: relative;
  height: 25px;

  .DropdownButton,
  .HistorySolidIcon {
    height: 25px;
  }

  > button {
    padding: 0 4px;
    font-size: 1.6em;
  }

  .historyHeader {
    font-size: 0.75em;
    padding: 16px;
    font-weight: bold;
  }

  .Dropdown {
    .chevronContainer {
      right: 3px;
    }

    .container {
      right: -16px;
    }
  }

  &.isMediumScreen {
    .Dropdown {
      .container {
        right: -5px;
        width: 380px;
      }
    }
  }
`
