import { Dispatch, Action } from 'redux'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'
import { PlanLinkMapDispatchReturnType } from './types/PlanLinkMapDispatchReturnType'
import { createSetScrollPositionAction } from 'actionCreators/set/SetScrollPosition/createSetScrollPositionAction'

export const PlanLinkMapDispatch = (
  dispatch: Dispatch<Action>
): PlanLinkMapDispatchReturnType => {
  return {
    clearPlanScrollPosition: () => {
      dispatch(
        createSetScrollPositionAction({
          description: 'PlanLinkMapDispatch',
          scrollPosition: {
            planPosition: 0,
          },
        })
      )
    },
  }
}
