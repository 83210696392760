import { css } from 'emotion'

import {
  NEUTRAL_BORDER_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_LIGHTER,
  NEUTRAL_HIGH_LIGHT_COLOR,
  BORDER_RADIUS,
} from 'constants/styleConstants'

export const GoBackLinkStyles = css`
  display: inline-block;
  border: 1px solid ${NEUTRAL_BORDER_COLOR};
  border-radius: ${BORDER_RADIUS};
  padding: 6px 10px;

  &.GoBackLink {
    color: ${TEXT_COLOR_LIGHTER};
  }

  &:hover, &:active {
    background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
    color: ${TEXT_COLOR};
  }

  .fas {
    font-size: 0.8em;
  }
}`
