import { MemberCardStyles } from './MemberCardStyles'
import React from 'react'
import { MemberCardProps } from './types/MemberCardProps'
import { Avatar } from 'components/widget/Avatar'
import { roleDescriptions } from 'constants/roleDescriptions'
import { lightBoxCardStyles } from 'styles/lightBoxCardStyles'

export const MemberCard = ({
  email,
  roleType,
  displayName,
  photoURL,
}: MemberCardProps): JSX.Element => {
  return (
    <div className={`MemberCard ${MemberCardStyles} ${lightBoxCardStyles}`}>
      <div className="avatarDisplayName">
        <Avatar photoURL={photoURL} displayName={displayName} />

        <div>
          <div className="displayName">{displayName || '(no name)'}</div>

          <div className="email">{email}</div>
        </div>
      </div>

      <div className="roleType">{roleType && roleDescriptions[roleType]}</div>
    </div>
  )
}
