import { ErrorMessageStyles } from './ErrorMessageStyles'
import React from 'react'
import { ErrorMessageProps } from './types/ErrorMessageProps'

export const ErrorMessage = ({
  className = '',
  ...props
}: ErrorMessageProps): JSX.Element => {
  const extraClassName = className ? ` ${className}` : ''

  return (
    <p
      className={'ErrorMessage ' + ErrorMessageStyles + extraClassName}
      {...props}
    />
  )
}
