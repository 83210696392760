import { connect } from 'react-redux'
import { MessagePushNotificationField } from './MessagePushNotificationField'
import { MessagePushNotificationFieldMapState } from './MessagePushNotificationFieldMapState'
import { MessagePushNotificationFieldMapDispatch } from './MessagePushNotificationFieldMapDispatch'

import { MessagePushNotificationFieldMapStateReturnType } from './types/MessagePushNotificationFieldMapStateReturnType'
import { MessagePushNotificationFieldMapDispatchReturnType } from './types/MessagePushNotificationFieldMapDispatchReturnType'
import { MessagePushNotificationFieldConnectedProps } from './types/MessagePushNotificationFieldConnectedProps'


export const MessagePushNotificationFieldConnected = connect<
  MessagePushNotificationFieldMapStateReturnType,
  MessagePushNotificationFieldMapDispatchReturnType,
  MessagePushNotificationFieldConnectedProps>(MessagePushNotificationFieldMapState,
    MessagePushNotificationFieldMapDispatch)(MessagePushNotificationField)
