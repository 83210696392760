import { DELETE_TEAMS_REQUEST } from 'actions'
import { DeleteTeamsRequestActionValues } from './types/DeleteTeamsRequestActionValues'
import { DeleteTeamsRequestAction } from './types/DeleteTeamsRequestAction'

interface Props {
  requestValues: DeleteTeamsRequestActionValues
}

export const createDeleteTeamsRequestAction = ({
  requestValues,
}: Props): DeleteTeamsRequestAction => {
  return {
    type: DELETE_TEAMS_REQUEST,
    requestValues,
  }
}
