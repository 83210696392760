import { connect } from 'react-redux'
import { FullImageWidget } from './FullImageWidget'
import { FullImageWidgetMapState } from './FullImageWidgetMapState'
import { FullImageWidgetMapDispatch } from './FullImageWidgetMapDispatch'

import { FullImageWidgetMapStateReturnType } from './types/FullImageWidgetMapStateReturnType'
import { FullImageWidgetMapDispatchReturnType } from './types/FullImageWidgetMapDispatchReturnType'
import { FullImageWidgetConnectedProps } from './types/FullImageWidgetConnectedProps'


export const FullImageWidgetConnected = connect<
  FullImageWidgetMapStateReturnType,
  FullImageWidgetMapDispatchReturnType,
  FullImageWidgetConnectedProps>(FullImageWidgetMapState,
    FullImageWidgetMapDispatch)(FullImageWidget)
