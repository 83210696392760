import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCanPossiblyOwn } from 'state/getters/getCanPossiblyOwn'

import { TeamDropdownConnectedProps } from './types/TeamDropdownConnectedProps'
import { TeamDropdownMapStateReturnType } from './types/TeamDropdownMapStateReturnType'


export const TeamDropdownMapState = (
  state: StateReducers,
  props: TeamDropdownConnectedProps
): TeamDropdownMapStateReturnType => {
  const showDropdown = state.toggles?.showDropdown
  const canPossiblyOwn = getCanPossiblyOwn({ state, teamId: props.teamId })
  const showTeamDropdown =
    showDropdown &&
    showDropdown.type === 'team' &&
    showDropdown.id === props.teamId

  return {
    showTeamDropdown,
    showSettingsLink: canPossiblyOwn,
  }
}
