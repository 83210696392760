import { MessageLayoutStyles } from './MessageLayoutStyles'
import React, { useRef, useEffect } from 'react'
import { MessageLayoutProps } from './types/MessageLayoutProps'
import { PageSpinnerWidgetConnected } from 'components/widget/PageSpinnerWidget'
import { MessagesMainConnected } from 'components/main/MessagesMain'
import { BigHeaderConnected } from 'components/header/BigHeader'
import { TaskTagListConnected } from '../../list/TaskTagList'

export const MessageLayout = ({
  taskId,
  canRead,
  isMediumScreen,
  isBigScreen,
  windowHeight,
}: MessageLayoutProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''
  const isBigScreenClassName = isBigScreen ? ' isBigScreen' : ''

  useEffect(() => {
    const element = ref.current

    if (element && taskId && canRead && windowHeight) {
      const header = element.children[0]
      const messages = element.children[1] as HTMLDivElement

      messages.style.height = windowHeight - header.clientHeight + 'px'

      const onAnyMutation = (
        _mutations: MutationRecord[],
        _observer: MutationObserver
      ) => {
        messages.style.height = windowHeight - header.clientHeight + 'px'
      }

      const anyMutationObserver = new MutationObserver(onAnyMutation)

      anyMutationObserver.observe(header, {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
      })

      return () => {
        anyMutationObserver.disconnect()
      }
    }

    return
  }, [ref, taskId, canRead, windowHeight])

  /*
    ***
    If you're make changes to the DOM, becareful because we're using children and indexes above
    ***
  */
  return (
    <div
      className={
        'MessageLayout ' +
        MessageLayoutStyles +
        isMediumScreenClassName +
        isBigScreenClassName
      }
      ref={ref}
    >
      <PageSpinnerWidgetConnected />

      <div className="mainHeader">
        <BigHeaderConnected />

        {taskId && (
          <TaskTagListConnected showTagDropdownLast={true} taskId={taskId} />
        )}
      </div>

      {taskId && canRead && <MessagesMainConnected taskId={taskId} />}
    </div>
  )
}
