import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { forEach } from 'lodash'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createPatchTasksRequestAction } from 'actionCreators/patch/PatchTasks/createPatchTasksRequestAction'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { createPatchTasksSuccessAction } from 'actionCreators/patch/PatchTasks/createPatchTasksSuccessAction'
import { createPatchTasksFailureAction } from 'actionCreators/patch/PatchTasks/createPatchTasksFailureAction'
import { batchUpdateTask } from '../../../firebaseFunctions/utilities/ref/batchUpdate/batchUpdateTask'
import { Task } from '../../../firebaseFunctions/types'

interface Props {
  items: TasksReducer
  lastModifiedUserId: 'skip' | string // Cant use null because we may actually want to use null instead of skipping
  lastModifiedDisplayName: 'skip' | string
}

export const patchTasksSaga = function*({
  items,
  lastModifiedUserId,
  lastModifiedDisplayName,
}: Props) {
  yield put(
    createPatchTasksRequestAction({
      requestValues: {
        lastModifiedUserId,
        lastModifiedDisplayName,
        items: items,
      },
    })
  )

  try {
    const batch = firestore.batch()

    forEach(items, (taskReducer, taskId) => {
      const { data, teamId } = taskReducer
      const taskMutable: Task = { ...data }

      if (lastModifiedUserId !== 'skip') {
        taskMutable.lastModifiedUserId = lastModifiedUserId
      }

      if (lastModifiedDisplayName !== 'skip') {
        taskMutable.lastModifiedDisplayName = lastModifiedDisplayName
      }

      batchUpdateTask({
        firestore,
        teamId,
        taskId,
        task: taskMutable,
        batch,
      })
    })

    yield batch.commit()

    yield put(
      createPatchTasksSuccessAction({
        requestValues: { items, lastModifiedUserId, lastModifiedDisplayName },
        successValues: { items },
      })
    )
  } catch (error) {
    yield put(
      createFirebaseErrorAction({
        error: error as firebase.FirebaseError,
        description: 'patchTasksSaga',
      })
    )

    yield put(
      createPatchTasksFailureAction({
        requestValues: { items, lastModifiedUserId, lastModifiedDisplayName },
      })
    )
  }
}
