import { connect } from 'react-redux'
import { LabelIconSpan } from './LabelIconSpan'
import { LabelIconSpanMapState } from './LabelIconSpanMapState'
import { LabelIconSpanMapDispatch } from './LabelIconSpanMapDispatch'

import { LabelIconSpanMapStateReturnType } from './types/LabelIconSpanMapStateReturnType'
import { LabelIconSpanMapDispatchReturnType } from './types/LabelIconSpanMapDispatchReturnType'
import { LabelIconSpanConnectedProps } from './types/LabelIconSpanConnectedProps'


export const LabelIconSpanConnected = connect<
  LabelIconSpanMapStateReturnType,
  LabelIconSpanMapDispatchReturnType,
  LabelIconSpanConnectedProps>(LabelIconSpanMapState,
    LabelIconSpanMapDispatch)(LabelIconSpan)
