import { Dispatch, Action } from 'redux'
import { SUBMIT_DELETE_WORKSPACE } from 'actions'
import { DeleteWorkspaceMainMapDispatchReturnType } from './types/DeleteWorkspaceMainMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const DeleteWorkspaceMainMapDispatch = (
  dispatch: Dispatch<Action>
): DeleteWorkspaceMainMapDispatchReturnType => {
  return {
    submitDelete: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_DELETE_WORKSPACE,
          values: {},
        })
      )
    },
  }
}
