import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import {
  // TaskSummaryFieldConnectedProps,
  TaskSummaryFieldMapStateReturnType,
} from './types'
import { getCanWrite } from '../../../state/getters/getCanWrite'

export const TaskSummaryFieldMapState = (
  state: StateReducers
  // props: TaskSummaryFieldConnectedProps,
): TaskSummaryFieldMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const task = taskId ? getCurrentTask({ state, taskId }) : undefined
  const summary = task?.summary
  const canWrite = getCanWrite({ state })

  return {
    initialSummary: summary,
    canWrite,
  }
}
