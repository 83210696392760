import { css } from 'emotion'

import {
  NEUTRAL_HIGH_LIGHT_COLOR,
  NEUTRAL_BORDER_COLOR_LIGHT,
  TEXT_COLOR_LIGHT,
  TEXT_COLOR_LIGHTER,
} from 'constants/styleConstants'

export const UploadedImageWidgetStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .actions {
    position: absolute;
    margin: 16px;

    > li {
      margin-top: 8px;

      &:first-child {
        margin-top: 0px;
      }
    }
  }

  .thumbNail {
    max-width: 100%;
    display: block;
    border: 1px dashed ${NEUTRAL_BORDER_COLOR_LIGHT};
  }

  .fileContainer {
    border: 1px dashed ${NEUTRAL_BORDER_COLOR_LIGHT};
    color: ${TEXT_COLOR_LIGHTER};

    .showFileActionsButton {
      padding: 16px;
    }

    .fileActions {
      > * {
        margin-right: 8px;
      }
    }
  }

  .imagePlaceHolder {
    height: 200px;
    background-color: ${NEUTRAL_HIGH_LIGHT_COLOR};
  }

  .fileName {
    font-size: 0.8em;
    color: ${TEXT_COLOR_LIGHT};
    margin-bottom: 8px;
  }
`
