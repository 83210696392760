import { forEach } from 'lodash'
import { createGenericReducer } from 'state/reducers/utilities/createGenericReducer'

import {
  GET_TASKS_SUCCESS,
  POST_TASKS_REQUEST,
  DELETE_TASKS_REQUEST,
  DELETE_TASKS_SUCCESS,
  PATCH_TASKS_REQUEST,
  CLEAR_TEAM_SCOPE,
  CLEAR_TEAM_WATCHED_TASKS,
} from 'actions'
import { TasksReducer } from './types/TasksReducer'
import { TaskReducer } from './types/TaskReducer'
import { ClearTeamWatchedTasksAction } from 'actionCreators/types/ClearTeamWatchedTasksAction'

const extend = (
  state?: TasksReducer,
  action?: ClearTeamWatchedTasksAction
): TasksReducer | null => {
  if (!action || !state) {
    return state || null
  }

  switch (action.type) {
    case CLEAR_TEAM_WATCHED_TASKS: {
      const newStateMutable: TasksReducer = {}

      forEach(state, (task, id) => {
        if (!task.teamWatched) {
          newStateMutable[id] = task
        }
      })

      return newStateMutable
    }

    default:
      return state || null
  }
}

export const tasksReducer = createGenericReducer<TasksReducer, TaskReducer>({
  getSuccessAction: GET_TASKS_SUCCESS,
  postRequestAction: POST_TASKS_REQUEST,
  deleteRequestAction: DELETE_TASKS_REQUEST,
  deleteSuccessAction: DELETE_TASKS_SUCCESS, // TODO: add delete success because another user may have done it.
  patchRequestAction: PATCH_TASKS_REQUEST,
  clearActions: [CLEAR_TEAM_SCOPE],
  extend,
  // debug: true,
})
