import { css } from 'emotion'
// import { LINK_COLOR, LINK_COLOR_DARK } from 'constants/styleConstants'

export const StatusDropdownStyles = css`
  position: relative;

  .Dropdown {
    .chevronContainer {
      left: 6px;
      top: 22px;
    }

    .container {
      width: 280px;
      left: 0px;
      top: 29px;
    }
  }
`
