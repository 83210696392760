import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'
import { getCurrentTask } from 'state/getters/getCurrentTask'

import {
  // TaskSummaryMainConnectedProps,
  TaskSummaryMainMapStateReturnType,
} from './types'
import { FirebaseTimestamp } from 'sdks'

export const TaskSummaryMainMapState = (
  state: StateReducers
  // props: TaskSummaryMainConnectedProps,
): TaskSummaryMainMapStateReturnType => {
  const taskId = getCurrentTaskId({ state })
  const task = taskId ? getCurrentTask({ state, taskId }) : undefined
  const currentUserId = state.currentUserId || undefined
  const workingOnSummary = task?.workingOnSummary
  const workingOnSummaryUserId = workingOnSummary?.userId
  const workingOnSummaryUpdated = workingOnSummary?.updated
  const workingOnSummaryUpdatedSeconds =
    workingOnSummaryUpdated instanceof FirebaseTimestamp
      ? workingOnSummaryUpdated.seconds
      : undefined

  return {
    currentUserId,
    workingOnSummaryUserId,
    workingOnSummaryUpdatedSeconds,
  }
}
