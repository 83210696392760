import { connect } from 'react-redux'
import { TaskStatusButtonsInputField } from './TaskStatusButtonsInputField'
import { TaskStatusButtonsInputFieldMapState } from './TaskStatusButtonsInputFieldMapState'
import { TaskStatusButtonsInputFieldMapDispatch } from './TaskStatusButtonsInputFieldMapDispatch'
import { TaskStatusButtonsInputFieldMapStateReturnType } from './types/TaskStatusButtonsInputFieldMapStateReturnType'
import { TaskStatusButtonsInputFieldMapDispatchReturnType } from './types/TaskStatusButtonsInputFieldMapDispatchReturnType'
import { TaskStatusButtonsInputFieldConnectedProps } from './types/TaskStatusButtonsInputFieldConnectedProps'

export const TaskStatusButtonsInputFieldConnected = connect<
  TaskStatusButtonsInputFieldMapStateReturnType,
  TaskStatusButtonsInputFieldMapDispatchReturnType,
  TaskStatusButtonsInputFieldConnectedProps
>(
  TaskStatusButtonsInputFieldMapState,
  TaskStatusButtonsInputFieldMapDispatch
)(TaskStatusButtonsInputField)
