// import { Dispatch, Action } from 'redux'

import {
  // TagDropdownConnectedProps,
  TagDropdownMapDispatchReturnType,
} from './types'

export const TagDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TagDropdownConnectedProps,
): TagDropdownMapDispatchReturnType => {
  return {
  }
}
