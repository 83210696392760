import { css } from 'emotion'

export const TextInputFieldStyles = css`
  > label {
    display: block;
  }

  .ErrorMessage {
    margin-top: 8px;
  }
`
