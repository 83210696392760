import { connect } from 'react-redux'
import { GoogleSignInButton } from './GoogleSignInButton'
import { GoogleSignInButtonMapState } from './GoogleSignInButtonMapState'
import { GoogleSignInButtonMapDispatch } from './GoogleSignInButtonMapDispatch'

import { GoogleSignInButtonMapStateReturnType } from './types/GoogleSignInButtonMapStateReturnType'
import { GoogleSignInButtonMapDispatchReturnType } from './types/GoogleSignInButtonMapDispatchReturnType'
import { GoogleSignInButtonConnectProps } from './types/GoogleSignInButtonConnectProps'


export const GoogleSignInButtonConnected = connect<
  GoogleSignInButtonMapStateReturnType,
  GoogleSignInButtonMapDispatchReturnType,
  GoogleSignInButtonConnectProps>(GoogleSignInButtonMapState,
    GoogleSignInButtonMapDispatch)(GoogleSignInButton)
