import { connect } from 'react-redux'
import { DeleteTeamLogoButton } from './DeleteTeamLogoButton'
import { DeleteTeamLogoButtonMapState } from './DeleteTeamLogoButtonMapState'
import { DeleteTeamLogoButtonMapDispatch } from './DeleteTeamLogoButtonMapDispatch'
import { DeleteTeamLogoButtonMapStateReturnType } from './types/DeleteTeamLogoButtonMapStateReturnType'
import { DeleteTeamLogoButtonMapDispatchReturnType } from './types/DeleteTeamLogoButtonMapDispatchReturnType'
import { DeleteTeamLogoButtonConnectedProps } from './types/DeleteTeamLogoButtonConnectedProps'

export const DeleteTeamLogoButtonConnected = connect<
  DeleteTeamLogoButtonMapStateReturnType,
  DeleteTeamLogoButtonMapDispatchReturnType,
  DeleteTeamLogoButtonConnectedProps>(DeleteTeamLogoButtonMapState,
    DeleteTeamLogoButtonMapDispatch)(DeleteTeamLogoButton)
