import { PATCH_TEAMS_FF_FAILURE } from 'actions'

import { PatchTeamsFfFailureAction } from './types/PatchTeamsFfFailureAction'
import { PatchTeamsFfRequestActionValues } from './types/PatchTeamsFfRequestActionValues'


interface Props {
  requestValues: PatchTeamsFfRequestActionValues
}

export const createPatchTeamsFfFailureAction = ({
  requestValues,
}: Props): PatchTeamsFfFailureAction => {
  return {
    type: PATCH_TEAMS_FF_FAILURE,
    requestValues,
  }
}
