// import { Dispatch, Action } from 'redux'

import {
  // TaskSizeListConnectedProps,
  TaskSizeListMapDispatchReturnType,
} from './types'

export const TaskSizeListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TaskSizeListConnectedProps,
): TaskSizeListMapDispatchReturnType => {
  return {
  }
}
