import { connect } from 'react-redux'
import { TeamTasksSingleWatcher } from './TeamTasksSingleWatcher'
import { TeamTasksSingleWatcherMapState } from './TeamTasksSingleWatcherMapState'
import { TeamTasksSingleWatcherMapDispatch } from './TeamTasksSingleWatcherMapDispatch'
import { TeamTasksSingleWatcherMapStateReturnType } from './types/TeamTasksSingleWatcherMapStateReturnType'
import { TeamTasksSingleWatcherMapDispatchReturnType } from './types/TeamTasksSingleWatcherMapDispatchReturnType'
import { TeamTasksSingleWatcherConnectedProps } from './types/TeamTasksSingleWatcherConnectedProps'

export const TeamTasksSingleWatcherConnected = connect<
  TeamTasksSingleWatcherMapStateReturnType,
  TeamTasksSingleWatcherMapDispatchReturnType,
  TeamTasksSingleWatcherConnectedProps>(TeamTasksSingleWatcherMapState,
    TeamTasksSingleWatcherMapDispatch)(TeamTasksSingleWatcher)
