import { ActionButtonStyles } from 'components/button/ActionButton/ActionButtonStyles'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { ButtonLinkProps } from './types/ButtonLinkProps'

export const ButtonLink = (props: ButtonLinkProps) => {
  const { to, className = '', title, children } = props

  const extraClassName = className ? ' ' + className : ''

  if (to) {
    return (
      <Link
        {...(props as any)}
        title={title}
        className={'ButtonLink ' + ActionButtonStyles + extraClassName}
        to={to}
      >
        {children}
      </Link>
    )
  }

  return (
    <a
      {...props}
      title={title}
      className={'ButtonLink ' + ActionButtonStyles + extraClassName}
    >
      {children}
    </a>
  )
}
