// import { Dispatch, Action } from 'redux'

import {
  // AlertMessageConnectedProps,
  AlertMessageMapDispatchReturnType,
} from './types'

export const AlertMessageMapDispatch = (): // dispatch: Dispatch<Action>,
// props: AlertMessageConnectedProps,
AlertMessageMapDispatchReturnType => {
  return {}
}
