// import { StateReducers } from 'state/reducers/types/StateReducers'

import {
  // TaskChangeCardConnectedProps,
  TaskChangeCardMapStateReturnType,
} from './types'

export const TaskChangeCardMapState = (): // state: StateReducers,
// props: TaskChangeCardConnectedProps,
TaskChangeCardMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
