import { CUSTOM_CLAIM_URL } from 'constants/urlConstants'
import { DeleteCustomClaimSuccessPayload } from 'firebaseFunctions/customClaim/types'
import Axios, { AxiosPromise } from 'axios'
import { DeleteCustomClaimApiProps } from './DeleteCustomClaimApiProps'

export const deleteCustomClaimApi = ({
  apiHeaders,
}: DeleteCustomClaimApiProps): AxiosPromise<DeleteCustomClaimSuccessPayload> => {
  return Axios.delete(CUSTOM_CLAIM_URL, {
    headers: apiHeaders,
  })
}
