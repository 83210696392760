import {
  TEAMS_COLLECTION,
  TASKS_COLLECTION,
  MESSAGES_COLLECTION,
} from '../../constants'

interface Props {
  teamId: string
  taskId: string
  messageId: string
}

export const getMessageStoragePath = ({
  teamId,
  taskId,
  messageId,
}: Props): string => {
  return `${TEAMS_COLLECTION}/${teamId}/${TASKS_COLLECTION}/${taskId}/${MESSAGES_COLLECTION}/${messageId}/`
}
