import { GET_USER_EXTENSION_FAILURE } from 'actions'

import { GetUserExtensionFailureAction } from './types/GetUserExtensionFailureAction'
import { GetUserExtensionRequestActionValues } from './types/GetUserExtensionRequestActionValues'


interface Props {
  requestValues: GetUserExtensionRequestActionValues
}

export const createGetUserExtensionFailureAction = ({
  requestValues,
}: Props): GetUserExtensionFailureAction => {
  return {
    type: GET_USER_EXTENSION_FAILURE,
    requestValues,
  }
}
