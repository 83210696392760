 import { StateReducers } from 'state/reducers/types/StateReducers'
import { UpdateAvatarFormConnectedProps } from './types/UpdateAvatarFormConnectedProps'
import { UpdateAvatarFormMapStateReturnType } from './types/UpdateAvatarFormMapStateReturnType'

export const UpdateAvatarFormMapState = (
  _state: StateReducers,
  _props: UpdateAvatarFormConnectedProps,
): UpdateAvatarFormMapStateReturnType => {
  // const currentLocation = state.currentLocation || undefined
  // const isRoutes = currentLocation && currentLocation.isRoutes
  // return { isRoutes }

  return {}
}
