import { DropdownButtonStyles } from './DropdownButtonStyles'
import React, { useEffect, useState, useCallback } from 'react'
import { DropdownButtonProps } from './types/DropdownButtonProps'
import { iconButtonStyles } from '../../../styles/iconButtonStyles'

export const DropdownButton = ({
  children,
  className = '',
  showDropdown,
  hideDropdown,
  title,
  isSameDropdownType,
  style,
  watchSelector,
  dropdownType,
  id,
  disabled,
}: DropdownButtonProps): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false)

  const hideDropdownCallback = useCallback(() => {
    hideDropdown({
      type: dropdownType,
      id: id || null,
    })
  }, [hideDropdown, dropdownType, id])

  useEffect(() => {
    if (isOpened) {
      const onClickHandler = (event?: MouseEvent) => {
        const element = document.querySelector(watchSelector)

        if (element && event && event.target) {
          // comment this out because it was causing a bug when user clicks a diffrent page, but leaving because not sure why it was added in the first place
          // const isInDocument = document.body.contains(event.target as any)
          const isChildren = element && element.contains(event.target as any)

          if (!isChildren) {
            setIsOpened(false)
          }
        }
      }

      window.addEventListener('click', onClickHandler)

      return () => {
        hideDropdownCallback()
        window.removeEventListener('click', onClickHandler)
      }
    }

    return
  }, [isOpened, watchSelector, hideDropdownCallback])

  const handleOnClick = () => {
    setIsOpened(!isOpened)

    if (isSameDropdownType) {
      hideDropdown({
        type: dropdownType,
        id: id || null,
      })
    } else {
      showDropdown({
        type: dropdownType,
        id: id || null,
      })
    }
  }

  return (
    <button
      type="button"
      onClick={handleOnClick}
      title={title}
      style={style}
      disabled={disabled}
      className={`DropdownButton ${DropdownButtonStyles} ${className} ${iconButtonStyles}`}
    >
      {children}
    </button>
  )
}
