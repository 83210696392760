import { USER_URL } from 'constants/urlConstants'
import Axios, { AxiosPromise } from 'axios'
import { PostUserSuccessPayload } from 'firebaseFunctions/user/types/PostUserSuccessPayload'
import { PostUserApiProps } from './types/PostUserApiProps'

export const postUserApi = ({
  values,
}: PostUserApiProps): AxiosPromise<PostUserSuccessPayload> => {
  return Axios.post(USER_URL, values)
}
