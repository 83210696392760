// import { Dispatch, Action } from 'redux'

import {
  // TeamListConnectedProps,
  TeamListMapDispatchReturnType,
} from './types'

export const TeamListMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TeamListConnectedProps,
): TeamListMapDispatchReturnType => {
  return {
  }
}
