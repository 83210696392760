import { createStore as reduxCreateStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { rootReducer } from './reducers/rootReducer'
import createSagaMiddleware from 'redux-saga'
import { createRouterMiddleware } from './createRouterMiddleware'
import { reduxLogger } from './reduxLogger'
import { StateReducers } from './reducers/types/StateReducers'
import { firebaseSdk } from 'sdks'
import { History, createBrowserHistory } from 'history'
import { createFirebaseAuthStateChangedAction } from 'actionCreators/auth'

interface Props {
  history?: History
  initialState?: StateReducers
  enableLogger?: boolean
  flows?: any
}

export const createStore = ({
  history,
  initialState = {},
  enableLogger = true,
  flows,
}: Props) => {
  const sagaMiddleware = createSagaMiddleware()
  const routerMiddleware = createRouterMiddleware(
    history || createBrowserHistory()
  )
  const noOp = (f: any) => f

  const finalCreateStore = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware), // Should be after saga in order to get put actions
    enableLogger ? applyMiddleware(reduxLogger) : noOp
  )(reduxCreateStore)

  const store = finalCreateStore(rootReducer, initialState) as Store<
    StateReducers
  >

  if (flows) {
    sagaMiddleware.run(flows)
  }

  firebaseSdk.auth().onAuthStateChanged((user) => {
    store.dispatch(
      createFirebaseAuthStateChangedAction({
        user: user || undefined,
      })
    )
  })

  return store
}
