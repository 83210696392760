import { StateReducers } from 'state/reducers/types/StateReducers'
import { getTaskMessages } from 'state/getters/getTaskMessages'
import { getSortedByCreated } from 'utilities/getSortedByCreated'
import { memoizeFlatArray } from 'utilities/memoizeFlatArray'

import { MessageListConnectedProps } from './types/MessageListConnectedProps'
import { MessageListMapStateReturnType } from './types/MessageListMapStateReturnType'


export const MessageListMapState = (
  state: StateReducers,
  props: MessageListConnectedProps
): MessageListMapStateReturnType => {
  const { taskId } = props
  const messages = getTaskMessages({ state, taskId })

  const { ids, createdSeconds } = getSortedByCreated({
    itemsObject: messages || undefined,
  })

  return {
    messageIds: memoizeFlatArray(ids, `messages-messageIds-${taskId}`),
    createdSeconds: memoizeFlatArray(
      createdSeconds,
      `messages-createdSeconds-${taskId}`
    ),
  }
}
