import { Dispatch, Action } from 'redux'

import { TeamActionListConnectedProps } from './types/TeamActionListConnectedProps'
import { TeamActionListMapDispatchReturnType } from './types/TeamActionListMapDispatchReturnType'

import { createToggleAction } from 'actionCreators/createToggleAction'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

import { SUBMIT_LEAVE_TEAM } from 'actions'

export const TeamActionListMapDispatch = (
  dispatch: Dispatch<Action>,
  props: TeamActionListConnectedProps
): TeamActionListMapDispatchReturnType => {
  return {
    leave: () => {
      dispatch(
        createSubmitAction({
          type: SUBMIT_LEAVE_TEAM,
          values: {
            teamId: props.teamId,
          },
        })
      )

      dispatch(
        createToggleAction({
          description: 'TeamActionListMapDispatch',
          toggles: {
            showDropdown: false,
          },
        })
      )
    },
  }
}
