import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { getCurrentQuery } from 'state/getters/getCurrentQuery'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { SUBMIT_TASK_DESCRIPTION, SUBMIT_TASK_DESCRIPTION_DONE } from 'actions'
import { patchTasksSaga } from 'flows/sagas/patch/patchTasksSaga'
import { SubmitEditorAction } from 'actionCreators/submit/SubmitEditor'

export const submitTaskDescriptionF = function*() {
  while (1) {
    const action: SubmitEditorAction = yield take([SUBMIT_TASK_DESCRIPTION])
    const state: StateReducers = yield select(selectState)
    const query = getCurrentQuery({ state })
    const taskId = query && query.taskId
    const currentUserId = state.currentUserId
    const currentUserDisplayName = state.currentUser?.displayName
    const markdown = action.values.markdown || ''
    const teamId = getCurrentTeamId({ state })

    if (taskId && teamId && currentUserId && currentUserDisplayName) {
      const items: TasksReducer = {
        [taskId]: {
          data: {
            description: markdown,
            workingOnDescription: null,
          },
          teamId,
        },
      }

      yield spawn(patchTasksSaga, {
        items,
        lastModifiedUserId: currentUserId,
        lastModifiedDisplayName: currentUserDisplayName,
      })
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitTaskDescriptionF',
        })
      )
    }

    yield put({
      type: SUBMIT_TASK_DESCRIPTION_DONE,
    })
  }
}
