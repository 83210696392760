import { CLOSE_DROPDOWN } from 'actions'
import { CloseDropdownAction } from './types/CloseDropdownAction'
import { ShowDropdown } from 'types/ShowDropdown'

interface Props {
  values: ShowDropdown
  description: string
}

export const createCloseDropdownAction = ({
  values,
  description,
}: Props): CloseDropdownAction => {
  return {
    type: CLOSE_DROPDOWN,
    values,
    description,
  }
}
