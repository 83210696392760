import { StateReducers } from 'state/reducers/types/StateReducers'
import { EnableNotificationMessageConnectedProps } from './types/EnableNotificationMessageConnectedProps'
import { EnableNotificationMessageMapStateReturnType } from './types/EnableNotificationMessageMapStateReturnType'
import { getNotificationSettings } from '../../../state/getters/getNotificationSettings'

export const EnableNotificationMessageMapState = (
  state: StateReducers,
  _props: EnableNotificationMessageConnectedProps
): EnableNotificationMessageMapStateReturnType => {
  const notificationPermission = state.windowMeta?.notificationPermission
  const isSelectTeamRoute = state.currentLocation?.isRoutes?.isSelectTeamRoute
  const isLoadingUser = !state.currentUnscopeUser
  const platform = state.cookieStorage?.platform
  const dontAskToEnableNotifications =
    state.cookieStorage?.dontAskToEnableNotifications

  const { isMessagingEnabled, isActivityEnabled } = getNotificationSettings({
    state,
  })

  const askToEnableNotifications =
    isSelectTeamRoute &&
    !isLoadingUser &&
    !dontAskToEnableNotifications &&
    !isMessagingEnabled &&
    !isActivityEnabled &&
    platform !== 'MacOs' &&
    platform !== 'Window'

  return {
    notificationPermission,
    askToEnableNotifications,
  }
}
