import { StateReducers } from 'state/reducers/types/StateReducers'
import { getCurrentTask } from 'state/getters/getCurrentTask'
import { getCurrentTaskId } from 'state/getters/getCurrentTaskId'

import { TaskSummarySpanConnectedProps } from './types/TaskSummarySpanConnectedProps'
import { TaskSummarySpanMapStateReturnType } from './types/TaskSummarySpanMapStateReturnType'


export const TaskSummarySpanMapState = (
  state: StateReducers,
  props: TaskSummarySpanConnectedProps
): TaskSummarySpanMapStateReturnType => {
  const taskId = props.taskId || getCurrentTaskId({ state })
  const task = taskId ? getCurrentTask({ state, taskId }) : undefined
  const summary = task?.summary

  return {
    summary,
  }
}
