import * as React from 'react'
import { MarkdownDiv } from './MarkdownDiv'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

const md = `sddsdfsdf

sdfsdfdf

_sdfsdf_

_**sdsdsd**_

- _**\`sadasdsad\`**_
1. _**\`sasdasd\`**_
2. _**\`sad\`**_

> asdasdasd

\`\`\`
sdasdasdsadasdasd
\`\`\`

\`\`\`
asd
\`\`\`

\`\`\`
asdasdasd
\`\`\`
`

export const MarkdownDivExamples = () => {
  return (
    <div className="MarkdownDivExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <MarkdownDiv>{md}</MarkdownDiv>
      </Provider>
    </div>
  )
}
