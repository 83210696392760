import { connect } from 'react-redux'
import { TitleWatcher } from './TitleWatcher'
import { TitleWatcherMapState } from './TitleWatcherMapState'
import { TitleWatcherMapDispatch } from './TitleWatcherMapDispatch'

import { TitleWatcherMapStateReturnType } from './types/TitleWatcherMapStateReturnType'
import { TitleWatcherMapDispatchReturnType } from './types/TitleWatcherMapDispatchReturnType'
import { TitleWatcherConnectedProps } from './types/TitleWatcherConnectedProps'


export const TitleWatcherConnected = connect<
  TitleWatcherMapStateReturnType,
  TitleWatcherMapDispatchReturnType,
  TitleWatcherConnectedProps>(TitleWatcherMapState,
    TitleWatcherMapDispatch)(TitleWatcher)
