import { connect } from 'react-redux'
import { QuickSearchField } from './QuickSearchField'
import { QuickSearchFieldMapState } from './QuickSearchFieldMapState'
import { QuickSearchFieldMapDispatch } from './QuickSearchFieldMapDispatch'
import { QuickSearchFieldMapStateReturnType } from './types/QuickSearchFieldMapStateReturnType'
import { QuickSearchFieldMapDispatchReturnType } from './types/QuickSearchFieldMapDispatchReturnType'
import { QuickSearchFieldConnectedProps } from './types/QuickSearchFieldConnectedProps'

export const QuickSearchFieldConnected = connect<
  QuickSearchFieldMapStateReturnType,
  QuickSearchFieldMapDispatchReturnType,
  QuickSearchFieldConnectedProps>(QuickSearchFieldMapState,
    QuickSearchFieldMapDispatch)(QuickSearchField)
