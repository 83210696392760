import { PATCH_UNSCOPE_USER_REQUEST } from 'actions'
import { PatchUnscopeUserRequestAction } from './types/PatchUnscopeUserRequestAction'
import { PatchUnscopeUserRequestActionValues } from './types/PatchUnscopeUserRequestActionValues'

interface Props {
  requestValues: PatchUnscopeUserRequestActionValues,
}

export const createPatchUnscopeUserRequestAction = ({ requestValues }: Props): PatchUnscopeUserRequestAction => {
  return {
    type: PATCH_UNSCOPE_USER_REQUEST,
    requestValues,
  }
}
