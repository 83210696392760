import { DELETE_TASKS_FAILURE } from 'actions'
import { DeleteTasksFailureAction } from './types/DeleteTasksFailureAction'
import { DeleteTasksRequestActionValues } from './types/DeleteTasksRequestActionValues'

interface Props {
  requestValues?: DeleteTasksRequestActionValues
}

export const createDeleteTasksFailureAction = ({
  requestValues,
}: Props): DeleteTasksFailureAction => {
  return {
    type: DELETE_TASKS_FAILURE,
    requestValues,
  }
}
