import { css } from 'emotion'

export const LoginFormStyles = css`
  .links,
  .PasswordField {
    margin-top: 24px;
  }

  .links {
    text-align: center;

    a {
      margin: 10px;
    }
  }

  .GoogleSignInButton {
    margin: 0 auto;
  }

  .EmailField {
    margin-top: 32px;
  }

  .Recaptcha {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .TextInputField {
    margin-top: 32px;
  }
`
