import { DELETE_USER_INVITES_FAILURE } from 'actions'
import { DeleteUserInvitesRequestActionValues } from './types/DeleteUserInvitesRequestActionValues'
import { DeleteUserInvitesFailureAction } from './types/DeleteUserInvitesFailureAction'

interface Props {
  requestValues: DeleteUserInvitesRequestActionValues
}

export const createDeleteUserInvitesFailureAction = ({
  requestValues,
}: Props): DeleteUserInvitesFailureAction => {
  return {
    type: DELETE_USER_INVITES_FAILURE,
    requestValues,
  }
}
