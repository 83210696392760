import { connect } from 'react-redux'
import { CurrentLink } from './CurrentLink'
import { CurrentLinkMapState } from './CurrentLinkMapState'
import { CurrentLinkMapDispatch } from './CurrentLinkMapDispatch'

import { CurrentLinkMapStateReturnType } from './types/CurrentLinkMapStateReturnType'
import { CurrentLinkMapDispatchReturnType } from './types/CurrentLinkMapDispatchReturnType'
import { CurrentLinkConnectedProps } from './types/CurrentLinkConnectedProps'


export const CurrentLinkConnected = connect<
  CurrentLinkMapStateReturnType,
  CurrentLinkMapDispatchReturnType,
  CurrentLinkConnectedProps>(CurrentLinkMapState,
    CurrentLinkMapDispatch)(CurrentLink)
