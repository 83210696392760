import { MessageListStyles } from './MessageListStyles'
import React, { useEffect } from 'react'
import { MessageListProps } from './types/MessageListProps'
import { MessageCardConnected } from 'components/card/MessageCard'
import { DateSeparatorSpan } from 'components/span/DateSeparatorSpan'
import { ShowMoreButton } from 'components/button/ShowMoreButton'
import { GET_MESSAGES_LIMIT } from 'constants/environmentConstants'

export const MessageList = ({
  startReadingMessages,
  stopReadingMessages,
  setUnreadCount,
  messageIds = [],
  createdSeconds = [],
  showMoreOnClick,
  fetchingMessages,
  showMoreButton,
  taskId,
  messageRef,
  setReplyToMessageId,
}: MessageListProps): JSX.Element => {
  useEffect(() => {
    const element = messageRef.current

    if (element !== null) {
      let scrollHeight = 0
      element.scrollTop = element.scrollHeight
      let scrolledToBottom = true
      let scrollTop = 0
      let unreadCount = 0

      const setUnreadCountHelper = (newUnreadCount: number) => {
        if (newUnreadCount !== unreadCount) {
          unreadCount = newUnreadCount
          setUnreadCount?.(unreadCount)
        }
      }

      const onScroll = () => {
        scrollTop = element.scrollTop
        const cushion = 5
        scrolledToBottom =
          element.scrollTop >=
          element.scrollHeight - element.clientHeight - cushion

        if (scrolledToBottom) {
          setUnreadCountHelper(0)
        }
      }

      element.addEventListener('scroll', onScroll)

      const visibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopReadingMessages()
        } else {
          startReadingMessages()
        }
      }

      document.addEventListener('visibilitychange', visibilityChange)

      const onNewMessage = (
        mutations: MutationRecord[],
        _observer: MutationObserver
      ) => {
        const isPrepending = mutations[0].nextSibling
        const hasOverFlow = element.scrollHeight > element.clientHeight

        if (isPrepending) {
          // lock scroll position when prepending stuff
          element.scrollTop = scrollTop + element.scrollHeight - scrollHeight
        } else if (hasOverFlow && (document.hidden || !scrolledToBottom)) {
          const elementBound = element.getBoundingClientRect()
          const addedNode = mutations[0].addedNodes[0] as any

          if (addedNode) {
            const addedNodeBound = addedNode.getBoundingClientRect()
            const isOverFlow = addedNodeBound.top > elementBound.top

            if (isOverFlow) {
              setUnreadCountHelper(unreadCount + 1)
            }
          }
        }
      }

      const newMessageObserver = new MutationObserver(onNewMessage)

      newMessageObserver.observe(element, {
        childList: true,
      })

      const onAnyMutation = (
        _mutations: MutationRecord[],
        _observer: MutationObserver
      ) => {
        if (scrolledToBottom && !document.hidden) {
          // lock to the bottom
          element.scrollTop = element.scrollHeight
        }

        scrollHeight = element.scrollHeight
        scrollTop = element.scrollTop
      }

      const anyMutationObserver = new MutationObserver(onAnyMutation)

      anyMutationObserver.observe(element, {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
      })

      startReadingMessages()

      return () => {
        stopReadingMessages()
        newMessageObserver.disconnect()
        anyMutationObserver.disconnect()
        document.removeEventListener('visibilitychange', visibilityChange)
        element.removeEventListener('scroll', onScroll)
      }
    }

    return undefined
  }, [
    setUnreadCount,
    startReadingMessages,
    stopReadingMessages,
    taskId,
    messageRef,
  ])

  let previousDayOfMonth: number

  return (
    <ul className={'MessageList ' + MessageListStyles} ref={messageRef}>
      {showMoreButton && messageIds.length >= GET_MESSAGES_LIMIT && (
        <ShowMoreButton
          showSpinner={fetchingMessages}
          onClick={showMoreOnClick}
        />
      )}

      {messageIds.map((messageId, index) => {
        const date = new Date(createdSeconds[index] * 1000)
        const dayOfMonth = date.getDate()
        const isDifferentDayFromLastItem = previousDayOfMonth !== dayOfMonth

        if (isDifferentDayFromLastItem) {
          previousDayOfMonth = dayOfMonth
        }

        return (
          // each li is used to count messages
          <li key={messageId}>
            {isDifferentDayFromLastItem && createdSeconds[index] && (
              <div className="separator">
                <DateSeparatorSpan createdSeconds={createdSeconds[index]} />
              </div>
            )}

            <MessageCardConnected
              messageId={messageId}
              setReplyToMessageId={setReplyToMessageId}
            />
          </li>
        )
      })}

      {messageIds.length === 0 && (
        <li className="beginning">
          <p>This is the beginning of the conversation. Write a message!</p>
        </li>
      )}
    </ul>
  )
}
