import { css } from 'emotion'

export const TaskTagCardStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .DisplayNameSpan {
    margin-left: 8px;
  }
`
