import { INCREMENT_WATCHER } from 'actions'
import { StatReducer } from './types/StatReducer'
import { IncrementWatcherAction } from 'actionCreators/types/IncrementWatcherAction'

// TODO: use implicit Type Guards
export const statReducer = (
  state?: StatReducer,
  action?: IncrementWatcherAction
): StatReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case INCREMENT_WATCHER: {
      const previousWatcherCount = state?.watcherCounts || {}
      const previousCount = state?.watcherCounts?.[action.watcherType] || 0

      const newWatcherCountMutable = {
        ...previousWatcherCount,
        [action.watcherType]: previousCount + action.increment,
      }

      return {
        ...state,
        watcherCounts: newWatcherCountMutable,
      }
    }

    default:
      return state || null
  }
}
