import { Team } from '../../../types/Team'
import { TEAMS_COLLECTION } from '../../../constants'
import { FirestoreClient } from '../../../types'
import { FirestoreAdmin } from '../../../types/FirestoreAdmin'
import firebase from 'firebase'

interface Props<T extends FirestoreClient | FirestoreAdmin> {
  firestore: T
  teamId: string
}

export const getTeamRef = <T extends FirestoreClient | FirestoreAdmin>({
  firestore,
  teamId,
}: Props<T>): T extends FirestoreClient
  ? firebase.firestore.DocumentReference<Team>
  : FirebaseFirestore.DocumentReference => {
  return firestore.collection(TEAMS_COLLECTION).doc(teamId) as any
}
