import { take, put } from 'redux-saga/effects'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { API_FLOW_ERROR } from 'actions'
import { statusCodes } from 'firebaseFunctions/statusCodes'
import { ApiFlowErrorAction } from 'actionCreators/types/ApiFlowErrorAction'

// TODO: deprecare in favor of apiErrorF
export const apiFlowErrorF = function*() {
  while (1) {
    const apiFlowErrorAction: ApiFlowErrorAction = yield take(API_FLOW_ERROR)
    const hasMessage =
      apiFlowErrorAction.values.status === statusCodes.unprocessable &&
      apiFlowErrorAction.values.message

    if (apiFlowErrorAction && !hasMessage) {
      yield put(
        createToggleAction({
          description: 'apiFlowErrorF',
          toggles: {
            showModal: {
              type: 'Error',
            },
          },
        })
      )
    }
  }
}
