import { take, put, select, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { getCurrentTeamId } from 'state/getters/getCurrentTeamId'
import { getBackLogTaskIds } from 'state/getters/getBackLogTaskIds'
import { FIX_TASK_ORDERS, FIX_TASK_ORDERS_DONE } from 'actions'
import { patchTasksSaga } from './sagas/patch/patchTasksSaga'

// TODO: get rid of global orders
export const fixTaskOrdersF = function*() {
  while (1) {
    yield take([FIX_TASK_ORDERS])

    const state: StateReducers = yield select(selectState)
    const taskIds = getBackLogTaskIds({ state })
    const newTasksMutable: TasksReducer = {}
    const teamId = getCurrentTeamId({ state })

    if (teamId) {
      taskIds.reverse().forEach((taskId, i) => {
        newTasksMutable[taskId] = {
          data: {
            order: i * 1000,
          },
          teamId,
        }
      })

      yield spawn(patchTasksSaga, {
        items: newTasksMutable,
        lastModifiedUserId: 'skip',
        lastModifiedDisplayName: 'skip',
      })

      yield put({
        type: FIX_TASK_ORDERS_DONE,
      })
    }
  }
}
