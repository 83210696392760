import { getInviteRef } from 'firebaseFunctions/utilities/ref/get/getInviteRef'
import { put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { getObjectFirstItem } from 'firebaseFunctions/utilities/get/getObjectFirstItem'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { InvitesReducer } from 'state/reducers/types/InvitesReducer'
import { createDeleteInvitesRequestAction } from 'actionCreators/delete/DeleteInvites/createDeleteInvitesRequestAction'
import { createDeleteInvitesSuccessAction } from 'actionCreators/delete/DeleteInvites/createDeleteInvitesSuccessAction'
import { createDeleteInvitesFailureAction } from 'actionCreators/delete/DeleteInvites/createDeleteInvitesFailureAction'

interface Props {
  items: InvitesReducer
}

export const deleteInvitesSaga = function*({ items }: Props) {
  yield put(
    createDeleteInvitesRequestAction({
      requestValues: {
        items,
      },
    })
  )

  const { key: inviteId, item: firstInvite } = getObjectFirstItem(items)

  if (inviteId && firstInvite) {
    try {
      yield getInviteRef({
        firestore,
        teamId: firstInvite.teamId,
        inviteId,
      }).delete()

      yield put(
        createDeleteInvitesSuccessAction({
          requestValues: { items },
          successValues: { items },
          description: 'deleteInvitesSaga',
        })
      )
    } catch (error) {
      yield put(
        createFirebaseErrorAction({
          error: error as firebase.FirebaseError,
          description: 'deleteInvitesSaga',
        })
      )

      yield put(
        createDeleteInvitesFailureAction({
          requestValues: { items },
        })
      )
    }
  } else {
    yield put(
      createUnexpectedErrorAction({
        description: 'deleteInvitesRequestF',
      })
    )
  }
}
