import { Dispatch, Action } from 'redux'
import { UploadWatcherMapDispatchReturnType } from './types/UploadWatcherMapDispatchReturnType'
import { createUploadSuccessAction } from 'actionCreators/Upload/createUploadSuccessAction'
import { createUploadFailureAction } from 'actionCreators/Upload/createUploadFailureAction'
import { createSetAlertMessageAction } from '../../../actionCreators/set/SetAlertMessage/createSetAlertMessageAction'

export const UploadWatcherMapDispatch = (
  dispatch: Dispatch<Action>
): UploadWatcherMapDispatchReturnType => {
  return {
    success: ({ url, upload, uploadId }) => {
      dispatch(
        createUploadSuccessAction({
          requestValues: {
            [uploadId]: upload,
          },
          successValues: {
            [uploadId]: {
              ...upload,
              url,
              status: 'done',
            },
          },
        })
      )

      dispatch(
        createSetAlertMessageAction({
          description: 'UploadWatcherMapDispatch',
          values: {
            location: 'toast',
            type: 'success',
            message: 'Upload success',
          },
        })
      )
    },

    failure: ({ upload, uploadId }) => {
      dispatch(
        createUploadFailureAction({
          requestValues: {
            [uploadId]: upload,
          },
          failureValues: {
            [uploadId]: {
              ...upload,
              status: 'failed',
            },
          },
        })
      )

      dispatch(
        createSetAlertMessageAction({
          description: 'uploadFailureF',
          values: {
            location: 'toast',
            type: 'error',
            message: 'Upload failed',
          },
        })
      )
    },
  }
}
