import { connect } from 'react-redux'
import { NewTeamForm } from './NewTeamForm'
import { NewTeamFormMapState } from './NewTeamFormMapState'
import { NewTeamFormMapDispatch } from './NewTeamFormMapDispatch'
import { NewTeamFormMapStateReturnType } from './types/NewTeamFormMapStateReturnType'
import { NewTeamFormMapDispatchReturnType } from './types/NewTeamFormMapDispatchReturnType'
import { NewTeamFormConnectedProps } from './types/NewTeamFormConnectedProps'

export const NewTeamFormConnected = connect<
  NewTeamFormMapStateReturnType,
  NewTeamFormMapDispatchReturnType,
  NewTeamFormConnectedProps
>(
  NewTeamFormMapState,
  NewTeamFormMapDispatch
)(NewTeamForm)
