import { take, put, select } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'

import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'

import {
  SUBMIT_DELETE_USER_INVITE,
  SUBMIT_DELETE_USER_INVITE_DONE,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createDeleteUserInvitesRequestAction } from 'actionCreators/delete/DeleteUserInvites/createDeleteUserInvitesRequestAction'

export const submitDeleteUserInviteF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_DELETE_USER_INVITE])
    const { inviteId } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUser = state.currentUser
    const email = currentUser && currentUser.email
    const userInvites = state.userInvites || {}
    const userInvite = inviteId && userInvites[inviteId]
    const teamId = userInvite && userInvite.data.teamId

    if (email && inviteId && teamId) {
      yield put(
        createDeleteUserInvitesRequestAction({
          requestValues: {
            items: {
              [inviteId]: {
                data: {
                  email,
                },
              },
            },
          },
        })
      )
    } else {
      yield put(
        createUnexpectedErrorAction({ description: 'submitDeleteUserInviteF' })
      )
    }

    yield put({
      type: SUBMIT_DELETE_USER_INVITE_DONE,
    })
  }
}
