import { Dispatch, Action } from 'redux'
// import { DropResult, ResponderProvided } from 'react-beautiful-dnd'
// import { SUBMIT_TASK_PLAN_DROP } from 'actions'

import { DashboardLayoutMapDispatchReturnType } from './types'
import { createSetScrollPositionAction } from 'actionCreators/set/SetScrollPosition/createSetScrollPositionAction'

export const DashboardLayoutMapDispatch = (
  dispatch: Dispatch<Action>
): DashboardLayoutMapDispatchReturnType => {
  return {
    setScrollPosition: (scrollPosition) => {
      dispatch(
        createSetScrollPositionAction({
          scrollPosition,
          description: 'DashboardLayoutMapDispatch',
        })
      )
    },
  }
}
