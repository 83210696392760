import { connect } from 'react-redux'
import { TaskSizeSelectField } from './TaskSizeSelectField'
import { TaskSizeSelectFieldMapState } from './TaskSizeSelectFieldMapState'
import { TaskSizeSelectFieldMapDispatch } from './TaskSizeSelectFieldMapDispatch'

import { TaskSizeSelectFieldMapStateReturnType } from './types/TaskSizeSelectFieldMapStateReturnType'
import { TaskSizeSelectFieldMapDispatchReturnType } from './types/TaskSizeSelectFieldMapDispatchReturnType'
import { TaskSizeSelectFieldConnectedProps } from './types/TaskSizeSelectFieldConnectedProps'


export const TaskSizeSelectFieldConnected = connect<
  TaskSizeSelectFieldMapStateReturnType,
  TaskSizeSelectFieldMapDispatchReturnType,
  TaskSizeSelectFieldConnectedProps>(TaskSizeSelectFieldMapState,
    TaskSizeSelectFieldMapDispatch)(TaskSizeSelectField)
