import { Action } from 'redux'

import {
  SUBMIT_NEW_MESSAGE_FILES,
  SUBMIT_DELETE_NEW_MESSAGE_FILE,
  SUBMIT_NEW_MESSAGE_DONE,
} from 'actions'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { UploadFilesReducer } from './types/UploadFilesReducer'

export const uploadFilesReducer = (
  state?: UploadFilesReducer,
  action?: Action
): UploadFilesReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case SUBMIT_NEW_MESSAGE_DONE:
      return {
        ...state,
        newMessages: undefined,
      }

    case SUBMIT_NEW_MESSAGE_FILES:
      const newMessageFilesAction = action as SubmitAction
      const { files } = newMessageFilesAction.values

      return {
        ...state,
        newMessages: files,
      }

    case SUBMIT_DELETE_NEW_MESSAGE_FILE:
      const deleteNewMessageFilesAction = action as SubmitAction
      const newMessagesUploadFiles = state?.newMessages
      const index = deleteNewMessageFilesAction.values.index || 0
      const newUploadFiles = newMessagesUploadFiles?.splice(index, 0)

      return {
        ...state,
        newMessages: newUploadFiles,
      }

    default:
      return state || null
  }
}
