import { StateReducers } from 'state/reducers/types/StateReducers'
import { getIsMediumScreen } from 'state/getters/getIsMediumScreen'

import {
  // HistoryDropdownConnectedProps,
  HistoryDropdownMapStateReturnType,
} from './types'

export const HistoryDropdownMapState = (
  state: StateReducers,
  // props: HistoryDropdownConnectedProps,
): HistoryDropdownMapStateReturnType => {
  const toggles = state.toggles || undefined
  const showDropdown = toggles && toggles.showDropdown
  const showHistorysDropdown = showDropdown && showDropdown.type === 'historyDropdown'
  const isMediumScreen = getIsMediumScreen({ state })

  return {
    showHistorysDropdown,
    isMediumScreen,
  }
}
