import { patchTeamsFfSaga } from './../sagas/patch/patchTeamsFfSaga'
import { take, put, select, race, spawn } from 'redux-saga/effects'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { selectState } from 'state/reducers/selectState'
import { auth } from 'sdks'
import { firebaseSdk } from 'sdks'
import { SubmitAction } from 'actionCreators/submit/Submit/types/SubmitAction'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createHistoryAction } from 'actionCreators/createHistoryAction'

import {
  SUBMIT_LEAVE_TEAM,
  SUBMIT_LEAVE_TEAM_DONE,
  PATCH_TEAMS_FF_SUCCESS,
  PATCH_TEAMS_FF_FAILURE,
  PATCH_TEAMS_FF_CANCEL,
} from 'actions'
import { SELECT_TEAM_ROUTE } from 'routes'
import { Race } from 'types/Race'
import { createToggleAction } from 'actionCreators/createToggleAction'
import { createGetTeamsSuccessAction } from 'actionCreators/get/GetTeams/createGetTeamsSuccessAction'
import { PatchTeamsFfSuccessAction } from 'actionCreators/patch/PatchTeamsFf/types/PatchTeamsFfSuccessAction'
import { PatchTeamsFfFailureAction } from 'actionCreators/patch/PatchTeamsFf/types/PatchTeamsFfFailureAction'

export const submitLeaveTeamF = function*() {
  while (1) {
    const action: SubmitAction = yield take([SUBMIT_LEAVE_TEAM])
    const { teamId } = action.values
    const state: StateReducers = yield select(selectState)
    const currentUserId = state.currentUserId
    const currentUser = firebaseSdk.auth().currentUser
    const authCurrentUser = auth.currentUser

    if (currentUser && authCurrentUser && currentUserId && teamId) {
      // Need to make sure current team id is not set

      yield put(
        createHistoryAction({
          description: 'submitLeaveTeamF',
          pathname: SELECT_TEAM_ROUTE,
          method: 'replace',
        })
      )

      yield put(
        createToggleAction({
          description: 'submitLeaveTeamF',
          toggles: {
            showModal: {
              type: 'Leaving',
            },
          },
        })
      )

      const idToken = yield currentUser.getIdToken()

      yield spawn(patchTeamsFfSaga, {
        values: {
          action: 'leave',
          items: {
            [teamId]: {},
          },
        },
        apiHeaders: {
          token: idToken,
        },
      })

      const patchResult: Race<
        PatchTeamsFfSuccessAction,
        PatchTeamsFfFailureAction,
        any
      > = yield race({
        success: take(PATCH_TEAMS_FF_SUCCESS),
        failure: take(PATCH_TEAMS_FF_FAILURE),
        cancel: take(PATCH_TEAMS_FF_CANCEL),
      })

      if (patchResult.success) {
        const { successValues } = patchResult.success

        yield put(
          createGetTeamsSuccessAction({
            description: 'submitLeaveTeamF',
            requestValues: {
              userId: currentUserId,
            },
            successValues: {
              items: {
                [teamId]: {
                  data: successValues.items[teamId],
                },
              },
            },
          })
        )

        yield put(
          createToggleAction({
            description: 'submitLeaveTeamF',
            toggles: {
              showModal: false,
            },
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'submitLeaveTeamF.ts',
        })
      )
    }

    yield put({
      type: SUBMIT_LEAVE_TEAM_DONE,
    })
  }
}
