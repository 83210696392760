import { SelectTagOptionStyles } from './SelectTagOptionStyles'
import React from 'react'
import { SelectTagOptionProps } from './types/SelectTagOptionProps'
import { AvatarConnected } from 'components/widget/Avatar'
import { DisplayNameSpanConnected } from 'components/span/DisplayNameSpan'
import { LabelIconSpanConnected } from 'components/span/LabelIconSpan'

export const SelectTagOption = ({
  id,
  type,
  showDisplayName = true,
}: SelectTagOptionProps): JSX.Element => {
  const isUser = type === 'user'

  return (
    <div className={'SelectTagOption ' + SelectTagOptionStyles} id={id}>
      {isUser && <AvatarConnected userId={id} />}

      {!isUser && <LabelIconSpanConnected labelId={id} />}

      {isUser && showDisplayName && <DisplayNameSpanConnected userId={id} />}

      {!isUser && showDisplayName && <DisplayNameSpanConnected labelId={id} />}
    </div>
  )
}
