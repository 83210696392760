// import { Dispatch, Action } from 'redux'
// import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'
// import { SUBMIT_DELETE_TASK_TAG } from 'actions'

import {
  // TeamDropdownConnectedProps,
  TeamDropdownMapDispatchReturnType,
} from './types'

export const TeamDropdownMapDispatch = (
  // dispatch: Dispatch<Action>,
  // props: TeamDropdownConnectedProps,
): TeamDropdownMapDispatchReturnType => {
  return {
  }
}
