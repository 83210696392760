import { css } from 'emotion'

export const TaskTagDropdownStyles = css`
  position: relative;

  .DropdownButton {
    display: block;
  }

  .Dropdown {
    .chevronContainer {
      left: 6px;
      top: 36px;
    }

    .container {
      width: 160px;
      left: -68px;
      top: 43px;
      padding: 8px;
      text-align: center;
    }
  }
`
