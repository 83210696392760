import * as React from 'react'
import { connect } from 'react-redux'
import { Router as RouterDom } from 'react-router-dom'
import { Router } from 'react-router'
import ReduxRouterMapState, {
  ReduxRouterMapStateReturnType,
} from './ReduxRouterMapState'
import {
  ReduxRouterMapDispatch,
  ReduxRouterMapDispatchReturnType,
} from './ReduxRouterMapDispatch'
import { History } from 'history'

export interface ReduxRouterConnectProps {
  history: History
}

export interface ReduxRouterClassProps
  extends ReduxRouterMapStateReturnType,
    ReduxRouterMapDispatchReturnType,
    ReduxRouterConnectProps {}

class ReduxRouterClass extends React.Component<ReduxRouterClassProps> {
  unsubscribeFromHistory: any

  constructor(props: ReduxRouterClassProps) {
    super(props)

    const { history, locationChange } = props
    this.unsubscribeFromHistory = history.listen(locationChange)

    locationChange(history.location)
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) {
      this.unsubscribeFromHistory()
    }
  }

  public render() {
    return React.createElement(window ? RouterDom : Router, this.props)
  }
}

export const ReduxRouterC = connect<
  ReduxRouterMapStateReturnType,
  ReduxRouterMapDispatchReturnType,
  ReduxRouterConnectProps
>(
  ReduxRouterMapState,
  ReduxRouterMapDispatch
)(ReduxRouterClass)
