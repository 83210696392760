import { BigHeaderStyles } from './BigHeaderStyles'
import React, { useState, useEffect } from 'react'
import { BigHeaderProps } from './types/BigHeaderProps'
import { ActivityNotificationDropdownConnected } from 'components/dropdown/ActivityNotificationDropdown'
import { MessageNotificationDropdownConnected } from 'components/dropdown/MessageNotificationDropdown'
import { HistoryDropdownConnected } from 'components/dropdown/HistoryDropdown'
import { UserAccountHeaderConnected } from '../UserAccountHeader'
import { TASK_DETAIL_ROUTE } from 'routes'
import { GoBackLinkConnected } from 'components/link/GoBackLink'
import { TeamNameLinkConnected } from 'components/link/TeamNameLink'
import { TaskSummarySpanConnected } from 'components/span/TaskSummarySpan'
import { CustomLinkConnected } from 'components/link/CustomLink'
import { PrimaryDropdownConnected } from 'components/dropdown/PrimaryDropdown'
import { flexSpaceBetweenStyles } from 'styles/flex/flexSpaceBetweenStyles'
import { textLinkStyles } from 'styles/text/textLinkStyles'
import { flexCenterCenterStyles } from '../../../styles/flex/flexCenterCenterStyles'
import { marginLeftSmallStyles } from '../../../styles/margin/marginLeftSmallStyles'
import { marginLeftSmallestStyles } from '../../../styles/margin/marginLeftSmallestStyles'
import { CheveronRightIcon } from '../../icon/CheveronRightIcon/CheveronRightIcon'
import { iconButtonStyles } from '../../../styles/iconButtonStyles'
import { paddingSmallStyles } from '../../../styles/padding/paddingSmallStyles'

export const BigHeader = ({
  showTaskSummary = false,
  isBigScreen,
  isMediumScreen,
}: BigHeaderProps): JSX.Element => {
  const isMediumScreenClassName = isMediumScreen ? ' isMediumScreen' : ''
  const isBigScreenClassName = isBigScreen ? ' isBigScreen' : ''
  const [hideIcons, setHideIcons] = useState<boolean>()

  useEffect(() => {
    setHideIcons(!isMediumScreen && showTaskSummary)
  }, [isMediumScreen, setHideIcons, showTaskSummary])

  return (
    <div
      className={`BigHeader
        ${flexSpaceBetweenStyles}
        ${BigHeaderStyles}
        ${isMediumScreenClassName}
        ${isBigScreenClassName}`}
    >
      <div className="goBackAndTeam flexAlign">
        <GoBackLinkConnected
          onClick={(e) => {
            if (!hideIcons && !isMediumScreen && showTaskSummary) {
              e.preventDefault()
              setHideIcons(true)
            }
          }}
        />

        {!showTaskSummary && (
          <TeamNameLinkConnected
            showLogoOnly={!isBigScreen && !isMediumScreen}
          />
        )}

        {showTaskSummary && (hideIcons !== false || isMediumScreen) && (
          <CustomLinkConnected
            route={TASK_DETAIL_ROUTE}
            className={`taskDetailLink ${textLinkStyles}`}
            withCurrentQuery={true}
            title="Go to task"
          >
            <TaskSummarySpanConnected />
          </CustomLinkConnected>
        )}
      </div>

      {hideIcons && (
        <button
          type="button"
          className={`${iconButtonStyles} ${paddingSmallStyles}`}
          onClick={() => {
            setHideIcons(false)
          }}
        >
          <CheveronRightIcon />
        </button>
      )}

      {!hideIcons && (
        <div className={`icons ${flexCenterCenterStyles}`}>
          <UserAccountHeaderConnected className={marginLeftSmallStyles} />

          <ActivityNotificationDropdownConnected
            className={marginLeftSmallStyles}
          />

          <MessageNotificationDropdownConnected
            className={marginLeftSmallStyles}
          />

          <HistoryDropdownConnected className={marginLeftSmallStyles} />

          <PrimaryDropdownConnected className={marginLeftSmallestStyles} />
        </div>
      )}
    </div>
  )
}
