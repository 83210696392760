import { InlineEditorToolBarStyles } from './InlineEditorToolBarStyles'
import React, { useState, CSSProperties } from 'react'
import { InlineEditorToolBarProps } from './types/InlineEditorToolBarProps'
import { DraftToolBar } from 'components/DraftToolBar'
import { Button } from 'components/button/Button'
import { TimesCircleIcon } from 'components/icon/TimesCircleIcon/TimesCircleIcon'

export const InlineEditorToolBar = ({
  editorState,
  setEditorState,
  width = 0,
  isTouch,
  alwaysShowInlineToolbar,
  isMediumScreen,
}: InlineEditorToolBarProps): JSX.Element | null => {
  const selection = editorState?.getSelection()
  const hasFocus = selection?.getHasFocus()
  const hasHighLight = !selection?.isCollapsed() && hasFocus
  const [hasHighLightCache, setHasHighLightCache] = useState(hasHighLight)
  const [manuallyClosed, setManuallyClosed] = useState(false)

  const [
    alwaysShowInlineToolbarCache,
    setAlwaysShowInlineToolbarCache,
  ] = useState(alwaysShowInlineToolbar)

  let isVisible = false

  if (alwaysShowInlineToolbarCache) {
    isVisible = !!hasFocus
  } else {
    isVisible = !!hasHighLight
  }

  if (manuallyClosed) {
    isVisible = false
  }

  if (hasHighLightCache !== hasHighLight) {
    setHasHighLightCache(hasHighLight)
  }

  if (!hasHighLightCache && hasHighLight) {
    isVisible = true
  }

  if (isVisible && manuallyClosed) {
    setManuallyClosed(false)
  }

  if (isVisible && alwaysShowInlineToolbar && !alwaysShowInlineToolbarCache) {
    setAlwaysShowInlineToolbarCache(true)
  }

  if (isVisible) {
    let styleMutable: CSSProperties = {
      marginLeft: '16px',
    }

    if (width < 500) {
      styleMutable.left = '16px'
    } else {
      styleMutable.left = undefined
    }

    if (isTouch) {
      styleMutable.bottom = '0px'
    } else {
      styleMutable.top = '100px'
    }

    return (
      <div
        className={'InlineEditorToolBar ' + InlineEditorToolBarStyles}
        style={styleMutable}
      >
        <Button
          className="closeInlineToolBar"
          onClick={() => {
            setAlwaysShowInlineToolbarCache(false)
            setManuallyClosed(true)
          }}
          onMouseDown={(e) => {
            e.preventDefault() // to keep focus on editor
          }}
        >
          <TimesCircleIcon />
        </Button>

        <DraftToolBar
          editorState={editorState}
          setEditorState={setEditorState}
          isMediumScreen={isMediumScreen}
          isTouch={isTouch}
          // extraOnClick={() => {
          //   setIsVisible(false)
          // }}
        />
      </div>
    )
  } else {
    return null
  }
}
