import { MessagingToolBarStyles } from './MessagingToolBarStyles'
import React from 'react'
import { MessagingToolBarProps } from './types/MessagingToolBarProps'
import { Button } from 'components/button/Button'
import { EditorEmojiButtonConnected } from 'components/button/EditorEmojiButton'
import { NewMessageFileFieldConnected } from 'components/field/NewMessageFileField'
import { PaperPlanIcon } from 'components/icon/PaperPlanIcon/PaperPlanIcon'

export const MessagingToolBar = ({
  editorState,
  setEditorState,
  showFileUpload,
  submit,
  dropdownId,
}: MessagingToolBarProps): JSX.Element => {
  return (
    <div className={'MessagingToolBar ' + MessagingToolBarStyles}>
      <div className="spaceBetween actions">
        <ul className="centerFlex">
          {showFileUpload && (
            <li>
              <NewMessageFileFieldConnected />
            </li>
          )}

          <li>
            <EditorEmojiButtonConnected
              dropdownId={dropdownId || 'MessagingToolBar'}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </li>
        </ul>

        <Button className="saveButton small" onClick={submit}>
          <PaperPlanIcon />
        </Button>
      </div>
    </div>
  )
}
