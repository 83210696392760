import { Dispatch, Action } from 'redux'
import { SUBMIT_DELETE_TASK } from 'actions'
import { DeleteTaskButtonMapDispatchReturnType } from './types/DeleteTaskButtonMapDispatchReturnType'
import { createSubmitAction } from 'actionCreators/submit/Submit/createSubmitAction'

export const DeleteTaskButtonMapDispatch = (
  dispatch: Dispatch<Action>
): DeleteTaskButtonMapDispatchReturnType => {
  return {
    deleteTask: (taskId) => {
      dispatch(
        createSubmitAction({
          values: {
            taskId,
          },
          type: SUBMIT_DELETE_TASK,
        })
      )
    },
  }
}
