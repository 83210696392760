import { take, put } from 'redux-saga/effects'
import { firestore } from 'sdks'
import { PATCH_UNSCOPE_USER_REQUEST } from 'actions'
import { setUnscopeUser } from 'firebaseFunctions/utilities/ref/set/setUnscopeUser'
import { createUnexpectedErrorAction } from 'actionCreators/createUnexpectedErrorAction'
import { createFirebaseErrorAction } from 'actionCreators/createFirebaseErrorAction'
import { PatchUnscopeUserRequestAction } from 'actionCreators/patch/PatchUnscopeUser/types/PatchUnscopeUserRequestAction'
import { createPatchUnscopeUserSuccessAction } from 'actionCreators/patch/PatchUnscopeUser/createPatchUnscopeUserSuccessAction'
import { createPatchUnscopeUserFailureAction } from 'actionCreators/patch/PatchUnscopeUser/createPatchUnscopeUserFailureAction'

export const patchUnscopeUserRequestF = function*() {
  while (1) {
    const patchUnscopeUserRequestAction: PatchUnscopeUserRequestAction = yield take(
      [PATCH_UNSCOPE_USER_REQUEST]
    )

    const { requestValues } = patchUnscopeUserRequestAction
    const { item, params } = requestValues
    const { userId } = params

    if (userId && item) {
      try {
        // TODO: handle when theres no internet
        yield setUnscopeUser({
          firestore,
          userId,
          unscopeUser: item,
        })

        yield put(
          createPatchUnscopeUserSuccessAction({
            requestValues,
            successValues: requestValues,
          })
        )
      } catch (error) {
        yield put(
          createFirebaseErrorAction({
            error: error as firebase.FirebaseError,
            description: 'patchUnscopeUserRequestF',
          })
        )

        yield put(
          createPatchUnscopeUserFailureAction({
            requestValues,
          })
        )
      }
    } else {
      yield put(
        createUnexpectedErrorAction({
          description: 'patchUnscopeUserRequestF',
        })
      )
    }
  }
}
