// import { Dispatch, Action } from 'redux'

import {
  // PrimaryDropdownConnectedProps,
  PrimaryDropdownMapDispatchReturnType,
} from './types'

export const PrimaryDropdownMapDispatch = (): // dispatch: Dispatch<Action>,
// props: PrimaryDropdownConnectedProps,
PrimaryDropdownMapDispatchReturnType => {
  return {}
}
