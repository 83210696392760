import { connect } from 'react-redux'
import { TeamLogoWidget } from './TeamLogoWidget'
import { TeamLogoWidgetMapState } from './TeamLogoWidgetMapState'
import { TeamLogoWidgetMapDispatch } from './TeamLogoWidgetMapDispatch'
import { TeamLogoWidgetMapStateReturnType } from './types/TeamLogoWidgetMapStateReturnType'
import { TeamLogoWidgetMapDispatchReturnType } from './types/TeamLogoWidgetMapDispatchReturnType'
import { TeamLogoWidgetConnectedProps } from './types/TeamLogoWidgetConnectedProps'

export const TeamLogoWidgetConnected = connect<
  TeamLogoWidgetMapStateReturnType,
  TeamLogoWidgetMapDispatchReturnType,
  TeamLogoWidgetConnectedProps>(TeamLogoWidgetMapState,
    TeamLogoWidgetMapDispatch)(TeamLogoWidget)
