import { css } from 'emotion'

export const StripeInvoiceListStyles = css`
  > li {
    display: inline-block;
    margin: 8px;

    .StripeInvoiceCard {
      display: inline-block;
    }
  }
`
