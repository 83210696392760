import * as React from 'react'
import { MessageLayoutConnected } from './MessageLayoutConnected'
import { Provider } from 'react-redux'
import { mockState, createMockStore } from 'mockState'

export const MessageLayoutExamples = () => {
  return (
    <div className="MessageLayoutExamples exampleContainer">
      <h1 className="exampleHeader">default</h1>

      <Provider store={createMockStore(mockState)}>
        <MessageLayoutConnected />
      </Provider>
    </div>
  )
}
